import React from 'react'
import { ToastContainer } from 'react-toastify'

import { CloseRangeProvider } from '../../context/CloseRangeProvider'
import { IdleTimerWrapProvider } from '../../context/IdleTimerProvider'
// hocs
//cpn
import Navbar from '../Common/navbar'

const Layout = (props) => {
  return (
    <IdleTimerWrapProvider>
      <CloseRangeProvider>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="min-h-screen bg-white text-opacity text-xs md:text-sm">
          <Navbar />
          {props.children}
        </div>
      </CloseRangeProvider>
    </IdleTimerWrapProvider>
  )
}

export default Layout
