import React, { useRef } from 'react'
import Textarea from '../Report/Textarea'
import useAuth from '../../hooks/useAuth'
import { makeRoles } from '../../utils/common'
function Checklist({ editMode, report, building, inspectors, cp, setValue, handleBreakLine }) {
  const { user } = useAuth()
  const { role } = user?.user || {}
  const refCheckListtable = useRef()
  return (
    <>
      <ol className="toc-list" style={{ lineHeight: '30px' }}>
        <li id="appendixF" className="text-lg font-bold">
          <div className="py-4">Appendix F: CP Checklist for PFI Report</div>
          <div className="py-4 text-center">
            CHECKLIST FOR PERIODIC FAÇADE INSPECTION (PFI) REPORT OF EXISTING BUILDING AT
          </div>
          <div className=" text-center">{building?.address}</div>
        </li>

        <div className="py-6">
          I declare that all the items listed below are addressed in my visual inspection report and a “tick” (✓) is
          placed in the checkbox for each item to indicate the aforementioned.
        </div>

        <div className="" style={{ lineHeight: 'normal' }}>
          <table className="border-[1px] w-full table-test">
            <tr className="border-[1px]">
              <td className="border-[1px] p-2 text-left">1. Types of façade systems and details</td>
              <td></td>
            </tr>
            <tr className="border-[1px]">
              <td className="border-[1px] p-2 px-8 text-left">a. Floor layout plans and details attached in Annex</td>
              <td className="text-center p-2">
                <input
                  disabled={!editMode || ![...makeRoles([10])].includes(role)}
                  name="q1a"
                  checked={report?.q1a}
                  onClick={(e) => setValue(e.currentTarget.checked, e.currentTarget.name)}
                  type="checkbox"
                />
              </td>
            </tr>
            <tr className="border-[1px]">
              <td className="border-[1px] p-2 px-8 text-left">
                b. Indication of façade types on building elevation plans
              </td>
              <td className="text-center p-2">
                <input
                  disabled={!editMode || ![...makeRoles([10])].includes(role)}
                  type="checkbox"
                  name="q1b"
                  checked={report?.q1b}
                  onClick={(e) => setValue(e.currentTarget.checked, e.currentTarget.name)}
                />
              </td>
            </tr>
            <tr className="border-[1px]">
              <td className="border-[1px] p-2 px-8 text-left">
                c. Description of all façade systems (including material and connections)
              </td>
              <td className="text-center p-2">
                <input
                  disabled={!editMode || ![...makeRoles([10])].includes(role)}
                  name="q1c"
                  checked={report?.q1c}
                  onClick={(e) => setValue(e.currentTarget.checked, e.currentTarget.name)}
                  type="checkbox"
                />
              </td>
            </tr>

            <tr className="border-[1px]">
              <td className="border-[1px] p-2 text-left font-bold">2. Particulars of inspection personnel</td>
              <td></td>
            </tr>
            <tr className="border-[1px]">
              <td className="border-[1px] p-2 px-8 text-left">
                a. For Competent Person (CP): Name of CP, company name, company address, contact number and email
                address stated on cover page
              </td>
              <td className="text-center p-2">
                <input
                  disabled={!editMode || ![...makeRoles([10])].includes(role)}
                  name="q2a"
                  checked={report?.q2a}
                  onClick={(e) => setValue(e.currentTarget.checked, e.currentTarget.name)}
                  type="checkbox"
                />
              </td>
            </tr>
            <tr className="border-[1px]">
              <td className="border-[1px] p-2 px-8 text-left">
                b. For Façade Inspectors (FI): Name of FI, company name, company address, FI registration number listed
                in Annex
              </td>
              <td className="text-center p-2">
                <input
                  disabled={!editMode || ![...makeRoles([10])].includes(role)}
                  name="q2b"
                  checked={report?.q2b}
                  onClick={(e) => setValue(e.currentTarget.checked, e.currentTarget.name)}
                  type="checkbox"
                />
              </td>
            </tr>

            <tr className="border-[1px]">
              <td className="border-[1px] p-2 text-left font-bold">3. Visual inspection of the building façade</td>
              <td></td>
            </tr>
            <tr className="border-[1px]">
              <td className="border-[1px] p-2 px-8 text-left">a. Inspected 100% of building façade</td>
              <td className="text-center p-2">
                <input
                  disabled={!editMode || ![...makeRoles([10])].includes(role)}
                  name="q3a"
                  checked={report?.q3a}
                  onClick={(e) => setValue(e.currentTarget.checked, e.currentTarget.name)}
                  type="checkbox"
                />
              </td>
            </tr>
            <tr className="border-[1px]">
              <td className="border-[1px] p-2 px-8 text-left">b. Description of inspection methods and its process</td>
              <td className="text-center p-2">
                <input
                  disabled={!editMode || ![...makeRoles([10])].includes(role)}
                  name="q3b"
                  checked={report?.q3b}
                  onClick={(e) => setValue(e.currentTarget.checked, e.currentTarget.name)}
                  type="checkbox"
                />
              </td>
            </tr>
            <tr className="border-[1px]">
              <td className="border-[1px] p-2 px-8 text-left">
                c. Close-up photos of façade defects classified as “Require repair” or “Unsafe” attached in Annex, with
                details of its location and defect type
              </td>
              <td className="text-center p-2">
                <input
                  disabled={!editMode || ![...makeRoles([10])].includes(role)}
                  name="q3c"
                  checked={report?.q3c}
                  onClick={(e) => setValue(e.currentTarget.checked, e.currentTarget.name)}
                  type="checkbox"
                />
              </td>
            </tr>

            <tr className="border-[1px]">
              <td className="border-[1px] p-2 text-left font-bold">4. Close-range inspection of the building façade</td>
              <td></td>
            </tr>
            <tr className="border-[1px]">
              <td className="border-[1px] p-2 px-8 text-left">a. Inspected a minimum of 10% per building elevation</td>
              <td className="text-center p-2">
                <input
                  disabled={!editMode || ![...makeRoles([10])].includes(role)}
                  name="q4a"
                  checked={report?.q4a}
                  onClick={(e) => setValue(e.currentTarget.checked, e.currentTarget.name)}
                  type="checkbox"
                />
              </td>
            </tr>
            <tr className="border-[1px]">
              <td className="border-[1px] p-2 px-8 text-left">
                b. Location of close-range inspection indicated on elevation plans, with calculation on façade area
                inspected at each building elevation to justify item 4a.
              </td>
              <td className="text-center p-2">
                <input
                  disabled={!editMode || ![...makeRoles([10])].includes(role)}
                  name="q4b"
                  checked={report?.q4b}
                  onClick={(e) => setValue(e.currentTarget.checked, e.currentTarget.name)}
                  type="checkbox"
                />
              </td>
            </tr>
            <tr className="border-[1px]">
              <td className="border-[1px] p-2 px-8 text-left">
                c. Description of inspection methods and its process for each façade system
              </td>
              <td className="text-center p-2">
                <input
                  disabled={!editMode || ![...makeRoles([10])].includes(role)}
                  name="q4c"
                  checked={report?.q4c}
                  onClick={(e) => setValue(e.currentTarget.checked, e.currentTarget.name)}
                  type="checkbox"
                />
              </td>
            </tr>
            <tr className="border-[1px]">
              <td className="border-[1px] p-2 px-8 text-left">
                d. Close-up photos of façade defects classified as “Require repair” or “Unsafe” attached in Annex, with
                details of its location and defect type
              </td>
              <td className="text-center p-2">
                <input
                  disabled={!editMode || ![...makeRoles([10])].includes(role)}
                  name="q4d"
                  checked={report?.q4d}
                  onClick={(e) => setValue(e.currentTarget.checked, e.currentTarget.name)}
                  type="checkbox"
                />
              </td>
            </tr>

            <tr className="border-[1px]">
              <td className="border-[1px] p-2 text-left font-bold">
                5. Overall elevation photo of each façade elevation, and medium long shot (not more than four stories)
                covering full building facade.
              </td>
              <td className="text-center p-2">
                <input
                  disabled={!editMode || ![...makeRoles([10])].includes(role)}
                  name="q5"
                  checked={report?.q5}
                  onClick={(e) => setValue(e.currentTarget.checked, e.currentTarget.name)}
                  type="checkbox"
                />
              </td>
            </tr>

            <tr className="border-[1px]">
              <td className="border-[1px] p-2 text-left font-bold">
                6. Inspected for signs of façade defects and deterioration
              </td>
              <td></td>
            </tr>
            <tr className="border-[1px]">
              <td className="border-[1px] p-2 px-8 text-left">a. Debonding/bulging</td>
              <td className="text-center p-2">
                <input
                  disabled={!editMode || ![...makeRoles([10])].includes(role)}
                  name="q6a"
                  checked={report?.q6a}
                  onClick={(e) => setValue(e.currentTarget.checked, e.currentTarget.name)}
                  type="checkbox"
                />
              </td>
            </tr>
            <tr className="border-[1px]">
              <td className="border-[1px] p-2 px-8 text-left">b. Cracks on façade and its supports</td>
              <td className="text-center p-2">
                <input
                  disabled={!editMode || ![...makeRoles([10])].includes(role)}
                  name="q6b"
                  checked={report?.q6b}
                  onClick={(e) => setValue(e.currentTarget.checked, e.currentTarget.name)}
                  type="checkbox"
                />
              </td>
            </tr>
            <tr className="border-[1px]">
              <td className="border-[1px] p-2 px-8 text-left">c. Corrosion</td>
              <td className="text-center p-2">
                <input
                  disabled={!editMode || ![...makeRoles([10])].includes(role)}
                  name="q6c"
                  checked={report?.q6c}
                  onClick={(e) => setValue(e.currentTarget.checked, e.currentTarget.name)}
                  type="checkbox"
                />
              </td>
            </tr>
            <tr className="border-[1px]">
              <td className="border-[1px] p-2 px-8 text-left">d. Loose/misaligned members</td>
              <td className="text-center p-2">
                <input
                  disabled={!editMode || ![...makeRoles([10])].includes(role)}
                  name="q6d"
                  checked={report?.q6d}
                  onClick={(e) => setValue(e.currentTarget.checked, e.currentTarget.name)}
                  type="checkbox"
                />
              </td>
            </tr>
            <tr className="border-[1px]">
              <td className="border-[1px] p-2 px-8 text-left">e. Spalling concrete</td>
              <td className="text-center p-2">
                <input
                  disabled={!editMode || ![...makeRoles([10])].includes(role)}
                  name="q6e"
                  checked={report?.q6e}
                  onClick={(e) => setValue(e.currentTarget.checked, e.currentTarget.name)}
                  type="checkbox"
                />
              </td>
            </tr>
            <tr className="border-[1px]">
              <td className="border-[1px] p-2 px-8 text-left">f. Dislodged/dangling façade elements</td>
              <td className="text-center p-2">
                <input
                  disabled={!editMode || ![...makeRoles([10])].includes(role)}
                  name="q6f"
                  checked={report?.q6f}
                  onClick={(e) => setValue(e.currentTarget.checked, e.currentTarget.name)}
                  type="checkbox"
                />
              </td>
            </tr>
            <tr className="border-[1px]">
              <td className="border-[1px] p-2 px-8 text-left">g. Missing supports/fixings</td>
              <td className="text-center p-2">
                <input
                  disabled={!editMode || ![...makeRoles([10])].includes(role)}
                  name="q6g"
                  checked={report?.q6g}
                  onClick={(e) => setValue(e.currentTarget.checked, e.currentTarget.name)}
                  type="checkbox"
                />
              </td>
            </tr>
            <tr className="border-[1px]">
              <td className="border-[1px] p-2 px-8 text-left">
                <div>h. Others (Please specify)</div>
                {[...makeRoles([10])].includes(role) && (
                  <div className="py-4">
                    <Textarea
                      refTextArea={refCheckListtable}
                      id="refCheckListtable"
                      checkeds={report?.q6h_comment}
                      name="q6h_comment"
                      setValue={setValue}
                      editMode={editMode}
                      maxlength={300}
                    />
                    <div id="refCheckListtable-div-container" className={editMode ? 'hidden' : ''}>
                      <div id={`refCheckListtable-div`}>{handleBreakLine(report?.q6h_comment)}</div>
                    </div>
                  </div>
                )}
              </td>
              <td className="text-center p-2">
                <input
                  disabled={!editMode || ![...makeRoles([10])].includes(role)}
                  name="q6h"
                  checked={report?.q6h}
                  onClick={(e) => setValue(e.currentTarget.checked, e.currentTarget.name)}
                  type="checkbox"
                />
              </td>
            </tr>

            <tr className="border-[1px]">
              <td className="border-[1px] p-2 text-left font-bold font-bold">
                7. Summary of façade defects observed, with condition classification (“Require Repair” or “Unsafe”)
              </td>
              <td className="text-center p-2">
                <input
                  disabled={!editMode || ![...makeRoles([10])].includes(role)}
                  name="q7"
                  checked={report?.q7}
                  onClick={(e) => setValue(e.currentTarget.checked, e.currentTarget.name)}
                  type="checkbox"
                />
              </td>
            </tr>

            <tr className="border-[1px]">
              <td className="border-[1px] p-2  text-left font-bold">
                8. Recommend remedial works for façade systems classified as “Require Repair” and “Unsafe” and façade
                maintenance measures.
              </td>
              <td className="text-center p-2">
                <input
                  disabled={!editMode || ![...makeRoles([10])].includes(role)}
                  name="q8"
                  checked={report?.q8}
                  onClick={(e) => setValue(e.currentTarget.checked, e.currentTarget.name)}
                  type="checkbox"
                />
              </td>
            </tr>

            <tr className="border-[1px]">
              <td className="border-[1px] p-2  text-left font-bold">
                9. Conclusion of inspection. Summary of assessment on the condition of the façade elements and highlight
                façade elements requiring remedial works. Assessment on the necessity for a full façade investigation.
              </td>
              <td className="text-center p-2">
                <input
                  disabled={!editMode || ![...makeRoles([10])].includes(role)}
                  name="q9"
                  checked={report?.q9}
                  onClick={(e) => setValue(e.currentTarget.checked, e.currentTarget.name)}
                  type="checkbox"
                />
              </td>
            </tr>

            <tr className="border-[1px]">
              <td className="border-[1px] p-2  text-left font-bold">
                10. Annex C: Inspection Checklist of Façade Record for Elevation in the “Guidelines on PFI” attached in
                Annex.
              </td>
              <td className="text-center p-2">
                <input
                  disabled={!editMode || ![...makeRoles([10])].includes(role)}
                  name="q10"
                  checked={report?.q10}
                  onClick={(e) => setValue(e.currentTarget.checked, e.currentTarget.name)}
                  type="checkbox"
                />
              </td>
            </tr>

            <tr className="border-[1px]">
              <td className="border-[1px] p-2 text-left font-bold">
                11. If drones are used for inspection, Annex E: UAS Inspection Report in “TR78-1 : 2020: Building façade
                inspection using unmanned aircraft system (UAS)” attached in Annex.
              </td>
              <td className="text-center p-2">
                <input
                  disabled={!editMode || ![...makeRoles([10])].includes(role)}
                  name="q11"
                  checked={report?.q11}
                  onClick={(e) => setValue(e.currentTarget.checked, e.currentTarget.name)}
                  type="checkbox"
                />
              </td>
            </tr>

            <tr className="border-[1px]">
              <td className="border-[1px] p-2 text-left font-bold">
                12. Annex D: Standard Certification by Competent Person on first and last page of report.
              </td>
              <td className="text-center p-2">
                <input
                  disabled={!editMode || ![...makeRoles([10])].includes(role)}
                  name="q12"
                  checked={report?.q12}
                  onClick={(e) => setValue(e.currentTarget.checked, e.currentTarget.name)}
                  type="checkbox"
                />
              </td>
            </tr>
          </table>
        </div>
        <div className="flex justify-between gap-8 pt-24 font-normal reportContentBreak">
          <div>
            <div className="flex justify-center gap-2 my-4">
              <div className="mt-1">Competent Person:</div>
              <div>
                <div className="text-center">{cp?.username}</div>
                <div className={cp?.username ? 'mt-[-25px]' : 'mt-1'}>__________________</div>
              </div>
            </div>
          </div>

          <div>
            <div className="flex justify-center gap-2 my-4">
              <div className="mt-1">Date:</div>
              <div className="mt-1">___________________</div>
            </div>
          </div>
        </div>
      </ol>
    </>
  )
}

export default Checklist
