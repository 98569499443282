import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import Webcam from 'react-webcam'
import Button from '../button/CommonButton'
import { color } from '../../../constant/styled'

const videoConstraints = {
  width: 400,
  height: 320,
  facingMode: 'environment'
}

function CameraWeb({ onTakePhoto }) {
  const [image, setImage] = useState('')
  const webcamRef = useRef(null)
  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot()
    setImage(imageSrc)
  }

  return (
    <div>
      <div className="h-[320px] max-w-[400px]">
        {image ? (
          <img src={image} alt="cam-thumb" />
        ) : (
          <Webcam
            audio={false}
            height={320}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width={400}
            videoConstraints={videoConstraints}
          />
        )}
      </div>
      <div className="flex items-center justify-between mt-10">
        {image ? (
          <Button
            textColor={color.white}
            bgColor={color.green}
            iconPosion="right"
            height={40}
            placement="Retake"
            width={80}
            onClick={() => setImage('')}
          />
        ) : (
          <Button
            placement="Capture"
            textColor={color.white}
            bgColor={color.green}
            iconPosion="right"
            height={40}
            width={80}
            onClick={capture}
          />
        )}
        <Button
          placement="Save"
          textColor={color.white}
          bgColor={image ? color.red : color.redLight}
          iconPosion="right"
          height={40}
          width={80}
          disabled={!image}
          onClick={() => onTakePhoto(image)}
        />
      </div>
    </div>
  )
}

CameraWeb.propTypes = {
  onTakePhoto: PropTypes.func
}

export default CameraWeb
