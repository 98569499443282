import { createAsyncThunk } from '@reduxjs/toolkit'
import logApis from '../../apis/logApis'
import logTypes from '../../constant/store/logTypes'

export const getInpsectionLog = createAsyncThunk(logTypes.GET_INSPECTION_LOG, async (data, { rejectWithValue }) => {
  try {
    const result = await logApis.getInpsectionLog(data)
    if (result.status === 200) {
      return result.data
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const getCompanyLog = createAsyncThunk(logTypes.GET_COMPANY_LOG, async (data, { rejectWithValue }) => {
  try {
    const result = await logApis.getCompanyLog(data)
    if (result.status === 200) {
      return result.data
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const getUserLog = createAsyncThunk(logTypes.GET_USER_LOG, async (data, { rejectWithValue }) => {
  try {
    const result = await logApis.getUserLog(data)
    if (result.status === 200) {
      return result.data
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const getElevationLog = createAsyncThunk(logTypes.GET_ELEVATION_LOG, async (data, { rejectWithValue }) => {
  try {
    const result = await logApis.getElevationLog(data.id, data.options)
    if (result.status === 200) {
      return result.data
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const getElevationLogAll = createAsyncThunk(
  logTypes.GET_ELEVATION_LOG_All,
  async (data, { rejectWithValue }) => {
    try {
      const result = await logApis.getElevationLogAll(data)
      if (result.status === 200) {
        return result.data
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getImageLogAll = createAsyncThunk(logTypes.GET_IMAGE_LOG_ALL, async (data, { rejectWithValue }) => {
  try {
    const result = await logApis.getImageLogAll(data)
    if (result.status === 200) {
      return result.data
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const getTrackUserLog = createAsyncThunk(logTypes.GET_TRACK_USER_LOG, async (data, { rejectWithValue }) => {
  try {
    const result = await logApis.getTrackUserLog(data)
    if (result.status === 200) {
      return result.data
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const getDownloadCompany = createAsyncThunk(logTypes.GET_DOWNLOAD_COMPANY, async (data, { rejectWithValue }) => {
  try {
    const result = await logApis.getDownloadCompany(data)
    if (result.status === 200) {
      return result.data
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const getDownloadInspection = createAsyncThunk(
  logTypes.GET_DOWNLOAD_INSPECTION,
  async (data, { rejectWithValue }) => {
    try {
      const result = await logApis.getDownloadInspection(data)
      if (result.status === 200) {
        return result.data
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getDownloadUser = createAsyncThunk(logTypes.GET_DOWNLOAD_USER, async (data, { rejectWithValue }) => {
  try {
    const result = await logApis.getDownloadUser(data)
    if (result.status === 200) {
      return result.data
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})
export const getDownloadElevation = createAsyncThunk(
  logTypes.GET_DOWNLOAD_ELEVATION,
  async (data, { rejectWithValue }) => {
    try {
      const result = await logApis.getDownloadElevation(data)
      if (result.status === 200) {
        return result.data
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
export const getDownloadImage = createAsyncThunk(logTypes.GET_DOWNLOAD_IMAGE, async (data, { rejectWithValue }) => {
  try {
    const result = await logApis.getDownloadImage(data)
    if (result.status === 200) {
      return result.data
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})
export const getDownloadTrackUser = createAsyncThunk(
  logTypes.GET_DOWNLOAD_TRACK_USER,
  async (data, { rejectWithValue }) => {
    try {
      const result = await logApis.getDownloadTrackUser(data)
      if (result.status === 200) {
        return result.data
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getImageLog = createAsyncThunk(logTypes.GET_IMAGE_LOG, async (data, { rejectWithValue }) => {
  try {
    let result = null
    if (data.type === 'Drone') {
      result = await logApis.getImageDroneLog(data.id, data.options)
      return result.data
    } else if (data.type === 'Manual') {
      result = await logApis.getImageManualLog(data.id, data.options)
    } else if (data.type === 'CloseRange') {
      result = await logApis.getImageCloseRangeLog(data.id, data.options)
    }
    if (result.status === 200) {
      return result.data
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})
