import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Form, Formik, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'

// @antd
import { ExclamationCircleOutlined } from '@ant-design/icons'

// assets

// actions
import { forgetPassword } from '../../store/thunks/userThunk'
import LoadingSpinner from '../Common/loading-spinner/LoadingSpinner'
import AuthLayout from '../Layout/AuthLayout'

function ForgetPassword() {
  const dispatch = useDispatch()
  const [submitting, setSubmitting] = useState(false)
  const [checkingEmail, setCheckingEmail] = useState('')
  const [sendEmailStatus, setSendEmailStatus] = useState(false)

  const initialValues = {
    email: ''
  }

  const onSubmit = async (values) => {
    //otp check
    setSubmitting(true)
    setCheckingEmail(values.email)
    dispatch(forgetPassword(values))
      .unwrap()
      .then(() => {
        // handle result here
        setSendEmailStatus(true)
        setSubmitting(false)
      })
      .catch(() => {
        toast.error('Something went wrong')
        setSubmitting(false)
      })
  }

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid Email').required('Email can not be blank')
  })

  return (
    <>
      {submitting && <LoadingSpinner />}
      <AuthLayout>
        {!sendEmailStatus ? (
          <div className="px-10 pt-4 pb-4 mb-4">
            <p className="my-5 mb-2 text-3xl font-bold">Reset Your Password</p>
            <div className="mb-4">
              <label className="block mb-4 text-sm font-medium text-primary">
                Forgot your password? <br />
                Please enter your email address to receive an email with a link to reset your password.
              </label>
              <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                {(formik) => {
                  return (
                    <Form>
                      <label className="block mb-2 text-sm font-bold text-primary" htmlFor="username">
                        Email <span className="text-red-500">*</span>
                      </label>
                      <Field
                        type="text"
                        id="email"
                        placeholder="Enter your email address"
                        className="w-full px-3 py-2 leading-tight text-primary border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                        name="email"
                        onChange={(e) => {
                          formik.setFieldValue('email', e.target.value.trimStart().toLowerCase())
                        }}
                      />
                      <ErrorMessage name="email">
                        {(errMsg) => (
                          <div className="text-red-500 mt-1 flex items-center">
                            <ExclamationCircleOutlined />
                            <span className="ml-1">{errMsg}</span>
                          </div>
                        )}
                      </ErrorMessage>
                      <div className="flex items-center justify-between mt-4">
                        <button
                          className="w-full px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline"
                          type="submit"
                        >
                          RESET PASSWORD
                        </button>
                      </div>
                    </Form>
                  )
                }}
              </Formik>
            </div>
          </div>
        ) : (
          <>
            <div className="px-10 pt-4 pb-4 mb-4">
              <p className="my-5 mb-4 text-3xl font-bold">Password Reset Request Sent</p>
              <label className="block mb-4 text-sm font-medium text-primary">
                A password reset message has been sent to your {checkingEmail} if it is registered
              </label>
              <label className="block mb-8 text-sm font-medium text-primary">
                If you do not receive the email within a few minutes, please refresh your inbox. Please also check your
                spam folder or other filtering tools.
              </label>
              <Link className=" text-white hover:text-white" to="/login">
                <button
                  className="w-full px-4 py-2 font-bold  bg-blue-500 rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline"
                  type="submit"
                >
                  RETURN TO LOG-IN
                </button>
              </Link>
            </div>
          </>
        )}

        <p className="text-xs text-center text-gray-500">&copy;2023 Axpara. All rights reserved.</p>
      </AuthLayout>
    </>
  )
}

export default ForgetPassword
