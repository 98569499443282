import React from 'react'

function RecordVisual({ defectListing }) {
  return (
    <>
      <ol className="toc-list" style={{ lineHeight: '30px' }}>
        <li id="appendixB" className="flex gap-4 text-lg font-bold">
          <div className="py-4">Appendix B:</div>
          <div className="py-4 text-center">Record Of Visual Inspection</div>
        </li>
        <li id="tableRecord-v">
          <div className="font-bold">
            <div className="py-4 text-center text-lg font-bold">
              Table 3: Defects Identified During Visual Inspection
            </div>
          </div>
        </li>

        {defectListing && defectListing.length > 0 ? (
          <div className="" style={{ fontSize: '16px', lineHeight: 'normal' }}>
            <table className="border-[1px] w-full table-test" style={{ fontSize: '12px' }}>
              <tr className="border-[1px]">
                <th className="border-[1px] px-1 py-[6px] w-[52px]">ID</th>
                <th className="border-[1px] px-1 py-[6px] w-[82px]">Elevation</th>
                <th className="border-[1px] px-1 py-[6px] w-[120px]">Location</th>
                <th className="border-[1px] px-1 py-[6px] w-[82px]">Element</th>
                <th className="border-[1px] px-1 py-[6px] w-[82px]">Defect</th>
                <th className="border-[1px] px-1 py-[6px] w-[75px]">Severity</th>
                <th className="border-[1px] px-1 py-[6px] w-[60px]">Photo#</th>
                <th className="border-[1px] px-1 py-[6px] w-[226px]">Reason for Defect</th>
                <th className="border-[1px] px-1 py-[6px] w-[226px]">Repair Recommended</th>
                <th className="border-[1px] px-1 py-[6px] w-[188px]">Remarks</th>
              </tr>
              {defectListing.map((d, index) => {
                return (
                  <>
                    <tr>
                      <td className="border-[1px] px-1 py-[6px] pt-2 w-[40px] text-center">{d?.orderId}</td>
                      <td className="border-[1px] px-1 py-[6px] pt-2 text-center">{d?.elevation_name}</td>
                      <td className="border-[1px] px-1 py-[6px] pt-2 text-center">
                        {d?.row} <br /> {d?.column}
                      </td>
                      <td className="border-[1px] px-1 py-[6px] pt-2 text-center">{d?.element}</td>
                      <td className="border-[1px] px-1 py-[6px] pt-2 text-center">{d?.tags}</td>
                      <td className="border-[1px] px-1 py-[6px] pt-2 text-center">{d?.cls || 'Point'}</td>
                      <td className="border-[1px] px-1 py-[6px] pt-2 text-center">{d?.photoRecord ? 'Yes' : 'No'}</td>

                      <td className="border-[1px] px-1 py-[6px] pt-2 text-center">
                        {d?.reasons?.map((reason, index) => (
                          <>
                            {reason?.label}
                            {index === d?.reasons?.length - 1 ? '' : ', '}
                          </>
                        ))}
                      </td>

                      <td className="border-[1px] px-1 py-[6px] pt-2 text-center">
                        {d?.repairs?.map((repair, index) => (
                          <>
                            {repair?.label}
                            {index === d?.repairs?.length - 1 ? '' : ', '}
                          </>
                        ))}
                      </td>

                      <td className="border-[1px] px-1 py-[6px] pt-2 text-center break-work-custom">{d?.remarks}</td>
                    </tr>
                  </>
                )
              })}
            </table>
          </div>
        ) : (
          <div className="font-bold text-center">Visual Inspection Data is not included in this report</div>
        )}
      </ol>
    </>
  )
}

export default RecordVisual
