import { createAsyncThunk } from '@reduxjs/toolkit'
import authTypes from '../../constant/store/authTypes'
import authApi from '../../apis/authApis'

export const changePassword = createAsyncThunk(authTypes.CHANGE_PASSWORD, async (data, { rejectWithValue }) => {
  try {
    const result = await authApi.changePassword(data)
    if (result.status === 200) {
      return result.data
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})
