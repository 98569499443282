import React, { useEffect, useRef, useState } from 'react'
import { onConfirm } from 'react-confirm-pro'
import LoadingAnimated from '../../assets/images/loading_new.gif'
import { CopyOutlined, DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { checkImageFormat } from '../../utils/fileUtils'
import { typeFile } from '../../constant/file'
function BuiltDrawings({ editMode, report, handleBreakLine, setValue, name, onDeleteDrawing }) {
  const [uploadImage, setUploadImage] = useState([])
  const [loading, setLoading] = useState(false)
  const appendixARefAdd = useRef([])
  const appendixARefReplace = useRef([])
  useEffect(() => {
    if (report?.drawing) {
      setUploadImage(report?.drawing)
    }
  }, [report?.drawing])

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = reject
    })

  const handleUploadImage = async (e, i) => {
    e.preventDefault()
    var files = e.target.files
    const isWrongFormatFile = checkImageFormat(e.target.files)
    if (isWrongFormatFile) {
      return onConfirm({
        title: <h3 className="text-center">{`The images must be a file of type: ${typeFile.join()}`}</h3>,
        description: <br />,

        onCancel: () => {},
        buttons: ({ onCancel }) => (
          <div className="custom-buttons">
            <button
              style={{
                background: 'rgba(37, 99, 235)',
                color: 'white'
              }}
              onClick={onCancel}
            >
              Ok
            </button>
          </div>
        )
      })
    }
    setLoading(true)
    var tmp = []
    if (files && files.length) {
      for (const file of files) {
        const base64 = await toBase64(file)
        tmp.push({
          url: base64,
          file: file,
          title: '',
          comment: ''
        })
      }
    }

    if (i === undefined) {
      setUploadImage([...uploadImage, ...tmp])
      setValue([...uploadImage, ...tmp], name)
    } else {
      const newImageupload = [...uploadImage]
      newImageupload.splice(i + 1, 0, ...[...tmp])
      setUploadImage(newImageupload)
      setValue(newImageupload, name)
    }
    setLoading(false)
  }

  const handleReplaceImage = async (e, i) => {
    e.preventDefault()
    var files = e.target.files
    const isWrongFormatFile = checkImageFormat(e.target.files)
    if (isWrongFormatFile) {
      return onConfirm({
        title: <h3 className="text-center">{`The images must be a file of type: ${typeFile.join()}`}</h3>,
        description: <br />,

        onCancel: () => {},
        buttons: ({ onCancel }) => (
          <div className="custom-buttons">
            <button
              style={{
                background: 'rgba(37, 99, 235)',
                color: 'white'
              }}
              onClick={onCancel}
            >
              Ok
            </button>
          </div>
        )
      })
    }
    setLoading(true)
    var tmp = []
    if (files && files.length) {
      for (const file of files) {
        const base64 = await toBase64(file)
        tmp = {
          url: base64,
          file: file,
          title: '',
          comment: ''
        }
      }
    }
    const newUploadImage = [...uploadImage]
    newUploadImage.splice(i, 1, tmp)
    setUploadImage(newUploadImage)
    setValue(newUploadImage, name)
    setLoading(false)
  }

  const handleTitle = (e, i) => {
    e.preventDefault()
    var tmp = [...uploadImage]
    const newItem = { ...uploadImage[i], title: e.currentTarget.value }
    tmp.splice(i, 1, newItem)
    setUploadImage(tmp)
    setValue(tmp, name)
  }

  const handleComment = (e, i) => {
    e.preventDefault()
    var tmp = [...uploadImage]
    const newItem = { ...uploadImage[i], comment: e.currentTarget.value }
    tmp.splice(i, 1, newItem)
    setUploadImage(tmp)
    setValue(tmp, name)
  }

  const handleUploadImageInput = () => {
    const fileInput = document.getElementById('uploadImageInput')
    fileInput.click()
  }
  const replaceImage = (i) => {
    appendixARefReplace?.current[i].click()
  }
  const addImage = (i) => {
    appendixARefAdd?.current[i].click()
  }

  useEffect(() => {}, [uploadImage])
  return (
    <>
      <ol className="toc-list" style={{ lineHeight: '30px' }}>
        <li id="appendixA" className="flex gap-4 text-lg font-bold">
          <div className="py-4">Appendix A:</div>
          <div className="py-4 text-center">As-Built Drawings & Building Plans</div>
        </li>
        <li className="text-lg font-bold">
          <div className="py-2">
            {uploadImage &&
              uploadImage.map((img, i) => {
                return (
                  <div key={i} className="drawing-break">
                    <input
                      type="file"
                      id={`appendixA-${i}`}
                      className="hidden"
                      ref={(el) => (appendixARefReplace.current[i] = el)}
                      onChange={(e) => handleReplaceImage(e, i)}
                    />
                    <input
                      type="file"
                      id={`appendixAAdd-${i}`}
                      className="hidden"
                      multiple
                      ref={(el) => (appendixARefAdd.current[i] = el)}
                      onChange={(e) => handleUploadImage(e, i)}
                    />
                    <div className="relative">
                      <img src={img.url} alt="img" />
                      {editMode && (
                        <div className="absolute top-4 right-4">
                          <button
                            className="flex items-center justify-center w-6 h-6 my-1 bg-gray-400 text-primary rounded-full hover:bg-orange-hover"
                            onClick={() => addImage(i)}
                          >
                            <PlusCircleOutlined style={{ fontSize: '18px' }} />
                          </button>
                          <button
                            className="flex items-center justify-center w-6 h-6 my-1 bg-gray-400 text-primary rounded-full hover:bg-orange-hover mt-2"
                            onClick={() => replaceImage(i)}
                          >
                            <CopyOutlined style={{ fontSize: '18px' }} />
                          </button>
                          <button
                            className="flex items-center justify-center w-6 h-6 my-1 bg-gray-400 text-primary rounded-full hover:bg-orange-hover mt-2"
                            onClick={() => {
                              onConfirm({
                                title: <h3>Are you sure you want to delete this image?</h3>,
                                description: <p>This process cannot be undone!</p>,
                                onSubmit: () => {
                                  onDeleteDrawing(i)
                                },
                                onCancel: () => {},
                                buttons: ({ onSubmit, onCancel }) => (
                                  <div className="custom-buttons">
                                    <button onClick={onSubmit}>YES</button>
                                    <button onClick={onCancel}>NO</button>
                                  </div>
                                )
                              })
                            }}
                          >
                            <DeleteOutlined style={{ fontSize: '18px' }} />
                          </button>
                        </div>
                      )}
                    </div>
                    <input
                      disabled={!editMode}
                      className="p-2 my-2 w-full text-center leading-8 bg-transparent"
                      maxlength={100}
                      type="text"
                      defaultValue={img.title}
                      placeholder={editMode ? 'Please title here' : ''}
                      onChange={(e) => handleTitle(e, i)}
                      value={uploadImage[i].title || ''}
                    />

                    <input
                      disabled={!editMode}
                      className="p-2 my-2 w-full text-center leading-8 bg-transparent"
                      type="text"
                      maxLength={100}
                      defaultValue={img.comment}
                      placeholder={editMode ? 'Please comment here' : ''}
                      onChange={(e) => handleComment(e, i)}
                      value={uploadImage[i].comment || ''}
                    />
                  </div>
                )
              })}

            {editMode && uploadImage.length === 0 && (
              <div className="flex flex-col items-center">
                <button
                  className="py-2 px-4 m-auto rounded-md shadow-lg cursor-pointer bg-blue-button text-white leading-8"
                  onClick={handleUploadImageInput}
                >
                  Add image
                </button>
                <input
                  id="uploadImageInput"
                  className="py-2 hidden"
                  type="file"
                  multiple
                  accept="image/*"
                  onChange={handleUploadImage}
                />
                {loading && (
                  <div className="flex flex-row items-center justify-center mt-10 h-full w-32">
                    <img alt="Loading" src={LoadingAnimated} />
                  </div>
                )}
              </div>
            )}
          </div>
        </li>
      </ol>
    </>
  )
}

export default BuiltDrawings
