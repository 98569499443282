import React, { useState, useEffect, useRef, useCallback } from 'react'
import Modal from '../../Common/Modal/index'
import { nanoid } from 'nanoid'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { BsCameraFill } from 'react-icons/bs'
import { SaveOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import imageCompression from 'browser-image-compression'
import usePrevious from '../../../hooks/usePrevious'
import { createElevation, updateElevationDetail } from '../../../store/thunks/elevationThunk'
import PreviewImage from '../../Common/PreviewImage'
import elevationTypes from '../../../constant/store/elevationTypes'
import LoadingSpinner from '../../Common/loading-spinner/LoadingSpinner'
import Cropper2 from '../../Common/cropper2/Cropper'
import { uploadElevationImage } from '../../../apis/fileApis'

function PopupEditElevation({
  closePopupEditElevation,
  id,
  buildingId,
  setPopupEditElevationDetail,
  buildingDetails,
  handleNavigateParamsUrl,
  tableOptions,
  setTableOptions
}) {
  const { loadings, errors, contents } = useSelector((state) => state.elevation)
  const [submitting, setSubmitting] = useState(false)
  const [currentFile, setCurrentFile] = useState(null)
  const [currentElevation, setCurrentElevation] = useState(null)
  const [_mounted, setMounted] = useState(false)
  const prevCreateElevation = usePrevious(loadings[elevationTypes.CREATE_ELEVATION])
  const prevUpdateElevationDetail = usePrevious(loadings[elevationTypes.UPDATE_ELEVATION_DETAIL])
  const prevUpdateAvatar = usePrevious(loadings[elevationTypes.UPDATE_ELEVATION_AVATAR])
  const listElevation = contents[elevationTypes.GET_ELEVATIONS_BUILDING]
  const dispatch = useDispatch()
  const cropperRef = useRef()
  useEffect(() => {
    const elevation = listElevation.find((elevation) => {
      return elevation.id === id
    })
    setMounted(true)
    setCurrentElevation(elevation)
    return () => {
      setMounted(false)
    }
  }, [id])

  const initialValues = {
    name: currentElevation?.name || '',
    avatar: currentElevation?.data_thumbnail.thumbnailUrl
  }

  const validationSchema = Yup.object({
    name: Yup.string().required('Please enter inspection name')
  }).shape({
    avatar: Yup.mixed().required('Avatar is required')
  })

  const handleCloseModal = () => {
    setCurrentFile(null)
    closePopupEditElevation()
  }
  const handleOpenCropper = useCallback(() => {
    cropperRef?.current?.openCropModal()
  }, [cropperRef])

  function onSubmitUpdate(values) {
    setSubmitting(true)
    const { name } = values
    const uploadFile = currentFile ? true : currentElevation?.data_thumbnail.thumbnailUrl ? false : true
    try {
      const dataReq = {
        updateElevationDetail: {
          elevation_id: currentElevation?.id,
          name: name.trim(),
          file: currentFile || currentElevation?.data_thumbnail.thumbnailUrl,
          url: currentElevation?.data_thumbnail.thumbnailUrl,
          upload: uploadFile
        }
      }
      dispatch(updateElevationDetail(dataReq.updateElevationDetail))
    } catch (err) {
      toast.error('Error when updating elevation')
    }
  }
  const onSubmitAdd = async (values) => {
    setSubmitting(true)
    const { name } = values
    try {
      const options = {
        maxSizeMB: 1,
        initialQuality: 0.6,
        maxWidthOrHeight: 1920
      }
      const dataThumbnail = {
        filename: nanoid() + currentFile?.name,
        contentType: currentFile?.type,
        key: 'elevationThumbnail',
        size: currentFile?.size
      }
      const { signedUrl } = await uploadElevationImage(dataThumbnail)
      const compressedFile = await imageCompression(currentFile, options)

      await fetch(signedUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': currentFile?.type
        },
        body: compressedFile
      })
      const newUrl2 = signedUrl.split('?')[0]
      let data_thumbnail = {
        thumbnailUrl: newUrl2
      }
      const body = {
        building_id: parseInt(buildingDetails.building.id),
        direction: parseInt(0),
        name,
        columns: parseInt(0),
        rows: parseInt(0),
        images: [],
        data_thumbnail: data_thumbnail
      }
      dispatch(createElevation(body))
    } catch (err) {
      toast.error('Error when creating elevation')
    }
  }

  useEffect(() => {
    if (prevCreateElevation && !loadings[elevationTypes.CREATE_ELEVATION]) {
      setSubmitting(false)
      handleNavigateParamsUrl({ page: 1, size: 10 }, false, `/inspections/${buildingId}`)
      if (!errors[elevationTypes.CREATE_ELEVATION]) {
        setPopupEditElevationDetail(false)
        setTableOptions({ page: 1, size: 10 })
        setCurrentFile(null)
        toast.success('Elevation Created', {
          theme: 'dark',
          autoClose: 2000
        })
      } else {
        toast.error(errors[elevationTypes.CREATE_ELEVATION], {
          theme: 'dark',
          autoClose: 2000
        })
      }
    }
  }, [errors, loadings, prevCreateElevation])
  useEffect(() => {
    if (prevUpdateElevationDetail && !loadings[elevationTypes.UPDATE_ELEVATION_DETAIL]) {
      setSubmitting(false)
      if (!errors[elevationTypes.UPDATE_ELEVATION_DETAIL]) {
        setPopupEditElevationDetail(false)
        setCurrentFile(null)
        toast.success('Elevation Updated', {
          theme: 'dark',
          autoClose: 2000
        })
      } else {
        toast.error(errors[elevationTypes.UPDATE_ELEVATION_DETAIL], {
          theme: 'dark',
          autoClose: 2000
        })
      }
    }
  }, [errors, loadings, prevUpdateElevationDetail])

  useEffect(() => {
    if (prevUpdateAvatar && !loadings[elevationTypes.UPDATE_ELEVATION_AVATAR]) {
      setSubmitting(false)
      if (!errors[elevationTypes.UPDATE_ELEVATION_AVATAR]) {
        const indexInspection = contents[elevationTypes.GET_ELEVATIONS_BUILDING].findIndex(
          (company) => company.id === currentElevation.id
        )
        setCurrentElevation(contents[elevationTypes.GET_ELEVATIONS_BUILDING][indexInspection])
        toast.success('Avatar updated', {
          theme: 'dark',
          autoClose: 2000
        })
      } else {
        toast.error(errors[elevationTypes.UPDATE_ELEVATION_AVATAR], {
          theme: 'dark',
          autoClose: 2000
        })
      }
    }
  }, [errors, loadings, prevUpdateAvatar])

  return (
    <div>
      {submitting && <LoadingSpinner />}

      {_mounted && (
        <>
          <Modal wrapper="w-auto max-w-md mx-auto" onClose={handleCloseModal}>
            <div className="flex items-start justify-between p-3 border-b border-solid rounded-t border-slate-200">
              <div className="text-xl text-primary font-semibold ">
                {currentElevation ? 'Edit Elevation' : 'Create Elevation'}
              </div>
            </div>
            <div className="relative flex flex-auto p-6">
              <div className="w-full max-w-lg">
                <Formik
                  initialValues={initialValues}
                  onSubmit={currentElevation ? onSubmitUpdate : onSubmitAdd}
                  validationSchema={validationSchema}
                >
                  {({ values, setFieldValue, isSubmitting, errors }) => {
                    return (
                      <Form className="w-full max-w-lg ">
                        <div className="flex flex-wrap mb-6 -mx-3 max-h-[68vh] overflow-y-auto overflow-x-hidden">
                          <div className="w-full px-3">
                            <div className="mb-6">
                              <div className="relative mt-1 rounded-md group h-[120px] w-[160px] shadow-avatar">
                                {currentFile ? (
                                  <PreviewImage file={currentFile} imgClass="w-full h-full ml-0" />
                                ) : currentElevation?.data_thumbnail.thumbnailUrl ? (
                                  <div
                                    className="w-full h-full rounded-md ml-0"
                                    style={{
                                      backgroundImage: `url(${currentElevation?.data_thumbnail.thumbnailUrl})`,
                                      display: 'block',
                                      backgroundRepeat: 'no-repeat',
                                      backgroundSize: 'cover',
                                      backgroundPositionX: 'center',
                                      backgroundPositionY: '10%'
                                    }}
                                  ></div>
                                ) : (
                                  <img
                                    className="w-full h-full ml-0 "
                                    src={process.env.REACT_APP_AWS_BLANK_IMAGE}
                                    alt="avt"
                                  />
                                )}
                                <div
                                  className="flex justify-center items-center absolute z-20 bottom-1 right-1 w-[32px] h-[32px] bg-gray-300 rounded-full cursor-pointer"
                                  onClick={handleOpenCropper}
                                >
                                  <BsCameraFill style={{ fontSize: '20px', color: 'black' }} />
                                </div>
                                <div className="absolute z-10 bottom-0 bg-gray-300 opacity-10 w-[160px] h-[120px]  hidden group-hover:block"></div>
                              </div>
                              <Cropper2
                                ref={cropperRef}
                                name="avatar"
                                cropShape="rect"
                                onCompleteCropper={(file) => {
                                  setCurrentFile(file)
                                  setFieldValue('avatar', file)
                                  cropperRef?.current?.closeCropModal()
                                }}
                                setLoading={setSubmitting}
                              />
                              <ErrorMessage name="avatar">
                                {(errMsg) => (
                                  <div className="text-red-500 mt-1 flex items-center">
                                    <ExclamationCircleOutlined />
                                    <span className="ml-1">{errMsg}</span>
                                  </div>
                                )}
                              </ErrorMessage>
                            </div>
                          </div>
                          <div className="w-full mb-3 px-3">
                            <label
                              className="block mb-2 text-xs font-bold tracking-wide text-primary uppercase"
                              htmlFor="building_name"
                            >
                              Elevation Name
                            </label>
                            <Field
                              type="text"
                              id="name"
                              placeholder="Name"
                              className="block w-full px-4 py-3 leading-tight text-primary bg-gray-50 border rounded appearance-none focus:outline-none focus:bg-white"
                              name="name"
                            />
                            <ErrorMessage name="name">
                              {(errMsg) => (
                                <div className="text-red-500 mt-1 flex items-center">
                                  <ExclamationCircleOutlined />
                                  <span className="ml-1">{errMsg}</span>
                                </div>
                              )}
                            </ErrorMessage>
                            {buildingDetails?.locked && (
                              <div className="text-red-500 mt-4 flex items-center">
                                <ExclamationCircleOutlined />
                                <span className="ml-1">The report is locked</span>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="flex items-center justify-between p-6 border-t border-solid rounded-b border-slate-200">
                          <button
                            className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear bg-gray-500 rounded shadow outline-none active:bg-gray-600 hover:shadow-lg focus:outline-none"
                            type="button"
                            onClick={() => {
                              setCurrentFile(null)
                              closePopupEditElevation()
                            }}
                          >
                            Close
                          </button>
                          <div className="flex gap-4 items-center">
                            <button
                              className={
                                buildingDetails?.locked === true
                                  ? `flex items-center opacity-50 px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear bg-red-500 rounded shadow outline-none active:bg-red-600 hover:shadow-lg focus:outline-none`
                                  : 'flex items-center px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear bg-red-500 rounded shadow outline-none active:bg-red-600 hover:shadow-lg focus:outline-none'
                              }
                              type="submit"
                              disabled={buildingDetails?.locked === true}
                              // onClick={onSubmitUpdate}
                            >
                              <span className="mr-2">Submit</span> <SaveOutlined />
                            </button>
                          </div>
                        </div>
                      </Form>
                    )
                  }}
                </Formik>
              </div>
            </div>
          </Modal>
        </>
      )}
    </div>
  )
}

export default PopupEditElevation
