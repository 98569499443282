import React from 'react'
import { Tooltip } from 'antd'
import PropTypes from 'prop-types'
import { color } from '../../../constant/styled'
function CommonTooltip({ children, title, align = 'center', placement = 'top', ...rest }) {
  return (
    <Tooltip
      overlayInnerStyle={{ padding: '8px 16px', borderRadius: 6 }}
      title={title}
      placement={placement}
      align={align}
      color={color}
      {...rest}
    >
      {children}
    </Tooltip>
  )
}

CommonTooltip.propTypes = {
  children: PropTypes.element,
  title: PropTypes.string.isRequired,
  icon: PropTypes.element,
  color: PropTypes.string,
  placement: PropTypes.string,
  align: PropTypes.string
}

export default CommonTooltip
