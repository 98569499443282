import { createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import company from '../../apis/companyApis'
import { uploadElevationImage } from '../../apis/fileApis'
import companyTypes from '../../constant/store/companyTypes'

import imageCompression from 'browser-image-compression'
import { nanoid } from 'nanoid'

// thunk company
export const getCompanies = createAsyncThunk(companyTypes.GET_COMPANIES, async (data, { rejectWithValue }) => {
  try {
    const result = await company.getCompanies(data)
    return result
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const getCompanyActive = createAsyncThunk(companyTypes.GET_COMPANY_ACTIVE, async (data, { rejectWithValue }) => {
  try {
    const result = await company.getCompanyActive()
    return result
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const createCompany = createAsyncThunk(
  companyTypes.CREATE_COMPANY,
  async (data, { rejectWithValue, dispatch }) => {
    try {
      let resultUpload = null
      const options = {
        maxSizeMB: 1,
        initialQuality: 0.6,
        maxWidthOrHeight: 1920
      }
      if (data.isUpload) {
        // resultUpload = await uploadFile(data.file)
        const dataThumbnail = {
          filename: nanoid() + data.file.name,
          contentType: data.file.type,
          key: 'company'
        }
        const { signedUrl } = await uploadElevationImage(dataThumbnail)
        const compressedFile = await imageCompression(data.file, options)
        resultUpload = signedUrl
        await fetch(signedUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': data.file.type
          },
          body: compressedFile
        })
      }
      const dataReq = {
        name: data.name,
        address: data.address,
        email: data.email,
        telephone: data.telephone,
        fax: data.fax,
        uen: data.uen,
        thumbnail: resultUpload ? resultUpload.split('?')[0] : process.env.REACT_APP_AWS_BLANK_IMAGE
      }
      const result = await company.createCompany(dataReq)
      if (result.status === 201) {
        return result.data
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const updateCompany = createAsyncThunk(
  companyTypes.UPDATE_COMPANY,
  async (data, { rejectWithValue, dispatch }) => {
    try {
      var resultUpload = null
      const options = {
        maxSizeMB: 1,
        initialQuality: 0.6,
        maxWidthOrHeight: 1920
      }
      if (data.upload === true) {
        // resultUpload = await uploadFile(data.file)
        const dataThumbnail = {
          filename: nanoid() + data.file.name,
          contentType: data.file.type,
          key: 'company'
        }
        const { signedUrl } = await uploadElevationImage(dataThumbnail)
        const compressedFile = await imageCompression(data.file, options)
        resultUpload = signedUrl
        await fetch(signedUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': data.file.type
          },
          body: compressedFile
        })
      }
      const dataReq = {
        name: data.name,
        address: data.address,
        thumbnail: resultUpload ? resultUpload.split('?')[0] : data.file,
        id: data.id,
        fax: data.fax,
        uen: data.uen,
        telephone: data.telephone,
        email: data.email
      }
      const result = await company.updateCompany(dataReq)
      return result
    } catch (error) {
      toast.error(error.message)
      return rejectWithValue(error)
    }
  }
)
export const updateCompanyAvatar = createAsyncThunk(
  companyTypes.UPDATE_COMPANY_AVATAR,
  async (data, { rejectWithValue, dispatch }) => {
    try {
      var resultUpload = null
      const options = {
        maxSizeMB: 1,
        initialQuality: 0.6,
        maxWidthOrHeight: 1920
      }
      if (data.upload === true) {
        // resultUpload = await uploadFile(data.file)
        const dataThumbnail = {
          filename: nanoid() + data.file.name,
          contentType: data.file.type,
          key: 'company'
        }
        const { signedUrl } = await uploadElevationImage(dataThumbnail)
        const compressedFile = await imageCompression(data.file, options)
        resultUpload = signedUrl
        await fetch(signedUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': data.file.type
          },
          body: compressedFile
        })
      }
      const dataReq = {
        name: data.name,
        address: data.address,
        thumbnail: resultUpload ? resultUpload.split('?')[0] : data.file,
        id: data.id,
        fax: data.fax,
        uen: data.uen,
        telephone: data.telephone,
        email: data.email
      }
      const result = await company.updateCompany(dataReq)
      return result
    } catch (error) {
      toast.error(error.message)
      return rejectWithValue(error)
    }
  }
)

export const deleteCompany = createAsyncThunk(
  companyTypes.DELETE_COMPANY,
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const result = await company.deleteCompany(data)
      if (result.status === 200 || result.status === 201) {
        return data
      }
      return result
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const revertDeleteCompany = createAsyncThunk(
  companyTypes.REVERT_DELETE_COMPANY,
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const result = await company.revertDeleteCompany(data.id)
      if (result.status === 200) {
        toast.success(result.data, {
          theme: 'dark',
          autoClose: 2000
        })

        return { id: data.id }
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
