import React from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'

function Map({ position, selected }) {
  return (
    <div style={{ maxWidth: '70vw', maxHeight: '70vh' }}>
      <ScrollContainer
        hideScrollbars={false}
        vertical={true}
        className="scroll-container custom-scroll-bar"
        style={{ maxHeight: '60vh' }}
      >
        {position.map((arrRow, idx) => (
          <div key={idx} className="flex flex-col items-center w-max justify-start">
            <div className="flex flex-row justify-start px-5 py-5 ">
              {arrRow.map((col, idx) => (
                <div
                  className={`flex bg-transparent flex-row items-center justify-center h-8 w-12 px-1 py-3 mx-1 text-white cursor-pointer ${
                    selected && col.order === selected.order ? 'bg-orange-hover' : 'bg-transparent'
                  } border-2 border-black`}
                  key={idx}
                ></div>
              ))}
            </div>
          </div>
        ))}
      </ScrollContainer>
    </div>
  )
}

export default Map
