import React, { useState, useEffect } from 'react'
import CustomRegionLabel from '../../CustomLabelAnnotation/CustomRegionLabel'
import { useLocation } from 'react-router-dom'
import Map from '../Map'
import { toast } from 'react-toastify'
import ReactImageAnnotate from 'react-image-annotate-custom-region-edit-label-ver-2'
import { LeftCircleOutlined, EnvironmentOutlined, RightCircleOutlined, CopyOutlined } from '@ant-design/icons'
import { TbClockRecord } from 'react-icons/tb'
import useCloseRange from '../../../../hooks/useCloseRange'
import useAuth from '../../../../hooks/useAuth'
import { makeRoles, formatMetadata } from '../../../../utils/common'
import Modal from '../../../Common/Modal'
import { color } from '../../../../constant/styled'

const ImagePopUp = ({
  popUpPosition,
  position,
  check,
  hideSave,
  region,
  listImgs,
  selected,
  status,
  visualStatus,
  onPrevious,
  onNext,
  onExit,
  onCreateAnnotate,
  onCheckDoneAnnotation,
  onReplaceUpload,
  setShowLogDrawer,
  handleNavigateParamsUrl
}) => {
  const location = useLocation()
  const { closeRangeDefectId } = useCloseRange()
  const { user } = useAuth()
  const { role } = user?.user || {}
  const [metadata, setMetadata] = useState({})
  const [showMap, setShowMap] = useState(false)
  const [listUrl, setListUrl] = useState([])
  const [hasNext, setNext] = useState(true)
  const [hasPrevious, setPrevious] = useState(true)
  const [checked, setChecked] = useState(check && check === true ? true : false)
  const [loadingRender, setLoadingRender] = useState(false)

  useEffect(() => {
    const listNewWithoutBlank = listImgs.filter((i) => i.url !== process.env.REACT_APP_AWS_BLANK_IMAGE)
    const findIndex = listNewWithoutBlank.map((i) => i.order).indexOf(selected.order)
    if (findIndex === 0) {
      setPrevious(false)
      setNext(true)
    } else if (findIndex === listNewWithoutBlank.length - 1) {
      setPrevious(true)
      setNext(false)
    } else {
      setPrevious(true)
      setNext(true)
    }
    const anno = [
      {
        src: selected ? selected?.urlOriginal || selected?.url : null,
        name: '',
        regions: region.map((item) => {
          if (selected?.closeRangeStatus === true) {
            return { ...item, closeRange: true }
          } else {
            return { ...item }
          }
        })
      }
    ]
    setListUrl(anno)
    setLoadingRender(false)
    setMetadata(formatMetadata(selected?.metadata))
  }, [selected])

  useEffect(() => {
    setChecked(check && check === true ? true : false)
  }, [check, selected])

  const handleCloseModal = () => {
    setShowMap(false)
  }

  const renderItemLocation = () => {
    return (
      <>
        {showMap && (
          <Modal
            wrapper="flex items-center justify-center overflow-x-hidden overflow-y-auto"
            onClose={handleCloseModal}
          >
            <div className="relative flex flex-col w-max">
              {/*header*/}
              <div className="flex items-start justify-between p-3 border-b border-solid rounded-t border-slate-200">
                <h3 className="text-xl text-primary font-semibold">Where you are now</h3>
              </div>
              {/*body*/}
              <div className="relative flex flex-auto p-6">
                <div className="flex flex-col items-start justify-start ">
                  <Map position={position} selected={selected} />
                </div>
              </div>
              {/*footer*/}
              <div className="flex items-center justify-end p-4 border-t border-solid rounded-b border-slate-200">
                <button
                  className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear bg-gray-500 rounded shadow outline-none active:bg-gray-600 hover:shadow-lg focus:outline-none"
                  type="button"
                  onClick={handleCloseModal}
                >
                  Close
                </button>
              </div>
            </div>
          </Modal>
        )}
      </>
    )
  }

  return (
    <Modal onClose={() => onExit('')} zIndex="z-[35]">
      <>
        <div className="fixed top-16 left-32 h-5/6 w-10/12">
          {status !== 'Completed' && !visualStatus && checked === true && (
            <input
              type="checkbox"
              className="absolute top-0 -right-10 w-6 h-6"
              onChange={(e) => {
                onCheckDoneAnnotation(e.target.checked, selected.order)
                setChecked(e.target.checked)
              }}
              checked
            />
          )}
          {!closeRangeDefectId && (
            <>
              {status !== 'Completed' && !visualStatus && checked === false && (
                <input
                  type="checkbox"
                  className="absolute top-0 -right-10 w-6 h-6"
                  onChange={(e) => {
                    onCheckDoneAnnotation(e.target.checked, selected.order)
                    setChecked(e.target.checked)
                  }}
                  checked={false}
                />
              )}
              {status !== 'Completed' && !visualStatus && (
                <h3 className="text-xs text-white font-semibold absolute top-7 -right-24">Image Checked</h3>
              )}
            </>
          )}

          {listUrl.length && !loadingRender && (
            <>
              {hasPrevious && (
                <button
                  className="absolute top-64 -left-20 mx-3 text-5xl text-white"
                  onClick={() => {
                    onPrevious()
                    setLoadingRender(true)
                  }}
                >
                  <LeftCircleOutlined />
                </button>
              )}

              <ReactImageAnnotate
                taskTitle="Annotate Defect"
                taskDescription={
                  metadata &&
                  `
## ** Longitude:** ${metadata.longitude} 
## ** Latitude:** ${metadata.latitude}
## ** Altitude:** ${metadata.altitude}`
                }
                hideSave={hideSave}
                key={selected?.urlOriginal || selected.url}
                hidePrev={true}
                hideNext={true}
                editMask={([...makeRoles([6, 7, 9])].includes(role) && true) || false}
                enabledTools={
                  hideSave
                    ? []
                    : selected.closeRangeStatus === true
                    ? [
                        'select',
                        `${![...makeRoles([5, 9])].includes(role) && 'create-box'}`,
                        `${
                          selected.closeRangeStatus === true && ![...makeRoles([5, 9])].includes(role) && 'create-point'
                        }`
                      ]
                    : ['select', `${![...makeRoles([5, 9])].includes(role) && 'create-box'}`]
                }
                RegionEditLabel={CustomRegionLabel}
                images={listUrl}
                onExit={(x) => {
                  if (selected.new) {
                    toast.error("Image hasn't been saved!")
                  } else {
                    let checkOutside = false

                    x.images[0].regions.forEach((r) => {
                      if (!r.fillColor && (r.x <= 0 || r.x >= 1 || r.y <= 0 || r.y >= 1.0)) {
                        checkOutside = true
                      }
                    })
                    if (checkOutside) {
                      toast.error('Create defect box inside image')
                    } else {
                      onCreateAnnotate(x.images[0].regions)
                    }
                  }
                }}
              />

              {hasNext && (
                <button
                  className="absolute top-64 -right-20 mx-3 text-5xl text-white"
                  onClick={() => {
                    onNext()
                    setLoadingRender(true)
                  }}
                >
                  <RightCircleOutlined />
                </button>
              )}
            </>
          )}

          <div className="absolute bottom-0 -right-28 text-white flex flex-col gap-3">
            {(selected?.thermalUrl || selected?.thermalUrlOrigin) && (
              <button onClick={() => onReplaceUpload(selected)}>
                <p className="flex items-center gap-1 cursor-pointer">
                  <CopyOutlined style={{ color: color.red }} />
                  <span className="leading-4 font-bold text-red-500">Thermal</span>
                </p>
              </button>
            )}

            <button
              onClick={() => {
                // handleNavigateParamsUrl({ page: 1, size: 10, type: "Drone", id: selected.annoId }, true, location.pathname)
                setShowLogDrawer({ type: 'Drone', id: selected.annoId, title: 'Drone image log' })
              }}
            >
              <p className="flex items-center gap-1 cursor-pointer">
                <TbClockRecord /> <span className="leading-4">Log</span>
              </p>
            </button>
            <button onClick={() => setShowMap(true)}>
              <p className="flex items-center gap-1 cursor-pointer">
                <EnvironmentOutlined /> <span className="leading-4">Where you at</span>
              </p>
            </button>
          </div>
        </div>

        {renderItemLocation()}
      </>
    </Modal>
  )
}

export default ImagePopUp
