import React, { useRef } from 'react'
import Textarea from '../Report/Textarea'

function Conclusion({ report, editMode, setValue, handleBreakLine }) {
  const refConclusion = useRef()
  return (
    <>
      <ol className="toc-list" style={{ lineHeight: '30px' }}>
        <li id="CONCLUSIONVALUE">
          <div className="font-bold">
            <h1 className="font-bold title">5.0 CONCLUSION</h1>
          </div>
        </li>
        {/* <ol> */}
        <li id="ConclusionPage">
          <div>
            <h2 className="font-bold pt-5 title">5.1 Conclusion</h2>
          </div>
        </li>
        <li id="Conclusion-container-div">
          <div className="py-2">
            <Textarea
              refTextArea={refConclusion}
              id="refConclusion"
              values={report?.conclusion}
              name="conclusion"
              setValue={setValue}
              editMode={editMode}
            />
            <div id="refConclusion-div-container" className={editMode ? 'hidden' : ''}>
              <div id={`refConclusion-div`}>{handleBreakLine(report?.conclusion)}</div>
            </div>
          </div>
        </li>
      </ol>
    </>
  )
}

export default Conclusion
