import React, { useEffect } from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'
import ImageItem from './ImageItem'

function ImageContainer({
  listImgs,
  status,
  visualStatus,
  position,
  selected,
  listCountColor,
  onSelected,
  onAddDummy,
  onChangeUpload,
  onReplaceUpload,
  onDelete,
  direction,
  handleThumbnail,
  onChangeStatusCloseRangeDrone
}) {
  useEffect(() => {
    handleThumbnail()
  }, [])

  var defects = []
  let closeRangeDefects = []

  return (
    <div className="flex flex-col px-6 py-1 bg-white border border-gray-300 rounded shadow-lg bg-opacity-90 bg-clip-padding">
      <div className="flex max-h-screen">
        <ScrollContainer
          hideScrollbars={false}
          vertical={true}
          className="scroll-container custom-scroll-bar"
          style={{
            paddingTop: '1rem',
            paddingBottom: '1rem'
          }}
        >
          <div
            className={`flex gap-1 sticky top-0  p-2  ${
              parseInt(direction) === 0 || parseInt(direction) === 1 ? 'flex-col' : 'flex-row'
            }`}
          >
            {position.map((arrRow, idx) => (
              <div key={idx} className="flex flex-row h-full justify-start">
                <div
                  className={`flex justify-start gap-1 h-full ${
                    parseInt(direction) === 0 || parseInt(direction) === 1
                      ? 'flex-row pr-5'
                      : parseInt(direction) === 2
                      ? 'flex-col'
                      : 'flex-col-reverse'
                  }`}
                >
                  {arrRow.map((col, idx) => {
                    if (!col.order) {
                      return (
                        <div
                          key={idx}
                          className="relative flex flex-row items-center justify-center h-48 pr-1 bg-transparent cursor-pointer w-60"
                        ></div>
                      )
                    }
                    let findIndex = listCountColor.map((i) => i.url).indexOf(col.url)
                    if (findIndex !== -1) {
                      defects = listCountColor[findIndex].defects
                      closeRangeDefects = listCountColor[findIndex].closeRangeDefects
                    } else {
                      defects = []
                      closeRangeDefects = []
                    }
                    return (
                      <ImageItem
                        listImgs={listImgs}
                        data={col}
                        type="drone"
                        status={status}
                        visualStatus={visualStatus}
                        key={idx}
                        selected={selected}
                        closeRangeDefects={closeRangeDefects}
                        defects={defects}
                        onSelected={onSelected}
                        onAddDummy={onAddDummy}
                        onChangeUpload={onChangeUpload}
                        onReplaceUpload={onReplaceUpload}
                        onDelete={onDelete}
                        onChangeStatusCloseRangeDrone={onChangeStatusCloseRangeDrone}
                      />
                    )
                  })}
                </div>
              </div>
            ))}
          </div>
        </ScrollContainer>
      </div>
    </div>
  )
}

export default ImageContainer
