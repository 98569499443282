import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'
import { format } from 'date-fns'

// hocs
import useAuth from '../../../hooks/useAuth'

import usePrevious from '../../../hooks/usePrevious'
// actions
import { getImageLog } from '../../../store/thunks/logThunk'

// commons
import Table from '../../Common/table2'
import { isEmptyObject, makeRoles } from '../../../utils/common'

// assets

import { FieldTimeOutlined } from '@ant-design/icons'

// constants
import SkeletonViewer from '../../Common/skeleton-viewer'
import NotAvailable from '../../Common/NotAvailable'
import logTypes from '../../../constant/store/logTypes'

const InspectionLog = ({ type, id }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { user } = useAuth()
  const { role } = user?.user || {}
  const [_mounted, setMouted] = useState(false)
  const {
    contents: contentsLogs,
    errors: errorsLogs,
    loadings: loadingsLogs,
    paginations: paginationlog
  } = useSelector((state) => state.log)

  const [tableOptions, setTableOptions] = useState({ page: 1, size: 10 })
  const [renderingProcess, setRenderingProcess] = useState({
    loading: true,
    error: false
  })
  const logList = contentsLogs[logTypes.GET_IMAGE_LOG]
  const loadingGetLog = loadingsLogs[logTypes.GET_IMAGE_LOG]
  const paginationsOptions = paginationlog[logTypes.GET_IMAGE_LOG]
  const prevGetLogs = usePrevious(loadingsLogs[logTypes.GET_IMAGE_LOG])

  const UserColumns = [
    {
      Header: 'Action',
      accessor: 'action',
      width: 240,
      disableSortBy: true
    },
    {
      Header: 'Userwww',
      accessor: 'user_name',
      width: 160,
      disableSortBy: true
    },
    {
      Header: 'Date Created',
      accessor: 'created_at',
      id: 'created_at',
      minWidth: 100,
      Cell: ({ value }) => {
        const date = format(new Date(value), 'dd/MM/yyyy')
        return (
          <div className="relative">
            <div className="flex items-center">
              <FieldTimeOutlined style={{ fontSize: '18px' }} className="group:hover:text-white" />
              <span className="ml-2">{date}</span>
            </div>
          </div>
        )
      }
    }
  ]

  useMemo(() => {
    setMouted(true)
  }, [])

  // get data table

  // const handleNavigateParamsUrl = (params, replace = false, pathname, state = null) => {
  //   navigate(
  //     {
  //       pathname: pathname,
  //       search: createSearchParams({
  //         ...(type ? { type: location.search.type } : {}),
  //         ...(id ? { type: location.search.id } : {}),
  //         page: params.page,
  //         size: params.size,
  //         ...params
  //       }).toString()
  //     },
  //     { replace, state }
  //   )
  // }

  // useEffect(() => {
  //   if (!location.search) {
  //     handleNavigateParamsUrl({ page: 1, size: 10 }, true, `/elevation/326?type=${type}&id=${id}`)
  //   }
  // }, [])

  // useEffect(() => {
  //   if (_mounted && location.search) {
  //     let params = Object.fromEntries(new URLSearchParams(window.location.search))
  //     delete params.type
  //     delete params.id
  //     const options = {
  //       ...params,
  //       ...(params.page ? { page: params.page } : { page: 1 }),
  //       ...(params.size && params.size >= 10 ? { size: params.size } : { size: 10 })
  //     }
  //     setTableOptions(options)
  //   }
  // }, [location.search])

  useEffect(() => {
    const get = async () => {
      if (_mounted) {
        if ([...makeRoles([1, 2, 6, 7])].includes(role)) {
          if ([...makeRoles([1, 2, 6, 7])].includes(role)) {
            dispatch(getImageLog({ type, id, options: tableOptions }))
          }
        }
      }
    }
    get()
  }, [dispatch, user, tableOptions])

  // end get data table

  useEffect(() => {
    return () => {
      setMouted(false)
    }
  }, [])

  useEffect(() => {
    if (prevGetLogs && !loadingsLogs[logTypes.GET_IMAGE_LOG]) {
      if (!errorsLogs[logTypes.GET_IMAGE_LOG]) {
        setRenderingProcess((prev) => ({ ...prev, error: false, loading: false }))
      } else {
        setRenderingProcess((prev) => ({ ...prev, error: true, loading: false }))
      }
    }
  }, [errorsLogs, loadingsLogs, prevGetLogs, dispatch])

  const renderSkeleton = () => {
    return (
      <div style={{ width: '100%', margin: '0 auto', marginTop: '16px' }}>
        <div className="flex items-center flex-wrap gap-2 md:gap-6">
          <SkeletonViewer borderRadius={16} className="h-8 md:h-10 w-32 md:w-44" />
          <SkeletonViewer borderRadius={16} className="h-8 md:h-10 w-32 md:w-72" />
          <div className="sm:ml-auto">
            <SkeletonViewer borderRadius={16} className="h-8 md:h-10 w-[90vw] sm:w-40 md:w-56 lg:w-72" />
          </div>
        </div>
        <div>
          <SkeletonViewer className="mt-2" height={60} width="100%" />
          <div className="block overflow-hidden mt-1" style={{ height: '58vh' }}>
            {Array.from({ length: 10 }, function (v, k) {
              return k
            }).map((i) => (
              <div key={i}>
                <SkeletonViewer className="mt-2" height={52} borderRadius={12} width="100%" />
              </div>
            ))}
          </div>
        </div>
        <div className="flex justify-center">
          <SkeletonViewer className="h-8 md:h-10 mt-4" width={300} />
        </div>
      </div>
    )
  }

  return (
    <div>
      {renderingProcess.loading ? (
        <>{renderSkeleton()}</>
      ) : renderingProcess.error ? (
        <NotAvailable content={errorsLogs[logTypes.GET_IMAGE_LOG]} />
      ) : (
        <Table
          columns={UserColumns}
          data={logList}
          loading={loadingGetLog}
          tableOptions={tableOptions}
          paginations={paginationsOptions}
          setTableOptions={setTableOptions}
          cellHeight={52}
          filterDate
          heightTable={'58vh'}
        ></Table>
      )}
    </div>
  )
}

export default InspectionLog
