import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useLocation, useNavigate, createSearchParams } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import { isEmptyObject, makeRoles } from '../../utils/common'
// actions
import { getUsersCompany } from '../../store/thunks/userThunk'
// commons
import Table from '../Common/table2'
import StatusButton from '../Common/status'
import { color } from '../../constant/styled'
// assets
import userTypes from '../../constant/store/userTypes'
import usePrevious from '../../hooks/usePrevious'
import NotAvailable from '../Common/NotAvailable'
import SkeletonViewer from '../Common/skeleton-viewer'
import PaddingAuto from '../Layout/PaddingAuto'

const ListUser = () => {
  const location = useLocation()
  const params = useParams()
  const navigate = useNavigate()

  const {
    contents: contentsUser,
    loadings: loadingsUser,
    paginations: paginationsUser,
    errors: errorsUser
  } = useSelector((state) => state.user)
  const { user } = useAuth()
  const { role } = user?.user || {}
  const dispatch = useDispatch()
  const [tableOptions, setTableOptions] = useState({ page: 1, size: 10 })
  const [_mounted, setMouted] = useState(false)
  const [renderingProcess, setRenderingProcess] = useState({
    loading: true,
    error: false
  })
  const prevUserGetFetching = usePrevious(loadingsUser[userTypes.GET_USERS_COMPANY])
  const usersCompany = contentsUser[userTypes.GET_USERS_COMPANY]
  const loadingUser = loadingsUser[userTypes.GET_USERS_COMPANY]
  const paginationsOptions = paginationsUser[userTypes.GET_USERS_COMPANY]
  const UserColumns = [
    {
      Header: 'Username',
      accessor: 'username',
      id: 'username',
      width: 180
    },
    {
      Header: 'Email',
      accessor: 'email',
      id: 'email',
      width: 180
    },
    {
      Header: 'Role',
      accessor: 'role_name',
      id: 'role',
      width: 180,
      Cell: ({ value }) => {
        return (
          <>
            {value === 'Project'
              ? 'Claritas Project'
              : value === 'Admin'
              ? 'Claritas Admin'
              : value === 'Reviewer'
              ? 'Claritas Reviewer'
              : value === 'AI'
              ? 'Claritas AI'
              : value === 'CP'
              ? 'Claritas CP'
              : value === 'Claritas Image'
              ? 'Claritas Image'
              : value === 'ClientAdmin'
              ? 'Admin'
              : value === 'ClientProject'
              ? 'Project'
              : value === 'ClientReviewer'
              ? 'Reviewer'
              : value === 'ClientImage'
              ? 'Image'
              : value === 'ClientCP'
              ? 'CP'
              : value === 'Image'
              ? 'Image'
              : value === 'ClientFI'
              ? 'FI'
              : 'Guest'}
          </>
        )
      }
    },
    {
      Header: 'Status',
      accessor: 'status',
      id: 'status',
      width: 180,
      Cell: ({ value }) => {
        return (
          <>
            {value === 'Active' ? (
              <StatusButton color={color.green} bgColor={color.greenLight} title="Active" />
            ) : value === 'Inactive' ? (
              <StatusButton color={color.red} bgColor={color.redLight} title="Inactive" />
            ) : (
              <>-</>
            )}
          </>
        )
      }
    }
  ]

  const renderSkeleton = () => {
    return (
      <div style={{ width: '100%', margin: '0 auto', marginTop: '16px' }}>
        <div className="flex items-center flex-wrap gap-2 md:gap-6">
          {[1, 2] &&
            Array.from({ length: 2 }, function (v, k) {
              return k
            }).map((i) => (
              <div key={i}>
                <SkeletonViewer borderRadius={16} className="h-8 md:h-10 w-32 md:w-44" />
              </div>
            ))}
          <div className="sm:ml-auto">
            <SkeletonViewer borderRadius={16} className="h-8 md:h-10 w-[90vw] sm:w-40 md:w-56 lg:w-72" />
          </div>
        </div>
        <div>
          <SkeletonViewer className="mt-2" height={60} width="100%" />
          <div className="block overflow-hidden mt-1" style={{ height: '64vh' }}>
            {Array.from({ length: 10 }, function (v, k) {
              return k
            }).map((i) => (
              <div key={i}>
                <SkeletonViewer className="mt-2" height={60} borderRadius={12} width="100%" />
              </div>
            ))}
          </div>
        </div>
        <div className="flex justify-center">
          <SkeletonViewer className="h-8 md:h-10 mt-4" width={300} />
        </div>
      </div>
    )
  }

  // get data table

  useMemo(() => {
    setMouted(true)
  }, [])
  const handleNavigateParamsUrl = (params, replace = false, pathname) => {
    navigate(
      {
        pathname: pathname,
        search: createSearchParams({
          page: params.page,
          size: params.size,
          ...params
        }).toString()
      },
      { replace }
    )
  }

  useEffect(() => {
    if (!location.search) {
      handleNavigateParamsUrl({ page: 1, size: 10 }, true, `/company/list-user/${params.companyId}`)
    }
  }, [])

  useEffect(() => {
    if (_mounted && location.search) {
      const params = Object.fromEntries(new URLSearchParams(window.location.search))
      const options = {
        ...params,
        ...(params.page ? { page: params.page } : { page: 1 }),
        ...(params.size && params.size >= 10 ? { size: params.size } : { size: 10 })
      }
      setTableOptions(options)
    }
  }, [location.search])

  useEffect(() => {
    const get = async () => {
      if (_mounted && location.search && !isEmptyObject(tableOptions)) {
        if ([...makeRoles([1, 2, 3, 4])].includes(role)) {
          dispatch(getUsersCompany({ id: params.companyId, options: tableOptions }))
        }
      }
    }
    get()
  }, [dispatch, user, tableOptions])

  useEffect(() => {
    if (prevUserGetFetching && !loadingsUser[userTypes.GET_USERS_COMPANY]) {
      if (!errorsUser[userTypes.GET_USERS_COMPANY]) {
        setRenderingProcess((prev) => ({ ...prev, error: false, loading: false }))
      } else {
        setRenderingProcess((prev) => ({ ...prev, error: true, loading: false }))
      }
    }
  }, [errorsUser, loadingsUser, prevUserGetFetching])
  return (
    <div>
      <PaddingAuto>
        {renderingProcess.loading ? (
          <>{renderSkeleton()}</>
        ) : renderingProcess.error ? (
          <NotAvailable content={errorsUser[userTypes.GET_USERS_COMPANY]} />
        ) : (
          <Table
            tableParams
            columns={UserColumns}
            data={usersCompany}
            title="List users"
            loading={loadingUser}
            tableOptions={tableOptions}
            paginations={paginationsOptions}
            setTableOptions={setTableOptions}
            heightTable={'64vh'}
            cellHeight={60}
            pathname={`/company/list-user/${params.companyId}`}
          ></Table>
        )}
      </PaddingAuto>
    </div>
  )
}

export default ListUser
