import React, { useEffect, useState } from 'react'
import ImageUploading from 'react-images-uploading'
import ScrollContainer from 'react-indiana-drag-scroll'
import { BsCameraFill, BsImage } from 'react-icons/bs'
import { AiOutlineClose } from 'react-icons/ai'
import { CameraOutlined, DeleteOutlined, CheckCircleTwoTone, EnvironmentOutlined, UploadOutlined } from '@ant-design/icons'
import { onConfirm } from 'react-confirm-pro'
import { makeRoles } from '../../../utils/common'
import useAuth from '../../../hooks/useAuth'
import NoImage from '../../../assets/images/no_upload_image.png'
import { typeFile, typeFileSort } from '../../../constant/file'
import Modal from '../../Common/Modal'
import CameraWeb from '../../Common/camera/CameraWeb'
import CountAnnotationContainer from './CountAnnotationContainer'
import { color } from '../../../constant/styled'

const ImageUserList = ({
  columns,
  manualRow,
  status,
  visualStatus,
  handleThumbnail,
  listManualImages,
  handleSelectImageUser,
  handleReplaceManualImage,
  onChangeStatusCloseRangeManual,
  onDelete,
  onReplacemanualPhotoByCamera
}) => {
  const { user } = useAuth()
  const { role } = user?.user || {}
  const [cameraPhotoSelected, setCameraPhotoSelected] = useState(null)
  let itemCol = 0

  const listTempManualImages = [...listManualImages]

  const onTakePhoto = (image) => {
    if (!image) {
      return
    }
    onReplacemanualPhotoByCamera(image, cameraPhotoSelected)
    setCameraPhotoSelected(null)
  }

  const handleCloseModal = async () => {
    setCameraPhotoSelected(null)
  }

  const renderModalCamera = () => {
    return (
      <>
        {cameraPhotoSelected && (
          <Modal wrapper="w-auto max-w-md mx-auto" onClose={handleCloseModal}>
            <div className="flex items-center justify-between py-3 px-6 border-b border-solid rounded-t border-slate-200 w-full">
              <div className=" text-xl  text-primary font-semibold">Take Photo</div>
              <div className="cursor-pointer" onClick={() => setCameraPhotoSelected(null)}>
                <AiOutlineClose style={{ fontSize: 22 }} />
              </div>
            </div>
            {/*body*/}
            <div className="relative flex flex-auto p-6">
              <div className="w-full max-w-lg">
                <CameraWeb cameraPhotoSelected={cameraPhotoSelected} onTakePhoto={onTakePhoto} />
              </div>
            </div>
          </Modal>
        )}
      </>
    )
  }

  const onImageUploadingError = (errors) => {
    if (errors?.acceptType) {
      onConfirm({
        title: <h3 className="text-center">{`The images must be a file of type: ${typeFile.join()}`}</h3>,
        description: <br />,

        onCancel: () => { },
        buttons: ({ onCancel }) => (
          <div className="custom-buttons">
            <button
              style={{
                background: 'rgba(37, 99, 235)',
                color: 'white'
              }}
              onClick={onCancel}
            >
              Ok
            </button>
          </div>
        )
      })
    }
  }

  useEffect(() => {
    handleThumbnail()
  }, [])

  return (
    <>
      {
        <div className="flex flex-col px-6 py-1 overflow-auto bg-white border border-gray-300 rounded shadow-lg bg-opacity-90 bg-clip-padding">
          <div className="flex max-h-screen">
            <ScrollContainer
              hideScrollbars={false}
              vertical={true}
              className="scroll-container custom-scroll-bar"
              style={{ paddingTop: '1rem', paddingBottom: '1rem' }}
            >
              <div className="flex flex-col gap-1 sticky top-0 ">
                {listManualImages && listManualImages.length > 0
                  ? Array.from({ length: manualRow }, function (v, k) {
                    return k
                  }).map((i) => {
                    let listImageRow = listTempManualImages.splice(0, columns)
                    itemCol++
                    return (
                      <div key={i} className="flex flex-row justify-start gap-1 w-max">
                        {listImageRow.map((item, idx) => {
                          return (
                            <div
                              key={`${idx} + id`}
                              className={`relative flex bg-transparent flex-row items-center justify-center h-48  cursor-pointer w-60 box-image-item ${item.url === process.env.REACT_APP_AWS_BLANK_IMAGE
                                ? 'cursor-default'
                                : 'cursor-pointer'
                                }`}
                            >
                              <img
                                src={item.url || process.env.REACT_APP_AWS_BLANK_IMAGE}
                                alt="Placeholder"
                                className="w-full h-full transition duration-500 transform rounded"
                                onClick={(e) => {
                                  if (item.url === process.env.REACT_APP_AWS_BLANK_IMAGE) return
                                  handleSelectImageUser(item)
                                }}
                              />
                              <div className="absolute top-0 flex flex-col items-center justify-end h-full z-20 right-2">
                                {item.check === true ? (
                                  <CheckCircleTwoTone
                                    size={24}
                                    className="checkboxEP absolute flex w-6 h-6 left-[6px] top-1 text-xl"
                                  />
                                ) : (
                                  ''
                                )}
                                {status !== 'Completed' &&
                                  !visualStatus &&
                                  [...makeRoles([1, 2, 5, 6, 7, 9, 10, 12, 13])].includes(role) && (
                                    <div className="relative">
                                      <div className="flex items-center group justify-center w-6 h-6 my-1 bg-gray-400 text-primary rounded-full hover:bg-orange-hover">
                                        {/* <CameraOutlined style={{ fontSize: '18px' }} /> */}
                                        <ImageUploading
                                          value={[]}
                                          onError={onImageUploadingError}
                                          acceptType={typeFileSort}
                                          onChange={(props) => handleReplaceManualImage(props, item.id)}
                                          maxNumber={1}
                                          dataURLKey="data_url"
                                        >
                                          {({ onImageUpdate }) => (
                                            <div
                                              onClick={(e) => {
                                                onImageUpdate(0)
                                              }}
                                            >
                                              <div className="flex items-center gap-2">
                                                <UploadOutlined style={{ fontSize: '18px' }} />
                                              </div>
                                            </div>
                                          )}
                                        </ImageUploading>
                                        <div
                                          className=" hidden  z-[200] text-xs text-left bg-white 
                                            rounded-lg shadow w-40 absolute bottom-1 right-[28px] before:content-[' '] before:bottom-1 
                                            before:right-[-28px] before:bg-transparent before:absolute before:w-10 before:h-16"
                                        >
                                          <ImageUploading
                                            value={[]}
                                            onError={onImageUploadingError}
                                            acceptType={typeFileSort}
                                            onChange={(props) => handleReplaceManualImage(props, item.id)}
                                            maxNumber={1}
                                            dataURLKey="data_url"
                                          >
                                            {({ onImageUpdate }) => (
                                              <div
                                                className="px-2 py-2  text-xs md:text-sm text-left hover:bg-gray-100 border-b rounded-t-lg"
                                                onClick={(e) => {
                                                  onImageUpdate(0)
                                                }}
                                              >
                                                <div className="flex items-center gap-2">
                                                  <BsImage style={{ fontSize: '20px', color: color.orange }} />
                                                  Upload photo
                                                </div>
                                              </div>
                                            )}
                                          </ImageUploading>
                                          <div
                                            className="px-2 py-2 flex items-center gap-2 text-xs md:text-sm hover:bg-gray-100 rounded-b-lg"
                                            onClick={() => setCameraPhotoSelected(item)}
                                          >
                                            <BsCameraFill
                                              style={{
                                                fontSize: '20px',
                                                color: color.purple
                                              }}
                                            />
                                            Take your picture
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                {status !== 'Completed' &&
                                  !visualStatus &&
                                  [...makeRoles([1, 2, 5, 6, 7, 9, 10, 12, 13])].includes(role) &&
                                  item.url !== process.env.REACT_APP_AWS_BLANK_IMAGE && (
                                    <div
                                      className=" flex items-center justify-center w-6 h-6 my-1 bg-gray-400 text-primary rounded-full hover:bg-orange-hover"
                                      onClick={() => {
                                        onConfirm({
                                          title: <h3>Are you sure you want to delete this image?</h3>,
                                          description: <p>This process cannot be undone!</p>,
                                          onSubmit: () => {
                                            onDelete(item.id)
                                          },
                                          onCancel: () => { },
                                          buttons: ({ onSubmit, onCancel }) => (
                                            <div className="custom-buttons">
                                              <button onClick={onSubmit}>YES</button>
                                              <button onClick={onCancel}>NO</button>
                                            </div>
                                          )
                                        })
                                      }}
                                    >
                                      <DeleteOutlined style={{ fontSize: '18px' }} />
                                    </div>
                                  )}

                                <div
                                  className={`${item?.closeRangeStatus === true ? 'bg-orange-hover' : 'bg-gray-400'
                                    } ${item.url !== process.env.REACT_APP_AWS_BLANK_IMAGE
                                      ? 'cursor-pointer'
                                      : 'cursor-auto'
                                    } text-primary group relative flex items-center justify-center w-6 h-6 my-1  rounded-full hover:bg-orange-hover hover:cursor-pointer`}
                                  onClick={() => {
                                    if (
                                      status !== 'Completed' &&
                                      item.url !== process.env.REACT_APP_AWS_BLANK_IMAGE &&
                                      [...makeRoles([1, 2, 3, 6, 7, 8, 10, 12, 13])].includes(role)
                                    ) {
                                      onChangeStatusCloseRangeManual(item)
                                    }
                                  }}
                                >
                                  <EnvironmentOutlined style={{ fontSize: '18px' }} />
                                  <div className=" group-hover:block hidden z-20 text-xs bg-white rounded-lg shadow w-32  absolute bottom-0 right-[28px] p-1">
                                    <div>{`Row: ${itemCol}`}</div>
                                    <div>{`Column: ${idx + 1}`}</div>
                                    {item?.fileName && <div>{`File name: ${item.fileName}`}</div>}
                                  </div>
                                </div>
                              </div>

                              {item.url !== process.env.REACT_APP_AWS_BLANK_IMAGE && (
                                <div
                                  className="absolute flex justify-center gap-1 top-1 left-1"
                                  onClick={(e) => {
                                    handleSelectImageUser(item)
                                  }}
                                >
                                  <CountAnnotationContainer defects={item.closeRangeDefects} />
                                </div>
                              )}
                              <div
                                className="absolute bottom-1 left-1"
                                onClick={(e) => {
                                  handleSelectImageUser(item)
                                }}
                              >
                                {item.url !== process.env.REACT_APP_AWS_BLANK_IMAGE && (
                                  <CountAnnotationContainer defects={item.defects} />
                                )}
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    )
                  })
                  : Array.from({ length: manualRow }, function (v, k) {
                    return k
                  }).map((i) => (
                    <div key={i} className="flex flex-row justify-start gap-1 w-max">
                      {Array.from({ length: columns }, function (f, g) {
                        return g
                      }).map((idx) => (
                        <div
                          key={`${idx} + id`}
                          className="relative flex bg-transparent flex-row items-center justify-center h-48  cursor-pointer w-60 false"
                        >
                          <img
                            src={NoImage}
                            alt="Placeholder"
                            className="w-full h-full transition duration-500 transform rounded"
                          />
                        </div>
                      ))}
                    </div>
                  ))}
              </div>
            </ScrollContainer>
          </div>
        </div>
      }
      {renderModalCamera()}
    </>
  )
}

export default ImageUserList
