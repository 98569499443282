import React, { useRef, useState, useEffect, useMemo } from 'react'
import Paper from '@mui/material/Paper'
import { makeStyles } from '@mui/styles'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { onConfirm } from 'react-confirm-pro'
import { toast } from 'react-toastify'
import styles from './style'
import classnames from 'classnames'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import TrashIcon from '@mui/icons-material/Delete'
import CheckIcon from '@mui/icons-material/Check'
import TextField from '@mui/material/TextField'
import CreatableSelect from 'react-select/creatable'
import { useSelector } from 'react-redux'
import { asMutable } from 'seamless-immutable'
import useAuth from '../../../../hooks/useAuth'
import useCloseRange from '../../../../hooks/useCloseRange'
import reasonTypes from '../../../../constant/store/reasonTypes'
import repairTypes from '../../../../constant/store/repairTypes'
import ImageUploading from 'react-images-uploading'
import ReactImageAnnotate from 'react-image-annotate-custom-region-edit-label-ver-2'
import CustomRegionLabelOnceColor from './index.js'
import elevationTypes from '../../../../constant/store/elevationTypes'
import { typeFile, typeFileSort } from '../../../../constant/file'
import { getDefectlist, getElementType } from '../../../../utils/stringUtil'
import Modal from '../../../Common/Modal/index.js'
import { IoCheckmarkDoneCircleSharp } from 'react-icons/io5'
import { BsCameraFill, BsImage } from 'react-icons/bs'
import { color } from '../../../../constant/styled'
import { AiOutlineClose } from 'react-icons/ai'
import CameraWeb from '../../../Common/camera/CameraWeb.js'
import { dataURLtoFile } from '../../../../utils/fileUtils.js'
import { nanoid } from 'nanoid'
import inspectionTypes from '../../../../constant/store/inspectionTypes.js'

const theme = createTheme()
const useStyles = makeStyles((theme) => styles)

const CustomRegionLabel = ({ region, editing, onDelete, onChange, onClose, onOpen }) => {
  //region.fillColor: '#000' - mask annotation is black
  if (region && region.type === 'box') {
    if (region.color !== '#FFFF00') {
      if (region.color !== '#00FF00') {
        if (region.color !== '#FF0000') {
          if (region.color !== '#00FFFF') {
            if (region.color !== '#000') {
              onChange({
                ...region,
                color: '#00FF00',
                cls: 'Safe'
              })
            }
          }
        }
      }
    }
  } else if (region && region.type === 'point') {
    if (region.color !== '#FF5F00') {
      onChange({
        ...region,
        color: '#FF5F00'
      })
    }
  }
  const { user } = useAuth()
  const {
    setCloseRangeDefectId,
    setCloseRangeImage,
    setCloseRangePopup,
    closeRangePopup,
    imageItem,
    setImageCloseRangeOnDrawer
  } = useCloseRange()
  const classes = useStyles()
  const { contents: contentsReason } = useSelector((state) => state.reason)
  const { contents: contentsRepair } = useSelector((state) => state.repair)
  const { contents } = useSelector((state) => state.building)
  const { contents: contentsElevationDetail } = useSelector((state) => state.elevation)
  const buildingDetails = contents[inspectionTypes.GET_BUILDINGS_DETAILS]
  const commentInputRef = useRef(null)
  const imageUploadRef = useRef()
  const [closeRangeImg, setCloseRangeImg] = useState()
  const [severityGrade] = useState(['Unsafe', 'Require Repair', 'Safe', 'False Positive'])
  const [element, setElement] = useState(region?.element || '')
  const [listDefectType, setListDefectType] = useState('')
  const [cameraPhotoRegionLabel, setCameraPhotoRegionLabel] = useState(null)
  const [listElementType, setListElemantType] = useState([])
  const reasons = contentsReason[reasonTypes.GET_ALL_COMPANY_REASON]
  const repairs = contentsRepair[repairTypes.GET_All_COMPANY_REPAIR]
  const elevationDetails = contentsElevationDetail[elevationTypes.GET_ELEVATION_DETAILS]
  const isCloseRange = useMemo(() => {
    return elevationDetails?.closeRangeImages?.find(
      (img) => img.defectId === region.id)
  }
    , [elevationDetails, region])
  useEffect(() => {
    let elementlist = []
    if (user.user.role === 'AI') {
      elementlist = getElementType(true)
    } else {
      elementlist = getElementType(false)
    }
    setListElemantType(elementlist)
  }, [user, element])

  useEffect(() => {
    if (element) {
      let listDefect = getDefectlist(element)
      const stringDefect = listDefect.map((defect) => defect.defect)
      setListDefectType(stringDefect)
    }
  }, [element, listElementType])

  useEffect(() => {
    if (!closeRangePopup && closeRangeImg) {
      setCloseRangePopup(true)
    }
  }, [closeRangeImg, closeRangePopup, setCloseRangePopup])

  const onCommentInputClick = (_) => {
    // The TextField wraps the <input> tag with two divs
    const commentInput = commentInputRef.current.children[0].children[0]

    if (commentInput) return commentInput.focus()
  }

  const handleCloseModal = () => {
    setCloseRangeImg()
    setCloseRangeDefectId()
    setCameraPhotoRegionLabel(null)
  }

  const handleUploadImage = (props) => {
    try {
      setCloseRangeImg(props[0])
    } catch (e) {
    } finally {
      setCloseRangeDefectId(region.id)
    }
  }

  const onImageUploadingError = (errors) => {
    if (errors?.acceptType) {
      onConfirm({
        title: <h3 className="text-center">{`The images must be a file of type: ${typeFile.join()}`}</h3>,
        description: <br />,

        onCancel: () => { },
        buttons: ({ onCancel }) => (
          <div className="custom-buttons">
            <button
              style={{
                background: 'rgba(37, 99, 235)',
                color: 'white'
              }}
              onClick={onCancel}
            >
              Ok
            </button>
          </div>
        )
      })
    }
  }

  const handleSetCloseRangeImageByPhoto = (image) => {
    if (!image) {
      return
    }
    const newFile = dataURLtoFile(image, nanoid())
    const fileOptions = {
      data_url: image,
      file: newFile
    }
    setCloseRangeImg(fileOptions)
    setCameraPhotoRegionLabel(null)
  }

  const renderModalCamera = () => {
    return (
      <>
        {cameraPhotoRegionLabel && (
          <Modal wrapper="w-auto max-w-md mx-auto" onClose={handleCloseModal}>
            {/* <Switcher /> */}
            <div className="flex items-center justify-between py-3 px-6 border-b border-solid rounded-t border-slate-200 w-full">
              <div className=" text-xl  text-primary font-semibold">Take Photo</div>
              <div className="cursor-pointer" onClick={() => setCameraPhotoRegionLabel(null)}>
                <AiOutlineClose style={{ fontSize: 22 }} />
              </div>
            </div>
            {/*body*/}
            <div className="relative flex flex-auto p-6">
              <div className="w-full max-w-lg">
                <CameraWeb cameraPhotoSelected={cameraPhotoRegionLabel} onTakePhoto={handleSetCloseRangeImageByPhoto} />
              </div>
            </div>
          </Modal>
        )}
      </>
    )
  }
  const renderModalCloseRange = () => {
    return (
      <>
        {closeRangeImg && (
          <Modal onClose={handleCloseModal}>
            <div className="fixed top-16 left-32 h-5/6 w-10/12">
              <ReactImageAnnotate
                taskTitle="Add close range image"
                hideSave={
                  buildingDetails?.building?.status === 'Completed' || elevationDetails?.elevation.switch_close_range
                    ? true
                    : false
                }
                key={closeRangeImg.data_url}
                RegionEditLabel={CustomRegionLabelOnceColor}
                enabledTools={['select', 'create-box']}
                regionClsList={[]}
                images={[
                  {
                    src: closeRangeImg.data_url ? closeRangeImg.data_url : '',
                    name: '',
                    regions: []
                  }
                ]}
                onExit={(x) => {
                  if (x.images[0].regions.length === 1) {
                    setCloseRangeImage({
                      image: closeRangeImg,
                      review: x.images[0].regions
                    })
                  } else if (x.images[0].regions.length > 1) {
                    toast.error('Please add one defect for one close-range image', {
                      theme: 'dark'
                    })
                  } else {
                    toast.error('Please add a defect', {
                      theme: 'dark'
                    })
                  }
                }}
              />
            </div>
          </Modal>
        )}
      </>
    )
  }

  return (
    <>
      {!region.fillColor && (
        <ThemeProvider theme={theme}>
          <Paper
            onClick={() => (!editing ? onOpen(region) : null)}
            className={classnames(classes.regionInfo, {
              highlighted: region?.highlighted
            })}
          >
            {!editing ? (
              <div>
                {region.cls && (
                  <div className="name">
                    <div className="circle" style={{ backgroundColor: region.color }} />
                    {region.cls}
                  </div>
                )}
                {region.element && (
                  <div className="tags">
                    <div className="tag">{region.element}</div>
                  </div>
                )}
                {region.tags && (
                  <div className="tags">
                    <div className="tag">{region.tags}</div>
                  </div>
                )}
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: 250,
                  height: 400,
                  backgroundColor: '#fff',
                  padding: '8px',
                  borderRadius: '15px',
                  position: 'fixed',
                  top: '129px',
                  right: '490px',
                  overflow: 'auto'
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div
                    style={{
                      display: 'flex',
                      backgroundColor: region.color || '#888',
                      color: '#fff',
                      padding: 4,
                      paddingLeft: 8,
                      paddingRight: 8,
                      borderRadius: 4,
                      fontWeight: 'bold',
                      textShadow: '0px 0px 5px rgba(0,0,0,0.4)'
                    }}
                  >
                    {region.type}
                  </div>
                  <div style={{ flexGrow: 1 }} />
                  <IconButton
                    onClick={() => onDelete(region)}
                    tabIndex={-1}
                    style={{ width: 22, height: 22 }}
                    size="small"
                    variant="outlined"
                  >
                    <TrashIcon style={{ marginTop: -8, width: 16, height: 16 }} />
                  </IconButton>
                </div>
                {(severityGrade || []).length > 0 && region.type === 'box' && (
                  <div style={{ marginTop: 6 }}>
                    <CreatableSelect
                      placeholder="Severity Grade"
                      onChange={(o, actionMeta) => {
                        if (actionMeta.action === 'create-option') {
                          return
                        }
                        if (o.value === 'Require Repair') {
                          return onChange({
                            ...region,
                            cls: o.value,
                            export: false,
                            color: '#FFFF00'
                          })
                        } else if (o.value === 'Safe') {
                          return onChange({
                            ...region,
                            cls: o.value,
                            export: false,
                            color: '#00FF00'
                          })
                        } else if (o.value === 'Unsafe') {
                          return onChange({
                            ...region,
                            cls: o.value,
                            color: '#FF0000'
                          })
                        } else if (o.value === 'False Positive') {
                          return onChange({
                            ...region,
                            cls: o.value,
                            color: '#00FFFF'
                          })
                        }
                      }}
                      value={region.cls ? { label: region.cls, value: region.cls } : null}
                      options={asMutable(severityGrade.map((c) => ({ value: c, label: c })))}
                    />
                  </div>
                )}
                {(region.cls === 'Safe' || region.cls === 'Require Repair') && region.type === 'box' && (
                  <div
                    className="px-2 py-1"
                    style={{
                      marginTop: 6,
                      display: 'flex',
                      alignItem: 'center',
                      backgroundColor: '#80b7f9',
                      justifyContent: 'space-between',
                      borderRadius: '4px'
                    }}
                  >
                    <label className="mr-2 text-white cursor-pointer">Include in Photo Record</label>
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        return onChange({
                          ...region,
                          export: e.target.checked
                        })
                      }}
                      checked={region.export ? region.export : false}
                    />
                  </div>
                )}
                {imageItem?.closeRangeStatus &&
                  [...elevationDetails?.elevationImages, ...elevationDetails.elevationNormalImages]
                    ?.find((img) => img.url === imageItem?.url)
                    ?.review.map((item) => item.id)
                    .includes(region.id) && (
                    <div
                      className="px-2 py-1"
                      style={{
                        marginTop: 6,
                        display: 'flex',
                        alignItem: 'center',
                        backgroundColor: '#80b7f9',
                        justifyContent: 'space-between',
                        borderRadius: '4px'
                      }}
                      onClick={() =>
                        setImageCloseRangeOnDrawer({
                          show: region.closeRangeImageId ? true : false,
                          region
                        })
                      }
                    >
                      <label className="mr-2 text-white cursor-pointer">Close Range Image</label>
                      {(region?.closeRangeImageId || isCloseRange) ? (
                        <div className="w-[20px] h-[20px]">
                          <IoCheckmarkDoneCircleSharp
                            style={{ background: color.white, color: color.green, fontSize: 20, borderRadius: '50%' }}
                          />
                        </div>
                      ) : (
                        <div className="flex items-center gap-2">
                          {/* <div
                            className="cursor-pointer"
                            onClick={() => {
                              setCameraPhotoRegionLabel(true)
                              setCloseRangeDefectId(region?.id)
                            }}
                          >
                            <BsCameraFill
                              style={{
                                fontSize: '18px'
                              }}
                            />
                          </div> */}
                          <div className="cursor-pointer" onClick={() => imageUploadRef.current.click()}>
                            <BsImage
                              style={{
                                fontSize: '16px'
                              }}
                            />
                          </div>
                        </div>
                      )}

                      <ImageUploading
                        onChange={(props) => {
                          handleUploadImage(props)
                        }}
                        maxNumber={1}
                        onError={onImageUploadingError}
                        acceptType={typeFileSort}
                        dataURLKey="data_url"
                      >
                        {({ onImageUpdate }) => (
                          <button
                            ref={imageUploadRef}
                            className="hidden items-center justify-center w-6 h-6 my-1 bg-gray-400 rounded-full hover:bg-orange-hover"
                            onClick={async () => {
                              onImageUpdate(0)
                            }}
                          ></button>
                        )}
                      </ImageUploading>
                    </div>
                  )}
                {(listElementType || []).length > 0 && region.type === 'box' && (
                  <div style={{ marginTop: 6 }}>
                    <CreatableSelect
                      placeholder="Element Types"
                      onChange={(o, actionMeta) => {
                        if (actionMeta.action === 'create-option') {
                          //onRegionClassAdded(o.value);
                          return
                        }
                        setElement(o.value)
                        return onChange({
                          ...region,
                          element: o.value,
                          tags: ''
                        })
                      }}
                      value={region.element ? { label: region.element, value: region.element } : null}
                      options={asMutable(listElementType.map((c) => ({ value: c, label: c })))}
                    />
                  </div>
                )}
                {(listDefectType || []).length > 0 && region.type === 'box' && (
                  <div style={{ marginTop: 6 }}>
                    <CreatableSelect
                      placeholder="Defect Type"
                      onChange={(o, actionMeta) => {
                        if (actionMeta.action === 'create-option') {
                          setListDefectType([...listDefectType, o.value])
                        }
                        return onChange({
                          ...region,
                          tags: o.value
                        })
                      }}
                      value={region.tags ? { label: region.tags, value: region.tags } : null}
                      options={asMutable(listDefectType.map((c) => ({ value: c, label: c })))}
                    />
                  </div>
                )}
                {region.type === 'box' && (
                  <>
                    <div style={{ marginTop: 4 }}>
                      <CreatableSelect
                        onChange={(reasons) =>
                          onChange({
                            ...region,
                            reasons: reasons.map((t) => ({ value: t.value, label: t.label }))
                          })
                        }
                        placeholder="Reasons for Defect"
                        value={(region.reasons || []).map((c) => ({
                          label: c.label,
                          value: c.value
                        }))}
                        isMulti
                        options={asMutable((reasons || []).map((c) => ({ value: c.id, label: c.name })))}
                      />
                    </div>
                    <div style={{ marginTop: 4 }}>
                      <CreatableSelect
                        onChange={(repairs) =>
                          onChange({
                            ...region,
                            repairs: repairs.map((t) => ({ value: t.value, label: t.label }))
                          })
                        }
                        placeholder="Repair Recommended"
                        value={(region.repairs || []).map((c) => ({
                          label: c.label,
                          value: c.value
                        }))}
                        isMulti
                        options={asMutable((repairs || []).map((c) => ({ value: c?.id, label: c?.name })))}
                      />
                    </div>

                    <TextField
                      InputProps={{
                        className: classes.commentBox,
                        style: {
                          fontSize: 'small',
                          marginTop: '6px'
                        }
                      }}
                      fullWidth
                      multiline
                      placeholder="Remarks"
                      rows={3}
                      maxRows={3}
                      ref={commentInputRef}
                      onClick={onCommentInputClick}
                      value={region.comment || ''}
                      onChange={(event) => onChange({ ...region, comment: event.target.value })}
                    />
                  </>
                )}

                <div className="flex row gap-2 justify-end">
                  {onClose && (
                    <div style={{ marginTop: 4, display: 'flex' }}>
                      <div style={{ flexGrow: 1 }} />
                      <Button onClick={() => onClose(region)} size="small" variant="contained" color="primary">
                        <CheckIcon />
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </Paper>

          {/* showing close range work place */}
          {renderModalCloseRange()}
          {/* take close range picture */}
          {renderModalCamera()}
        </ThemeProvider>
      )}
    </>
  )
}

export default CustomRegionLabel
