import React, { useEffect, useState, useRef } from 'react'
import { Link, useLocation, NavLink, useNavigate } from 'react-router-dom'
import { useClickAway } from 'react-use'
// hocs
import useAuth from '../../../hooks/useAuth'

// utils
import { changeAvtName, makeRoles } from './../../../utils/common'
// @antd
import { MenuOutlined, CloseOutlined, LogoutOutlined } from '@ant-design/icons'

// assets
import Logo from '../../../assets/images/svg/banner_header.svg'

// variables
import { ClientProject } from '../../../constant/auth'
import ShadowBox from '../shadow-box/ShadowBox'

const rightOptions = [{ id: 2, value: 'Logout', action: 'logout' }]

function Navbar() {
  const { pathname } = useLocation()

  const { logout, user } = useAuth()
  const { role } = user?.user || {}
  const [togglerNav, setTogglerNav] = useState(false)
  const [openNavOption, setOpenNavOption] = useState(false)
  const [path, setpath] = useState('/')
  const siderbarRef = useRef(null)
  const navigate = useNavigate()
  const listSettings = [
    { name: 'Update “Reasons for Defect”', path: '/settings/reasons-for-defect' },
    { name: 'Update “Repair Recommended', path: '/settings/repair-recommended' }
  ]
  const [isShowSelect, setIsShowSelect] = useState(false)

  const clickHandler = () => {
    setTogglerNav(!togglerNav)
    document.body.style.position = 'fixed'
  }

  const handleSignOut = () => {
    logout()
  }
  useEffect(() => {
    if (togglerNav) {
      setTogglerNav(false)
    }
  }, [pathname])

  useClickAway(siderbarRef, () => {
    setTogglerNav(false)
  })

  useEffect(() => {
    setpath(pathname)
  }, [pathname])

  return (
    <React.Fragment>
      {/* overlay */}
      <div
        className={`fixed top-0 left-0 bg-popup z-50 w-full h-full ease-out delay-150 duration-300  ${
          togglerNav ? 'visible md:hidden' : 'invisible'
        }`}
        onClick={() => {
          setTogglerNav(false)
          document.body.style.position = 'static'
        }}
      ></div>
      {/* siderbar */}
      <div
        className={`fixed top-0  bg-white z-50 h-full w-72 md:hidden  ease-out delay-150 duration-300 ${
          togglerNav ? 'left-0' : '-left-72'
        } `}
      >
        {/* mobile navbar */}
        <div className="flex flex-col gap-3 justify-center items-center mt-6">
          <div onClick={() => navigate(`/profile`)}>
            <div
              className="w-36 h-36 text-4xl mb-4 rounded-full flex justify-center items-center font-bold cursor-pointer  text-white"
              style={{ backgroundColor: user.user.color }}
            >
              {changeAvtName(user.user.username)}
            </div>
          </div>
          <div className="text-center px-6 text-2xl">{user.user.username}</div>
        </div>
        <NavLink
          to="/inspections"
          className={`flex items-center md:justify-center hover:bg-gray-100  py-3 text-sm  mt-5 px-4  ${
            ([...makeRoles([1, 2])].includes(role) && path === '/inspections') ||
            ([...makeRoles([5, 6, 7, 8, 9, 10, 11])].includes(role) && path === '/')
              ? 'text-blue-button hover:text-blue-button bg-red-100 md:bg-transparent'
              : 'text-opacity hover:text-opacity'
          }`}
        >
          {/* <img
            src={!isAdmin ? DashboardIconActive : DashboardIcon}
            alt="Dashboard"
          /> */}
          <span className="ml-2 font-bold">Inspections</span>
        </NavLink>
        {[...makeRoles([1, 2]), ...ClientProject].includes(role) && (
          <NavLink
            to={[...makeRoles([1, 2])].includes(role) ? '/users/claritas-user' : '/users/normal-user'}
            className={`flex items-center md:justify-center hover:bg-gray-100 py-3 text-sm px-4 ${
              path.includes('users')
                ? 'text-blue-button hover:text-blue-button bg-red-100 md:bg-transparent'
                : 'text-opacity hover:text-opacity'
            }`}
          >
            {/* <img src={isAdmin ? AdminIconActive : AdminIcon} alt="Admin" /> */}
            <span className="ml-2 font-bold">Users</span>
          </NavLink>
        )}
        {[...makeRoles([1, 2])].includes(role) && (
          <NavLink
            to="/"
            className={`flex items-center md:justify-center hover:bg-gray-100 py-3 text-sm px-4 ${
              path.includes('/company') || path === '/'
                ? 'text-blue-button hover:text-blue-button bg-red-100 md:bg-transparent'
                : 'text-opacity hover:text-opacity'
            }`}
          >
            {/* <img src={isAdmin ? AdminIconActive : AdminIcon} alt="Admin" /> */}
            <span className="ml-2 font-bold">Companies</span>
          </NavLink>
        )}
        <div className="px-3">
          <div className="p-2 font-bold">
            {rightOptions.map((item) => {
              return (
                <div key={item.id} className="p-2 hover:bg-gray-100 rounded-lg" onClick={() => handleSignOut()}>
                  <div className="flex gap-3 items-center cursor-pointer ">
                    <LogoutOutlined />
                    {item.value}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>

      <nav className="bg-whit dark:bg-gray-800  max-w-full md:max-w-screen-2xl mx-auto px-4 md:px-6 py-1 md:py-3 md:mb-8">
        <div className={`flex flex-col md:flex-row md:items-center mx-auto relative`}>
          <div className="flex justify-between items-center">
            <Link to="/" className="flex items-center">
              <img src={Logo} className="h-14" alt="Flowbite Logo" />
            </Link>
            <button
              className="md:ml-auto md:hidden h-10 px-4 rounded-xl bg-gray-100 flex justify-center items-center mr-4 peer"
              onClick={clickHandler}
            >
              {!togglerNav ? <MenuOutlined /> : <CloseOutlined />}
            </button>
          </div>

          {/* {pathname.includes("elevation") && (
              <NavLink
                to="/"
                className="flex items-center justify-center px-4 py-1 my-1 mr-4 text-white bg-black rounded hover:text-primary hover:bg-white"
              >
                <span className="mr-2 font-semibold"> Your inspections</span>{" "}
                <BankOutlined />
              </NavLink>
            )} */}
          <div className={`hidden md:flex flex-col md:flex-row md:flex-grow`}>
            <NavLink
              to="/inspections"
              className={`flex items-center md:justify-center  py-3 text-sm md:ml-10 ${
                path.includes('inspections')
                  ? 'text-blue-button hover:text-blue-button bg-red-100 md:bg-transparent'
                  : 'text-opacity hover:text-opacity '
              }`}
            >
              {/* <img
                src={!isAdmin ? DashboardIconActive : DashboardIcon}
                alt="Dashboard"
              /> */}
              <span className="ml-2 font-bold">Inspections</span>
            </NavLink>
            {[...makeRoles([1, 2]), ...ClientProject].includes(role) && (
              <NavLink
                to={[...makeRoles([1, 2])].includes(role) ? '/users/claritas-user' : '/users/normal-user'}
                className={`flex items-center md:justify-center py-3 text-sm md:ml-10 ${
                  path.includes('users')
                    ? 'text-blue-button hover:text-blue-button bg-red-100 md:bg-transparent'
                    : 'text-opacity hover:text-opacity'
                }`}
              >
                {/* <img src={isAdmin ? AdminIconActive : AdminIcon} alt="Admin" /> */}
                <span className="font-bold">Users</span>
              </NavLink>
            )}
            {[...makeRoles([1, 2])].includes(role) && (
              <NavLink
                to="/"
                className={`flex items-center md:justify-center py-3 text-sm md:ml-12 relative ${
                  (path.includes('/company') && !path.includes('/logs')) || path === '/'
                    ? 'text-blue-button hover:text-blue-button bg-red-100 md:bg-transparent'
                    : 'text-opacity hover:text-opacity'
                }`}
              >
                {/* <img src={isAdmin ? AdminIconActive : AdminIcon} alt="Admin" /> */}
                <span className="font-bold">Companies</span>
              </NavLink>
            )}
            {[...makeRoles([1, 2])].includes(role) && (
              <NavLink
                to="/logs/inspection"
                className={`flex items-center md:justify-center py-3 text-sm md:ml-10 ${
                  path.includes('logs')
                    ? 'text-blue-button hover:text-blue-button bg-red-100 md:bg-transparent'
                    : 'text-opacity hover:text-opacity'
                }`}
              >
                {/* <img src={isAdmin ? AdminIconActive : AdminIcon} alt="Admin" /> */}
                <span className="font-bold">Logs</span>
              </NavLink>
            )}
            {[...makeRoles([6, 7])].includes(role) && (
              <div
                className={`flex items-center md:justify-center py-3 text-sm md:ml-12 relative cursor-pointer ${
                  path.includes('/settings')
                    ? 'text-blue-button hover:text-blue-button bg-red-100 md:bg-transparent'
                    : 'text-opacity hover:text-opacity'
                }`}
                onMouseEnter={() => setIsShowSelect(true)}
                onMouseLeave={() => setIsShowSelect(false)}
              >
                {/* <img src={isAdmin ? AdminIconActive : AdminIcon} alt="Admin" /> */}
                <span className="font-bold">Setting</span>
                <div
                  className={`text-left  absolute bg-white z-20 w-60 cursor-pointer top-10 left-0 transition-all duration-200 shadow-box ${
                    isShowSelect ? 'opacity-100 visible' : 'opacity-0 invisible'
                  }`}
                >
                  {isShowSelect &&
                    listSettings.map((item) => (
                      <div
                        key={item.name}
                        className={`w-full hover:bg-blue-button  border-b last:border-b-0 transition-colors duration-200  ${
                          path.includes(item.path) ? 'bg-blue-button ' : ''
                        }`}
                      >
                        <NavLink
                          to={item.path}
                          className={`  hover:text-white font-medium block px-4 py-[10px]  ${
                            path.includes(item.path) ? 'text-white' : 'text-opacity'
                          }`}
                        >
                          {item.name}
                        </NavLink>
                      </div>
                    ))}
                </div>
              </div>
            )}
            <div className="flex items-center md:ml-auto">
              <div></div>
              <div className="cursor-pointer ml-4 relative">
                <div
                  className={`w-10 h-10 rounded-full ml-9 flex justify-center items-center font-bold cursor-pointer text-white`}
                  onClick={() => setOpenNavOption(!openNavOption)}
                  style={{ backgroundColor: user.user.color }}
                >
                  {changeAvtName(user.user.username)}
                </div>
                <ShadowBox shadowBoxOpen={openNavOption} wrapper="right-0 w-64 top-12 z-30" backgroundColor="white">
                  <div className="p-2 font-bold">
                    <div className="p-2 shadow-box-inner rounded-lg mb-3 ">
                      <NavLink to={'/profile'} className="hover:text-opacity">
                        <div className="flex items-center gap-3 ">
                          <div
                            className="min-w-10 h-10 rounded-full bg-gray-200 flex justify-center items-center font-bold cursor-pointer text-white"
                            onClick={() => setOpenNavOption(!openNavOption)}
                            style={{ backgroundColor: user.user.color }}
                          >
                            {changeAvtName(user.user.username)}
                          </div>
                          <div className="truncate">{user.user.username}</div>
                        </div>
                      </NavLink>
                    </div>
                    {rightOptions.map((item) => {
                      return (
                        <div key={item.id} className="p-2 hover:bg-gray-100 rounded-lg" onClick={() => handleSignOut()}>
                          <div className="flex gap-3 items-center  ">
                            <LogoutOutlined />
                            {item.value}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </ShadowBox>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </React.Fragment>
  )
}

export default Navbar
