import React from 'react'

function DefectListings({ report, building, inspectors, crDefectListing, cp, editMode, setValue }) {
  return (
    <>
      <ol className="toc-list" style={{ lineHeight: '30px' }}>
        <li id="DefectListings-v">
          <div className="font-bold">
            <div className="py-4 text-center text-lg font-bold">
              Table 4: Defects Identified During Close Range Inspection
            </div>
          </div>
        </li>

        <div className="" style={{ fontSize: '16px', lineHeight: 'normal' }}>
          {crDefectListing && crDefectListing.length > 0 ? (
            <table className="border-[1px] w-full table-test" style={{ fontSize: '12px' }}>
              <tr className="border-[1px]">
                <th className="border-[1px] px-1 py-[6px] w-[51px]">ID</th>
                <th className="border-[1px] px-1 py-[6px] w-[73px]">Elevation</th>
                <th className="border-[1px] px-1 py-[6px] w-[95px]">Location</th>
                <th className="border-[1px] px-1 py-[6px] w-[80px]">Element</th>
                <th className="border-[1px] px-1 py-[6px] w-[80px]">Defect</th>
                <th className="border-[1px] px-1 py-[6px] w-[66px]">Severity</th>
                <th className="border-[1px] px-1 py-[6px] w-[58px]">Visual Defect ID</th>
                <th className="border-[1px] px-1 py-[6px] w-[51px]">Photo Record</th>
                <th className="border-[1px] px-1 py-[6px] w-[130px]">Reason for Defect</th>
                <th className="border-[1px] px-1 py-[6px] w-[130px]">Repair Recommended</th>
                <th className="border-[1px] px-1 py-[6px]  w-[118px]">Remarks</th>
              </tr>
              {crDefectListing.map((d, index) => (
                <>
                  <tr>
                    <td className="border-[1px] px-1 py-[6px] text-center">{d?.orderId}</td>
                    <td className="border-[1px] px-1 py-[6px] text-center">{d?.elevation_name}</td>
                    <td className="border-[1px] px-1 py-[6px] text-center">
                      {d?.row} <br /> {d?.column}
                    </td>
                    <td className="border-[1px] px-1 py-[6px] text-center">{d?.element}</td>
                    <td className="border-[1px] px-1 py-[6px] text-center">{d?.tags}</td>
                    <td className="border-[1px] px-1 py-[6px] text-center">{d?.cls}</td>
                    <td className="border-[1px] px-1 py-[6px] text-center">{d?.visualOrderId}</td>
                    <td className="border-[1px] px-1 py-[6px] text-center">{d?.photoRecord ? 'Yes' : 'No'}</td>
                    <td className="border-[1px] px-1 py-[6px] text-center">
                      {d?.reasons?.map((reason, index) => (
                        <>
                          {reason?.label}
                          {index === d?.reasons?.length - 1 ? '' : ', '}
                        </>
                      ))}
                    </td>

                    <td className="border-[1px] px-1 py-[6px] text-center">
                      {d?.repairs?.map((repair, index) => (
                        <>
                          {repair?.label}
                          {index === d?.repairs?.length - 1 ? '' : ', '}
                        </>
                      ))}
                    </td>

                    <td className="border-[1px] px-1 py-[6px] text-center">{d?.remarks}</td>
                  </tr>
                </>
              ))}
            </table>
          ) : (
            <div className="font-bold text-center">Close Range Inspection Data is not included in this report</div>
          )}

          <div className="reportContentBreak">
            <div className="flex justify-evenly gap-8 pt-20 drawing-break">
              <div className="flex flex-col justify-end">
                <div className="flex justify-evenly">
                  <div></div>
                  <div className="flex flex-col justify-center mb-[-20px]">
                    {inspectors?.map((inspector, index) => (
                      <div className="p-1 text-center">{inspector.username}</div>
                    ))}
                  </div>
                </div>

                <div>
                  <div className="flex justify-center gap-4 mb-4">
                    <div className="mt-auto">Signed By:</div>
                    <div>
                      <div className="mt-auto">________________________</div>
                    </div>
                  </div>
                  <div className="flex justify-between gap-4 my-4">
                    <div>(FI)</div>
                    <div>Name / Signature </div>
                  </div>
                </div>
              </div>

              <div className="reportContentBreakE flex flex-col justify-end">
                <div className="flex justify-evenly mb-[-16px]">
                  <div></div>
                  <div className="text-center">{cp?.username}</div>
                </div>

                <div>
                  <div className="flex justify-center gap-4 mb-4">
                    <div className="mt-auto">Verified By:</div>
                    <div>
                      <div className="mt-auto">________________________</div>
                    </div>
                  </div>
                  <div className="flex justify-between gap-4 my-4">
                    <div>(CP)</div>
                    <div>Name / Signature </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ol>
    </>
  )
}

export default DefectListings
