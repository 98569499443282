import React from 'react'
import { onConfirm } from 'react-confirm-pro'
import {
  CopyOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  UploadOutlined,
  CheckCircleTwoTone,
  EnvironmentOutlined
} from '@ant-design/icons'
import ImageUploading from 'react-images-uploading'
import { makeRoles } from '../../../utils/common'
import useAuth from '../../../hooks/useAuth'
import { typeFile, typeFileSort } from '../../../constant/file'
import CountAnnotationContainer from './CountAnnotationContainer'

function ImageItem({
  listImgs,
  data,
  type,
  status,
  visualStatus,
  defects,
  closeRangeDefects,
  onSelected,
  onAddDummy,
  onChangeUpload,
  onReplaceUpload,
  onDelete,
  onChangeStatusCloseRangeDrone
}) {
  const { user } = useAuth()
  const { role } = user?.user || {}

  const onImageUploadingError = (errors) => {
    if (errors?.acceptType) {
      onConfirm({
        title: <h3 className="text-center">{`The images must be a file of type: ${typeFile.join()}`}</h3>,
        description: <br />,

        onCancel: () => {},
        buttons: ({ onCancel }) => (
          <div className="custom-buttons">
            <button
              style={{
                background: 'rgba(37, 99, 235)',
                color: 'white'
              }}
              onClick={onCancel}
            >
              Ok
            </button>
          </div>
        )
      })
    }
  }

  return (
    <>
      <div
        className={`relative flex bg-transparent flex-row items-center justify-center h-48 cursor-pointer w-60 box-image-item ${
          data.url === process.env.REACT_APP_AWS_BLANK_IMAGE ? 'cursor-default' : 'cursor-pointer'
        } `}
      >
        {type === 'drone' ? (
          <img
            src={data.url || process.env.REACT_APP_AWS_BLANK_IMAGE}
            alt=""
            className="w-full h-[inherit] transition duration-500 transform rounded"
            onClick={() => onSelected(data)}
          />
        ) : (
          <img src={data.thermalUrl} alt="" className="w-full h-full transition duration-500 transform rounded" />
        )}

        {type === 'drone' && (
          <>
            {data.url !== process.env.REACT_APP_AWS_BLANK_IMAGE && (
              <div className="absolute flex justify-center gap-1 top-1 left-1" onClick={() => onSelected(data)}>
                <CountAnnotationContainer defects={closeRangeDefects} />
              </div>
            )}
            <div className="absolute bottom-1 left-1" onClick={() => onSelected(data)}>
              {data.url !== process.env.REACT_APP_AWS_BLANK_IMAGE && <CountAnnotationContainer defects={defects} />}
            </div>

            {data.check === true ? (
              <CheckCircleTwoTone size={24} className="checkboxEP absolute flex w-6 h-6 right-[6px] top-1 text-xl" />
            ) : (
              ''
            )}

            <div className="absolute top-0 flex flex-col items-center justify-end h-full z-20 right-2">
              {[...makeRoles([1, 2, 5, 6, 7, 9, 10, 12, 13])].includes(role) &&
                listImgs?.length < 400 &&
                status !== 'Completed' &&
                !visualStatus && (
                  <button
                    className="flex items-center justify-center w-6 h-6 my-1 bg-gray-400 text-primary rounded-full hover:bg-orange-hover"
                    onClick={() => onAddDummy(data.order)}
                  >
                    <PlusCircleOutlined style={{ fontSize: '18px' }} />
                  </button>
                )}

              {[...makeRoles([1, 2, 5, 6, 7, 9, 10, 12, 13])].includes(role) &&
                listImgs?.length < 400 &&
                status !== 'Completed' &&
                !visualStatus && (
                  <ImageUploading
                    value={[]}
                    onChange={(props) => onChangeUpload(props, data.order)}
                    maxNumber={1}
                    onError={onImageUploadingError}
                    acceptType={typeFileSort}
                    dataURLKey="data_url"
                  >
                    {({ onImageUpdate }) => (
                      <button
                        className="flex items-center justify-center w-6 h-6 my-1 bg-gray-400 text-primary rounded-full hover:bg-orange-hover"
                        onClick={() => {
                          onImageUpdate(0)
                        }}
                      >
                        <UploadOutlined style={{ fontSize: '18px' }} />
                      </button>
                    )}
                  </ImageUploading>
                )}

              {[...makeRoles([1, 2, 5, 6, 7, 9, 10, 12, 13])].includes(role) &&
                status !== 'Completed' &&
                !visualStatus && (
                  <button
                    className="flex items-center justify-center w-6 h-6 my-1 bg-gray-400 text-primary rounded-full hover:bg-orange-hover"
                    onClick={() => onReplaceUpload(data)}
                  >
                    <CopyOutlined style={{ fontSize: '18px' }} />
                  </button>
                )}
              {[...makeRoles([1, 2, 5, 6, 7, 9, 10, 12, 13])].includes(role) &&
                status !== 'Completed' &&
                !visualStatus && (
                  <button
                    className="flex items-center justify-center w-6 h-6 my-1 bg-gray-400 text-primary rounded-full hover:bg-orange-hover"
                    onClick={() => {
                      onConfirm({
                        title: <h3>Are you sure you want to delete this image?</h3>,
                        description: <p>This process cannot be undone!</p>,
                        onSubmit: () => {
                          onDelete(data.order)
                        },
                        onCancel: () => {},
                        buttons: ({ onSubmit, onCancel }) => (
                          <div className="custom-buttons">
                            <button onClick={onSubmit}>YES</button>
                            <button onClick={onCancel}>NO</button>
                          </div>
                        )
                      })
                    }}
                  >
                    <DeleteOutlined style={{ fontSize: '18px' }} />
                  </button>
                )}

              <button
                className={`${data?.closeRangeStatus === true ? 'bg-orange-hover' : 'bg-gray-400'}  ${
                  data.url !== process.env.REACT_APP_AWS_BLANK_IMAGE ? 'cursor-pointer' : 'cursor-auto'
                } text-primary group relative flex items-center justify-center w-6 h-6 my-1  rounded-full hover:bg-orange-hover ${
                  [...makeRoles([1, 2, 3, 6, 7, 8, 10, 12, 13])].includes(role) ? 'cursor-pointer' : 'cursor-default'
                }`}
                onClick={() => {
                  if (
                    status !== 'Completed' &&
                    data.url !== process.env.REACT_APP_AWS_BLANK_IMAGE &&
                    [...makeRoles([1, 2, 3, 6, 7, 8, 10, 12, 13])].includes(role)
                  ) {
                    onChangeStatusCloseRangeDrone(data)
                  }
                }}
              >
                <EnvironmentOutlined style={{ fontSize: '18px' }} />
                <div className=" group-hover:block hidden z-20 text-xs bg-white rounded-lg shadow w-32  absolute bottom-0 right-[28px] p-1">
                  <div>{`Row: ${data.row + 1}`}</div>
                  <div>{`Column: ${data.col + 1}`}</div>
                  {data?.fileName && <div>{`File name: ${data.fileName}`}</div>}
                </div>
              </button>
            </div>
          </>
        )}
      </div>
    </>
    // </div>
  )
}

export default ImageItem
