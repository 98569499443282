import React from 'react'
import CustomRegionLabel from '../../CustomLabelAnnotation/CustomRegionLabel'
import ReactImageAnnotate from 'react-image-annotate-custom-region-edit-label-ver-2'
import { toast } from 'react-toastify'
import { makeRoles, formatMetadata } from '../../../../utils/common'
import { TbClockRecord } from 'react-icons/tb'
import useAuth from '../../../../hooks/useAuth'
import Modal from '../../../Common/Modal'
const ImageCloseRangePopUp = ({
  selected,
  setPopUpImageCloseRange,
  onExit,
  hideSave,
  onEditCloseRange,
  setShowLogDrawer
}) => {
  const { user } = useAuth()
  const { role } = user?.user || {}
  const metadata = formatMetadata(selected?.metadata)

  const handleCloseModal = () => {
    setPopUpImageCloseRange('down')
    onExit('')
  }

  return (
    <Modal onClose={handleCloseModal}>
      <div className="fixed top-16 left-32 h-5/6 w-10/12">
        {selected && (
          <>
            <ReactImageAnnotate
              key={selected.urlOriginal || selected.url}
              taskTitle="Edit close range image"
              taskDescription={
                metadata &&
                `
## ** Longitude:** ${metadata.longitude} 
## ** Latitude:** ${metadata.latitude}
## ** Altitude:** ${metadata.altitude}`
              }
              hidePrev={true}
              hideNext={true}
              enabledTools={hideSave ? [] : ['select', `${![...makeRoles([5, 9])].includes(role) && 'create-box'}`]}
              RegionEditLabel={CustomRegionLabel}
              hideSave={hideSave}
              images={[
                {
                  src: selected.urlOriginal || selected.url ? selected.urlOriginal || selected.url : '',
                  name: '',
                  regions: selected?.review
                }
              ]}
              onExit={(x) => {
                let checkOutSide = false
                x.images[0].regions.forEach((region) => {
                  if (region.x <= 0 || region.x >= 1 || region.y <= 0 || region.y >= 1) {
                    checkOutSide = true
                  }
                })
                if (checkOutSide) {
                  toast.error('Create defect inside image')
                } else {
                  onEditCloseRange(x.images[0].regions, selected)
                }
              }}
            />
          </>
        )}
        <div className="absolute bottom-0 -right-16 text-white flex flex-col gap-3">
          <button
            onClick={() => setShowLogDrawer({ type: 'CloseRange', id: selected.id, title: 'Close range image log' })}
          >
            <p className="flex items-center gap-1 cursor-pointer">
              <TbClockRecord /> <span className="leading-4">Log</span>
            </p>
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ImageCloseRangePopUp
