import { createSlice } from '@reduxjs/toolkit'
import {
  getBuildingDetails,
  getCompanyBuildings,
  createBuilding,
  updateBuilding,
  deleteBuilding,
  getBuildings,
  getRoleBuilding,
  getBuildingsActive,
  changeStatusBuilding,
  revertDeleteBuilding,
  updateBuildingAvatar
} from '../thunks/buildingThunk'

import inspectionType from '../../constant/store/inspectionTypes'

const buildingSlice = createSlice({
  name: 'building',
  initialState: {
    loadings: {},
    errors: {},
    contents: {},
    paginations: {}
  },
  extraReducers: {
    // getRoleBuilding
    [getRoleBuilding.pending]: (state) => {
      state.loadings[inspectionType.GET_ROLE_BUILDING] = true
      state.errors[inspectionType.GET_ROLE_BUILDING] = ''
    },
    [getRoleBuilding.fulfilled]: (state, { payload }) => {
      const newPayload = payload.map(({ id, name }) => {
        return { label: name, value: id }
      })
      state.loadings[inspectionType.GET_ROLE_BUILDING] = false
      state.contents[inspectionType.GET_ROLE_BUILDING] = newPayload
    },

    [getRoleBuilding.rejected]: (state, { payload }) => {
      state.loadings[inspectionType.GET_ROLE_BUILDING] = false
      state.errors[inspectionType.GET_ROLE_BUILDING] = payload.message
    },

    //action get building company
    [getCompanyBuildings.pending]: (state) => {
      state.loadings[inspectionType.GET_BUILDINGS] = true
    },
    [getCompanyBuildings.fulfilled]: (state, { payload }) => {
      state.loadings[inspectionType.GET_BUILDINGS] = false
      state.paginations[inspectionType.GET_BUILDINGS] = payload.pagination
      const newListBuilding = payload.building.map((building) => {
        let cps_new = []
        let fis_new = []
        if (building?.cps) {
          cps_new = building.cps.map((cps) => {
            return {
              label: cps.username,
              value: cps.id
            }
          })
        }
        if (building?.fis) {
          fis_new = building.fis.map((cps) => {
            return {
              label: cps.username,
              value: cps.id
            }
          })
        }
        return {
          ...building,
          cps: cps_new,
          fis: fis_new
        }
      })

      state.contents[inspectionType.GET_BUILDINGS] = newListBuilding
      state.errors[inspectionType.GET_BUILDINGS] = ''
    },
    [getCompanyBuildings.rejected]: (state, { payload }) => {
      state.loadings[inspectionType.GET_BUILDINGS] = false
      state.errors[inspectionType.GET_BUILDINGS] = payload.message
    },
    // COMPANY ACTION TYPE: Get all active buildings
    [getBuildingsActive.pending]: (state) => {
      state.loadings[inspectionType.GET_BUILDINGS_ACTIVE] = true
    },
    [getBuildingsActive.fulfilled]: (state, { payload }) => {
      state.loadings[inspectionType.GET_BUILDINGS_ACTIVE] = false
      state.contents[inspectionType.GET_BUILDINGS_ACTIVE] = payload.buildings
      state.errors[inspectionType.GET_BUILDINGS_ACTIVE] = ''
    },
    [getBuildingsActive.rejected]: (state, { payload }) => {
      state.loadings[inspectionType.GET_BUILDINGS_ACTIVE] = false
      state.errors[inspectionType.GET_BUILDINGS_ACTIVE] = payload.message
    },
    // BUILDING ACTION TYPE: Get all buildings
    [getBuildings.pending]: (state) => {
      state.loadings[inspectionType.GET_BUILDINGS] = true
    },
    [getBuildings.fulfilled]: (state, { payload }) => {
      const { buildings, pagination } = payload
      state.loadings[inspectionType.GET_BUILDINGS] = false
      const newListBuilding = buildings.map((building) => {
        let cps_new = []
        let fis_new = []
        if (building?.cps) {
          cps_new = building.cps.map((cps) => {
            return {
              label: cps.username,
              value: cps.id
            }
          })
        }
        if (building?.fis) {
          fis_new = building.fis.map((cps) => {
            return {
              label: cps.username,
              value: cps.id
            }
          })
        }
        return {
          ...building,
          cps: cps_new,
          fis: fis_new
        }
      })
      state.paginations[inspectionType.GET_BUILDINGS] = pagination
      state.contents[inspectionType.GET_BUILDINGS] = newListBuilding
      state.errors[inspectionType.GET_BUILDINGS] = ''
    },
    [getBuildings.rejected]: (state, { payload }) => {
      state.loadings[inspectionType.GET_BUILDINGS] = false
      state.errors[inspectionType.GET_BUILDINGS] = payload.message
    },

    // ACTION TYPE: Get building details
    [getBuildingDetails.pending]: (state) => {
      state.loadings[inspectionType.GET_BUILDINGS_DETAILS] = true
    },
    [getBuildingDetails.fulfilled]: (state, { payload }) => {
      state.loadings[inspectionType.GET_BUILDINGS_DETAILS] = false
      state.contents[inspectionType.GET_BUILDINGS_DETAILS] = payload
      state.errors[inspectionType.GET_BUILDINGS_DETAILS] = ''
    },
    [getBuildingDetails.rejected]: (state, { payload }) => {
      state.loadings[inspectionType.GET_BUILDINGS_DETAILS] = false
      state.errors[inspectionType.GET_BUILDINGS_DETAILS] = payload.message
    },
    //BUILDING ACTION TYPE: Create new building
    [createBuilding.pending]: (state) => {
      state.loadings[inspectionType.CREATE_BUIDING] = true
    },
    [createBuilding.fulfilled]: (state, { payload }) => {
      state.loadings[inspectionType.CREATE_BUIDING] = false
      let cps_new = []
      if (payload.building?.cps) {
        cps_new = payload.building.cps.map((cps) => {
          return {
            value: cps.id,
            label: cps.username
          }
        })
      }
      state.contents[inspectionType.GET_BUILDINGS] = [
        { ...payload.building, cps: cps_new },
        ...state.contents[inspectionType.GET_BUILDINGS]
      ]
      state.errors[inspectionType.CREATE_BUIDING] = ''
    },
    [createBuilding.rejected]: (state, { payload }) => {
      state.loadings[inspectionType.CREATE_BUIDING] = false
      state.errors[inspectionType.CREATE_BUIDING] = payload.message
    },
    //BUILDING ACTION TYPE: Update building
    [updateBuilding.pending]: (state) => {
      state.loadings[inspectionType.UPDATE_BUIDING] = true
    },
    [updateBuilding.fulfilled]: (state, { payload }) => {
      state.loadings[inspectionType.UPDATE_BUIDING] = false
      const index = state.contents[inspectionType.GET_BUILDINGS].findIndex(
        (building) => building.id === payload.building.id
      )
      let cps_new = []
      if (payload.building?.cps) {
        cps_new = payload.building.cps.map((cps) => {
          return {
            value: cps.id,
            label: cps.username
          }
        })
      }
      let fis_new = []
      if (payload?.building?.fis) {
        fis_new = payload?.building.fis.map((cps) => {
          return {
            label: cps.username,
            value: cps.id
          }
        })
      }

      state.contents[inspectionType.GET_BUILDINGS].splice(index, 1, { ...payload.building, cps: cps_new, fis: fis_new })
      state.errors[inspectionType.UPDATE_BUIDING] = ''
    },
    [updateBuilding.rejected]: (state, { payload }) => {
      state.loadings[inspectionType.UPDATE_BUIDING] = false
      state.errors[inspectionType.UPDATE_BUIDING] = payload.message
    },
    //BUILDING ACTION TYPE: Update avatar building
    [updateBuildingAvatar.pending]: (state) => {
      state.loadings[inspectionType.UPDATE_BUIDING_AVATAR] = true
    },
    [updateBuildingAvatar.fulfilled]: (state, { payload }) => {
      state.loadings[inspectionType.UPDATE_BUIDING_AVATAR] = false
      if (!payload.clasify) {
        state.contents[inspectionType.GET_BUILDINGS_DETAILS].building.thumbnail = payload.building.building.thumbnail
      } else {
        const index = state.contents[inspectionType.GET_BUILDINGS].findIndex(
          (building) => building.id === payload.building.building.id
        )
        state.contents[inspectionType.GET_BUILDINGS][index].thumbnail = payload.building.building.thumbnail
      }
      state.errors[inspectionType.UPDATE_BUIDING_AVATAR] = ''
    },
    [updateBuildingAvatar.rejected]: (state, { payload }) => {
      state.loadings[inspectionType.UPDATE_BUIDING_AVATAR] = false
      state.errors[inspectionType.UPDATE_BUIDING_AVATAR] = payload.message
    },

    //BUILDING ACTION TYPE: Delete building
    [deleteBuilding.pending]: (state) => {
      state.loadings[inspectionType.DELETE_BUIDING] = true
    },
    [deleteBuilding.fulfilled]: (state, { payload }) => {
      state.loadings[inspectionType.DELETE_BUIDING] = false
      // const { id, building } = payload
      // if (building?.building_id) {
      //   const index = state.contents[inspectionType.GET_BUILDINGS].findIndex((b) => b.id === building.building_id)
      //   const updateData = { ...state.contents[inspectionType.GET_BUILDINGS][index], status: 'Deleted' }
      //   state.contents[inspectionType.GET_BUILDINGS].splice(index, 1, updateData)
      // } else {
      //   const index = state.contents[inspectionType.GET_BUILDINGS].findIndex((b) => b.id === id)
      //   state.contents[inspectionType.GET_BUILDINGS].splice(index, 1)
      // }
      state.errors[inspectionType.DELETE_BUIDING] = ''
    },
    [deleteBuilding.rejected]: (state, { payload }) => {
      state.loadings[inspectionType.DELETE_BUIDING] = false
      state.errors[inspectionType.DELETE_BUIDING] = payload.message
    },
    //BUILDING ACTION TYPE: Update status building
    [changeStatusBuilding.pending]: (state) => {
      state.loadings[inspectionType.CHANGE_STATUS_BUILDING] = true
    },
    [changeStatusBuilding.fulfilled]: (state, { payload }) => {
      const buildingIndex = state.contents[inspectionType.GET_BUILDINGS].findIndex(
        (building) => building.id === payload.id
      )
      const buildingChange = state.contents[inspectionType.GET_BUILDINGS].find((building) => building.id === payload.id)
      const newBuilding = { ...buildingChange, status: payload.status }

      state.loadings[inspectionType.CHANGE_STATUS_BUILDING] = false
      state.contents[inspectionType.GET_BUILDINGS][buildingIndex] = newBuilding
      state.errors[inspectionType.CHANGE_STATUS_BUILDING] = ''
    },
    [changeStatusBuilding.rejected]: (state, { payload }) => {
      state.loadings[inspectionType.CHANGE_STATUS_BUILDING] = false
      state.errors[inspectionType.CHANGE_STATUS_BUILDING] = payload.message
    },

    //BUILDING ACTION TYPE: revert delete building

    [revertDeleteBuilding.pending]: (state) => {
      state.loadings[inspectionType.REVERT_DELETE_BUILDING] = true
    },
    [revertDeleteBuilding.fulfilled]: (state, { payload }) => {
      const buildingIndex = state.contents[inspectionType.GET_BUILDINGS].findIndex(
        (building) => building.id === payload.id
      )
      const buildingChange = state.contents[inspectionType.GET_BUILDINGS].find((building) => building.id === payload.id)
      const newBuilding = { ...buildingChange, status: 'Archived' }

      state.loadings[inspectionType.REVERT_DELETE_BUILDING] = false
      state.contents[inspectionType.GET_BUILDINGS][buildingIndex] = newBuilding
      state.errors[inspectionType.REVERT_DELETE_BUILDING] = ''
    },
    [revertDeleteBuilding.rejected]: (state, { payload }) => {
      state.loadings[inspectionType.REVERT_DELETE_BUILDING] = false
      state.errors[inspectionType.REVERT_DELETE_BUILDING] = payload.message
    }
  }
})
export default buildingSlice.reducer
