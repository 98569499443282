import React, { useEffect, useRef, useState } from 'react'
import { useTable, useSortBy, useGlobalFilter, usePagination, useRowSelect } from 'react-table'
import { useNavigate, createSearchParams, useLocation } from 'react-router-dom'
import { PlusCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Pagination } from 'react-headless-pagination'
import moment from 'moment'
import { CSVLink } from 'react-csv'

// hocs
import useAuth from '../../../hooks/useAuth'

// cpns
import { TableCheckbox2 } from './TableCheckbox2'
import { FilterTable2 } from './FilterTable2'
import Nodata from '../Nodata'

// assets
import PreIcon from '../../../assets/images/pagination_pre.png'
import NextIcon from '../../../assets/images/pagination_next.png'
import DropdownSelectIcon from '../../../assets/images/svg/select_dropdown_icon.svg'
import { BiChevronsUp, BiChevronsDown } from 'react-icons/bi'
import { AiOutlineDownload } from 'react-icons/ai'
import { CgCloseO, CgAdd } from 'react-icons/cg'
import { TbClockRecord } from 'react-icons/tb'
import SearchIcon from '../../../assets/images/svg/search_icon.svg'

// variables
import { makeRoles } from '../../../utils/common'
import { sizeOptions } from '../../../constant/common'
import { color } from '../../../constant/styled'
import SkeletonViewer from '../skeleton-viewer'
import ShadowBox from '../shadow-box/ShadowBox'
import DatePickerRange from '../date-picker-range'
import Button from '../button/CommonButton'
import SmartDrawer from '../drawer/SmartDrawer'

function BasicTable(props) {
  const {
    tableParams = false,
    columns = [],
    data = [],
    __filename,
    pathname,
    isExport = false,
    title,
    isShowSelectBox = false,
    actions = [],
    hideColumn,
    tableOptions = {},
    setTableOptions = () => {},
    paginations = {},
    loading,
    cellHeight = 60,
    setShowModal,
    onRowClicked,
    filterDate,
    heightTable = '66vh',
    showLog,
    exportData,
    setShowLogDrawer,
    onSubmitExportDrawer,
    topElement = true
  } = props

  const navigate = useNavigate()
  const [isShowSelect, setIsShowSelect] = useState(false)
  const popupSelect = useRef(null)
  const [showDrawer, setShowDrawer] = useState(false)
  const { user } = useAuth()
  const { role } = user?.user || {}
  const [exportOption, setExportOption] = useState({})
  const [errorsDowload, setErrorDowload] = useState({})
  const [exportColumns, setExportColumns] = useState([])
  const [CSVColumns, setCSVColumns] = useState([])
  const hide = hideColumn ? hideColumn : []

  const { getTableProps, getTableBodyProps, page, prepareRow, headerGroups, setPageSize } = useTable(
    {
      columns,
      data: data || [],
      initialState: { hiddenColumns: hide, ...tableOptions }
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,

    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: 'selection',
            accessor: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }) => <TableCheckbox2 {...getToggleAllRowsSelectedProps()} />,
            Cell: ({ row }) => <TableCheckbox2 {...row.getToggleRowSelectedProps()} />,
            width: 40,
            disableSortBy: true
          },
          ...columns
        ]
      })
    }
  )

  const handleNavigateParamsUrl = (params, replace = false) => {
    navigate(
      {
        pathname: `${pathname}`,
        search: createSearchParams({
          page: params.page,
          size: params.size,
          ...params
        }).toString()
      },
      { replace }
    )
  }

  const handleChangeTableParams = (params, replace = false) => {
    if (tableParams) {
      handleNavigateParamsUrl(params, replace)
    } else {
      setTableOptions(params)
    }
  }

  useEffect(() => {
    const columnsOptions = [...columns].map((column) => {
      return {
        isRequired: column.isRequired,
        selected: false,
        Header: column.Header,
        accessor: column.accessor
      }
    })
    setExportOption({ search: tableOptions.search, from: tableOptions.from, to: tableOptions.to })
    setExportColumns(columnsOptions)
    return () => {
      setExportOption({ search: tableOptions.search, from: tableOptions.from, to: tableOptions.to })
    }
  }, [showDrawer])

  useEffect(() => {
    const CSVColumns = exportColumns
      .filter((column) => column.isRequired || column.selected)
      .map((c) => {
        return {
          label: c.Header,
          key: c.accessor
        }
      })
    setCSVColumns(CSVColumns)
  }, [exportColumns])

  const handleExportCSV = () => {
    if (!exportOption.from || !exportOption.to) {
      setErrorDowload({ ...errorsDowload, date: 'Date is required' })
      return false
    }
    // setSubmitting(true)
    let optionDownload = {
      ...exportOption,
      search: exportOption?.search || '',
      from: exportOption?.from || tableOptions?.from,
      to: exportOption?.to || tableOptions?.to
    }
    delete optionDownload.page
    delete optionDownload.size
    if (!optionDownload.search) {
      delete optionDownload.search
    }

    onSubmitExportDrawer(optionDownload)
  }

  const handleSetExportColumn = (header) => {
    const columns = [...exportColumns]
    const index = columns.findIndex((i) => i.Header === header)
    const newItem = {
      ...columns[index],
      selected: !columns[index].selected
    }
    columns.splice(index, 1, newItem)
    setExportColumns(columns)
  }

  const renderTableSkeleton = () => {
    return (
      <>
        {Array.from({ length: 10 }, function (v, k) {
          return k
        }).map((i) => (
          <tr className="w-full table" key={i}>
            <th className="w-full">
              <SkeletonViewer borderRadius={12} height={cellHeight} />
            </th>
          </tr>
        ))}
      </>
    )
  }

  const renderExportDrawer = () => {
    return (
      <SmartDrawer
        visible={showDrawer ? true : false}
        onClose={() => {
          setShowDrawer(false)
          setErrorDowload({})
        }}
        title="Export Inspection"
        width={500}
        issubmit
        submitTitle="Export"
        onSubmit={handleExportCSV}
        zIndex={showDrawer ? 1000 : 10}
      >
        {showDrawer && (
          <div>
            <div className="font-bold mb-2 uppercase text-xs">Date</div>
            <DatePickerRange
              onChange={(from, to) => {
                setExportOption({ ...exportOption, from, to })
              }}
              value={
                exportOption?.from && exportOption?.to
                  ? [moment(exportOption.from.slice(0, 10)), moment(exportOption.to.slice(0, 10))]
                  : []
              }
            />
            {errorsDowload?.date && (
              <div className="text-red-500 mt-1 flex items-center">
                <ExclamationCircleOutlined />
                <span className="ml-1">{errorsDowload?.date}</span>
              </div>
            )}
            <div className="font-bold mb-2 mt-4 tracking-wide uppercase text-xs">Search</div>
            <div className="w-full sm:w-40 md:w-56 lg:w-72 h-8 md:h-10 rounded-2xl bg-gray-200 focus:outline-none">
              <div className="relative flex justify-between items-center w-[98%] sm:w-40 md:w-56 lg:w-72 align-middle whitespace-nowrap">
                <input
                  type="search"
                  id="search"
                  className="w-full sm:w-32 md:w-48 lg:w-64 h-8 md:h-10 border-0 text-xs md:text-sm pl-4 rounded-2xl bg-gray-200 focus:outline-none"
                  placeholder="Search..."
                  defaultValue={exportOption.search}
                  onChange={(e) => setExportOption({ ...exportOption, search: e.target.value })}
                />
                <span className="pr-4">
                  <img src={SearchIcon} alt="Search" />
                </span>
              </div>
            </div>
            <div className="font-bold mb-2 mt-4 tracking-wide uppercase text-xs">Column</div>
            <div className="flex items-center flex-wrap gap-2 ">
              {exportColumns.map(({ Header, isRequired, selected }) => {
                return (
                  <div
                    key={Header}
                    className={`flex items-center rounded-md px-2 py-1 cshadow-avatar font-medium ${
                      !isRequired ? 'cursor-pointer' : 'cursor-not-allowed'
                    }`}
                    style={{ background: isRequired ? color.blueDisabled : selected ? color.blue : color.whiteDark }}
                    onClick={() => handleSetExportColumn(Header)}
                  >
                    <div className="pr-1" style={{ color: isRequired || selected ? color.white : color.blue }}>
                      {Header}
                    </div>
                    {isRequired || selected ? (
                      <CgCloseO size={16} style={{ color: color.white }} />
                    ) : (
                      <CgAdd size={16} style={{ color: color.blue }} />
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </SmartDrawer>
    )
  }

  return (
    <div>
      <div style={{ width: '100%', margin: '0 auto', marginTop: topElement ? 16 : 0 }}>
        <div className="flex items-center flex-wrap gap-2 md:gap-6">
          {title && <div className="text-xl font-bold">{title}</div>}
          {actions &&
            actions.map((action) => {
              if (action.isShow) {
                return (
                  <div
                    className="flex items-center justify-center h-8 md:h-10 w-32 md:w-44 text-xs md:text-sm font-semibold leading-[16px] md:leading-[20px] text-primary border-solid border cursor-pointer border-primary rounded-2xl"
                    onClick={() => setShowModal(true)}
                    key={action.id}
                  >
                    <span className="mr-2">New {action.title}</span>
                    <PlusCircleOutlined />
                  </div>
                )
              }
              return false
            })}

          {showLog && (
            <div
              className="flex items-center justify-center h-8 md:h-10 w-32 md:w-44 text-xs md:text-sm font-semibold leading-[16px] md:leading-[20px] text-primary border-solid border cursor-pointer border-primary rounded-2xl"
              onClick={() => setShowLogDrawer(true)}
            >
              <span className="mr-2">Log</span>
              <TbClockRecord />
            </div>
          )}

          <div className={`relative`}>
            <div
              className="text-primary flex items-center justify-between  font-semibold h-8 md:h-10 w-32 md:w-44 text-xs md:text-sm border-solid border border-primary  rounded-2xl cursor-pointer"
              onClick={() => setIsShowSelect(!isShowSelect)}
            >
              <span className="pl-4 ">Show {tableOptions.size} item</span>
              <img
                src={DropdownSelectIcon}
                className="pointer-events-none leading-[16px] md:leading-[20px] md:mr-4"
                alt="drop icon"
              />
            </div>

            <ShadowBox
              shadowBoxOpen={isShowSelect}
              wrapper="absolute z-20 w-36 md:w-44 right-0 mt-1"
              backgroundColor="white"
            >
              <ul ref={popupSelect} className="text-left cursor-pointer">
                {sizeOptions.map((item) => {
                  return (
                    <li
                      className="px-4 py-2 text-xs md:text-sm hover:bg-gray-100 first:rounded-t-lg last:rounded-b-lg  border-b last:border-b-0"
                      onClick={() => {
                        setPageSize(Number(item.value))
                        setIsShowSelect(false)
                        handleChangeTableParams({ ...tableOptions, page: 1, size: Number(item.value) })
                        // setTableOptions({ ...tableOptions, page: 1, size: Number(item.value) })
                      }}
                      key={item.value}
                    >
                      Show {item.value} items
                    </li>
                  )
                })}
              </ul>
            </ShadowBox>
          </div>

          {filterDate && (
            <DatePickerRange
              value={
                tableOptions?.from && tableOptions?.to
                  ? [moment(tableOptions?.from.slice(0, 10)), moment(tableOptions?.to.slice(0, 10))]
                  : []
              }
              onChange={(from, to, dateStrings) => {
                if (from && to) {
                  // setTableOptions({ ...tableOptions, from, to })
                  handleChangeTableParams({ ...tableOptions, from, to })
                  setExportOption({ ...exportOption, from, to })
                } else {
                  const newOptions = { ...tableOptions }
                  delete newOptions.from
                  delete newOptions.to
                  handleChangeTableParams({ ...newOptions })
                  // setTableOptions({ ...newOptions })
                  setExportOption({ ...newOptions })
                }
              }}
            />
          )}
          <div className="sm:ml-auto flex items-center gap-6">
            {isExport && (
              <Button
                icon={
                  <AiOutlineDownload
                    style={{
                      fontSize: '20px',
                      color: color.white
                    }}
                  />
                }
                textColor={color.white}
                bgColor={color.green}
                iconPosion="right"
                height={40}
                onClick={() => setShowDrawer(true)}
              />
            )}
            {[...makeRoles([1, 2, 6, 7, 10, 12, 13])].includes(role) && (
              <FilterTable2
                filter={tableOptions.search}
                setFilter={(value) => {
                  if (!value) {
                    const newOptions = { ...tableOptions, page: 1 }
                    delete newOptions.search
                    handleChangeTableParams({ ...newOptions })
                    // setTableOptions({ ...newOptions })
                  } else {
                    handleChangeTableParams({ ...tableOptions, search: value, page: 1 })
                    // setTableOptions({ ...tableOptions, search: value, page: 1 })
                  }
                }}
              />
            )}
          </div>
        </div>
        <div style={{ overflowX: 'auto' }}>
          <table {...getTableProps} className="mx-auto border-separate">
            <thead>
              {headerGroups.map((headerGroup) => {
                return (
                  <tr {...headerGroup.getHeaderGroupProps()} className="mt-2 px-3  w-full table table-fixed">
                    {headerGroup.headers.map((column) => {
                      if (column.isHidden) return false
                      if (column.id === 'selection' && !isShowSelectBox) {
                        return false
                      }
                      if (column.role && !column.role.includes(role)) {
                        return false
                      }
                      return (
                        <th
                          className="p-3 text-left font-medium break-words cursor-pointer"
                          {...column.getHeaderProps()}
                          style={{
                            minWidth: column.minWidth,
                            width: column.width,
                            maxWidth: column.maxWidth,
                            marginLeft: 12
                          }}
                        >
                          <div
                            className="flex items-center"
                            {...column.getSortByToggleProps({
                              onClick: (e) => {
                                if (column.disableSortBy) return false
                                else if (!tableOptions.sort) {
                                  handleChangeTableParams({ ...tableOptions, sort: column.id, sortType: 'asc' })
                                  // setTableOptions({ ...tableOptions, sort: column.id, sortType: 'asc' })
                                } else if (tableOptions.sort === column.id && tableOptions.sortType === 'asc') {
                                  handleChangeTableParams({ ...tableOptions, sort: column.id, sortType: 'desc' })
                                  // setTableOptions({ ...tableOptions, sort: column.id, sortType: 'desc' })
                                } else if (tableOptions.sort === column.id && tableOptions.sortType === 'desc') {
                                  const newTB = { ...tableOptions }
                                  delete newTB.sort
                                  delete newTB.sortType
                                  handleChangeTableParams({ ...newTB })
                                  // setTableOptions({ ...newTB })
                                } else if (tableOptions.sort !== column.id) {
                                  handleChangeTableParams({ ...tableOptions, sort: column.id, sortType: 'asc' })
                                  // setTableOptions({ ...tableOptions, sort: column.id, sortType: 'asc' })
                                }
                              }
                            })}
                          >
                            <span>{!column.hideHeader && column.render('Header')}</span>
                            <span>
                              {column.canSort && (
                                <div className="relative ml-2">
                                  <div>
                                    <BiChevronsUp
                                      style={{
                                        fontSize: '16px',
                                        color: `${
                                          tableOptions.sort === column.id && tableOptions.sortType === 'asc'
                                            ? color.blue
                                            : color.opacity
                                        }`
                                      }}
                                    />
                                  </div>
                                  <div>
                                    <BiChevronsDown
                                      style={{
                                        fontSize: '16px',
                                        color: `${
                                          tableOptions.sort === column.id && tableOptions.sortType === 'desc'
                                            ? color.blue
                                            : color.opacity
                                        }`
                                      }}
                                    />
                                  </div>
                                </div>
                              )}
                            </span>
                          </div>
                        </th>
                      )
                    })}
                  </tr>
                )
              })}
            </thead>
            <tbody
              {...getTableBodyProps}
              className={`block overflow-x-hidden   ${loading ? 'overflow-hidden mt-1' : 'overflow-y-scroll'}`}
              id="table-custom"
              style={{ height: `${heightTable}` }}
            >
              {loading ? (
                renderTableSkeleton()
              ) : (
                <>
                  {data?.length > 0 ? (
                    page.map((row) => {
                      prepareRow(row)
                      return (
                        <tr
                          {...row.getRowProps({
                            onClick: (e) => onRowClicked && onRowClicked(row, e)
                          })}
                          className="group rounded-xl border-solid border border-primary my-2 p-3 w-full table table-fixed hover:bg-blue-button relative"
                        >
                          {row.cells.map((cell, i) => {
                            const { column } = cell
                            if (column.isHidden) return false
                            if (column.id === 'selection' && !isShowSelectBox) {
                              return false
                            }
                            if (column.role && !column.role.includes(role)) {
                              return false
                            }
                            return (
                              <td
                                className="text-left px-3 break-words relative group-hover:text-white"
                                {...cell.getCellProps({
                                  style: {
                                    minWidth: column.minWidth,
                                    width: column.width,
                                    maxWidth: column.maxWidth,
                                    height: column.height
                                  }
                                })}
                              >
                                {cell.render('Cell')}
                              </td>
                            )
                          })}
                        </tr>
                      )
                    })
                  ) : (
                    <tr className="w-full flex justify-center" style={{ height: '58vh' }}>
                      <td className="w-full flex justify-center">
                        <div className="flex items-center justify-center h-full">
                          <Nodata width="400px" />
                        </div>
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>

        {renderExportDrawer()}
        {data?.length ? (
          <Pagination
            currentPage={tableOptions.page - 1}
            setCurrentPage={(page) => handleChangeTableParams({ ...tableOptions, page: page + 1 })}
            totalPages={paginations?.maxPage}
            edgePageCount={3}
            middlePagesSiblingCount={1}
            className="flex items-center justify-center mt-4"
            truncableText="..."
            truncableClassName=""
          >
            <Pagination.PrevButton
              className={`w-8 md:w-10 h-8 md:h-10 rounded-lg border-solid border border-primary ${
                paginations.hasPrevious ? 'cursor-pointer ' : 'cursor-default'
              }`}
            >
              <img src={PreIcon} className="h-3 w-3" alt="arrPrev" />
            </Pagination.PrevButton>

            <div className="flex items-center justify-center mx-6">
              {paginations?.maxPage > 0 ? (
                <Pagination.PageButton
                  activeClassName="bg-blue-button border-0 text-white "
                  inactiveClassName="border"
                  className="flex justify-center text-xs md:text-sm items-center rounded-lg border-solid  border-primary mx-1 w-8 md:w-10 h-8 md:h-10 cursor-pointer font-medium"
                />
              ) : (
                <div className="flex justify-center text-xs md:text-sm items-center rounded-lg  mx-1 w-8 md:w-10 h-8 md:h-10 cursor-pointer font-medium bg-blue-button border-0 text-white">
                  1
                </div>
              )}
            </div>

            <Pagination.NextButton
              className={`w-8 md:w-10 h-8 md:h-10 rounded-lg border-solid border border-primary  ${
                paginations.hasNext ? 'cursor-pointer' : 'cursor-not-allowed'
              }`}
            >
              <img src={NextIcon} className="h-3 w-3" alt="arrNext" />
            </Pagination.NextButton>
          </Pagination>
        ) : null}

        <CSVLink data={exportData || []} filename={__filename} headers={CSVColumns} id="exportCSV"></CSVLink>
      </div>
    </div>
  )
}

export default React.memo(BasicTable)
