import React from 'react'
import ImageItem from './ImageItem'
import ScrollContainer from 'react-indiana-drag-scroll'

function ImageThermalList({ position, direction }) {
  return (
    <div className="flex flex-col px-6 py-1 overflow-auto bg-white border border-gray-300 rounded shadow-lg bg-opacity-90 bg-clip-padding">
      <div className="flex max-h-screen">
        <ScrollContainer
          hideScrollbars={false}
          vertical={true}
          className="scroll-container custom-scroll-bar"
          style={{ paddingTop: '1rem', paddingBottom: '1rem' }}
        >
          <div
            className={`flex gap-1 sticky top-0 ${
              parseInt(direction) === 0 || parseInt(direction) === 1 ? 'flex-col' : 'flex-row'
            }`}
          >
            {position.map((arrRow, idx) => (
              <div key={idx} className="flex flex-row justify-start">
                <div
                  className={`flex justify-start gap-1 ${
                    parseInt(direction) === 0 || parseInt(direction) === 1
                      ? 'flex-row pr-5'
                      : parseInt(direction) === 2
                      ? 'flex-col'
                      : 'flex-col-reverse'
                  }`}
                >
                  {arrRow.map((col, idx) => {
                    if (!col.order) {
                      return (
                        <div
                          key={idx}
                          className="relative flex flex-row items-center justify-center h-48 pr-1 bg-transparent cursor-pointer w-60"
                        ></div>
                      )
                    }
                    return <ImageItem data={col} type="thermal" key={idx} />
                  })}
                </div>
              </div>
            ))}
          </div>
        </ScrollContainer>
      </div>
    </div>
  )
}

export default ImageThermalList
