import React, { useRef, useState } from 'react'
import { useTable, useSortBy, useGlobalFilter, usePagination, useRowSelect } from 'react-table'
import { PlusCircleOutlined } from '@ant-design/icons'
import { Pagination } from 'react-headless-pagination'

// hocs
import useAuth from '../../../hooks/useAuth'

// cpns
import { TableCheckox2 } from './Checkbox'
import { FilterTable2 } from './GlobalFilter'
import Nodata from '../Nodata'

// assets
import PreIcon from '../../../assets/images/pagination_pre.png'
import NextIcon from '../../../assets/images/pagination_next.png'
import SortIcon from '../../../assets/images/svg/sort_icon.svg'
import DropdownSelectIcon from '../../../assets/images/svg/select_dropdown_icon.svg'

// variables
import { sizeOptions } from '../../../constant/common'
import ShadowBox from '../shadow-box/ShadowBox'

function BasicTable2(props) {
  const { columns, data, title, isShowSelectBox = false, actions = [], setShowModal, onRowClicked, hideColumn } = props

  const [isShowSelect, setIsShowSelect] = useState(false)
  const popupSelect = useRef(null)
  const { user } = useAuth()
  const { role } = user?.user || {}
  const hide = hideColumn ? hideColumn : []

  // useClickAway(popupSelect, () => {
  //   setIsShowSelect(!isShowSelect);
  // });

  const {
    getTableProps,
    getTableBodyProps,
    page,
    prepareRow,
    headerGroups,
    state,
    pageOptions,
    setGlobalFilter,
    gotoPage,
    canNextPage,
    canPreviousPage,
    setPageSize
  } = useTable(
    {
      columns,
      data: data || [],
      initialState: { hiddenColumns: hide }
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,

    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: 'selection',
            accessor: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }) => <TableCheckox2 {...getToggleAllRowsSelectedProps()} />,
            Cell: ({ row }) => <TableCheckox2 {...row.getToggleRowSelectedProps()} />,
            width: 40,
            disableSortBy: true
          },
          ...columns
        ]
      })
    }
  )

  const { pageIndex, pageSize } = state

  return (
    <div>
      <div style={{ width: '100%', margin: '0 auto', marginTop: '16px' }}>
        <div className="flex items-center flex-wrap gap-2 md:gap-6">
          {title && <div className="text-xl font-bold">{title}</div>}
          {actions &&
            actions.map((action) => {
              if (action.isShow) {
                return (
                  <div
                    className="flex items-center justify-center h-8 md:h-10 w-32 md:w-44 text-xs md:text-sm font-semibold leading-[16px] md:leading-[20px] text-primary border-solid border cursor-pointer border-primary rounded-2xl"
                    onClick={() => setShowModal(true)}
                    key={action.id}
                  >
                    <span className="mr-2">New {action.title}</span>
                    <PlusCircleOutlined />
                  </div>
                )
              }
              return false
            })}

          <div className="relative">
            <div
              className="text-primary flex items-center justify-between  font-semibold h-8 md:h-10 w-32 md:w-44 text-xs md:text-sm border-solid border border-primary  rounded-2xl cursor-pointer"
              onClick={() => setIsShowSelect(!isShowSelect)}
            >
              <span className="pl-4">Show {pageSize} item</span>
              <img
                src={DropdownSelectIcon}
                className="pointer-events-none leading-[16px] md:leading-[20px] md:mr-4"
                alt="drop icon"
              />
            </div>
            <ShadowBox
              shadowBoxOpen={isShowSelect}
              wrapper="absolute z-20 w-36 md:w-44 right-0 mt-1"
              backgroundColor="white"
            >
              <ul ref={popupSelect} className="text-left cursor-pointer">
                {sizeOptions.map((item) => {
                  return (
                    <li
                      className="px-4 py-2 text-xs md:text-sm hover:bg-gray-100 first:rounded-t-lg last:rounded-b-lg  border-b last:border-b-0"
                      onClick={() => {
                        setPageSize(Number(item.value))
                        setIsShowSelect(false)
                      }}
                      key={item.value}
                    >
                      Show {item.value} items
                    </li>
                  )
                })}
              </ul>
            </ShadowBox>
          </div>

          <FilterTable2 filter={state.globalFilter} setFilter={setGlobalFilter} />
        </div>
        <div style={{ overflowX: 'auto' }}>
          <table {...getTableProps} className="mx-auto border-separate">
            <thead>
              {headerGroups.map((headerGroup) => {
                return (
                  <tr {...headerGroup.getHeaderGroupProps()} className="mt-2 px-3  w-full table table-fixed">
                    {headerGroup.headers.map((column) => {
                      if (column.isHidden) return false
                      if (column.id === 'selection' && !isShowSelectBox) {
                        return false
                      }
                      if (column.role && !column.role.includes(role)) {
                        return false
                      }
                      return (
                        <th
                          className="p-3 text-left font-medium break-words cursor-pointer "
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          style={{
                            minWidth: column.minWidth,
                            width: column.width,
                            maxWidth: column.maxWidth,
                            marginLeft: 12
                          }}
                        >
                          <div className="flex items-center">
                            <span>{!column.hideHeader && column.render('Header')}</span>
                            <span>
                              {column.canSort && <img src={SortIcon} className="w-4 h-4 ml-2" alt="sorticon" />}
                            </span>
                          </div>
                        </th>
                      )
                    })}
                  </tr>
                )
              })}
            </thead>
            <tbody
              {...getTableBodyProps}
              className="block overflow-x-hidden overflow-y-scroll"
              id="table-custom"
              style={{ height: '64vh' }}
            >
              {data?.length > 0 ? (
                page.map((row) => {
                  prepareRow(row)
                  return (
                    <tr
                      {...row.getRowProps({
                        onClick: (e) => onRowClicked && onRowClicked(row, e)
                      })}
                      className="group rounded-xl border-solid border border-primary my-2 p-3 w-full table table-fixed hover:bg-blue-button relative"
                    >
                      {row.cells.map((cell, i) => {
                        const { column } = cell
                        if (column.isHidden) return false
                        if (column.id === 'selection' && !isShowSelectBox) {
                          return false
                        }
                        if (column.role && !column.role.includes(role)) {
                          return false
                        }
                        return (
                          <td
                            className="text-left px-3 break-words relative group-hover:text-white"
                            {...cell.getCellProps({
                              style: {
                                minWidth: column.minWidth,
                                width: column.width,
                                maxWidth: column.maxWidth,
                                height: column.height
                              }
                            })}
                          >
                            {cell.render('Cell')}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })
              ) : (
                <tr className="w-full flex justify-center" style={{ height: '58vh' }}>
                  <td className="w-full flex justify-center">
                    <div className="flex items-center justify-center h-full">
                      <Nodata width="400px" />
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {data?.length ? (
          <Pagination
            currentPage={pageIndex}
            setCurrentPage={(page) => gotoPage(page)}
            totalPages={pageOptions.length}
            edgePageCount={3}
            middlePagesSiblingCount={1}
            className="flex items-center justify-center mt-4"
            truncableText="..."
            truncableClassName=""
          >
            <Pagination.PrevButton
              className={`w-8 md:w-10 h-8 md:h-10 rounded-lg border-solid border border-primary ${
                canPreviousPage ? 'cursor-pointer ' : 'cursor-default'
              }`}
            >
              <img src={PreIcon} className="h-3 w-3" alt="arrPrev" />
            </Pagination.PrevButton>

            <div className="flex items-center justify-center mx-6">
              {page.length > 0 ? (
                <Pagination.PageButton
                  activeClassName="bg-blue-button border-0 text-white "
                  inactiveClassName="border"
                  className="flex justify-center items-center rounded-lg border-solid  border-primary mx-1 w-10 h-10 cursor-pointer font-medium"
                />
              ) : (
                <div className="flex justify-center items-center rounded-lg  mx-1 w-10 h-10 cursor-pointer font-medium bg-blue-button border-0 text-white">
                  1
                </div>
              )}
            </div>

            <Pagination.NextButton
              className={`w-8 md:w-10 h-8 md:h-10 rounded-lg border-solid border border-primary  ${
                canNextPage ? 'cursor-pointer' : 'cursor-not-allowed'
              }`}
            >
              <img src={NextIcon} className="h-3 w-3" alt="arrNext" />
            </Pagination.NextButton>
          </Pagination>
        ) : null}
      </div>
    </div>
  )
}

export default React.memo(BasicTable2)
