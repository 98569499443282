import { createAsyncThunk } from '@reduxjs/toolkit'
import repairTypes from '../../constant/store/repairTypes'
import repair from '../../apis/repairApis'

export const getCompanyRepair = createAsyncThunk(repairTypes.GET_COMPANY_REPAIR, async (data, { rejectWithValue }) => {
  try {
    const result = await repair.getCompanyRepair(data.id, data.options)
    if (result.status === 200) {
      return result.data
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})
export const createCompanyRepair = createAsyncThunk(
  repairTypes.CREATE_COMPANY_REPAIR,
  async (data, { rejectWithValue }) => {
    try {
      const result = await repair.createCompanyRepair(data)
      if (result.status === 201) {
        return result.data
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
export const updateCompanyRepair = createAsyncThunk(
  repairTypes.UPDATE_COMPANY_REPAIR,
  async (data, { rejectWithValue }) => {
    try {
      const result = await repair.updateCompanyRepair(data)
      if (result.status === 200) {
        return result.data
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
export const deleteCompanyRepair = createAsyncThunk(
  repairTypes.DELETE_COMPANY_REPAIR,
  async (data, { rejectWithValue }) => {
    try {
      const result = await repair.deleteCompanyRepair(data)
      if (result.status === 200) {
        return { id: data.id }
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
export const getAllCompanyRepair = createAsyncThunk(
  repairTypes.GET_All_COMPANY_REPAIR,
  async (data, { rejectWithValue }) => {
    try {
      const result = await repair.getAllCompanyRepair(data)
      if (result.status === 200) {
        return result.data
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
