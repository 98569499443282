import React, { useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import './styles.css'
function ShadowBox({ children, wrapper, backgroundColor, shadowBoxOpen }) {
  const nodeRef = useRef(null)

  return (
    <CSSTransition in={shadowBoxOpen} nodeRef={nodeRef} timeout={150} classNames="shadow-box" unmountOnExit>
      <div ref={nodeRef} className={`absolute shadow-box rounded-lg ${wrapper}`} style={{ backgroundColor }}>
        {children}
      </div>
    </CSSTransition>
  )
}

export default ShadowBox
