import React from 'react'
import { DatePicker, Space } from 'antd'
import moment from 'moment'
import './style.css'
const { RangePicker } = DatePicker
function DatePickerRange(props) {
  const { onChange, value } = props

  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      let dateFrom = new Date(dateStrings[0]).toISOString()
      let newDayto = new Date(dateStrings[1])
      newDayto.setUTCHours(23, 59, 59, 999)
      const dateTo = newDayto.toISOString()
      onChange(dateFrom, dateTo, dateStrings)
    } else {
      onChange()
    }
  }

  const disabledDate = (current) => {
    // Can not select days after today
    return current && current > moment().endOf('day')
  }

  return (
    <Space direction="vertical" size={12}>
      <RangePicker
        style={{ borderRadius: '1rem', paddingTop: 8, paddingBottom: 8 }}
        onChange={onRangeChange}
        disabledDate={disabledDate}
        value={value}
      />
    </Space>
  )
}

export default DatePickerRange
