const inspectionTypes = {
  CHANGE_STATUS_BUILDING: 'CHANGE_STATUS_BUILDING',
  REVERT_DELETE_BUILDING: 'REVERT_DELETE_BUILDING',
  GET_COMPANY_BUILDINGS: 'GET_COMPANY_BUILDINGS',
  GET_BUILDINGS: 'GET_BUILDINGS',
  GET_BUILDINGS_ACTIVE: 'GET_BUILDINGS_ACTIVE',
  GET_ROLE_BUILDING: 'GET_ROLE_BUILDING',
  GET_BUILDINGS_DETAILS: 'GET_BUILDINGS_DETAILS',
  CREATE_BUIDING: 'CREATE_BUIDING',
  UPDATE_BUIDING: 'UPDATE_BUIDING',
  UPDATE_BUIDING_AVATAR: 'UPDATE_BUIDING_AVATAR',
  DELETE_BUIDING: 'DELETE_BUIDING'
}

export default inspectionTypes
