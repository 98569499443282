import React, { useRef } from 'react'
import Textarea from '../Report/Textarea'

function Summary({ report, editMode, setValue, handleBreakLine }) {
  const refSummaryA = useRef()
  const refSummaryB = useRef()
  return (
    <>
      <ol className="toc-list" style={{ lineHeight: '30px' }}>
        <li id="SUMMARY-AND-RECOMMENDATION">
          <div className="font-bold">
            <h1 className="font-bold title">4.0 SUMMARY AND RECOMMENDATION</h1>
          </div>
        </li>
        {/* <ol> */}
        <li id="Summary">
          <div>
            <h2 className="font-bold pt-5 title">4.1 Summary</h2>
          </div>
        </li>
        <li id="Summary-container-div">
          <div className="py-2">
            <Textarea
              refTextArea={refSummaryA}
              id="refSummaryA"
              values={report?.summary}
              name="summary"
              setValue={setValue}
              editMode={editMode}
            />
            <div id="refSummaryA-div-container" className={editMode ? 'hidden' : ''}>
              <div id={`refSummaryA-div`}>{handleBreakLine(report?.summary)}</div>
            </div>
          </div>
        </li>

        <li id="Recommendations">
          <div>
            <h2 className="font-bold pt-5 title">4.2 Recommendations</h2>
          </div>
        </li>
        <li id="Recommendations-container-div">
          <div className="py-2">
            <Textarea
              refTextArea={refSummaryB}
              id="refSummaryB"
              values={report?.recommendations}
              name="recommendations"
              setValue={setValue}
              editMode={editMode}
            />
            <div id="refSummaryB-div-container" className={editMode ? 'hidden' : ''}>
              <div id={`refSummaryB-div`}>{handleBreakLine(report?.recommendations)}</div>
            </div>
          </div>
        </li>
        {/* </ol> */}
      </ol>
    </>
  )
}

export default Summary
