import { createAsyncThunk } from '@reduxjs/toolkit'
import company from '../../apis/companyApis'
import elevationApi from '../../apis/elevationApis'
import elevationTypes from '../../constant/store/elevationTypes'
import { uploadElevationImage, maskingElevationImage } from '../../apis/fileApis'
import imageCompression from 'browser-image-compression'
import { nanoid } from 'nanoid'

export const getElevationDetails = createAsyncThunk(
  elevationTypes.GET_ELEVATION_DETAILS,
  async (data, { rejectWithValue }) => {
    try {
      const result = await company.elevationDetails(data)
      if (result.status === 200) {
        return result.data
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
export const getFrameThermal = createAsyncThunk(elevationTypes.GET_FRAME_THERMAL, async (data, { rejectWithValue }) => {
  try {
    const result = await company.getFrameThermal(data)
    if (result.status === 200) {
      return result.data
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const createElevation = createAsyncThunk(elevationTypes.CREATE_ELEVATION, async (data, { rejectWithValue }) => {
  try {
    const result = await company.createElevation(data)
    if (result.status === 201) {
      return result.data
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const editElevation = createAsyncThunk(elevationTypes.UPDATE_ELEVATION, async (data, { rejectWithValue }) => {
  try {
    const result = await company.editElevation(data)
    if (result.status === 200) {
      return result.data
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const deleteElevation = createAsyncThunk(elevationTypes.DELETE_ELEVATION, async (data, { rejectWithValue }) => {
  try {
    const result = await company.deleteElevation(data)
    if (result.status === 200 || result.status === 201) {
      return data
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const getElevationsBuilding = createAsyncThunk(
  elevationTypes.GET_ELEVATIONS_BUILDING,
  async (data, { rejectWithValue }) => {
    try {
      const result = await elevationApi.getElevationsBuilding(data.id, data.options)
      if (result.status === 200) {
        return result.data
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const createAnnotationDroneElevation = createAsyncThunk(
  elevationTypes.CREATE_ANNOTATION_DRONE_ELEVATION,
  async (data, { rejectWithValue }) => {
    try {
      const result = await elevationApi.createAnnotationDroneElevation(data)
      if (result.status === 201) {
        return result.data
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const createAnnotationCloseRangeElevation = createAsyncThunk(
  elevationTypes.CREATE_ANNOTATION_CLOSE_RANGE_ELEVATION,
  async (data, { rejectWithValue }) => {
    try {
      const result = await elevationApi.createAnnotationCloseRangeElevation(data)
      if (result.status === 201) {
        return result.data
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const createCloseRangeInThumbnail = createAsyncThunk(
  elevationTypes.CREATE_CLOSE_RANGE_IN_THUMBNAIL,
  async (data, { rejectWithValue }) => {
    try {
      const result = await elevationApi.createCloseRangeInThumbnail(data)
      if (result.status === 200) {
        return result.data
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const createAnnotationUserElevation = createAsyncThunk(
  elevationTypes.CREATE_ANNOTATION_USER_ELEVATION,
  async (data, { rejectWithValue }) => {
    try {
      const result = await elevationApi.createAnnotationUserElevation(data)
      if (result.status === 201) {
        return result.data
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const updateCheckedDroneAnnotationElevation = createAsyncThunk(
  elevationTypes.UPDATE_CHECKED_DRONE_ANNOTATION_ELEVATION,
  async (data, { rejectWithValue }) => {
    try {
      const result = await elevationApi.updateCheckedDroneAnnotationElevation(data.elevation_id, data.url, data.check)
      if (result.status === 200 || result.status === 201) {
        return result.data
      }
      return result
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const updateCheckedUserAnnotationElevation = createAsyncThunk(
  elevationTypes.UPDATE_CHECKED_USER_ANNOTATION_ELEVATION,
  async (data, { rejectWithValue }) => {
    try {
      const result = await elevationApi.updateCheckedUserAnnotationElevation(data.elevation_id, data.url, data.check)
      if (result.status === 200) {
        return result.data
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
export const updateStatusCloseRangeDrone = createAsyncThunk(
  elevationTypes.UPDATE_STATUS_CLOSE_RANGE_DRONE,
  async (data, { rejectWithValue }) => {
    try {
      const result = await elevationApi.updateStatusCloseRangeDrone(data.elevation_id, data.url, data.closeRange)

      if (result.status === 200) {
        return {
          data: data,
          resp: result.data
        }
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const updateStatusCloseRangeManual = createAsyncThunk(
  elevationTypes.UPDATE_STATUS_CLOSE_RANGE_MANUAL,
  async (data, { rejectWithValue }) => {
    try {
      const result = await elevationApi.updateStatusCloseRangeManual(data.elevation_id, data.url, data.closeRange)

      if (result.status === 200) {
        return {
          data: data,
          resp: result.data
        }
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const updateElevationDetail = createAsyncThunk(
  elevationTypes.UPDATE_ELEVATION_DETAIL,
  async (data, { rejectWithValue, dispatch }) => {
    try {
      var resultUpload = null
      const options = {
        maxSizeMB: 1,
        initialQuality: 0.6,
        maxWidthOrHeight: 1920
      }

      if (data.upload === true) {
        // resultUpload = await uploadFile(data.file);
        const dataThumbnail = {
          filename: nanoid() + data.file.name,
          contentType: data.file.type,
          key: 'elevationThumbnail'
        }
        const { signedUrl } = await uploadElevationImage(dataThumbnail)
        resultUpload = signedUrl
        const compressedFile = await imageCompression(data.file, options)
        await fetch(signedUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': data.file.type
          },
          body: compressedFile
        })
      }
      let dataReq = null

      dataReq = {
        elevation_id: data.elevation_id,
        name: data.name,
        url: resultUpload ? resultUpload.split('?')[0] : data.file
      }

      const result = await elevationApi.updateElevationDetail(dataReq)
      if (result.status === 200 || result.status === 201) {
        return result.data
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
export const updateElevationThumbnail = createAsyncThunk(
  elevationTypes.UPDATE_ELEVATION_THUMBNAIL,
  async (data, { rejectWithValue, dispatch }) => {
    try {
      var resultUpload = null
      const options = {
        maxSizeMB: 1,
        initialQuality: 0.6,
        maxWidthOrHeight: 1920
      }

      if (data.upload === true) {
        // resultUpload = await uploadFile(data.file);
        const dataThumbnail = {
          filename: nanoid() + data.file.name,
          contentType: data.file.type,
          key: 'elevationThumbnail'
        }
        const { signedUrl } = await uploadElevationImage(dataThumbnail)
        resultUpload = signedUrl
        const compressedFile = await imageCompression(data.file, options)
        await fetch(signedUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': data.file.type
          },
          body: compressedFile
        })
      }

      let dataReq = {
        elevation_id: data.elevation_id,
        name: data.name,
        avatarUrl: data.avatarUrl,
        url: resultUpload ? resultUpload.split('?')[0] : ''
      }

      const result = await elevationApi.updateElevationDetail(dataReq)
      if (result.status === 200 || result.status === 201) {
        return result.data
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const updateElevationAvatar = createAsyncThunk(
  elevationTypes.UPDATE_ELEVATION_AVATAR,
  async (data, { rejectWithValue, dispatch }) => {
    try {
      var resultUpload = null
      const options = {
        maxSizeMB: 1,
        initialQuality: 0.6,
        maxWidthOrHeight: 1920
      }
      if (data.upload === true) {
        // resultUpload = await uploadFile(data.file);
        const dataThumbnail = {
          filename: nanoid() + data.file.name,
          contentType: data.file.type,
          key: 'elevationThumbnail'
        }
        const { signedUrl } = await uploadElevationImage(dataThumbnail)
        resultUpload = signedUrl
        const compressedFile = await imageCompression(data.file, options)
        await fetch(signedUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': data.file.type
          },
          body: compressedFile
        })
      }
      const dataReq = {
        elevation_id: data.elevation_id,
        name: data.name,
        url: resultUpload ? resultUpload.split('?')[0] : data.file
      }
      const result = await elevationApi.updateElevationDetail(dataReq)
      if (result.status === 200 || result.status === 201) {
        return result.data
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const deleteCloseRangeElevation = createAsyncThunk(
  elevationTypes.DELETE_CLOSE_RANGE_ELEVATION,
  async (data, { rejectWithValue }) => {
    try {
      const result = await elevationApi.deleteCloseRangeElevation(data)
      if (result.status === 200 || result.status === 201) {
        return result.data
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
export const setElevationStatus = createAsyncThunk(
  elevationTypes.SET_ELEVATION_STATUS,
  async (data, { rejectWithValue }) => {
    try {
      const result = await elevationApi.setElevationStatus(data)
      if (result.status === 200 || result.status === 201) {
        return result.data
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const maskingElevation = createAsyncThunk(
  elevationTypes.AI_MASKING_ELEVATION,
  async (data, { rejectWithValue }) => {
    try {
      const result = await maskingElevationImage(data)
      if (result.status === 200 || result.status === 201) {
        return result.data
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
export const resetElevation = createAsyncThunk(elevationTypes.RESET_ELEVATION, async (data, { rejectWithValue }) => {
  try {
    return data
  } catch (error) {
    return rejectWithValue(error)
  }
})
