/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import YupPassword from 'yup-password'
import ChangePassword from './ChangePassword'
import { useDispatch } from 'react-redux'

// assets

// actions
import { verifyEmailSend } from '../../store/thunks/userThunk'
import AuthLayout from '../Layout/AuthLayout'

YupPassword(Yup)

function PasswordResetId() {
  const { search } = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [emailKey, setEmailKey] = useState('')
  const [_mounted, setMounted] = useState(false)
  const [error, setError] = useState(false)
  const getQueryParams = (url) => {
    const paramArr = url.slice(url.indexOf('?') + 1).split('&')
    const params = {}
    paramArr.map((param) => {
      const [key, val] = param.split('=')
      params[key] = decodeURIComponent(val)
    })
    return params
  }

  const verifyKeyMail = async () => {
    const { username, keyMail } = getQueryParams(search)
    if (!username || !keyMail) {
      navigate('/login')
      return false
    }
    setEmail(username)
    setEmailKey(keyMail)
    const payload = { email: username, keySendMail: keyMail }
    try {
      dispatch(verifyEmailSend(payload))
        .unwrap()
        .then(() => {
          setMounted(true)
        })
        .catch(() => {
          setError(true)
          setMounted(true)
        })
    } catch (e) {
      navigate('/login')
    }
  }

  useEffect(() => {
    verifyKeyMail()
  }, [])

  return (
    <div>
      {_mounted && (
        <AuthLayout>
          {!error ? (
            <ChangePassword isPassword={true} checkingEmail={email.trim()} keyMail={emailKey.trim()} />
          ) : (
            <>
              <div className="text-xl mt-4 font-bold flex justify-center">Something went wrong. Please try again!</div>
              <Link className=" text-white hover:text-white" to="/login">
                <button
                  className="w-full mt-8 px-4 py-2 font-bold  bg-blue-500 rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline"
                  type="submit"
                >
                  RETURN TO LOG-IN
                </button>
              </Link>
            </>
          )}
          <p className="text-xs text-center text-gray-500 mt-12">&copy;2023 Axpara. All rights reserved.</p>
        </AuthLayout>
      )}
    </div>
  )
}

export default PasswordResetId
