import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
function SkeletonViewer(props) {
  const skeletonProps = {
    widthRandomness: 0,
    borderRadius: 0,
    ...props
  }
  return (
    <SkeletonTheme>
      <p>
        <Skeleton {...skeletonProps} style={{ lineHeight: 'normal' }} />
      </p>
    </SkeletonTheme>
  )
}

export default SkeletonViewer
