import { createSlice } from '@reduxjs/toolkit'
import {
  getCompanies,
  getCompanyActive,
  createCompany,
  updateCompany,
  deleteCompany,
  revertDeleteCompany,
  updateCompanyAvatar
} from '../thunks/companyThunk'

import companyTypes from '../../constant/store/companyTypes'

const companySlice = createSlice({
  name: 'company',
  initialState: {
    companyActive: [],
    companies: null,
    fetchLoading: false,
    modifyLoading: false,
    fetchError: '',
    modifyError: '',
    types: [],
    loadings: {},
    errors: {},
    contents: {},
    paginations: {}
  },
  extraReducers: {
    // COMPANY ACTION TYPE: Get all companies
    [getCompanies.pending]: (state) => {
      state.loadings[companyTypes.GET_COMPANIES] = true
    },
    [getCompanies.fulfilled]: (state, { payload }) => {
      state.loadings[companyTypes.GET_COMPANIES] = false
      state.contents[companyTypes.GET_COMPANIES] = payload.companies
      state.paginations[companyTypes.GET_COMPANIES] = payload.pagination
      state.errors[companyTypes.GET_COMPANIES] = ''
    },
    [getCompanies.rejected]: (state, { payload }) => {
      state.loadings[companyTypes.GET_COMPANIES] = false
      state.errors[companyTypes.GET_COMPANIES] = payload
    },
    // COMPANY ACTION TYPE: Get all companies
    [getCompanyActive.pending]: (state) => {
      state.loadings[companyTypes.GET_COMPANY_ACTIVE] = true
    },
    [getCompanyActive.fulfilled]: (state, { payload }) => {
      state.loadings[companyTypes.GET_COMPANY_ACTIVE] = false
      state.contents[companyTypes.GET_COMPANY_ACTIVE] = payload.companies
      state.errors[companyTypes.GET_COMPANY_ACTIVE] = ''
    },
    [getCompanyActive.rejected]: (state, { payload }) => {
      state.loadings[companyTypes.GET_COMPANY_ACTIVE] = false
      state.errors[companyTypes.GET_COMPANY_ACTIVE] = payload
    },
    //ACTION TYPE: Create new company
    [createCompany.pending]: (state) => {
      state.loadings[companyTypes.CREATE_COMPANY] = true
    },
    [createCompany.fulfilled]: (state, { payload }) => {
      state.loadings[companyTypes.CREATE_COMPANY] = false
      state.contents[companyTypes.GET_COMPANIES] = [payload.company, ...state.contents[companyTypes.GET_COMPANIES]]
      state.errors[companyTypes.CREATE_COMPANY] = ''
    },
    [createCompany.rejected]: (state, { payload }) => {
      state.loadings[companyTypes.CREATE_COMPANY] = false
      state.errors[companyTypes.CREATE_COMPANY] = payload.message
    },
    //ACTION TYPE: Update company
    [updateCompany.pending]: (state) => {
      state.loadings[companyTypes.UPDATE_COMPANY] = true
    },
    [updateCompany.fulfilled]: (state, { payload }) => {
      state.loadings[companyTypes.UPDATE_COMPANY] = false
      const index = state.contents[companyTypes.GET_COMPANIES].findIndex((company) => company.id === payload.company.id)
      state.contents[companyTypes.GET_COMPANIES].splice(index, 1, payload.company)
      state.errors[companyTypes.UPDATE_COMPANY] = ''
    },
    [updateCompany.rejected]: (state, { payload }) => {
      state.loadings[companyTypes.UPDATE_COMPANY] = false
      state.errors[companyTypes.UPDATE_COMPANY] = payload
    },
    //ACTION TYPE: Update avatar company
    [updateCompanyAvatar.pending]: (state) => {
      state.loadings[companyTypes.UPDATE_COMPANY_AVATAR] = true
    },
    [updateCompanyAvatar.fulfilled]: (state, { payload }) => {
      state.loadings[companyTypes.UPDATE_COMPANY_AVATAR] = false
      const index = state.contents[companyTypes.GET_COMPANIES].findIndex((company) => company.id === payload.company.id)
      state.contents[companyTypes.GET_COMPANIES][index].thumbnail = payload.company.thumbnail
      state.errors[companyTypes.UPDATE_COMPANY_AVATAR] = ''
    },
    [updateCompanyAvatar.rejected]: (state, { payload }) => {
      state.loadings[companyTypes.UPDATE_COMPANY_AVATAR] = false
      state.errors[companyTypes.UPDATE_COMPANY_AVATAR] = payload
    },
    //ACTION TYPE: Delete company

    [deleteCompany.pending]: (state) => {
      state.loadings[companyTypes.DELETE_COMPANY] = true
    },
    [deleteCompany.fulfilled]: (state, { payload }) => {
      state.loadings[companyTypes.DELETE_COMPANY] = false
      const index = state.contents[companyTypes.GET_COMPANIES].findIndex((company) => company.id === payload)
      const updateData = { ...state.contents[companyTypes.GET_COMPANIES][index], status: 'Inactive' }
      state.contents[companyTypes.GET_COMPANIES].splice(index, 1, updateData)
      state.errors[companyTypes.DELETE_COMPANY] = ''
    },
    [deleteCompany.rejected]: (state, { payload }) => {
      state.loadings[companyTypes.DELETE_COMPANY] = false
      state.errors[companyTypes.DELETE_COMPANY] = payload.message
    },

    //ACTION TYPE: Revert Delete company
    [revertDeleteCompany.pending]: (state) => {
      state.loadings[companyTypes.REVERT_DELETE_COMPANY] = true
    },
    [revertDeleteCompany.fulfilled]: (state, { payload }) => {
      const conpanyIndex = state.contents[companyTypes.GET_COMPANIES].findIndex((company) => company.id === payload.id)
      const companyChange = state.contents[companyTypes.GET_COMPANIES].find((company) => company.id === payload.id)
      const newBuilding = { ...companyChange, status: 'Active' }

      state.loadings[companyTypes.REVERT_DELETE_COMPANY] = false
      state.contents[companyTypes.GET_COMPANIES][conpanyIndex] = newBuilding
      state.errors[companyTypes.REVERT_DELETE_COMPANY] = ''
    },
    [revertDeleteCompany.rejected]: (state, { payload }) => {
      state.loadings[companyTypes.REVERT_DELETE_COMPANY] = false
      state.errors[companyTypes.REVERT_DELETE_COMPANY] = payload.message
    }
  }
})
export default companySlice.reducer
