import React from 'react'

function Classification({ defectSummary }) {
  return (
    <>
      <ol className="toc-list" style={{ lineHeight: '30px' }}>
        <div className="pt-10 pb-4 text-center text-xl font-bold">
          <h1 className="title">TABLE 1 : VISUAL INSPECTION SUMMARY</h1>
        </div>

        {defectSummary && defectSummary.length > 0 ? (
          <div className="py-10" style={{ fontSize: '16px', lineHeight: 'normal' }}>
            <table className="border-[1px] w-full table-test" style={{ fontSize: '12px' }}>
              <tr className="border-[1px]">
                <th className="border-[1px] px-1 py-[6px] w-[40px]">S/N</th>
                <th className="border-[1px] px-1 py-[6px] w-[107px]">Elevation</th>
                <th className="border-[1px] px-1 py-[6px] w-[110px]">Element</th>
                <th className="border-[1px] px-1 py-[6px] w-[110px]">Defect</th>
                <th className="border-[1px] px-1 py-[6px] w-[76px]">Severity</th>
                <th className="border-[1px] px-1 py-[6px] w-[51px]">Defect Count</th>
                <th className="border-[1px] px-1 py-[6px] w-[220px]">Photo # (Record)</th>
                <th className="border-[1px] px-1 py-[6px] w-[220px]">Reason(s) for Defects</th>
              </tr>

              {defectSummary.map((defectSummary, index) => (
                <>
                  <tr>
                    <td className="border-[1px] px-1 py-[6px] text-center">{index + 1}</td>
                    <td className="border-[1px] px-1 py-[6px] text-center">{defectSummary?.elevation_name}</td>
                    <td className="border-[1px] px-1 py-[6px] text-center">{defectSummary?.element}</td>
                    <td className="border-[1px] px-1 py-[6px] text-center">{defectSummary?.tags}</td>
                    <td className="border-[1px] px-1 py-[6px] text-center">{defectSummary?.cls}</td>
                    <td className="border-[1px] px-1 py-[6px] text-center">{defectSummary?.count}</td>
                    <td className="border-[1px] px-1 py-[6px] text-center">
                      {defectSummary?.photos?.map((photo, index) => (
                        <>
                          {photo}
                          {index === defectSummary?.photos?.length - 1 ? '' : ', '}
                        </>
                      ))}
                    </td>
                    <td className="border-[1px] px-1 py-[6px] text-center">
                      {defectSummary?.reasons?.map((reason, index) => (
                        <>
                          {reason?.label}
                          {index === defectSummary?.reasons?.length - 1 ? '' : ', '}
                        </>
                      ))}
                    </td>
                  </tr>
                </>
              ))}
            </table>
          </div>
        ) : (
          <div className="font-bold text-center">Visual Inspection Data is not included in this report</div>
        )}
      </ol>
    </>
  )
}

export default Classification
