import aws from 'aws-sdk'
import { makeid } from './common'

const region = process.env.REACT_APP_AWS_REGION
const bucketName = process.env.REACT_APP_AWS_BUCKET_NAME
const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID
const secretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY

const s3 = new aws.S3({
  region,
  accessKeyId,
  secretAccessKey,
  signatureVersion: 'v4'
})

export async function generateUploadURL(name) {
  const imageName = makeid(30)
  const key = `${imageName}/${name}`

  const params = {
    Bucket: bucketName,
    Key: key,
    Expires: 60
  }

  const uploadURL = await s3.getSignedUrlPromise('putObject', params)
  return uploadURL
}

export const uploadFileAWS = async (file, name) => {
  const params = {
    Bucket: bucketName,
    Body: file,
    Key: name
  }

  const res = await s3
    .putObject(params)
    .on('httpUploadProgress', (evt) => {
    })
    .send((err) => {
    })
  return res
}
