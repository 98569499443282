import { createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import userApis from '../../apis/userApis'
import userTypes from '../../constant/store/userTypes'

// thunk user

export const clearErrors = createAsyncThunk(userTypes.CLEAR_ERRORS, async (data, { rejectWithValue }) => {
  try {
    return data
  } catch (error) {
    return rejectWithValue(error)
  }
})
export const getRoleAdmin = createAsyncThunk(userTypes.GET_ROLE_ADMIN, async (data, { rejectWithValue }) => {
  try {
    const result = await userApis.getRoleAdmin()
    if (result.status === 200) {
      return result.data
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const getRoleUser = createAsyncThunk(userTypes.GET_ROLE_USER, async (data, { rejectWithValue }) => {
  try {
    const result = await userApis.getRoleUser()
    if (result.status === 200) {
      return result.data
    }
    return result
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const getUsers = createAsyncThunk(userTypes.GET_USER, async (data, { rejectWithValue }) => {
  try {
    const result = await userApis.getUsers(data.roletab, data.options)
    if (result.status === 200) {
      return result.data
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const createUser = createAsyncThunk(userTypes.CREATE_USER, async (data, { rejectWithValue, dispatch }) => {
  try {
    const result = await userApis.createUser(data)
    if (result.status === 200 || result.status === 201) {
      return result.data.data
    }
  } catch (error) {
    return rejectWithValue(error.message)
  }
})

export const updateUser = createAsyncThunk(userTypes.UPDATE_USER, async (data, { rejectWithValue, dispatch }) => {
  try {
    const result = await userApis.updateUser(data)
    if (result.status === 201 || result.status === 200) {
      return result.data.data
    }
  } catch (error) {
    return rejectWithValue(error.message)
  }
})

export const deleteUser = createAsyncThunk('user/deleteUser', async (data, { rejectWithValue, dispatch }) => {
  try {
    const { id } = data
    await userApis.deleteUser(id)
    toast.success('User deleted')
  } catch (error) {
    toast.error(error.message)
    return rejectWithValue(error.message)
  }
})

export const checkEmailUser = createAsyncThunk('user/send-mail-otp', async (data, { rejectWithValue, dispatch }) => {
  try {
    const result = await userApis.getEmail(data)
    // toast.success("User created")
    return result
  } catch (error) {
    toast.error(error.message)
    return rejectWithValue(error.message)
  }
})
export const forgetPassword = createAsyncThunk('FORGET_PASSWORD', async (data, { rejectWithValue, dispatch }) => {
  try {
    const result = await userApis.forgetPassword(data)
    // toast.success("User created")
    return result
  } catch (error) {
    toast.error(error.message)
    return rejectWithValue(error.message)
  }
})

export const verifyOtp = createAsyncThunk('user/verify-otp', async (data, { rejectWithValue, dispatch }) => {
  try {
    const result = await userApis.VerifyOtp(data)
    // toast.success("User created")
    return result
  } catch (error) {
    toast.error(error.message)
    return rejectWithValue(error.message)
  }
})

export const changePassword = createAsyncThunk('user/reset-Password', async (data, { rejectWithValue, dispatch }) => {
  try {
    const result = await userApis.changePassword(data)
    return result
  } catch (e) {
    toast.error(e.message)
    return rejectWithValue(e.message)
  }
})

export const verifyEmailSend = createAsyncThunk('user/check-send-mail', async (data, { rejectWithValue, dispatch }) => {
  try {
    const result = await userApis.verifyEmailSend(data)
    return result
  } catch (error) {
    return rejectWithValue(error.message)
  }
})

export const getUsersCompany = createAsyncThunk(userTypes.GET_USER_COMPANY, async (data, { rejectWithValue }) => {
  try {
    const result = await userApis.getUsersCompany(data.id, data.options)
    if (result.status === 200) {
      return result.data
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})
