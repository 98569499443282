import { createSlice } from '@reduxjs/toolkit'
import {
  getElevationLog,
  getInpsectionLog,
  getImageLog,
  getCompanyLog,
  getUserLog,
  getElevationLogAll,
  getImageLogAll,
  getTrackUserLog,
  getDownloadCompany,
  getDownloadInspection,
  getDownloadUser,
  getDownloadElevation,
  getDownloadImage,
  getDownloadTrackUser
} from '../thunks/logThunk'
import logTypes from '../../constant/store/logTypes'

const elevationSlice = createSlice({
  name: 'log',
  initialState: {
    loadings: {},
    errors: {},
    contents: {},
    paginations: {}
  },
  extraReducers: {
    // ACTION TYPE: get Inpsection Log
    [getInpsectionLog.pending]: (state) => {
      state.loadings[logTypes.GET_INSPECTION_LOG] = true
    },
    [getInpsectionLog.fulfilled]: (state, { payload }) => {
      state.loadings[logTypes.GET_INSPECTION_LOG] = false
      state.contents[logTypes.GET_INSPECTION_LOG] = payload.data
      state.paginations[logTypes.GET_INSPECTION_LOG] = payload.pagination
      state.errors[logTypes.GET_INSPECTION_LOG] = ''
    },
    [getInpsectionLog.rejected]: (state, { payload }) => {
      state.loadings[logTypes.GET_INSPECTION_LOG] = false
      state.errors[logTypes.GET_INSPECTION_LOG] = payload.message
    },
    // ACTION TYPE: get elevation Log
    [getElevationLog.pending]: (state) => {
      state.loadings[logTypes.GET_ELEVATION_LOG] = true
    },
    [getElevationLog.fulfilled]: (state, { payload }) => {
      state.loadings[logTypes.GET_ELEVATION_LOG] = false
      state.contents[logTypes.GET_ELEVATION_LOG] = payload.data
      state.paginations[logTypes.GET_ELEVATION_LOG] = payload.pagination
      state.errors[logTypes.GET_ELEVATION_LOG] = ''
    },
    [getElevationLog.rejected]: (state, { payload }) => {
      state.loadings[logTypes.GET_ELEVATION_LOG] = false
      state.errors[logTypes.GET_ELEVATION_LOG] = payload.message
    },
    // ACTION TYPE: get all elevation Log
    [getElevationLogAll.pending]: (state) => {
      state.loadings[logTypes.GET_ELEVATION_LOG_All] = true
    },
    [getElevationLogAll.fulfilled]: (state, { payload }) => {
      state.loadings[logTypes.GET_ELEVATION_LOG_All] = false
      state.contents[logTypes.GET_ELEVATION_LOG_All] = payload.data
      state.paginations[logTypes.GET_ELEVATION_LOG_All] = payload.pagination
      state.errors[logTypes.GET_ELEVATION_LOG_All] = ''
    },
    [getElevationLogAll.rejected]: (state, { payload }) => {
      state.loadings[logTypes.GET_ELEVATION_LOG_All] = false
      state.errors[logTypes.GET_ELEVATION_LOG_All] = payload.message
    },
    // ACTION TYPE: get Image Drone Log
    [getImageLog.pending]: (state) => {
      state.loadings[logTypes.GET_IMAGE_LOG] = true
    },
    [getImageLog.fulfilled]: (state, { payload }) => {
      state.loadings[logTypes.GET_IMAGE_LOG] = false
      state.contents[logTypes.GET_IMAGE_LOG] = payload.data
      state.paginations[logTypes.GET_IMAGE_LOG] = payload.pagination
      state.errors[logTypes.GET_IMAGE_LOG] = ''
    },
    [getImageLog.rejected]: (state, { payload }) => {
      state.loadings[logTypes.GET_IMAGE_LOG] = false
      state.errors[logTypes.GET_IMAGE_LOG] = payload.message
    },
    // ACTION TYPE: get all Drone Log
    [getImageLogAll.pending]: (state) => {
      state.loadings[logTypes.GET_IMAGE_LOG_ALL] = true
    },
    [getImageLogAll.fulfilled]: (state, { payload }) => {
      state.loadings[logTypes.GET_IMAGE_LOG_ALL] = false
      state.contents[logTypes.GET_IMAGE_LOG_ALL] = payload.data
      state.paginations[logTypes.GET_IMAGE_LOG_ALL] = payload.pagination
      state.errors[logTypes.GET_IMAGE_LOG_ALL] = ''
    },
    [getImageLogAll.rejected]: (state, { payload }) => {
      state.loadings[logTypes.GET_IMAGE_LOG_ALL] = false
      state.errors[logTypes.GET_IMAGE_LOG_ALL] = payload.message
    },
    // ACTION TYPE: get company Log
    [getCompanyLog.pending]: (state) => {
      state.loadings[logTypes.GET_COMPANY_LOG] = true
    },
    [getCompanyLog.fulfilled]: (state, { payload }) => {
      state.loadings[logTypes.GET_COMPANY_LOG] = false
      state.contents[logTypes.GET_COMPANY_LOG] = payload.data
      state.paginations[logTypes.GET_COMPANY_LOG] = payload.pagination
      state.errors[logTypes.GET_COMPANY_LOG] = ''
    },
    [getCompanyLog.rejected]: (state, { payload }) => {
      state.loadings[logTypes.GET_COMPANY_LOG] = false
      state.errors[logTypes.GET_COMPANY_LOG] = payload.message
    },
    // ACTION TYPE: get user Log
    [getUserLog.pending]: (state) => {
      state.loadings[logTypes.GET_USER_LOG] = true
    },
    [getUserLog.fulfilled]: (state, { payload }) => {
      state.loadings[logTypes.GET_USER_LOG] = false
      state.contents[logTypes.GET_USER_LOG] = payload.data
      state.paginations[logTypes.GET_USER_LOG] = payload.pagination
      state.errors[logTypes.GET_USER_LOG] = ''
    },
    [getUserLog.rejected]: (state, { payload }) => {
      state.loadings[logTypes.GET_USER_LOG] = false
      state.errors[logTypes.GET_USER_LOG] = payload.message
    },
    // ACTION TYPE: get track user  Log
    [getTrackUserLog.pending]: (state) => {
      state.loadings[logTypes.GET_TRACK_USER_LOG] = true
    },
    [getTrackUserLog.fulfilled]: (state, { payload }) => {
      state.loadings[logTypes.GET_TRACK_USER_LOG] = false
      state.contents[logTypes.GET_TRACK_USER_LOG] = payload.data
      state.paginations[logTypes.GET_TRACK_USER_LOG] = payload.pagination
      state.errors[logTypes.GET_TRACK_USER_LOG] = ''
    },
    [getTrackUserLog.rejected]: (state, { payload }) => {
      state.loadings[logTypes.GET_TRACK_USER_LOG] = false
      state.errors[logTypes.GET_TRACK_USER_LOG] = payload.message
    },
    // ACTION TYPE: get data download company
    [getDownloadCompany.pending]: (state) => {
      state.loadings[logTypes.GET_DOWNLOAD_COMPANY] = true
    },
    [getDownloadCompany.fulfilled]: (state, { payload }) => {
      state.loadings[logTypes.GET_DOWNLOAD_COMPANY] = false
      state.contents[logTypes.GET_DOWNLOAD_COMPANY] = payload.data
      state.paginations[logTypes.GET_DOWNLOAD_COMPANY] = payload.pagination
      state.errors[logTypes.GET_DOWNLOAD_COMPANY] = ''
    },
    [getDownloadCompany.rejected]: (state, { payload }) => {
      state.loadings[logTypes.GET_DOWNLOAD_COMPANY] = false
      state.errors[logTypes.GET_DOWNLOAD_COMPANY] = payload.message
    },
    // ACTION TYPE: get data download company
    [getDownloadInspection.pending]: (state) => {
      state.loadings[logTypes.GET_DOWNLOAD_INSPECTION] = true
    },
    [getDownloadInspection.fulfilled]: (state, { payload }) => {
      state.loadings[logTypes.GET_DOWNLOAD_INSPECTION] = false
      state.contents[logTypes.GET_DOWNLOAD_INSPECTION] = payload.data
      state.paginations[logTypes.GET_DOWNLOAD_INSPECTION] = payload.pagination
      state.errors[logTypes.GET_DOWNLOAD_INSPECTION] = ''
    },
    [getDownloadInspection.rejected]: (state, { payload }) => {
      state.loadings[logTypes.GET_DOWNLOAD_INSPECTION] = false
      state.errors[logTypes.GET_DOWNLOAD_INSPECTION] = payload.message
    },
    // ACTION TYPE: get data download user
    [getDownloadUser.pending]: (state) => {
      state.loadings[logTypes.GET_DOWNLOAD_USER] = true
    },
    [getDownloadUser.fulfilled]: (state, { payload }) => {
      state.loadings[logTypes.GET_DOWNLOAD_USER] = false
      state.contents[logTypes.GET_DOWNLOAD_USER] = payload.data
      state.paginations[logTypes.GET_DOWNLOAD_USER] = payload.pagination
      state.errors[logTypes.GET_DOWNLOAD_USER] = ''
    },
    [getDownloadUser.rejected]: (state, { payload }) => {
      state.loadings[logTypes.GET_DOWNLOAD_USER] = false
      state.errors[logTypes.GET_DOWNLOAD_USER] = payload.message
    },
    // ACTION TYPE: get data download elevation
    [getDownloadElevation.pending]: (state) => {
      state.loadings[logTypes.GET_DOWNLOAD_ELEVATION] = true
    },
    [getDownloadElevation.fulfilled]: (state, { payload }) => {
      state.loadings[logTypes.GET_DOWNLOAD_ELEVATION] = false
      state.contents[logTypes.GET_DOWNLOAD_ELEVATION] = payload.data
      state.paginations[logTypes.GET_DOWNLOAD_ELEVATION] = payload.pagination
      state.errors[logTypes.GET_DOWNLOAD_ELEVATION] = ''
    },
    [getDownloadElevation.rejected]: (state, { payload }) => {
      state.loadings[logTypes.GET_DOWNLOAD_ELEVATION] = false
      state.errors[logTypes.GET_DOWNLOAD_ELEVATION] = payload.message
    },
    // ACTION TYPE: get data download image
    [getDownloadImage.pending]: (state) => {
      state.loadings[logTypes.GET_DOWNLOAD_IMAGE] = true
    },
    [getDownloadImage.fulfilled]: (state, { payload }) => {
      state.loadings[logTypes.GET_DOWNLOAD_IMAGE] = false
      state.contents[logTypes.GET_DOWNLOAD_IMAGE] = payload.data
      state.paginations[logTypes.GET_DOWNLOAD_IMAGE] = payload.pagination
      state.errors[logTypes.GET_DOWNLOAD_IMAGE] = ''
    },
    [getDownloadImage.rejected]: (state, { payload }) => {
      state.loadings[logTypes.GET_DOWNLOAD_IMAGE] = false
      state.errors[logTypes.GET_DOWNLOAD_IMAGE] = payload.message
    },
    // ACTION TYPE: get data download track user
    [getDownloadTrackUser.pending]: (state) => {
      state.loadings[logTypes.GET_DOWNLOAD_TRACK_USER] = true
    },
    [getDownloadTrackUser.fulfilled]: (state, { payload }) => {
      state.loadings[logTypes.GET_DOWNLOAD_TRACK_USER] = false
      state.contents[logTypes.GET_DOWNLOAD_TRACK_USER] = payload.data
      state.paginations[logTypes.GET_DOWNLOAD_TRACK_USER] = payload.pagination
      state.errors[logTypes.GET_DOWNLOAD_TRACK_USER] = ''
    },
    [getDownloadTrackUser.rejected]: (state, { payload }) => {
      state.loadings[logTypes.GET_DOWNLOAD_TRACK_USER] = false
      state.errors[logTypes.GET_DOWNLOAD_TRACK_USER] = payload.message
    }
  }
})
export default elevationSlice.reducer
