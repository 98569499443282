import React, { useEffect, useRef, useState } from 'react'
import Textarea from '../Report/Textarea'
import { DeleteOutlined } from '@ant-design/icons'
import { onConfirm } from 'react-confirm-pro'
import LoadingAnimated from '../../assets/images/loading_new.gif'
import { checkImageFormat } from '../../utils/fileUtils'
import { typeFile } from '../../constant/file'
function UASInspectionReport({
  editMode,
  report,
  building,
  setValue,
  name,
  handleBreakLine,
  onDeleteLayout,
  onDeleteBuildings,
  onDeleteOthers
}) {
  const refBuilding1 = useRef()
  const refBuilding2 = useRef()

  const refUAS1 = useRef()
  const refUAS2 = useRef()
  const refUAS3 = useRef()
  const refUAS4 = useRef()
  const refUAS5 = useRef()
  const refUAS6 = useRef()
  const refUAS7 = useRef()

  const [uploadImageB3, setUploadImageB3] = useState([])
  const [uploadImageB4, setUploadImageB4] = useState([])
  const [uploadImageB5, setUploadImageB5] = useState([])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (report?.site_layout) {
      setUploadImageB3(report?.site_layout)
    }
  }, [report?.site_layout])
  useEffect(() => {
    if (report?.site_buildings) {
      setUploadImageB4(report?.site_buildings)
    }
  }, [report?.site_buildings])
  useEffect(() => {
    if (report?.site_others) {
      setUploadImageB5(report?.site_others)
    }
  }, [report?.site_others])
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = reject
    })

  const handleUploadImageB3 = async (e) => {
    e.preventDefault()
    var files = e.target.files
    const isWrongFormatFile = checkImageFormat(e.target.files)
    if (isWrongFormatFile) {
      return onConfirm({
        title: <h3 className="text-center">{`The images must be a file of type: ${typeFile.join()}`}</h3>,
        description: <br />,

        onCancel: () => { },
        buttons: ({ onCancel }) => (
          <div className="custom-buttons">
            <button
              style={{
                background: 'rgba(37, 99, 235)',
                color: 'white'
              }}
              onClick={onCancel}
            >
              Ok
            </button>
          </div>
        )
      })
    }
    setLoading(true)
    var tmp = []
    if (files && files.length) {
      for (const file of files) {
        const base64 = await toBase64(file)
        tmp.push({
          url: base64,
          file: file,
          title: '',
          comment: ''
        })
      }
    }

    setUploadImageB3([...uploadImageB3, ...tmp])
    setValue([...uploadImageB3, ...tmp], name['site_layout'])
    setLoading(false)
  }

  const handleUploadImageB4 = async (e) => {
    e.preventDefault()
    var files = e.target.files
    const isWrongFormatFile = checkImageFormat(e.target.files)
    if (isWrongFormatFile) {
      return onConfirm({
        title: <h3 className="text-center">{`The images must be a file of type: ${typeFile.join()}`}</h3>,
        description: <br />,

        onCancel: () => { },
        buttons: ({ onCancel }) => (
          <div className="custom-buttons">
            <button
              style={{
                background: 'rgba(37, 99, 235)',
                color: 'white'
              }}
              onClick={onCancel}
            >
              Ok
            </button>
          </div>
        )
      })
    }
    setLoading(true)
    var tmp = []
    if (files && files.length) {
      for (const file of files) {
        const base64 = await toBase64(file)
        tmp.push({
          url: base64,
          file: file,
          title: '',
          comment: ''
        })
      }
    }

    setUploadImageB4([...uploadImageB4, ...tmp])
    setValue([...uploadImageB4, ...tmp], name['site_buildings'])
    setLoading(false)
  }

  const handleUploadImageB5 = async (e) => {
    e.preventDefault()
    var files = e.target.files
    const isWrongFormatFile = checkImageFormat(e.target.files)
    if (isWrongFormatFile) {
      return onConfirm({
        title: <h3 className="text-center">{`The images must be a file of type: ${typeFile.join()}`}</h3>,
        description: <br />,

        onCancel: () => { },
        buttons: ({ onCancel }) => (
          <div className="custom-buttons">
            <button
              style={{
                background: 'rgba(37, 99, 235)',
                color: 'white'
              }}
              onClick={onCancel}
            >
              Ok
            </button>
          </div>
        )
      })
    }
    setLoading(true)
    var tmp = []
    if (files && files.length) {
      for (const file of files) {
        const base64 = await toBase64(file)
        tmp.push({
          url: base64,
          file: file,
          title: '',
          comment: ''
        })
      }
    }

    setUploadImageB5([...uploadImageB5, ...tmp])
    setValue([...uploadImageB5, ...tmp], name['site_others'])
    setLoading(false)
  }

  const handleTitleB3 = (e, i) => {
    e.preventDefault()
    var tmp = [...uploadImageB3]
    const newItem = { ...uploadImageB3[i], title: e.currentTarget.value }
    tmp.splice(i, 1, newItem)
    setUploadImageB3(tmp)
    setValue(tmp, name['site_layout'])
  }

  const handleCommentB3 = (e, i) => {
    e.preventDefault()
    var tmp = [...uploadImageB3]
    const newItem = { ...uploadImageB3[i], comment: e.currentTarget.value }
    tmp.splice(i, 1, newItem)
    setUploadImageB3(tmp)
    setValue(tmp, name['site_layout'])
  }

  const handleTitleB4 = (e, i) => {
    e.preventDefault()
    var tmp = [...uploadImageB4]
    const newItem = { ...uploadImageB4[i], title: e.currentTarget.value }
    tmp.splice(i, 1, newItem)
    setUploadImageB4(tmp)
    setValue(tmp, name['site_buildings'])
  }

  const handleCommentB4 = (e, i) => {
    e.preventDefault()
    var tmp = [...uploadImageB4]
    const newItem = { ...uploadImageB4[i], comment: e.currentTarget.value }
    tmp.splice(i, 1, newItem)
    setUploadImageB4(tmp)
    setValue(tmp, name['site_buildings'])
  }

  const handleTitleB5 = (e, i) => {
    e.preventDefault()
    var tmp = [...uploadImageB5]
    const newItem = { ...uploadImageB5[i], title: e.currentTarget.value }
    tmp.splice(i, 1, newItem)
    setUploadImageB5(tmp)
    setValue(tmp, name['site_others'])
  }

  const handleCommentB5 = (e, i) => {
    e.preventDefault()
    var tmp = [...uploadImageB5]
    const newItem = { ...uploadImageB5[i], comment: e.currentTarget.value }
    tmp.splice(i, 1, newItem)
    setUploadImageB5(tmp)
    setValue(tmp, name['site_others'])
  }

  const handleUploadImageInputB3 = () => {
    const fileInput = document.getElementById('uploadImageInputB3')
    fileInput.click()
  }
  const handleUploadImageInputB4 = () => {
    const fileInput = document.getElementById('uploadImageInputB4')
    fileInput.click()
  }
  const handleUploadImageInputB5 = () => {
    const fileInput = document.getElementById('uploadImageInputB5')
    fileInput.click()
  }

  useEffect(() => { }, [uploadImageB4])
  useEffect(() => { }, [uploadImageB5])
  return (
    <>
      <ol className="toc-list" style={{ lineHeight: '30px' }}>
        <li id="appendixE" className="flex gap-4 text-lg font-bold">
          <div className="py-4">Appendix E:</div>
          <div className="py-4 text-center">UAS Inspection Report</div>
        </li>

        <div className="pb-10" style={{ lineHeight: 'normal' }}>
          <table className="border-[1px] w-full table-test" style={{ fontSize: '12px' }}>
            <tr className="border-[1px]">
              <th colSpan={2} className="border-[1px] p-2 text-left">
                A. General Information
              </th>
            </tr>
            <tr className="border-[1px] ">
              <td className="border-[1px] p-2 w-1/2 font-bold">Flight location</td>
              <td className="border-[1px] p-2">{handleBreakLine(building?.address)}</td>
            </tr>
            <tr className="border-[1px] ">
              <td className="border-[1px] p-2 font-bold">Flight date(s) & time(s)</td>
              <td className="border-[1px] p-2">
                <Textarea
                  refTextArea={refUAS1}
                  id="refUAS1"
                  values={report?.uas_flight_datetime}
                  name="uas_flight_datetime"
                  setValue={setValue}
                  editMode={editMode}
                />
                <div id="refUAS1-div-container" className={editMode ? 'hidden' : ''}>
                  <div id={`refUAS1-div`}>{handleBreakLine(report?.uas_flight_datetime)}</div>
                </div>
              </td>
            </tr>
            <tr className="border-[1px] ">
              <td className="border-[1px] p-2 font-bold">Purpose</td>
              <td className="border-[1px] p-2">
                <Textarea
                  refTextArea={refUAS2}
                  id="refUAS2"
                  values={report?.purpose}
                  name="purpose"
                  setValue={setValue}
                  editMode={editMode}
                />
                <div id="refUAS2-div-container" className={editMode ? 'hidden' : ''}>
                  <div id={`refUAS2-div`}>{handleBreakLine(report?.purpose)}</div>
                </div>
              </td>
            </tr>
            <tr className="border-[1px] ">
              <td className="border-[1px] p-2 font-bold">CAAS activity permit reference number</td>
              <td className="border-[1px] p-2">
                <Textarea
                  refTextArea={refUAS3}
                  id="refUAS3"
                  values={report?.caas_permit_no}
                  name="caas_permit_no"
                  setValue={setValue}
                  editMode={editMode}
                  maxLength={20}
                />
                <div id="refUAS3-div-container" className={editMode ? 'hidden' : ''}>
                  <div id={`refUAS3-div`}>{handleBreakLine(report?.caas_permit_no)}</div>
                </div>
              </td>
            </tr>
            <tr className="border-[1px] ">
              <td className="border-[1px] p-2 font-bold">Areas of operation</td>
              <td className="border-[1px] p-2">
                <Textarea
                  refTextArea={refUAS4}
                  id="refUAS4"
                  values={report?.areas}
                  name="areas"
                  setValue={setValue}
                  editMode={editMode}
                />
                <div id="refUAS4-div-container" className={editMode ? 'hidden' : ''}>
                  <div id={`refUAS4-div`}>{handleBreakLine(report?.areas)}</div>
                </div>
              </td>
            </tr>
            <tr className="border-[1px] ">
              <td className="border-[1px] p-2 font-bold">Media and file format</td>
              <td className="border-[1px] p-2">
                <Textarea
                  refTextArea={refUAS5}
                  id="refUAS5"
                  values={report?.media_file}
                  name="media_file"
                  setValue={setValue}
                  editMode={editMode}
                />
                <div id="refUAS5-div-container" className={editMode ? 'hidden' : ''}>
                  <div id={`refUAS5-div`}>{handleBreakLine(report?.media_file)}</div>
                </div>
              </td>
            </tr>
            <tr className="border-[1px] ">
              <td className="border-[1px] p-2 font-bold">UA(s) model</td>
              <td className="border-[1px] p-2">
                <Textarea
                  refTextArea={refUAS6}
                  id="refUAS6"
                  values={report?.uas_model}
                  name="uas_model"
                  setValue={setValue}
                  editMode={editMode}
                />
                <div id="refUAS6-div-container" className={editMode ? 'hidden' : ''}>
                  <div id={`refUAS6-div`}>{handleBreakLine(report?.uas_model)}</div>
                </div>
              </td>
            </tr>
            <tr className="border-[1px] ">
              <td className="border-[1px] p-2 font-bold">Tethered UA (if applicable)</td>
              {editMode ? (
                <td className="border-[1px] p-2 flex justify-evenly">
                  <div className="flex justify-center items-center gap-6">
                    <p>Yes</p>
                    <input
                      type="radio"
                      id="True"
                      name="tethered"
                      checked={report?.tethered}
                      onClick={(e) => setValue(Boolean(true), e.currentTarget.name)}
                    />
                  </div>
                  <div className="flex justify-center items-center gap-6">
                    <p>No</p>
                    <input
                      type="radio"
                      id="False"
                      name="tethered"
                      onClick={(e) => setValue(Boolean(false), e.currentTarget.name)}
                      checked={!report?.tethered}
                    />
                  </div>
                </td>
              ) : (
                <div className="p-2">{report?.tethered ? "Yes" : "No"}</div>
              )}
            </tr>
            <tr className="border-[1px] ">
              <td className="border-[1px] p-2 font-bold">Camera/Sensor(s) model and specification</td>
              <td className="border-[1px] p-2 ">
                <Textarea
                  refTextArea={refUAS7}
                  id="refUAS7"
                  values={report?.camera}
                  name="camera"
                  setValue={setValue}
                  editMode={editMode}
                />
                <div id="refUAS7-div-container" className={editMode ? 'hidden' : ''}>
                  <div id={`refUAS7-div`}>{handleBreakLine(report?.camera)}</div>
                </div>
              </td>
            </tr>
          </table>
        </div>

        <div className="pb-10" style={{ lineHeight: 'normal' }}>
          <table className="border-[1px] w-full table-test" style={{ fontSize: '12px' }}>
            <tr className="border-[1px]">
              <th colSpan={2} className="border-[1px] p-2 text-left">
                B. Building Information
              </th>
            </tr>
            <tr className="border-[1px] ">
              <td className="border-[1px] p-2 font-bold">B.1 - Client Information</td>
              <td className="border-[1px] p-2 h-8 text-center w-2/3">
                <Textarea
                  refTextArea={refBuilding1}
                  id="refBuilding1"
                  values={report?.client_name}
                  name="client_name"
                  setValue={setValue}
                  editMode={editMode}
                />
                <div id="refBuilding1-div-container" className={editMode ? 'hidden' : ''}>
                  <div id={`refBuilding1-div`}>{handleBreakLine(report?.client_name)}</div>
                </div>
              </td>
            </tr>
            <tr className="border-[1px] ">
              <td className="border-[1px] p-2 font-bold">B.2 - Address</td>
              <td className="border-[1px] p-2 h-8 text-center">
                <Textarea
                  refTextArea={refBuilding2}
                  id="refBuilding2"
                  values={report?.client_address}
                  name="client_address"
                  setValue={setValue}
                  editMode={editMode}
                />
                <div id="refBuilding2-div-container" className={editMode ? 'hidden' : ''}>
                  <div id={`refBuilding2-div`}>{handleBreakLine(report?.client_address)}</div>
                </div>
              </td>
            </tr>

            {uploadImageB3.length > 0 ? (
              <>
                <tr className="border-[1px] " style={{ minHeight: '400px' }}>
                  <td className="border-[1px]  p-2 font-bold">B.3 - Site layout plan</td>
                  <td className="border-[1px]  p-2">
                    {uploadImageB3.map((img, i) => {
                      return (
                        <div className="py-2">
                          <div key={i}>
                            <div className="relative">
                              <img src={img.url} alt="img" />
                              {editMode && (
                                <button
                                  className="absolute top-2.5 right-4 flex items-center justify-center w-6 h-6 my-1 bg-gray-400 text-primary rounded-full hover:bg-orange-hover"
                                  onClick={() => {
                                    onConfirm({
                                      title: <h3>Are you sure you want to delete this image?</h3>,
                                      description: <p>This process cannot be undone!</p>,
                                      onSubmit: () => {
                                        onDeleteLayout(i)
                                      },
                                      onCancel: () => { },
                                      buttons: ({ onSubmit, onCancel }) => (
                                        <div className="custom-buttons">
                                          <button onClick={onSubmit}>YES</button>
                                          <button onClick={onCancel}>NO</button>
                                        </div>
                                      )
                                    })
                                  }}
                                >
                                  <DeleteOutlined style={{ fontSize: '18px' }} />
                                </button>
                              )}
                            </div>
                            <input
                              disabled={!editMode}
                              className="p-2 my-2 w-full text-center leading-8 bg-transparent"
                              maxlength={100}
                              type="text"
                              defaultValue={img.title}
                              placeholder={editMode ? 'Please title here' : ''}
                              onChange={(e) => handleTitleB3(e, i)}
                              value={uploadImageB3[i].title || ''}
                            />

                            <input
                              disabled={!editMode}
                              className="p-2 my-2 w-full text-center leading-8 bg-transparent"
                              type="text"
                              maxLength={100}
                              defaultValue={img.comment}
                              placeholder={editMode ? 'Please comment here' : ''}
                              onChange={(e) => handleCommentB3(e, i)}
                              value={uploadImageB3[i].comment || ''}
                            />
                            {editMode && i === uploadImageB3.length - 1 && (
                              <div className="flex flex-col items-center">
                                <button
                                  className="py-2 px-4 m-auto rounded-md shadow-lg cursor-pointer bg-blue-button text-white leading-8"
                                  onClick={handleUploadImageInputB3}
                                >
                                  {uploadImageB3.length > 0 ? 'Add image' : ' Upload Image'}
                                </button>
                                <input
                                  id="uploadImageInputB3"
                                  className="py-2 hidden"
                                  type="file"
                                  multiple
                                  accept="image/*"
                                  onChange={handleUploadImageB3}
                                />
                                {loading && (
                                  <div className="flex flex-row items-center justify-center mt-10 h-full w-32">
                                    <img alt="Loading" src={LoadingAnimated} />
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      )
                    })}
                  </td>
                </tr>
              </>
            ) : (
              <tr className="border-[1px] ">
                <td className="border-[1px]  p-2 font-bold">B.3 - Site layout plan</td>
                <td className="border-[1px]  p-2">
                  <div className="py-2">
                    {editMode && (
                      <div className="flex flex-col items-center">
                        <button
                          className="py-2 px-4 m-auto rounded-md shadow-lg cursor-pointer bg-blue-button text-white leading-8"
                          onClick={handleUploadImageInputB3}
                        >
                          {uploadImageB3.length > 0 ? 'Add image' : ' Upload Image'}
                        </button>
                        <input
                          id="uploadImageInputB3"
                          className="py-2 hidden"
                          type="file"
                          multiple
                          accept="image/*"
                          onChange={handleUploadImageB3}
                        />
                        {loading && (
                          <div className="flex flex-row items-center justify-center mt-10 h-full w-32">
                            <img alt="Loading" src={LoadingAnimated} />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            )}

            {uploadImageB4.length > 0 ? (
              <>
                <tr className="border-[1px] " style={{ minHeight: '400px' }}>
                  <td className="border-[1px]  p-2 font-bold">B.4 - Building(s) layout plan and elevation</td>
                  <td className="border-[1px]  p-2">
                    {uploadImageB4.map((img, i) => {
                      return (
                        <div className="py-2">
                          <div key={i}>
                            <div className="relative">
                              <img src={img.url} alt="img" />
                              {editMode && (
                                <button
                                  className="absolute top-2.5 right-4 flex items-center justify-center w-6 h-6 my-1 bg-gray-400 text-primary rounded-full hover:bg-orange-hover"
                                  onClick={() => {
                                    onConfirm({
                                      title: <h3>Are you sure you want to delete this image?</h3>,
                                      description: <p>This process cannot be undone!</p>,
                                      onSubmit: () => {
                                        onDeleteBuildings(i)
                                      },
                                      onCancel: () => { },
                                      buttons: ({ onSubmit, onCancel }) => (
                                        <div className="custom-buttons">
                                          <button onClick={onSubmit}>YES</button>
                                          <button onClick={onCancel}>NO</button>
                                        </div>
                                      )
                                    })
                                  }}
                                >
                                  <DeleteOutlined style={{ fontSize: '18px' }} />
                                </button>
                              )}
                            </div>
                            <input
                              disabled={!editMode}
                              className="p-2 my-2 w-full text-center leading-8 bg-transparent"
                              maxlength={100}
                              type="text"
                              defaultValue={img.title}
                              placeholder={editMode ? 'Please title here' : ''}
                              onChange={(e) => handleTitleB4(e, i)}
                              value={uploadImageB4[i].title || ''}
                            />

                            <input
                              disabled={!editMode}
                              className="p-2 my-2 w-full text-center leading-8 bg-transparent"
                              type="text"
                              maxLength={100}
                              defaultValue={img.comment}
                              placeholder={editMode ? 'Please comment here' : ''}
                              onChange={(e) => handleCommentB4(e, i)}
                              value={uploadImageB4[i].comment || ''}
                            />
                            {editMode && i === uploadImageB4.length - 1 && (
                              <div className="flex flex-col items-center">
                                <button
                                  className="py-2 px-4 m-auto rounded-md shadow-lg cursor-pointer bg-blue-button text-white leading-8"
                                  onClick={handleUploadImageInputB4}
                                >
                                  {uploadImageB4.length > 0 ? 'Add image' : ' Upload Image'}
                                </button>
                                <input
                                  id="uploadImageInputB4"
                                  className="py-2 hidden"
                                  type="file"
                                  multiple
                                  accept="image/*"
                                  onChange={handleUploadImageB4}
                                />
                                {loading && (
                                  <div className="flex flex-row items-center justify-center mt-10 h-full w-32">
                                    <img alt="Loading" src={LoadingAnimated} />
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      )
                    })}
                  </td>
                </tr>
              </>
            ) : (
              <tr className="border-[1px] ">
                <td className="border-[1px]  p-2 font-bold">B.4 - Building(s) layout plan and elevation</td>
                <td className="border-[1px]  p-2">
                  <div className="py-2">
                    {editMode && (
                      <div className="flex flex-col items-center">
                        <button
                          className="py-2 px-4 m-auto rounded-md shadow-lg cursor-pointer bg-blue-button text-white leading-8"
                          onClick={handleUploadImageInputB4}
                        >
                          {uploadImageB4.length > 0 ? 'Add image' : ' Upload Image'}
                        </button>
                        <input
                          id="uploadImageInputB4"
                          className="py-2 hidden"
                          type="file"
                          multiple
                          accept="image/*"
                          onChange={handleUploadImageB4}
                        />
                        {loading && (
                          <div className="flex flex-row items-center justify-center mt-10 h-full w-32">
                            <img alt="Loading" src={LoadingAnimated} />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            )}

            {uploadImageB5.length > 0 ? (
              <>
                <tr className="border-[1px] " style={{ minHeight: '400px' }}>
                  <td className="border-[1px]  p-2 font-bold">
                    B.5 – Other drawings/plans/ sketches (if deemed applicable)
                  </td>
                  <td className="border-[1px]  p-2">
                    {uploadImageB5.map((img, i) => {
                      return (
                        <div className="py-2 uas-images">
                          <div key={i}>
                            <div className="relative">
                              <img src={img.url} alt="img" />
                              {editMode && (
                                <button
                                  className="absolute top-2.5 right-4 flex items-center justify-center w-6 h-6 my-1 bg-gray-400 text-primary rounded-full hover:bg-orange-hover"
                                  onClick={() => {
                                    onConfirm({
                                      title: <h3>Are you sure you want to delete this image?</h3>,
                                      description: <p>This process cannot be undone!</p>,
                                      onSubmit: () => {
                                        onDeleteOthers(i)
                                      },
                                      onCancel: () => { },
                                      buttons: ({ onSubmit, onCancel }) => (
                                        <div className="custom-buttons">
                                          <button onClick={onSubmit}>YES</button>
                                          <button onClick={onCancel}>NO</button>
                                        </div>
                                      )
                                    })
                                  }}
                                >
                                  <DeleteOutlined style={{ fontSize: '18px' }} />
                                </button>
                              )}
                            </div>
                            <input
                              disabled={!editMode}
                              className="p-2 my-2 w-full text-center leading-8 bg-transparent"
                              maxlength={100}
                              type="text"
                              defaultValue={img.title}
                              placeholder={editMode ? 'Please title here' : ''}
                              onChange={(e) => handleTitleB5(e, i)}
                              value={uploadImageB5[i].title || ''}
                            />

                            <input
                              disabled={!editMode}
                              className="p-2 my-2 w-full text-center leading-8 bg-transparent"
                              type="text"
                              maxLength={100}
                              defaultValue={img.comment}
                              placeholder={editMode ? 'Please comment here' : ''}
                              onChange={(e) => handleCommentB5(e, i)}
                              value={uploadImageB5[i].comment || ''}
                            />
                            {editMode && i === uploadImageB5.length - 1 && (
                              <div className="flex flex-col items-center">
                                <button
                                  className="py-2 px-4 m-auto rounded-md shadow-lg cursor-pointer bg-blue-button text-white leading-8"
                                  onClick={handleUploadImageInputB5}
                                >
                                  {uploadImageB5.length > 0 ? 'Add image' : ' Upload Image'}
                                </button>
                                <input
                                  id="uploadImageInputB5"
                                  className="py-2 hidden"
                                  type="file"
                                  multiple
                                  accept="image/*"
                                  onChange={handleUploadImageB5}
                                />
                                {loading && (
                                  <div className="flex flex-row items-center justify-center mt-10 h-full w-32">
                                    <img alt="Loading" src={LoadingAnimated} />
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      )
                    })}
                  </td>
                </tr>
              </>
            ) : (
              <tr className="border-[1px] ">
                <td className="border-[1px]  p-2 font-bold">
                  B.5 – Other drawings/plans/ sketches (if deemed applicable)
                </td>
                <td className="border-[1px]  p-2">
                  <div className="py-2">
                    {editMode && (
                      <div className="flex flex-col items-center">
                        <button
                          className="py-2 px-4 m-auto rounded-md shadow-lg cursor-pointer bg-blue-button text-white leading-8"
                          onClick={handleUploadImageInputB5}
                        >
                          {uploadImageB4.length > 0 ? 'Add image' : ' Upload Image'}
                        </button>
                        <input
                          id="uploadImageInputB5"
                          className="py-2 hidden"
                          type="file"
                          multiple
                          accept="image/*"
                          onChange={handleUploadImageB5}
                        />
                        {loading && (
                          <div className="flex flex-row items-center justify-center mt-10 h-full w-32">
                            <img alt="Loading" src={LoadingAnimated} />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            )}
          </table>
        </div>

        <div style={{ lineHeight: 'normal' }}>
          <table className="border-[1px] w-full table-test" style={{ fontSize: '12px' }}>
            <tr className="border-[1px]">
              <th className="border-[1px] p-2 text-left">
                C. List of unsafe facade defects that require immediate action
              </th>
            </tr>
            <tr className="border-[1px]">
              <th className="border-[1px] p-2">Please refer to Table A of Appendix B</th>
            </tr>
          </table>
        </div>
      </ol>
    </>
  )
}

export default UASInspectionReport
