import axios from 'axios'
import authApi from './authApis'
export const TOKEN_KEY = 'claritas_token'
export const REFRESH_TOKEN_KEY = 'claritas_refresh_token'
export const USER_ID = 'user_id'
export const REFRESH_TOKEN_ID = 'refresh_token_id'

const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_API_SECRET
})

apiInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('claritas_token')
    if (token) {
      config.headers['Authorization'] = ` bearer ${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export const logOutApp = async () => {
  const resp = await authApi.logOut(localStorage.getItem(REFRESH_TOKEN_ID))
  if (resp.msg === 'logout') {
    localStorage.clear()
    window.location.reload()
  }
}

export const refreshToken = async (user_id, refresh_token_id, refresh_token) => {
  try {
    const resp = await authApi.refreshToken(user_id, refresh_token_id, refresh_token)
    return resp
  } catch (error) {
    localStorage.clear()
    window.location.reload()
  }
}

apiInstance.interceptors.response.use(
  (response) => {
    return response
  },
  async function (error) {
    const originalRequest = error.config
    if (error.response.status !== 401) {
      return Promise.reject(error)
    }
    if (
      error.response.status === 401 &&
      (error.response?.data?.message === 'No auth token' || error.response?.data?.message === 'Wrong user credential')
    ) {
      if (!localStorage.getItem(REFRESH_TOKEN_ID)) {
        return Promise.reject(error)
      } else {
        console.log(2222)
        logOutApp()
        return false
      }
    } else if (error.response.status === 401 && error.response?.data?.message !== 'Unauthorized') {
      console.log(11111111111)
      const user_id = localStorage.getItem(USER_ID)
      const token_key = localStorage.getItem(TOKEN_KEY)
      const refresh_token_id = localStorage.getItem(REFRESH_TOKEN_ID)
      const refresh_token = localStorage.getItem(REFRESH_TOKEN_KEY)

      if (!user_id || !refresh_token_id || !refresh_token || !token_key) {
        console.log(333333)
        logOutApp()
        return false
      } else {
        const resp = await refreshToken(user_id, refresh_token_id, refresh_token)
        console.log(4444)
        const access_token = resp.token
        localStorage.setItem(TOKEN_KEY, access_token)
        apiInstance.defaults.headers.common['Authorization'] = `Bearer ${access_token}`
        return apiInstance(originalRequest)
      }
    }
    return Promise.reject(error)
  }
)

export default apiInstance
