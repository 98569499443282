import React, { useState, useEffect } from 'react'
import CustomRegionLabel from '../../CustomLabelAnnotation/CustomRegionLabel'
import ReactImageAnnotate from 'react-image-annotate-custom-region-edit-label-ver-2'
import { useDispatch, useSelector } from 'react-redux'
import { TbClockRecord } from 'react-icons/tb'
import { updateCheckedUserAnnotationElevation } from '../../../../store/thunks/elevationThunk'
import { toast } from 'react-toastify'
import { makeRoles, formatMetadata } from '../../../../utils/common'
import useAuth from '../../../../hooks/useAuth'
import usePrevious from '../../../../hooks/usePrevious'
import elevationTypes from '../../../../constant/store/elevationTypes'
import Modal from '../../../Common/Modal'

const ImageUserPopUp = ({
  check,
  hideSave,
  selected,
  visualStatus,
  status,
  popUpManualImage,
  setPopUpManualImage,
  handleSaveNewAnnotateImageUser,
  elevationDetails,
  setListManualImages,
  listManualImages,
  onExit,
  setShowLogDrawer
}) => {
  const [checked, setChecked] = useState(check && check === true ? true : false)

  const dispatch = useDispatch()
  const { errors: errorsElevation, loadings: loadingsElevation } = useSelector((state) => state.elevation)

  const { user } = useAuth()
  const { role } = user?.user || {}
  const metadata = formatMetadata(selected?.metadata)
  const prevElevationUpdateCheckedAnnotationUser = usePrevious(
    loadingsElevation[elevationTypes.UPDATE_CHECKED_USER_ANNOTATION_ELEVATION]
  )
  useEffect(() => {
    if (
      prevElevationUpdateCheckedAnnotationUser &&
      !loadingsElevation[elevationTypes.UPDATE_CHECKED_USER_ANNOTATION_ELEVATION]
    ) {
      if (!errorsElevation[elevationTypes.UPDATE_CHECKED_USER_ANNOTATION_ELEVATION]) {
        setTimeout(() => {
          toast.success('Annotation updated', {
            theme: 'dark',
            autoClose: 2000
          })
        }, 500)
      } else {
        toast.error(errorsElevation[elevationTypes.UPDATE_CHECKED_USER_ANNOTATION_ELEVATION], {
          theme: 'dark',
          autoClose: 2000
        })
      }
    }
  }, [loadingsElevation, errorsElevation, prevElevationUpdateCheckedAnnotationUser])

  useEffect(() => {
    setChecked(check && check === true ? true : false)
  }, [check, selected])

  const onCheckDoneAnnotation = (e) => {
    try {
      const dataReq = {
        elevation_id: parseInt(elevationDetails.elevation.id),
        url: selected?.url,
        check: e
      }
      dispatch(updateCheckedUserAnnotationElevation(dataReq))
    } catch (error) {
      toast.error('Checking error')
    }
  }
  const handleCheck = () => {
    const updatedListImgUser = listManualImages.map((item) => {
      if (item.url === selected?.url) {
        return {
          ...item,
          check: !checked
        }
      }
      return item
    })
    setListManualImages(updatedListImgUser)
  }

  const handleCloseModal = () => {
    setPopUpManualImage('down')
    onExit('')
  }

  return (
    <Modal onClose={handleCloseModal} zIndex="z-[35]">
      <div className="fixed top-16 left-32 h-5/6 w-10/12">
        {status !== 'Completed' && !visualStatus && ![...makeRoles([5, 9, 11])].includes(role) && (
          <>
            {checked === true && (
              <input
                type="checkbox"
                className="absolute top-0 -right-10 w-6 h-6"
                onChange={(e) => {
                  onCheckDoneAnnotation(e.target.checked, selected.id)
                  setChecked(e.target.checked)
                  handleCheck()
                }}
                checked
              />
            )}
            {checked === false && (
              <input
                type="checkbox"
                className="absolute top-0 -right-10 w-6 h-6"
                onChange={(e) => {
                  onCheckDoneAnnotation(e.target.checked, selected.id)
                  setChecked(e.target.checked)
                  handleCheck()
                }}
                checked={false}
              />
            )}
            <h3 className="text-xs text-white font-semibold absolute top-7 -right-24">Image Checked</h3>
          </>
        )}

        {selected && (
          <>
            <ReactImageAnnotate
              key={selected.urlOriginal || selected.url}
              taskTitle="Annotate Defect"
              taskDescription={
                metadata &&
                `
## ** Longitude:** ${metadata.longitude} 
## ** Latitude:** ${metadata.latitude}
## ** Altitude:** ${metadata.altitude}`
              }
              hideSave={hideSave}
              hidePrev={true}
              hideNext={true}
              enabledTools={
                hideSave
                  ? []
                  : selected.closeRangeStatus === true
                  ? [
                      'select',
                      `${![...makeRoles([5, 9])].includes(role) && 'create-box'}`,
                      `${
                        selected.closeRangeStatus === true && ![...makeRoles([5, 9])].includes(role) && 'create-point'
                      }`
                    ]
                  : ['select', `${![...makeRoles([5, 9])].includes(role) && 'create-box'}`]
              }
              RegionEditLabel={CustomRegionLabel}
              images={[
                {
                  src: selected.urlOriginal || selected.url ? selected.urlOriginal || selected.url : '',
                  name: '',
                  regions: selected?.review
                    ? selected.review.map((item) => {
                        if (selected.closeRangeStatus === true) {
                          return { ...item, closeRange: true }
                        } else {
                          return { ...item }
                        }
                      })
                    : []
                }
              ]}
              onExit={(x) => {
                let checkOutSide = false
                x.images[0].regions.forEach((region) => {
                  if (region.x <= 0 || region.x >= 1 || region.y <= 0 || region.y >= 1) {
                    checkOutSide = true
                  }
                })
                if (checkOutSide) {
                  toast.error('Create defect inside image')
                } else {
                  handleSaveNewAnnotateImageUser(x.images[0].regions)
                }
              }}
            />
          </>
        )}

        <div className="absolute bottom-0 -right-12 text-white flex flex-col gap-3">
          <button onClick={() => setShowLogDrawer({ type: 'Manual', id: selected.annoId, title: 'Manual image log' })}>
            <p className="flex items-center gap-1 cursor-pointer">
              <TbClockRecord /> <span className="leading-4">Log</span>
            </p>
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ImageUserPopUp
