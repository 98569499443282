import Modal from '../Common/Modal/index'
function Confirm({ closePopupConfirm, saveConfirm, handleAddConfirm, addConfirm }) {
  return (
    <div>
      <Modal wrapper="w-auto max-w-md mx-auto" onClose={closePopupConfirm}>
        <div className="flex items-start justify-center p-3 border-b border-solid rounded-t border-slate-200">
          <h2 className="text-xl text-primary font-semibold ">Confirm</h2>
        </div>

        <div
          className="w-full p-6"
          style={{
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '21px'
          }}
        >
          <div>
            <input
              onChange={(e) => handleAddConfirm(e)}
              value={addConfirm}
              className="inline-block w-full h-10 rounded-lg border text-center leading-10 font-bold text-primary"
            />
          </div>
        </div>

        <div className="flex items-center justify-evenly mb-2">
          <div
            className="px-5 py-2 border-solid  w-24 border-primary border rounded-xl flex justify-center cursor-pointer mx-6 bg-red-500"
            onClick={() => closePopupConfirm()}
          >
            <span className="text-white">Cancel</span>
          </div>
          <button
            // disabled={!(floors && gridline)}
            className="px-5 py-2 border-solid w-24 border-primary flex justify-center border rounded-xl cursor-pointer mx-6 bg-blue-button  disabled:bg-blue-400 disabled:cursor-default"
            onClick={() => {
              saveConfirm()
            }}
          >
            <p className="text-white">Save</p>
          </button>
        </div>
      </Modal>
    </div>
  )
}

export default Confirm
