import React, { useEffect, useState } from 'react'
import ImageUploading from 'react-images-uploading'
import { toast } from 'react-toastify'
import { PlusCircleOutlined, DeleteOutlined, CheckCircleTwoTone, EnvironmentOutlined } from '@ant-design/icons'
import { onConfirm } from 'react-confirm-pro'
import { makeRoles } from '../../../utils/common'
import useAuth from '../../../hooks/useAuth'
import useCloseRange from '../../../hooks/useCloseRange'
import CustomRegionLabel from '../CustomLabelAnnotation/CustomRegionLabel'
import ReactImageAnnotate from 'react-image-annotate-custom-region-edit-label-ver-2'
import { typeFile, typeFileSort } from '../../../constant/file'
import CountAnnotationContainer from './CountAnnotationContainer'

import Modal from '../../Common/Modal'
import { AiOutlineClose } from 'react-icons/ai'
import { dataURLtoFile } from '../../../utils/fileUtils'
import { nanoid } from 'nanoid'
import CameraWeb from '../../Common/camera/CameraWeb'
const ImageCloseRangeList = ({
  closeRangeSwitchStatus,
  type,
  cameraAddByThumbnail,
  setCameraAddByThumbnail,
  position,
  status,
  manualPosition,
  updateReview,
  isShow,
  listImageCloseRange,
  setPopUpThumbnail,
  setFloors,
  setGridline,
  handleThumbnail,
  setReviewOnThumbnail,
  imageUploadRef,
  popUpCloseRangeThumbnail,
  setPopupAddImageType,
  handleClickOnMap,
  onDelete,
  closePopupAddCloseRangeByThubmnail
}) => {
  const { user } = useAuth()
  const { role } = user?.user || {}
  const { setCloseRangeImage } = useCloseRange()
  const [closeRangeImg, setCloseRangeImg] = useState()
  const [tempListData, setTempListData] = useState(listImageCloseRange)

  const handleUploadImage = (props) => {
    try {
      setCloseRangeImg({
        url: props[0].data_url,
        file: props[0].file
      })
    } catch (e) {
    } finally {
      updateReview()
    }
  }

  const onImageUploadingError = (errors) => {
    if (errors?.acceptType) {
      onConfirm({
        title: <h3 className="text-center">{`The images must be a file of type: ${typeFile.join()}`}</h3>,
        description: <br />,

        onCancel: () => {},
        buttons: ({ onCancel }) => (
          <div className="custom-buttons">
            <button
              style={{
                background: 'rgba(37, 99, 235)',
                color: 'white'
              }}
              onClick={onCancel}
            >
              Ok
            </button>
          </div>
        )
      })
    }
  }

  const handleCloseModal = () => {
    closePopupAddCloseRangeByThubmnail()
  }

  useEffect(() => {
    setTempListData(listImageCloseRange)
  }, [listImageCloseRange])

  useEffect(() => {
    handleThumbnail()
  }, [])

  const handleSetCloseRangeImageByPhoto = (image) => {
    if (!image) {
      return
    }
    const newFile = dataURLtoFile(image, nanoid())
    const fileOptions = {
      url: image,
      file: newFile
    }
    setCloseRangeImg(fileOptions)
    setPopupAddImageType(false)
    setCameraAddByThumbnail(null)
    updateReview()
  }

  const renderModalCamera = () => {
    return (
      <>
        {cameraAddByThumbnail && (
          <Modal wrapper="w-auto max-w-md mx-auto" zIndex="z-[60]" onClose={() => setCameraAddByThumbnail(null)}>
            {/* <Switcher /> */}
            <div className="flex items-center justify-between py-3 px-6 border-b border-solid rounded-t border-slate-200 w-full">
              <div className=" text-xl  text-primary font-semibold">Take Photo</div>
              <div className="cursor-pointer" onClick={() => setCameraAddByThumbnail(null)}>
                <AiOutlineClose style={{ fontSize: 22 }} />
              </div>
            </div>
            {/*body*/}
            <div className="relative flex flex-auto p-6">
              <div className="w-full max-w-lg">
                <CameraWeb onTakePhoto={handleSetCloseRangeImageByPhoto} />
              </div>
            </div>
          </Modal>
        )}
      </>
    )
  }

  const renderAnnotateImage = () => {
    return (
      <>
        {popUpCloseRangeThumbnail === 'up' && (
          <Modal onClose={handleCloseModal} zIndex="z-[70]">
            <div className="fixed top-16 left-32 h-5/6 w-10/12">
              {closeRangeImg && (
                <>
                  <ReactImageAnnotate
                    taskTitle="Add close range image"
                    key={closeRangeImg?.url}
                    hidePrev={true}
                    hideNext={true}
                    hideSave={closeRangeSwitchStatus}
                    RegionEditLabel={CustomRegionLabel}
                    enabledTools={
                      closeRangeSwitchStatus
                        ? []
                        : ['select', `${![...makeRoles([5, 9])].includes(role) && 'create-box'}`]
                    }
                    images={[
                      {
                        src: closeRangeImg?.url,
                        name: '',
                        regions: []
                      }
                    ]}
                    onExit={(x) => {
                      if (x.images[0].regions.length === 1) {
                        setCloseRangeImage({
                          image: closeRangeImg,
                          review: x.images[0].regions
                        })
                      } else if (x.images[0].regions.length > 1) {
                        toast.error('Please add one defect for one close-range image', {
                          theme: 'dark'
                        })
                      } else {
                        toast.error('Please add a defect', {
                          theme: 'dark'
                        })
                      }
                    }}
                  />
                </>
              )}
            </div>
          </Modal>
        )}
      </>
    )
  }

  return (
    <>
      <div className="flex flex-row justify-start pb-2 pr-1">
        <div className="relative flex flex-row items-center justify-center pr-1 bg-transparent cursor-pointer">
          <div className="flex h-full transition duration-500 transform rounded ">
            {status !== 'Completed' &&
              !closeRangeSwitchStatus &&
              isShow &&
              type === 'Thumbnail' &&
              [...makeRoles([1, 2, 3, 6, 7, 8, 10, 12, 13])].includes(role) && (
                <button
                  onClick={(e) => {
                    e.preventDefault()
                    setPopUpThumbnail('closeRange')
                    setReviewOnThumbnail([])
                    setFloors('')
                    setGridline('')
                  }}
                  className="flex items-center justify-center mr-2 mb-2 px-4 h-10 w-60 my-2 font-bold text-gray-800 bg-transparent border border-black rounded hover:bg-black hover:text-white"
                >
                  <PlusCircleOutlined />
                  <span className="ml-2">Add image</span>
                </button>
              )}
          </div>
        </div>
      </div>

      <div className="flex flex-row">
        {listImageCloseRange.length > 0 && (
          <div
            className="flex flex-row flex-wrap px-6 py-1  overflow-auto bg-white border border-gray-300 rounded shadow-lg bg-opacity-90 bg-clip-padding"
            style={{ width: '90vw', height: 'fit-content' }}
          >
            {tempListData.map((data, idx) => {
              return (
                <div key={idx} className="flex flex-row  justify-start">
                  <div className="flex flex-row justify-start py-2 pr-1">
                    <div className="relative flex flex-row items-center justify-center h-48 pr-1 bg-transparent cursor-pointer w-60">
                      <div
                        onClick={(e) => {
                          e.preventDefault()
                          handleClickOnMap(data)
                        }}
                        className="relative flex flex-row items-center justify-center h-48 pr-1 bg-transparent cursor-pointer w-60"
                      >
                        <img
                          src={data.url}
                          alt=""
                          className="w-full h-full transition duration-500 transform rounded "
                        />
                      </div>

                      {data.check === true ? (
                        <CheckCircleTwoTone className="checkboxEP absolute flex w-5 h-5 left-1 top-1 text-xl" />
                      ) : (
                        ''
                      )}

                      <div className="absolute bottom-1 left-1">
                        <CountAnnotationContainer defects={data.defects} />
                      </div>

                      <div className="absolute top-0 mx-2 flex flex-col items-center justify-center h-full right-2">
                        <button className="group relative flex items-center justify-center w-6 h-6 my-1 bg-gray-400 text-gray-800 rounded-full hover:bg-orange-hover">
                          <EnvironmentOutlined style={{ fontSize: '18px' }} />
                          <div className="group-hover:block hidden z-50 w-32  bg-white rounded-lg shadow  absolute bottom-0 right-[28px] p-1">
                            {data.type === 'Drone' ? (
                              <>
                                {position?.map((i) => {
                                  return i.map((r, id) => {
                                    if (r.url === data.fromUrl) {
                                      return (
                                        <div key={id}>
                                          <div>{`Drone Row: ${r.row + 1}`}</div>
                                          <div> {`Column : ${r.col + 1}`}</div>
                                        </div>
                                      )
                                    }
                                  })
                                })}
                              </>
                            ) : data.type === 'Manual' ? (
                              <>
                                {manualPosition?.map((i) => {
                                  return i.map((r, id) => {
                                    if (r.url === data.fromUrl) {
                                      return (
                                        <>
                                          <div>{`Manual Row: ${r.row}`}</div>
                                          <div> {`Column: ${r.column}`}</div>
                                        </>
                                      )
                                    }
                                  })
                                })}
                              </>
                            ) : (
                              <>
                                <div>{`Floor: ${data.floor}`}</div>
                                <div> {`Gridline: ${data.gridline}`}</div>
                              </>
                            )}
                          </div>
                        </button>
                        {status !== 'Completed' &&
                          !closeRangeSwitchStatus &&
                          [...makeRoles([1, 2, 5, 6, 7, 10, 12, 13])].includes(role) && (
                            <button
                              className="flex items-center justify-center w-6 h-6 my-1 bg-gray-400 text-gray-800 rounded-full hover:bg-orange-hover"
                              onClick={() => {
                                onConfirm({
                                  title: <h3 className="text-center">Are you sure you want to delete this image?</h3>,
                                  description: <br />,
                                  onSubmit: () => {
                                    onDelete(data.id, data.defectId)
                                  },
                                  onCancel: () => {},
                                  buttons: ({ onSubmit, onCancel }) => (
                                    <div className="custom-buttons">
                                      <button
                                        style={{
                                          background: 'rgba(37, 99, 235)',
                                          color: 'white'
                                        }}
                                        onClick={onSubmit}
                                      >
                                        YES
                                      </button>

                                      <button
                                        style={{
                                          background: 'rgba(220, 38, 38)',
                                          color: 'white'
                                        }}
                                        onClick={onCancel}
                                      >
                                        NO
                                      </button>
                                    </div>
                                  )
                                })
                              }}
                            >
                              <DeleteOutlined style={{ fontSize: '18px' }} />
                            </button>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        )}
        <ImageUploading
          onChange={(props) => {
            handleUploadImage(props)
          }}
          onError={onImageUploadingError}
          maxNumber={1}
          acceptType={typeFileSort}
          dataURLKey="data_url"
        >
          {({ onImageUpdate }) => (
            <button
              ref={imageUploadRef}
              className="hidden items-center justify-center w-6 h-6 my-1 bg-gray-400 rounded-full hover:bg-orange-hover"
              onClick={async () => {
                setCloseRangeImg()
                onImageUpdate(0)
              }}
            ></button>
          )}
        </ImageUploading>
      </div>
      {renderModalCamera()}

      {renderAnnotateImage()}
    </>
  )
}

export default ImageCloseRangeList
