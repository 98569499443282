import React from 'react'
import { color } from '../../../constant/styled'
import { Switch } from 'antd'
import './style.css'

const Switcher = ({ checked, onChange, label, className = '', bgColor = color['blue-button'] }) => {
  return (
    <div id="switcher">
      <div className="flex gap-2 items-center">
        <Switch
          style={{ backgroundColor: bgColor }}
          className={`${className}`}
          defaultChecked
          checked={checked}
          onClick={onChange}
        />
        <div className="">{label}</div>
      </div>
    </div>
  )
}

export default Switcher
