const companyTypes = {
  GET_COMPANIES: 'GET_COMPANIES',
  GET_COMPANY_ACTIVE: 'GET_COMPANY_ACTIVE',
  CREATE_COMPANY: 'CREATE_COMPANY',
  UPDATE_COMPANY: 'UPDATE_COMPANY',
  UPDATE_COMPANY_AVATAR: 'UPDATE_COMPANY_AVATAR',
  DELETE_COMPANY: 'DELETE_COMPANY',
  REVERT_DELETE_COMPANY: 'REVERT_DELETE_COMPANY'
}

export default companyTypes
