import React, { useEffect, useState } from 'react'

const PreviewImage = ({ file, wrapper = 'w-full h-full', imgClass }) => {
  const [preview, setPreview] = useState(null)

  const renderFile = () => {
    if (file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        setPreview(reader.result)
      }
    }
  }

  useEffect(() => {
    renderFile()
  }, [file])

  return (
    <>
      {preview && (
        <div className={`${wrapper}`}>
          <div
            className={imgClass}
            style={{
              backgroundImage: `url(${preview})`,
              display: 'block',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPositionX: 'center',
              backgroundPositionY: '10%'
            }}
          ></div>
        </div>
      )}
    </>
  )
}

export default PreviewImage
