import { role } from './../constant/auth'
import { nanoid } from 'nanoid'
import { uploadElevationImage } from '../apis/fileApis'
import Compressor from 'compressorjs'

export const makeid = (length) => {
  var result = ''
  var characters = '0123456789'
  var charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const isObject = (obj) => {
  if (typeof obj === 'object' && !Array.isArray(obj) && obj !== null) {
    return true
  } else {
    return false
  }
}

export const makeRoles = (roles) => {
  let listRole = []
  roles.forEach((r) => {
    const item = role.find((i) => i.id === r)
    if (item) {
      listRole.push(item.value)
    }
  })
  return listRole
}
export const makeRolesString = (id) => {
  let roleString = ''
  role.forEach((r) => {
    if (r.id === id) {
      roleString = r.value
    }
  })
  return roleString
}
export const makeRolesNumber = (id) => {
  let roleNumber = 1
  role.forEach((r) => {
    if (r.value === id) {
      roleNumber = r.id
    }
  })
  return roleNumber
}

export const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0
}

export const isEmptyAray = (arr) => {
  if (Array.isArray(arr) && !arr.length) {
    return true
  } else {
    return false
  }
}
export const changeAvtName = (name, quantity = 2) => {
  let avt = 'NN'
  if (name) {
    avt = name.slice(0, quantity)
  }
  return avt.toUpperCase()
}

export const makeStatusInspection = (curStatus) => {
  switch (curStatus) {
    case 'Active':
      return ['Completed']

    case 'Completed':
      return ['Active', 'Archived']

    case 'Archived':
      return ['Completed']

    case 'Deleted':
      return ['Archived']

    default:
      return ['Completed']
  }
}

export const deepEqual = (object1, object2, keys = []) => {
  const keys1 = Object.keys(object1)

  for (const key of keys1) {
    if (!keys.includes(key)) continue
    else {
      const val1 = object1[key]
      const val2 = object2[key]
      const areObjects = isObject(val1) && isObject(val2)
      if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
        return false
      }
    }
  }

  return true
}

export const changeFileName = (fileName, type) => {
  let a = fileName.lastIndexOf('.')
  let b = fileName.slice(0, a)
  let c = b
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/đ/g, 'd')
    .replace(/Đ/g, 'D')
  return c + `.${type}`
}

export const formatMetadata = (metadata) => {
  if (!metadata || JSON.stringify(metadata) === '{}' || !metadata.GPSLongitude || !metadata.GPSLatitude) {
    return false
  }
  const longitude = `${metadata.GPSLongitude[0]}° ${metadata.GPSLongitude[1]}' ${metadata.GPSLongitude[2].toFixed(
    2
  )}" ${metadata.GPSLongitudeRef}`
  const latitude = `${metadata.GPSLatitude[0]}° ${metadata.GPSLatitude[1]}' ${metadata.GPSLatitude[2].toFixed(2)}" ${
    metadata.GPSLatitudeRef
  }`
  const altitude = `${metadata?.GPSAltitude}m`
  return {
    longitude,
    latitude,
    altitude
  }
}

export const dataURItoBlob = (dataURI) => {
  var binary = atob(dataURI.split(',')[1])
  var array = []
  for (var i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i))
  }
  return new Blob([new Uint8Array(array)], { type: 'image/jpeg' })
}

// draw and create annotation for drone and user
export const handleCreateCloseupImage = async (image, url, elevationDetails, reset) => {
  let heightAnno = 0,
    widthAnno = 0 // kich thuoc hop chu thich
  let imageWidth = 0,
    imageHeight = 0 // kich thuoc anh goc
  let startX = 0,
    startY = 0 // toa độ đầu của annotation
  let startBgX = 0,
    startBgY = 0 // tọa độ mà ta sẽ cắt ảnh tại đó
  let checkWidthBiggerThanHeight = false // check chieu rong annotation lon hon chieu dai
  const canvas = document.getElementById('canvasElevationDetail') // thẻ canvas
  const ctx = canvas.getContext('2d')
  ctx.clearRect(0, 0, canvas.width, canvas.height) // clear the canvas
  var img = new Image()
  img.crossOrigin = 'anonymous'
  img.onload = async function () {
    // hàm loading du lieu image
    imageWidth = img.width
    imageHeight = img.height
    canvas.width = imageWidth // set kích thước thẻ canvas bằng ảnh gốc
    canvas.height = imageHeight
    startX = image.x * canvas.width
    startY = image.y * canvas.height
    widthAnno = image.w * canvas.width
    heightAnno = image.h * canvas.height
    checkWidthBiggerThanHeight = widthAnno > heightAnno ? true : false // kiểm tra hộp chú thích có chiều dài lớn hơn hay bé hơn chiều rộng
    await ctx.drawImage(img, 0, 0, canvas.width, canvas.height) // hàm vẽ ảnh lên thẻ canvas

    if (image.type === 'box') {
      if (
        (checkWidthBiggerThanHeight && widthAnno > canvas.width / 1.8) || // cạnh hộp lớn hơn 1/1.8 cạnh của ảnh gốc thì
        (!checkWidthBiggerThanHeight && heightAnno > canvas.height / 1.8) // ảnh giữ nguyên ko zoom
      ) {
        startBgX = startX // vị trí cắt bằng vị trí ban đầu
        startBgY = startY
        ctx.lineWidth = checkWidthBiggerThanHeight ? widthAnno / 40 : heightAnno / 40 // độ rộng của viền hộp
      } else {
        if (widthAnno < canvas.width / 10 || heightAnno < canvas.height / 10) {
          // cạnh hộp lớn hơn 1/10 cạnh của ảnh gốc thì zoom ra 100 đơn vị
          startBgX = startX - 100 // vị trí cắt sẽ bằng vị trí ban đầu + 100
          startBgY = startY - 100
          ctx.lineWidth = checkWidthBiggerThanHeight ? (widthAnno + 200) / 80 : (heightAnno + 200) / 80
        } else if (widthAnno < canvas.width / 8 || heightAnno < canvas.height / 8) {
          startBgX = startX - 150
          startBgY = startY - 150
          ctx.lineWidth = checkWidthBiggerThanHeight ? (widthAnno + 300) / 80 : (heightAnno + 300) / 80
        } else if (widthAnno < canvas.width / 5 || heightAnno < canvas.height / 5) {
          startBgX = startX - 200
          startBgY = startY - 200
          ctx.lineWidth = checkWidthBiggerThanHeight ? (widthAnno + 400) / 70 : (heightAnno + 400) / 70
        } else if (widthAnno < canvas.width / 3 || heightAnno < canvas.height / 3) {
          startBgX = startX - 220
          startBgY = startY - 220
          ctx.lineWidth = checkWidthBiggerThanHeight ? (widthAnno + 440) / 70 : (heightAnno + 440) / 70
        } else {
          startBgX = startX
          startBgY = startY
          ctx.lineWidth = checkWidthBiggerThanHeight ? widthAnno / 40 : heightAnno / 40
        }
      }

      ctx.strokeStyle = image.color // set màu
      ctx.strokeRect(startX, startY, widthAnno, heightAnno) // hàm vẽ hộp chú thích
    } else {
      startBgX = startX - 400
      startBgY = startY - 400
      widthAnno = 0.1 * canvas.width
      heightAnno = 0.1 * canvas.height
      ctx.beginPath()
      ctx.arc(image.x * canvas.width, image.y * canvas.height, Math.floor(canvas.width / 100), 0, 2 * Math.PI)
      ctx.fillStyle = image.color
      ctx.fill()
    }
  }
  img.src = url + '?stopGivingMeHeadaches=true' // set url của ảnh truyền vào Image
  await img.decode() // đợi ảnh đc vẽ xong

  const img1 = await createImageBitmap(canvas) // chuyển ảnh kèm hộp chú thích vừa vẽ xong thành bitmap để truyền lại vào thẻ canvas
  await ctx.clearRect(0, 0, canvas.width, canvas.height) // clear thẻ canvas
  if (
    (checkWidthBiggerThanHeight && widthAnno > canvas.width / 1.8) ||
    (!checkWidthBiggerThanHeight && heightAnno > canvas.height / 1.8)
  ) {
    ctx.drawImage(img1, 0, 0, canvas.width, canvas.height, 0, 0, canvas.width, canvas.height) // điều kiện như ở trên, ảnh sẽ không cần zoom
  } else {
    if (widthAnno < canvas.width / 10 || heightAnno < canvas.height / 10) {
      if (checkWidthBiggerThanHeight) {
        canvas.width = widthAnno + 200 // set chiều dài của thẻ canvas bằng chiều dài của hộp chú thích + 200
        canvas.height = widthAnno + 200
        startBgY -= widthAnno / 2 - heightAnno / 2
      } else {
        canvas.width = heightAnno + 200
        canvas.height = heightAnno + 200
        startBgX -= heightAnno / 2 - widthAnno / 2
      }
      ctx.drawImage(img1, startBgX, startBgY, canvas.width, canvas.height, 0, 0, canvas.width, canvas.height) //  hàm vẽ ảnh với vị trí trên ảnh bắt đầu tại starBgX,Y và vẽ tại gốc tọa độ 0,0
    } else if (widthAnno < canvas.width / 8 || heightAnno < canvas.height / 8) {
      if (checkWidthBiggerThanHeight) {
        canvas.width = widthAnno + 300
        canvas.height = widthAnno + 300
        startBgY -= widthAnno / 2 - heightAnno / 2
      } else {
        canvas.width = heightAnno + 300
        canvas.height = heightAnno + 300
        startBgX -= heightAnno / 2 - widthAnno / 2
      }
      ctx.drawImage(img1, startBgX, startBgY, canvas.width, canvas.height, 0, 0, canvas.width, canvas.height)
    } else if (widthAnno < canvas.width / 5 || heightAnno < canvas.height / 5) {
      if (checkWidthBiggerThanHeight) {
        canvas.width = widthAnno + 400
        canvas.height = widthAnno + 400
        startBgY -= widthAnno / 2 - heightAnno / 2
      } else {
        canvas.width = heightAnno + 400
        canvas.height = heightAnno + 400
        startBgX -= heightAnno / 2 - widthAnno / 2
      }
      ctx.drawImage(img1, startBgX, startBgY, canvas.width, canvas.height, 0, 0, canvas.width, canvas.height)
    } else if (widthAnno < canvas.width / 3 || heightAnno < canvas.height / 3) {
      if (checkWidthBiggerThanHeight) {
        canvas.width = widthAnno + 440
        canvas.height = widthAnno + 440
        startBgY -= widthAnno / 2 - heightAnno / 2
      } else {
        canvas.width = heightAnno + 440
        canvas.height = heightAnno + 440
        startBgX -= heightAnno / 2 - widthAnno / 2
      }
      ctx.drawImage(img1, startBgX, startBgY, canvas.width, canvas.height, 0, 0, canvas.width, canvas.height)
    } else {
      ctx.drawImage(img1, 0, 0, canvas.width, canvas.height, 0, 0, canvas.width, canvas.height)
    }
  }

  let canvasUrl = await canvas.toDataURL('image/jpeg') // chuyển ảnh trên thẻ canvas thành url
  let imageAws = dataURItoBlob(canvasUrl) // chuyển url thành blob
  const dataThumbnail = {
    filename: nanoid(),
    contentType: imageAws.type,
    key: 'elevation',
    type: 'other',
    elevationId: elevationDetails.elevation.id,
    size: 20000
  }
  const { signedUrl } = await uploadElevationImage(dataThumbnail)

  new Compressor(imageAws, {
    quality: 0.2,
    async success(result) {
      await fetch(signedUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': imageAws.type
        },
        body: result
      })
    },
    error(err) {}
  })
  reset()

  return signedUrl.split('?')[0]
}

// const loadImage = async (url) => {
//   return new Promise((resolve, reject) => {
//     const img = new Image()
//     img.crossOrigin = 'anonymous'
//     img.onload = () => resolve(img)
//     img.onerror = () => reject(new Error(`load ${url} fail`))
//     img.src = url + '?stopGivingMeHeadaches=true'
//   })
// }

// const rhombus = (ctx, xCenter, yCenter, size, color) => {
//   const numberOfSides = 4
//   ctx.beginPath()
//   ctx.moveTo(xCenter + size * Math.cos(0), yCenter + size * Math.sin(0))

//   for (var k = 1; k <= numberOfSides; k += 1) {
//     ctx.lineTo(
//       xCenter + size * Math.cos((k * 2 * Math.PI) / numberOfSides),
//       yCenter + size * Math.sin((k * 2 * Math.PI) / numberOfSides)
//     )
//   }
//   ctx.strokeStyle = color
//   ctx.stroke()
//   ctx.fill()
// }

// const pentagon = (ctx, xCenter, yCenter, size, color) => {
//   const numberOfSides = 5
//   const step = (2 * Math.PI) / numberOfSides, //Precalculate step value
//     shift = (Math.PI / 180.0) * -18
//   ctx.beginPath()
//   ctx.moveTo(xCenter + size * Math.cos(0), yCenter + size * Math.sin(0))

//   for (var i = 0; i <= numberOfSides; i++) {
//     var curStep = i * step + shift
//     ctx.lineTo(xCenter + size * Math.cos(curStep), yCenter + size * Math.sin(curStep))
//   }
//   ctx.strokeStyle = color
//   ctx.stroke()
//   ctx.fill()
// }

// const shapeOfLegends = {
//   crackUnSafe: 'square-#FF0000', //red
//   spallingUnSafe: 'pentagon-#FF0000', //red
//   corrosionUnSafe: 'circle-#FF0000', //red
//   otherUnSafe: 'rhombus-#FF0000', //red
//   crackTotalRequireRepair: 'square-#FFFF00', //yellow
//   crackReportedRequireRepair: '',
//   spallingTotalRequireRepair: 'pentagon-#FFFF00', //yellow
//   spallingReportedRequireRepair: '',
//   corrosionTotalRequireRepair: 'circle-#FFFF00', //yellow
//   corrosionReportedRequireRepair: '',
//   otherTotalRequireRepair: 'rhombus-#FFFF00', //yellow
//   otherReportedRequireRepair: '',
//   crackTotalSafe: 'square-#00FF00', //green
//   crackReportedSafe: '',
//   spallingTotalSafe: 'pentagon-#00FF00', //green
//   spallingReportedSafe: '',
//   corrosionTotalSafe: 'circle-#00FF00', //green
//   corrosionReportedSafe: '',
//   otherTotalSafe: 'rhombus-#00FF00', //green
//   otherReportedSafe: '',
//   crackTotalFP: 'square-#00FFFF', //blue
//   spallingTotalFP: 'pentagon-#00FFFF', //blue
//   corrosionTotalFP: 'circle-#00FFFF', //blue
//   otherTotalFP: 'rhombus-#00FFFF' //blue
// }

// const drawShape = (ctx, x, y, sh, color, shape, countBoxOfRow, nextLine, totalCount, report) => {
//   ctx.lineWidth = 1
//   ctx.fillStyle = color
//   // create box color
//   if (shape === 'square') {
//     // hinh vuong
//     ctx.fillRect(x + 8 + countBoxOfRow * 40, y + sh - 42 - nextLine * 38, 32, 32)
//   } else if (shape === 'pentagon') {
//     // hinh ngu giac
//     const xCenter = x + 24 + countBoxOfRow * 40,
//       yCenter = y + sh - 26 - nextLine * 38,
//       size = 18
//     pentagon(ctx, xCenter, yCenter, size, color)
//   } else if (shape === 'circle') {
//     // hinh tron
//     const xCenter = x + 24 + countBoxOfRow * 40,
//       yCenter = y + sh - 26 - nextLine * 38,
//       size = 18
//     ctx.beginPath()
//     ctx.arc(xCenter, yCenter, size, 0, 2 * Math.PI, false)
//     ctx.fill()
//   } else {
//     // hinh thoi
//     const xCenter = x + 24 + countBoxOfRow * 40,
//       yCenter = y + sh - 26 - nextLine * 38,
//       size = 18
//     rhombus(ctx, xCenter, yCenter, size, color)
//   }

//   // fill number defect
//   ctx.fillStyle = '#000'
//   ctx.font = '14px Georgia'
//   report
//     ? ctx.fillText(report + '/' + totalCount, x + 15 + countBoxOfRow * 40, y + sh - 24 - nextLine * 38)
//     : ctx.fillText(totalCount, x + 20 + countBoxOfRow * 40, y + sh - 24 - nextLine * 38)
// }

// const depict = async (options, ctx) => {
//   const myOptions = Object.assign({}, options)
//   if (myOptions.direction) {
//     if (myOptions.row === 1) {
//       ctx.fillStyle = '#000'
//       ctx.font = '20px Georgia'
//       ctx.fillText(myOptions.column, myOptions.x - 30, myOptions.y + 200)
//     }
//     if (myOptions.column === 1) {
//       ctx.fillStyle = '#000'
//       ctx.font = '20px Georgia'
//       ctx.fillText(myOptions.row, myOptions.x + 150, myOptions.y - 20)
//     }
//   } else {
//     if (myOptions.row === 1) {
//       ctx.fillStyle = '#000'
//       ctx.font = '20px Georgia'
//       ctx.fillText(myOptions.column, myOptions.x + 200, myOptions.y - 20)
//     }
//     if (myOptions.column === 1) {
//       ctx.fillStyle = '#000'
//       ctx.font = '20px Georgia'
//       ctx.fillText(myOptions.row, myOptions.x - 30, myOptions.y + 150)
//     }
//   }
//   if (myOptions.uri) {
//     return await loadImage(myOptions.uri).then((img) => {
//       ctx.drawImage(img, myOptions.x, myOptions.y, myOptions.sw, myOptions.sh)
//       if (myOptions.closeRangeStatus === true) {
//         ctx.lineWidth = 4
//         ctx.strokeStyle = '#FF5F00'
//         ctx.strokeRect(myOptions.x + 2, myOptions.y + 2, myOptions.sw - 4, myOptions.sh - 4)
//       }
//       const defects = myOptions.defects
//       let totalAnno = Object.values(defects).reduce((sum, def) => sum + def, 0) || 0
//       if (totalAnno > 0) {
//         let countBoxOfRow = 0,
//           nextLine = 0
//         for (const key in shapeOfLegends) {
//           const count = defects[key]
//           if (key.indexOf('Reported') === -1) {
//             if (count > 0) {
//               if (countBoxOfRow === 4) {
//                 nextLine++
//                 countBoxOfRow -= 4
//               }
//               const keyReport = key.indexOf('Total') !== -1 ? key.replace('Total', 'Reported') : ''
//               const indexSlice = shapeOfLegends[key].indexOf('-')
//               const shape = shapeOfLegends[key].slice(0, indexSlice)
//               const color = shapeOfLegends[key].slice(indexSlice + 1, shapeOfLegends[key].length)

//               drawShape(
//                 ctx,
//                 myOptions.x,
//                 myOptions.y,
//                 myOptions.sh,
//                 color,
//                 shape,
//                 countBoxOfRow,
//                 nextLine,
//                 count,
//                 defects[keyReport] || null
//               )
//               countBoxOfRow++
//             }
//           }
//         }
//       }
//       const closeRangeDefect = myOptions.closeRangeDefect
//       totalAnno = Object.values(closeRangeDefect).reduce((sum, def) => sum + def, 0)
//       if (totalAnno > 0) {
//         let countBoxOfRow = 0,
//           nextLine = 0
//         for (const key in shapeOfLegends) {
//           const count = closeRangeDefect[key]
//           if (key.indexOf('Reported') === -1) {
//             if (count > 0) {
//               if (countBoxOfRow === 4) {
//                 nextLine++
//                 countBoxOfRow -= 4
//               }
//               const keyReport = key.indexOf('Total') !== -1 ? key.replace('Total', 'Reported') : ''
//               const indexSlice = shapeOfLegends[key].indexOf('-')
//               const shape = shapeOfLegends[key].slice(0, indexSlice)
//               const color = shapeOfLegends[key].slice(indexSlice + 1, shapeOfLegends[key].length)

//               drawShape(
//                 ctx,
//                 myOptions.x,
//                 myOptions.y,
//                 52,
//                 color,
//                 shape,
//                 countBoxOfRow,
//                 nextLine,
//                 count,
//                 closeRangeDefect[keyReport] || null
//               )
//               countBoxOfRow++
//             }
//           }
//         }
//       }
//     })
//   }
// }

// export const createFullElevationImage = async (
//   canvas1,
//   canvas2,
//   direction,
//   position,
//   listCountColorAnnotates,
//   listManualImages,
//   columns,
//   manualRow
// ) => {
//   const imgs = []
//   var countColors = {}
//   var countCloseRangeColor = {}
//   const ctx = canvas1.getContext('2d')
//   ctx.clearRect(0, 0, canvas1.width, canvas1.height)
//   const width = 400
//   const height = 300
//   if (direction === 0 || direction === 1) {
//     canvas1.width = position[0].length * width + 40
//     canvas1.height = position.length * height + 40

//     position.forEach((col, idc) => {
//       col.forEach((row, idr) => {
//         let findIndex = listCountColorAnnotates.map((i) => i.url).indexOf(row.url)
//         if (findIndex !== -1) {
//           countColors = listCountColorAnnotates[findIndex].defects || {}
//           countCloseRangeColor = listCountColorAnnotates[findIndex].closeRangeDefects || {}
//         } else {
//           countColors = {}
//           countCloseRangeColor = {}
//         }
//         imgs.push({
//           uri: row?.url ? row.url : row?.urlOriginal ? row.urlOriginal : '',
//           closeRangeStatus: row?.closeRangeStatus || false,
//           x: width * idr + 40,
//           y: height * idc + 40,
//           sw: width,
//           sh: height,
//           defects: countColors,
//           closeRangeDefect: countCloseRangeColor,
//           column: idr + 1,
//           row: idc + 1,
//           direction: false
//         })
//       })
//     })
//   } else {
//     canvas1.width = position.length * width + 40
//     canvas1.height = position[0].length * height + 40
//     position.forEach((row, idr) => {
//       ;(direction === 2 ? row : [...row].reverse()).forEach((col, idc) => {
//         let findIndex = listCountColorAnnotates.map((i) => i.url).indexOf(col.url)
//         if (findIndex !== -1) {
//           countColors = listCountColorAnnotates[findIndex].defects || {}
//           countCloseRangeColor = listCountColorAnnotates[findIndex].closeRangeDefects || {}
//         } else {
//           countColors = {}
//           countCloseRangeColor = {}
//         }
//         imgs.push({
//           uri: col?.url ? col.url : col?.urlOriginal ? col.urlOriginal : '',
//           closeRangeStatus: col?.closeRangeStatus || false,
//           x: width * idr + 40,
//           y: height * idc + 40,
//           sw: width,
//           sh: height,
//           defects: countColors,
//           closeRangeDefect: countCloseRangeColor,
//           column: idc + 1,
//           row: idr + 1,
//           direction: true
//         })
//       })
//     })
//   }

//   ctx.fillStyle = 'white'
//   ctx.fillRect(0, 0, canvas1.width, canvas1.height)

//   for (const item of imgs) {
//     await depict(item, ctx)
//   }

//   const manualImgs = []
//   const ctx2 = canvas2.getContext('2d')
//   ctx2.clearRect(0, 0, canvas2.width, canvas2.height)
//   canvas2.width = columns * width + 40
//   canvas2.height = manualRow * height + 40

//   listManualImages.forEach((item) => {
//     manualImgs.push({
//       uri: item?.url ? item.url : item?.urlOriginal ? item.urlOriginal : '',
//       closeRangeStatus: item?.closeRangeStatus || false,
//       x: width * (item.column - 1) + 40,
//       y: height * (item.row - 1) + 40,
//       sw: width,
//       sh: height,
//       defects: item?.defects || {},
//       closeRangeDefect: item?.closeRangeDefects || {},
//       column: item.column,
//       row: item.row,
//       direction: false
//     })
//   })

//   ctx2.fillStyle = 'white'
//   ctx2.fillRect(0, 0, canvas2.width, canvas2.height)

//   for (const item of manualImgs) {
//     await depict(item, ctx2)
//   }
// }
