import { TOKEN_KEY } from './configApis'
import apiInstance from './configApis'

const signIn = async (email, password) => {
  try {
    const { data } = await apiInstance.post('user/login', { email, password })
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const signUp = async (email, username, password) => {
  try {
    const { data } = await apiInstance.post('user/register', {
      email,
      username,
      password
    })
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const getCurrentUser = async (token = localStorage.getItem(TOKEN_KEY)) => {
  try {
    const { data } = await apiInstance.get('user/me')
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const getRoleAdmin = async () => {
  try {
    const data = await apiInstance.get(`role/admin-roles`)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}
const getRoleUser = async () => {
  try {
    const data = await apiInstance.get(`role/company-roles`)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

//  admin user

const getUsers = async (roletab, options) => {
  const url = '?' + new URLSearchParams(options).toString()
  try {
    if (roletab === 'user') {
      const data = await apiInstance.get(`user/all${url}`)
      return data
    } else {
      const data = await apiInstance.get(`user/all-admin${url}`)
      return data
    }
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const deleteUser = async (id) => {
  try {
    const { data } = await apiInstance.delete(`user/delete-user/${id}`)
    return data.users
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const createUser = async ({ email, username, company_id, building_ids, password, role, status, isClaritas, regno }) => {
  try {
    if (isClaritas) {
      const data = await apiInstance.post('user/register-admin', {
        email,
        username,
        role_id: role,
        status: 'Active',
        building_ids: building_ids || [0]
      })
      return data
    } else {
      const data = await apiInstance.post('user/register-user', {
        email,
        username,
        company_id,
        building_ids: building_ids?.length ? building_ids : undefined,
        status: 'Active',
        role_id: role,
        regno
      })
      return data
    }
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const updateUser = async ({
  id,
  email,
  username,
  company_id,
  building_ids,
  password,
  status,
  role,
  isClaritas,
  regno
}) => {
  try {
    const data = await apiInstance.patch('user/update-user', {
      id,
      username,
      company_id: isClaritas ? null : company_id,
      role_id: role,
      status,
      building_ids,
      regno
    })
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}
// const updateUser = async ({
//   id,
//   email,
//   username,
//   company_id,
//   building_ids,
//   password,
//   role,
//   isClaritas,
// }) => {
//   try {
//     if (isClaritas) {
//       const { data } = await apiInstance.patch("user/update-user-by-admin", {
//         id,
//         username,
//         role_id: role,
//         building_ids: null,
//         company_id : null,
//         status : "Active",
//         password
//       });
//       return data;
//     } else {
//       const { data } = await apiInstance.patch("user/update-user-by-admin", {
//         email,
//         username,
//         company_id,
//         building_ids,
//         role: role,
//       });
//       return data;
//     }
//   } catch (err) {
//     throw new Error(err?.response.data.message)
//   }
// };
const changePassword = async ({ email, password, keyMail, otpCodes }) => {
  try {
    const { data } = await apiInstance.post('user/reset-Password', {
      email,
      password,
      keyMail,
      otpCodes
    })
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const getEmail = async ({ email, statusCode }) => {
  try {
    const { data } = await apiInstance.post('user/send-mail-otp', {
      email,
      statusCode
    })
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const forgetPassword = async ({ email, statusCode }) => {
  try {
    const { data } = await apiInstance.post('user/forgot-password', {
      email,
      statusCode
    })
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const VerifyOtp = async ({ email, otpCodes }) => {
  try {
    const { data } = await apiInstance.post('user/verify-otp', {
      email,
      otpCodes
    })
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const verifyEmailSend = async (data2) => {
  const email = data2.email
  const keySendMail = data2.keySendMail
  try {
    const { data } = await apiInstance.post('user/check-send-mail', {
      email,
      keySendMail
    })
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const getUsersCompany = async (companyId, options) => {
  const url = '?' + new URLSearchParams(options).toString()
  try {
    const data = await apiInstance.get(`user/filter-user-company/${companyId}${url}`)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const userApi = {
  signIn,
  signUp,
  getCurrentUser,
  getUsers,
  createUser,
  updateUser,
  deleteUser,
  getEmail,
  getRoleAdmin,
  getRoleUser,
  VerifyOtp,
  forgetPassword,
  changePassword,
  verifyEmailSend,
  getUsersCompany
}

export default userApi
