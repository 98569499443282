import React from 'react'
import '../Report/index.css'
import { formatMetadata } from '../../utils/common'
function CloseRangePhotoRecord({ crDefectListing }) {

  return (
    <ol className="toc-list" style={{ lineHeight: '30px' }}>
      <li id="record-v">
        <div className="font-bold">
          <div className="py-4 text-sm font-bold">
            Photos of Selected Defects Identified During Close Range Inspection
          </div>
        </div>
      </li>
      <div style={{ lineHeight: 'normal', width: '100%' }}>
        {crDefectListing?.map((crDefectListings) => {
          if (crDefectListings?.photoRecord === true) {
            const metadata = formatMetadata(crDefectListings?.metadata)
            const { longitude, latitude, altitude } = metadata || {}
            if (crDefectListings?.type !== 'Thumbnail') {
              return (
                <table
                  className="border-[1px] w-full photo-record table-test table-break"
                  style={{ fontSize: '12px' }}
                >
                  <tr className="border-[1px]">
                    <td className="border-[1px] p-2 font-bold w-[115px]">Defect Id #</td>
                    <td className="border-[1px] p-2 font-bold w-[242px]">{crDefectListings?.orderId}</td>
                    <td rowSpan={7} className=" h-full border-[1px] align-baseline w-[242px]">
                      <div className="p-2 font-bold">Close Range Image</div>
                      {crDefectListings?.url && (
                        <img
                          className="p-10 max-w-full max-h-[300px] bg-white"
                          alt="crImage"
                          src={crDefectListings?.url}
                        />
                      )}
                    </td>
                  </tr>
                  <tr className="border-[1px]">
                    <td className="border-[1px] p-2 font-bold">Elevation</td>
                    <td className="border-[1px] p-2">{crDefectListings?.elevation_name}</td>
                  </tr>
                  <tr className="border-[1px]">
                    <td className="border-[1px] p-2 font-bold">Location</td>
                    <td className="border-[1px] p-2">
                      {crDefectListings?.row} - {crDefectListings?.column}
                    </td>
                  </tr>
                  <tr className="border-[1px]">
                    <td className="border-[1px] p-2 font-bold">Element</td>
                    <td className="border-[1px] p-2">{crDefectListings?.element}</td>
                  </tr>
                  <tr className="border-[1px]">
                    <td className="border-[1px] p-2 font-bold">Defect</td>
                    <td className="border-[1px] p-2">{crDefectListings?.tags}</td>
                  </tr>
                  {/* longitude, latitude */}
                  <tr className="border-[1px]">
                    <td className="border-[1px] p-2 font-bold">Longtitude - Latitude</td>
                    <td className="border-[1px] p-2">
                      {longitude && latitude && (
                        <>
                          {longitude} - {latitude}
                        </>
                      )}
                    </td>
                  </tr>
                  <tr className="border-[1px]">
                    <td className="border-[1px] p-2 font-bold">Aitutude</td>
                    <td className="border-[1px] p-2">
                      {altitude}
                    </td>
                  </tr>
                  <tr className="border-[1px]">
                    <td className="border-[1px] p-2 font-bold">Severity</td>
                    <td className="border-[1px] p-2">{crDefectListings?.cls}</td>
                    <td rowSpan={5} className=" h-full border-[1px] align-baseline">
                      <div className="p-2 font-bold">Visual Inspection Image</div>
                      {crDefectListings?.fromUrl && (
                        <img
                          className="p-10 max-w-full max-h-[300px] bg-white"
                          alt="vsImage"
                          src={crDefectListings?.fromUrl}
                        />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="border-[1px] p-2 font-bold">Visual Defect ID</td>
                    <td className="border-[1px] p-2">{crDefectListings?.visualOrderId}</td>
                  </tr>
                  <tr className="border-[1px]">
                    <td className="border-[1px] p-2 font-bold">Remarks</td>
                    <td className="border-[1px] p-2">{crDefectListings?.remarks}</td>
                  </tr>
                  <tr className="border-[1px]">
                    <td className="border-[1px] p-2 font-bold">Reason for Defect</td>
                    <td className="border-[1px] p-2">
                      {crDefectListings?.reasons?.map((reason, index) => (
                        <>
                          {reason?.label}
                          {index === crDefectListings?.reasons?.length - 1 ? '' : ', '}
                        </>
                      ))}
                    </td>
                  </tr>
                  <tr className="border-[1px]">
                    <td className="border-[1px] p-2 font-bold">Repair Recommended</td>
                    <td className="border-[1px] p-2">
                      {crDefectListings?.repairs?.map((repair, index) => (
                        <>
                          {repair?.label}
                          {index === crDefectListings?.repairs?.length - 1 ? '' : ', '}
                        </>
                      ))}
                    </td>
                  </tr>

                </table>
              )
            } else {
              return (
                <table
                  className="border-[1px] w-full photo-record table-test table-break"
                  style={{ fontSize: '12px' }}
                >
                  <tr className="border-[1px]">
                    <td className="border-[1px] p-2 font-bold">Defect Id #</td>
                    <td className="border-[1px] p-2 font-bold">{crDefectListings?.orderId}</td>
                    <td rowSpan={12} className=" h-full border-[1px] align-baseline">
                      <div className="p-2 font-bold">Close Range Image</div>
                      {crDefectListings?.url && (
                        <img
                          className="p-10 max-w-full max-h-[300px] bg-white"
                          alt="crImage"
                          src={crDefectListings?.url}
                        />
                      )}
                    </td>
                  </tr>
                  <tr className="border-[1px]">
                    <td className="border-[1px] p-2 font-bold">Elevation</td>
                    <td className="border-[1px] p-2">{crDefectListings?.elevation_name}</td>
                  </tr>
                  <tr className="border-[1px]">
                    <td className="border-[1px] p-2 font-bold">Location</td>
                    <td className="border-[1px] p-2">
                      {crDefectListings?.row} - {crDefectListings?.column}
                    </td>
                  </tr>
                  <tr className="border-[1px]">
                    <td className="border-[1px] p-2 font-bold">Element</td>
                    <td className="border-[1px] p-2">{crDefectListings?.element}</td>
                  </tr>
                  <tr className="border-[1px]">
                    <td className="border-[1px] p-2 font-bold">Defect</td>
                    <td className="border-[1px] p-2">{crDefectListings?.tags}</td>
                  </tr>
                  <tr className="border-[1px]">
                    <td className="border-[1px] p-2 font-bold">Severity</td>
                    <td className="border-[1px] p-2">{crDefectListings?.cls}</td>
                    {/* <td rowSpan={5} className=" h-full border-[1px] align-baseline">
                        <div className="p-2 font-bold">Visual Inspection Image</div>
                        {crDefectListings?.fromUrl && (
                          <img className="p-5 bg-white h-80" alt="vsImage" src={crDefectListings?.fromUrl} />
                        )}
                      </td> */}
                  </tr>
                  <tr>
                    <td className="border-[1px] p-2 font-bold">Visual Defect ID</td>
                    <td className="border-[1px] p-2">{crDefectListings?.visualOrderId}</td>
                  </tr>
                  <tr className="border-[1px]">
                    <td className="border-[1px] p-2 font-bold">Remarks</td>
                    <td className="border-[1px] p-2 break-work-custom">{crDefectListings?.remarks}</td>
                  </tr>
                  <tr className="border-[1px]">
                    <td className="border-[1px] p-2 font-bold">Reason for Defect</td>
                    <td className="border-[1px] p-2">
                      {crDefectListings?.reasons?.map((reason, index) => (
                        <>
                          {reason?.label}
                          {index === crDefectListings?.reasons?.length - 1 ? '' : ', '}
                        </>
                      ))}
                    </td>
                  </tr>
                  <tr className="border-[1px]">
                    <td className="border-[1px] p-2 font-bold">Repair Recommended</td>
                    <td className="border-[1px] p-2">
                      {crDefectListings?.repairs?.map((repair, index) => (
                        <>
                          {repair?.label}
                          {index === crDefectListings?.repairs?.length - 1 ? '' : ', '}
                        </>
                      ))}
                    </td>
                  </tr>
                  {/* longitude, latitude */}
                  <tr className="border-[1px]">
                    <td className="border-[1px] p-2 font-bold">Longtitude - Latitude</td>
                    <td className="border-[1px] p-2">
                      {longitude && latitude && (
                        <>
                          {longitude} - {latitude}
                        </>
                      )}
                    </td>
                  </tr>
                  <tr className="border-[1px]">
                    <td className="border-[1px] p-2 font-bold">Aitutude</td>
                    <td className="border-[1px] p-2">
                      {altitude}
                    </td>
                  </tr>
                </table>
              )
            }
          }
        })}
      </div>
    </ol>
  )
}

export default CloseRangePhotoRecord
