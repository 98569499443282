import React, { useRef, useState, useEffect } from 'react'
import Paper from '@mui/material/Paper'
import { makeStyles } from '@mui/styles'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import styles from './style'
import classnames from 'classnames'
import IconButton from '@mui/material/IconButton'
import TrashIcon from '@mui/icons-material/Delete'
const theme = createTheme()
const useStyles = makeStyles((theme) => styles)
const CustomRegionLabelOnceColor = ({
  region,
  editing,
  allowedClasses,
  allowedTags,
  onDelete,
  onChange,
  onClose,
  onOpen,
  onRegionClassAdded,
  allowComments
}) => {
  if (region) {
    if (region.color !== '#FF5F00') {
      onChange({
        ...region,
        color: '#FF5F00'
      })
    }
  }
  const classes = useStyles()
  return (
    <ThemeProvider theme={theme}>
      <Paper
        onClick={() => (!editing ? onOpen(region) : null)}
        className={classnames(classes.regionInfo, {
          highlighted: region.highlighted
        })}
      >
        {!editing ? (
          <div></div>
        ) : (
          <div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ flexGrow: 1 }} />
              <IconButton
                onClick={() => onDelete(region)}
                tabIndex={-1}
                style={{ width: 22, height: 22 }}
                size="small"
                variant="outlined"
              >
                <TrashIcon style={{ marginTop: -8, width: 16, height: 16 }} />
              </IconButton>
            </div>
          </div>
        )}
      </Paper>
    </ThemeProvider>
  )
}
export default CustomRegionLabelOnceColor
