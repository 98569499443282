const reportTypes = {
  GET_INSPECTION_REPORT: 'GET_INSPECTION_REPORT',
  GET_CLOSE_RANGE_DEFECT_SUMMARY: 'GET_CLOSE_RANGE_DEFECT_SUMMARY',
  UPDATE_INSPECTION_REPORT: 'UPDATE_INSPECTION_REPORT',
  ADD_INSPECTOR: 'ADD_INSPECTOR',
  ADD_DECLARE: 'ADD_DECLARE',
  ADD_CONFIRMATION: 'ADD_CONFIRMATION',
  ADD_LOCK: 'ADD_LOCK',
  GET_DEFECT_LISTING: 'GET_DEFECT_LISTING',
  GET_INSPECTION_REPORT_DEFECT_SUMMARY: 'GET_INSPECTION_REPORT_DEFECT_SUMMARY',
  UPDATE_LOCK_REPORT: 'UPDATE_LOCK_REPORT',
  UPDATE_REPORT_CONFIRMATION: 'UPDATE_REPORT_CONFIRMATION'
}

export default reportTypes
