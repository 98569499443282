import React, { useState } from 'react'
import { ExclamationCircleOutlined, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'
import { Form, Formik, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'

//action
import { changePassword } from '../../store/thunks/userThunk'
import LoadingSpinner from '../Common/loading-spinner/LoadingSpinner'
import { toast } from 'react-toastify'

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required('Please enter a password')
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+={}\[\]|\\:;"'<,>.?\/])(?=.*[^\s]).{8,}$/,
      'Password must have at least 8 characters, contain at least one uppercase letter, one lowercase letter, one number, and one symbol'
    ),
  confirmPassword: Yup.string()
    .required('Please re-enter your password')
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
})

const ChangePassword = ({ checkingOTP, checkingEmail, OTPcodes, keyMail }) => {
  const dispatch = useDispatch()

  const [submitting, setSubmitting] = useState(false)
  const [iconPassword, setIconPassword] = useState('hide')
  const [iconPasswordConfirm, setIconPasswordConfirm] = useState('hide')
  const [typePassword, setTypePassword] = useState('password')
  const [typePasswordConfirm, setTypePasswordConfirm] = useState('password')
  const [isPassWord, setIsPassWord] = useState(false)

  const handleToggle = () => {
    if (typePassword === 'password') {
      setIconPassword('show')
      setTypePassword('text')
    } else {
      setIconPassword('hide')
      setTypePassword('password')
    }
  }
  const handleTogglePasswordConfirm = () => {
    if (typePasswordConfirm === 'password') {
      setIconPasswordConfirm('show')
      setTypePasswordConfirm('text')
    } else {
      setIconPasswordConfirm('hide')
      setTypePasswordConfirm('password')
    }
  }

  const handleResetPassword = async (password, e) => {
    try {
      setSubmitting(true)
      e.preventDefault()
      const data = {
        email: checkingEmail.toLowerCase(),
        password: password,
        keyMail: keyMail,
        otpCodes: OTPcodes ? OTPcodes.trim() : ''
      }
      dispatch(changePassword(data))
        .unwrap()
        .then((originalPromiseResult) => {
          setIsPassWord(!isPassWord)
          setSubmitting(false)
        })
        .catch((rejectedValueOrSerializedError) => {
          toast.error('Something went wrong')
          setSubmitting(false)
        })
    } catch (err) {
      setSubmitting(true)
      toast.error('Something went wrong')
    }
  }

  return (
    <>
      {submitting && <LoadingSpinner />}
      {!isPassWord ? (
        <>
          <p className="my-5 text-3xl font-bold flex justify-center">Reset Password</p>

          <Formik
            initialValues={{
              password: '',
              confirmPassword: ''
            }}
            validationSchema={validationSchema}
          >
            {({ isValid, dirty, values }) => (
              <Form>
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-bold text-primary" htmlFor="username">
                    New Password
                  </label>
                  <div className="flex items-center w-full px-3 py-2 bg-white leading-tight text-primary border rounded shadow appearance-none focus:outline-none focus:shadow-outline">
                    <Field
                      type={typePassword}
                      id="password"
                      placeholder="Password"
                      className="h-full w-full border-0 outline-none"
                      name="password"
                      value={values.password}
                    />
                    <span onClick={handleToggle} className="cursor-pointer">
                      {iconPassword === 'hide' ? (
                        <EyeOutlined style={{ fontSize: 20 }} />
                      ) : (
                        <EyeInvisibleOutlined style={{ fontSize: 20 }} />
                      )}
                    </span>
                  </div>
                  <ErrorMessage name="password">
                    {(errMsg) => (
                      <div className="text-red-500 mt-1 flex items-center">
                        <ExclamationCircleOutlined />
                        <span className="ml-1">{errMsg}</span>
                      </div>
                    )}
                  </ErrorMessage>
                </div>
                <div className="mb-6">
                  <label className="block mb-2 text-sm font-bold text-primary" htmlFor="Confirm password">
                    Confirm Password
                  </label>
                  <div className="flex items-center w-full px-3 py-2 bg-white leading-tight text-primary border rounded shadow appearance-none focus:outline-none focus:shadow-outline">
                    <Field
                      type={typePasswordConfirm}
                      id="confirmPassword"
                      placeholder="Confirm Password"
                      className="h-full w-full border-0 outline-none"
                      name="confirmPassword"
                      value={values.confirmPassword}
                    />
                    <span onClick={handleTogglePasswordConfirm} className="cursor-pointer">
                      {iconPasswordConfirm === 'hide' ? (
                        <EyeOutlined style={{ fontSize: 20 }} />
                      ) : (
                        <EyeInvisibleOutlined style={{ fontSize: 20 }} />
                      )}
                    </span>
                  </div>
                  <ErrorMessage name="confirmPassword">
                    {(errMsg) => (
                      <div className="text-red-500 mt-1 flex items-center">
                        <ExclamationCircleOutlined />
                        <span className="ml-1">{errMsg}</span>
                      </div>
                    )}
                  </ErrorMessage>
                </div>
                <div className="flex items-center justify-between my-8">
                  <button
                    className="w-full px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline"
                    type="submit"
                    disabled={!isValid || !dirty}
                    onClick={(e) => {
                      handleResetPassword(values.confirmPassword, e)
                    }}
                  >
                    RESET PASSWORD
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </>
      ) : (
        <div className="px-10 pt-4 pb-4 mb-4">
          <p className="my-5 mb-4 text-3xl font-bold">Password Changed</p>
          <label className="block mb-8 text-sm font-medium text-primary">
            Your password has been changed successfully.
          </label>
          <Link className=" text-white hover:text-white" to="/login">
            <button
              className="w-full px-4 py-2 font-bold  bg-blue-500 rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline"
              type="submit"
            >
              RETURN TO LOG-IN
            </button>
          </Link>
        </div>
      )}
    </>
  )
}

export default ChangePassword
