import apiInstance from './configApis'

const getInspectionReport = async (buildingId) => {
  try {
    const data = await apiInstance.get(`inspection-report/${buildingId}`)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const getInspectionDefectSummary = async (buildingId) => {
  try {
    const data = await apiInstance.get(`inspection-report/defect-summary/${buildingId}`)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const getCloseRangeDefectSummary = async (buildingId) => {
  try {
    const data = await apiInstance.get(`/inspection-report/close-range-defect-summary/${buildingId}`)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const updateInspectionReport = async (report) => {
  try {
    const reportData = {
      building_id: +report.building_id,
      company: report.company ? report.company : undefined,
      dates: report.dates ? report.dates : undefined,
      location: report.location ? report.location : undefined,
      inspectors_comment: report.inspectors_comment ? report.inspectors_comment : undefined,
      witnesses: report.witnesses ? report.witnesses : undefined,
      preparation: report.preparation ? report.preparation : undefined,
      reference: report.reference ? report.reference : undefined,
      background: report.background ? report.background : undefined,
      objectives: report.objectives ? report.objectives : undefined,
      systems: report.systems ? report.systems : undefined,
      defect_classifications: report.defect_classifications ? report.defect_classifications : undefined,
      summary: report.summary ? report.summary : undefined,
      recommendations: report.recommendations ? report.recommendations : undefined,
      drawing: report.drawing ? report.drawing : undefined,
      building_height: report.building_height ? report.building_height : undefined,
      storeys: report.storeys ? parseInt(report.storeys) : undefined,
      block_number: report.block_number ? report.block_number : undefined,
      age: report.age ? report.age : undefined,
      conclusion: report.conclusion ? report.conclusion : undefined,
      bca_notice: report.bca_notice ? report.bca_notice : undefined,
      report_reference: report.report_reference ? report.report_reference : undefined,
      inspection_datetime: report.inspection_datetime ? report.inspection_datetime : undefined,
      weather_condition: report.weather_condition ? report.weather_condition : undefined,
      inspection_lastdate: report.inspection_lastdate ? report.inspection_lastdate : undefined,
      elevation_marking: report.elevation_marking ? report.elevation_marking : undefined,
      duration_visual: report.duration_visual ? report.duration_visual : undefined,
      duration_closeup: report.duration_closeup ? report.duration_closeup : undefined,
      location_closeup: report.location_closeup ? report.location_closeup : undefined,
      tools_used: report.tools_used ? report.tools_used : undefined,
      inspection_assessment: report.inspection_assessment ? report.inspection_assessment : undefined,
      facade_type: report.facade_type ? report.facade_type : undefined,
      cladding_material: report.cladding_material ? report.cladding_material : undefined,
      attachment: report.attachment ? report.attachment : undefined,
      exterior_false_ceiling: report.exterior_false_ceiling ? report.exterior_false_ceiling : undefined,
      uas_flight_datetime: report.uas_flight_datetime ? report.uas_flight_datetime : undefined,
      purpose: report.purpose ? report.purpose : undefined,
      caas_permit_no: report.caas_permit_no ? report.caas_permit_no : undefined,
      areas: report.areas ? report.areas : undefined,
      media_file: report.media_file ? report.media_file : undefined,
      uas_model: report.uas_model ? report.uas_model : undefined,
      tethered: report.tethered,
      camera: report.camera ? report.camera : undefined,
      client_name: report.client_name ? report.client_name : undefined,
      client_address: report.client_address ? report.client_address : undefined,
      site_layout: report.site_layout ? report.site_layout : undefined,
      site_buildings: report.site_buildings ? report.site_buildings : undefined,
      site_others: report.site_others ? report.site_others : undefined,
      q1a: report.q1a,
      q1b: report.q1b,
      q1c: report.q1c,
      q2a: report.q2a,
      q2b: report.q2b,
      q3a: report.q3a,
      q3b: report.q3b,
      q3c: report.q3c,
      q4a: report.q4a,
      q4b: report.q4b,
      q4c: report.q4c,
      q4d: report.q4d,
      q5: report.q5,
      q6a: report.q6a,
      q6b: report.q6b,
      q6c: report.q6c,
      q6d: report.q6d,
      q6e: report.q6e,
      q6f: report.q6f,
      q6g: report.q6g,
      q6h: report.q6h,
      q6h_comment: report.q6h_comment ? report.q6h_comment : undefined,
      q7: report.q7,
      q8: report.q8,
      q9: report.q9,
      q10: report.q10,
      q11: report.q11,
      q12: report.q12
    }
    const data = await apiInstance.post(`/inspection-report`, reportData)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}
const addInspector = async ({ building_id, inspector_ids, cp_id }) => {
  try {
    const data = await apiInstance.post(`/inspection-report/add-inspectors`, { building_id, inspector_ids, cp_id })
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}
const getDefectListing = async (id) => {
  try {
    const data = await apiInstance.get(`/inspection-report/defect-listing/${id}`)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const addDeclare = async ({ building_id, checked }) => {
  try {
    const data = await apiInstance.post(`/inspection-report/declaration`, { building_id, checked })
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const confirmation = async ({ building_id, description }) => {
  try {
    const data = await apiInstance.post(`/inspection-report/confirmation`, { building_id, description })
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}
const updateReportConfirmation = async ({ building_id, description }) => {
  try {
    const data = await apiInstance.post(`/inspection-report/confirmation`, { building_id, description })
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const updateLockReport = async ({ building_id, locked }) => {
  try {
    const data = await apiInstance.post(`/inspection-report/lock`, { building_id, locked })
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}
const reportApi = {
  getInspectionReport,
  getInspectionDefectSummary,
  getCloseRangeDefectSummary,
  updateInspectionReport,
  addInspector,
  getDefectListing,
  addDeclare,
  confirmation,
  updateLockReport,
  updateReportConfirmation
}

export default reportApi
