// libs
import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { onConfirm } from 'react-confirm-pro'
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'

// hocs
import useAuth from '../../../../hooks/useAuth'
import usePrevious from '../../../../hooks/usePrevious'

// actions
import {
  createCompanyReason,
  updateCompanyReason,
  deleteCompanyReason,
  getCompanyReason
} from '../../../../store/thunks/reasonThunk'

// commons
import Table from '../../../Common/table2'
import Modal from '../../../Common/Modal'

// @antd
import { SaveOutlined, ExclamationCircleOutlined, EditOutlined, DeleteOutlined, CloseOutlined } from '@ant-design/icons'

// assets

// variables
import { adminRoles } from '../../../../constant/auth'
import { color } from '../../../../constant/styled'
import reasonTypes from '../../../../constant/store/reasonTypes'
import SkeletonViewer from '../../../Common/skeleton-viewer'
import LoadingSpinner from '../../../Common/loading-spinner/LoadingSpinner'
import NotAvailable from '../../../Common/NotAvailable'
import PaddingAuto from '../../../Layout/PaddingAuto'
import { isEmptyObject } from '../../../../utils/common'

const ReasonsForDefect = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { user } = useAuth()
  const { role } = user?.user || {}
  const {
    loadings: loadingsReason,
    contents: contentsReason,
    errors: errorsReason,
    paginations: paginationsReason
  } = useSelector((state) => state.reason)
  const [_mounted, setMouted] = useState(false)
  const reasons = contentsReason[reasonTypes.GET_COMPANY_REASON]
  const { companyId } = useParams()
  const [submitting, setSubmitting] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [currentReason, setCurrentReason] = useState(null)
  const [confirm, setConfirm] = useState(false)
  const [tableOptions, setTableOptions] = useState({})
  const [renderingProcess, setRenderingProcess] = useState({
    loading: true,
    error: false
  })

  const paginationsOptions = paginationsReason[reasonTypes.GET_COMPANY_REASON]
  const loadingsGetReason = loadingsReason[reasonTypes.GET_COMPANY_REASON]
  const prevReasonGetFetching = usePrevious(loadingsReason[reasonTypes.GET_COMPANY_REASON])
  const prevReasonCreateFetching = usePrevious(loadingsReason[reasonTypes.CREATE_COMPANY_REASON])
  const prevReasonUpdateFetching = usePrevious(loadingsReason[reasonTypes.UPDATE_COMPANY_REASON])
  const prevReasonDeleteFetching = usePrevious(loadingsReason[reasonTypes.DELETE_COMPANY_REASON])

  const initialValues = {
    reason: currentReason?.name || ''
  }

  const actions = [
    {
      id: 1,
      title: '',
      isShow: adminRoles.includes(role)
    }
  ]

  const CompanyColumns = [
    {
      Header: 'Reason',
      accessor: 'name',
      width: 150
    },

    {
      Header: 'Actions',
      hideHeader: true,
      disableSortBy: true,
      role: adminRoles,
      width: 92,
      maxWidth: 92,
      Cell: (tableProps) => {
        const { id } = tableProps.row?.original
        return (
          <>
            <div className="flex items-center gap-2">
              <div className="relative">
                <div
                  className="inline-flex items-center font-bold py-2 px-3 rounded-lg bg-view-detail group-hover:bg-white group-hover:text-blue-button cursor-pointer"
                  onClick={() => handleEditReason(tableProps.row.original.id)}
                >
                  <EditOutlined
                    style={{
                      fontSize: '20px',
                      cursor: 'pointer',
                      color: color.green
                    }}
                  />
                </div>
              </div>
              <div
                className="inline-flex items-center font-bold py-2 px-3 rounded-lg bg-view-detail group-hover:bg-white group-hover:text-blue-button cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation()
                  onConfirm({
                    title: <h3 className="text-center">Are you sure you want to delete this reason?</h3>,
                    description: <br />,
                    onSubmit: async () => {
                      dispatch(deleteCompanyReason({ id }))
                    },
                    onCancel: () => {},
                    buttons: ({ onSubmit, onCancel }) => (
                      <div className="custom-buttons">
                        <button style={{ background: 'rgba(37, 99, 235)', color: 'white' }} onClick={onSubmit}>
                          YES
                        </button>
                        <button style={{ background: 'rgba(220, 38, 38)', color: 'white' }} onClick={onCancel}>
                          NO
                        </button>
                      </div>
                    )
                  })
                }}
              >
                <DeleteOutlined
                  style={{
                    fontSize: '20px',
                    cursor: 'pointer',
                    color: color.red
                  }}
                  alt="delete"
                />
              </div>
            </div>
          </>
        )
      }
    }
  ]

  const handleCloseModalConfirm = () => {
    setConfirm(false)
    setCurrentReason(null)
  }

  const handleCloseModal = () => {
    setShowModal(false)
    setCurrentReason(null)
  }

  const onSubmitUpdate = (values, onSubmitProps) => {
    setSubmitting(true)
    const { reason } = values
    try {
      const dataReq = {
        id: currentReason.id,
        reason
      }
      dispatch(updateCompanyReason(dataReq))
    } catch (err) {
      toast.error('Error when updating building')
    }
  }

  const onSubmitAdd = (values, onSubmitProps) => {
    setSubmitting(true)
    const { reason } = values
    try {
      const dataReq = {
        company_id: parseInt(user?.user?.companyId || companyId),
        reason
      }

      dispatch(createCompanyReason(dataReq))
    } catch (err) {
      toast.error('Error when add reason')
    }
  }

  const validationSchema = Yup.object({
    reason: Yup.string().required('Please enter reason')
  })

  const handleEditReason = (id) => {
    setShowModal(true)
    const indexReason = reasons.findIndex((r) => r.id === id)
    setCurrentReason(reasons[indexReason])
  }

  useMemo(() => {
    setMouted(true)
  }, [])

  // get data table

  const handleNavigateParamsUrl = (params, replace = false, pathname) => {
    navigate(
      {
        pathname: pathname,
        search: createSearchParams({
          page: params.page,
          size: params.size,
          ...params
        }).toString()
      },
      { replace }
    )
  }

  useEffect(() => {
    if (!location.search) {
      handleNavigateParamsUrl({ page: 1, size: 10 }, true, '/settings/reasons-for-defect')
    }
  }, [])

  useEffect(() => {
    if (_mounted && location.search) {
      const params = Object.fromEntries(new URLSearchParams(window.location.search))
      const options = {
        ...params,
        ...(params.page ? { page: params.page } : { page: 1 }),
        ...(params.size && params.size >= 10 ? { size: params.size } : { size: 10 })
      }
      setTableOptions(options)
    }
  }, [location.search])

  useEffect(() => {
    const get = async () => {
      if (_mounted && location.search && !isEmptyObject(tableOptions)) {
        dispatch(getCompanyReason({ id: user?.user?.companyId || companyId, options: tableOptions }))
      }
    }
    get()
  }, [dispatch, user, tableOptions])

  // end get data table

  useEffect(() => {
    return () => {
      setMouted(false)
    }
  }, [])

  useEffect(() => {
    if (prevReasonGetFetching && !loadingsReason[reasonTypes.GET_COMPANY_REASON]) {
      if (!errorsReason[reasonTypes.GET_COMPANY_REASON]) {
        setRenderingProcess((prev) => ({ ...prev, loading: false }))
      } else {
        setRenderingProcess((prev) => ({ ...prev, error: true, loading: false }))
      }
    }
  }, [loadingsReason, errorsReason, prevReasonGetFetching])

  useEffect(() => {
    if (prevReasonCreateFetching && !loadingsReason[reasonTypes.CREATE_COMPANY_REASON]) {
      setSubmitting(false)
      if (!errorsReason[reasonTypes.CREATE_COMPANY_REASON]) {
        handleNavigateParamsUrl({ page: 1, size: 10 }, false, `/settings/reasons-for-defect`)
        setShowModal(false)
        setCurrentReason(null)
        toast.success('Reason created', {
          theme: 'dark',
          autoClose: 2000
        })
        setShowModal(false)
      } else {
        toast.error(errorsReason[reasonTypes.CREATE_COMPANY_REASON], {
          theme: 'dark',
          autoClose: 2000
        })
      }
    }
  }, [errorsReason, loadingsReason, prevReasonCreateFetching])

  useEffect(() => {
    if (prevReasonUpdateFetching && !loadingsReason[reasonTypes.UPDATE_COMPANY_REASON]) {
      setSubmitting(false)
      if (!errorsReason[reasonTypes.UPDATE_COMPANY_REASON]) {
        setShowModal(false)
        setCurrentReason(null)
        toast.success('Reason updated', {
          theme: 'dark',
          autoClose: 2000
        })
        setShowModal(false)
      } else {
        toast.error(errorsReason[reasonTypes.UPDATE_COMPANY_REASON], {
          theme: 'dark',
          autoClose: 2000
        })
      }
    }
  }, [errorsReason, loadingsReason, prevReasonUpdateFetching])
  useEffect(() => {
    if (prevReasonDeleteFetching && !loadingsReason[reasonTypes.DELETE_COMPANY_REASON]) {
      if (!errorsReason[reasonTypes.DELETE_COMPANY_REASON]) {
        let newPage = tableOptions.page
        if (newPage > 1 && contentsReason[reasonTypes.GET_COMPANY_REASON].length === 0) {
          newPage = newPage - 1
        }
        handleNavigateParamsUrl({ size: 10, ...tableOptions, page: newPage }, false, `/settings/reasons-for-defect`)
        setShowModal(false)
        setCurrentReason(null)
        toast.success('Reason deleted', {
          theme: 'dark',
          autoClose: 2000
        })
        setShowModal(false)
      } else {
        toast.error(errorsReason[reasonTypes.DELETE_COMPANY_REASON], {
          theme: 'dark',
          autoClose: 2000
        })
      }
    }
  }, [errorsReason, loadingsReason, prevReasonDeleteFetching])

  const modalConfirm = () => {
    return (
      <Modal wrapper="w-auto max-w-md mx-auto" onClose={handleCloseModalConfirm}>
        <div className="w-88">
          <div className="flex items-center justify-between p-3 border-b border-solid rounded-t border-slate-200">
            <div className="text-xl text-primary font-semibold ">Notification</div>
            <span className="block w-6 h-6 ">
              <CloseOutlined style={{ fontSize: '22px', cursor: 'pointer' }} onClick={() => setConfirm(false)} />
            </span>
          </div>
          {/*body*/}
          <div className="relative flex flex-auto p-6">
            <div className="w-full max-w-lg">Are you sure to delete the company?</div>
          </div>
          <div className="flex items-center justify-between p-6 border-t border-solid rounded-b border-slate-200">
            <button
              className="px-6 py-3 mb-1 mr-1 text-sm font-bold cursor-pointer text-white uppercase transition-all duration-150 ease-linear bg-red-500 rounded shadow outline-none active:bg-gray-600 hover:shadow-lg focus:outline-none"
              type="button"
              onClick={() => {
                setConfirm(false)
                setCurrentReason(null)
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      </Modal>
    )
  }

  const renderSkeleton = () => {
    return (
      <div style={{ width: '100%', margin: '0 auto', marginTop: '16px' }}>
        <div className="flex items-center flex-wrap gap-2 md:gap-6">
          {actions &&
            Array.from({ length: actions.length + 1 }, function (v, k) {
              return k
            }).map((i) => (
              <div key={i}>
                <SkeletonViewer borderRadius={16} className="h-8 md:h-10 w-32 md:w-44" />
              </div>
            ))}
          <div className="sm:ml-auto">
            <SkeletonViewer borderRadius={16} className="h-8 md:h-10 w-[90vw] sm:w-40 md:w-56 lg:w-72" />
          </div>
        </div>
        <div>
          <SkeletonViewer className="mt-2" height={56} width="100%" />
          <div className="block overflow-hidden  mt-1" style={{ height: '64vh' }}>
            {Array.from({ length: 10 }, function (v, k) {
              return k
            }).map((i) => (
              <div key={i}>
                <SkeletonViewer className="mt-2" borderRadius={12} height={68} width="100%" />
              </div>
            ))}
          </div>
        </div>
        <div className="flex justify-center">
          <SkeletonViewer className="h-8 md:h-10 mt-4" width={300} />
        </div>
      </div>
    )
  }

  return (
    <React.Fragment>
      <div>
        <PaddingAuto>
          {submitting && <LoadingSpinner />}

          {renderingProcess.loading ? (
            renderSkeleton()
          ) : renderingProcess.error ? (
            <NotAvailable />
          ) : (
            <Table
              tableParams
              columns={CompanyColumns}
              data={reasons}
              actions={actions}
              setShowModal={setShowModal}
              loading={loadingsGetReason}
              tableOptions={tableOptions}
              paginations={paginationsOptions}
              setTableOptions={setTableOptions}
              heightTable={'64vh'}
              cellHeight={68}
              pathname={'/settings/reasons-for-defect'}
            ></Table>
          )}
        </PaddingAuto>

        {confirm && modalConfirm()}

        {showModal && (
          <Modal wrapper="w-auto max-w-md mx-auto" onClose={handleCloseModal}>
            <div className="flex items-center justify-between p-3 border-b border-solid rounded-t border-slate-200">
              <div className="text-xl text-primary font-semibold">
                {currentReason ? 'Edit Reason' : 'Create Reason'}
              </div>
            </div>
            {/*body*/}
            <div className="relative flex flex-auto p-6">
              <div className="w-full max-w-lg">
                <Formik
                  initialValues={initialValues}
                  onSubmit={currentReason ? onSubmitUpdate : onSubmitAdd}
                  validationSchema={validationSchema}
                >
                  {() => {
                    return (
                      <Form className="w-full max-w-lg">
                        <div className="flex flex-wrap mb-6 -mx-3">
                          <div className="w-full px-3 mb-3">
                            <label
                              className="block mb-2 text-xs font-bold tracking-wide text-primary uppercase"
                              htmlFor="grid-first-name"
                            >
                              Reason
                            </label>
                            <Field
                              type="text"
                              id="reason"
                              placeholder="Reason"
                              className="block w-full px-4 py-3 leading-tight text-primary bg-gray-50 border rounded appearance-none focus:outline-none focus:bg-white"
                              name="reason"
                            />
                            <ErrorMessage name="reason">
                              {(errMsg) => (
                                <div className="text-red-500 mt-1 flex items-center">
                                  <ExclamationCircleOutlined />
                                  <span className="ml-1">{errMsg}</span>
                                </div>
                              )}
                            </ErrorMessage>
                          </div>
                        </div>
                        <div className="flex items-center justify-between p-6 border-t border-solid rounded-b border-slate-200">
                          <button
                            className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear bg-gray-500 rounded shadow outline-none active:bg-gray-600 hover:shadow-lg focus:outline-none"
                            type="button"
                            onClick={handleCloseModal}
                          >
                            Close
                          </button>

                          <div className="flex gap-4 items-center">
                            <button
                              className="flex items-center px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear bg-red-500 rounded shadow outline-none active:bg-red-600 hover:shadow-lg focus:outline-none"
                              type="submit"
                            >
                              <span className="mr-2">Submit</span> <SaveOutlined />
                            </button>
                          </div>
                        </div>
                      </Form>
                    )
                  }}
                </Formik>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </React.Fragment>
  )
}

export default ReasonsForDefect
