import React, { useRef, useState, useEffect, useMemo } from 'react'

const DrawCanvasOnImage = ({ imageUrl, rectangles }) => {
  const canvasRef = useRef(null)
  const [modifiedImageUrl, setModifiedImageUrl] = useState(null)

  const listDefectAvailable = useMemo(() => [...(rectangles || [])].filter((r) => !r.fillColor), [rectangles])
  useEffect(() => {
    const canvas = canvasRef.current
    const context = canvas.getContext('2d')

    const loadImageAndDrawRectangles = async () => {
      try {
        if (listDefectAvailable.length === 0) {
          setModifiedImageUrl(imageUrl)
          return true
        } else {
          const image = new Image()
          image.crossOrigin = 'anonymous' // Enable cross-origin resource sharing if needed
          // Load the image from the provided URL
          await new Promise((resolve, reject) => {
            image.onload = resolve
            image.onerror = reject
            image.src = imageUrl + '?stopGivingMeHeadaches=true'
          })
          // Draw the image on the canvas
          canvas.width = image.width
          canvas.height = image.height

          context.drawImage(image, 0, 0)
          // Draw multiple listDefectAvailable on the image
          listDefectAvailable.forEach((rectangle) => {
            let startX = rectangle.x * canvas.width
            let startY = rectangle.y * canvas.height
            let widthAnno = rectangle.w * canvas.width
            let heightAnno = rectangle.h * canvas.height
            context.strokeStyle = rectangle.color || 'red'
            context.lineWidth = 4
            context.strokeRect(startX, startY, widthAnno, heightAnno)
          })
          // Convert the canvas content to a data URL
          const modifiedDataUrl = canvas.toDataURL('image/jpeg')
          setModifiedImageUrl(modifiedDataUrl)
        }
      } catch (error) {
        console.error('Error loading image:', error)
      }
    }

    loadImageAndDrawRectangles()
  }, [imageUrl, listDefectAvailable])

  return (
    <div className="w-full h-[480px] ">
      <canvas ref={canvasRef} style={{ display: 'none' }} />
      <img
        src={modifiedImageUrl}
        alt="Modified with Rectangles"
        className="w-full h-[480px] flex rounded shadow-avatar"
      />
    </div>
  )
}

export default DrawCanvasOnImage
