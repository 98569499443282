import { isEmptyObject } from '../utils/common'
import apiInstance from './configApis'

const getInpsectionLog = async (options) => {
  const url = '?' + new URLSearchParams(options).toString()
  try {
    const data = await apiInstance.get(`/audit-inspection${url}`)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const getCompanyLog = async (options) => {
  const url = '?' + new URLSearchParams(options).toString()
  try {
    const data = await apiInstance.get(`/audit-company${url}`)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}
const getUserLog = async (options) => {
  const url = '?' + new URLSearchParams(options).toString()
  try {
    const data = await apiInstance.get(`/audit-user${url}`)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const getElevationLog = async (id, options) => {
  const url = '?' + new URLSearchParams(options).toString()
  try {
    const data = await apiInstance.get(`/audit-elevation/${id}${url}`)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}
const getElevationLogAll = async (options) => {
  const url = '?' + new URLSearchParams(options).toString()
  try {
    const data = await apiInstance.get(`/audit-elevation${url}`)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const getImageLogAll = async (options) => {
  const url = '?' + new URLSearchParams(options).toString()
  try {
    const data = await apiInstance.get(`/audit-images${url}`)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const getImageCloseRangeLog = async (id, options) => {
  const url = '?' + new URLSearchParams(options).toString()
  try {
    const data = await apiInstance.get(`/audit-annotation-close-range/${id}${url}`)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const getImageManualLog = async (id, options) => {
  const url = '?' + new URLSearchParams(options).toString()
  try {
    const data = await apiInstance.get(`/audit-annotation-manual/${id}${url}`)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const getImageDroneLog = async (id, options) => {
  const url = '?' + new URLSearchParams(options).toString()
  try {
    const data = await apiInstance.get(`/audit-annotation-drone/${id}${url}`)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}
const getTrackUserLog = async (options) => {
  const url = '?' + new URLSearchParams(options).toString()
  try {
    const data = await apiInstance.get(`/track-user${url}`)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}
const getDownloadCompany = async (options) => {
  const url = isEmptyObject(options) ? '' : '?' + new URLSearchParams(options).toString()
  try {
    const data = await apiInstance.get(`/audit-company/export${url}`)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}
const getDownloadInspection = async (options) => {
  const url = isEmptyObject(options) ? '' : '?' + new URLSearchParams(options).toString()
  try {
    const data = await apiInstance.get(`/audit-inspection/export${url}`)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const getDownloadUser = async (options) => {
  const url = isEmptyObject(options) ? '' : '?' + new URLSearchParams(options).toString()
  try {
    const data = await apiInstance.get(`/audit-user/export${url}`)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const getDownloadElevation = async (options) => {
  const url = isEmptyObject(options) ? '' : '?' + new URLSearchParams(options).toString()
  try {
    const data = await apiInstance.get(`/audit-elevation/export${url}`)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}
const getDownloadImage = async (options) => {
  const url = isEmptyObject(options) ? '' : '?' + new URLSearchParams(options).toString()
  try {
    const data = await apiInstance.get(`/audit-images/export${url}`)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}
const getDownloadTrackUser = async (options) => {
  const url = isEmptyObject(options) ? '' : '?' + new URLSearchParams(options).toString()
  try {
    const data = await apiInstance.get(`/track-user/export${url}`)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const logApis = {
  getInpsectionLog,
  getElevationLog,
  getElevationLogAll,
  getImageLogAll,
  getImageDroneLog,
  getImageManualLog,
  getImageCloseRangeLog,
  getCompanyLog,
  getUserLog,
  getTrackUserLog,
  getDownloadCompany,
  getDownloadInspection,
  getDownloadUser,
  getDownloadElevation,
  getDownloadImage,
  getDownloadTrackUser
}
export default logApis
