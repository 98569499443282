import React, { useEffect, useState } from 'react'

import * as Yup from 'yup'
import YupPassword from 'yup-password'

// hocs
import useAuth from '../../../../hooks/useAuth'

// actions

// commons
import Navigate from '../../../Common/Navigate'
import { makeRoles } from '../../../../utils/common'

// assets

// constants
import { claritasAdmin } from '../../../../constant/auth'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'
import PaddingAuto from '../../../Layout/PaddingAuto'

YupPassword(Yup)

const tabUser = [
  { label: 'Inspection', value: 1, path: '/inspection' },
  { label: 'User', value: 2, path: '/user' },
  { label: 'Company', value: 3, path: '/company' },
  { label: 'Elevation', value: 4, path: '/elevation' },
  { label: 'Image', value: 5, path: '/image' },
  { label: 'Track User', value: 6, path: '/track-user' }
]

const LogManagement = () => {
  const { user } = useAuth()
  const { role } = user?.user || {}

  const navigate = useNavigate()
  const location = useLocation()
  const [currentTab, setCurrentTab] = useState(1)

  useEffect(() => {
    if ([...claritasAdmin].includes(role)) {
      setCurrentTab(1)
    } else {
      setCurrentTab(2)
    }
  }, [])

  return (
    <div>
      <PaddingAuto>
        <>
          {[...makeRoles([1, 2])].includes(role) && (
            <div>
              <div className="border-0 border-b border-solid border-[#ebedec] pb-4 mt-2">
                <ul className="flex justify-start">
                  {tabUser.map((tab) => (
                    <li
                      key={tab.value}
                      onClick={() => {
                        navigate(`/logs${tab.path}`)
                        setCurrentTab(tab.value)
                      }}
                      className={`w-28 cursor-pointer p-2 first:rounded-l-3xl font-semibold last:rounded-r-3xl text-center last:border-l last:border-white last:border-solid ${
                        location?.pathname === `/logs${tab.path}` ? 'bg-blue-500 text-white' : 'bg-gray-50 text-primary'
                      }`}
                    >
                      {tab.label}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
          <Outlet />
        </>
      </PaddingAuto>
    </div>
  )
}

export default LogManagement
