import { createSlice } from '@reduxjs/toolkit'
import { mapElevationDetailPayload } from '../../utils/sliceUtils'
import {
  createElevation,
  editElevation,
  getElevationDetails,
  deleteElevation,
  getElevationsBuilding,
  createAnnotationDroneElevation,
  createAnnotationCloseRangeElevation,
  createCloseRangeInThumbnail,
  createAnnotationUserElevation,
  updateCheckedDroneAnnotationElevation,
  updateCheckedUserAnnotationElevation,
  updateElevationDetail,
  updateStatusCloseRangeDrone,
  updateStatusCloseRangeManual,
  deleteCloseRangeElevation,
  setElevationStatus,
  maskingElevation,
  updateElevationAvatar,
  updateElevationThumbnail,
  getFrameThermal,
  resetElevation
} from '../thunks/elevationThunk'
import elevationTypes from '../../constant/store/elevationTypes'

const elevationSlice = createSlice({
  name: 'building',
  initialState: {
    elevationDetails: {},
    fetchLoading: false,
    modifyLoading: false,
    fetchError: '',
    modifyError: '',
    loadings: {},
    errors: {},
    contents: {},
    paginations: {}
  },
  extraReducers: {
    // ACTION TYPE: Get elevation details
    [getElevationDetails.pending]: (state) => {
      state.loadings[elevationTypes.GET_ELEVATION_DETAILS] = true
    },
    [getElevationDetails.fulfilled]: (state, { payload }) => {
      const newPayload = mapElevationDetailPayload(payload)
      state.loadings[elevationTypes.GET_ELEVATION_DETAILS] = false
      state.contents[elevationTypes.GET_ELEVATION_DETAILS] = newPayload
      state.errors[elevationTypes.GET_ELEVATION_DETAILS] = ''
    },
    [getElevationDetails.rejected]: (state, { payload }) => {
      state.loadings[elevationTypes.GET_ELEVATION_DETAILS] = false
      state.errors[elevationTypes.GET_ELEVATION_DETAILS] = payload.message
    },
    // ACTION TYPE: Get Frame Thermal
    [getFrameThermal.pending]: (state) => {
      state.loadings[elevationTypes.GET_FRAME_THERMAL] = true
    },
    [getFrameThermal.fulfilled]: (state, { payload }) => {
      state.loadings[elevationTypes.GET_FRAME_THERMAL] = false
      state.contents[elevationTypes.GET_FRAME_THERMAL] = payload
      state.errors[elevationTypes.GET_FRAME_THERMAL] = ''
    },
    [getFrameThermal.rejected]: (state, { payload }) => {
      state.loadings[elevationTypes.GET_FRAME_THERMAL] = false
      state.errors[elevationTypes.GET_FRAME_THERMAL] = payload.message
    },
    //ACTION TYPE: Create new elevation
    [createElevation.pending]: (state) => {
      state.loadings[elevationTypes.CREATE_ELEVATION] = true
    },
    [createElevation.fulfilled]: (state, { payload }) => {
      state.loadings[elevationTypes.CREATE_ELEVATION] = false
      state.contents[elevationTypes.CREATE_ELEVATION] = payload
      state.errors[elevationTypes.CREATE_ELEVATION] = ''
    },
    [createElevation.rejected]: (state, { payload }) => {
      state.loadings[elevationTypes.CREATE_ELEVATION] = false
      state.errors[elevationTypes.CREATE_ELEVATION] = payload.message
    },

    //ACTION TYPE: Edit elevation
    [editElevation.pending]: (state) => {
      state.loadings[elevationTypes.UPDATE_ELEVATION] = true
    },
    [editElevation.fulfilled]: (state, { payload }) => {
      const newPayload = mapElevationDetailPayload(payload)
      state.loadings[elevationTypes.UPDATE_ELEVATION] = false
      state.contents[elevationTypes.GET_ELEVATION_DETAILS] = newPayload
      state.errors[elevationTypes.UPDATE_ELEVATION] = ''
    },
    [editElevation.rejected]: (state, { payload }) => {
      state.loadings[elevationTypes.UPDATE_ELEVATION] = false
      state.errors[elevationTypes.UPDATE_ELEVATION] = payload.message
    },
    // ACTION TYPE: Delete elevation
    [deleteElevation.pending]: (state) => {
      state.loadings[elevationTypes.DELETE_ELEVATION] = true
      state.errors[elevationTypes.DELETE_ELEVATION] = ''
    },
    [deleteElevation.fulfilled]: (state, { payload }) => {
      state.loadings[elevationTypes.DELETE_ELEVATION] = false
      state.errors[elevationTypes.DELETE_ELEVATION] = ''
    },
    [deleteElevation.rejected]: (state, { payload }) => {
      state.loadings[elevationTypes.DELETE_ELEVATION] = false
      state.errors[elevationTypes.DELETE_ELEVATION] = payload.message
    },
    // ACTION TYPE: get elevation building
    [getElevationsBuilding.pending]: (state) => {
      state.loadings[elevationTypes.GET_ELEVATIONS_BUILDING] = true
      state.errors[elevationTypes.GET_ELEVATIONS_BUILDING] = ''
    },
    [getElevationsBuilding.fulfilled]: (state, { payload }) => {
      state.loadings[elevationTypes.GET_ELEVATIONS_BUILDING] = false
      state.contents[elevationTypes.GET_ELEVATIONS_BUILDING] = payload.elevationsData
      state.paginations[elevationTypes.GET_ELEVATIONS_BUILDING] = payload.pagination
      state.errors[elevationTypes.GET_ELEVATIONS_BUILDING] = ''
    },
    [getElevationsBuilding.rejected]: (state, { payload }) => {
      state.loadings[elevationTypes.GET_ELEVATIONS_BUILDING] = false
      state.errors[elevationTypes.GET_ELEVATIONS_BUILDING] = payload.message
    },
    // ACTION TYPE: create Annotation Drone Elevation
    [createAnnotationDroneElevation.pending]: (state) => {
      state.loadings[elevationTypes.CREATE_ANNOTATION_DRONE_ELEVATION] = true
      state.errors[elevationTypes.CREATE_ANNOTATION_DRONE_ELEVATION] = ''
    },
    [createAnnotationDroneElevation.fulfilled]: (state, { payload }) => {
      const newPayload = mapElevationDetailPayload(payload)
      state.loadings[elevationTypes.CREATE_ANNOTATION_DRONE_ELEVATION] = false
      state.contents[elevationTypes.GET_ELEVATION_DETAILS] = newPayload
      state.errors[elevationTypes.CREATE_ANNOTATION_DRONE_ELEVATION] = ''
    },
    [createAnnotationDroneElevation.rejected]: (state, { payload }) => {
      state.loadings[elevationTypes.CREATE_ANNOTATION_DRONE_ELEVATION] = false
      state.errors[elevationTypes.CREATE_ANNOTATION_DRONE_ELEVATION] = payload?.message
    },

    //  update Status Close Range Drone
    [updateStatusCloseRangeDrone.pending]: (state) => {
      state.loadings[elevationTypes.UPDATE_STATUS_CLOSE_RANGE_DRONE] = true
      state.errors[elevationTypes.UPDATE_STATUS_CLOSE_RANGE_DRONE] = ''
    },
    [updateStatusCloseRangeDrone.fulfilled]: (state, { payload }) => {
      const newPayload = mapElevationDetailPayload(payload.resp)
      state.loadings[elevationTypes.UPDATE_STATUS_CLOSE_RANGE_DRONE] = false
      state.contents[elevationTypes.GET_ELEVATION_DETAILS] = newPayload
      state.errors[elevationTypes.UPDATE_STATUS_CLOSE_RANGE_DRONE] = ''
    },
    [updateStatusCloseRangeDrone.rejected]: (state, { payload }) => {
      state.loadings[elevationTypes.UPDATE_STATUS_CLOSE_RANGE_DRONE] = false
      state.errors[elevationTypes.UPDATE_STATUS_CLOSE_RANGE_DRONE] = payload.message
    },

    //  update Status Close Range Manual
    [updateStatusCloseRangeManual.pending]: (state) => {
      state.loadings[elevationTypes.UPDATE_STATUS_CLOSE_RANGE_MANUAL] = true
      state.errors[elevationTypes.UPDATE_STATUS_CLOSE_RANGE_MANUAL] = ''
    },
    [updateStatusCloseRangeManual.fulfilled]: (state, { payload }) => {
      const newPayload = mapElevationDetailPayload(payload.resp)
      state.loadings[elevationTypes.UPDATE_STATUS_CLOSE_RANGE_MANUAL] = false
      state.contents[elevationTypes.GET_ELEVATION_DETAILS] = newPayload
      state.errors[elevationTypes.UPDATE_STATUS_CLOSE_RANGE_MANUAL] = ''
    },
    [updateStatusCloseRangeManual.rejected]: (state, { payload }) => {
      state.loadings[elevationTypes.UPDATE_STATUS_CLOSE_RANGE_MANUAL] = false
      state.errors[elevationTypes.UPDATE_STATUS_CLOSE_RANGE_MANUAL] = payload.message
    },

    // create Annotation Close Range Elevation
    [createAnnotationCloseRangeElevation.pending]: (state) => {
      state.loadings[elevationTypes.CREATE_ANNOTATION_CLOSE_RANGE_ELEVATION] = true
      state.errors[elevationTypes.CREATE_ANNOTATION_CLOSE_RANGE_ELEVATION] = ''
    },
    [createAnnotationCloseRangeElevation.fulfilled]: (state, { payload }) => {
      const newPayload = mapElevationDetailPayload(payload)
      state.loadings[elevationTypes.CREATE_ANNOTATION_CLOSE_RANGE_ELEVATION] = false
      state.contents[elevationTypes.GET_ELEVATION_DETAILS] = newPayload
      state.errors[elevationTypes.CREATE_ANNOTATION_CLOSE_RANGE_ELEVATION] = ''
    },
    [createAnnotationCloseRangeElevation.rejected]: (state, { payload }) => {
      state.loadings[elevationTypes.CREATE_ANNOTATION_CLOSE_RANGE_ELEVATION] = false
      state.errors[elevationTypes.CREATE_ANNOTATION_CLOSE_RANGE_ELEVATION] = payload.message
    },
    // create Annotation Close Range Elevation
    [createCloseRangeInThumbnail.pending]: (state) => {
      state.loadings[elevationTypes.CREATE_CLOSE_RANGE_IN_THUMBNAIL] = true
      state.errors[elevationTypes.CREATE_CLOSE_RANGE_IN_THUMBNAIL] = ''
    },
    [createCloseRangeInThumbnail.fulfilled]: (state, { payload }) => {
      const newPayload = mapElevationDetailPayload(payload)
      state.loadings[elevationTypes.CREATE_CLOSE_RANGE_IN_THUMBNAIL] = false
      state.contents[elevationTypes.GET_ELEVATION_DETAILS] = newPayload
      state.errors[elevationTypes.CREATE_CLOSE_RANGE_IN_THUMBNAIL] = ''
    },
    [createCloseRangeInThumbnail.rejected]: (state, { payload }) => {
      state.loadings[elevationTypes.CREATE_CLOSE_RANGE_IN_THUMBNAIL] = false
      state.errors[elevationTypes.CREATE_CLOSE_RANGE_IN_THUMBNAIL] = payload.message
    },
    // ACTION TYPE: create Annotation User Elevation
    [createAnnotationUserElevation.pending]: (state) => {
      state.loadings[elevationTypes.CREATE_ANNOTATION_USER_ELEVATION] = true
      state.errors[elevationTypes.CREATE_ANNOTATION_USER_ELEVATION] = ''
    },
    [createAnnotationUserElevation.fulfilled]: (state, { payload }) => {
      const newPayload = mapElevationDetailPayload(payload)
      state.loadings[elevationTypes.CREATE_ANNOTATION_USER_ELEVATION] = false
      state.contents[elevationTypes.GET_ELEVATION_DETAILS] = newPayload
      state.errors[elevationTypes.CREATE_ANNOTATION_USER_ELEVATION] = ''
    },
    [createAnnotationUserElevation.rejected]: (state, { payload }) => {
      state.loadings[elevationTypes.CREATE_ANNOTATION_USER_ELEVATION] = false
      state.errors[elevationTypes.CREATE_ANNOTATION_USER_ELEVATION] = payload.message
    },
    // ACTION TYPE: update Checked Drone Annotation Elevation
    [updateCheckedDroneAnnotationElevation.pending]: (state) => {
      state.loadings[elevationTypes.UPDATE_CHECKED_DRONE_ANNOTATION_ELEVATION] = true
      state.errors[elevationTypes.UPDATE_CHECKED_DRONE_ANNOTATION_ELEVATION] = ''
    },
    [updateCheckedDroneAnnotationElevation.fulfilled]: (state, { payload }) => {
      state.loadings[elevationTypes.UPDATE_CHECKED_DRONE_ANNOTATION_ELEVATION] = false
      // state.contents[elevationTypes.UPDATE_CHECKED_DRONE_ANNOTATION_ELEVATION] = payload
      state.errors[elevationTypes.UPDATE_CHECKED_DRONE_ANNOTATION_ELEVATION] = ''
    },
    [updateCheckedDroneAnnotationElevation.rejected]: (state, { payload }) => {
      state.loadings[elevationTypes.UPDATE_CHECKED_DRONE_ANNOTATION_ELEVATION] = false
      state.errors[elevationTypes.UPDATE_CHECKED_DRONE_ANNOTATION_ELEVATION] = payload.message
    },
    // ACTION TYPE: update Checked User Annotation Elevation
    [updateCheckedUserAnnotationElevation.pending]: (state) => {
      state.loadings[elevationTypes.UPDATE_CHECKED_USER_ANNOTATION_ELEVATION] = true
      state.errors[elevationTypes.UPDATE_CHECKED_USER_ANNOTATION_ELEVATION] = ''
    },
    [updateCheckedUserAnnotationElevation.fulfilled]: (state, { payload }) => {
      state.loadings[elevationTypes.UPDATE_CHECKED_USER_ANNOTATION_ELEVATION] = false
      // state.contents[elevationTypes.UPDATE_CHECKED_USER_ANNOTATION_ELEVATION] = payload
      state.errors[elevationTypes.UPDATE_CHECKED_USER_ANNOTATION_ELEVATION] = ''
    },
    [updateCheckedUserAnnotationElevation.rejected]: (state, { payload }) => {
      state.loadings[elevationTypes.UPDATE_CHECKED_USER_ANNOTATION_ELEVATION] = false
      state.errors[elevationTypes.UPDATE_CHECKED_USER_ANNOTATION_ELEVATION] = payload.message
    },
    //ACTION TYPE: Masking elevation
    [maskingElevation.pending]: (state) => {
      state.loadings[elevationTypes.AI_MASKING_ELEVATION] = true
    },
    [maskingElevation.fulfilled]: (state, { payload }) => {
      const newPayload = mapElevationDetailPayload(payload)
      state.loadings[elevationTypes.AI_MASKING_ELEVATION] = false
      state.contents[elevationTypes.GET_ELEVATION_DETAILS] = newPayload
      state.errors[elevationTypes.AI_MASKING_ELEVATION] = ''
    },
    [maskingElevation.rejected]: (state, { payload }) => {
      state.loadings[elevationTypes.AI_MASKING_ELEVATION] = false
      state.errors[elevationTypes.AI_MASKING_ELEVATION] = payload.message
    },

    //BUILDING ACTION TYPE: Update elevation detail
    [updateElevationDetail.pending]: (state) => {
      state.loadings[elevationTypes.UPDATE_ELEVATION_DETAIL] = true
    },
    [updateElevationDetail.fulfilled]: (state, { payload }) => {
      state.loadings[elevationTypes.UPDATE_ELEVATION_DETAIL] = false
      const index = state.contents[elevationTypes.GET_ELEVATIONS_BUILDING].findIndex(
        (elevation) => elevation.id === payload.elevation.id
      )
      state.contents[elevationTypes.GET_ELEVATIONS_BUILDING].splice(index, 1, { ...payload.elevation })
      state.errors[elevationTypes.UPDATE_ELEVATION_DETAIL] = ''
    },
    [updateElevationDetail.rejected]: (state, { payload }) => {
      state.loadings[elevationTypes.UPDATE_ELEVATION_DETAIL] = false
      state.errors[elevationTypes.UPDATE_ELEVATION_DETAIL] = payload.message
    },
    //BUILDING ACTION TYPE: Update elevation thumbnail
    [updateElevationThumbnail.pending]: (state) => {
      state.loadings[elevationTypes.UPDATE_ELEVATION_THUMBNAIL] = true
    },
    [updateElevationThumbnail.fulfilled]: (state, { payload }) => {
      state.loadings[elevationTypes.UPDATE_ELEVATION_THUMBNAIL] = false
      state.contents[elevationTypes.GET_ELEVATION_DETAILS].elevation.data_thumbnail = payload.elevation.data_thumbnail
      state.errors[elevationTypes.UPDATE_ELEVATION_THUMBNAIL] = ''
    },
    [updateElevationThumbnail.rejected]: (state, { payload }) => {
      state.loadings[elevationTypes.UPDATE_ELEVATION_THUMBNAIL] = false
      state.errors[elevationTypes.UPDATE_ELEVATION_THUMBNAIL] = payload.message
    },
    //BUILDING ACTION TYPE: Update elevation avatar
    [updateElevationAvatar.pending]: (state) => {
      state.loadings[elevationTypes.UPDATE_ELEVATION_AVATAR] = true
    },
    [updateElevationAvatar.fulfilled]: (state, { payload }) => {
      state.loadings[elevationTypes.UPDATE_ELEVATION_AVATAR] = false
      state.contents[elevationTypes.GET_ELEVATIONS_BUILDING].elevation.data_thumbnail = payload.elevation.data_thumbnail
      state.errors[elevationTypes.UPDATE_ELEVATION_AVATAR] = ''
    },
    [updateElevationAvatar.rejected]: (state, { payload }) => {
      state.loadings[elevationTypes.UPDATE_ELEVATION_AVATAR] = false
      state.errors[elevationTypes.UPDATE_ELEVATION_AVATAR] = payload.message
    },

    // ACTION TYPE: Delete close range elevation
    [deleteCloseRangeElevation.pending]: (state) => {
      state.loadings[elevationTypes.DELETE_CLOSE_RANGE_ELEVATION] = true
      state.errors[elevationTypes.DELETE_CLOSE_RANGE_ELEVATION] = ''
    },
    [deleteCloseRangeElevation.fulfilled]: (state, { payload }) => {
      const newPayload = mapElevationDetailPayload(payload)
      state.loadings[elevationTypes.DELETE_CLOSE_RANGE_ELEVATION] = false
      state.contents[elevationTypes.GET_ELEVATION_DETAILS] = newPayload
      state.errors[elevationTypes.DELETE_CLOSE_RANGE_ELEVATION] = ''
    },
    [deleteCloseRangeElevation.rejected]: (state, { payload }) => {
      state.loadings[elevationTypes.DELETE_CLOSE_RANGE_ELEVATION] = false
      state.errors[elevationTypes.DELETE_CLOSE_RANGE_ELEVATION] = payload.message
    },
    // ACTION TYPE: set elevation status
    [setElevationStatus.pending]: (state) => {
      state.loadings[elevationTypes.SET_ELEVATION_STATUS] = true
      state.errors[elevationTypes.SET_ELEVATION_STATUS] = ''
    },
    [setElevationStatus.fulfilled]: (state, { payload }) => {
      const newPayload = mapElevationDetailPayload(payload)
      state.loadings[elevationTypes.SET_ELEVATION_STATUS] = false
      state.contents[elevationTypes.GET_ELEVATION_DETAILS] = newPayload
      state.errors[elevationTypes.SET_ELEVATION_STATUS] = ''
    },
    [setElevationStatus.rejected]: (state, { payload }) => {
      state.loadings[elevationTypes.SET_ELEVATION_STATUS] = false
      state.errors[elevationTypes.SET_ELEVATION_STATUS] = payload.message
    },
    // ACTION TYPE: reset elevation slice
    [resetElevation.fulfilled]: (state, { payload }) => {
      state.contents[payload] = null
    }
  }
})
export default elevationSlice.reducer
