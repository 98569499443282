import React from 'react'
import { useNavigate } from 'react-router-dom'
import PermissionData from '../../assets/images/svg/permissions_gray_wash.svg'

function NotAvailable(props) {
  const navigate = useNavigate()
  const goBack = () => navigate(-1)

  return (
    <div
      className="flex flex-row items-center justify-center my-2 min-h-[60vh]"
      style={{
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px'
      }}
    >
      <div className="flex flex-col max-w-[330px] items-center">
        <img alt="PermissionData" src={PermissionData} className="w-20 rounded h-14" />
        <div className="text-center mt-1 font-bold">
          {props.content ? props.content : 'You are not authorised to view this content'}
        </div>
        <div className="bg-blue-600 px-2 py-2 rounded-md text-center text-[#fff] mt-4 cursor-pointer" onClick={goBack}>
          Go back
        </div>
      </div>
    </div>
  )
}

export default NotAvailable
