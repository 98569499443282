import apiInstance from './configApis'

const getBuildings = async (options) => {
  const url = '?' + new URLSearchParams(options).toString()

  try {
    const data = await apiInstance.get(`building/all-buildings${url}`)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const getBuildingsActive = async () => {
  try {
    const data = await apiInstance.get(`building/all-active-buildings`)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const getCompanyBuildings = async (id, options) => {
  const url = '?' + new URLSearchParams(options).toString()
  try {
    const data = await apiInstance.get(`company/${id}${url}`)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}
const buildingDetails = async (id) => {
  try {
    const data = await apiInstance.get(`building/building-detail/${id}`)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const getRoleBuilding = async () => {
  try {
    const data = await apiInstance.get(`building-type`)

    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const createBuilding = async ({ name, address, thumbnail, company_id, type_id }) => {
  try {
    const data = await apiInstance.post(`building/create-building`, {
      name,
      address,
      thumbnail,
      type_id,
      company_id
    })
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const updateBuilding = async ({ name, address, thumbnail, company_id, type, status, id, cp_id }) => {
  try {
    const data = await apiInstance.patch(`building/update-building`, {
      name,
      address,
      thumbnail,
      company_id,
      type,
      status,
      id,
      cp_id
    })
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const deleteBuilding = async (id) => {
  try {
    const data = await apiInstance.delete(`building/delete-building/${id}`)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const changeStatusBuilding = async ({ id, status }) => {
  try {
    const data = await apiInstance.patch(`building/change-status`, { id, status })
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const revertDeleteBuilding = async (id) => {
  try {
    const data = await apiInstance.patch(`building/restore/${id}`)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const adminApi = {
  createBuilding,
  updateBuilding,
  deleteBuilding,
  getCompanyBuildings,
  buildingDetails,
  getBuildings,
  getRoleBuilding,
  getBuildingsActive,
  changeStatusBuilding,
  revertDeleteBuilding
}
export default adminApi
