import React, { useRef, useCallback } from 'react'
import {
  CopyOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  SaveOutlined,
  ExportOutlined,
  UploadOutlined,
  DownloadOutlined,
  RobotOutlined
} from '@ant-design/icons'
import { Progress } from 'antd'
import { useSelector } from 'react-redux'
import { onConfirm } from 'react-confirm-pro'

import Direction_1 from '../../../assets/images/direction1.png'
import Direction_2 from '../../../assets/images/direction2.png'
import Direction_3 from '../../../assets/images/direction3.png'
import Direction_4 from '../../../assets/images/direction4.png'
import { BsCameraFill } from 'react-icons/bs'
import { MdOutlineSquare, MdOutlinePentagon, MdOutlineCircle } from 'react-icons/md'
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai'
import useAuth from '../../../hooks/useAuth'
import { makeRoles } from '../../../utils/common'

import inspectionTypes from '../../../constant/store/inspectionTypes'
import Switcher from '../../Common/switch/Switcher'
import { color } from '../../../constant/styled'
import Cropper2 from '../../Common/cropper2/Cropper'

function ElevationInfo({
  isLockReport,
  elevation,
  direction = 0,
  thumbnail,
  elevationRow,
  columns,
  rows,
  manualRow,
  setManualRow,
  openTab,
  onSubmitSave,
  onDelete,
  onExportTxT,
  onExportTxTnImage,
  count,
  totalNewItem,
  isShowProcess,
  onLoad,
  thumbnailRef,
  thumbnailH,
  thumbnailW,
  listImgUser,
  handleClickOnMap,
  listImageCloseRangeThumbnail,
  listDefectCloseRangeThumbnail,
  handleCompositeImage,
  lockSaveBtn,
  setLockSaveBtn,
  composeDrone,
  composeManual,
  switchVisual,
  buildingStatus,
  switchCloseRange,
  onChangeElevationStatus,
  handleMaskingElevation,
  handleSetAvatar,
  fakeDroneCanvas
}) {
  const { user } = useAuth()
  const { role } = user?.user || {}
  const { contents } = useSelector((state) => state.building)
  const buildingsDetails = contents[inspectionTypes.GET_BUILDINGS_DETAILS]
  const cropperRef = useRef()

  const handleOpenCropper = useCallback(() => {
    cropperRef?.current?.openCropModal()
  }, [cropperRef])

  return (
    <div
      className="w-full overflow-auto bg-white border border-gray-300 rounded-xl shadow-lg bg-opacity-90
    bg-clip-padding flex flex-col mt-4"
    >
      <div className="flex flex-row text-opacity  font-bold border-b">
        <div className="w-1/3 border-r">
          <div className="flex flex-col px-4 py-4 w-full gap-6">
            <p
              className="text-lg"
              style={{
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '24px'
              }}
            >
              Building: <span className="text-center rounded-full w-11 h-11">{buildingsDetails?.building?.name}</span>
            </p>
            <p
              className="text-lg"
              style={{
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '24px'
              }}
            >
              Elevation: <span className="text-center rounded-full w-11 h-11">{elevation.name}</span>
            </p>
            <div
              className="flex flex-row relative items-center justify-start w-max"
              style={{
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '24px'
              }}
            >
              <p className="mr-2 ">Drone Direction (hover):</p>
              <img
                alt="Direction"
                src={
                  parseInt(direction) === 0
                    ? Direction_1
                    : parseInt(direction) === 1
                    ? Direction_2
                    : parseInt(direction) === 2
                    ? Direction_3
                    : Direction_4
                }
                className="absolute -right-24 w-20 bg-white transition duration-500 transform rounded h-14 hover:scale-300"
              />
            </div>
            <div
              className="flex gap-4 pt-2"
              style={{
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '21px'
              }}
            >
              <div className="flex">
                <p
                  className="flex items-center w-12 text-lg"
                  style={{
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '24px'
                  }}
                >
                  Row :
                </p>
                {openTab === 2 && columns > 0 ? (
                  elevationRow ? (
                    <p className="inline-block w-28 h-10 rounded-lg border text-center leading-10 font-bold text-primary">
                      {elevationRow}
                    </p>
                  ) : (
                    <>
                      <div className="flex items-center justify-center rounded-xl p-2 border border-solid border-primary leading-3 w-28 h-10">
                        {[...makeRoles([1, 2, 5, 6, 7, 9, 10, 12, 13])].includes(role) && !switchVisual && (
                          <button
                            className="btn btn-outline-primary"
                            type="button"
                            disabled={manualRow === 0}
                            onClick={(e) => {
                              if (manualRow === 1) {
                                setManualRow(manualRow - 1)
                                setLockSaveBtn(true)
                              } else if (manualRow > 1) {
                                setManualRow(manualRow - 1)
                              }
                            }}
                          >
                            <AiOutlineMinusCircle
                              size={18}
                              style={{ color: `${manualRow === 0 ? color.grayLight : color.gray}` }}
                            />
                          </button>
                        )}

                        <input
                          type="text"
                          className="focus:outline-none w-8 mx-4 text-center"
                          value={manualRow}
                          readOnly
                        />
                        {[...makeRoles([1, 2, 5, 6, 7, 9, 10, 12, 13])].includes(role) && !switchVisual && (
                          <button
                            className="btn btn-outline-primary"
                            type="button"
                            onClick={(e) => {
                              setManualRow(manualRow + 1)
                              setLockSaveBtn(false)
                            }}
                            disabled={manualRow >= 10}
                          >
                            <AiOutlinePlusCircle
                              size={18}
                              style={{ color: `${manualRow >= 10 ? color.grayLight : color.gray}` }}
                            />
                          </button>
                        )}
                      </div>
                    </>
                  )
                ) : (
                  <p className="inline-block w-28 h-10 rounded-lg border text-center leading-10 font-bold text-primary">
                    {rows}
                  </p>
                )}
              </div>

              <div>
                <p
                  className="inline-block w-20 text-lg"
                  style={{
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '24px'
                  }}
                >
                  Columns :
                </p>
                <p className="inline-block w-28 h-10 rounded-lg border text-center leading-10 font-bold text-primary">
                  {columns}
                </p>
              </div>
            </div>
            <div>
              {[...makeRoles([1, 2, 6, 7, 10, 12, 13])].includes(role) && (
                <div className="flex flex-col gap-2  rounded-lg dark:bg-blue-200 dark:text-blue-800" role="alert">
                  <Switcher
                    checked={switchVisual}
                    className="block"
                    label="Lock visual inspection"
                    onChange={(value) => onChangeElevationStatus('visual', value)}
                  />
                  <Switcher
                    checked={switchCloseRange}
                    className="block"
                    label="Lock close-range inspection"
                    onChange={(value) => onChangeElevationStatus('close_range', value)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="w-1/3 border-r ">
          <div className="flex flex-col w-full px-4 py-4 gap-2">
            <div
              style={{
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '21px'
              }}
            >
              <p className="flex flex-row items-center ">
                <PlusCircleOutlined />
                <span className="ml-2">Add a dummy image after the chosen one</span>
              </p>
              <p className="flex flex-row items-center">
                <UploadOutlined />
                <span className="ml-2">Upload a new image after the chosen one</span>
              </p>
              <p className="flex flex-row items-center ">
                <CopyOutlined />
                <span className="ml-2">Replace by a new image</span>
              </p>
              <p className="flex flex-row items-center ">
                <DeleteOutlined /> <span className="ml-2">Delete this image</span>
              </p>
            </div>
            <div className="py-2" style={{ fontSize: '0.75rem' }}>
              <div className="flex gap-2">
                <div className="flex gap-1 min-w-[85px]">
                  <MdOutlineSquare className="text-2xl" />
                  <span>Cracks</span>
                </div>
                <div className="flex gap-1 min-w-[85px]">
                  <MdOutlinePentagon className="text-2xl" />
                  <span>Spalling</span>
                </div>
              </div>
              <div className="flex gap-2 py-1">
                <div className="flex  gap-1 min-w-[85px]">
                  <MdOutlineCircle className="text-2xl" />
                  <span>Corrosion</span>
                </div>
                <div className="flex gap-1 min-w-[85px]">
                  <MdOutlineSquare className="text-2xl transform rotate-45" />
                  <span>Others</span>
                </div>
              </div>
            </div>

            {!isShowProcess ? (
              <div className="flex flex-wrap">
                {[...makeRoles([1, 2, 5, 6, 7, 9, 10, 12, 13])].includes(role) &&
                  buildingsDetails?.building?.status !== 'Completed' && (
                    <button
                      className={`flex items-center justify-center mb-2 mr-2 px-4 h-10 my-2 font-bold ${
                        lockSaveBtn
                          ? 'text-opacity bg-transparent border border-gray-400 rounded pointer-events-none'
                          : 'text-blue-600 bg-transparent border border-blue-600 rounded hover:bg-blue-600 hover:text-white'
                      } `}
                      onClick={onSubmitSave}
                    >
                      <SaveOutlined /> <span className="ml-2">Save</span>
                    </button>
                  )}

                {[...makeRoles([1, 2, 5, 6, 7, 9, 10, 12, 13])].includes(role) &&
                  buildingsDetails?.building?.status !== 'Completed' && (
                    <>
                      <button
                        className="flex items-center justify-center mr-2 mb-2 px-4 h-10 my-2 font-bold text-red-600 bg-transparent border border-red-600 rounded hover:bg-red-600  hover:text-white"
                        onClick={() => {
                          onConfirm({
                            title: <h3 className="text-center">Are you sure you want to delete this elevation?</h3>,
                            description: <br />,
                            onSubmit: () => {
                              onDelete()
                            },
                            onCancel: () => {},
                            buttons: ({ onSubmit, onCancel }) => (
                              <div className="custom-buttons">
                                <button style={{ background: 'rgba(37, 99, 235)', color: 'white' }} onClick={onSubmit}>
                                  YES
                                </button>
                                <button style={{ background: 'rgba(220, 38, 38)', color: 'white' }} onClick={onCancel}>
                                  NO
                                </button>
                              </div>
                            )
                          })
                        }}
                      >
                        <DeleteOutlined /> <span className="ml-2">Delete</span>
                      </button>
                      <button
                        className={`flex items-center justify-center mb-2 mr-2 px-4 h-10 my-2 font-bold  bg-transparent border  rounded ${
                          switchVisual && (composeDrone || composeManual)
                            ? `text-green-600 border-green-600  hover:bg-green-600 hover:text-white cursor-pointer`
                            : 'text-opacity bg-transparent border border-gray-400 rounded pointer-events-none'
                        }`}
                        onClick={() => {
                          if (switchVisual && (composeDrone || composeManual)) {
                            handleCompositeImage()
                          }
                        }}
                      >
                        <p className="flex flex-row items-center ">
                          <DownloadOutlined /> <span className="ml-2">Composite</span>
                        </p>
                      </button>
                      <button
                        className={`flex items-center justify-center mb-2 mr-2 px-4 h-10 my-2 font-bold  bg-transparent border  rounded text-blue-500 border-blue-500  hover:bg-blue-500 hover:text-white cursor-pointer
                        `}
                        onClick={() => {
                          handleMaskingElevation()
                        }}
                      >
                        <p className="flex flex-row items-center ">
                          <RobotOutlined /> <span className="ml-2">Defect generate</span>
                        </p>
                      </button>
                    </>
                  )}

                {[...makeRoles([4])].includes(role) && (
                  <>
                    <button
                      className="flex items-center justify-center mr-2  px-4 h-10 my-2 font-bold bg-blue-600 text-white border border-white rounded hover:bg-white hover:text-blue-600 hover:border-blue-600"
                      onClick={(e) => {
                        onExportTxT()
                      }}
                    >
                      <ExportOutlined /> <span className="ml-2">YOLO</span>
                    </button>
                    <button
                      className="flex items-center justify-center mr-2  px-4 h-10 my-2 font-bold bg-blue-600 text-white border border-white rounded hover:bg-white hover:text-blue-600 hover:border-blue-600"
                      onClick={(e) => {
                        onExportTxTnImage()
                      }}
                    >
                      <CopyOutlined />
                      <span className="ml-2">Download image & txt</span>
                    </button>
                  </>
                )}
                {/* {[...makeRoles([1, 2])].includes(role) && (
                  <>
                    <button
                      className="flex items-center justify-center mr-2  px-4 h-10 my-2 font-bold bg-blue-600 text-white border border-white rounded hover:bg-white hover:text-blue-600 hover:border-blue-600"
                      onClick={fakeDroneCanvas}
                    >
                      <CopyOutlined />
                      <span className="ml-2">Fake data</span>
                    </button>
                  </>
                )} */}
              </div>
            ) : (
              <div className="pr-12 h-12 flex items-center mb-2">
                <div className="font-bold mr-4">
                  Updated {count}/{totalNewItem}
                </div>
                <Progress
                  type="circle"
                  percent={Math.ceil(((count * 100) / totalNewItem) * 10) / 10}
                  status="exception"
                  height={40}
                  width={40}
                />
              </div>
            )}
          </div>
        </div>

        <div className="flex w-1/3">
          <div className={'flex flex-row items-center relative w-full h-full py-2'}>
            <img
              src={thumbnail ? thumbnail : ''}
              alt="test"
              useMap="#workmap"
              onLoad={onLoad}
              ref={thumbnailRef}
              className="shadow-avatar max-w-[360px] max-h-[320px]"
            />
            <div className="absolute top-2 right-2">
              {![...makeRoles([1, 2, 6, 7, 10, 12, 13])].includes(role) ||
              buildingStatus ||
              switchVisual ||
              isLockReport ? null : (
                <>
                  <div
                    className="flex justify-center items-center w-[32px] h-[32px] bg-gray-300 rounded-full cursor-pointer"
                    onClick={handleOpenCropper}
                  >
                    <BsCameraFill style={{ fontSize: '20px', color: 'black' }} />
                  </div>
                  <Cropper2
                    ref={cropperRef}
                    name="avatar"
                    cropShape="rect"
                    onCompleteCropper={(file) => {
                      handleSetAvatar(file)
                      cropperRef?.current?.closeCropModal()
                    }}
                  />
                </>
              )}
            </div>

            <map name="workmap" style={{ position: 'relative' }}>
              {listImgUser &&
                listImgUser.map((anno, id) => {
                  let x = anno.x * thumbnailW,
                    y = anno.y * thumbnailH,
                    w = anno.w * thumbnailW,
                    h = anno.h * thumbnailH
                  return (
                    <area
                      style={{ outline: '1px solid red' }}
                      key={id}
                      title="manual"
                      shape="rect"
                      coords={`${x}, ${y}, ${w + x}, ${h + y}`}
                      alt="Manual"
                      href="#"
                      onClick={(e) => {
                        handleClickOnMap(listImgUser[id])
                      }}
                    />
                  )
                })}
              {listDefectCloseRangeThumbnail &&
                listDefectCloseRangeThumbnail.map((anno, id) => {
                  let x = anno.x * thumbnailW,
                    y = anno.y * thumbnailH,
                    w = (thumbnailW / 100) * 2
                  return (
                    <area
                      style={{ outline: '1px solid red', fillColor: 'cd3333' }}
                      key={id}
                      title="close range"
                      shape="circle"
                      coords={`${x}, ${y}, ${w}`}
                      alt="Close Range"
                      href="#"
                      onClick={(e) => {
                        const findIndex = listImageCloseRangeThumbnail.map((i) => i.defectId).indexOf(anno.id)
                        handleClickOnMap(listImageCloseRangeThumbnail[findIndex])
                      }}
                    />
                  )
                })}
            </map>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ElevationInfo
