import { useMemo, createContext } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import useAuth from '../hooks/useAuth'
const IdleTimerContext = createContext({})

export const IdleTimerWrapProvider = ({ children }) => {
  const { logout } = useAuth()
  const onPresenceChange = (presence) => {
    // Handle state changes in one function
  }

  const onIdle = () => {
    logout()
  }

  const { reset } = useIdleTimer({
    onPresenceChange,
    onIdle,
    timeout: 1000 * 900,
    promptBeforeIdle: 0,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
      'focus'
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: 'idle-timer',
    leaderElection: false
  })

  const idleTimerValue = useMemo(
    () => ({
      reset
    }),
    [reset]
  )
  return <IdleTimerContext.Provider value={idleTimerValue}>{children}</IdleTimerContext.Provider>
}

export default IdleTimerContext
