import React from 'react'
import NodataImage from './../../assets/images/nodata.gif'
const NoData = (props) => {
  const { width, bg } = props
  return (
    <div className="text-center" style={{ background: bg }}>
      <img src={NodataImage} alt="nodata" style={{ width, background: bg }} />
      <div className="text-[24px] mt-[-32px]">No Data!</div>
    </div>
  )
}

export default NoData
