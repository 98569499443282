import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { nanoid } from 'nanoid'
import { useNavigate, useParams } from 'react-router-dom'
import Compressor from 'compressorjs'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Progress } from 'antd'
import ImageUploading from 'react-images-uploading'
import { onConfirm } from 'react-confirm-pro'
import LoadingAnimated from '../../../assets/images/loading_new.gif'
import { editElevation, getFrameThermal } from '../../../store/thunks/elevationThunk'
import NoImage from '../../../assets/images/no_upload_image.png'
import SaveIcon from '../../../assets/images/svg/save_icon.svg'
import UploadFileIcon from '../../../assets/images/svg/file_upload_icon.svg'
import EditIcon from '../../../assets/images/svg/edit_icon.svg'
import CloseIcon from '../../../assets/images/svg/close_icon.svg'
import { toast } from 'react-toastify'
import { makeid } from '../../../utils/common'
import useIdleTimer from '../../../hooks/useIdleTimer'
import inspectionTypes from '../../../constant/store/inspectionTypes'
import { uploadElevationImage } from '../../../apis/fileApis'
import usePrevious from '../../../hooks/usePrevious'
import Direction_1 from '../../../assets/images/direction1.png'
import Direction_2 from '../../../assets/images/direction2.png'
import Direction_3 from '../../../assets/images/direction3.png'
import Direction_4 from '../../../assets/images/direction4.png'
import elevationTypes from '../../../constant/store/elevationTypes'
import { changeFileName } from '../../../utils/common'
import NotAvailable from '../../Common/NotAvailable'
import SkeletonViewer from '../../Common/skeleton-viewer'
import { typeFile, typeFileSort } from '../../../constant/file'
import PaddingAuto from '../../Layout/PaddingAuto'

function ThermalUploadImage() {
  const navigate = useNavigate()
  const { id: elevation_id } = useParams()
  const { reset } = useIdleTimer()
  const dispatch = useDispatch()
  const {
    contents: contentsElevation,
    loadings: loadingsElevation,
    errors: errorsElevation
  } = useSelector((state) => state.elevation)
  const [images, setImages] = useState([])
  const [position, setPosition] = useState([])

  const [preViewLoading, setPreviewLoading] = useState(false)
  let [count, setCount] = useState(0)
  const [totalNewItem, setTotalNewItem] = useState(0)
  const [isShowProcess, setIsShowProcess] = useState(false)
  const [renderingProcess, setRenderingProcess] = useState({
    loading: true,
    error: false
  })

  const elevationDetails = contentsElevation[elevationTypes.GET_FRAME_THERMAL]
  const prevGetFrameThermalFetching = usePrevious(loadingsElevation[elevationTypes.GET_FRAME_THERMAL])
  const direction = elevationDetails?.elevation.direction
  const rows = elevationDetails?.elevation.rows
  const columns = elevationDetails?.elevation.columns
  const listFrame = elevationDetails?.listImages
  const prevElevationUpdateFetching = usePrevious(loadingsElevation[elevationTypes.UPDATE_ELEVATION])

  useEffect(() => {
    dispatch(getFrameThermal(elevation_id))
  }, [elevation_id, dispatch])

  useEffect(() => {
    if (prevGetFrameThermalFetching && !loadingsElevation[elevationTypes.GET_FRAME_THERMAL]) {
      if (!errorsElevation[elevationTypes.GET_FRAME_THERMAL]) {
        setRenderingProcess((prev) => ({ ...prev, loading: false }))
      } else {
        setRenderingProcess((prev) => ({
          ...prev,
          error: !!errorsElevation[elevationTypes.GET_FRAME_THERMAL],
          message: errorsElevation[elevationTypes.GET_FRAME_THERMAL],
          loading: false
        }))
      }
    }
  }, [loadingsElevation, errorsElevation, prevGetFrameThermalFetching])

  useEffect(() => {
    if (prevElevationUpdateFetching && !loadingsElevation[elevationTypes.UPDATE_ELEVATION]) {
      if (!errorsElevation[inspectionTypes.UPDATE_ELEVATION]) {
        toast.success(
          'Thermal created',
          {
            theme: 'dark',
            autoClose: 2000
          },
          500
        )
        navigate(`/elevation/${elevationDetails?.elevation?.id}/thermal`, {
          replace: true
        })
      } else {
        toast.error(errorsElevation[elevationTypes.UPDATE_ELEVATION], {
          theme: 'dark',
          autoClose: 2000
        })
        setCount(0)
        setIsShowProcess(false)
      }
    }
  }, [loadingsElevation, errorsElevation, prevElevationUpdateFetching, contentsElevation, navigate])

  const handleUploadImages = async () => {
    setIsShowProcess(true)
    let newImgUrls = []
    let temUrls = []
    let newPosition = [...position]
    if (parseInt(direction) === 3) {
      newPosition = [...position].reverse()
    }
    newPosition.forEach((arrRow, idx) => {
      if (parseInt(direction) === 3) {
        arrRow = arrRow.reverse()
      }
      arrRow.forEach((item) => {
        if (parseInt(direction) === 0 || parseInt(direction) === 2) {
          if (idx % 2 === 0) {
            temUrls.push(item?.img ? item.img.file : 'dummy')
          } else {
            temUrls.unshift(item?.img ? item.img.file : 'dummy')
          }
        } else {
          if (idx % 2 !== 0) {
            temUrls.push(item?.img ? item.img.file : 'dummy')
          } else {
            temUrls.unshift(item?.img ? item.img.file : 'dummy')
          }
        }
      })
      newImgUrls.push(...temUrls)

      temUrls = []
    })

    try {
      setTotalNewItem(images.length)
      let uploadUrls = []
      let order = 1
      for (const item of newImgUrls) {
        if (item === 'dummy' || !item) {
          uploadUrls.push({
            order: order,
            url: process.env.REACT_APP_AWS_BLANK_IMAGE,
            urlOriginal: process.env.REACT_APP_AWS_BLANK_IMAGE
          })
        } else {
          // image compress
          const randomId = nanoid()
          const newFileName = changeFileName(randomId + item.name, 'jpg')
          const fileName = decodeURIComponent(item.name)
          const blob = item.slice(0, item.size, 'image/jpeg')
          const newFile = new File([blob], newFileName, { type: 'image/jpeg' })
          const dataThumbnail = {
            filename: newFile.name,
            contentType: newFile.type,
            key: 'elevation',
            type: 'compressed',
            elevationId: elevationDetails.elevation.id,
            size: item.size
          }
          let { signedUrl } = await uploadElevationImage(dataThumbnail)
          // const compressedFile = await imageCompression(newFile, options)
          new Compressor(newFile, {
            quality: 0.6,
            async success(result) {
              await fetch(signedUrl, {
                method: 'PUT',
                headers: {
                  'Content-Type': newFile.type
                },
                body: result
              })
            },
            error(err) {}
          })
          reset()
          const newUrl = signedUrl.split('?')[0]

          //original image
          const newFileNameOriginal = changeFileName('original_' + randomId + item.name, 'jpg')
          const newFileOriginal = new File([blob], newFileNameOriginal, { type: 'image/jpeg' })
          const dataThumbnailOrg = {
            filename: newFileOriginal.name,
            contentType: newFileOriginal.type,
            key: 'elevation',
            type: 'raw',
            elevationId: elevationDetails.elevation.id,
            size: item.size
          }
          let originalSigned = await uploadElevationImage(dataThumbnailOrg)
          await fetch(originalSigned.signedUrl, {
            method: 'PUT',
            headers: {
              'Content-Type': newFileOriginal.type
            },
            body: newFileOriginal
          })
          const urlOriginal = originalSigned.signedUrl.split('?')[0]
          uploadUrls.push({ order: order, url: newUrl, urlOriginal, fileName })
        }
        // push all images

        order++
        count += 1
        setCount(count)
      }

      const body2 = {
        ...elevationDetails.elevation,
        elevation_id: parseInt(elevation_id),
        thermal_images: uploadUrls
      }
      dispatch(editElevation(body2))
    } catch (error) {
      toast.error('Elevation update error', {
        theme: 'dark'
      })
    }
  }

  const handleOnDragEnd = (result) => {
    if (!result.destination) {
      return
    }
    const image1 = position[result.source.droppableId][result.source.index]
    const image2 = position[result.destination.droppableId][result.destination.index]
    let newPosition = [...position]
    if (!image2.active) return
    const sourceImage = image1?.img
    const destinationImage = image2?.img

    image1.img = destinationImage
    image2.img = sourceImage
    newPosition[result.source.droppableId].splice(result.source.index, 1, image1)
    newPosition[result.destination.droppableId].splice(result.destination.index, 1, image2)
    handleImages(newPosition)
    setPosition(newPosition)
  }
  const handleImages = (positionData) => {
    let newPosition = [...positionData]
    if (direction === 3) {
      newPosition = newPosition.reverse()
    }
    let newPositionList = []
    for (let newList of newPosition) {
      let newArray = [...newList]
      newArray.sort((a, b) => (a.order > b.order ? 1 : -1))
      newPositionList.push(newArray)
    }
    let newImages = []
    for (let newPositionArray of newPositionList) {
      const urls = newPositionArray.map((array) => array.img)
      newImages = newImages.concat(urls)
    }
    const checkImages = newImages.every((image) => !image)
    if (checkImages) {
      return []
    }
    setImages(newImages)
    return newImages
  }

  const handlePosition = (imagesData) => {
    const newPosition = []
    const images = [...imagesData]
    if (parseInt(direction) === 0 || parseInt(direction) === 1) {
      for (let iRow = 1; iRow < parseInt(rows) + 1; iRow++) {
        const newRows = []
        for (let iCol = 1; iCol < parseInt(columns) + 1; iCol++) {
          const element = { x: iRow, y: iCol, active: false, img: null }
          newRows.push(element)
        }
        newPosition.push(newRows)
      }

      setPosition(newPosition)
    } else {
      for (let iRow = 1; iRow < parseInt(columns) + 1; iRow++) {
        const newRows = []
        for (let iCol = 1; iCol < parseInt(rows) + 1; iCol++) {
          const element = { x: iRow, y: iCol, active: false, img: null }
          newRows.push(element)
        }
        newPosition.push(newRows)
      }
      setPosition(newPosition)
    }

    const newPos = [...newPosition]
    let currentIndex = 0
    let currentImage = 0
    const isfirstCreate = images.every((image) => image.data_url)
    for (let i = 0; i < newPos.length; i++) {
      if (parseInt(direction) === 0) {
        if (i % 2 === 0) {
          for (let j = 0; j < newPos[i].length; j++) {
            if (listFrame[currentIndex] && !listFrame[currentIndex]?.url) {
              newPos[i][j].img = images[isfirstCreate ? currentImage : currentIndex]
              newPos[i][j].active = true
              currentImage++
            } else if (listFrame[currentIndex] && !listFrame[currentIndex]?.url) {
              newPos[i][j].img = null
            } else {
              newPos[i][j].img = null
            }
            newPos[i][j].order = currentIndex
            currentIndex++
          }
        } else {
          for (let u = newPos[i].length - 1; u >= 0; u--) {
            if (listFrame[currentIndex] && !listFrame[currentIndex]?.url) {
              newPos[i][u].img = images[isfirstCreate ? currentImage : currentIndex]
              newPos[i][u].active = true
              currentImage++
            } else if (listFrame[currentIndex] && !listFrame[currentIndex]?.url) {
              newPos[i][u].img = null
            } else {
              newPos[i][u].img = null
            }
            newPos[i][u].order = currentIndex
            currentIndex++
          }
        }
      } else if (parseInt(direction) === 1) {
        if (i % 2 === 0) {
          for (let t = newPos[i].length - 1; t >= 0; t--) {
            if (listFrame[currentIndex] && !listFrame[currentIndex]?.url) {
              newPos[i][t].img = images[isfirstCreate ? currentImage : currentIndex]
              newPos[i][t].active = true
              currentImage++
            } else {
              newPos[i][t].img = null
            }
            newPos[i][t].order = currentIndex
            currentIndex++
          }
        } else {
          for (let z = 0; z < newPos[i].length; z++) {
            if (listFrame[currentIndex] && !listFrame[currentIndex]?.url) {
              newPos[i][z].img = images[isfirstCreate ? currentImage : currentIndex]
              newPos[i][z].active = true
              currentImage++
            } else {
              newPos[i][z].img = null
            }
            newPos[i][z].order = currentIndex
            currentIndex++
          }
        }
      } else if (parseInt(direction) === 2) {
        if (i % 2 === 0) {
          for (let j = 0; j < newPos[i].length; j++) {
            if (listFrame[currentIndex] && !listFrame[currentIndex]?.url) {
              newPos[i][j].img = images[isfirstCreate ? currentImage : currentIndex]
              newPos[i][j].active = true
              currentImage++
            } else if (listFrame[currentIndex] && !listFrame[currentIndex]?.url) {
              newPos[i][j].img = null
            } else {
              newPos[i][j].img = null
            }
            newPos[i][j].order = currentIndex
            currentIndex++
          }
        } else {
          for (let u = newPos[i].length - 1; u >= 0; u--) {
            if (listFrame[currentIndex] && !listFrame[currentIndex]?.url) {
              newPos[i][u].img = images[isfirstCreate ? currentImage : currentIndex]
              newPos[i][u].active = true
              currentImage++
            } else if (listFrame[currentIndex] && !listFrame[currentIndex]?.url) {
              newPos[i][u].img = null
            } else {
              newPos[i][u].img = null
            }
            newPos[i][u].order = currentIndex
            currentIndex++
          }
        }
      } else if (parseInt(direction) === 3) {
        if (i % 2 === 0) {
          for (let t = newPos[i].length - 1; t >= 0; t--) {
            if (listFrame[currentIndex] && !listFrame[currentIndex]?.url) {
              newPos[i][t].img = images[isfirstCreate ? currentImage : currentIndex]
              newPos[i][t].active = true
              currentImage++
            } else if (listFrame[currentIndex] && !listFrame[currentIndex]?.url) {
              newPos[i][t].img = null
            } else {
              newPos[i][t].img = null
            }
            newPos[i][t].order = currentIndex
            currentIndex++
          }
        } else {
          for (let z = 0; z < newPos[i].length; z++) {
            if (listFrame[currentIndex] && !listFrame[currentIndex]?.url) {
              newPos[i][z].img = images[isfirstCreate ? currentImage : currentIndex]
              newPos[i][z].active = true
              currentImage++
            } else if (listFrame[currentIndex] && !listFrame[currentIndex]?.url) {
              newPos[i][z].img = null
            } else {
              newPos[i][z].img = null
            }
            newPos[i][z].order = currentIndex
            currentIndex++
          }
        }
      }
    }
    if (parseInt(direction) === 3) {
      newPos.map((pos) => {
        return pos.reverse()
      })
      const posReverse = [...newPos].reverse()
      handleImages(posReverse)
      setPosition(posReverse)
    } else {
      handleImages(newPos)
      setPosition(newPos)
    }
  }

  const handleSetPosition = () => {
    const newPosition = []
    if (parseInt(direction) === 0 || parseInt(direction) === 1) {
      for (let iRow = 1; iRow < parseInt(rows) + 1; iRow++) {
        const newRows = []
        for (let iCol = 1; iCol < parseInt(columns) + 1; iCol++) {
          const element = { x: iRow, y: iCol, active: true, img: null }
          newRows.push(element)
        }

        newPosition.push(newRows)
      }

      setPosition(newPosition)
    } else {
      for (let iRow = 1; iRow < parseInt(columns) + 1; iRow++) {
        const newRows = []
        for (let iCol = 1; iCol < parseInt(rows) + 1; iCol++) {
          const element = { x: iRow, y: iCol, active: true, img: null }
          newRows.push(element)
        }
        newPosition.push(newRows)
      }
      setPosition(newPosition)
    }
  }

  const changeDirectAction = () => {
    let imagesData = [...images]
    let newImageData = []
    for (const imageValue of imagesData) {
      if (imageValue) {
        newImageData.push(imageValue)
      }
    }
    setImages(newImageData)
    handlePosition(newImageData)
  }

  useEffect(() => {
    handlePosition(images)
  }, [rows, columns])

  useEffect(() => {
    changeDirectAction()
  }, [direction])

  const onChange = (imageList, addUpdateIndex, a) => {
    if (addUpdateIndex?.length > 0) {
      const newImageList = imageList.map((img) => {
        return {
          ...img,
          id: makeid(30)
        }
      })

      setImages(newImageList)
      handlePosition(newImageList)
      setPreviewLoading(false)
    } else if (!addUpdateIndex && imageList.length === 0) {
      setImages(imageList)
      handlePosition(imageList)
    } else if (!addUpdateIndex && imageList.length > 0) {
      const imagesId = images.map((img) => {
        if (img) {
          return img.id
        }
      })
      const imagesListId = imageList.map((l) => {
        if (l) {
          return l.id
        }
      })
      const removeItemIndex = imagesId.filter((img) => !imagesListId.includes(img))
      const newPosition = position.map((arrow, i) => {
        return arrow.map((item, j) => {
          if (item?.img?.id === removeItemIndex[0]) {
            return {
              ...item,
              img: null
            }
          } else {
            return item
          }
        })
      })
      handleImages(newPosition)
      setPosition(newPosition)
    }
  }

  const renderSkeleton = () => {
    return (
      <div style={{ width: '100%', margin: '0 auto' }}>
        <div className="w-full overflow-auto bg-white border border-gray-300 rounded-xl shadow-lg bg-opacity-90 bg-clip-padding flex flex-col mb-6">
          <div className="flex flex-row justify-between border-b pt-5 pb-8 px-[30px]">
            <div className="flex  w-full justify-between mr-3">
              <div className="flex flex-col gap-4">
                <SkeletonViewer width={300} height={24} />
                <div className="flex flex-row items-center justify-start my-2 gap-12">
                  <SkeletonViewer width={190} height={24} />
                  <SkeletonViewer width={80} height={56} />
                </div>
                <div className="flex gap-4">
                  <div className="flex gap-2 items-center">
                    <SkeletonViewer width={40} height={24} />
                    <SkeletonViewer borderRadius={8} width={112} height={40} />
                  </div>

                  <div className="flex gap-2 items-center">
                    <SkeletonViewer width={80} height={24} />
                    <SkeletonViewer borderRadius={8} width={112} height={40} />
                  </div>
                </div>
              </div>
              <SkeletonViewer width={160} height={160} circle />
            </div>
            <div className="border-l ml-4">
              <div className="w-36">
                <SkeletonViewer width={128} borderRadius={12} className="ml-4" height={48} />
              </div>
            </div>
          </div>
        </div>
        <SkeletonViewer borderRadius={12} className="mb-4" height={48} />
        <div className="relative flex flex-col min-w-0  break-words bg-navigate w-full mb-6 shadow-lg rounded">
          <div className="px-4 py-4 flex-auto">
            <div className="tab-content tab-space">
              <div className="flex flex-col overflow-hidden w-full">
                <div className="flex gap-1">
                  {Array.from({ length: 7 }, function (v, k) {
                    return k
                  }).map((i) => (
                    <div key={i} className="flex flex-col gap-1">
                      {Array.from({ length: 3 }, function (f, g) {
                        return g
                      }).map((idx) => (
                        <div key={`${idx} + id`}>
                          <SkeletonViewer width={240} height={192} borderRadius={4} />
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  const onImageUploadingError = (errors) => {
    if (errors?.acceptType) {
      onConfirm({
        title: <h3 className="text-center">{`The images must be a file of type: ${typeFile.join()}`}</h3>,
        description: <br />,

        onCancel: () => {},
        buttons: ({ onCancel }) => (
          <div className="custom-buttons">
            <button
              style={{
                background: 'rgba(37, 99, 235)',
                color: 'white'
              }}
              onClick={onCancel}
            >
              Ok
            </button>
          </div>
        )
      })
    }
  }

  return (
    <>
      <div className="relative">
        <PaddingAuto>
          {renderingProcess.loading ? (
            renderSkeleton()
          ) : renderingProcess.error ? (
            <NotAvailable content={renderingProcess.message} />
          ) : (
            <ImageUploading
              multiple
              value={images}
              onChange={onChange}
              onError={onImageUploadingError}
              acceptType={typeFileSort}
              maxNumber={rows * columns}
              dataURLKey="data_url"
            >
              {({
                imageList,
                isDragging,
                dragProps,
                errors,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove
              }) => {
                return (
                  <>
                    <div className="w-full overflow-auto bg-white border border-gray-300 rounded-xl shadow-lg bg-opacity-90 bg-clip-padding flex flex-col mb-6">
                      <div className="flex flex-row justify-between border-b pt-5 pb-8 px-[30px]">
                        <div className="flex  w-full justify-between mr-3">
                          <div className="flex flex-col gap-4">
                            <p
                              className="text-lg"
                              style={{
                                fontSize: '16px',
                                fontWeight: 400,
                                lineHeight: '24px'
                              }}
                            >
                              Elevation:
                              <span className="text-center rounded-full w-11 h-11">
                                {elevationDetails?.elevation.name}
                              </span>
                            </p>
                            <div
                              className="flex flex-row items-center justify-start my-2"
                              style={{
                                fontSize: '16px',
                                fontWeight: 400,
                                lineHeight: '24px'
                              }}
                            >
                              <p className="mr-2 ">Drone Direction (hover):</p>
                              <img
                                alt="Direction"
                                src={
                                  parseInt(direction) === 0
                                    ? Direction_1
                                    : parseInt(direction) === 1
                                    ? Direction_2
                                    : parseInt(direction) === 2
                                    ? Direction_3
                                    : Direction_4
                                }
                                className="w-20 bg-white transition duration-500 transform rounded h-14 hover:scale-250"
                              />
                            </div>
                            <div
                              className="flex gap-4"
                              style={{
                                fontSize: '14px',
                                fontWeight: 400,
                                lineHeight: '21px'
                              }}
                            >
                              <div className="flex ">
                                <div className="flex items-center w-10"> Row: </div>

                                <p className="inline-block w-28 h-10 rounded-lg border text-center leading-10 font-bold text-primary">
                                  {rows}
                                </p>
                              </div>

                              <div>
                                <p className="inline-block w-20">Columns: </p>
                                <p className="inline-block w-28 h-10 rounded-lg border text-center leading-10 font-bold text-primary">
                                  {columns}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="relative flex items-center gap-2 group h-[160px] w-[160px] rounded-full shadow-avatar">
                            <img
                              className="w-full h-full rounded-full mr-4 "
                              src={elevationDetails?.elevation.data_thumbnail?.thumbnailUrl}
                              alt="avt"
                            />
                          </div>
                        </div>
                        <div className="border-l ml-4">
                          {columns !== 0 && rows !== 0 && (
                            <div className="w-36">
                              {isShowProcess ? (
                                <div className="h-14 flex flex-col justify-center items-center mt-6">
                                  <div className="font-bold">
                                    Uploaded {count}/{totalNewItem}
                                  </div>
                                  <Progress
                                    type="circle"
                                    percent={Math.ceil(((count * 100) / totalNewItem) * 10) / 10}
                                    status="exception"
                                    height={56}
                                    width={56}
                                  />
                                </div>
                              ) : (
                                <>
                                  {images && images.length !== 0 && (
                                    <button
                                      className="w-32 float-right mr-0 bg-blue-button py-4 flex items-center justify-around rounded-xl  leading-3 cursor-pointer mb-3 px-3"
                                      onClick={() => {
                                        handleUploadImages()
                                      }}
                                    >
                                      <img src={SaveIcon} alt="save" className="flex mx-2" />
                                      <span className="text-white font-bold mr-2">Save</span>
                                    </button>
                                  )}

                                  {(!images || images.length === 0) && (
                                    <button
                                      className="w-32 float-right border border-primary boroder-solid py-4 flex items-center justify-around rounded-xl  leading-3 cursor-pointer mb-3"
                                      onClick={() => {
                                        onImageUpload()
                                        setPreviewLoading(true)
                                      }}
                                      {...dragProps}
                                    >
                                      <img src={UploadFileIcon} alt="save" />
                                      <span className="text-primary mr-2 font-bold">Upload photo</span>
                                    </button>
                                  )}
                                  {images && images.length !== 0 && (
                                    <button
                                      className="w-32 float-right border border-primary boroder-solid py-4 flex items-center justify-around rounded-xl  leading-3 cursor-pointer mb-3 px-3"
                                      onClick={onImageRemoveAll}
                                    >
                                      <img src={CloseIcon} alt="save" />
                                      <span className="text-opacity mr-2">Remove all</span>
                                    </button>
                                  )}
                                  {preViewLoading && (
                                    <img src={LoadingAnimated} className="w-12 h-12 ml-4" alt="Loading" />
                                  )}
                                </>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {columns !== 0 && rows !== 0 && !errors?.maxNumber && (
                      <div
                        className="flex p-4 mb-4 text-sm text-yellow-700 bg-yellow-100 rounded-lg dark:bg-yellow-200 dark:text-yellow-800 mt-4"
                        role="alert"
                      >
                        <svg
                          className="inline flex-shrink-0 mr-3 w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <div>
                          <span className="font-medium">Warning!</span> If you want to change the direction, columns or
                          rows after you add images, you should remove all images and add again
                        </div>
                      </div>
                    )}

                    {errors && errors.maxNumber && (
                      <div
                        className="relative p-4 my-4 text-red-700 bg-red-100 border border-red-400 rounded"
                        role="alert"
                      >
                        <strong className="mr-2 font-bold">Error!</strong>
                        <span className="block sm:inline">Number of selected images exceed max number</span>
                      </div>
                    )}
                    {columns !== 0 && rows !== 0 && (
                      <div className="relative flex flex-col min-w-0  break-words bg-navigate w-full mb-6 shadow-lg rounded">
                        <div className="px-4 py-4 flex-auto">
                          <div className="tab-content tab-space">
                            <div className="flex flex-col overflow-auto w-full">
                              <div
                                className={`flex gap-1 ${
                                  parseInt(direction) === 0 || parseInt(direction) === 1 ? 'flex-col' : 'flex-row'
                                }`}
                              >
                                <DragDropContext onDragEnd={handleOnDragEnd}>
                                  {position.map((arrRow, idx) => (
                                    <React.Fragment key={idx}>
                                      <Droppable
                                        droppableId={idx.toString()}
                                        key={idx}
                                        direction={direction < 2 ? 'horizontal' : 'vertical'}
                                        isDropDisabled={false}
                                        isCombineEnabled={true}
                                        ignoreContainerClipping={true}
                                      >
                                        {(provided) => {
                                          return (
                                            <div key={idx} className="flex flex-row items-center justify-start">
                                              <div
                                                className={`flex justify-start  gap-1 ${
                                                  parseInt(direction) === 0 || parseInt(direction) === 1
                                                    ? 'flex-row pr-5'
                                                    : parseInt(direction) === 2
                                                    ? 'flex-col'
                                                    : isShowProcess
                                                    ? 'flex-col-reverse'
                                                    : 'flex-col'
                                                }`}
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                              >
                                                {arrRow.map((col, idx) => {
                                                  return (
                                                    <Draggable
                                                      key={col.order?.toString()}
                                                      draggableId={col.order?.toString()}
                                                      index={idx}
                                                      isDragDisabled={!col.active}
                                                    >
                                                      {(provided, snapshot) => (
                                                        <>
                                                          <div
                                                            className={`relative flex bg-transparent flex-row items-center justify-center h-48  cursor-pointer w-60 ${
                                                              snapshot.isDragging ? '' : 'drag'
                                                            }`}
                                                            // style={getStyle(provided.draggableProps.style, snapshot)}
                                                            key={idx}
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                          >
                                                            {col.active && col.img?.data_url ? (
                                                              <>
                                                                <img
                                                                  src={col.img.data_url}
                                                                  alt="New Images"
                                                                  className="rounded-lg  w-60 h-48"
                                                                />
                                                                <button
                                                                  onClick={() => {
                                                                    const index = images.findIndex(
                                                                      (img) => img && img.id === col.img.id
                                                                    )

                                                                    return onImageUpdate(index)
                                                                  }}
                                                                  className="absolute rounded-lg flex items-center justify-center w-8 h-8 bg-white opacity-50 top-2 left-2 cursor-pointer hover:opacity-100"
                                                                >
                                                                  <img src={EditIcon} alt="edit" />
                                                                </button>
                                                              </>
                                                            ) : col.active ? (
                                                              <img
                                                                src={NoImage}
                                                                alt="Placeholder"
                                                                className="w-60 h-full rounded"
                                                              />
                                                            ) : col && listFrame[col.order] ? (
                                                              <img
                                                                src={process.env.REACT_APP_AWS_BLANK_IMAGE}
                                                                alt="Placeholder"
                                                                className="w-60 h-full rounded"
                                                              />
                                                            ) : null}
                                                          </div>
                                                          {snapshot.isDragging ? (
                                                            <div
                                                              className={`relative flex bg-transparent flex-row items-center justify-center h-48  cursor-pointer w-60`}
                                                            >
                                                              <img
                                                                src={NoImage}
                                                                alt="Placeholder"
                                                                className="w-60 h-full rounded"
                                                              />
                                                            </div>
                                                          ) : (
                                                            <></>
                                                          )}
                                                        </>
                                                      )}
                                                    </Draggable>
                                                  )
                                                })}
                                                <span
                                                  style={{
                                                    display: 'none'
                                                  }}
                                                >
                                                  {provided.placeholder}
                                                </span>
                                              </div>
                                            </div>
                                          )
                                        }}
                                      </Droppable>
                                    </React.Fragment>
                                  ))}
                                </DragDropContext>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )
              }}
            </ImageUploading>
          )}
        </PaddingAuto>
      </div>
    </>
  )
}

export default ThermalUploadImage
