import { createAsyncThunk } from '@reduxjs/toolkit'
import reason from '../../apis/reasonApis'
import reasonTypes from '../../constant/store/reasonTypes'

export const getCompanyReason = createAsyncThunk(reasonTypes.GET_COMPANY_REASON, async (data, { rejectWithValue }) => {
  try {
    const result = await reason.getCompanyReason(data.id, data.options)
    if (result.status === 200) {
      return result.data
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const createCompanyReason = createAsyncThunk(
  reasonTypes.CREATE_COMPANY_REASON,
  async (data, { rejectWithValue }) => {
    try {
      const result = await reason.createCompanyReason(data)
      if (result.status === 201) {
        return result.data
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const updateCompanyReason = createAsyncThunk(
  reasonTypes.UPDATE_COMPANY_REASON,
  async (data, { rejectWithValue }) => {
    try {
      const result = await reason.updateCompanyReason(data)
      if (result.status === 200) {
        return result.data
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const deleteCompanyReason = createAsyncThunk(
  reasonTypes.DELETE_COMPANY_REASON,
  async (data, { rejectWithValue }) => {
    try {
      const result = await reason.deleteCompanyReason(data)
      if (result.status === 200) {
        return { id: data.id }
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
export const getAllCompanyReason = createAsyncThunk(
  reasonTypes.GET_ALL_COMPANY_REASON,
  async (data, { rejectWithValue }) => {
    try {
      const result = await reason.getAllCompanyReason(data)
      if (result.status === 200) {
        return result.data
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
