import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import YupPassword from 'yup-password'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { toast } from 'react-toastify'

// hocs
import useAuth from '../../../hooks/useAuth'
import usePrevious from '../../../hooks/usePrevious'
// actions
import {
  createUser,
  getUsers,
  updateUser,
  getRoleAdmin,
  getRoleUser,
  clearErrors
} from '../../../store/thunks/userThunk'

import { getCompanyActive } from '../../../store/thunks/companyThunk'
import { getBuildingsActive } from '../../../store/thunks/buildingThunk'

// commons
import Modal from '../../Common/Modal'
import CustomMulSelect from '../../Common/CustomMulSelect'
import CustomSelect from '../../Common/CustomSelect'
import Table from '../../Common/table2'
import { isEmptyObject, makeRoles, makeRolesString } from '../../../utils/common'
import { status } from '../../../constant/common'
import StatusButton from '../../Common/status'

// antd
import { ExclamationCircleOutlined, SaveOutlined, EditOutlined, RedoOutlined } from '@ant-design/icons'

// assets

// constants
import { color } from '../../../constant/styled'
import userTypes from '../../../constant/store/userTypes'
import { claritasAdmin, ClientProject, adminRoles } from '../../../constant/auth'
import inspectionTypes from '../../../constant/store/inspectionTypes'
import companyTypes from '../../../constant/store/companyTypes'
import SkeletonViewer from '../../Common/skeleton-viewer'
import LoadingSpinner from '../../Common/loading-spinner/LoadingSpinner'
import NotAvailable from '../../Common/NotAvailable'
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'
import PaddingAuto from '../../Layout/PaddingAuto'

YupPassword(Yup)

const tabUser = [
  { label: 'Claritas User', value: 1, path: '/users/claritas-user' },
  { label: 'User', value: 2, path: '/users/normal-user' }
]

const UserManagement = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { modifyError: errorPayload } = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const { user } = useAuth()
  const { role } = user?.user
  const [_mounted, setMouted] = useState(false)
  const {
    contents: contentsUser,
    errors: errorsUser,
    loadings: loadingsUser,
    paginations: paginationUser
  } = useSelector((state) => state.user)
  const { contents } = useSelector((state) => state.building)
  const { contents: contentsCompany } = useSelector((state) => state.company)
  const [options, setOptions] = useState([])

  const [currentUser, setCurrentUser] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [currentRole, setCurrentRole] = useState('Project')
  const [companySelected, setCompanySelected] = useState(null)
  const [companyList, setCompanyList] = useState([])
  const [submitting, setSubmitting] = useState(false)
  const [roleList, setRoleList] = useState([])
  const [isReset, setIsReset] = useState(false)
  const [currentTab, setCurrentTab] = useState(1)
  const [tableOptions, setTableOptions] = useState({})
  const [defaultValueMuiBuilding, setDefaultValueMuiBuilding] = useState([])
  const [renderingProcess, setRenderingProcess] = useState({
    loading: true,
    error: false
  })
  const companies = contentsCompany[companyTypes.GET_COMPANY_ACTIVE]
  const users = contentsUser[userTypes.GET_USER]
  const rolesAdmin = contentsUser[userTypes.GET_ROLE_ADMIN]
  const rolesUser = contentsUser[userTypes.GET_ROLE_USER]
  const loadingUser = loadingsUser[userTypes.GET_USER]
  const paginationsOptions = paginationUser[userTypes.GET_USER]
  const prevUserCreateFetching = usePrevious(loadingsUser[userTypes.CREATE_USER])
  const prevGetUser = usePrevious(loadingsUser[userTypes.GET_USER])
  const prevUserUpdateFetching = usePrevious(loadingsUser[userTypes.UPDATE_USER])

  const handleEditUser = (id) => {
    setShowModal(true)
    const indexCpn = users.findIndex((company) => company.id === id)
    setCurrentUser(users[indexCpn])
  }

  const actions = [
    {
      id: 1,
      title: 'User',
      isShow: adminRoles.includes(role)
    }
  ]

  const UserColumns = [
    {
      Header: 'Username',
      accessor: 'username',
      width: 180
    },
    {
      Header: 'Email',
      accessor: 'email',
      width: 180
    },
    {
      Header: 'Company',
      accessor: 'companyname',
      id: 'company',
      isHidden: currentTab === 1,
      width: 200
    },
    {
      Header: 'Role',
      accessor: 'role_name',
      id: 'role',
      width: 180,
      Cell: ({ value }) => {
        return (
          <>
            {value === 'Project'
              ? 'Claritas Project'
              : value === 'Admin'
              ? 'Claritas Admin'
              : value === 'Reviewer'
              ? 'Claritas Reviewer'
              : value === 'AI'
              ? 'Claritas AI'
              : value === 'CP'
              ? 'Claritas CP'
              : value === 'Claritas Image'
              ? 'Claritas Image'
              : value === 'ClientAdmin'
              ? 'Admin'
              : value === 'ClientProject'
              ? 'Project'
              : value === 'ClientReviewer'
              ? 'Reviewer'
              : value === 'ClientImage'
              ? 'Image'
              : value === 'ClientCP'
              ? 'CP'
              : value === 'Image'
              ? 'Image'
              : value === 'ClientFI'
              ? 'FI'
              : 'Guest'}
          </>
        )
      }
    },
    {
      Header: 'Status',
      accessor: 'status',
      id: 'status',
      width: 180,
      Cell: ({ value }) => {
        return (
          <>
            {value === 'Active' ? (
              <StatusButton color={color.green} bgColor={color.greenLight} title="Active" />
            ) : value === 'Inactive' ? (
              <StatusButton color={color.red} bgColor={color.redLight} title="Inactive" />
            ) : (
              <>-</>
            )}
          </>
        )
      }
    },
    {
      Header: 'Actions',
      accessor: 'isDelete',
      role: adminRoles,
      width: 180,
      disableSortBy: true,
      Cell: (tableProps) => {
        const { status } = tableProps.row?.original
        return (
          <>
            {status === 'Active' || status === 'Inactive' ? (
              <div className="flex gap-2">
                <div className="relative">
                  <div
                    className="inline-flex items-center font-bold py-2 px-3 rounded-lg bg-view-detail group-hover:bg-white group-hover:text-blue-button cursor-pointer"
                    onClick={() => handleEditUser(tableProps.row.original.id)}
                  >
                    <EditOutlined
                      style={{
                        fontSize: '20px',
                        cursor: 'pointer',
                        color: color.green
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex gap-2">
                <div className="relative">
                  <div className="inline-flex items-center font-bold py-2 px-3 rounded-lg bg-view-detail group-hover:bg-white group-hover:text-blue-button cursor-pointer">
                    <RedoOutlined
                      style={{
                        fontSize: '20px',
                        cursor: 'pointer',
                        color: color.blue
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        )
      }
    }
  ]

  const handleCloseModal = () => {
    setShowModal(false)
    setCurrentUser(null)
    setDefaultValueMuiBuilding([])
    dispatch(clearErrors([userTypes.CREATE_USER]))
  }

  const initialValues = {
    company: currentUser?.company_id || (companies && companies[0]?.id),
    email: currentUser?.email || '',
    username: currentUser?.username || '',
    building_ids: [],
    role: currentUser ? currentUser?.role_id : roleList?.length > 0 ? roleList[0]?.value : 1,
    status: currentUser ? currentUser?.status : status[0].value,
    regno: currentUser ? currentUser?.regno : ''
  }

  const onSubmit = async (fields, onSubmitProps) => {
    setSubmitting(true)
    const { building_ids, company, username, email, role, status, regno } = fields
    const newListBuiding = building_ids.map((item) => item.id)
    const isClaritas = [...makeRoles([1, 2, 3, 4, 5, 12])].includes(currentRole)
    const isClientAdmin = ClientProject.includes(currentRole)
    try {
      const dataReq = {
        ...fields,
        role: role,
        username: username.trim(),
        email: email.trim().toLowerCase(),
        regno: regno,
        company_id: parseInt(company) || user?.user?.companyId,
        building_ids: isClientAdmin ? [] : newListBuiding,
        status,
        isClaritas
      }
      dispatch(createUser(dataReq))
    } catch (err) {
      toast.error('Error when creating user')
    }
  }

  function onSubmitUpdate(fields, onSubmitProps) {
    setSubmitting(true)
    const { building_ids, company, username, email, role, status, regno } = fields
    const newListBuiding = building_ids.map((item) => item.id)
    const newOptions = options.map((item) => item.id)
    const isClaritas = [...makeRoles([1, 2, 3, 4, 5])].includes(currentRole)
    const isClientAdmin = ClientProject.includes(currentRole)
    try {
      const dataReq = {
        id: currentUser.id,
        username: username.trim(),
        email: email.trim().toLowerCase(),
        company_id: parseInt(company) || user?.user?.company.id,
        building_ids: isClientAdmin ? newOptions : newListBuiding,
        regno: regno,
        isClaritas,
        status,
        role: currentRole?.role_id || role
      }
      dispatch(updateUser(dataReq))
    } catch (err) {
      toast.error('Error when creating user')
    }
  }

  const validationSchema = Yup.object({
    company:
      claritasAdmin.includes(user?.user?.role) &&
      ![...makeRoles([1, 2, 3, 4])].includes(currentRole) &&
      Yup.string().required('Please select your company'),
    username: Yup.string().required('Please enter username'),
    email: Yup.string()
      .required('Please enter correct user email')
      .email('Invalid Email')
      .test('Invalid Email', 'Invalid Email', (value) => {
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(String(value))
      }),
    role: Yup.string().required('Please select role'),
    regno: Yup.string().max(12, 'Regno must be at most 12 characters')
  })

  const handleNavigateParamsUrl = (params, replace = false, pathname) => {
    navigate(
      {
        pathname: pathname,
        search: createSearchParams({
          page: params.page,
          size: params.size,
          ...params
        }).toString()
      },
      { replace }
    )
  }

  useMemo(() => {
    setMouted(true)
  }, [])

  // effect with store
  useEffect(() => {
    if (prevGetUser && !loadingsUser[userTypes.GET_USER]) {
      if (!errorsUser[userTypes.GET_USER]) {
        setRenderingProcess((prev) => ({ ...prev, error: false, loading: false }))
      } else {
        setRenderingProcess((prev) => ({ ...prev, error: true, loading: false }))
      }
    }
  }, [errorsUser, loadingsUser, prevGetUser, dispatch])

  useEffect(() => {
    if (prevUserCreateFetching && !loadingsUser[userTypes.CREATE_USER]) {
      setSubmitting(false)
      if (!errorsUser[userTypes.CREATE_USER]) {
        setShowModal(false)
        handleNavigateParamsUrl(
          { page: 1, size: 10 },
          false,
          `${currentTab === 1 ? '/users/claritas-user' : '/users/normal-user'}`
        )
        setShowModal(false)
        setCurrentUser(null)
        setDefaultValueMuiBuilding([])
        dispatch(clearErrors([userTypes.CREATE_USER]))
        toast.success('Create user successfully', {
          theme: 'dark',
          autoClose: 2000
        })
      }
    }
  }, [errorsUser, loadingsUser, prevUserCreateFetching, dispatch])

  useEffect(() => {
    if (prevUserUpdateFetching && !loadingsUser[userTypes.UPDATE_USER]) {
      setSubmitting(false)
      if (!errorsUser[userTypes.UPDATE_USER]) {
        setShowModal(false)
        setShowModal(false)
        setCurrentUser(null)
        setDefaultValueMuiBuilding([])
        dispatch(clearErrors([userTypes.UPDATE_USER]))
        toast.success('Update user successfully', {
          theme: 'dark',
          autoClose: 2000
        })
      } else {
        toast.error(errorsUser[userTypes.UPDATE_USER], {
          theme: 'dark',
          autoClose: 2000
        })
      }
    }
  }, [errorsUser, loadingsUser, prevUserUpdateFetching])

  // ** //

  useEffect(() => {
    const setListCompany = () => {
      let cpnList =
        companies?.length &&
        companies?.map((item) => {
          return {
            value: item.id,
            label: item.name
          }
        })
      setCompanyList(cpnList)
    }

    setListCompany()
  }, [user, companies])

  useEffect(() => {
    const mapOptions = () => {
      let options = []
      if (currentRole === 'Image') {
        options = contents[inspectionTypes.GET_BUILDINGS_ACTIVE].map((b) => ({
          id: b.id,
          name: b.name,
          label: b.name,
          value: b.id
        }))
      } else if (user?.user?.companyId) {
        options = contents[inspectionTypes.GET_BUILDINGS_ACTIVE]
          ?.filter((b) => b.company_id === parseInt(user?.user.companyId))
          .map((b) => ({
            id: b.id,
            name: b.name,
            label: b.name,
            value: b.id
          }))
      } else if (currentUser?.company_id) {
        options = contents[inspectionTypes.GET_BUILDINGS_ACTIVE]
          ?.filter((b) => b.company_id === parseInt(currentUser?.company_id))
          .map((b) => ({
            id: b.id,
            name: b.name,
            label: b.name,
            value: b.id
          }))
      } else if (companySelected) {
        options = contents[inspectionTypes.GET_BUILDINGS_ACTIVE]
          ?.filter((b) => b.company_id === parseInt(companySelected))
          .map((b) => ({
            id: b.id,
            name: b.name,
            label: b.name,
            value: b.id
          }))
      } else if (companies?.length) {
        options = contents[inspectionTypes.GET_BUILDINGS_ACTIVE]
          ?.filter((b) => b.company_id === parseInt(companies[0]?.id))
          .map((b) => ({
            id: b.id,
            name: b.name,
            label: b.name,
            value: b.id
          }))
      }

      setOptions(options)
    }
    mapOptions()
  }, [user, contents, companySelected, currentRole, role, companies, currentUser])

  useEffect(() => {
    const setListRoles = () => {
      if (currentTab === 2) {
        setRoleList(rolesUser)
      } else {
        setRoleList(rolesAdmin)
      }
    }
    setListRoles()
  }, [currentTab, rolesUser, rolesAdmin])

  useEffect(() => {
    const defaultValueMulSelect = (crrUser) => {
      let listBuildingSelected = []
      if (crrUser) {
        listBuildingSelected = contents[inspectionTypes.GET_BUILDINGS_ACTIVE]
          ?.filter((b) => crrUser.includes(b.id))
          .map((b) => ({
            id: b.id || b.building_id,
            name: b.name,
            label: b.name,
            value: b.id || b.building_id
          }))
        setDefaultValueMuiBuilding(listBuildingSelected)
      }
    }
    defaultValueMulSelect(currentUser?.buildings)
  }, [currentUser, contents, setDefaultValueMuiBuilding])

  useEffect(() => {
    if (location.pathname === '/users/claritas-user') {
      setCurrentTab(1)
    } else {
      setCurrentTab(2)
    }
  }, [])

  useEffect(() => {
    if (currentTab === 1) {
      setCurrentRole('Project')
      setTableOptions({ page: 1, size: 10, ...tableOptions }) // get user
    } else {
      setTableOptions({ page: 1, size: 10, ...tableOptions }) // get user
      setCurrentRole('ClientAdmin')
    }
  }, [currentTab])

  useEffect(() => {
    if (currentTab === 1 && [...makeRoles([1, 2])].includes(user?.user?.role)) {
      dispatch(getRoleAdmin())
    }
    dispatch(getRoleUser())
  }, [])

  useEffect(() => {
    if (currentUser?.role_name) {
      setCurrentRole(currentUser?.role_name)
    }
  }, [currentUser?.role_name, currentUser])

  // get data table

  useEffect(() => {
    if (!location.search) {
      handleNavigateParamsUrl(
        { page: 1, size: 10 },
        true,
        `${currentTab === 1 ? '/users/claritas-user' : '/users/normal-user'}`
      )
    }
  }, [])

  useEffect(() => {
    if (_mounted && location.search) {
      const params = Object.fromEntries(new URLSearchParams(window.location.search))
      const options = {
        ...params,
        ...(params.page ? { page: params.page } : { page: 1 }),
        ...(params.size && params.size >= 10 ? { size: params.size } : { size: 10 })
      }

      setTableOptions(options)
    }
  }, [location.search])

  useEffect(() => {
    const get = () => {
      if (_mounted && location.search && !isEmptyObject(tableOptions)) {
        if (currentTab === 1 && [...makeRoles([1, 2])].includes(user?.user?.role)) {
          dispatch(getUsers({ roletab: 'claritas', options: tableOptions }))
        } else {
          dispatch(getUsers({ roletab: 'user', options: tableOptions }))
        }
      }
    }

    get()
  }, [dispatch, tableOptions])

  useEffect(() => {
    dispatch(getBuildingsActive())
  }, [dispatch])

  useEffect(() => {
    if ([...makeRoles([1, 2, 4])].includes(user?.user?.role)) {
      dispatch(getCompanyActive())
    }
  }, [dispatch, user])

  const renderSkeleton = () => {
    return (
      <div style={{ width: '100%', margin: '0 auto', marginTop: '16px' }}>
        <div className="flex items-center flex-wrap gap-2 md:gap-6">
          {actions &&
            Array.from({ length: actions.length + 1 }, function (v, k) {
              return k
            }).map((i) => (
              <div key={i}>
                <SkeletonViewer borderRadius={16} className="h-8 md:h-10 w-32 md:w-44" />
              </div>
            ))}
          <div className="sm:ml-auto">
            <SkeletonViewer borderRadius={16} className="h-8 md:h-10 w-[90vw] sm:w-40 md:w-56 lg:w-72" />
          </div>
        </div>
        <div>
          <SkeletonViewer className="mt-2" height={60} width="100%" />
          <div className="block overflow-hidden mt-1" style={{ height: '58vh' }}>
            {Array.from({ length: 10 }, function (v, k) {
              return k
            }).map((i) => (
              <div key={i}>
                <SkeletonViewer className="mt-2" height={68} borderRadius={12} width="100%" />
              </div>
            ))}
          </div>
        </div>
        <div className="flex justify-center">
          <SkeletonViewer className="h-8 md:h-10 mt-4" width={300} />
        </div>
      </div>
    )
  }

  const renderModalAddEdit = () => {
    return (
      <>
        {showModal && (
          <Modal wrapper="w-auto max-w-md mx-auto" onClose={handleCloseModal}>
            <div className="flex items-center justify-between p-3 border-b border-solid rounded-t border-slate-200">
              <h3 className="text-xl text-primary font-semibold">{currentUser ? 'Edit User' : 'Create User'} </h3>
            </div>
            {/*body*/}
            <div className="relative flex flex-auto p-6">
              <div className="flex flex-col items-start justify-start "></div>
              <div className="w-full max-w-lg">
                <Formik
                  initialValues={initialValues}
                  onSubmit={currentUser ? onSubmitUpdate : onSubmit}
                  validationSchema={validationSchema}
                >
                  {({ values, setFieldValue, errors, isSubmitting }) => {
                    return (
                      <Form>
                        <div className="flex flex-wrap mb-6 -mx-3">
                          <div className="w-full px-3 mb-3">
                            <label
                              className="block mb-2 text-xs font-bold tracking-wide text-primary uppercase"
                              htmlFor="companies_selection"
                            >
                              Select Role
                            </label>
                            <CustomSelect
                              className="input"
                              onChange={({ value }) => {
                                setFieldValue('role', value)
                                setCurrentRole(makeRolesString(value))
                              }}
                              options={roleList}
                              value={values?.role || ''}
                            />
                            <ErrorMessage name="role">
                              {(errMsg) => (
                                <div className="text-red-500 mt-1 flex items-center">
                                  <ExclamationCircleOutlined />
                                  <span className="ml-1">{errMsg}</span>
                                </div>
                              )}
                            </ErrorMessage>
                          </div>
                          {![...makeRoles([1, 2, 3, 4, 5, 12])].includes(currentRole) &&
                            ![...ClientProject].includes(role) &&
                            !currentUser && (
                              <div className="w-full px-3 mb-3">
                                <label
                                  className="block mb-2 text-xs font-bold tracking-wide text-primary uppercase"
                                  htmlFor="companies_selection"
                                >
                                  Select Company
                                </label>
                                <CustomSelect
                                  className="input"
                                  onChange={({ value }) => {
                                    setFieldValue('company', value)
                                    setCompanySelected(value)
                                    setIsReset(true)
                                    setDefaultValueMuiBuilding([])
                                  }}
                                  options={companyList}
                                  value={values?.company}
                                />
                                <ErrorMessage name="company">
                                  {(errMsg) => (
                                    <div className="text-red-500 mt-1 flex items-center">
                                      <ExclamationCircleOutlined />
                                      <span className="ml-1">{errMsg}</span>
                                    </div>
                                  )}
                                </ErrorMessage>
                              </div>
                            )}

                          <div className="w-full px-3 mb-3">
                            <label
                              className="block mb-2 text-xs font-bold tracking-wide text-primary uppercase"
                              htmlFor="username"
                            >
                              Username
                            </label>
                            <Field
                              type="text"
                              id="username"
                              placeholder="Username"
                              className="block w-full px-4 py-3 leading-tight text-primary bg-gray-50 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                              name="username"
                            />
                            <ErrorMessage name="username">
                              {(errMsg) => (
                                <div className="text-red-500 mt-1 flex items-center">
                                  <ExclamationCircleOutlined />
                                  <span className="ml-1">{errMsg}</span>
                                </div>
                              )}
                            </ErrorMessage>
                          </div>

                          <div className="w-full px-3 mb-3">
                            {currentUser ? (
                              <>
                                <label
                                  className="block mb-2 text-xs font-bold tracking-wide text-primary uppercase"
                                  htmlFor="Status"
                                >
                                  Select Status
                                </label>
                                <CustomSelect
                                  className="input mb-3"
                                  onChange={({ value }) => {
                                    setFieldValue('status', value)
                                  }}
                                  options={status}
                                  value={values.status}
                                />
                              </>
                            ) : (
                              ''
                            )}
                            <label
                              className="block mb-2 text-xs font-bold tracking-wide text-primary uppercase"
                              htmlFor="email"
                            >
                              Email
                            </label>
                            <Field
                              type="email"
                              id="email"
                              placeholder="Email"
                              className="block w-full px-4 py-3 leading-tight text-primary bg-gray-50 border rounded appearance-none focus:outline-none focus:bg-white"
                              name="email"
                              onChange={(e) => {
                                setFieldValue('email', e.target.value.trimStart().toLowerCase())
                              }}
                              disabled={currentUser ? true : false}
                            />

                            {showModal && !errors.email && !!errorsUser[userTypes.CREATE_USER] ? (
                              <div div className="text-red-500 mt-1 flex items-center">
                                <ExclamationCircleOutlined />
                                <span className="ml-1">{errorsUser[userTypes.CREATE_USER]}</span>
                              </div>
                            ) : (
                              <ErrorMessage name="email">
                                {(errMsg) => (
                                  <div className="text-red-500 mt-1 flex items-center">
                                    <ExclamationCircleOutlined />
                                    <span className="ml-1">{errorPayload ? errorPayload : errMsg}</span>
                                  </div>
                                )}
                              </ErrorMessage>
                            )}
                          </div>

                          <div className="w-full px-3">
                            {![...makeRoles([1, 2, 3, 4, 6, 7, 10, 12, 13])].includes(currentRole) && (
                              <div className="w-full mb-3">
                                <label
                                  className="block mb-2 text-xs font-bold tracking-wide text-primary uppercase"
                                  htmlFor="companies_selection"
                                >
                                  Select Inspection
                                </label>
                                <Field
                                  defaultValue={defaultValueMuiBuilding}
                                  component={CustomMulSelect}
                                  // validate={validateBuildings}
                                  name="building_ids"
                                  options={options}
                                  placeholder="Inspections"
                                  isReset={isReset}
                                />
                                <ErrorMessage name="building_ids">
                                  {(errMsg) => (
                                    <div className="text-red-500 mt-1 flex items-center">
                                      <ExclamationCircleOutlined />
                                      <span className="ml-1">{errMsg}</span>
                                    </div>
                                  )}
                                </ErrorMessage>
                              </div>
                            )}
                          </div>
                          {[...makeRoles([7, 10, 13])].includes(currentRole) && (
                            <div className="w-full px-3">
                              <label
                                className="block mb-2 text-xs font-bold tracking-wide text-primary uppercase"
                                htmlFor="regno"
                              >
                                Registration Number
                              </label>
                              <Field
                                type="text"
                                id="regno"
                                placeholder="Registration Number"
                                className="block w-full px-4 py-3 leading-tight text-primary bg-gray-50 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                                name="regno"
                                maxlength={12}
                              />
                              <ErrorMessage name="regno">
                                {(errMsg) => (
                                  <div className="text-red-500 mt-1 flex items-center">
                                    <ExclamationCircleOutlined />
                                    <span className="ml-1">{errMsg}</span>
                                  </div>
                                )}
                              </ErrorMessage>
                            </div>
                          )}
                        </div>

                        {/*footer*/}
                        <div className="flex items-center justify-between p-6 border-t border-solid rounded-b border-slate-200">
                          <button
                            className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear bg-gray-500 rounded shadow outline-none active:bg-gray-600 hover:shadow-lg focus:outline-none"
                            type="button"
                            onClick={handleCloseModal}
                          >
                            Close
                          </button>

                          <div className="flex gap-4 items-center">
                            <button
                              className="flex items-center px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear bg-red-500 rounded shadow outline-none active:bg-red-600 hover:shadow-lg focus:outline-none"
                              type="submit"
                            >
                              <span className="mr-2">Submit</span> <SaveOutlined />
                            </button>
                          </div>
                        </div>
                      </Form>
                    )
                  }}
                </Formik>
              </div>
            </div>
          </Modal>
        )}
      </>
    )
  }

  return (
    <div>
      {submitting && <LoadingSpinner />}

      <PaddingAuto>
        <>
          {[...makeRoles([1, 2])].includes(user?.user?.role) && (
            <div>
              <div className="border-0 border-b border-solid border-[#ebedec] pb-4 mt-2">
                <ul className="flex justify-start">
                  {tabUser.map((tab) => (
                    <li
                      key={tab.value}
                      onClick={() => {
                        setCurrentTab(tab.value)
                        navigate(tab.path)
                        handleNavigateParamsUrl({ page: 1, size: 10 }, true, tab.path)
                      }}
                      className={`w-28 cursor-pointer p-2 first:rounded-l-3xl font-semibold last:rounded-r-3xl text-center last:border-l last:border-white last:border-solid ${
                        location.pathname === `${tab.path}` ? 'bg-blue-500 text-white' : 'bg-gray-50 text-primary'
                      }`}
                    >
                      {tab.label}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
          {renderingProcess.loading ? (
            <>{renderSkeleton()}</>
          ) : renderingProcess.error ? (
            <NotAvailable content={errorsUser[userTypes.GET_USER]} />
          ) : (
            <Table
              tableParams
              columns={UserColumns}
              data={users}
              actions={actions}
              setShowModal={setShowModal}
              loading={loadingUser}
              tableOptions={tableOptions}
              paginations={paginationsOptions}
              // setTableOptions={setTableOptions}
              cellHeight={68}
              heightTable={'58vh'}
              pathname={currentTab === 1 ? '/users/claritas-user' : '/users/normal-user'}
            ></Table>
          )}
          {renderModalAddEdit()}
        </>
      </PaddingAuto>
    </div>
  )
}

export default UserManagement
