export const typeFile = [
  '.xbm',
  '.png',
  '.jpg',
  '.webp',
  '.tif',
  '.jfif',
  '.ico',
  '.tiff',
  '.gif',
  '.svg',
  '.jpeg',
  '.svgz',
  '.bmp',
  '.pjg',
  '.apng',
  '.pjpeg',
  '.avif'
]

export const typeFileSort = [
  'xbm',
  'png',
  'jpg',
  'webp',
  'tif',
  'jfif',
  'ico',
  'tiff',
  'gif',
  'svg',
  'jpeg',
  'svgz',
  'bmp',
  'pjg',
  'apng',
  'pjpeg',
  'avif'
]

export const typeFileLong = [
  'image/apng',
  'image/avif',
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'image/webp'
]
