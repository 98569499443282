import React from 'react'
import { format } from 'date-fns'
function CoverPage({ data, handleBreakLine, company, report }) {
  return (
    <>
      <ol className="toc-list" style={{ lineHeight: '30px' }}>
        <img src={company?.thumbnail} className="h-20 ml-auto mr-0" id="logo" alt="logo" />
        <li className="text-center text-2xl m-10">
          <div className="py-2 font-medium">VISUAL FACADE INSPECTION REPORT</div>
          <div className="py-2 font-medium">ON</div>
          <div className="py-6 text-center font-medium">{handleBreakLine(data?.address)}</div>

          <div className="pt-20">DOCUMENT PREPARATION RECORD</div>

          <div className="py-10" style={{ fontSize: '16px', lineHeight: 'normal' }}>
            <table className="border-[1px] w-full" style={{ fontSize: '12px' }}>
              <tr className="border-[1px]">
                <td className="border-[1px] p-2 w-16">Rev</td>
                <td className="border-[1px] p-2">Date</td>
                <td className="border-[1px] p-2">Prepared By</td>
                <td className="border-[1px] p-2">Description</td>
              </tr>

              {report?.record?.map((records, index) => (
                <tr className="border-[1px]">
                  <td className="border-[1px] p-2">{index + 1}.0</td>
                  <td className="border-[1px] p-2">{format(new Date(records.date), 'dd/MM/yyyy')}</td>
                  <td className="border-[1px] p-2">{records?.name}</td>
                  <td className="border-[1px] p-2">{records?.description}</td>
                </tr>
              ))}
            </table>
          </div>
        </li>
      </ol>
      <div className="mt-[12rem] text-center leading-8">
        <p>
          {company?.name} (UEN: {company?.uen})
        </p>
        <p>{company?.address} </p>
        <p>
          Tel: {company?.telephone} <span className="ml-4"> Fax: {company?.fax}</span>
        </p>
        <p></p>
        <p>Email: {company?.email}</p>
      </div>
    </>
  )
}

export default CoverPage
