import { createSlice } from '@reduxjs/toolkit'
import { changePassword } from '../thunks/authThunk'
import authTypes from '../../constant/store/authTypes'

const elevationSlice = createSlice({
  name: 'auth',
  initialState: {
    loadings: {},
    errors: {},
    contents: {},
    paginations: {}
  },
  extraReducers: {
    // ACTION TYPE:change Password
    [changePassword.pending]: (state) => {
      state.loadings[authTypes.CHANGE_PASSWORD] = true
    },
    [changePassword.fulfilled]: (state, { payload }) => {
      state.loadings[authTypes.CHANGE_PASSWORD] = false
      state.errors[authTypes.CHANGE_PASSWORD] = ''
    },
    [changePassword.rejected]: (state, { payload }) => {
      state.loadings[authTypes.CHANGE_PASSWORD] = false
      state.errors[authTypes.CHANGE_PASSWORD] = payload.message
    }
  }
})
export default elevationSlice.reducer
