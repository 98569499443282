import React, { useState } from 'react'
import { Link } from 'react-router-dom'

// hocs
import useAuth from '../../hooks/useAuth'

// @antd
import { EyeOutlined, EyeInvisibleOutlined, ExclamationCircleOutlined } from '@ant-design/icons'

// cpn
import AuthLayout from '../../components/Layout/AuthLayout'

// assets
import LoadingSpinner from '../Common/loading-spinner/LoadingSpinner'
import { useMemo } from 'react'
import { useEffect } from 'react'

function Login() {
  const { signIn, submitting } = useAuth()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [inputError, setInputError] = useState({
    email: "",
    password: ""
  })
  const [_mounted, setMounted] = useState(false)

  useMemo(() => {
    setMounted(true)
  }, [])

  useEffect(() => {
    return () => {
      setMounted(false)
    }
  }, [_mounted])

  const [typePassword, setTypePassword] = useState('password')
  const [iconPassword, setIconPassword] = useState('hide')

  const handleSubmit = (e) => {
    if (!_mounted) return
    e.preventDefault()
    const newEmail = email.trim()
    const newPassword = password.trim()
    if (!newEmail || !newPassword) {
      let errors = {}
      if (!newEmail) {
        errors = { ...errors, email: 'Please enter your email' }
      }
      if (!newPassword) {
        errors = { ...errors, password: 'Please enter password' }
      }
      setInputError(errors)
      return false
    }
    signIn(newEmail.toLowerCase(), newPassword.trim())
  }

  const handleToggle = () => {
    if (typePassword === 'password') {
      setIconPassword('show')
      setTypePassword('text')
    } else {
      setIconPassword('hide')
      setTypePassword('password')
    }
  }

  const onHandleKeydown = (e) => {
    if (e.which === 32 && e.target.selectionStart === 0) {
      return false
    }
  }

  return (
    <>
      {submitting && <LoadingSpinner />}
      <AuthLayout>
        <form onSubmit={handleSubmit} className="px-10 pt-4 pb-4 mb-4">
          <p className="my-5 text-3xl font-bold">Sign in to your account</p>
          <div className="mb-4">
            <label className="block mb-2 text-sm font-bold text-primary" htmlFor="username">
              User name
            </label>
            <input
              className="w-full px-3 py-2 leading-tight text-primary border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
              id="username"
              type="text"
              placeholder="Username"
              value={email}
              onChange={(e) => {
                setEmail(e.currentTarget.value)
                setInputError({ ...inputError, email: "" })
              }}
            />
            {inputError.email && <div className="text-red-500 mt-1 flex items-center">
              <ExclamationCircleOutlined />
              <span className="ml-1">{inputError.email}</span>
            </div>}
          </div>
          <div className="mb-6">
            <label className="block mb-2 text-sm font-bold text-primary" htmlFor="password">
              Password
            </label>
            <div className="flex items-center w-full px-3 py-2 bg-white leading-tight text-primary border rounded shadow appearance-none focus:outline-none focus:shadow-outline">
              <input
                type={typePassword}
                id="password"
                placeholder="Password"
                className="h-full w-full border-0 outline-none"
                name="password"
                onChange={(e) => {
                  setPassword(e.currentTarget.value)
                  setInputError({ ...inputError, password: "" })
                }}
              />
              <span onClick={handleToggle} className="cursor-pointer">
                {iconPassword === 'hide' ? (
                  <EyeOutlined style={{ fontSize: 20 }} />
                ) : (
                  <EyeInvisibleOutlined style={{ fontSize: 20 }} />
                )}
              </span>
            </div>
            {inputError.password && <div className="text-red-500 mt-1 flex items-center">
              <ExclamationCircleOutlined />
              <span className="ml-1">{inputError.password}</span>
            </div>}
          </div>
          <div className="flex items-center justify-between">
            <button
              className="px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline"
              type="submit"
              disabled={submitting}
            >
              Sign In
            </button>

            <Link
              className="inline-block text-sm font-bold text-blue-500 align-baseline hover:text-blue-800"
              to="/forget_password"
            >
              Forgot Password?
            </Link>
          </div>
        </form>
        <p className="text-xs text-center text-gray-500">&copy;2023 Axpara. All rights reserved.</p>
      </AuthLayout >
    </>
  )
}

export default Login
