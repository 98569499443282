import { useCallback } from 'react'
import useBlockNavigate from '../../hooks/useBlockNavigate'
import useConfirmation from '../../hooks/useConfirmation'
import Modal from '../Common/Modal'
import { AiOutlineClose } from 'react-icons/ai'
import PropTypes from 'prop-types'

const CustomPrompt = ({ when = true, message, title }) => {
  const { isOpen, proceed, cancel, onConfirm, hasConfirmed, resetConfirmation } = useConfirmation()
  let blocker = useCallback(
    async (tx) => {
      if (await onConfirm()) {
        if (tx.action === 'POP') {
          tx.retry()
          return
        } else {
          resetConfirmation()
          tx.retry()
        }
      }
    },
    [resetConfirmation, onConfirm]
  )

  useBlockNavigate(blocker, when && !hasConfirmed)
  if (!isOpen) return false
  return (
    <Modal wrapper="w-auto max-w-md mx-auto" onClose={cancel}>
      <div className="w-88">
        <div className="flex items-center justify-between p-3 border-b border-solid rounded-t border-slate-200">
          <div className="text-xl text-primary font-semibold ">{title}</div>
          <span className="block w-6 h-6 ">
            <AiOutlineClose style={{ fontSize: '22px', cursor: 'pointer' }} onClick={cancel} />
          </span>
        </div>
        {/*body*/}
        <div className="relative flex flex-auto p-6">
          <div className="w-full max-w-lg">{message}</div>
        </div>
        <div className="flex items-center justify-between p-6 border-t border-solid rounded-b border-slate-200">
          <button
            className="px-6 py-3 mb-1 mr-1 text-md font-bold cursor-pointe text-white uppercase transition-all duration-150 ease-linear bg-blue-button rounded shadow outline-none active:bg-gray-600 hover:shadow-lg focus:outline-none"
            type="button"
            onClick={cancel}
          >
            Stay on Page
          </button>
          <button
            className="px-6 py-3 mb-1 mr-1 text-sm font-bold cursor-pointer text-white uppercase transition-all duration-150 ease-linear bg-red-500 rounded shadow outline-none active:bg-gray-600 hover:shadow-lg focus:outline-none"
            type="button"
            onClick={proceed}
          >
            Leave Page
          </button>
        </div>
      </div>
    </Modal>
  )
}

CustomPrompt.prototype = {
  title: PropTypes.string,
  message: PropTypes.string,
  when: PropTypes.bool
}

export default CustomPrompt
