import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation, createSearchParams } from 'react-router-dom'
import { onConfirm } from 'react-confirm-pro'
import { toast } from 'react-toastify'
import { format } from 'date-fns'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'
// hocs
import useAuth from '../../../hooks/useAuth'
import usePrevious from '../../../hooks/usePrevious'
import CustomSelect from '../../Common/CustomSelect'

// actions
import {
  getBuildings,
  createBuilding,
  updateBuilding,
  deleteBuilding,
  getRoleBuilding,
  getBuildingsActive,
  changeStatusBuilding,
  revertDeleteBuilding
} from '../../../store/thunks/buildingThunk'

import { addInspector } from '../../../store/thunks/reportThunk'
import { getCompanyActive } from '../../../store/thunks/companyThunk'

// commons
import Table from '../../Common/table2'
import { claritasAdmin, claritasRoles } from '../../../constant/auth'
import { BsCameraFill } from 'react-icons/bs'
import Modal from '../../Common/Modal'
import { isEmptyObject, makeRoles, makeStatusInspection } from '../../../utils/common'
import StatusButton from '../../Common/status'
import SkeletonViewer from '../../Common/skeleton-viewer'

// antd
import {
  SaveOutlined,
  ExclamationCircleOutlined,
  FieldTimeOutlined,
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  DownSquareOutlined,
  RedoOutlined
} from '@ant-design/icons'

// assets

// variables
import { color } from '../../../constant/styled'
import inspectionTypes from '../../../constant/store/inspectionTypes'
import companyTypes from '../../../constant/store/companyTypes'
import CustomMulSelect from '../../Common/CustomMulSelect'
import LoadingSpinner from '../../Common/loading-spinner/LoadingSpinner'
import NotAvailable from '../../Common/NotAvailable'
import PreviewImage from '../../Common/PreviewImage'
import PaddingAuto from '../../Layout/PaddingAuto'
import Cropper2 from '../../Common/cropper2/Cropper'

const BuildingManagement = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  const { user } = useAuth()
  const { role } = user?.user || {}
  const [_mounted, setMouted] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const { contents, loadings, errors, paginations: paginationsBuilding } = useSelector((state) => state.building)
  const { contents: contentsCompany } = useSelector((state) => state.company)
  const companies = contentsCompany[companyTypes.GET_COMPANY_ACTIVE]
  const [showModal, setShowModal] = useState(false)
  const [currentFile, setCurrentFile] = useState(null)

  const [currentInspection, setCurrentInspection] = useState(null)
  const [defaultInspector, setDefaultInspector] = useState([])
  const [currentStatusId, setCurrentStatusId] = useState(null)
  const [tableOptions, setTableOptions] = useState({})
  const [renderingProcess, setRenderingProcess] = useState({
    loading: true,
    error: false
  })
  const cropperRef = useRef()

  const loadingBuilding = loadings[inspectionTypes.GET_BUILDINGS]
  const prevBuildingGet = usePrevious(loadings[inspectionTypes.GET_BUILDINGS])
  const prevUpdateAvatar = usePrevious(loadings[inspectionTypes.UPDATE_BUIDING_AVATAR])
  const prevBuildingCreateFetching = usePrevious(loadings[inspectionTypes.CREATE_BUIDING])
  const prevBuildingUpdateFetching = usePrevious(loadings[inspectionTypes.UPDATE_BUIDING])
  const prevBuildingDeleteFetching = usePrevious(loadings[inspectionTypes.DELETE_BUIDING])
  const prevBuildingRevertFetching = usePrevious(loadings[inspectionTypes.REVERT_DELETE_BUILDING])
  const paginationsOptions = paginationsBuilding[inspectionTypes.GET_BUILDINGS]

  const handleEditInspection = (id) => {
    setShowModal(true)
    const indexInspection = contents[inspectionTypes.GET_BUILDINGS].findIndex((company) => company.id === id)
    const listInspector = contents[inspectionTypes.GET_BUILDINGS][indexInspection]?.fis

    setCurrentInspection(contents[inspectionTypes.GET_BUILDINGS][indexInspection])
    if (contents[inspectionTypes.GET_BUILDINGS][indexInspection].inspectors?.length > 0) {
      const tempDefaultListInspectors = listInspector?.filter((value) =>
        contents[inspectionTypes.GET_BUILDINGS][indexInspection].inspectors.includes(value.value)
      )

      setDefaultInspector(tempDefaultListInspectors)
    }
  }

  const handleOpenCropper = useCallback(() => {
    cropperRef?.current?.openCropModal()
  }, [cropperRef])

  const handleSetAvatar = async (file) => {
    setCurrentFile(file)
  }

  const types = contents[inspectionTypes.GET_ROLE_BUILDING]

  const actions = [
    {
      id: 1,
      title: 'Inspection',
      isShow: [...makeRoles([1, 2])].includes(role)
    }
  ]

  const BuildingColumns = [
    {
      Header: 'Building Image',
      accessor: 'thumbnail',
      id: 'thumbnail',
      width: 140,
      Cell: (tableProps) => (
        <div
          className="w-16 h-12 rounded-md shadow-avatar"
          style={{
            backgroundImage: `url(${tableProps.row.original.thumbnail})`,
            display: 'block',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPositionX: 'center',
            backgroundPositionY: '10%'
          }}
        ></div>
      ),
      disableSortBy: true
    },
    {
      Header: 'Inspection Name',
      accessor: 'name',
      id: 'name',
      width: 160
    },
    {
      Header: 'Address',
      accessor: 'address',
      width: 120
    },
    {
      Header: 'Type',
      accessor: 'type_name',
      id: 'type',
      width: 120
    },
    {
      Header: 'Company',
      role: claritasRoles,
      accessor: 'company_name',
      id: 'company'
    },
    {
      Header: 'Date Created',
      accessor: 'created_at',
      id: 'created_at',
      minWidth: 120,
      Cell: ({ value }) => {
        const date = format(new Date(value), 'dd/MM/yyyy')
        return (
          <div className="relative">
            <div className="flex items-center">
              <FieldTimeOutlined style={{ fontSize: '18px' }} className="group:hover:text-white" />
              <span className="ml-2">{date}</span>
            </div>
          </div>
        )
      }
    },
    {
      Header: 'Status',
      accessor: 'status',
      id: 'status',
      width: 160,
      Cell: ({ value }) => {
        return (
          <>
            {value === 'Active' ? (
              <StatusButton color={color.green} bgColor={color.greenLight} title="Active" />
            ) : value === 'Completed' ? (
              <StatusButton color={color.blue} bgColor={color.blueLight} title="Completed" />
            ) : value === 'Deleted' ? (
              <StatusButton color={color.red} bgColor={color.redLight} title="Deleted" />
            ) : value === 'Archived' ? (
              <StatusButton color={color.yellow} bgColor={color.yellowLight} title="Archived" />
            ) : (
              ''
            )}
          </>
        )
      }
    },
    {
      Header: 'Actions',
      hideHeader: true,
      disableSortBy: true,
      width: 180,
      maxWidth: 180,
      Cell: (tableProps) => {
        const { id, status } = tableProps.row?.original

        return (
          <>
            {status !== 'Deleted' ? (
              <div className="flex items-center gap-2">
                {[...makeRoles([1, 2, 6, 7])].includes(role) ? (
                  <div
                    className="mark_status_building relative"
                    onMouseEnter={() => setCurrentStatusId(id)}
                    onMouseLeave={() => setCurrentStatusId(null)}
                  >
                    <div
                      className={`inline-flex items-center font-bold py-2 px-3 rounded-lg bg-view-detail group-hover:bg-white group-hover:text-blue-button cursor-pointer`}
                    >
                      <DownSquareOutlined
                        style={{
                          fontSize: '20px',
                          cursor: 'pointer',
                          color: color.gray
                        }}
                      />
                    </div>

                    {id === currentStatusId && (
                      <div className="z-10  bg-white divide-y divide-gray-100 rounded-lg shadow w-32 dark:bg-gray-700 dark:divide-gray-600 absolute top-10 right-0">
                        <ul className="py-2 text-sm text-primary dark:text-gray-200">
                          {makeStatusInspection(status).map((val) => {
                            return (
                              <li
                                className="cursor-pointer"
                                key={val}
                                onClick={() => dispatch(changeStatusBuilding({ id, status: val }))}
                              >
                                <div className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                  {val}
                                </div>
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    )}
                  </div>
                ) : (
                  <></>
                )}

                {status !== 'Completed' &&
                status !== 'Archived' &&
                [...makeRoles([1, 2, 3, 6, 7, 8, 10, 12, 13])].includes(role) ? (
                  <div className="relative">
                    <div
                      className="inline-flex items-center font-bold py-2 px-3 rounded-lg bg-view-detail group-hover:bg-white group-hover:text-blue-button cursor-pointer"
                      onClick={() => handleEditInspection(id)}
                    >
                      <EditOutlined
                        style={{
                          fontSize: '20px',
                          cursor: 'pointer',
                          color: color.green
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                {[...makeRoles([1, 2, 6, 7])].includes(role) ? (
                  <div
                    className="inline-flex items-center font-bold py-2 px-3 rounded-lg bg-view-detail group-hover:bg-white group-hover:text-blue-button cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation()
                      onConfirm({
                        title: <h3 className="text-center">Are you sure you want to delete this inspection?</h3>,
                        description: <br />,
                        onSubmit: async () => {
                          dispatch(deleteBuilding(id))
                        },
                        onCancel: () => {},
                        buttons: ({ onSubmit, onCancel }) => (
                          <div className="custom-buttons">
                            <button
                              style={{
                                background: 'rgba(37, 99, 235)',
                                color: 'white'
                              }}
                              onClick={onSubmit}
                            >
                              YES
                            </button>
                            <button
                              style={{
                                background: 'rgba(220, 38, 38)',
                                color: 'white'
                              }}
                              onClick={onCancel}
                            >
                              NO
                            </button>
                          </div>
                        )
                      })
                    }}
                  >
                    <DeleteOutlined
                      style={{
                        fontSize: '20px',
                        cursor: 'pointer',
                        color: color.red
                      }}
                      alt="delete"
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <div className="flex gap-2">
                <div className="relative">
                  {[...makeRoles([1, 2])].includes(role) ? (
                    <div
                      className="inline-flex items-center font-bold py-2 px-3 rounded-lg bg-view-detail group-hover:bg-white group-hover:text-blue-button cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation()
                        onConfirm({
                          title: (
                            <h3 className="text-center">Are you sure you want to restore this deleted inspection?</h3>
                          ),
                          description: <br />,
                          onSubmit: async () => {
                            dispatch(revertDeleteBuilding({ id }))
                          },
                          onCancel: () => {},
                          buttons: ({ onSubmit, onCancel }) => (
                            <div className="custom-buttons">
                              <button
                                style={{
                                  background: 'rgba(37, 99, 235)',
                                  color: 'white'
                                }}
                                onClick={onSubmit}
                              >
                                YES
                              </button>
                              <button
                                style={{
                                  background: 'rgba(220, 38, 38)',
                                  color: 'white'
                                }}
                                onClick={onCancel}
                              >
                                NO
                              </button>
                            </div>
                          )
                        })
                      }}
                    >
                      <RedoOutlined
                        style={{
                          fontSize: '20px',
                          cursor: 'pointer',
                          color: color.blue
                        }}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            )}
          </>
        )
      }
    },
    {
      Header: 'Action',
      width: 180,
      maxWidth: 180,
      hideHeader: true,
      disableSortBy: true,
      Cell: (tableProps) => {
        const { id, status } = tableProps.row?.original
        return (
          <>
            {status !== 'Deleted' && status !== 'Archived' && (
              <div
                className="inline-flex items-center font-bold py-2 px-3 rounded-lg bg-view-detail group-hover:bg-white group-hover:text-blue-button cursor-pointer"
                onClick={() => navigate(`/inspections/${id}`)}
              >
                <EyeOutlined style={{ fontSize: '18px' }} alt="eye" className="mr-1" />
                Details
              </div>
            )}
          </>
        )
      }
    }
  ]

  const initialValues = {
    company: currentInspection?.company_id || (companies && companies[0]?.id),
    name: currentInspection?.name,
    address: currentInspection?.address,
    inspectionType: currentInspection?.type_id || (types && types[0].value),
    competentPerson: currentInspection?.cp_id || null,
    thumbnail: null,
    inspector: defaultInspector
  }

  function onSubmitAdd(values, onSubmitProps) {
    setSubmitting(true)
    const { company, name, address, inspectionType } = values
    try {
      const dataReq = {
        company: parseInt(company) || user?.company?.id,
        name: name.trim(),
        address: address.trim(),
        file: currentFile,
        type_id: parseInt(inspectionType),
        isUpload: currentFile ? true : false
      }
      dispatch(createBuilding(dataReq))
    } catch (err) {
      toast.error('Error when creating building')
    }
  }

  function onSubmitUpdate(values, onSubmitProps) {
    setSubmitting(true)
    const { company, name, address, inspectionType, inspector, competentPerson } = values
    const uploadFile = currentFile ? true : currentInspection.thumbnail ? false : true
    try {
      const dataReq = {
        addInspector: {
          building_id: currentInspection.id,
          inspector_ids: inspector ? inspector.map((item) => item.value) : null,
          cp_id: competentPerson
        },
        updateBuilding: {
          company: parseInt(company) || user?.company?.id,
          name: name.trim(),
          address: address.trim(),
          file: currentFile || currentInspection.thumbnail || '',
          id: parseInt(currentInspection?.id),
          type: parseInt(inspectionType) || currentInspection.type.trim(),
          status: currentInspection.status.trim(),
          cp_id: competentPerson,
          upload: uploadFile
        }
      }

      if (!currentInspection.locked && [...makeRoles([1, 2, 6, 7])].includes(role)) {
        dispatch(addInspector(dataReq))
      } else {
        dispatch(updateBuilding(dataReq.updateBuilding))
      }
    } catch (err) {
      toast.error('Error when updating inspection')
    }
  }

  const handleCloseModal = () => {
    setShowModal(false)
    setCurrentFile(null)
    setCurrentInspection(null)
    setDefaultInspector([])
  }

  const validationSchema = Yup.object({
    name: Yup.string().required('Please enter inspection name'),
    address: Yup.string().required('Please enter inspection address'),
    inspectionType: Yup.string().required('Please select inspection type')
  }).shape({
    thumbnail: currentInspection?.thumbnail
      ? Yup.mixed().nullable()
      : // .test(
        //   'Fichier taille',
        //   'The maximum size for the uploads is 2MB',
        //   (value) => !value || (value && value.size <= 2048 * 1024)
        // )
        Yup.mixed().nullable()
    // .test(
    //   'Fichier taille',
    //   'The maximum size for the uploads is 2MB',
    //   (value) => !value || (value && value.size <= 2048 * 1024)
    // )
  })

  const validationCompany = (company) => {
    let error
    if (claritasAdmin.includes(role) && !company) {
      error = 'Please select your company'
    }
    return error
  }

  useEffect(() => {
    if (prevBuildingGet && !loadings[inspectionTypes.GET_BUILDINGS]) {
      if (!errors[inspectionTypes.GET_BUILDINGS]) {
        setRenderingProcess((prev) => ({ ...prev, error: false, loading: false }))
      } else {
        setRenderingProcess((prev) => ({ ...prev, error: true, loading: false }))
      }
    }
  }, [errors, loadings, prevBuildingGet])

  useEffect(() => {
    if (prevUpdateAvatar && !loadings[inspectionTypes.UPDATE_BUIDING_AVATAR]) {
      setSubmitting(false)
      if (!errors[inspectionTypes.UPDATE_BUIDING_AVATAR]) {
        const indexInspection = contents[inspectionTypes.GET_BUILDINGS].findIndex(
          (company) => company.id === currentInspection.id
        )
        setCurrentInspection(contents[inspectionTypes.GET_BUILDINGS][indexInspection])
        toast.success('Avatar updated', {
          theme: 'dark',
          autoClose: 2000
        })
      } else {
        toast.error(errors[inspectionTypes.UPDATE_BUIDING_AVATAR], {
          theme: 'dark',
          autoClose: 2000
        })
      }
    }
  }, [loadings, errors, prevUpdateAvatar])

  useEffect(() => {
    if (prevBuildingCreateFetching && !loadings[inspectionTypes.CREATE_BUIDING]) {
      setSubmitting(false)
      if (!errors[inspectionTypes.CREATE_BUIDING]) {
        setShowModal(false)
        setCurrentFile(null)
        setCurrentInspection(null)
        setDefaultInspector([])
        handleNavigateParamsUrl({ page: 1, size: 10 }, false, '/inspections')
        toast.success('Building Created', {
          theme: 'dark',
          autoClose: 2000
        })
      } else {
        toast.error(errors[inspectionTypes.CREATE_BUIDING], {
          theme: 'dark',
          autoClose: 2000
        })
      }
    }
  }, [errors, loadings, prevBuildingCreateFetching])

  useEffect(() => {
    if (prevBuildingUpdateFetching && !loadings[inspectionTypes.UPDATE_BUIDING]) {
      setSubmitting(false)
      if (!errors[inspectionTypes.UPDATE_BUIDING]) {
        setShowModal(false)
        setCurrentFile(null)
        setCurrentInspection(null)
        setDefaultInspector([])
        toast.success('Building Updated', {
          theme: 'dark',
          autoClose: 2000
        })
      } else {
        toast.error(errors[inspectionTypes.UPDATE_BUIDING], {
          theme: 'dark',
          autoClose: 2000
        })
      }
    }
  }, [errors, loadings, prevBuildingUpdateFetching])

  useEffect(() => {
    if (prevBuildingDeleteFetching && !loadings[inspectionTypes.DELETE_BUIDING]) {
      if (!errors[inspectionTypes.DELETE_BUIDING]) {
        setTableOptions({ page: 1, size: 10, ...tableOptions })
        toast.success('Building Deleted', {
          theme: 'dark',
          autoClose: 2000
        })
      } else {
        toast.error(errors[inspectionTypes.DELETE_BUIDING], {
          theme: 'dark',
          autoClose: 2000
        })
      }
    }
  }, [errors, loadings, prevBuildingDeleteFetching])

  useEffect(() => {
    if (prevBuildingRevertFetching && !loadings[inspectionTypes.REVERT_DELETE_BUILDING]) {
      if (!errors[inspectionTypes.REVERT_DELETE_BUILDING]) {
        toast.success('Building restored', {
          theme: 'dark',
          autoClose: 2000
        })
      } else {
        toast.error(errors[inspectionTypes.REVERT_DELETE_BUILDING], {
          theme: 'dark',
          autoClose: 2000
        })
      }
    }
  }, [errors, loadings, prevBuildingRevertFetching])

  useMemo(() => {
    setMouted(true)
  }, [])

  // get data table

  const handleNavigateParamsUrl = (params, replace = false, pathname) => {
    navigate(
      {
        pathname: pathname,
        search: createSearchParams({
          page: params.page,
          size: params.size,
          ...params
        }).toString()
      },
      { replace }
    )
  }

  useEffect(() => {
    if (!location.search) {
      handleNavigateParamsUrl({ page: 1, size: 10 }, true, '/inspections')
    }
  }, [])

  useEffect(() => {
    if (_mounted && location.search) {
      const params = Object.fromEntries(new URLSearchParams(window.location.search))
      const options = {
        ...params,
        ...(params.page ? { page: params.page } : { page: 1 }),
        ...(params.size && params.size >= 10 ? { size: params.size } : { size: 10 })
      }
      setTableOptions(options)
    }
  }, [location.search])

  useEffect(() => {
    const get = async () => {
      if (_mounted && location.search && !isEmptyObject(tableOptions)) {
        dispatch(getBuildings(tableOptions))
      }
    }
    get()
  }, [dispatch, user, tableOptions])

  // end get data table

  useEffect(() => {
    return () => {
      setMouted(false)
    }
  }, [])

  useEffect(() => {
    const get = async () => {
      dispatch(getRoleBuilding())
    }
    get()
  }, [dispatch])

  useEffect(() => {
    const get = async () => {
      if ([...makeRoles([1, 2, 4])].includes(role)) {
        dispatch(getCompanyActive())
      }
    }
    get()
  }, [dispatch, user])

  useEffect(() => {
    const get = async () => {
      if ([...makeRoles([1, 2, 6, 7])].includes(role)) {
        dispatch(getBuildingsActive())
      }
    }
    get()
  }, [dispatch, user])

  const renderSkeleton = () => {
    return (
      <div style={{ width: '100%', margin: '0 auto', marginTop: '16px' }}>
        <div className="flex items-center flex-wrap gap-2 md:gap-6">
          {actions &&
            Array.from({ length: actions.length + 1 }, function (v, k) {
              return k
            }).map((i) => (
              <div key={i}>
                <SkeletonViewer borderRadius={16} className="h-8 md:h-10 w-32 md:w-44" />
              </div>
            ))}
          <div className="sm:ml-auto">
            <SkeletonViewer borderRadius={16} className="h-8 md:h-10 w-[90vw] sm:w-40 md:w-56 lg:w-72" />
          </div>
        </div>
        <div>
          <SkeletonViewer className="mt-2" height={60} width="100%" />
          <div className="block overflow-hidden mt-1" style={{ height: '66vh' }}>
            {Array.from({ length: 10 }, function (v, k) {
              return k
            }).map((i) => (
              <div key={i}>
                <SkeletonViewer className="mt-2" height={96} borderRadius={12} width="100%" />
              </div>
            ))}
          </div>
        </div>
        <div className="flex justify-center">
          <SkeletonViewer className="h-8 md:h-10 mt-4" width={300} />
        </div>
      </div>
    )
  }

  const renderModalAddEdit = () => {
    return (
      <>
        {showModal && (
          <Modal wrapper="w-auto max-w-md mx-auto" onClose={handleCloseModal}>
            <div className="flex items-start justify-between p-3 border-b border-solid rounded-t border-slate-200">
              <div className="text-xl text-primary font-semibold ">
                {currentInspection ? 'Edit Inspection' : 'Create Inspection'}
              </div>
            </div>
            <div className="relative flex flex-auto p-6">
              <div className="w-full max-w-lg">
                <Formik
                  initialValues={initialValues}
                  onSubmit={!currentInspection ? onSubmitAdd : onSubmitUpdate}
                  validationSchema={validationSchema}
                >
                  {({ values, setFieldValue }) => {
                    return (
                      <Form className="w-full  max-w-lg">
                        <div className="flex flex-wrap mb-3 max-h-[68vh] overflow-y-auto overflow-x-hidden">
                          <div className="w-full px-3">
                            <div className="mb-6">
                              <div className="relative mt-1 rounded-md group h-[120px] w-[160px] shadow-avatar">
                                {currentFile ? (
                                  <PreviewImage file={currentFile} imgClass="w-full h-full rounded-md ml-0" />
                                ) : currentInspection ? (
                                  <div
                                    className="w-full h-full rounded-md ml-0"
                                    style={{
                                      backgroundImage: `url(${currentInspection?.thumbnail})`,
                                      display: 'block',
                                      backgroundRepeat: 'no-repeat',
                                      backgroundSize: 'cover',
                                      backgroundPositionX: 'center',
                                      backgroundPositionY: '10%'
                                    }}
                                  ></div>
                                ) : (
                                  <img
                                    className="w-full h-full rounded-md ml-0 "
                                    src={process.env.REACT_APP_AWS_BLANK_IMAGE}
                                    alt="avt"
                                  />
                                )}
                                <div
                                  className="flex justify-center items-center absolute z-20 bottom-1 right-1 w-[32px] h-[32px] bg-gray-300 rounded-full cursor-pointer"
                                  onClick={handleOpenCropper}
                                >
                                  <BsCameraFill style={{ fontSize: '20px', color: 'black' }} />
                                </div>
                                <Cropper2
                                  ref={cropperRef}
                                  name="avatar"
                                  cropShape="rect"
                                  onCompleteCropper={(file) => {
                                    handleSetAvatar(file)
                                    cropperRef?.current?.closeCropModal()
                                  }}
                                  setLoading={setSubmitting}
                                />
                                <div className="absolute z-10 bottom-0 bg-gray-300 opacity-10 cursor-pointer w-[160px] h-[120px] rounded-md hidden group-hover:block"></div>
                              </div>
                            </div>
                          </div>
                          {!currentInspection && [...makeRoles([1, 2, 4])].includes(role) && (
                            <div className="w-full px-3 mb-3">
                              <label
                                className="block mb-2 text-xs font-bold tracking-wide text-primary uppercase"
                                htmlFor="companies_selection"
                              >
                                Select Company
                              </label>
                              <Field
                                name="company"
                                as="select"
                                validate={validationCompany}
                                id="company"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full h-11 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              >
                                <option disabled value="">
                                  Select a company
                                </option>
                                {user && companies?.length > 0 ? (
                                  companies?.map((c) => (
                                    <option key={c.id} value={c.id}>
                                      {c.name}
                                    </option>
                                  ))
                                ) : (
                                  <option>aaaa</option>
                                )}
                              </Field>
                              <ErrorMessage name="company">
                                {(errMsg) => (
                                  <div className="text-red-500 mt-1 flex items-center">
                                    <ExclamationCircleOutlined />
                                    <span className="ml-1">{errMsg}</span>
                                  </div>
                                )}
                              </ErrorMessage>
                            </div>
                          )}
                          {currentInspection && (
                            <div className="w-full px-3 mb-3">
                              <label
                                className="block mb-2 text-xs font-bold tracking-wide text-primary uppercase"
                                htmlFor="companies_selection"
                              >
                                Company name
                              </label>
                              <div>{currentInspection.company_name}</div>
                            </div>
                          )}
                          <div className="w-full px-3 mb-3">
                            {/* {currentInspection ? ( */}
                            <>
                              <label
                                className="block mb-2 text-xs font-bold tracking-wide text-primary uppercase"
                                htmlFor="companies_selection"
                              >
                                Select inspection type
                              </label>
                              <CustomSelect
                                className="input"
                                onChange={({ value }) => {
                                  setFieldValue('inspectionType', value)
                                }}
                                options={types}
                                value={values?.inspectionType}
                              />
                              <ErrorMessage name="inspectionType">
                                {(errMsg) => (
                                  <div className="text-red-500 mt-1 flex items-center">
                                    <ExclamationCircleOutlined />
                                    <span className="ml-1">{errMsg}</span>
                                  </div>
                                )}
                              </ErrorMessage>
                            </>
                          </div>

                          <div className="w-full px-3 mb-3">
                            <label
                              className="block mb-2 text-xs font-bold tracking-wide text-primary uppercase"
                              htmlFor="building_name"
                            >
                              Inspection Name
                            </label>
                            <Field
                              type="text"
                              id="name"
                              placeholder="Name"
                              className="block w-full px-4 py-3 leading-tight text-primary bg-gray-50 border rounded appearance-none focus:outline-none focus:bg-white"
                              name="name"
                            />
                            <ErrorMessage name="name">
                              {(errMsg) => (
                                <div className="text-red-500 mt-1 flex items-center">
                                  <ExclamationCircleOutlined />
                                  <span className="ml-1">{errMsg}</span>
                                </div>
                              )}
                            </ErrorMessage>
                          </div>
                          <div className="w-full px-3 mb-3">
                            <label
                              className="block mb-2 text-xs font-bold tracking-wide text-primary uppercase"
                              htmlFor="address"
                            >
                              Address
                            </label>
                            <Field
                              type="text"
                              id="address"
                              placeholder="Address"
                              className="block w-full px-4 py-3 leading-tight text-primary bg-gray-50 border rounded appearance-none focus:outline-none focus:bg-white"
                              name="address"
                            />
                            <ErrorMessage name="address">
                              {(errMsg) => (
                                <div className="text-red-500 mt-1 flex items-center">
                                  <ExclamationCircleOutlined />
                                  <span className="ml-1">{errMsg}</span>
                                </div>
                              )}
                            </ErrorMessage>
                          </div>
                          {/* !currentInspection.locked */}
                          {currentInspection && [...makeRoles([1, 2, 6, 7])].includes(role) && (
                            <div className="w-full px-3 mb-3">
                              <label
                                className="block mb-2 text-xs font-bold tracking-wide text-primary uppercase"
                                htmlFor="inspector"
                              >
                                Inspector
                              </label>
                              <Field
                                defaultValue={defaultInspector}
                                component={CustomMulSelect}
                                name="inspector"
                                isDisabled={currentInspection?.locked}
                                className="input"
                                options={currentInspection?.fis || []}
                                placeholder="Inspectors"
                              />
                              <ErrorMessage name="inspector">
                                {(errMsg) => (
                                  <div className="text-red-500 mt-1 flex items-center">
                                    <ExclamationCircleOutlined />
                                    <span className="ml-1">{errMsg}</span>
                                  </div>
                                )}
                              </ErrorMessage>
                            </div>
                          )}

                          {currentInspection && [...makeRoles([1, 2, 6, 7])].includes(role) && (
                            <div className="w-full px-3 mb-3">
                              <>
                                <label
                                  className="block mb-2 text-xs font-bold tracking-wide text-primary uppercase"
                                  htmlFor="companies_selection"
                                >
                                  COMPETENT PERSON
                                </label>
                                <CustomSelect
                                  className="input"
                                  onChange={({ value }) => {
                                    setFieldValue('competentPerson', value)
                                  }}
                                  options={currentInspection.cps || []}
                                  // value={values?.competentPerson || currentInspection.cps[0]?.value}
                                  value={values?.competentPerson}
                                  isDisabled={currentInspection.locked}
                                />
                              </>
                            </div>
                          )}
                        </div>
                        <div className="flex items-center justify-between px-4 pt-4 border-t border-solid rounded-b border-slate-200">
                          <button
                            className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear bg-gray-500 rounded shadow outline-none active:bg-gray-600 hover:shadow-lg focus:outline-none"
                            type="button"
                            onClick={handleCloseModal}
                          >
                            Close
                          </button>
                          <div className="flex gap-4 items-center">
                            <button
                              className="flex items-center px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear bg-red-500 rounded shadow outline-none active:bg-red-600 hover:shadow-lg focus:outline-none"
                              type="submit"
                            >
                              <span className="mr-2">Submit</span> <SaveOutlined />
                            </button>
                          </div>
                        </div>
                      </Form>
                    )
                  }}
                </Formik>
              </div>
            </div>
          </Modal>
        )}
      </>
    )
  }

  return (
    <React.Fragment>
      <div>
        {submitting && <LoadingSpinner />}
        <PaddingAuto>
          {renderingProcess.loading ? (
            <>{renderSkeleton()}</>
          ) : renderingProcess.error ? (
            <NotAvailable content={errors[inspectionTypes.GET_BUILDINGS]} />
          ) : (
            <Table
              tableParams
              columns={BuildingColumns}
              data={contents[inspectionTypes.GET_BUILDINGS]}
              actions={actions}
              setShowModal={setShowModal}
              loading={loadingBuilding}
              tableOptions={tableOptions}
              paginations={paginationsOptions}
              setTableOptions={setTableOptions}
              cellHeight={96}
              pathname={`/inspections`}
            ></Table>
          )}
        </PaddingAuto>
        {renderModalAddEdit()}
      </div>
    </React.Fragment>
  )
}

export default BuildingManagement
