import React, { useEffect } from 'react'

function Textarea({ refTextArea, id, values, setValue, name, editMode, maxLength }) {
  useEffect(() => {
    refTextArea.current.style.height = refTextArea.current.scrollHeight + 'px'
  }, [editMode, values])
  return (
    <div>
      <textarea
        className={`w-full h-auto border border-black p-2 ` + (!editMode ? 'hidden' : '')}
        rows={2}
        id={id}
        ref={refTextArea}
        value={values}
        disabled={!editMode}
        maxLength={maxLength}
        onKeyDown={(evt) => {
          if (name === 'storeys') {
            const charCode = evt.keyCode
            if (values === '' && charCode === 48) {
              evt.preventDefault()
            }

            if (evt.shiftKey) {
              evt.preventDefault()
            }

            if (charCode !== 8 && charCode !== 9 && charCode !== 16 && (charCode < 48 || charCode > 57)) {
              evt.preventDefault()
            }
          }
        }}
        onInput={(e) => {
          e.currentTarget.style.height = e.currentTarget.scrollHeight + 'px'
          setValue(e.currentTarget.value, name)
          const div = document.getElementById(`${e.currentTarget.id}-div`)
          div.remove()
          const newDiv = document.createElement('div')
          const container = document.getElementById(`${e.currentTarget.id}-div-container`)
          newDiv.id = `${e.currentTarget.id}-div`
          newDiv.innerText = e.target.value
          container.appendChild(newDiv)
        }}
      />
    </div>
  )
}

export default Textarea
