import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'

import userReducer from './slices/userSlice'
import companyReducer from './slices/companySlice'
import buildingReducer from './slices/buildingSlice'
import elevationReducer from './slices/elevationSlice'
import reasonReducer from './slices/reasonSlice'
import repairReducer from './slices/repairSlice'
import reportReducer from './slices/reportSlice'
import authReducer from './slices/authSlice'
import logReducer from './slices/logSliceSlice'

const reducer = combineReducers({
  company: companyReducer,
  building: buildingReducer,
  user: userReducer,
  elevation: elevationReducer,
  reason: reasonReducer,
  repair: repairReducer,
  report: reportReducer,
  auth: authReducer,
  log: logReducer
})

const store = configureStore({
  reducer,
  devTools: process.env.REACT_APP_NODE_ENV !== 'production'
})

export default store
