import { typeFileLong } from '../constant/file'

export const checkImageFormat = (files) => {
  let isErrorFile = false
  for (const img of files) {
    if (!typeFileLong.includes(img && img.type)) {
      isErrorFile = true
    }
  }
  return isErrorFile
}

export const getBase64Image = async (url) => {
  var canvas = document.createElement('canvas')
  var ctx = canvas.getContext('2d')
  var img = new Image()
  img.crossOrigin = 'anonymous'
  img.onload = async function () {
    canvas.width = img.width
    canvas.height = img.height
    await ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
  }
  img.src = url + '?stopGivingMeHeadaches=true'
  await img.decode()
  var dataURL = canvas.toDataURL('image/png')
  // return dataURL
  return dataURL.replace(/^data:image\/?[A-z]*;base64,/)
}

export const convertFileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)

    fileReader.onload = () => {
      resolve(fileReader.result)
    }

    fileReader.onerror = (error) => {
      reject(error)
    }
  })
}

export const convertFileToBuffer = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsArrayBuffer(file)

    fileReader.onload = () => {
      resolve(fileReader.result)
    }

    fileReader.onerror = (error) => {
      reject(error)
    }
  })
}

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

export const getByteBase64 = (base64) => {
  const [, byte] = base64.split(',')
  return byte
}

export const getImageSize = (image) =>
  new Promise((resolve, reject) => {
    const i = new Image()
    i.onload = () => {
      resolve(i)
    }
    i.src = image
  })

export const dataURLtoFile = (dataurl, filename) => {
  if (!dataurl) return ''
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[arr.length - 1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}

export const isWrongFormatFile = (file) => {
  const image = file?.file
  const isWrong = checkImageFormat([image])
  if (isWrong) return true
  return false
}
export const isWrongFormatFileCropeper2 = (files) => {
  const isWrong = checkImageFormat(files)
  if (isWrong) return true
  return false
}
