import React from 'react'
import '../Report/index.css'
import { formatMetadata } from '../../utils/common'
function ElevationImages({ defectListing }) {
  return (
    <ol className="toc-list" style={{ lineHeight: '30px' }}>
      <li id="record-v">
        <div className="font-bold">
          <div className="py-4 text-sm font-bold">Photos of Selected Defects Identified During Visual Inspection</div>
        </div>
      </li>
      <div style={{ lineHeight: 'normal' }}>
        {defectListing?.map((defectListings, index) => {
          if (defectListings?.photoRecord === true) {
            const metadata = formatMetadata(defectListings?.metadata)
            const { longitude, latitude, altitude } = metadata || {}
            return (
              <table className="border-[1px] w-full photo-record table-test table-break" style={{ fontSize: '12px' }}>
                <tr className="border-[1px]">
                  <td rowSpan={11} className=" h-full border-[1px] relative w-[242px]">
                    <img
                      className="pt-0 px-4 max-w-full max-h-[300px] absolute top-4"
                      src={defectListings?.url}
                      alt={`vsInspectionImage-${index}`}
                    />
                  </td>
                  <td className="border-[1px] p-2 font-bold w-[115px]">Defect Id #</td>
                  <td className="border-[1px] p-2 font-bold w-[242px]">{defectListings?.orderId}</td>
                </tr>
                <tr className="border-[1px] ">
                  <td className="border-[1px]  p-2 font-bold">Elevation</td>
                  <td className="border-[1px]  p-2">
                    {defectListings?.elevation_name} - {defectListings?.type}
                  </td>
                </tr>
                <tr className="border-[1px] ">
                  <td className="border-[1px]  p-2 font-bold">Location</td>
                  <td className="border-[1px]  p-2">
                    {defectListings?.row} - {defectListings?.column}
                  </td>
                </tr>
                <tr className="border-[1px] ">
                  <td className="border-[1px]  p-2 font-bold">Element</td>
                  <td className="border-[1px]  p-2">{defectListings?.element}</td>
                </tr>
                <tr className="border-[1px] ">
                  <td className="border-[1px]  p-2 font-bold">Defect</td>
                  <td className="border-[1px]  p-2">{defectListings?.tags}</td>
                </tr>
                <tr className="border-[1px] ">
                  <td className="border-[1px]  p-2 font-bold">Severity</td>
                  <td className="border-[1px]  p-2">{defectListings?.cls}</td>
                </tr>
                <tr className="border-[1px] ">
                  <td className="border-[1px]  p-2 font-bold">Remarks</td>
                  <td className="border-[1px]  p-2 break-work-custom">{defectListings?.remarks}</td>
                </tr>
                <tr className="border-[1px] ">
                  <td className="border-[1px]  p-2 font-bold">Reason for Defect</td>
                  <td className="border-[1px]  p-2">
                    {defectListings?.reasons?.map((reason, index) => (
                      <>
                        {reason?.label}
                        {index === defectListings?.reasons?.length - 1 ? '' : ', '}
                      </>
                    ))}
                  </td>
                </tr>
                <tr className="border-[1px] ">
                  <td className="border-[1px] p-2  font-bold">Repair Recommended</td>
                  <td className="border-[1px] p-2 ">
                    {defectListings?.repairs?.map((repair, index) => (
                      <>
                        {repair?.label}
                        {index === defectListings?.repairs?.length - 1 ? '' : ', '}
                      </>
                    ))}
                  </td>
                </tr>
                <tr className="border-[1px] ">
                  <td className="border-[1px] p-2  font-bold">Longtitude - Latitude</td>
                  <td className="border-[1px] p-2 ">
                    {longitude && latitude && (
                      <>
                        {longitude} - {latitude}
                      </>
                    )}
                  </td>
                </tr>
                <tr className="border-[1px] ">
                  <td className="border-[1px] p-2  font-bold">Aitutude</td>
                  <td className="border-[1px] p-2 ">{altitude}</td>
                </tr>
              </table>
            )
          }
        })}
      </div>
    </ol>
  )
}

export default ElevationImages
