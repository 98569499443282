import React, { useEffect } from 'react'
import { useLocation, Navigate, Outlet, useNavigate } from 'react-router-dom'
// hocs
import useAuth from '../../hooks/useAuth'
import { makeRoles } from '../../utils/common'

const ProtectedAuth = ({ allowedRoles, routeProtected = true }) => {
  const { user } = useAuth()
  const { role } = user?.user || {}
  const location = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    if (location.pathname === '/') {
      if (![...makeRoles([1, 2])].includes(role)) {
        navigate('/inspections')
      }
    }
    // if (location.pathname.includes('/settings') && !location.pathname.includes('settings/repair-recommended')) {
    //   if ([...makeRoles([6, 7, 10])].includes(role)) {
    //     // navigate('/settings/reasons-for-defect')
    //   }
    // }
  }, [location.pathname, user, navigate])

  if (!routeProtected) {
    return !user ? <Outlet /> : <Navigate to="/" state={{ from: location }} replace />
  }
  if (allowedRoles.includes(role)) {
    return <Outlet />
  } else if (role && !allowedRoles.includes(role)) {
    return <Navigate to="/" state={{ from: location }} replace />
  } else {
    return <Navigate to="/login" state={{ from: location }} replace />
  }
}

export default ProtectedAuth
