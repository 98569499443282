const elevationTypes = {
  GET_ELEVATION_DETAILS: 'GET_ELEVATION_DETAILS',
  CREATE_ELEVATION: 'CREATE_ELEVATION',
  UPDATE_ELEVATION: 'UPDATE_ELEVATION',
  DELETE_ELEVATION: 'DELETE_ELEVATION',
  GET_ELEVATIONS_BUILDING: 'GET_ELEVATIONS_BUILDING',
  CREATE_ANNOTATION_DRONE_ELEVATION: 'CREATE_ANNOTATION_DRONE_ELEVATION',
  CREATE_ANNOTATION_USER_ELEVATION: 'CREATE_ANNOTATION_USER_ELEVATION',
  CREATE_ANNOTATION_CLOSE_RANGE_ELEVATION: 'CREATE_ANNOTATION_CLOSE_RANGE_ELEVATION',
  CREATE_CLOSE_RANGE_IN_THUMBNAIL: 'CREATE_CLOSE_RANGE_IN_THUMBNAIL',
  UPDATE_CHECKED_DRONE_ANNOTATION_ELEVATION: 'UPDATE_CHECKED_DRONE_ANNOTATION_ELEVATION',
  UPDATE_CHECKED_USER_ANNOTATION_ELEVATION: 'UPDATE_CHECKED_USER_ANNOTATION_ELEVATION',
  UPDATE_ELEVATION_DETAIL: 'UPDATE_ELEVATION_DETAIL',
  UPDATE_STATUS_CLOSE_RANGE_DRONE: 'UPDATE_STATUS_CLOSE_RANGE_DRONE',
  UPDATE_STATUS_CLOSE_RANGE_MANUAL: 'UPDATE_STATUS_CLOSE_RANGE_MANUAL',
  DELETE_CLOSE_RANGE_ELEVATION: 'DELETE_CLOSE_RANGE_ELEVATION',
  SET_ELEVATION_STATUS: 'SET_ELEVATION_STATUS',
  AI_MASKING_ELEVATION: 'AI_MASKING_ELEVATION',
  UPDATE_ELEVATION_AVATAR: 'UPDATE_ELEVATION_AVATAR',
  UPDATE_ELEVATION_THUMBNAIL: 'UPDATE_ELEVATION_THUMBNAIL',
  GET_FRAME_THERMAL: 'GET_FRAME_THERMAL',
  RESET_ELEVATION: 'RESET_ELEVATION'
}

export default elevationTypes
