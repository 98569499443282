import React, { useEffect, useState, useRef } from 'react'
import { format } from 'date-fns'
import { useClickAway } from 'react-use'
import { toast } from 'react-toastify'
import { Form, Formik, Field, ErrorMessage } from 'formik'

import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
// hocs
import useAuth from '../../hooks/useAuth'

import { changeAvtName } from '../../utils/common'
// @antd
import { ExclamationCircleOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'

// assets
import { useSelector } from 'react-redux'
import { changePassword } from '../../store/thunks/authThunk'
import usePrevious from '../../hooks/usePrevious'
import authTypes from '../../constant/store/authTypes'
import LoadingSpinner from '../Common/loading-spinner/LoadingSpinner'
import Navigate from '../Common/Navigate'

function ProfileMember() {
  const dispatch = useDispatch()

  const { user } = useAuth()
  const { errors: errorsAuth, loadings: loadingsAuth } = useSelector((state) => state.auth)

  const [togglerNav, setTogglerNav] = useState(false)
  const siderbarRef = useRef(null)
  const [email, setEmail] = useState()
  const [username, setUsername] = useState()
  const [status, setStatus] = useState()
  const [submitting, setSubmitting] = useState(false)
  const [showPassword, setShowPassword] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false
  })
  const prevAuthUpdatePassword = usePrevious(loadingsAuth[authTypes.CHANGE_PASSWORD])

  const optionNavigate = [{ id: 1, title: 'Profile', canNavigate: true, path: `/profile` }]

  const initialValues = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  }

  const onSubmit = async (values) => {
    setSubmitting(true)
    const { oldPassword, newPassword } = values
    try {
      const dataReq = {
        password: oldPassword.trim(),
        passwordNew: newPassword.trim()
      }
      dispatch(changePassword(dataReq))
    } catch (err) {
      toast.error('Error when creating user')
    }
  }

  const validationSchema = Yup.object({
    oldPassword: Yup.string()
      .required('Please enter old password')
      .minLowercase(
        1,
        'Password must have at least 8 character and contain uppercase letters, lowercase letters, numbers and symbols'
      )
      .minUppercase(
        1,
        'Password must have at least 8 character and contain uppercase letters, lowercase letters, numbers and symbols'
      )
      .minNumbers(
        1,
        'Password must have at least 8 character and contain uppercase letters, lowercase letters, numbers and symbols'
      )
      .minSymbols(
        1,
        'Password must have at least 8 character and contain uppercase letters, lowercase letters, numbers and symbols'
      ),
    newPassword: Yup.string()
      .required('Please enter new password')
      .minLowercase(
        1,
        'Password must have at least 8 character and contain uppercase letters, lowercase letters, numbers and symbols'
      )
      .minUppercase(
        1,
        'Password must have at least 8 character and contain uppercase letters, lowercase letters, numbers and symbols'
      )
      .minNumbers(
        1,
        'Password must have at least 8 character and contain uppercase letters, lowercase letters, numbers and symbols'
      )
      .minSymbols(
        1,
        'Password must have at least 8 character and contain uppercase letters, lowercase letters, numbers and symbols'
      ),
    confirmPassword: Yup.string()
      .required('Please enter confirm password')
      .minLowercase(
        1,
        'Password must have at least 8 character and contain uppercase letters, lowercase letters, numbers and symbols'
      )
      .minUppercase(
        1,
        'Password must have at least 8 character and contain uppercase letters, lowercase letters, numbers and symbols'
      )
      .minNumbers(
        1,
        'Password must have at least 8 character and contain uppercase letters, lowercase letters, numbers and symbols'
      )
      .minSymbols(
        1,
        'Password must have at least 8 character and contain uppercase letters, lowercase letters, numbers and symbols'
      )
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
  })

  useClickAway(siderbarRef, () => {
    setTogglerNav(false)
  })

  const handleToggleShowPassword = (key) => {
    setShowPassword((prev) => ({ ...prev, [key]: !prev[key] }))
  }

  useEffect(() => {
    if (prevAuthUpdatePassword && !loadingsAuth[authTypes.CHANGE_PASSWORD]) {
      setSubmitting(false)
      if (!errorsAuth[authTypes.CHANGE_PASSWORD]) {
        toast.success('Update password successfully', {
          theme: 'dark',
          autoClose: 2000
        })
      } else {
        toast.error(errorsAuth[authTypes.CHANGE_PASSWORD], {
          theme: 'dark',
          autoClose: 2000
        })
      }
    }
  }, [errorsAuth, loadingsAuth, prevAuthUpdatePassword])

  return (
    <div>
      {submitting && <LoadingSpinner />}
      <Navigate options={optionNavigate} isBackButton={true} />

      <div className="w-full h-full  max-w-full md:max-w-screen-2xl mx-auto px-4 md:px-6 py-7 md:py-10">
        <div className="flex flex-col md:flex-row gap-10 justify-between h-full">
          <div className="flex-col md:w-1/3">
            <div className="bg-navigate shadow-xl py-4 px-6 rounded-xl h-auto">
              <div className="text-center text-blue-500  font-bold h-10 text-base sm:text-2xl truncate">
                {user.user.username}
              </div>

              <div className="flex justify-center items-center py-6">
                <div className="flex justify-center items-center font-bold cursor-pointer">
                  <div
                    className="w-32 h-32 truncate rounded-full text-3xl bg-gray-200 flex justify-center items-center font-bold cursor-pointer text-white"
                    style={{ backgroundColor: user.user.color }}
                  >
                    {changeAvtName(user.user.username)}
                  </div>
                </div>
              </div>

              <div className="flex gap-2 justify-center items-center sm:text-xl font-bold py-10">
                Member Since:
                <div className="text-primary">{format(new Date(user?.user?.createdDate), 'dd/MM/yyyy')}</div>
              </div>
            </div>
          </div>

          <div className="flex-col md:w-2/3">
            <div className="bg-navigate shadow-xl pt-4 pb-8 rounded-xl h-auto">
              <div className="ml-10 text-left text-blue-500 text-base sm:text-2xl font-bold h-10">Edit Profile</div>

              <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                {({ errors, values }) => {
                  return (
                    <Form>
                      <div className="flex gap-4 justify-center items-center ml-10 pb-6">
                        <div className="w-20">Email: </div>
                        <input
                          readOnly
                          name="email"
                          className="bg-transparent rounded-xl w-72 px-5 py-4 ml-5 focus:outline-none"
                          type="text"
                          placeholder="Email"
                          value={user.user.email}
                          onChange={(e) => setEmail(e.currentTarget.value)}
                        />
                      </div>

                      <div className="flex gap-4 justify-center items-center ml-10 pb-6">
                        <div className="w-20">Name: </div>
                        <input
                          readOnly
                          name="name"
                          className="bg-transparent rounded-xl w-72 px-5 py-4 ml-5 focus:outline-none"
                          type="text"
                          placeholder="Name"
                          value={user.user.username}
                          onChange={(e) => setUsername(e.currentTarget.value)}
                        />
                      </div>

                      {user.user.companyName ? (
                        <div className="flex gap-4 justify-center items-center ml-10 pb-6">
                          <div className="w-20">Company: </div>
                          <input
                            readOnly
                            name="company"
                            className="bg-transparent rounded-xl w-72 px-5 py-4 ml-5 focus:outline-none"
                            type="text"
                            placeholder="Company"
                            value={user.user.companyName}
                            onChange={(e) => setUsername(e.currentTarget.value)}
                          />
                        </div>
                      ) : (
                        <></>
                      )}

                      <div className="flex gap-4 justify-center items-center ml-10 pb-6">
                        <div className="w-20">Status: </div>
                        <input
                          readOnly
                          name="status"
                          className="bg-transparent rounded-xl w-72 px-5 py-4 ml-5 focus:outline-none"
                          type="text"
                          placeholder="Status"
                          value={user.user.status}
                          onChange={(e) => setStatus(e.currentTarget.value)}
                        />
                      </div>

                      <div className="flex gap-4 justify-center items-center ml-10 pb-6">
                        <div className="w-20">Old Password: </div>
                        <div>
                          <div className="flex items-center px-3 py-2 bg-white leading-tight w-72 text-primary border rounded shadow appearance-none focus:outline-none focus:shadow-outline">
                            <Field
                              type={showPassword.oldPassword ? 'text' : 'password'}
                              id="oldPassword"
                              className="h-full w-full border-0 outline-none"
                              placeholder="Enter old password"
                              name="oldPassword"
                            />
                            <span onClick={() => handleToggleShowPassword('oldPassword')} className="cursor-pointer">
                              {showPassword.oldPassword ? (
                                <EyeOutlined style={{ fontSize: 20 }} />
                              ) : (
                                <EyeInvisibleOutlined style={{ fontSize: 20 }} />
                              )}
                            </span>
                          </div>
                          <ErrorMessage name="oldPassword">
                            {(errMsg) => (
                              <div className="text-red-500 mt-1 flex items-center w-72">
                                <ExclamationCircleOutlined />
                                <span className="ml-1">{errMsg}</span>
                              </div>
                            )}
                          </ErrorMessage>
                        </div>
                      </div>
                      <div className="flex gap-4 justify-center items-center ml-10 pb-6">
                        <div className="w-20">New Password: </div>
                        <div>
                          <div className="flex items-center px-3 py-2 bg-white leading-tight w-72 text-primary border rounded shadow appearance-none focus:outline-none focus:shadow-outline">
                            <Field
                              type={showPassword.newPassword ? 'text' : 'password'}
                              id="newPassword"
                              className="h-full w-full border-0 outline-none"
                              placeholder="Enter new password"
                              name="newPassword"
                            />
                            <span onClick={() => handleToggleShowPassword('newPassword')} className="cursor-pointer">
                              {showPassword.newPassword ? (
                                <EyeOutlined style={{ fontSize: 20 }} />
                              ) : (
                                <EyeInvisibleOutlined style={{ fontSize: 20 }} />
                              )}
                            </span>
                          </div>
                          <ErrorMessage name="newPassword">
                            {(errMsg) => (
                              <div className="text-red-500 mt-1 flex items-center w-72">
                                <ExclamationCircleOutlined />
                                <span className="ml-1">{errMsg}</span>
                              </div>
                            )}
                          </ErrorMessage>
                        </div>
                      </div>
                      <div className="flex gap-4 justify-center items-center ml-10 pb-6">
                        <div className="w-20">Confirm Password:</div>
                        <div>
                          <div className="flex items-center px-3 py-2 bg-white leading-tight w-72 text-primary border rounded shadow appearance-none focus:outline-none focus:shadow-outline">
                            <Field
                              type={showPassword.confirmPassword ? 'text' : 'password'}
                              id="confirmPassword"
                              className="h-full w-full border-0 outline-none"
                              placeholder="Enter confirm password"
                              name="confirmPassword"
                            />
                            <span
                              onClick={() => handleToggleShowPassword('confirmPassword')}
                              className="cursor-pointer"
                            >
                              {showPassword.confirmPassword ? (
                                <EyeOutlined style={{ fontSize: 20 }} />
                              ) : (
                                <EyeInvisibleOutlined style={{ fontSize: 20 }} />
                              )}
                            </span>
                          </div>

                          <ErrorMessage name="confirmPassword">
                            {(errMsg) => (
                              <div className="text-red-500 mt-1 flex items-center w-72">
                                <ExclamationCircleOutlined />
                                <span className="ml-1">{errMsg}</span>
                              </div>
                            )}
                          </ErrorMessage>
                        </div>
                      </div>

                      <div className="flex gap-4 justify-center items-center ml-10 mt-4">
                        <button
                          type="submit"
                          className="w-[400px] mx-20 lg:mx-40 h-12 text-center rounded-full bg-red-500 flex justify-center items-center font-bold text-white cursor-pointer"
                        >
                          Change Password
                        </button>
                      </div>
                    </Form>
                  )
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileMember
