import apiInstance from './configApis'

const getCompanyReason = async (id, options) => {
  const url = '?' + new URLSearchParams(options).toString()
  try {
    const data = await apiInstance.get(`/annotation-setting/reasons/${id}${url}`)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}
const getAllCompanyReason = async (id) => {
  try {
    const data = await apiInstance.get(`/annotation-setting/all-reasons/${id}`)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}
const createCompanyReason = async ({ reason, company_id }) => {
  try {
    const data = await apiInstance.post(`/annotation-setting/reason`, { company_id, reason })
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}
const updateCompanyReason = async ({ id, reason }) => {
  try {
    const data = await apiInstance.patch(`/annotation-setting/reason`, { id, reason })
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}
const deleteCompanyReason = async ({ id }) => {
  try {
    const data = await apiInstance.delete(`annotation-setting/reason/${id}`)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const reasonApis = {
  getCompanyReason,
  createCompanyReason,
  updateCompanyReason,
  deleteCompanyReason,
  getAllCompanyReason
}
export default reasonApis
