import React from 'react'

const CountAnnotationContainer = ({ defects }) => {
  const colors = ['#FF0000', '#FFFF00', '#00FF00', '#00FFFF'] // red, yellow,green,blue
  const shapes = ['', 'pentagon', 'rounded-2xl', `w-[25px] h-[24px] transform rotate-45 mx-[4px]`] // vuong, ngu giac, tron, thoi

  const BoxContainer = ({ total, numReport, color, shape }) => {
    if (total && total !== 0) {
      return (
        <div
          className={`flex items-center justify-center w-8 h-8 text-primary ${shape}`}
          style={{ backgroundColor: color }}
        >
          {!numReport || numReport === 0 ? (
            <span className={`${shape === shapes[3] && 'transform -rotate-45'}`}>{total} </span>
          ) : (
            <span className={` ${shape === shapes[3] && 'transform -rotate-45'} text-[12px]`}>
              <sup>{numReport}</sup>&frasl;<sub>{total}</sub>
            </span>
          )}
        </div>
      )
    } else return <></>
  }

  return defects ? (
    <div className="flex flex-row gap-1  items-center max-w-[10rem] flex-wrap-reverse min-h-[32px] min-w-[32px] ">
      {/* Unsafe */}
      <BoxContainer color={colors[0]} shape={shapes[0]} total={defects.crackUnSafe} />
      <BoxContainer color={colors[0]} shape={shapes[1]} total={defects.spallingUnSafe} />
      <BoxContainer color={colors[0]} shape={shapes[2]} total={defects.corrosionUnSafe} />
      <BoxContainer color={colors[0]} shape={shapes[3]} total={defects.otherUnSafe} />

      {/*Require Repair */}
      <BoxContainer
        color={colors[1]}
        shape={shapes[0]}
        total={defects.crackTotalRequireRepair}
        numReport={defects.crackReportedRequireRepair}
      />
      <BoxContainer
        color={colors[1]}
        shape={shapes[1]}
        total={defects.spallingTotalRequireRepair}
        numReport={defects.spallingReportedRequireRepair}
      />
      <BoxContainer
        color={colors[1]}
        shape={shapes[2]}
        total={defects.corrosionTotalRequireRepair}
        numReport={defects.corrosionReportedRequireRepair}
      />
      <BoxContainer
        color={colors[1]}
        shape={shapes[3]}
        total={defects.otherTotalRequireRepair}
        numReport={defects.otherReportedRequireRepair}
      />

      {/*Safe */}
      <BoxContainer
        color={colors[2]}
        shape={shapes[0]}
        total={defects.crackTotalSafe}
        numReport={defects.crackReportedSafe}
      />
      <BoxContainer
        color={colors[2]}
        shape={shapes[1]}
        total={defects.spallingTotalSafe}
        numReport={defects.spallingReportedSafe}
      />
      <BoxContainer
        color={colors[2]}
        shape={shapes[2]}
        total={defects.corrosionTotalSafe}
        numReport={defects.corrosionReportedSafe}
      />
      <BoxContainer
        color={colors[2]}
        shape={shapes[3]}
        total={defects.otherTotalSafe}
        numReport={defects.otherReportedSafe}
      />

      {/* False Positive */}
      <BoxContainer color={colors[3]} shape={shapes[0]} total={defects.crackTotalFP} />
      <BoxContainer color={colors[3]} shape={shapes[1]} total={defects.spallingTotalFP} />
      <BoxContainer color={colors[3]} shape={shapes[2]} total={defects.corrosionTotalFP} />
      <BoxContainer color={colors[3]} shape={shapes[3]} total={defects.otherTotalFP} />
    </div>
  ) : (
    <></>
  )
}

export default CountAnnotationContainer
