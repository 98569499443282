export const color = {
  green: '#008000',
  greenLight: '#cbffcb',

  red: '#ff0000',
  redLight: '#ffd2d2',

  blue: '#0000ff',
  blueLight: '#a5a5ff',
  blueDisabled: '#5252ad',
  purple: '#9300ff',
  gray: '#808080',
  grayLight: '#d9d9d9',

  yellow: '#d9d50a',
  yellowLight: '#e2e2c0',

  orange: '#f18d0c',
  orangeLight: '#efc664',

  opacity: '#8083A3',
  primary: '#171721',
  white: '#FFFFFF',
  whiteDark: '#F1F1F1',
  'blue-button': '#3E7EFF'
}

export const avatarColors = {
  A: 'rgb(45,69,99,0.5)',
  B: 'rgb(165,64,184,0.5)',
  C: 'rgb(233,68,64,0.5)',
  D: 'rgb(254,162,32,0.5)',
  E: 'rgb(76,167,80,0.5)',
  F: 'rgb(38,122,255,0.5)',
  G: 'rgb(73,73,73,0.5)',
  H: 'rgb(88,93,113,0.5)',
  I: 'rgb(251,123,101,0.8)',
  J: 'rgb(255,168,80,0.8)',
  K: 'rgb(233,188,90,0.8)',
  L: 'rgb(88,144,220,0.8)',
  M: 'rgb(87,191,219,0.8)',
  N: 'rgb(45,69,99,0.5)',
  O: 'rgb(165,64,184,0.5)',
  P: 'rgb(233,68,64,0.5)',
  Q: 'rgb(254,162,32,0.5)',
  R: 'rgb(76,167,80,0.5)',
  S: 'rgb(38,122,255,0.5)',
  T: 'rgb(73,73,73,0.5)',
  U: 'rgb(88,93,113,0.5)',
  V: 'rgb(251,123,101,0.8)',
  W: 'rgb(255,168,80,0.8)',
  X: 'rgb(233,188,90,0.8)',
  Y: 'rgb(88,144,220,0.8)',
  Z: 'rgb(87,191,219,0.8)',
  '+': 'rgb(189,189,189)'
}
