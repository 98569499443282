import { createSlice } from '@reduxjs/toolkit'
import {
  getUsers,
  createUser,
  updateUser,
  getRoleUser,
  getRoleAdmin,
  getUsersCompany,
  clearErrors
} from '../thunks/userThunk'
import userTypes from '../../constant/store/userTypes'
const userSlice = createSlice({
  name: 'user',
  initialState: {
    users: [],
    usersCompany: [],
    fetchLoading: false,
    modifyLoading: false,
    modifyError: '',
    roles: {},
    error: {},
    loadings: {},
    errors: {},
    contents: {},
    paginations: {}
  },
  extraReducers: {
    // get role user
    [getRoleUser.pending]: (state) => {
      state.loadings[userTypes.GET_ROLE_USER] = true
    },
    [getRoleUser.fulfilled]: (state, { payload }) => {
      const newPayload = payload.map(({ id, name }) => {
        const newName =
          name === 'ClientAdmin'
            ? 'Admin'
            : name === 'ClientProject'
            ? 'Project'
            : name === 'ClientReviewer'
            ? 'Reviewer'
            : name === 'ClientImage'
            ? 'Image'
            : name === 'ClientCP'
            ? 'CP'
            : name === 'ClientGuest'
            ? 'Guest'
            : name === 'ClientFI'
            ? 'FI'
            : '-'

        return { label: newName, value: id }
      })

      state.loadings[userTypes.GET_ROLE_USER] = false
      state.contents[userTypes.GET_ROLE_USER] = [...newPayload]
      state.errors[userTypes.GET_ROLE_USER] = ''
    },
    [getRoleUser.rejected]: (state, { payload }) => {
      state.loadings[userTypes.GET_ROLE_USER] = false
      state.errors[userTypes.GET_ROLE_USER] = payload
    },
    // get role Admin
    [getRoleAdmin.pending]: (state) => {
      state.loadings[userTypes.GET_ROLE_ADMIN] = true
    },
    [getRoleAdmin.fulfilled]: (state, { payload }) => {
      const newPayload = payload.map(({ r_id, r_name }) => {
        const newName =
          r_name === 'Project'
            ? 'Claritas Project'
            : r_name === 'Admin'
            ? 'Claritas Admin'
            : r_name === 'Reviewer'
            ? 'Claritas Reviewer'
            : r_name === 'AI'
            ? 'Claritas AI'
            : r_name === 'CP'
            ? 'Claritas CP'
            : r_name === 'Claritas Image'
            ? 'Claritas Image'
            : r_name === 'Image'
            ? 'Image'
            : ''
        return { label: newName, value: r_id }
      })
      state.loadings[userTypes.GET_ROLE_ADMIN] = false
      state.contents[userTypes.GET_ROLE_ADMIN] = [...newPayload]
      state.errors[userTypes.GET_ROLE_ADMIN] = ''
    },
    [getRoleAdmin.rejected]: (state, { payload }) => {
      state.loadings[userTypes.GET_ROLE_ADMIN] = false
      state.errors[userTypes.GET_ROLE_ADMIN] = payload
    },
    // USER ACTION TYPE: Get all users
    [getUsers.pending]: (state) => {
      state.loadings[userTypes.GET_USER] = true
    },
    [getUsers.fulfilled]: (state, { payload }) => {
      state.loadings[userTypes.GET_USER] = false
      state.contents[userTypes.GET_USER] = payload.users
      state.paginations[userTypes.GET_USER] = payload.pagination
      state.errors[userTypes.GET_USER] = ''
    },
    [getUsers.rejected]: (state, { payload }) => {
      state.fetchLoading = false
      state.errors[userTypes.GET_USER] = payload.message
    },

    //ACTION TYPE: Create new user
    [createUser.pending]: (state, { payload }) => {
      state.loadings[userTypes.CREATE_USER] = true
      state.errors[userTypes.CREATE_USER] = ''
    },
    [createUser.fulfilled]: (state, { payload }) => {
      state.loadings[userTypes.CREATE_USER] = false
      state.contents[userTypes.GET_USER] = [payload, ...state.contents[userTypes.GET_USER]]
      state.errors[userTypes.CREATE_USER] = ''
    },
    [createUser.rejected]: (state, { payload }) => {
      state.loadings[userTypes.CREATE_USER] = false
      state.errors[userTypes.CREATE_USER] = payload
    },

    //ACTION TYPE: update user
    [updateUser.pending]: (state) => {
      state.loadings[userTypes.UPDATE_USER] = true
      state.errors[userTypes.UPDATE_USER] = ''
    },
    [updateUser.fulfilled]: (state, { payload }) => {
      state.loadings[userTypes.UPDATE_USER] = false
      const index = state.contents[userTypes.GET_USER].findIndex((user) => user.id === payload.id)
      state.contents[userTypes.GET_USER].splice(index, 1, payload)
      state.errors[userTypes.CREATE_USER] = ''
    },
    [updateUser.rejected]: (state, { payload }) => {
      state.loadings[userTypes.UPDATE_USER] = false
      state.errors[userTypes.UPDATE_USER] = payload
    },

    // // delete user
    // [updateUser.pending]: (state) => {
    //   state.fetchLoading = true;
    // },
    // [updateUser.fulfilled]: (state, { payload }) => {
    //   state.fetchLoading = false;
    //   state.modifyError = "";
    // },
    // [updateUser.rejected]: (state, { payload }) => {
    //   state.fetchLoading = false;
    //   state.modifyError = payload;
    // },

    // USER ACTION TYPE: Get  users company
    [getUsersCompany.pending]: (state) => {
      state.loadings[userTypes.GET_USERS_COMPANY] = true
      state.errors[userTypes.GET_USERS_COMPANY] = ''
    },
    [getUsersCompany.fulfilled]: (state, { payload }) => {
      state.loadings[userTypes.GET_USERS_COMPANY] = false
      state.contents[userTypes.GET_USERS_COMPANY] = payload.users
      state.paginations[userTypes.GET_USERS_COMPANY] = payload.pagination
      state.errors[userTypes.GET_USERS_COMPANY] = ''
    },
    [getUsersCompany.rejected]: (state, { payload }) => {
      state.loadings[userTypes.GET_USERS_COMPANY] = false
      state.errors[userTypes.GET_USERS_COMPANY] = payload
    },

    // clear errors
    [clearErrors.fulfilled]: (state, { payload }) => {
      payload.forEach((element) => {
        return (state.errors[element] = '')
      })
    }
  }
})
export default userSlice.reducer
