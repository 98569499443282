export const sizeOptions = [
  { label: 10, value: 10 },
  { label: 15, value: 15 },
  { label: 20, value: 20 },
  { label: 25, value: 25 }
]

// export const inspectionType = [
//   { label: "HDB", value: "HDB" },
//   { label: "Condominium ", value: "Condominium" },
//   { label: "Commercial ", value: "Commercial" },
// ]

export const status = [
  { label: 'Active', value: 'Active' },
  { label: 'Inactive', value: 'Inactive' }
]

export const statusBuidingEdit = ['Active', 'Completed', 'Archived']

export const GET_API_STATUS_SUCCESS = 200
export const HANDLE_API_STATUS_SUCCESS = 201

export const elementAndDefect = [
  {
    id: 1,
    element: 'Plaster / Concrete',
    defectList: [
      { defectId: 10, defect: 'Cracks' },
      { defectId: 11, defect: 'Spalling' },
      { defectId: 12, defect: 'Delamination' },
      { defectId: 13, defect: 'Efflorescence' },
      { defectId: 14, defect: 'Discolouration' },
      { defectId: 15, defect: 'Hollow' }
    ]
  },
  {
    id: 2,
    element: 'Tiles',
    defectList: [
      { defectId: 20, defect: 'Cracks' },
      { defectId: 21, defect: 'Loose tiles' },
      { defectId: 22, defect: 'Efflorescence' },
      { defectId: 23, defect: 'Discolouration' }
    ]
  },
  {
    id: 3,
    element: 'Bricks',
    defectList: [
      { defectId: 30, defect: 'Cracks' },
      { defectId: 31, defect: 'Loose brick' },
      { defectId: 32, defect: 'Efflorescence' },
      { defectId: 33, defect: 'Discolouration' }
    ]
  },
  {
    id: 4,
    element: 'Curtain Wall System',
    defectList: [
      { defectId: 40, defect: 'Loose gaskets and seals' },
      { defectId: 41, defect: 'Deformation of capping' },
      { defectId: 42, defect: 'Dislodgement of capping' },
      { defectId: 43, defect: 'Misalignment of transoms' },
      { defectId: 44, defect: 'Breakage of panels' },
      { defectId: 45, defect: 'Corrosion of brackets' },
      { defectId: 46, defect: 'Failure of joints' }
    ]
  },
  {
    id: 5,
    element: 'Cladding',
    defectList: [
      { defectId: 50, defect: 'Cracked panels' },
      { defectId: 51, defect: 'Loose cladding panels' },
      { defectId: 52, defect: 'Dislodged panel' },
      { defectId: 53, defect: 'Corrosion of fasteners' },
      { defectId: 54, defect: 'Corrosion of brackets' },
      { defectId: 55, defect: 'Missing screws/pins in connections' },
      { defectId: 56, defect: 'Failure of screws/rivets' },
      { defectId: 57, defect: 'Failure of adhesive bond' },
      { defectId: 58, defect: 'Warping/Deformation of panels' },
      { defectId: 59, defect: 'Defects on boards' }
    ]
  },
  {
    id: 16,
    element: 'Green Wall / Screen Wall',
    defectList: [
      { defectId: 60, defect: 'Loose connections' },
      { defectId: 61, defect: 'Corrosion of green walls' },
      { defectId: 62, defect: 'Missing connections' }
    ]
  },
  {
    id: 6,
    element: 'Suspended Ceiling System',
    defectList: [
      { defectId: 70, defect: 'Spalling of concrete substrate' },
      { defectId: 71, defect: 'Corroded fixing' },
      { defectId: 72, defect: 'Loose ceiling boards' },
      { defectId: 73, defect: 'Water staining on ceiling boards' },
      { defectId: 74, defect: 'Dangling ceiling panel' },
      { defectId: 75, defect: 'Damaged hanging rod for false ceiling' },
      { defectId: 76, defect: 'Moulding on external ceiling board' },
      { defectId: 77, defect: 'Poor embedment of fixing' },
      { defectId: 78, defect: 'Degradation of ceiling board' },
      { defectId: 79, defect: 'Degradation of support frame' }
    ]
  },
  {
    id: 7,
    element: 'Window',
    defectList: [
      { defectId: 80, defect: 'Dangling window panel' },
      { defectId: 81, defect: 'Cracked window glass panels' },
      { defectId: 82, defect: 'Missing window glass panels' },
      { defectId: 83, defect: 'Corroded window panel fixings' },
      { defectId: 84, defect: 'Corroded Screws / Fasteners' },
      { defectId: 85, defect: 'Missing window sealant' },
      { defectId: 86, defect: 'Damaged window sealant' }
    ]
  },
  {
    id: 8,
    element: 'Balustrade and Railing',
    defectList: [
      { defectId: 90, defect: 'Cracked cement balustrade' },
      { defectId: 91, defect: 'Spalling cement balustrade' },
      { defectId: 92, defect: 'Delamination cement balustrade' },
      { defectId: 93, defect: 'Efflorescence cement balustrade' },
      { defectId: 94, defect: 'Discolouration cement balustrade' },
      { defectId: 95, defect: 'Corroded railing members' },
      { defectId: 96, defect: 'Corroded railing supports' },
      { defectId: 97, defect: 'Corroded railing connections' },
      { defectId: 98, defect: 'Cracked glass balustrade' },
      { defectId: 99, defect: 'Damaged sealant' }
    ]
  },
  {
    id: 9,
    element: 'Fins and Louvres',
    defectList: [
      { defectId: 'A0', defect: 'Dangling elements' },
      { defectId: 'A1', defect: 'Corrosion of elements/connections' },
      { defectId: 'A2', defect: 'Corroded connections' }
    ]
  },
  {
    id: 10,
    element: 'Awning and Canopy',
    defectList: [
      { defectId: 'B0', defect: 'Dislodgement' },
      { defectId: 'B1', defect: 'Corroded connections' },
      { defectId: 'B2', defect: 'Loose connections' }
    ]
  },
  {
    id: 11,
    element: 'RWDP and Gutter',
    defectList: [
      { defectId: 'C0', defect: 'Dislodgement' },
      { defectId: 'C1', defect: 'Corroded connections' }
    ]
  },
  {
    id: 12,
    element: 'Air-conditioning Unit and Support',
    defectList: [
      { defectId: 'D0', defect: 'Corrosion of Air-conditioning unit' },
      { defectId: 'D1', defect: 'Corrosion of brackets' },
      { defectId: 'D2', defect: 'Corrosion of fixing/ fasteners' }
    ]
  },
  {
    id: 13,
    element: 'Cloth Hanger',
    defectList: [
      { defectId: 'E0', defect: 'Dislodgement' },
      { defectId: 'E1', defect: 'Corroded connections' },
      { defectId: 'E2', defect: 'Loose connections' }
    ]
  },
  {
    id: 14,
    element: 'Metal Structure',
    defectList: [
      { defectId: 'F1', defect: 'Dislodgement' },
      { defectId: 'F2', defect: 'Corrosion of metal structure' },
      { defectId: 'F3', defect: 'Corrosion of metal structure support' }
    ]
  },
  {
    id: 15,
    element: 'Masking',
    defectList: [
      { defectId: 'Z0', defect: 'Window' },
      { defectId: 'Z1', defect: 'Face' },
      { defectId: 'Z2', defect: 'Car Plate Number' }
    ]
  },
  {
    id: 16,
    element: 'Others',
    defectList: [
      { defectId: 'G0', defect: 'Vegatation' },
      { defectId: 'G1', defect: 'Others (Refer to remarks)' }
    ]
  }
]
