// libs
import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { onConfirm } from 'react-confirm-pro'
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'

// hocs
import useAuth from '../../../../hooks/useAuth'
import usePrevious from '../../../../hooks/usePrevious'

// actions
import {
  getCompanyRepair,
  createCompanyRepair,
  updateCompanyRepair,
  deleteCompanyRepair
} from '../../../../store/thunks/repairThunk'

// commons
import Table from '../../../Common/table2'
import Modal from '../../../Common/Modal'
import SkeletonViewer from '../../../Common/skeleton-viewer'
import LoadingSpinner from '../../../Common/loading-spinner/LoadingSpinner'
import NotAvailable from '../../../Common/NotAvailable'

// @antd
import { SaveOutlined, ExclamationCircleOutlined, EditOutlined, DeleteOutlined, CloseOutlined } from '@ant-design/icons'

// assets

// variables
import { adminRoles } from '../../../../constant/auth'
import { color } from '../../../../constant/styled'
import repairTypes from '../../../../constant/store/repairTypes'
import PaddingAuto from '../../../Layout/PaddingAuto'
import { isEmptyObject } from '../../../../utils/common'

const RepairRecommend = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { user } = useAuth()
  const { role } = user?.user || {}
  const { companyId } = useParams()
  const {
    loadings: loadingsRepair,
    contents: contentsRepair,
    errors: errorsRepair,
    paginations: paginationsRepair
  } = useSelector((state) => state.repair)

  const repairs = contentsRepair[repairTypes.GET_COMPANY_REPAIR]
  const [_mounted, setMouted] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [currentRepair, setCurrentRepair] = useState(null)
  const [confirm, setConfirm] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [renderingProcess, setRenderingProcess] = useState({
    loading: true,
    error: false
  })
  const [tableOptions, setTableOptions] = useState({})
  const loadingsGetRepair = loadingsRepair[repairTypes.GET_COMPANY_REPAIR]
  const paginationsOptions = paginationsRepair[repairTypes.GET_COMPANY_REPAIR]
  const prevRepairGetFetching = usePrevious(loadingsRepair[repairTypes.GET_COMPANY_REPAIR])
  const prevReasonCreateFetching = usePrevious(loadingsRepair[repairTypes.CREATE_COMPANY_REPAIR])
  const prevReasonUpdateFetching = usePrevious(loadingsRepair[repairTypes.UPDATE_COMPANY_REPAIR])
  const prevReasonDeleteFetching = usePrevious(loadingsRepair[repairTypes.DELETE_COMPANY_REPAIR])

  const initialValues = {
    repair: currentRepair?.name || ''
  }

  const actions = [
    {
      id: 1,
      title: '',
      isShow: adminRoles.includes(role)
    }
  ]

  const CompanyColumns = [
    {
      Header: 'Repair',
      accessor: 'name',
      width: 150
    },

    {
      Header: 'Actions',
      hideHeader: true,
      disableSortBy: true,
      role: adminRoles,
      width: 92,
      maxWidth: 92,
      Cell: (tableProps) => {
        const { id } = tableProps.row?.original
        return (
          <>
            <div className="flex items-center gap-2">
              <div className="relative">
                <div
                  className="inline-flex items-center font-bold py-2 px-3 rounded-lg bg-view-detail group-hover:bg-white group-hover:text-blue-button cursor-pointer"
                  onClick={() => handleEditRepair(tableProps.row.original.id)}
                >
                  <EditOutlined
                    style={{
                      fontSize: '20px',
                      cursor: 'pointer',
                      color: color.green
                    }}
                  />
                </div>
              </div>
              <div
                className="inline-flex items-center font-bold py-2 px-3 rounded-lg bg-view-detail group-hover:bg-white group-hover:text-blue-button cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation()
                  onConfirm({
                    title: <h3 className="text-center">Are you sure you want to delete this repair?</h3>,
                    description: <br />,
                    onSubmit: async () => {
                      dispatch(deleteCompanyRepair({ id }))
                    },
                    onCancel: () => {},
                    buttons: ({ onSubmit, onCancel }) => (
                      <div className="custom-buttons">
                        <button style={{ background: 'rgba(37, 99, 235)', color: 'white' }} onClick={onSubmit}>
                          YES
                        </button>
                        <button style={{ background: 'rgba(220, 38, 38)', color: 'white' }} onClick={onCancel}>
                          NO
                        </button>
                      </div>
                    )
                  })
                }}
              >
                <DeleteOutlined
                  style={{
                    fontSize: '20px',
                    cursor: 'pointer',
                    color: color.red
                  }}
                  alt="delete"
                />
              </div>
            </div>
          </>
        )
      }
    }
  ]

  const handleCloseModalConfirm = () => {
    setConfirm(false)
    setCurrentRepair(null)
  }

  const handleCloseModal = () => {
    setShowModal(false)
    setCurrentRepair(null)
  }

  const onSubmitUpdate = (values, onSubmitProps) => {
    setSubmitting(true)
    const { repair } = values
    try {
      const dataReq = {
        id: currentRepair.id,
        repair
      }
      setTimeout(() => {
        onSubmitProps.setSubmitting(false)
      }, 1000)
      dispatch(updateCompanyRepair(dataReq))
    } catch (err) {
      toast.error('Error when updating building')
    }
  }

  const onSubmitAdd = (values, onSubmitProps) => {
    setSubmitting(true)
    const { repair } = values
    try {
      const dataReq = {
        company_id: parseInt(user?.user?.companyId || companyId),
        repair
      }
      setTimeout(() => {
        onSubmitProps.setSubmitting(false)
      }, 1000)
      dispatch(createCompanyRepair(dataReq))
    } catch (err) {
      toast.error('Error when add reason')
    }
  }

  const validationSchema = Yup.object({
    repair: Yup.string().required('Please enter repair')
  })

  const handleEditRepair = (id) => {
    setShowModal(true)
    const indexReason = repairs.findIndex((r) => r.id === id)
    setCurrentRepair(repairs[indexReason])
  }

  useMemo(() => {
    setMouted(true)
  }, [])

  // get data table

  const handleNavigateParamsUrl = (params, replace = false, pathname) => {
    navigate(
      {
        pathname: pathname,
        search: createSearchParams({
          page: params.page,
          size: params.size,
          ...params
        }).toString()
      },
      { replace }
    )
  }

  useEffect(() => {
    if (!location.search) {
      handleNavigateParamsUrl({ page: 1, size: 10 }, true, '/settings/repair-recommended')
    }
  }, [])

  useEffect(() => {
    if (_mounted && location.search) {
      const params = Object.fromEntries(new URLSearchParams(window.location.search))
      const options = {
        ...params,
        ...(params.page ? { page: params.page } : { page: 1 }),
        ...(params.size && params.size >= 10 ? { size: params.size } : { size: 10 })
      }
      setTableOptions(options)
    }
  }, [location.search])

  useEffect(() => {
    const get = async () => {
      if (_mounted && location.search && !isEmptyObject(tableOptions)) {
        dispatch(getCompanyRepair({ id: user?.user?.companyId || companyId, options: tableOptions }))
      }
    }
    get()
  }, [dispatch, user, tableOptions])

  // end get data table

  useEffect(() => {
    return () => {
      setMouted(false)
    }
  }, [])

  useEffect(() => {
    if (prevRepairGetFetching && !loadingsRepair[repairTypes.GET_COMPANY_REPAIR]) {
      if (!errorsRepair[repairTypes.GET_COMPANY_REPAIR]) {
        setRenderingProcess((prev) => ({ ...prev, loading: false }))
      } else {
        setRenderingProcess((prev) => ({ ...prev, error: true, loading: false }))
      }
    }
  }, [loadingsRepair, errorsRepair, prevRepairGetFetching])

  useEffect(() => {
    if (prevReasonCreateFetching && !loadingsRepair[repairTypes.CREATE_COMPANY_REPAIR]) {
      setSubmitting(false)
      if (!errorsRepair[repairTypes.CREATE_COMPANY_REPAIR]) {
        setShowModal(false)
        setCurrentRepair(null)
        handleNavigateParamsUrl({ page: 1, size: 10 }, false, `/settings/repair-recommended`)
        toast.success('Repair created', {
          theme: 'dark',
          autoClose: 2000
        })
        setShowModal(false)
      } else {
        toast.error(errorsRepair[repairTypes.CREATE_COMPANY_REPAIR], {
          theme: 'dark',
          autoClose: 2000
        })
      }
    }
  }, [errorsRepair, loadingsRepair, prevReasonCreateFetching])

  useEffect(() => {
    if (prevReasonUpdateFetching && !loadingsRepair[repairTypes.UPDATE_COMPANY_REPAIR]) {
      setSubmitting(false)
      if (!errorsRepair[repairTypes.UPDATE_COMPANY_REPAIR]) {
        setShowModal(false)
        setCurrentRepair(null)
        toast.success('Repair updated', {
          theme: 'dark',
          autoClose: 2000
        })
        setShowModal(false)
      } else {
        toast.error(errorsRepair[repairTypes.UPDATE_COMPANY_REPAIR], {
          theme: 'dark',
          autoClose: 2000
        })
      }
    }
  }, [errorsRepair, loadingsRepair, prevReasonUpdateFetching])
  useEffect(() => {
    if (prevReasonDeleteFetching && !loadingsRepair[repairTypes.DELETE_COMPANY_REPAIR]) {
      if (!errorsRepair[repairTypes.DELETE_COMPANY_REPAIR]) {
        setShowModal(false)
        setCurrentRepair(null)
        let newPage = tableOptions.page
        if (newPage > 1 && contentsRepair[repairTypes.GET_COMPANY_REPAIR].length === 0) {
          newPage = newPage - 1
        }
        handleNavigateParamsUrl({ size: 10, ...tableOptions, page: newPage }, false, `/settings/repair-recommended`)
        toast.success('Repair deleted', {
          theme: 'dark',
          autoClose: 2000
        })
        setShowModal(false)
      } else {
        toast.error(errorsRepair[repairTypes.DELETE_COMPANY_REPAIR], {
          theme: 'dark',
          autoClose: 2000
        })
      }
    }
  }, [errorsRepair, loadingsRepair, prevReasonDeleteFetching])

  const modalConfirm = () => {
    return (
      <Modal wrapper="w-auto max-w-md mx-auto" onClose={handleCloseModalConfirm}>
        <div className="w-88">
          <div className="flex items-center justify-between p-3 border-b border-solid rounded-t border-slate-200">
            <div className="text-xl text-primary font-semibold ">Notification</div>
            <span className="block w-6 h-6 ">
              <CloseOutlined style={{ fontSize: '22px', cursor: 'pointer' }} onClick={() => setConfirm(false)} />
            </span>
          </div>
          {/*body*/}
          <div className="relative flex flex-auto p-6">
            <div className="w-full max-w-lg">Are you sure to delete this repair?</div>
          </div>
          <div className="flex items-center justify-between p-6 border-t border-solid rounded-b border-slate-200">
            <button
              className="px-6 py-3 mb-1 mr-1 text-sm font-bold cursor-pointer text-white uppercase transition-all duration-150 ease-linear bg-red-500 rounded shadow outline-none active:bg-gray-600 hover:shadow-lg focus:outline-none"
              type="button"
              onClick={() => {
                setConfirm(false)
                setCurrentRepair(null)
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      </Modal>
    )
  }

  const renderSkeleton = () => {
    return (
      <div style={{ width: '100%', margin: '0 auto', marginTop: '16px' }}>
        <div className="flex items-center flex-wrap gap-2 md:gap-6">
          {actions &&
            Array.from({ length: actions.length + 1 }, function (v, k) {
              return k
            }).map((i) => (
              <div key={i}>
                <SkeletonViewer borderRadius={16} className="h-8 md:h-10 w-32 md:w-44" />
              </div>
            ))}
          <div className="sm:ml-auto">
            <SkeletonViewer borderRadius={16} className="h-8 md:h-10 w-[90vw] sm:w-40 md:w-56 lg:w-72" />
          </div>
        </div>
        <div>
          <SkeletonViewer className="mt-2" height={56} width="100%" />
          <div className="block overflow-hidden mt-1" style={{ height: '64vh' }}>
            {Array.from({ length: 10 }, function (v, k) {
              return k
            }).map((i) => (
              <div key={i}>
                <SkeletonViewer className="mt-2" borderRadius={12} height={68} width="100%" />
              </div>
            ))}
          </div>
        </div>
        <div className="flex justify-center">
          <SkeletonViewer className="h-8 md:h-10 mt-4" width={300} />
        </div>
      </div>
    )
  }

  return (
    <React.Fragment>
      <div>
        <PaddingAuto>
          {submitting && <LoadingSpinner />}

          {renderingProcess.loading ? (
            renderSkeleton()
          ) : renderingProcess.error ? (
            <NotAvailable />
          ) : (
            <Table
              tableParams
              columns={CompanyColumns}
              data={repairs}
              actions={actions}
              setShowModal={setShowModal}
              loading={loadingsGetRepair}
              tableOptions={tableOptions}
              paginations={paginationsOptions}
              setTableOptions={setTableOptions}
              heightTable={'64vh'}
              cellHeight={68}
              pathname={'/settings/repair-recommended'}
            ></Table>
          )}
        </PaddingAuto>

        {confirm && modalConfirm()}

        {showModal && (
          <Modal wrapper="w-auto max-w-md mx-auto" onClose={handleCloseModal}>
            <div className="flex items-center justify-between p-3 border-b border-solid rounded-t border-slate-200">
              <div className="text-xl text-primary font-semibold">
                {currentRepair ? 'Edit Repair' : 'Create Repair'}
              </div>
            </div>
            {/*body*/}
            <div className="relative flex flex-auto p-6">
              <div className="w-full max-w-lg">
                <Formik
                  initialValues={initialValues}
                  onSubmit={currentRepair ? onSubmitUpdate : onSubmitAdd}
                  validationSchema={validationSchema}
                >
                  {() => {
                    return (
                      <Form className="w-full max-w-lg">
                        <div className="flex flex-wrap mb-6 -mx-3">
                          <div className="w-full px-3 mb-3">
                            <label
                              className="block mb-2 text-xs font-bold tracking-wide text-primary uppercase"
                              htmlFor="grid-first-name"
                            >
                              Repair
                            </label>
                            <Field
                              type="text"
                              id="repair"
                              placeholder="Repair"
                              className="block w-full px-4 py-3 leading-tight text-primary bg-gray-50 border rounded appearance-none focus:outline-none focus:bg-white"
                              name="repair"
                            />
                            <ErrorMessage name="repair">
                              {(errMsg) => (
                                <div className="text-red-500 mt-1 flex items-center">
                                  <ExclamationCircleOutlined />
                                  <span className="ml-1">{errMsg}</span>
                                </div>
                              )}
                            </ErrorMessage>
                          </div>
                        </div>
                        <div className="flex items-center justify-between p-6 border-t border-solid rounded-b border-slate-200">
                          <button
                            className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear bg-gray-500 rounded shadow outline-none active:bg-gray-600 hover:shadow-lg focus:outline-none"
                            type="button"
                            onClick={handleCloseModal}
                          >
                            Close
                          </button>

                          <button
                            className={`flex items-center px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase bg-red-500 transition-all duration-150 ease-linear rounded shadow outline-none active:bg-red-600 hover:shadow-lg focus:outline-none`}
                            type="submit"
                          >
                            <span className="mr-2">Submit</span> <SaveOutlined />
                          </button>
                        </div>
                      </Form>
                    )
                  }}
                </Formik>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </React.Fragment>
  )
}

export default RepairRecommend
