import { elementAndDefect } from '../constant/common'

export const getNameFromString = (string, separator, index) => {
  let newString = string.split(separator)
  if (!index) {
    return newString[newString.length - 1]
  } else {
    return newString[index]
  }
}
const getHashOfString = (str = '') => {
  let hash = 0
  for (let i = 0; i < str?.length || 0; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  hash = Math.abs(hash)
  return hash
}

const normalizeHash = (hash, min, max) => {
  return Math.floor((hash % (max - min)) + min)
}

export const generateHSL = (name) => {
  const hRange = [0, 360]
  const sRange = [20, 100]
  const lRange = [0, 60]

  const hash = getHashOfString(name)
  const h = normalizeHash(hash, hRange[0], hRange[1])
  const s = normalizeHash(hash, sRange[0], sRange[1])
  const l = normalizeHash(hash, lRange[0], lRange[1])
  return `hsl(${h}, ${s}%, ${l}%)`
}

export const getDefectCode = (defectType, elementType) => {
  if (!elementType || !defectType) return 0
  const listDefect = elementAndDefect.find((e) => e.element === elementType)
  if (!listDefect) return 0
  const defectItem = listDefect.defectList.find((d) => d.defect === defectType)
  return defectItem.defectId
}

export const getElementType = (isAi) => {
  const elementList = elementAndDefect.map((e) => e.element)
  if (isAi) {
    return elementList
  } else {
    const elementWithoutAi = elementList.filter((item) => item !== 'Masking')
    return elementWithoutAi
  }
}

export const getDefectlist = (elementType) => {
  const element = elementAndDefect.find((e) => e.element === elementType)
  return element?.defectList || []
}
