import React from 'react'
import './index.css'
function TableOfContent({
  InspectionInformationPage,
  InspectionDatesPage,
  InspectionLocationPage,
  InspectorsPage,
  WitnessesPage,
  ReportPreparationPage,
  ReferenceDocumentsPage,
  IntroductionPage,
  BackgroundInformationPage,
  ObjectivesPage,
  FacadeSystemsPage,
  DefectPage,
  SummaryAndRecommendationsPage,
  SummaryPage,
  RecommendationsPage,
  ConclusionValue,
  ConclusionPage,
  AppendixA,
  AppendixB,
  AppendixC,
  AppendixD,
  AppendixE,
  AppendixF,
  DefectListingsV
}) {
  return (
    <>
      <ol className="toc-list" style={{ lineHeight: '30px' }}>
        <div style={{ fontSize: '20px', fontWeight: 'bold' }}>
          <h1>TABLE OF CONTENTS</h1>
        </div>
        <li>
          <a id="INTRODUCTION-href" href="#INTRODUCTION">
            <span className="title">
              1.0 INTRODUCTION<span className="leaders" aria-hidden="true"></span>
            </span>
            <span data-href="#INTRODUCTION" className="page">
              <span className="visually-hidden">Page&nbsp;</span>
              <span id="INTRODUCTION-Page">{IntroductionPage}</span>
            </span>
          </a>
          <ol>
            <li>
              <a id="Background-Information-href" href="#Background-Information">
                <span className="title">
                  1.1 Background Information<span className="leaders" aria-hidden="true"></span>
                </span>
                <span data-href="#Background-Information" className="page">
                  <span className="visually-hidden">Page&nbsp;</span>
                  <span id="Background-Information-Page">{BackgroundInformationPage}</span>
                </span>
              </a>
            </li>

            <li>
              <a id="Objectives-href" href="#Objectives">
                <span className="title">
                  1.2 Objectives<span className="leaders" aria-hidden="true"></span>
                </span>
                <span data-href="#Objectives" className="page">
                  <span className="visually-hidden">Page&nbsp;</span>
                  <span id="Objectives-Page">{ObjectivesPage}</span>
                </span>
              </a>
            </li>

            <li>
              <a id="Façade-Systems-href" href="#Façade-Systems">
                <span className="title">
                  1.3 Facade Systems<span className="leaders" aria-hidden="true"></span>
                </span>
                <span data-href="#Façade-Systems" className="page">
                  <span className="visually-hidden">Page&nbsp;</span>
                  <span id="Façade-Systems-Page">{FacadeSystemsPage}</span>
                </span>
              </a>
            </li>
          </ol>
        </li>

        <li>
          <a id="INSPECTION-INFORMATION-href" href="#INSPECTION-INFORMATION">
            <span className="title">
              2.0 INSPECTION INFORMATION<span className="leaders" aria-hidden="true"></span>
            </span>
            <span data-href="#INSPECTION-INFORMATION" className="page">
              <span className="visually-hidden">Page&nbsp;</span>
              <span id="INSPECTION-INFORMATION-Page">{InspectionInformationPage}</span>
            </span>
          </a>
          <ol>
            <li>
              <a id="Inspection-Dates-href" href="#Inspection-Dates">
                <span className="title">
                  2.1 Inspection Dates<span className="leaders" aria-hidden="true"></span>
                </span>
                <span data-href="#Inspection-Dates" className="page">
                  <span className="visually-hidden">Page&nbsp;</span>
                  <span id="Inspection-Dates-Page">{InspectionDatesPage}</span>
                </span>
              </a>
            </li>

            <li>
              <a id="Inspection-Location-href" href="#Inspection-Location">
                <span className="title">
                  2.2 Inspection Location<span className="leaders" aria-hidden="true"></span>
                </span>
                <span data-href="#Inspection-Location" className="page">
                  <span className="visually-hidden">Page&nbsp;</span>
                  <span id="Inspection-Location-Page">{InspectionLocationPage}</span>
                </span>
              </a>
            </li>

            <li>
              <a id="Inspectors-href" href="#Inspectors">
                <span className="title">
                  2.3 Inspectors<span className="leaders" aria-hidden="true"></span>
                </span>
                <span data-href="#Inspectors" className="page">
                  <span className="visually-hidden">Page&nbsp;</span>
                  <span id="Inspectors-Page">{InspectorsPage}</span>
                </span>
              </a>
            </li>

            <li>
              <a id="Witnesses-href" href="#Witnesses">
                <span className="title">
                  2.4 Witnesses<span className="leaders" aria-hidden="true"></span>
                </span>
                <span data-href="#Witnesses" className="page">
                  <span className="visually-hidden">Page&nbsp;</span>
                  <span id="Witnesses-Page">{WitnessesPage}</span>
                </span>
              </a>
            </li>

            <li>
              <a id="Report-Preparation-href" href="#Report-Preparation">
                <span className="title">
                  2.5 Report Preparation<span className="leaders" aria-hidden="true"></span>
                </span>
                <span data-href="#Report-Preparation" className="page">
                  <span className="visually-hidden">Page&nbsp;</span>
                  <span id="Report-Preparation-Page">{ReportPreparationPage}</span>
                </span>
              </a>
            </li>

            <li>
              <a id="Reference-Documents-href" href="#Reference-Documents">
                <span className="title">
                  2.6 Reference Documents<span className="leaders" aria-hidden="true"></span>
                </span>
                <span data-href="#Reference-Documents" className="page">
                  <span className="visually-hidden">Page&nbsp;</span>
                  <span id="Reference-Documents-Page">{ReferenceDocumentsPage}</span>
                </span>
              </a>
            </li>
          </ol>
        </li>

        <li>
          <a id="DEFECT-DESCRIPTION-AND-CLASSIFICATION-href" href="#DEFECT-DESCRIPTION-AND-CLASSIFICATION">
            <span className="title">
              3.0 DEFECT DESCRIPTION AND CLASSIFICATION<span className="leaders" aria-hidden="true"></span>
            </span>
            <span data-href="#DEFECT-DESCRIPTION-AND-CLASSIFICATION" className="page">
              <span className="visually-hidden">Page&nbsp;</span>
              <span id="DEFECT-DESCRIPTION-AND-CLASSIFICATION-Page">{DefectPage}</span>
            </span>
          </a>
        </li>

        <li>
          <a id="SUMMARY-AND-RECOMMENDATION-href" href="#SUMMARY-AND-RECOMMENDATION">
            <span className="title">
              4.0 SUMMARY AND RECOMMENDATION <span className="leaders" aria-hidden="true"></span>
            </span>
            <span data-href="#SUMMARY-AND-RECOMMENDATION" className="page">
              <span className="visually-hidden">Page&nbsp;</span>
              <span id="SUMMARY-AND-RECOMMENDATION-Page">{ConclusionValue}</span>
            </span>
          </a>
          <ol>
            <li>
              <a id="Summary-href" href="#Summary">
                <span className="title">
                  4.1 Summary<span className="leaders" aria-hidden="true"></span>
                </span>
                <span data-href="#Summary" className="page">
                  <span className="visually-hidden">Page&nbsp;</span>
                  <span id="Summary-Page">{SummaryPage}</span>
                </span>
              </a>
            </li>

            <li>
              <a id="Recommendations-href" href="#Recommendations">
                <span className="title">
                  4.2 Recommendations<span className="leaders" aria-hidden="true"></span>
                </span>
                <span data-href="#Recommendations" className="page">
                  <span className="visually-hidden">Page&nbsp;</span>
                  <span id="Recommendations-Page">{RecommendationsPage}</span>
                </span>
              </a>
            </li>
          </ol>
        </li>

        <li>
          <a id="CONCLUSIONVALUE-href" href="#CONCLUSIONVALUE">
            <span className="title">
              5.0 CONCLUSION <span className="leaders" aria-hidden="true"></span>
            </span>
            <span data-href="#CONCLUSIONVALUE" className="page">
              <span className="visually-hidden">Page&nbsp;</span>
              <span id="CONCLUSIONVALUE-Page">{SummaryAndRecommendationsPage}</span>
            </span>
          </a>
          <ol>
            <li>
              <a id="ConclusionPage-href" href="#ConclusionPage">
                <span className="title">
                  5.1 Conclusion<span className="leaders" aria-hidden="true"></span>
                </span>
                <span data-href="#ConclusionPage" className="page">
                  <span className="visually-hidden">Page&nbsp;</span>
                  <span id="ConclusionPage-Page">{ConclusionValue}</span>
                </span>
              </a>
            </li>
          </ol>
        </li>

        <li>
          <a id="AppendixA-href" href="#appendixA">
            <span className="title">
              Appendix A: As-Built Drawings & Building Plans <span className="leaders" aria-hidden="true"></span>
            </span>
            <span data-href="#AppendixA" className="page">
              <span className="visually-hidden">Page&nbsp;</span>
              <span id="AppendixA-Page">{AppendixA}</span>
            </span>
          </a>
        </li>
        <li>
          <a id="AppendixB-href" href="#appendixB">
            <span className="title">
              Appendix B: Records of Visual Inspection <span className="leaders" aria-hidden="true"></span>
            </span>
            <span data-href="#AppendixB" className="page">
              <span className="visually-hidden">Page&nbsp;</span>
              <span id="AppendixB-Page">{AppendixB}</span>
            </span>
          </a>
        </li>
        <li>
          <a id="AppendixC-href" href="#appendixC">
            <span className="title">
              Appendix C: Inspection Checklist of Façade Record for Elevation
              <span className="leaders" aria-hidden="true"></span>
            </span>
            <span data-href="#AppendixC" className="page">
              <span className="visually-hidden">Page&nbsp;</span>
              <span id="AppendixC-Page">{AppendixC}</span>
            </span>
          </a>
        </li>
        <li>
          <a id="AppendixD-href" href="#appendixD">
            <span className="title">
              Appendix D: Elevation Images <span className="leaders" aria-hidden="true"></span>
            </span>
            <span data-href="#AppendixD" className="page">
              <span className="visually-hidden">Page&nbsp;</span>
              <span id="AppendixD-Page">{AppendixD}</span>
            </span>
          </a>
        </li>
        <li>
          <a id="AppendixE-href" href="#appendixE">
            <span className="title">
              Appendix E: UAS Inspection Report <span className="leaders" aria-hidden="true"></span>
            </span>
            <span data-href="#AppendixE" className="page">
              <span className="visually-hidden">Page&nbsp;</span>
              <span id="AppendixE-Page">{AppendixE}</span>
            </span>
          </a>
        </li>
        <li>
          <a id="AppendixF-href" href="#appendixF">
            <span className="title">
              Appendix F: CP Checklist for PFI Report <span className="leaders" aria-hidden="true"></span>
            </span>
            <span data-href="#AppendixF" className="page">
              <span className="visually-hidden">Page&nbsp;</span>
              <span id="AppendixF-Page">{AppendixF}</span>
            </span>
          </a>
        </li>
      </ol>
    </>
  )
}

export default TableOfContent
