import apiInstance from './configApis'
// get companies
const getCompanies = async (options) => {
  const url = '?' + new URLSearchParams(options).toString()
  try {
    const { data } = await apiInstance.get(`company${url}`)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const getCompanyActive = async () => {
  try {
    const { data } = await apiInstance.get(`company/allActive`)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const createCompany = async ({ name, address, thumbnail, email, fax, uen, telephone }) => {
  try {
    const data = await apiInstance.post(`company`, {
      name,
      address,
      thumbnail,
      email,
      fax,
      uen,
      telephone
    })
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const updateCompany = async ({ name, address, thumbnail, status, id, email, fax, uen, telephone }) => {
  try {
    const { data } = await apiInstance.patch(`company`, {
      name,
      address,
      thumbnail,
      status,
      id,
      email,
      fax,
      uen,
      telephone
    })
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const deleteCompany = async (id) => {
  try {
    const data = await apiInstance.delete(`company/${id}`)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const elevationDetails = async (id) => {
  try {
    const data = await apiInstance.get(`building/elevation-detail/${id}`)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}
const getFrameThermal = async (id) => {
  try {
    const data = await apiInstance.get(`building/request-upload-thermal/${id}`)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const createElevation = async ({ building_id, name, direction, rows, columns, images, data_thumbnail }) => {
  try {
    const data = await apiInstance.post(`building/create-elevation`, {
      building_id,
      name,
      direction,
      rows,
      columns,
      images,
      data_thumbnail
    })
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const editElevation = async ({
  columns,
  rows,
  elevation_id,
  building_id,
  images,
  data_thumbnail,
  direction,
  manual_rows,
  manual_images,
  thermal_images
}) => {
  try {
    const data = await apiInstance.patch(`building/edit-images-in-elevation`, {
      direction,
      columns,
      rows,
      elevation_id,
      building_id,
      images,
      data_thumbnail,
      manual_rows,
      manual_images,
      thermal_images
    })
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const deleteElevation = async (id) => {
  try {
    const data = await apiInstance.delete(`building/delete-elevation/${id}`)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const updateThumbnailElevation = async (info) => {
  try {
    const { data } = await apiInstance.patch(`building/update-data-thumbnail-elevation`, info)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}
const revertDeleteCompany = async (id) => {
  try {
    const data = await apiInstance.patch(`company/restore/${id}`)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const company = {
  getCompanies,
  getCompanyActive,
  createElevation,
  editElevation,
  elevationDetails,
  getFrameThermal,
  deleteElevation,
  createCompany,
  updateCompany,
  deleteCompany,
  updateThumbnailElevation,
  revertDeleteCompany
}
export default company
