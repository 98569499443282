import React, { useState, useEffect } from 'react'
import { useAsyncDebounce } from 'react-table'

// assets
import SearchIcon from '../../../assets/images/svg/search_icon.svg'

export const FilterTable2 = ({ filter, setFilter }) => {
  const [value, setValue] = useState(filter)
  const onChange = useAsyncDebounce((value) => {
    setFilter(value || undefined)
  }, 1000)
  return (
    <div className="ml-auto w-40 md:w-56 lg:w-72 h-8 md:h-10 rounded-2xl bg-gray-200 focus:outline-none">
      <div className="relative flex justify-between items-center w-[98%] sm:w-40 md:w-56 lg:w-72 align-middle whitespace-nowrap">
        <input
          type="search"
          id="search"
          className="w-full sm:w-32 md:w-48 lg:w-64 h-8 md:h-10 border-0 text-xs md:text-sm pl-4 rounded-2xl bg-gray-200 focus:outline-none"
          placeholder="Search..."
          value={value || ''}
          onChange={(e) => {
            setValue(e.target.value)
            onChange(e.target.value)
          }}
        />
        <span className="pr-4">
          <img src={SearchIcon} alt="Search" />
        </span>
      </div>
    </div>
  )
}
