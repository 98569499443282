import React, { Suspense } from 'react'
import { ToastContainer } from 'react-toastify'
import { useLocation, useNavigate } from 'react-router-dom'
import { CloseOutlined } from '@ant-design/icons'

// hocs
import Background from '../../assets/images/background.jpg'
import Logo from '../../assets/images/svg/banner_header.svg'

//cpn

const AuthLayout = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <Suspense fallback="...Loasing">
      <div
        className="flex flex-col justify-center min-h-screen py-6 bg-gray-100 sm:py-12"
        style={{
          backgroundImage: `url(${Background})`,
          backgroundSize: 'cover'
        }}
      >
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="relative py-3 sm:mx-auto">
          <div className="relative max-w-full px-4 py-10 bg-white border border-gray-200 shadow-lg sm:rounded-xl sm:p-10 bg-clip-padding">
            {location.pathname !== '/login' && (
              <div className="cursor-pointer absolute top-8 right-8" onClick={() => navigate('/login')}>
                <CloseOutlined style={{ fontSize: '22px' }} />
              </div>
            )}
            <div className="min-w-full mx-auto">
              <div className="w-full">
                <img src={Logo} className="h-20 mx-auto" alt="Logo" />
              </div>

              <div className="divide-y divide-gray-200">
                <div className="w-full md:w-login">{children}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Suspense>
  )
}

export default AuthLayout
