import React, { useRef } from 'react'
import Textarea from '../Report/Textarea'

function ClassificationH({ report, handleBreakLine, editMode, setValue }) {
  const refClassification = useRef()
  return (
    <>
      <ol className="toc-list" style={{ lineHeight: '30px' }}>
        <li id="DEFECT-DESCRIPTION-AND-CLASSIFICATION">
          <div className="font-bold">
            <h1 className="title font-bold">3.0 DEFECT DESCRIPTION AND CLASSIFICATION</h1>
          </div>
        </li>

        <div className="py-2" id="DEFECT-DESCRIPTION-AND-CLASSIFICATION-container-div">
          <Textarea
            refTextArea={refClassification}
            id="refClassification"
            values={report?.defect_classifications}
            name="defect_classifications"
            setValue={setValue}
            editMode={editMode}
          />
          <div id="refClassification-div-container" className={editMode ? 'hidden' : ''}>
            <div id={`refClassification-div`}>{handleBreakLine(report?.defect_classifications)}</div>
          </div>
        </div>
      </ol>
    </>
  )
}

export default ClassificationH
