import React from 'react'
import { Drawer, Space, Button } from 'antd'

function SmartDrawer(props) {
  const {
    title,
    onClose,
    visible,
    onSubmit,
    width,
    issubmit,
    placement = 'right',
    submitTitle = 'Save',
    ...rest
  } = props

  return (
    <Drawer
      title={`${title}`}
      placement={placement}
      onClose={onClose}
      onSubmit={onSubmit}
      width={width || '1'}
      visible={visible}
      closeIcon={false}
      footer={
        <Space>
          <Button onClick={onClose}>Cancel</Button>
          {issubmit && (
            <Button type="primary" onClick={onSubmit}>
              {submitTitle}
            </Button>
          )}
        </Space>
      }
      {...rest}
    >
      {props.children}
    </Drawer>
  )
}

export default SmartDrawer
