import React from 'react'
import '../Report/index.css'
function ElevationImages({ elevations }) {
  return (
    <>
      <ol className="toc-list" style={{ lineHeight: '30px' }}>
        <li id="appendixD" className="flex gap-4 text-lg font-bold">
          <div className="py-4">Appendix D:</div>
          <div className="py-4 text-center">Elevation Images</div>
        </li>

        {elevations?.map((elevation, index) => (
          <div className="elevation-images font-medium">
            <p className=" mb-2">
              {index + 1}. Elevation {elevation?.name}
            </p>
            <img src={elevation?.url} id={elevation?.id} alt="FBD" />
            <p className="mb-4 text-center">Thumbnail</p>
            {elevation?.composite_drone && (
              <>
                <img className="mt-4" src={elevation?.composite_drone} id={elevation?.id + 'drone'} alt="FBD_drone" />
                <p className="mb-4 text-center">Composite drone image</p>
              </>
            )}
            {elevation?.composite_manual && (
              <>
                <img
                  className="mt-4"
                  src={elevation?.composite_manual}
                  id={elevation?.id + 'manual'}
                  alt="FBD_manual"
                />
                <p className="mb-4 text-center">Composite manual image</p>
              </>
            )}
          </div>
        ))}
      </ol>
    </>
  )
}

export default ElevationImages
