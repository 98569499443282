import React, { createContext, useMemo, useState } from 'react'

const CloseRangeContext = createContext({})

export const CloseRangeProvider = ({ children }) => {
  const [closeRangeDefectId, setCloseRangeDefectId] = useState()
  const [closeRangeImage, setCloseRangeImage] = useState(null)
  const [closeRangePopup, setCloseRangePopup] = useState(false)
  const [closeRangeStatus, setCloseRangeStatus] = useState(null)
  const [imageItem, setImageItem] = useState(null)
  const [imageCloseRangeOnDrawer, setImageCloseRangeOnDrawer] = useState({})

  const closeRangeValue = useMemo(
    () => ({
      closeRangeDefectId,
      setCloseRangeDefectId,
      closeRangeImage,
      setCloseRangeImage,
      closeRangePopup,
      setCloseRangePopup,
      closeRangeStatus,
      setCloseRangeStatus,
      imageItem,
      setImageItem,
      imageCloseRangeOnDrawer,
      setImageCloseRangeOnDrawer
    }),
    [closeRangeDefectId, closeRangeImage, closeRangePopup, closeRangeStatus, imageItem, imageCloseRangeOnDrawer]
  )
  return <CloseRangeContext.Provider value={closeRangeValue}>{children}</CloseRangeContext.Provider>
}

export default CloseRangeContext
