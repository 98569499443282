import React, { useRef } from 'react'
import Textarea from '../Report/Textarea'
// hocs
import useAuth from '../../hooks/useAuth'
import { makeRoles } from '../../utils/common'
function FacadeRecord({ report, building, inspectors, cp, editMode, setValue, handleBreakLine, handleChecked }) {
  const { user } = useAuth()
  const { role } = user?.user || {}
  const refRecord1 = useRef()
  const refRecord2 = useRef()
  const refRecord3 = useRef()
  const refRecord4 = useRef()
  const refRecord5 = useRef()
  const refRecord6 = useRef()
  const refRecord7 = useRef()

  const refInspectionChecklist1 = useRef()
  const refInspectionChecklist2 = useRef()
  const refInspectionChecklist3 = useRef()
  const refInspectionChecklist4 = useRef()
  const refInspectionChecklist5 = useRef()
  const refInspectionChecklist6 = useRef()
  const refInspectionChecklist7 = useRef()
  const refInspectionChecklist8 = useRef()
  const refInspectionChecklist9 = useRef()
  const refInspectionChecklist10 = useRef()
  const refInspectionChecklist11 = useRef()
  const refInspectionChecklist12 = useRef()
  const refInspectionChecklist13 = useRef()

  const handleNumberStorey = (evt) => {
    var charCode = evt.keyCode
    if (refRecord2 === '' && charCode === 48) {
      evt.preventDefault()
    }
    if (evt.shiftKey) {
      evt.preventDefault()
    }

    if (charCode !== 8 && charCode !== 9 && charCode !== 16 && (charCode < 48 || charCode > 57)) evt.preventDefault()
  }
  return (
    <>
      <ol className="toc-list" style={{ lineHeight: '30px' }}>
        <li id="appendixC" className="flex gap-4 text-lg font-bold">
          <div className="py-4">Appendix C:</div>
          <div className="py-4 text-center">Inspection Checklist of Façade Record for Elevation</div>
        </li>

        <div className="pb-10" style={{ fontSize: '16px', lineHeight: 'normal' }}>
          <table className="border-[1px] w-full table-test" style={{ fontSize: '12px' }}>
            <tr className="border-[1px]">
              <td className="border-[1px] p-2 font-bold">Building Address:</td>
              <td colSpan={3} className="border-[1px] p-2">
                {handleBreakLine(building?.address)}
              </td>
            </tr>

            <tr className="border-[1px]">
              <td className="border-[1px] p-2 font-bold">Building Height:</td>
              <td className="border-[1px] p-2 h-8 text-center">
                <Textarea
                  refTextArea={refRecord1}
                  id="refRecord1"
                  values={report?.building_height}
                  name="building_height"
                  setValue={setValue}
                  editMode={editMode}
                />
                <div id="refRecord1-div-container" className={editMode ? 'hidden' : ''}>
                  <div id={`refRecord1-div`}>{handleBreakLine(report?.building_height)}</div>
                </div>
              </td>
              <td className="border-[1px] p-2 font-bold">Number of Storeys:</td>
              <td className="border-[1px] p-2 h-8 text-center">
                <Textarea
                  refTextArea={refRecord2}
                  id="refRecord2"
                  values={report?.storeys}
                  name="storeys"
                  setValue={setValue}
                  editMode={editMode}
                  maxLength={3}
                />
                <div id="refRecord2-div-container" className={editMode ? 'hidden' : ''}>
                  <div id={`refRecord2-div`}>{report?.storeys}</div>
                </div>
              </td>
            </tr>

            <tr className="border-[1px]">
              <td className="border-[1px] p-2 font-bold">Block Number:</td>
              <td className="border-[1px] text-center p-2 h-8">
                <Textarea
                  refTextArea={refRecord3}
                  id="refRecord3"
                  values={report?.block_number}
                  name="block_number"
                  setValue={setValue}
                  editMode={editMode}
                />
                <div id="refRecord3-div-container" className={editMode ? 'hidden' : ''}>
                  <div id={`refRecord3-div`}>{handleBreakLine(report?.block_number)}</div>
                </div>
              </td>
              <td className="border-[1px] p-2 font-bold">Age of Building:</td>
              <td className="border-[1px] text-center p-2 h-8">
                <Textarea
                  refTextArea={refRecord4}
                  id="refRecord4"
                  values={report?.age}
                  name="age"
                  setValue={setValue}
                  editMode={editMode}
                />
                <div id="refRecord4-div-container" className={editMode ? 'hidden' : ''}>
                  <div id={`refRecord4-div`}>{handleBreakLine(report?.age)}</div>
                </div>
              </td>
            </tr>

            <tr className="border-[1px]">
              <td className="border-[1px] p-2 font-bold w-1/4">BCA Notice Number:</td>
              <td className="border-[1px] text-center p-2 h-8 w-1/4">
                <Textarea
                  refTextArea={refRecord5}
                  id="refRecord5"
                  values={report?.bca_notice}
                  name="bca_notice"
                  setValue={setValue}
                  editMode={editMode}
                />
                <div id="refRecord5-div-container" className={editMode ? 'hidden' : ''}>
                  <div id={`refRecord5-div`}>{handleBreakLine(report?.bca_notice)}</div>
                </div>
              </td>
              <td className="border-[1px] p-2 font-bold w-1/4">Report Reference:</td>
              <td className="border-[1px] text-center p-2 h-8 w-1/4">
                <Textarea
                  refTextArea={refRecord6}
                  id="refRecord6"
                  values={report?.report_reference}
                  name="report_reference"
                  setValue={setValue}
                  editMode={editMode}
                />
                <div id="refRecord6-div-container" className={editMode ? 'hidden' : ''}>
                  <div id={`refRecord6-div`}>{handleBreakLine(report?.report_reference)}</div>
                </div>
              </td>
            </tr>

            <tr className="border-[1px]">
              <td className="border-[1px] w-1/3 p-2 font-bold">Inspectors (FI):</td>
              <td colSpan={3} className="border-[1px] p-2">
                {inspectors?.map((inspector, index) => (
                  <div key={inspector.id}>
                    <div>
                      {index + 1}. {inspector?.username} {inspector?.regno ? '(' + inspector?.regno + ')' : ''}
                    </div>
                  </div>

                ))}
              </td>
            </tr>

            <tr className="border-[1px]">
              <td className="border-[1px] p-2 font-bold">Competent Person (CP):</td>
              <td colSpan={3} className="border-[1px] p-2">
                {cp?.username || cp?.regno ? (
                  <p>
                    {cp?.username} {cp?.regno ? '(' + cp?.regno + ')' : ""}
                  </p>
                ) : (
                  <></>
                )}
              </td>
            </tr>

            <tr className="border-[1px]">
              <td className="border-[1px] p-2 font-bold">Company of CP:</td>
              <td colSpan={3} className="border-[1px] p-2 h-8">
                <Textarea
                  refTextArea={refRecord7}
                  id="refRecord7"
                  values={report?.company}
                  name="company"
                  setValue={setValue}
                  editMode={editMode}
                />
                <div id="refRecord7-div-container" className={editMode ? 'hidden' : ''}>
                  <div id={`refRecord7-div`}>{handleBreakLine(report?.company)}</div>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div className="py-4 text-lg font-bold">FAÇADE INSPECTION CHECKLIST</div>

        <div style={{ fontSize: '16px', lineHeight: 'normal' }}>
          <table className="border-[1px] w-full table-test" style={{ fontSize: '12px' }}>
            <tr className="border-[1px]">
              <td className="border-[1px] p-2 font-bold">Date & Time of Inspection:</td>
              <td colSpan={3} className="border-[1px] p-2">
                <Textarea
                  refTextArea={refInspectionChecklist1}
                  id="refInspectionChecklist1"
                  values={report?.inspection_datetime}
                  name="inspection_datetime"
                  setValue={setValue}
                  editMode={editMode}
                />
                <div id="refInspectionChecklist1-div-container" className={editMode ? 'hidden' : ''}>
                  <div id={`refInspectionChecklist1-div`}>{handleBreakLine(report?.inspection_datetime)}</div>
                </div>
              </td>
            </tr>

            <tr className="border-[1px]">
              <td className="border-[1px] p-2 font-bold">Weather Condition:</td>
              <td className="border-[1px] p-2 h-8 m-2 w-1/4">
                <Textarea
                  refTextArea={refInspectionChecklist2}
                  id="refInspectionChecklist2"
                  values={report?.weather_condition}
                  name="weather_condition"
                  setValue={setValue}
                  editMode={editMode}
                />
                <div id="refInspectionChecklist2-div-container" className={editMode ? 'hidden' : ''}>
                  <div id={`refInspectionChecklist2-div`}>{handleBreakLine(report?.weather_condition)}</div>
                </div>
              </td>
              <td className="border-[1px] p-2 font-bold">Last Inspection Date:</td>
              <td className="border-[1px] p-2 h-8 m-2 w-1/4">
                <Textarea
                  refTextArea={refInspectionChecklist3}
                  id="refInspectionChecklist3"
                  values={report?.inspection_lastdate}
                  name="inspection_lastdate"
                  setValue={setValue}
                  editMode={editMode}
                />
                <div id="refInspectionChecklist3-div-container" className={editMode ? 'hidden' : ''}>
                  <div id={`refInspectionChecklist3-div`}>{handleBreakLine(report?.inspection_lastdate)}</div>
                </div>
              </td>
            </tr>

            <tr className="border-[1px]">
              <td className="border-[1px] p-2 font-bold">Elevation Marking/ Reference:</td>
              <td colSpan={3} className="border-[1px] p-2 h-8">
                <Textarea
                  refTextArea={refInspectionChecklist4}
                  id="refInspectionChecklist4"
                  values={report?.elevation_marking}
                  name="elevation_marking"
                  setValue={setValue}
                  editMode={editMode}
                />
                <div id="refInspectionChecklist4-div-container" className={editMode ? 'hidden' : ''}>
                  <div id={`refInspectionChecklist4-div`}>{handleBreakLine(report?.elevation_marking)}</div>
                </div>
              </td>
            </tr>

            <tr className="border-[1px]">
              <td className="border-[1px] p-2 font-bold">Duration of Visual Inspection:</td>
              <td colSpan={3} className="border-[1px] p-2 m-2 w-1/4">
                <Textarea
                  refTextArea={refInspectionChecklist5}
                  id="refInspectionChecklist5"
                  values={report?.duration_visual}
                  name="duration_visual"
                  setValue={setValue}
                  editMode={editMode}
                />
                <div id="refInspectionChecklist5-div-container" className={editMode ? 'hidden' : ''}>
                  <div id={`refInspectionChecklist5-div`}>{handleBreakLine(report?.duration_visual)}</div>
                </div>
              </td>
            </tr>

            <tr className="border-[1px]">
              <td className="border-[1px] p-2 font-bold">Duration of close range inspection:</td>
              <td className="border-[1px] p-2 h-8 m-2 w-1/4">
                <Textarea
                  refTextArea={refInspectionChecklist6}
                  id="refInspectionChecklist6"
                  values={report?.duration_closeup}
                  name="duration_closeup"
                  setValue={setValue}
                  editMode={editMode}
                />
                <div id="refInspectionChecklist6-div-container" className={editMode ? 'hidden' : ''}>
                  <div id={`refInspectionChecklist6-div`}>{handleBreakLine(report?.duration_closeup)}</div>
                </div>
              </td>
              <td className="border-[1px] p-2 font-bold">Location of close-range inspection:</td>
              <td className="border-[1px] p-2 h-8 m-2 w-1/4">
                <Textarea
                  refTextArea={refInspectionChecklist7}
                  id="refInspectionChecklist7"
                  values={report?.location_closeup}
                  name="location_closeup"
                  setValue={setValue}
                  editMode={editMode}
                />
                <div id="refInspectionChecklist7-div-container" className={editMode ? 'hidden' : ''}>
                  <div id={`refInspectionChecklist7-div`}>{handleBreakLine(report?.location_closeup)}</div>
                </div>
              </td>
            </tr>

            <tr className="border-[1px]">
              <td className="border-[1px] p-2 font-bold">Tools used for visual Inspection:</td>
              <td colSpan={3} className="border-[1px] p-2 m-2 w-1/4">
                <Textarea
                  refTextArea={refInspectionChecklist8}
                  id="refInspectionChecklist8"
                  values={report?.tools_used}
                  name="tools_used"
                  setValue={setValue}
                  editMode={editMode}
                />
                <div id="refInspectionChecklist8-div-container" className={editMode ? 'hidden' : ''}>
                  <div id={`refInspectionChecklist8-div`}>{handleBreakLine(report?.tools_used)}</div>
                </div>
              </td>
            </tr>

            <tr className="border-[1px]">
              <td className="border-[1px] p-2 font-bold">Visual Inspection Assessment:</td>
              <td colSpan={3} className="border-[1px] p-2 m-2 w-1/4">
                <Textarea
                  refTextArea={refInspectionChecklist9}
                  id="refInspectionChecklist9"
                  values={report?.inspection_assessment}
                  name="inspection_assessment"
                  setValue={setValue}
                  editMode={editMode}
                />
                <div id="refInspectionChecklist9-div-container" className={editMode ? 'hidden' : ''}>
                  <div id={`refInspectionChecklist9-div`}>{handleBreakLine(report?.inspection_assessment)}</div>
                </div>
              </td>
            </tr>

            <tr className="border-[1px]">
              <td className="border-[1px] p-2 font-bold">Façade Type(s):</td>
              <td colSpan={3} className="border-[1px] p-2 m-2 w-1/4">
                <Textarea
                  refTextArea={refInspectionChecklist10}
                  id="refInspectionChecklist10"
                  values={report?.facade_type}
                  name="facade_type"
                  setValue={setValue}
                  editMode={editMode}
                />
                <div id="refInspectionChecklist10-div-container" className={editMode ? 'hidden' : ''}>
                  <div id={`refInspectionChecklist10-div`}>{handleBreakLine(report?.facade_type)}</div>
                </div>
              </td>
            </tr>

            <tr className="border-[1px]">
              <td className="border-[1px] p-2 font-bold">Cladding Material (if any):</td>
              <td colSpan={3} className="border-[1px] p-2 m-2 w-1/4">
                <Textarea
                  refTextArea={refInspectionChecklist11}
                  id="refInspectionChecklist11"
                  values={report?.cladding_material}
                  name="cladding_material"
                  setValue={setValue}
                  editMode={editMode}
                />
                <div id="refInspectionChecklist11-div-container" className={editMode ? 'hidden' : ''}>
                  <div id={`refInspectionChecklist11-div`}>{handleBreakLine(report?.cladding_material)}</div>
                </div>
              </td>
            </tr>

            <tr className="border-[1px]">
              <td className="border-[1px] p-2 font-bold">Attachments / Appendages Type:</td>
              <td colSpan={3} className="border-[1px] p-2 m-2 w-1/4">
                <Textarea
                  refTextArea={refInspectionChecklist12}
                  id="refInspectionChecklist12"
                  values={report?.attachment}
                  name="attachment"
                  setValue={setValue}
                  editMode={editMode}
                />
                <div id="refInspectionChecklist12-div-container" className={editMode ? 'hidden' : ''}>
                  <div id={`refInspectionChecklist12-div`}>{handleBreakLine(report?.attachment)}</div>
                </div>
              </td>
            </tr>

            <tr className="border-[1px]">
              <td className="border-[1px] p-2 font-bold">
                Any Exterior False Ceiling System? If ‘Yes’, please specify the material used for the ceiling panels.
              </td>
              <td colSpan={3} className="border-[1px] p-2 m-2 w-1/4">
                <Textarea
                  refTextArea={refInspectionChecklist13}
                  id="refInspectionChecklist13"
                  values={report?.exterior_false_ceiling}
                  name="exterior_false_ceiling"
                  setValue={setValue}
                  editMode={editMode}
                />
                <div id="refInspectionChecklist13-div-container" className={editMode ? 'hidden' : ''}>
                  <div id={`refInspectionChecklist13-div`}>{handleBreakLine(report?.exterior_false_ceiling)}</div>
                </div>
              </td>
            </tr>

            <tr className="border-[1px]">
              <td className="border-[1px] p-2 font-bold">Declaration of FI on visual inspection works:</td>
              <td colSpan={3} className="border-[1px] p-2">
                {editMode && (
                  <>
                    <input
                      type="checkbox"
                      disabled={![...makeRoles([13])].includes(role)}
                      onChange={(e) => handleChecked(e)}
                      defaultChecked={inspectors?.find((inspector) => inspector?.id === report?.fi_declare_id)}
                    />
                  </>
                )}
                {''} I have taken photos of the entire façade under CP’s instruction for his assessment.
                <p className="py-2 font-bold">
                  Declared by:
                  {report?.fi_declare_id &&
                    inspectors?.map((inspector, index) => {
                      if (inspector?.id === report?.fi_declare_id) {
                        return (
                          <span>
                            {inspector?.username} - {inspector?.regno}
                          </span>
                        )
                      }
                    })}
                </p>
              </td>
            </tr>
          </table>
        </div>
      </ol>
    </>
  )
}

export default FacadeRecord
