import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import reportTypes from '../../../constant/store/reportTypes'
import StatusButton from '../../Common/status'
import { makeRoles } from '../../../utils/common'
import useAuth from '../../../hooks/useAuth'
import { color } from '../../../constant/styled'
import { format } from 'date-fns'
import Table from '../../Common/table/Table'

import { getInspectionDefectSummary } from '../../../store/thunks/reportThunk'
import SkeletonViewer from '../../Common/skeleton-viewer'
import { CSVLink } from 'react-csv'
import usePrevious from '../../../hooks/usePrevious'
import NotAvailable from '../../Common/NotAvailable'
import PaddingAuto from '../../Layout/PaddingAuto'
import Navigate from '../../Common/Navigate'

const actions = []
const DefectSummary = () => {
  const { id } = useParams()
  const { user } = useAuth()
  const { role } = user?.user || {}
  const dispatch = useDispatch()

  const [renderingProcess, setRenderingProcess] = useState({
    loading: true,
    error: false
  })
  const [fileName, setFileName] = useState('report')
  const {
    contents: contentsReport,
    loadings: loadingsReport,
    errors: errorsReport
  } = useSelector((state) => state.report)
  const buildingDetails = contentsReport[reportTypes.GET_INSPECTION_REPORT_DEFECT_SUMMARY]
  const prevGetListSummary = usePrevious(loadingsReport[reportTypes.GET_INSPECTION_REPORT_DEFECT_SUMMARY])
  const tableContent = contentsReport[reportTypes.GET_INSPECTION_REPORT_DEFECT_SUMMARY]?.defectSummary

  const updatedData = tableContent?.map((item, i) => {
    return {
      ...item,
      count: item.count + '\t',
      reasons: item.reasons.map((reasons) => reasons.label).join(', '),
      photos: item.photos.join(', ') + '\t',
      index: i + 1 + '\t'
    }
  })

  const optionNavigate = [
    { id: 1, title: 'Inspection', canNavigate: true, path: `/inspections` },
    { id: 2, title: 'Inspection Detail', canNavigate: true, path: `/inspections/${id}` },
    {
      id: 3,
      title: 'List Visual Defect Summary',
      canNavigate: false,
      path: `/inspections/defect-sumary/${id}`
    }
  ]

  const defectSummaryColumns = [
    {
      Header: 'S/N',
      accessor: '',
      width: 110,
      Cell: (tableProps) => {
        return <>{parseInt(tableProps.row.id) + 1} </>
      }
    },
    {
      Header: 'Elevation Name',
      accessor: 'elevation_name',
      width: 110
    },
    {
      Header: 'Element',
      accessor: 'element',
      width: 110
    },
    {
      Header: 'Defect',
      accessor: 'tags',
      width: 110
    },
    {
      Header: 'Severity',
      accessor: 'cls',
      width: 110
    },
    {
      Header: 'Defect count',
      accessor: 'count',
      width: 110
    },
    {
      Header: 'Photo#',
      accessor: 'photos',
      width: 110,
      Cell: ({ value }) => {
        return <>{value.join(', ')} </>
      },
      disableSortBy: false
    },
    {
      Header: 'Reason(s) for Defects',
      accessor: 'reasons',
      width: 110,
      Cell: ({ value }) => {
        return <>{value.map((item) => item.label).join(', ')} </>
      },
      disableSortBy: false
    }
  ]

  const handleExportCSV = async (e) => {
    e.preventDefault()
    const element = document.getElementById('exportCSV')
    element.click()
  }

  useEffect(() => {
    dispatch(getInspectionDefectSummary(id))
  }, [dispatch, user, id])

  useEffect(() => {
    if (prevGetListSummary && !loadingsReport[reportTypes.GET_INSPECTION_REPORT_DEFECT_SUMMARY]) {
      if (!errorsReport[reportTypes.GET_INSPECTION_REPORT_DEFECT_SUMMARY]) {
        setRenderingProcess((prev) => ({ ...prev, error: false, loading: false }))
      } else {
        setRenderingProcess((prev) => ({ ...prev, error: true, loading: false }))
      }
    }
  }, [errorsReport, loadingsReport])

  useEffect(() => {
    if (buildingDetails && buildingDetails.building) {
      const d = new Date()
      const day = d.getMonth() + 1 + '/' + d.getDate() + '/' + d.getFullYear()
      const name = `${buildingDetails.building.name ? buildingDetails.building.name : ''}-${day.toString()}`
      setFileName(name)
    }
  }, [buildingDetails])

  const renderSkeleton = () => {
    return (
      <>
        <div className="w-full overflow-auto bg-white border border-gray-300 rounded-xl shadow-lg bg-opacity-90 bg-clip-padding flex flex-col mb-6">
          <div className="flex flex-rowborder-b pt-5 pb-8 px-[30px]">
            <div className="flex justify-between items-stretch w-full relative">
              <div className="flex flex-col gap-4 flex-grow-[4]">
                <SkeletonViewer height={20} width={300} />
                <SkeletonViewer height={20} width={300} />
                <div className="flex items-center gap-2">
                  <SkeletonViewer width={160} height={160} className="mr-4" circle />
                </div>
              </div>
              <div className="flex flex-col gap-4 flex-grow-[6]">
                <SkeletonViewer height={20} width={300} />
                <SkeletonViewer height={28} width={300} />
                <SkeletonViewer height={20} width={300} />
                <SkeletonViewer height={20} width={300} />
              </div>
              <div className=" absolute bottom-0 right-0">
                <SkeletonViewer borderRadius={6} height={52} width={250} />
              </div>
              <div className="flex items-center gap-4 text-white absolute bottom-0 right-0">
                <SkeletonViewer height={52} width={250} borderRadius={6} />
              </div>
            </div>
          </div>
        </div>
        <div style={{ width: '100%', margin: '0 auto', marginTop: '16px' }}>
          <div className="flex items-center flex-wrap gap-2 md:gap-6">
            {actions &&
              Array.from({ length: actions.length + 1 }, function (v, k) {
                return k
              }).map((i) => (
                <div key={i}>
                  <SkeletonViewer borderRadius={16} className="h-8 md:h-10 w-32 md:w-44" />
                </div>
              ))}
            <div className="ml-auto">
              <SkeletonViewer borderRadius={16} className="h-8 md:h-10 w-40 md:w-56 lg:w-72" />
            </div>
          </div>
          <div>
            <div className="block overflow-hidden mt-1" style={{ height: '64vh' }}>
              <SkeletonViewer className="mt-2" height={68} width="100%" />
              {Array.from({ length: 10 }, function (v, k) {
                return k
              }).map((i) => (
                <div key={i}>
                  <SkeletonViewer className="mt-2" borderRadius={12} height={52} width="100%" />
                </div>
              ))}
            </div>
          </div>
          <div className="flex justify-center">
            <SkeletonViewer className="h-8 md:h-10 mt-4" width={300} />
          </div>
        </div>
      </>
    )
  }

  return (
    <div>
      <Navigate options={optionNavigate} isBackButton backToLink={`/inspections/${id}`} />
      <PaddingAuto>
        {renderingProcess.loading ? (
          <>{renderSkeleton()}</>
        ) : renderingProcess.error ? (
          <NotAvailable content={errorsReport[reportTypes.GET_INSPECTION_REPORT_DEFECT_SUMMARY]} />
        ) : (
          <>
            <div className="w-full overflow-auto bg-white border border-gray-300 rounded-xl shadow-lg bg-opacity-90 bg-clip-padding flex flex-col mb-6">
              <div className="flex flex-row text-primary  border-b pt-5 pb-8 px-[30px]">
                <div className="flex justify-between items-stretch w-full   relative">
                  <div className="flex flex-col gap-4 flex-grow-[4]">
                    <div className="flex items-center gap-2">
                      <div>Inspection Name:</div>
                      <div>{buildingDetails?.building.name}</div>
                    </div>
                    <div className="flex items-center gap-2">
                      <div>Address:</div>
                      <div>{buildingDetails?.building.address}</div>
                    </div>
                    <div className="flex items-center gap-2">
                      <div className="relative flex items-center gap-2 group h-[160px] w-[160px] rounded-full shadow-avatar">
                        <img
                          className="w-full h-full rounded-full ml-0 "
                          src={buildingDetails?.building.thumbnail}
                          alt="avt"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-4 flex-grow-[6]">
                    <div className="flex items-center gap-2">
                      <div>Inspection Type:</div>
                      <div>{buildingDetails?.building.type_name}</div>
                    </div>
                    <div className="flex items-center gap-2">
                      <div>Status:</div>
                      <div>
                        {buildingDetails?.building.status === 'Active' ? (
                          <StatusButton color={color.green} bgColor={color.greenLight} title="Active" />
                        ) : buildingDetails?.building.status === 'Completed' ? (
                          <StatusButton color={color.blue} bgColor={color.blueLight} title="Completed" />
                        ) : buildingDetails?.building.status === 'Deleted' ? (
                          <StatusButton color={color.red} bgColor={color.redLight} title="Deleted" />
                        ) : buildingDetails?.building.status === 'Archived' ? (
                          <StatusButton color={color.yellow} bgColor={color.yellowLight} title="Archived" />
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      <div>Date Created:</div>
                      <div>
                        {buildingDetails && format(new Date(buildingDetails?.building.created_at), 'dd/MM/yyyy')}
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      <div>Date Modified :</div>
                      <div>
                        {buildingDetails && format(new Date(buildingDetails?.building.updated_at), 'dd/MM/yyyy')}
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center gap-4 text-white absolute bottom-0 right-0">
                    {[...makeRoles([1, 2, 3, 6, 7, 8, 10, 12])].includes(role) && (
                      <button
                        className="py-4 px-8 rounded-md cursor-pointer bg-blue-button"
                        onClick={(e) => {
                          handleExportCSV(e)
                        }}
                      >
                        <span className="ml-2">Export Visual Defects Summary</span>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* <Table
              columns={ElevationColumns}
              data={elevations}
              actions={actions}
            /> */}
            <Table columns={defectSummaryColumns} data={tableContent} />
            <CSVLink
              data={updatedData}
              filename={`${fileName + ' - Defect Summary'}.csv`}
              headers={[
                { label: 'S/N', key: 'index' },
                { label: 'Elevation Name', key: 'elevation_name' },
                { label: 'Element', key: 'element' },
                { label: 'Defect', key: 'tags' },
                { label: 'Severity', key: 'cls' },
                { label: 'Defect count', key: 'count' },
                { label: 'Photo#', key: 'photos' },
                { label: 'Reason(s) for Defects', key: 'reasons' }
              ]}
              id="exportCSV"
            ></CSVLink>
          </>
        )}
      </PaddingAuto>
    </div>
  )
}

export default DefectSummary
