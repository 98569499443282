import { useContext, useEffect } from 'react'
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom'

const useBlockNavigate = (callback, when = true) => {
  const { navigator } = useContext(NavigationContext)

  useEffect(() => {
    if (!when) return
    const unblock = navigator.block((tx) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          // Automatically unblock the transition so it can play all the way
          // through before retrying it. TODO: Figure out how to re-enable
          // this block if the transition is cancelled for some reason.
          unblock()
          tx.retry()
        }
      }

      callback(autoUnblockingTx)
    })

    return unblock
  }, [navigator, callback, when])
}

export default useBlockNavigate
