import React, { forwardRef, useCallback, useState, useImperativeHandle, useRef, useEffect } from 'react'
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop, convertToPixelCrop } from 'react-image-crop'
import { canvasPreview } from './canvasPreview'
import { isWrongFormatFileCropeper2 } from '../../../utils/fileUtils'
import { typeFile } from '../../../constant/file'
import { onConfirm } from 'react-confirm-pro'
import Modal from '../Modal'
import 'react-image-crop/dist/ReactCrop.css'
import { useDebounceEffect } from '../../../hooks/useDebounceEffect'
import PreviewImage from '../PreviewImage'

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  )
}

const Cropper = forwardRef(({ onCompleteCropper }, ref) => {
  const [openCrop, setOpenCrop] = useState(false)
  const [imageSrc, setImageSrc] = useState(null)
  const [currentFile, setCurrentFile] = useState(null)
  const [completedCrop, setCompletedCrop] = useState()
  const [fileName, setFileName] = useState()
  const [aspect, setAspect] = useState()
  const [cropOptions, setCropOptions] = useState()

  const cropperRef = useRef()
  const imgRef = useRef()

  const handleCloseModal = useCallback(() => {
    setOpenCrop(false)
    setImageSrc(null)
    setCurrentFile(null)
    setCompletedCrop(null)
    setFileName()
  }, [openCrop, imageSrc, currentFile, completedCrop, fileName])

  const handleSaveCropper = useCallback(() => {
    onCompleteCropper(currentFile)
  }, [currentFile, openCrop, completedCrop])

  const onFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setCropOptions(undefined) // Makes crop preview update between images.
      setFileName(e.target.files[0]?.name)
      const isWrongFormat = isWrongFormatFileCropeper2(e.target.files)
      if (isWrongFormat) {
        return onConfirm({
          title: <h3 className="text-center">{`The images must be a file of type: ${typeFile.join()}`}</h3>,
          description: <br />,

          onCancel: () => {},
          buttons: ({ onCancel }) => (
            <div className="custom-buttons">
              <button
                style={{
                  background: 'rgba(37, 99, 235)',
                  color: 'white'
                }}
                onClick={onCancel}
              >
                Ok
              </button>
            </div>
          )
        })
      }
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        () => {
          setImageSrc(reader.result?.toString() || '')
          setOpenCrop(true)
        },
        false
      )
      reader.readAsDataURL(e.target.files[0])
      e.target.value = null
    }
  }

  function onImageLoad(e) {
    const { width, height } = e.currentTarget
    setCropOptions(centerAspectCrop(width, height, 16 / 9))
  }

  useImperativeHandle(ref, () => ({
    openCropModal: () => {
      cropperRef?.current.click()
    },
    closeCropModal: () => {
      setOpenCrop(false)
      setImageSrc(null)
      setCurrentFile(null)
      setCompletedCrop(null)
      setFileName()
    }
  }))

  useDebounceEffect(
    async () => {
      if (completedCrop?.width && completedCrop?.height && imgRef.current && fileName) {
        // We use canvasPreview as it's much faster than imgPreview.
        const crFile = await canvasPreview(imgRef.current, completedCrop, fileName)
        setCurrentFile(crFile)
      }
    },
    100,
    [completedCrop, cropOptions]
  )

  return (
    <React.Fragment>
      <input
        ref={cropperRef}
        id="uploadImageInputB3"
        className="hidden"
        type="file"
        accept="image/*"
        onChange={onFileChange}
      />

      {openCrop && imageSrc && (
        <Modal wrapper="w-auto max-w-md mx-auto" onClose={handleCloseModal} zIndex="z-[50]">
          <div className="min-w-88">
            <div className="flex items-center justify-between p-3 border-b border-solid rounded-t border-slate-200">
              <div className="text-xl text-primary font-semibold ">Update Elevation Thumbnail</div>
              <span className="block w-6 h-6 "></span>
            </div>
            {/*body*/}
            <div className="relative flex flex-auto p-6">
              <div className="w-full min-h-64">
                <ReactCrop
                  crop={cropOptions}
                  onChange={(_, percentCrop) => setCropOptions(percentCrop)}
                  onComplete={(c) => {
                    setCompletedCrop(c)
                  }}
                  aspect={aspect}
                  // minWidth={400}
                  // circularCrop
                >
                  <img
                    ref={imgRef}
                    alt="Crop me"
                    src={imageSrc}
                    // style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                    onLoad={onImageLoad}
                  />
                </ReactCrop>
              </div>
            </div>
            {/* footer */}
            <div className="flex items-center justify-end p-6 border-t border-solid rounded-b border-slate-200">
              <button
                className="px-6 py-3 mb-1 mr-1 text-sm font-bold cursor-pointer text-white uppercase transition-all duration-150 ease-linear bg-blue-button rounded shadow outline-none active:bg-gray-600 hover:shadow-lg focus:outline-none"
                type="button"
                onClick={handleSaveCropper}
              >
                Save
              </button>
            </div>
          </div>
        </Modal>
      )}
    </React.Fragment>
  )
})

export default Cropper
