import apiInstance from './configApis'

const getCompanyRepair = async (id, options) => {
  const url = '?' + new URLSearchParams(options).toString()

  try {
    const data = await apiInstance.get(`/annotation-setting/repairs/${id}${url}`)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}
const createCompanyRepair = async ({ company_id, repair }) => {
  try {
    const data = await apiInstance.post(`/annotation-setting/repair-recommended`, { company_id, repair })
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}
const updateCompanyRepair = async ({ id, repair }) => {
  try {
    const data = await apiInstance.patch(`/annotation-setting/repair-recommended`, { id, repair })
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}
const deleteCompanyRepair = async ({ id }) => {
  try {
    const data = await apiInstance.delete(`/annotation-setting/repair-recommended/${id}`)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}
const getAllCompanyRepair = async (id) => {
  try {
    const data = await apiInstance.get(`/annotation-setting/all-repairs/${id}`)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const repairApis = {
  getCompanyRepair,
  createCompanyRepair,
  updateCompanyRepair,
  deleteCompanyRepair,
  getAllCompanyRepair
}
export default repairApis
