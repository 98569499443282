import { createSlice } from '@reduxjs/toolkit'
import {
  getCompanyRepair,
  createCompanyRepair,
  updateCompanyRepair,
  deleteCompanyRepair,
  getAllCompanyRepair
} from '../thunks/repairThunk'
import repairTypes from '../../constant/store/repairTypes'

const elevationSlice = createSlice({
  name: 'repair',
  initialState: {
    loadings: {},
    errors: {},
    contents: {},
    paginations: {}
  },
  extraReducers: {
    // ACTION TYPE: Get all repair
    [getCompanyRepair.pending]: (state) => {
      state.loadings[repairTypes.GET_COMPANY_REPAIR] = true
    },
    [getCompanyRepair.fulfilled]: (state, { payload }) => {
      state.loadings[repairTypes.GET_COMPANY_REPAIR] = false
      state.contents[repairTypes.GET_COMPANY_REPAIR] = payload.repairs
      state.paginations[repairTypes.GET_COMPANY_REPAIR] = payload.pagination
      state.errors[repairTypes.GET_COMPANY_REPAIR] = ''
    },
    [getCompanyRepair.rejected]: (state, { payload }) => {
      state.loadings[repairTypes.GET_COMPANY_REPAIR] = false
      state.errors[repairTypes.GET_COMPANY_REPAIR] = payload.message
    },

    // ACTION TYPE: create repair
    [createCompanyRepair.pending]: (state) => {
      state.loadings[repairTypes.CREATE_COMPANY_REPAIR] = true
    },
    [createCompanyRepair.fulfilled]: (state, { payload }) => {
      state.loadings[repairTypes.CREATE_COMPANY_REPAIR] = false
      state.contents[repairTypes.GET_COMPANY_REPAIR] = [payload, ...state.contents[repairTypes.GET_COMPANY_REPAIR]]
      state.errors[repairTypes.CREATE_COMPANY_REPAIR] = ''
    },
    [createCompanyRepair.rejected]: (state, { payload }) => {
      state.loadings[repairTypes.CREATE_COMPANY_REPAIR] = false
      state.errors[repairTypes.CREATE_COMPANY_REPAIR] = payload.message
    },
    // ACTION TYPE: update repair
    [updateCompanyRepair.pending]: (state) => {
      state.loadings[repairTypes.UPDATE_COMPANY_REPAIR] = true
    },
    [updateCompanyRepair.fulfilled]: (state, { payload }) => {
      state.loadings[repairTypes.UPDATE_COMPANY_REPAIR] = false
      const index = state.contents[repairTypes.GET_COMPANY_REPAIR].findIndex((reason) => reason.id === payload.id)
      state.contents[repairTypes.GET_COMPANY_REPAIR].splice(index, 1, payload)
      state.errors[repairTypes.UPDATE_COMPANY_REPAIR] = ''
    },
    [updateCompanyRepair.rejected]: (state, { payload }) => {
      state.loadings[repairTypes.UPDATE_COMPANY_REPAIR] = false
      state.errors[repairTypes.UPDATE_COMPANY_REPAIR] = payload.message
    },
    // ACTION TYPE: delete repair
    [deleteCompanyRepair.pending]: (state) => {
      state.loadings[repairTypes.DELETE_COMPANY_REPAIR] = true
    },
    [deleteCompanyRepair.fulfilled]: (state, { payload }) => {
      state.loadings[repairTypes.DELETE_COMPANY_REPAIR] = false
      const index = state.contents[repairTypes.GET_COMPANY_REPAIR].findIndex((reason) => reason.id === payload.id)
      state.contents[repairTypes.GET_COMPANY_REPAIR].splice(index, 1)
      state.errors[repairTypes.DELETE_COMPANY_REPAIR] = ''
    },
    [deleteCompanyRepair.rejected]: (state, { payload }) => {
      state.loadings[repairTypes.DELETE_COMPANY_REPAIR] = false
      state.errors[repairTypes.DELETE_COMPANY_REPAIR] = payload.message
    },
    // ACTION TYPE: Get all company repair
    [getAllCompanyRepair.pending]: (state) => {
      state.loadings[repairTypes.GET_All_COMPANY_REPAIR] = true
    },
    [getAllCompanyRepair.fulfilled]: (state, { payload }) => {
      state.loadings[repairTypes.GET_All_COMPANY_REPAIR] = false
      state.contents[repairTypes.GET_All_COMPANY_REPAIR] = payload.repairs
      state.errors[repairTypes.GET_All_COMPANY_REPAIR] = ''
    },
    [getAllCompanyRepair.rejected]: (state, { payload }) => {
      state.loadings[repairTypes.GET_All_COMPANY_REPAIR] = false
      state.errors[repairTypes.GET_All_COMPANY_REPAIR] = payload.message
    }
  }
})
export default elevationSlice.reducer
