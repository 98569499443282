import { color } from '../../../../constant/styled'
import { BsCameraFill, BsImage } from 'react-icons/bs'
import Modal from '../../../Common/Modal'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useRef } from 'react'
import { useState } from 'react'
import { isEmptyObject } from '../../../../utils/common'
function PopupAddImageType({
  floors,
  gridline,
  closePopupAddCloseRangeByThubmnail,
  handleClickUploadImage,
  handleNumberFloor,
  handleStringGridline,
  handleChangeFloor,
  handleChangeGridline,
  setCameraAddByThumbnail,
  setPopupAddImageType
}) {
  const inputErrors = useRef([])
  const [errors, setErrors] = useState({})

  const handleCloseModal = () => {
    closePopupAddCloseRangeByThubmnail()
  }

  const handleUploadPhoto = (type) => {
    let newError = {}
    if (!inputErrors?.current[0]?.value) {
      newError = { ...newError, floors: 'Floors is required' }
    }
    if (!inputErrors?.current[1]?.value) {
      newError = { ...newError, gridline: 'Gridline is required' }
    }
    setErrors({ ...newError })
    if (isEmptyObject(newError)) {
      if (type === 'upload') {
        handleClickUploadImage()
        setPopupAddImageType(false)
      } else setCameraAddByThumbnail(true)
    }
  }

  return (
    <div>
      <Modal wrapper="w-auto max-w-md mx-auto px-6" onClose={handleCloseModal}>
        <div className="flex items-start justify-center p-3 border-b border-solid rounded-t border-slate-200">
          <h2 className="text-xl text-primary font-semibold">Position</h2>
        </div>

        <div
          className="pt-6 pb-4"
          style={{
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '21px'
          }}
        >
          <div>
            <p className="inline-block"> Floor: </p>
            <input
              onKeyDown={(e) => handleNumberFloor(e)}
              onChange={(e) => handleChangeFloor(e)}
              value={floors}
              ref={(el) => (inputErrors.current[0] = el)}
              className="inline-block mt-1 w-full h-10 rounded-lg border text-center leading-10 font-bold text-primary"
            />
            {errors?.floors && (
              <div className="text-red-500 flex items-center mt-2">
                <ExclamationCircleOutlined />
                <span className="ml-1">{errors.floors}</span>
              </div>
            )}
          </div>
          <div>
            <p className="inline-block mt-4">Gridline: </p>
            <input
              onKeyDown={(e) => handleStringGridline(e)}
              onChange={(e) => handleChangeGridline(e)}
              ref={(el) => (inputErrors.current[1] = el)}
              value={gridline}
              className="inline-block mt-1 w-full h-10 rounded-lg border text-center leading-10 font-bold text-primary"
            />
            {errors?.gridline && (
              <div className="text-red-500 flex items-center mt-2">
                <ExclamationCircleOutlined />
                <span className="ml-1">{errors.gridline}</span>
              </div>
            )}
          </div>
        </div>

        {/* <div
          className="px-2 py-2 flex items-center gap-2 text-xs md:text-sm cursor-pointer"
          onClick={() => {
            handleUploadPhoto('camera')
          }}
        >
          <BsCameraFill
            style={{
              fontSize: '20px',
              color: color.purple
            }}
          />
          Take your picture
        </div> */}
        <div className="flex items-center justify-evenly mt-4 mb-8">
          <div
            className="py-2 border-solid  w-24 border-primary border rounded-xl flex justify-center cursor-pointer bg-red-500"
            onClick={handleCloseModal}
          >
            <span className="text-white font-medium">Cancel</span>
          </div>
          <div
            className="py-2 border-solid  w-24 border-primary border rounded-xl flex justify-center cursor-pointer bg-green-table"
            onClick={() => handleUploadPhoto('upload')}
          >
            <span className="text-white font-medium">Next</span>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default PopupAddImageType
