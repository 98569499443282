import React from 'react'
import { useEffect } from 'react'
import PropTypes from 'prop-types'

const Modal = ({ children, onClose, wrapper = 'w-full', zIndex = 'z-40' }) => {
  useEffect(() => {
    document.body.classList.add('touch-action-scroll')

    return () => {
      document.body.classList.remove('touch-action-scroll')
    }
  }, [])

  return (
    <>
      <div
        className={`fixed inset-0 flex items-center  justify-center overflow-x-hidden overflow-y-auto bg-black/50	 ${zIndex} `}
        onMouseDown={onClose}
      >
        <div className="bg-white border-0 rounded-lg shadow-lg" onMouseDown={(e) => e.stopPropagation()}>
          <div className={wrapper}>{children}</div>
        </div>
      </div>
    </>
  )
}

Modal.propTypes = {
  wrapper: PropTypes.string,
  children: PropTypes.element,
  onClose: PropTypes.func,
  zIndex: PropTypes.string
}

export default Modal
