export const claritasRoles = ['Admin', 'Project', 'AI', 'Reviewer']
export const clientRoles = ['ClientGuest', 'ClientCP', 'ClientImage', 'ClientReviewer', 'ClientAdmin', 'ClientProject']
export const claritasImage = ['Image']
export const claritasCP = ['CP']
export const adminRoles = ['Admin', 'Project', 'ClientAdmin', 'ClientProject']
export const claritasAdmin = ['Admin', 'Project']
export const claritasNotAdmin = ['Reviewer', 'AI']
export const ClientProject = ['ClientProject', 'ClientAdmin']

export const roles = [
  'Project',
  'Admin',
  'Reviewer',
  'AI',
  'Image',
  'ClientAdmin',
  'ClientProject',
  'ClientReviewer',
  'ClientImage',
  'ClientCP',
  'ClientGuest'
]
export const role = [
  { id: 1, value: 'Admin' },
  { id: 2, value: 'Project' },
  { id: 3, value: 'Reviewer' },
  { id: 4, value: 'AI' },
  { id: 5, value: 'Image' },
  { id: 6, value: 'ClientAdmin' },
  { id: 7, value: 'ClientProject' },
  { id: 8, value: 'ClientReviewer' },
  { id: 9, value: 'ClientImage' },
  { id: 10, value: 'ClientCP' },
  { id: 11, value: 'ClientGuest' },
  { id: 12, value: 'CP' },
  { id: 13, value: 'ClientFI' }
]
