import React, { useEffect, useRef } from 'react'
import Select from 'react-select'
import { useField } from 'formik'

const CustomMulSelect = (props) => {
  const { options } = props
  const [field, state, { setValue, setTouched }] = useField(props.field.name)
  const optionRef = useRef(null)

  useEffect(() => {
    if (options !== optionRef) {
      setValue(props?.defaultValue)
    }
  }, [options])

  const onChange = (value) => {
    setValue(value)
  }

  return <Select {...props} isMulti value={state.value} onChange={onChange} onBlur={setTouched} />
}

export default CustomMulSelect
