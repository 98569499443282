import { createAsyncThunk } from '@reduxjs/toolkit'
import reportTypes from '../../constant/store/reportTypes'
import report from '../../apis/reportApis'
import { updateBuilding } from './buildingThunk'

export const getInspectionReport = createAsyncThunk(
  reportTypes.GET_INSPECTION_REPORT,
  async (data, { rejectWithValue }) => {
    try {
      const result = await report.getInspectionReport(data)
      if (result.status === 200) {
        return result.data
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
export const getInspectionDefectSummary = createAsyncThunk(
  reportTypes.GET_INSPECTION_REPORT_DEFECT_SUMMARY,
  async (data, { rejectWithValue }) => {
    try {
      const result = await report.getInspectionDefectSummary(data)
      if (result.status === 200) {
        return result.data
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getCloseRangeDefectSummary = createAsyncThunk(
  reportTypes.GET_CLOSE_RANGE_DEFECT_SUMMARY,
  async (data, { rejectWithValue }) => {
    try {
      const result = await report.getCloseRangeDefectSummary(data)
      if (result.status === 200) {
        return result.data
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const updateInspectionReport = createAsyncThunk(
  reportTypes.UPDATE_INSPECTION_REPORT,
  async (data, { rejectWithValue }) => {
    try {
      const result = await report.updateInspectionReport(data)
      if (result.status === 201) {
        return result.data
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const addInspector = createAsyncThunk(reportTypes.ADD_INSPECTOR, async (data, { rejectWithValue, dispatch }) => {
  try {
    const result = await report.addInspector(data.addInspector)
    if (result.status === 201) {
      dispatch(updateBuilding(data.updateBuilding))
      return result.data
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})
export const addDeclare = createAsyncThunk(reportTypes.ADD_DECLARE, async (data, { rejectWithValue }) => {
  try {
    const result = await report.addDeclare(data)
    if (result.status === 200) {
      return result.data
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})
export const confirmation = createAsyncThunk(reportTypes.ADD_CONFIRMATION, async (data, { rejectWithValue }) => {
  try {
    const result = await report.confirmation(data)
    if (result.status === 200) {
      return result.data
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})
export const lock = createAsyncThunk(reportTypes.ADD_LOCK, async (data, { rejectWithValue }) => {
  try {
    const result = await report.lock(data)
    if (result.status === 200) {
      return result.data
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})
export const getDefectListing = createAsyncThunk(reportTypes.GET_DEFECT_LISTING, async (data, { rejectWithValue }) => {
  try {
    const result = await report.getDefectListing(data)
    if (result.status === 200) {
      return result.data
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})
export const updateLockReport = createAsyncThunk(reportTypes.UPDATE_LOCK_REPORT, async (data, { rejectWithValue }) => {
  try {
    const result = await report.updateLockReport(data)
    if (result.status === 201) {
      return result.data
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})
export const updateReportConfirmation = createAsyncThunk(
  reportTypes.UPDATE_REPORT_CONFIRMATION,
  async (data, { rejectWithValue }) => {
    try {
      const result = await report.updateReportConfirmation(data)
      if (result.status === 201) {
        return result.data
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
