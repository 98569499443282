// libs
import React, { useState, useEffect } from 'react'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import imageCompression from 'browser-image-compression'
// hocs

// actions

// commons
import { toast } from 'react-toastify'
import Standard from '../../Report/Standard'
import CoverPage from '../../Report/CoverPage'
import TableOfContent from '../../Report/TableOfContent'
import InspectionInformation from '../../Report/InspectionInformation'
import Introduction from '../../Report/Introduction'
import Classification from '../../Report/Classification'
import Summary from '../../Report/Summary'
import Conclusion from '../../Report/Conclusion'
import DefectListings from '../../Report/DefectListings'
import PhotoRecords from '../../Report/PhotoRecords'
import { useParams } from 'react-router-dom'
import { uploadElevationImage } from '../../../apis/fileApis'
import { format } from 'date-fns'
// @antd
import {} from '@ant-design/icons'
import reportTypes from '../../../constant/store/reportTypes'
// assets
import usePrevious from '../../../hooks/usePrevious'
// variables
import { useDispatch, useSelector } from 'react-redux'
import '../../Report/index.css'
import BuiltDrawings from '../../Report/BuiltDrawings'
import FacadeRecord from '../../Report/FacadeRecord'
import ElevationImages from '../../Report/ElevationImages'
import UASInspectionReport from '../../Report/UASInspectionReport'
import Checklist from '../../Report/Checklist'

import {
  getInspectionReport,
  updateInspectionReport,
  updateLockReport,
  updateReportConfirmation
} from '../../../store/thunks/reportThunk'
import ClassificationH from '../../Report/ClassificationH'
import LoadingSpinner from '../../Common/loading-spinner/LoadingSpinner'
import SkeletonViewer from '../../Common/skeleton-viewer'
import reportApi from '../../../apis/reportApis'
import Confirm from '../../Report/Confirm'
import Lock from '../../Report/Lock'
import { formatMetadata, isEmptyObject, makeRoles } from '../../../utils/common'
import useAuth from '../../../hooks/useAuth'
import ClassificationCloseRange from '../../Report/ClassificationCloseRange'
import RecordVisual from '../../Report/RecordVisual'
import CloseRangePhotoRecord from '../../Report/CloseRangePhotoRecord'
import NotAvailable from '../../Common/NotAvailable'
import useIdleTimer from '../../../hooks/useIdleTimer'
import PaddingAuto from '../../Layout/PaddingAuto'
import Navigate from '../../Common/Navigate'
import TopIcon from '../../../assets/images/svg/to_top.svg'

pdfMake.vfs = pdfFonts.pdfMake.vfs

const BuildingSummaryReport = () => {
  const dispatch = useDispatch()
  const { reset } = useIdleTimer()
  const { id } = useParams()
  const { user } = useAuth()
  const { role } = user?.user || {}
  const { contents: contentsReport, loadings, errors } = useSelector((state) => state.report)
  const [editMode, setEditMode] = useState(false)
  const [InspectionInformationPage, setInspectionInformationPage] = useState('.')
  const [InspectionDatesPage, setInspectionDatesPage] = useState('.')
  const [InspectionLocationPage, setInspectionLocationPage] = useState('.')
  const [InspectorsPage, setInspectorsPage] = useState('.')
  const [WitnessesPage, setWitnessesPage] = useState('.')
  const [ReportPreparationPage, setReportPreparationPage] = useState('.')
  const [ReferenceDocumentsPage, setReferenceDocumentsPage] = useState('.')
  const [IntroductionPage, setIntroductionPage] = useState('.')
  const [BackgroundInformationPage, setBackgroundInformationPage] = useState('.')
  const [ObjectivesPage, setObjectivesPage] = useState('.')
  const [FacadeSystemsPage, setFacadeSystemsPage] = useState('.')
  const [DefectPage, setDefectPage] = useState('.')
  const [SummaryAndRecommendationsPage, setSummaryAndRecommendationsPage] = useState('.')
  const [SummaryPage, setSummaryPage] = useState('.')
  const [RecommendationsPage, setRecommendationsPage] = useState('.')
  const [ConclusionValue, setConclusionValue] = useState('.')
  const [ConclusionPage, setConclusionPage] = useState('.')
  const [appendixA, setAppendixA] = useState('.')
  const [appendixB, setAppendixB] = useState('.')
  const [appendixC, setAppendixC] = useState('.')
  const [appendixD, setAppendixD] = useState('.')
  const [appendixE, setAppendixE] = useState('.')
  const [appendixF, setAppendixF] = useState('.')
  const [defectListingsV, setDefectListingsV] = useState('.')
  const [report, setReport] = useState(null)
  const [loading, setLoading] = useState(false)
  const [loadingDownLoad, setLoadingDownload] = useState(false)
  const [renderingProcess, setRenderingProcess] = useState({
    loading: true,
    error: false
  })
  const [declaration, setDeclaration] = useState({})
  const [confirmation, setConfirmation] = useState({})
  const reportDefectListing = contentsReport[reportTypes.GET_INSPECTION_REPORT]?.defectListing
  const reportCloseRangeDefectListing = contentsReport[reportTypes.GET_INSPECTION_REPORT]?.crDefectListing
  const prevUpdateReportFetching = usePrevious(loadings[reportTypes.UPDATE_INSPECTION_REPORT])
  const prevGetReportFetching = usePrevious(loadings[reportTypes.GET_INSPECTION_REPORT])
  const prevUpdateLockReportFetching = usePrevious(loadings[reportTypes.UPDATE_LOCK_REPORT])
  const prevUpdateConfirmReportFetching = usePrevious(loadings[reportTypes.UPDATE_REPORT_CONFIRMATION])

  const [confirms, setConfirms] = useState(false)
  const [addConfirm, setAddConfirm] = useState('')

  const [locks, setLocks] = useState(false)
  const [visible, setVisible] = useState(false)

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop
    if (scrolled > 300) {
      setVisible(true)
    } else if (scrolled <= 300) {
      setVisible(false)
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
      /* you can also use 'auto' behaviour 
         in place of 'smooth' */
    })
  }
  window.addEventListener('scroll', toggleVisible)

  const optionNavigate = [
    { id: 1, title: 'Inspection', canNavigate: true, path: `/inspections` },
    { id: 2, title: 'Inspection Detail', canNavigate: true, path: `/inspections/${id}` },
    {
      id: 3,
      title: 'PFI Report',
      canNavigate: false,
      path: `/inspections/inspection-report/${id}`
    }
  ]
  useEffect(() => {
    dispatch(getInspectionReport(id))
  }, [id])

  useEffect(() => {
    setReport(contentsReport[reportTypes.GET_INSPECTION_REPORT])
  }, [contentsReport])

  useEffect(() => {
    if (prevUpdateLockReportFetching && !loadings[reportTypes.UPDATE_LOCK_REPORT]) {
      if (!errors[reportTypes.UPDATE_LOCK_REPORT]) {
        if (!contentsReport[reportTypes.GET_INSPECTION_REPORT].report.locked) {
          toast.success('Update Sucess', {
            theme: 'dark',
            autoClose: 2000
          })
        }
      } else {
        toast.error(errors[reportTypes.UPDATE_LOCK_REPORT], {
          theme: 'dark',
          autoClose: 2000
        })
      }
    }
  }, [errors, loadings, prevUpdateLockReportFetching])

  useEffect(() => {
    if (prevUpdateConfirmReportFetching && !loadings[reportTypes.UPDATE_REPORT_CONFIRMATION]) {
      setAddConfirm('')
      if (!errors[reportTypes.UPDATE_REPORT_CONFIRMATION]) {
        closePopupConfirm()
        toast.success('Confirm Sucess', {
          theme: 'dark',
          autoClose: 2000
        })
      } else {
        closePopupConfirm()
        toast.error(errors[reportTypes.UPDATE_REPORT_CONFIRMATION], {
          theme: 'dark',
          autoClose: 2000
        })
      }
    }
  }, [errors, loadings, prevUpdateConfirmReportFetching])

  const handleBreakLine = (data) => {
    const newData = data?.split('\n')
    return (
      <div>
        {newData?.map((text) => (
          <p className="reportContentBreak">{text === '' ? <br /> : text}</p>
        ))}
      </div>
    )
  }

  const changeUrlToBase64 = async (array) => {
    if (array && array.length > 0) {
      const data = await Promise.all(
        array.map(async (src) => {
          let url
          let fromUrl
          let composite_drone
          let composite_manual
          if (src.url) {
            url = await convertURLImageToBase64(src.url)
          }
          if (src.fromUrl) {
            fromUrl = await convertURLImageToBase64(src.fromUrl)
          }
          if (src?.composite_drone) {
            composite_drone = await convertURLImageToBase64(src.composite_drone)
          }
          if (src?.composite_manual) {
            composite_manual = await convertURLImageToBase64(src.composite_manual)
          }

          const object = {
            ...src,
            url,
            fromUrl,
            composite_drone,
            composite_manual
          }

          return object
        })
      )
      //   completedArray = completedArray.concat(data)
      // }
      // console.log('completed', completedArray)
      // for (let index = 0; index < array.length; index++) {
      //   let url
      //   let fromUrl
      //   let composite_drone
      //   let composite_manual
      //   if (array[index].url) {
      //     url = await convertURLImageToBase64(array[index].url)
      //   }
      //   if (array[index].fromUrl) {
      //     fromUrl = await convertURLImageToBase64(array[index].fromUrl)
      //   }
      //   if (array[index]?.composite_drone) {
      //     composite_drone = await convertURLImageToBase64(array[index].composite_drone)
      //   }
      //   if (array[index]?.composite_manual) {
      //     composite_manual = await convertURLImageToBase64(array[index].composite_manual)
      //   }

      //   const object = {
      //     ...array[index],
      //     url,
      //     fromUrl,
      //     composite_drone,
      //     composite_manual
      //   }
      //   data[index] = object
      // }

      return data
    } else {
      return []
    }
  }

  const generatePDF = async () => {
    setLoadingDownload(true)
    // let images = document.getElementById('pdfContent').getElementsByTagName('img')
    // if (images && images.length > 0) {
    //   for (let index = 0; index < images.length; index++) {
    //     const url = await handleImage(images[index])
    //     images[index].src = url
    //   }
    // }

    const logo = document.getElementById('logo')
    const logoUrl = await handleImage(logo)
    logo.src = logoUrl

    let elevationImages = []
    for (const elevationImage of report?.elevations) {
      const element = document.getElementById(elevationImage.id)
      const elementDrone = document.getElementById(elevationImage?.id + 'drone')
      const elementManual = document.getElementById(elevationImage?.id + 'manual')
      const newElevationObject = {
        ...elevationImage,
        height: (element?.offsetHeight / 96) * 72,
        width: (element?.offsetWidth / 96) * 72,
        heightDrone: (elementDrone?.offsetHeight / 96) * 72,
        widthDrone: (elementDrone?.offsetWidth / 96) * 72,
        heightManual: (elementManual?.offsetHeight / 96) * 72,
        widthManual: (elementManual?.offsetWidth / 96) * 72
      }
      elevationImages.push(newElevationObject)
    }

    const drawingData = await changeUrlToBase64(report?.report?.drawing)
    const elevationData = await changeUrlToBase64(elevationImages)
    const checkedReportDefectListing = reportDefectListing.filter((image) => image.photoRecord === true)
    const defectData = await changeUrlToBase64(checkedReportDefectListing)
    const checkedReportCloseRangeDefectListing = reportCloseRangeDefectListing.filter(
      (image) => image.photoRecord === true
    )
    const crdefectData = await changeUrlToBase64(checkedReportCloseRangeDefectListing)
    const imageB3Data = await changeUrlToBase64(report?.report?.site_layout)
    const imageB4Data = await changeUrlToBase64(report?.report?.site_buildings)
    const imageB5Data = await changeUrlToBase64(report?.report?.site_others)
    const record = report?.report?.record ? report?.report?.record : []
    const inspectors = report?.inspectors ? report?.inspectors : []
    const defectSummaryData = report?.defectSummary ? report?.defectSummary : []
    const closeRangeDefectSummaryData = report?.crDefectSummary ? report?.crDefectSummary : []
    const closeRangeDefectListingData = report?.crDefectListing ? report?.crDefectListing : []

    // return

    let docDefinition = {
      pageMargins: [50, 50, 50, 50],
      footer: function (currentPage, pageCount) {
        if (currentPage > 3 && currentPage < pageCount) {
          return [
            { text: currentPage.toString() + ' of ' + pageCount.toString(), alignment: 'center', margin: [0, 20, 0, 0] }
          ]
        } else {
          return
        }
      },
      pageOrientation: 'portrait',
      pageSize: 'A4',
      content: [
        {
          text: 'Standard Certification by Competent Person',
          style: ['standardHeader', 'defaultSpacingHeight']
        },
        {
          text: 'for Periodic Façade Inspection of Buildings',
          style: ['standardHeader', 'defaultSpacingHeight']
        },
        {
          text: [
            'In accordance with Section 28(6) of the Building Control Act (the “Act”) and Regulations 15, 16 and 17 of (Periodic Inspection of Buildings and Building Facades) Regulations 2021 (the “Regulations”), I,',
            {
              text: report?.cp?.username && report?.cp?.regno ? `${report?.cp?.username} (${report?.cp?.regno})` : ' ',
              bold: true
            },
            ', the Competent Person appointed by the building owner under Section 28(3) of the Act have conducted an inspection on the condition and integrity of the building façade and hereby submit the report of the results of the inspection. I certify that the inspection was carried out and the report was prepared by me in accordance with the provisions of the Act and the Regulations.'
          ],
          style: ['standardBody', 'defaultSpacingHeight'],
          margin: [0, 20, 0, 0]
        },
        {
          table: {
            widths: ['*', '*'],
            defaultBorder: false,
            dontBreakRows: true,
            body: [
              [
                {
                  text: [
                    `Competent Person: `,
                    {
                      text: report?.cp?.username
                        ? `         ${report?.cp?.username}            `
                        : `                                     `,
                      bold: true,
                      decoration: 'underline'
                    }
                  ],
                  alignment: 'center'
                },
                {
                  text: [
                    `Date: `,
                    { text: `                                          `, bold: true, decoration: 'underline' }
                  ],
                  margin: [50, 0, 0, 0]
                }
              ]
            ]
          },
          layout: 'noBorders',
          margin: [0, 50, 0, 0]
        },
        {
          text: 'VISUAL FACADE INSPECTION REPORT',
          pageBreak: 'before',
          style: 'coverPage',
          margin: [0, 100, 0, 0]
        },
        {
          image: logo.src,
          width: 80,
          height: 80,
          absolutePosition: {
            x: 460,
            y: 30
          }
        },
        {
          text: 'ON',
          style: 'coverPage'
        },
        {
          text: report?.building?.address ? `${report?.building?.address}` : '',
          style: 'coverPage'
        },
        {
          text: 'DOCUMENT PREPARATION RECORD',
          style: 'coverPage',
          margin: [0, 40, 0, 0]
        },
        {
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            widths: [50, 110, 110, 200],
            defaultBorder: true,
            dontBreakRows: true,

            body: [
              [
                { text: 'Rev', alignment: 'center', style: 'tableTh' },
                { text: 'Date', alignment: 'center', style: 'tableTh' },
                { text: 'Prepared By', alignment: 'center', style: 'tableTh' },
                { text: 'Description', alignment: 'center', style: 'tableTh' }
              ],
              // ...(exportPhonebook ? [{ id: 'exportExcel', text: i18n.t('export_excel'), noneDisplay: true }] : []),
              ...record.map((r, index) => {
                return [`${index + 1}.0`, format(new Date(r.date), 'dd/MM/yyyy'), `${r.name}`, `${r.description}`]
              })
            ]
          },
          margin: [0, 50, 0, 0],
          style: 'coverPageAddress'
        },
        {
          text: [
            report?.company?.name ? ` ${report?.company?.name}` : '',
            '   UEN:',
            report?.company?.uen ? ` (${report?.company?.uen})` : '()'
          ],
          style: 'coverPageAddress',
          margin: [0, 30, 0, 10]
        },
        {
          text: `${report?.company?.address}`,
          style: 'coverPageAddress',
          margin: [0, 0, 0, 10]
        },
        {
          text: [
            'Tel:',
            report?.company?.telephone ? ` ${report?.company?.telephone}` : '',
            '   Fax:',
            report?.company?.fax ? ` (${report?.company?.fax})` : ''
          ],
          style: 'coverPageAddress',
          margin: [0, 0, 0, 10]
        },
        {
          text: ['Email:', report?.company?.email ? ` ${report?.company?.email}` : ''],
          style: 'coverPageAddress',
          margin: [0, 0, 0, 10]
        },
        {
          toc: {
            title: { text: 'TABLE OF CONTENTS', style: 'h0' },
            numberStyle: { bold: true }
          },
          pageBreak: 'before'
        },
        {
          text: '1.0 INTRODUCTION',
          style: 'h1',
          pageBreak: 'before',
          tocItem: true,
          tocMargin: [0, 14, 0, 0],
          tocStyle: 'h1'
        },
        {
          text: '1.1 Background Information',
          style: 'fontNomal',

          tocItem: true,
          tocMargin: [18, 6, 0, 0],
          tocStyle: 'fontNomal'
        },
        {
          text: report?.report?.background ? `${report?.report?.background}` : '',
          margin: [18, 10, 0, 10],
          alignment: 'justify'
        },
        {
          text: '1.2 Objectives',
          style: 'fontNomal',

          tocItem: true,
          tocMargin: [18, 6, 0, 0],
          tocStyle: 'fontNomal'
        },
        {
          text: report?.report?.objectives ? `${report?.report?.objectives}` : '',
          margin: [18, 10, 0, 10],
          alignment: 'justify'
        },
        {
          text: '1.3 Facade Systems',
          style: 'fontNomal',

          tocItem: true,
          tocMargin: [18, 6, 0, 0],
          tocStyle: 'fontNomal'
        },
        {
          text: report?.report?.systems ? `${report?.report?.systems}` : '',
          margin: [18, 10, 0, 10],
          alignment: 'justify'
        },
        {
          text: '2.0 INSPECTION INFORMATION',
          style: 'h1',
          pageBreak: 'before',
          tocItem: true,
          tocMargin: [0, 14, 0, 0],
          tocStyle: 'h1'
        },
        {
          text: '2.1 Inspection Location',
          style: 'fontNomal',

          tocItem: true,
          tocMargin: [18, 6, 0, 0],
          tocStyle: 'fontNomal'
        },
        {
          text: 'The visual façade inspection was carried out at:',
          margin: [18, 18, 0, 0]
        },
        {
          text: report?.building?.address ? `${report?.building?.address}` : '',
          margin: [18, 8, 0, 0],
          alignment: 'justify'
        },
        {
          text: report?.report?.location ? `${report?.report?.location}` : ``,
          margin: [18, 8, 0, 10],
          alignment: 'justify'
        },
        {
          text: '2.2 Inspection Dates',
          style: 'fontNomal',
          tocItem: true,
          tocMargin: [18, 6, 0, 0],
          tocStyle: 'fontNomal'
        },
        {
          text: 'The façade inspection was carried out using UAS on the following dates:',
          margin: [18, 18, 0, 0]
        },
        {
          text: report?.report?.uas_flight_datetime ? `${report?.report?.uas_flight_datetime}` : '',
          margin: [18, 10, 0, 0],
          alignment: 'justify'
        },
        {
          text: 'The close-up visual façade inspection was carried out on the following dates:',
          margin: [18, 18, 0, 0]
        },
        {
          text: report?.report?.inspection_datetime ? `${report?.report?.inspection_datetime}` : '',
          margin: [18, 10, 0, 0],
          alignment: 'justify'
        },
        {
          text: report?.report?.dates ? `${report?.report?.dates}` : '',
          margin: [18, 18, 0, 10],
          alignment: 'justify'
        },

        {
          text: '2.3 Inspectors',
          style: 'fontNomal',
          tocItem: true,
          tocMargin: [18, 6, 0, 0],
          tocStyle: 'fontNomal'
        },
        {
          text: 'The façade inspection was carried out by the following registered facade inspectors:',
          margin: [18, 18, 0, 0]
        },
        {
          text: 'Competent Person:',
          margin: [18, 18, 0, 0]
        },
        {
          text:
            report?.cp?.username || report?.cp?.regno
              ? `- ${report?.cp?.username} ${report?.cp?.regno ? '(' + report?.cp?.regno + ')' : ''}`
              : '',
          margin: [18, 10, 0, 0]
        },
        {
          text: 'Facade inspectors:',
          margin: [18, 18, 0, 0]
        },
        ...inspectors.map((inspector) => {
          return {
            text: `- ${inspector?.username} ${inspector?.regno ? '(' + inspector?.regno + ')' : ''}\n`,
            margin: [18, 10, 0, 0]
          }
        }),
        {
          text: 'Additional personnel involved are',
          margin: [18, 18, 0, 0]
        },
        {
          text: report?.report?.inspectors_comment ? `${report?.report?.inspectors_comment}` : '',
          margin: [18, 10, 0, 10],
          alignment: 'justify'
        },
        {
          text: '2.4 Witnesses',
          style: 'fontNomal',

          tocItem: true,
          tocMargin: [18, 6, 0, 0],
          tocStyle: 'fontNomal'
        },
        {
          text: 'The façade inspection was witnessed by:',
          margin: [18, 18, 0, 0]
        },
        {
          text: report?.report?.witnesses ? `${report?.report?.witnesses}` : '',
          margin: [18, 10, 0, 10],
          alignment: 'justify'
        },
        {
          text: '2.5 Report Preparation',
          style: 'fontNomal',

          tocItem: true,
          tocMargin: [18, 6, 0, 0],
          tocStyle: 'fontNomal'
        },
        {
          text: 'The façade inspection report was prepared by:',
          margin: [18, 18, 0, 0]
        },
        {
          text: report?.report?.preparation ? `${report?.report?.preparation}` : '',
          margin: [18, 10, 0, 10],
          alignment: 'justify'
        },
        {
          text: '2.6 Reference Documents',
          style: 'fontNomal',
          tocItem: true,
          tocMargin: [18, 6, 0, 0],
          tocStyle: 'fontNomal'
        },
        {
          text: report?.report?.reference ? `${report?.report?.reference}` : '',
          margin: [18, 10, 0, 0],
          alignment: 'justify'
        },
        {
          text: '3.0 DEFECT DESCRIPTION AND CLASSIFICATION',
          style: 'h1',
          pageBreak: 'before',
          tocItem: true,
          tocMargin: [0, 14, 0, 0],
          tocStyle: 'h1'
        },
        {
          text: report?.report?.defect_classifications ? `${report?.report?.defect_classifications}` : '',
          margin: [18, 10, 0, 10],
          alignment: 'justify'
        },

        {
          text: 'Table 1: VISUAL INSPECTION SUMMARY',
          alignment: 'center',
          pageBreak: 'before',
          pageOrientation: 'landscape',
          margin: [0, 0, 16, 30],
          fontSize: 18,
          bold: true
        },
        defectSummaryData.length > 0
          ? {
              table: {
                // headerRows: 1,
                widths: [30, 78, 80, 80, 55, 37, '*', '*'],
                dontBreakRows: true,
                body: [
                  [
                    { text: 'S/N', style: 'tableTh', alignment: 'center' },
                    { text: 'Elevation', style: 'tableTh', alignment: 'center' },
                    { text: 'Element', style: 'tableTh', alignment: 'center' },
                    { text: 'Defect', style: 'tableTh', alignment: 'center' },
                    { text: 'Severity', style: 'tableTh', alignment: 'center' },
                    { text: 'Defect Count', style: 'tableTh', alignment: 'center' },
                    { text: 'Photo # (Record)', style: 'tableTh', alignment: 'center' },
                    { text: 'Reason(s) for Defects', style: 'tableTh', alignment: 'center' }
                  ],
                  ...defectSummaryData.map((defectSummary, index) => {
                    return [
                      { text: `${index + 1}`, style: 'tableTd', alignment: 'center' },
                      {
                        text: defectSummary?.elevation_name ? `${defectSummary?.elevation_name}` : ' ',
                        style: 'tableTd',
                        alignment: 'center'
                      },
                      {
                        text: defectSummary?.element ? `${defectSummary?.element}` : ' ',
                        style: 'tableTd',
                        alignment: 'center'
                      },
                      {
                        text: defectSummary?.tags ? `${defectSummary?.tags}` : ' ',
                        style: 'tableTd',
                        alignment: 'center'
                      },
                      {
                        text: defectSummary?.cls ? `${defectSummary?.cls}` : ' ',
                        style: 'tableTd',
                        alignment: 'center'
                      },
                      {
                        text: defectSummary?.count ? `${defectSummary?.count}` : ' ',
                        style: 'tableTd',
                        alignment: 'center'
                      },
                      {
                        text:
                          defectSummary?.photos?.length > 0
                            ? defectSummary?.photos?.map((photo, index) => {
                                return `${photo}${index === defectSummary?.photos?.length - 1 ? '' : ', '}`
                              })
                            : ' ',
                        style: 'tableTd',
                        alignment: 'center'
                      },
                      {
                        text:
                          defectSummary?.reasons?.length > 0
                            ? defectSummary?.reasons?.map((reason, index) => {
                                return `${reason?.label}${index === defectSummary?.reasons?.length - 1 ? '' : ', '}`
                              })
                            : ' ',
                        style: 'tableTd',
                        alignment: 'center'
                      }
                    ]
                  })
                ]
              }
            }
          : {
              text: 'Visual Inspection Data is not included in this report',
              style: 'coverPageAddress',
              margin: [0, 0, 0, 10]
            },
        {
          text: 'Table 2: CLOSE RANGE INSPECTION SUMMARY',
          alignment: 'center',
          pageBreak: 'before',
          pageOrientation: 'landscape',
          margin: [0, 0, 16, 30],
          fontSize: 18,
          bold: true
        },
        closeRangeDefectSummaryData.length > 0
          ? {
              table: {
                // headerRows: 1,
                widths: [30, 78, 80, 80, 55, 37, '*', '*'],
                dontBreakRows: true,
                body: [
                  [
                    { text: 'S/N', style: 'tableTh', alignment: 'center' },
                    { text: 'Elevation', style: 'tableTh', alignment: 'center' },
                    { text: 'Element', style: 'tableTh', alignment: 'center' },
                    { text: 'Defect', style: 'tableTh', alignment: 'center' },
                    { text: 'Severity', style: 'tableTh', alignment: 'center' },
                    { text: 'Defect Count', style: 'tableTh', alignment: 'center' },
                    { text: 'Photo # (Record)', style: 'tableTh', alignment: 'center' },
                    { text: 'Reason(s) for Defects', style: 'tableTh', alignment: 'center' }
                  ],
                  ...closeRangeDefectSummaryData.map((crDefectSummary, index) => {
                    return [
                      { text: `${index + 1}`, style: 'tableTd', alignment: 'center' },
                      {
                        text: crDefectSummary?.elevation_name ? `${crDefectSummary?.elevation_name}` : ' ',
                        style: 'tableTd',
                        alignment: 'center'
                      },
                      {
                        text: crDefectSummary?.element ? `${crDefectSummary?.element}` : ' ',
                        style: 'tableTd',
                        alignment: 'center'
                      },
                      {
                        text: crDefectSummary?.tags ? `${crDefectSummary?.tags}` : ' ',
                        style: 'tableTd',
                        alignment: 'center'
                      },
                      {
                        text: crDefectSummary?.cls ? `${crDefectSummary?.cls}` : ' ',
                        style: 'tableTd',
                        alignment: 'center'
                      },
                      {
                        text: crDefectSummary?.count ? `${crDefectSummary?.count}` : ' ',
                        style: 'tableTd',
                        alignment: 'center'
                      },
                      {
                        text:
                          crDefectSummary?.photos?.length > 0
                            ? crDefectSummary?.photos?.map((photo, index) => {
                                return `${photo}${index === crDefectSummary?.photos?.length - 1 ? '' : ', '}`
                              })
                            : ' ',
                        style: 'tableTd',
                        alignment: 'center'
                      },
                      {
                        text:
                          crDefectSummary?.reasons?.length > 0
                            ? crDefectSummary?.reasons?.map((reason, index) => {
                                return `${reason?.label}${index === crDefectSummary?.reasons?.length - 1 ? '' : ', '}`
                              })
                            : ' ',
                        style: 'tableTd',
                        alignment: 'center'
                      }
                    ]
                  })
                ]
              }
            }
          : {
              text: 'Close Range Inspection Data is not included in this report',
              style: 'coverPageAddress',
              margin: [0, 0, 0, 10]
            },
        {
          text: '4.0 SUMMARY AND RECOMMENDATION',
          style: 'h1',
          pageBreak: 'before',
          tocItem: true,
          tocMargin: [0, 14, 0, 0],
          tocStyle: 'h1',
          pageOrientation: 'portrait'
        },
        {
          text: '4.1 Summary',
          style: 'fontNomal',

          tocItem: true,
          tocMargin: [18, 6, 0, 0],
          tocStyle: 'fontNomal'
        },
        {
          text: report?.report?.summary ? `${report?.report?.summary}` : '',
          margin: [18, 10, 0, 10],
          alignment: 'justify'
        },
        {
          text: '4.2 Recommendations',
          style: 'fontNomal',

          tocItem: true,
          tocMargin: [18, 6, 0, 0],
          tocStyle: 'fontNomal'
        },
        {
          text: report?.report?.recommendations ? `${report?.report?.recommendations}` : '',
          margin: [18, 10, 0, 10],
          alignment: 'justify'
        },

        {
          text: '5.0 CONCLUSION',
          style: 'h1',
          pageBreak: 'before',
          tocItem: true,
          tocMargin: [0, 14, 0, 0],
          tocStyle: 'h1',
          pageOrientation: 'portrait'
        },
        {
          text: '5.1 Conclusion',
          style: 'fontNomal',

          tocItem: true,
          tocMargin: [18, 6, 0, 0],
          tocStyle: 'fontNomal'
        },
        {
          text: report?.report?.conclusion ? `${report?.report?.conclusion}` : '',
          margin: [18, 10, 0, 10],
          alignment: 'justify'
        },

        {
          text: 'Appendix A: As-Built Drawings & Building Plans',
          style: 'h1',
          pageBreak: 'before',
          tocItem: true,
          tocMargin: [0, 16, 0, 0],
          tocStyle: 'h1'
        },
        ...drawingData
          .map((drawing) => {
            return [
              {
                image: `${drawing.url}`,
                alignment: 'center',
                width: 500,
                margin: [0, 10, 0, 0]
              },
              { text: drawing.title ? `${drawing.title}` : '', margin: [0, 10, 0, 0], alignment: 'center' },
              {
                text: drawing.comment ? `${drawing.comment}` : '',
                margin: [0, 10, 0, 20],
                alignment: 'center',
                fontSize: 14
              }
            ]
          })
          .map((r) =>
            r.map((d) => {
              return d
            })
          ),

        {
          text: 'Appendix B: Records of Visual Inspection',
          style: 'h1',
          pageBreak: 'before',
          pageOrientation: 'landscape',
          tocItem: true,
          tocMargin: [0, 16, 0, 0],
          tocStyle: 'h1'
        },
        {
          text: 'Table 3: Defects Identified During Visual Inspection',
          alignment: 'center',
          pageSize: 'A4',
          pageOrientation: 'landscape',
          margin: [0, 20, 16, 30],
          fontSize: 18,
          bold: true
        },
        reportDefectListing.length > 0
          ? {
              table: {
                // headerRows: 1,
                widths: [35, 55, 75, 55, 55, 46, 40, 90, 90, 80],
                dontBreakRows: true,
                body: [
                  [
                    { text: 'ID', style: 'tableTh', alignment: 'center' },
                    { text: 'Elevation', style: 'tableTh', alignment: 'center' },
                    { text: 'Location', style: 'tableTh', alignment: 'center' },
                    { text: 'Element', style: 'tableTh', alignment: 'center' },
                    { text: 'Defect', style: 'tableTh', alignment: 'center' },
                    { text: 'Severity', style: 'tableTh', alignment: 'center' },
                    { text: 'Photo Record', style: 'tableTh', alignment: 'center' },
                    { text: 'Reason for Defect	', style: 'tableTh', alignment: 'center' },
                    { text: 'Repair Recommended', style: 'tableTh', alignment: 'center' },
                    { text: 'Remarks', style: 'tableTh', alignment: 'center' }
                  ],
                  ...reportDefectListing?.map((defectListing, index) => {
                    return [
                      {
                        text: defectListing?.orderId ? `${defectListing?.orderId}` : ' ',
                        style: 'tableTd',
                        alignment: 'center'
                      },
                      {
                        text: defectListing?.elevation_name ? `${defectListing?.elevation_name}` : ' ',
                        style: 'tableTd',
                        alignment: 'center'
                      },
                      {
                        text:
                          defectListing?.row && defectListing?.column
                            ? `${defectListing?.row}\n${defectListing?.column}`
                            : ' ',
                        style: 'tableTd',
                        alignment: 'center'
                      },
                      {
                        text: defectListing?.element ? `${defectListing?.element}` : ' ',
                        style: 'tableTd',
                        alignment: 'center'
                      },
                      {
                        text: defectListing?.tags ? `${defectListing?.tags}` : ' ',
                        style: 'tableTd',
                        alignment: 'center'
                      },
                      {
                        text: defectListing?.cls ? `${defectListing.cls}` : 'Point',
                        style: 'tableTd',
                        alignment: 'center'
                      },
                      {
                        text: defectListing?.photoRecord ? 'Yes' : 'No',
                        style: 'tableTd',
                        alignment: 'center'
                      },
                      {
                        text:
                          defectListing?.reasons?.length > 0
                            ? defectListing?.reasons?.map((reason, index) => {
                                return `${reason?.label}${index === defectListing?.reasons?.length - 1 ? '' : ', '}`
                              })
                            : ' ',
                        style: 'tableTd',
                        alignment: 'center'
                      },
                      {
                        text:
                          defectListing?.repairs?.length > 0
                            ? defectListing?.repairs?.map((repair, index) => {
                                return `${repair?.label}${index === defectListing?.repairs?.length - 1 ? '' : ', '}`
                              })
                            : ' ',
                        style: 'tableTd',
                        alignment: 'center'
                      },
                      {
                        text: defectListing?.remarks ? `${defectListing?.remarks}` : ' ',
                        style: 'tableTd',
                        alignment: 'center'
                      }
                    ]
                  })
                ]
              }
            }
          : {
              text: 'Visual Inspection Data is not included in this report',
              style: 'coverPageAddress',
              margin: [0, 0, 0, 10]
            },
        {
          text: 'Photos of Selected Defects Identified During Visual Inspection',
          pageBreak: 'before',
          margin: [0, 30, 0, 20],
          fontSize: 14,
          pageOrientation: 'portrait',
          bold: true
        },
        ...defectData?.map((defect) => {
          if (defect?.photoRecord === true) {
            return {
              margin: [0, 10, 0, 10],
              table: {
                widths: [190, 90, 190],
                dontBreakRows: false,
                headerRows: 11,
                body: [
                  [
                    defect?.url
                      ? {
                          image: `${defect?.url}`,
                          rowSpan: 11,
                          fit: [180, 180],
                          margin: [0, 10, 0, 0],
                          alignment: 'center',
                          style: 'tableTd'
                        }
                      : {
                          text: ' ',
                          rowSpan: 11,
                          width: 250,
                          margin: [0, 30, 0, 30],
                          alignment: 'center',
                          style: 'tableTd'
                        },
                    { text: 'Defect ID #', alignment: 'center', bold: true, style: 'tableTh' },
                    { text: `${defect.orderId}`, style: 'tableTh' }
                  ],
                  [
                    {},
                    { text: 'Elevation', alignment: 'center', bold: true, style: 'tableTh' },
                    {
                      text: defect?.elevation_name ? `${defect?.elevation_name}` : ' ',
                      style: 'tableTd'
                    }
                  ],
                  [
                    {},
                    { text: 'Location', alignment: 'center', bold: true, style: 'tableTh' },
                    {
                      text: defect?.row || defect?.column ? `${defect?.row} - ${defect?.column}` : ' ',
                      style: 'tableTd'
                    }
                  ],
                  [
                    {},
                    { text: 'Element', alignment: 'center', bold: true, style: 'tableTh' },
                    { text: defect?.element ? `${defect?.element}` : ' ', style: 'tableTd' }
                  ],
                  [
                    {},
                    { text: 'Defect', alignment: 'center', bold: true, style: 'tableTh' },
                    { text: defect?.tags ? `${defect?.tags}` : ' ', style: 'tableTd' }
                  ],
                  [
                    {},
                    { text: 'Severity', alignment: 'center', bold: true, style: 'tableTh' },
                    { text: defect?.cls ? `${defect?.cls}` : ' ', style: 'tableTd' }
                  ],
                  [
                    {},
                    {
                      text: 'Remarks',
                      alignment: 'center',
                      bold: true,
                      style: 'tableTh',
                      height: 60
                    },
                    {
                      text: defect?.remarks ? `${defect?.remarks}` : ' ',
                      style: 'tableTd',
                      height: 60
                    }
                  ],
                  [
                    {},
                    { text: 'Reason for Defect', alignment: 'center', bold: true, style: 'tableTh', height: 60 },
                    {
                      text:
                        defect?.reasons && defect?.reasons?.length > 0
                          ? defect?.reasons?.map((reason, index) => {
                              return `${reason?.label}${index === defect?.reasons?.length - 1 ? '' : ', '}`
                            })
                          : ' ',
                      style: 'tableTd',
                      height: 60
                    }
                  ],
                  [
                    {},
                    { text: 'Repair Recommended', alignment: 'center', bold: true, style: 'tableTh', height: 60 },
                    {
                      text:
                        defect?.repairs && defect?.repairs?.length > 0
                          ? defect?.repairs?.map((repair, index) => {
                              return `${repair?.label}${index === defect?.repairs?.length - 1 ? '' : ', '}`
                            })
                          : ' ',
                      style: 'tableTd',
                      height: 60
                    }
                  ],
                  [
                    {},
                    { text: 'Longtitude - Latitude', alignment: 'center', bold: true, style: 'tableTh', height: 60 },
                    {
                      text:
                        defect?.metadata && !isEmptyObject(defect.metadata)
                          ? `${formatMetadata(defect.metadata)?.longitude} - ${
                              formatMetadata(defect.metadata)?.latitude
                            }`
                          : ' ',
                      style: 'tableTd',
                      height: 60
                    }
                  ],
                  [
                    {},
                    { text: 'Aitutude', alignment: 'center', bold: true, style: 'tableTh', height: 60 },
                    {
                      text:
                        defect?.metadata && !isEmptyObject(defect.metadata)
                          ? `${formatMetadata(defect.metadata)?.altitude}`
                          : ' ',
                      style: 'tableTd',
                      height: 60
                    }
                  ]
                ]
              }
            }
          }
        }),
        {
          text: 'Appendix C: Inspection Checklist of Façade Record for Elevation',
          style: 'h1',
          pageBreak: 'before',
          tocItem: true,
          tocMargin: [0, 16, 0, 0],
          margin: [0, 0, 0, 10],
          tocStyle: 'h1'
        },
        {
          table: {
            headerRows: 1,
            widths: ['*', 100, 100, '*'],
            dontBreakRows: true,
            margin: [0, 0, 0, 10],
            body: [
              [
                { text: 'Building Address:', style: 'tableTh' },
                {
                  text: report?.building?.address ? `${report?.building?.address}` : ' ',
                  style: 'tableTd',
                  colSpan: 3
                },
                '',
                ''
              ],
              [
                { text: 'Building Height:', style: 'tableTh' },
                {
                  text: report?.report?.building_height ? `${report?.report?.building_height}` : ' ',
                  alignment: 'center',
                  style: 'tableTd'
                },
                { text: 'Number of Storeys:', style: 'tableTh' },
                {
                  text: report?.report?.storeys ? `${report?.report?.storeys}` : ' ',
                  alignment: 'center',
                  style: 'tableTd'
                }
              ],
              [
                { text: 'Block Number:', style: 'tableTh' },
                {
                  text: report?.report?.block_number ? `${report?.report?.block_number}` : ' ',
                  alignment: 'center',
                  style: 'tableTd'
                },
                { text: 'Age of Building:', style: 'tableTh' },
                {
                  text: report?.report?.age ? `${report?.report?.age}` : ' ',
                  alignment: 'center',
                  style: 'tableTd'
                }
              ],
              [
                { text: 'BCA Notice Number:', style: 'tableTh' },
                {
                  text: report?.report?.bca_notice ? `${report?.report?.bca_notice}` : ' ',
                  alignment: 'center',
                  style: 'tableTd'
                },
                { text: 'Report Reference:', style: 'tableTh' },
                {
                  text: report?.report?.report_reference ? `${report?.report?.report_reference}` : ' ',
                  alignment: 'center',
                  style: 'tableTd'
                }
              ],
              [
                { text: 'Inspectors (FI):', style: 'tableTh' },
                {
                  text: inspectors.map((inspector, index) => {
                    return {
                      text: `${index + 1}. ${inspector?.username} ${
                        inspector?.regno ? '(' + inspector?.regno + ')' : ''
                      }\n`,
                      margin: [18, 10, 0, 0]
                    }
                  }),
                  style: 'tableTd',
                  colSpan: 3
                }
              ],
              [
                { text: 'Competent Person (CP):', style: 'tableTh' },
                {
                  text:
                    report?.cp?.username || report?.cp?.regno
                      ? `- ${report?.cp?.username} ${report?.cp?.regno ? '(' + report?.cp?.regno + ')' : ''}`
                      : '',
                  style: 'tableTd',
                  colSpan: 3
                }
              ],
              [
                { text: 'Company of CP:', style: 'tableTh' },
                {
                  text: report?.report?.company ? `${report?.report?.company}` : ' ',
                  style: 'tableTd',
                  colSpan: 3
                }
              ]
            ]
          }
        },
        {
          text: 'FAÇADE INSPECTION CHECKLIST',
          margin: [0, 20, 0, 16],
          fontSize: 14,
          bold: true
        },
        {
          table: {
            // headerRows: 1,
            widths: ['*', 100, 100, '*'],
            dontBreakRows: true,
            body: [
              [
                { text: 'Date & Time of Inspection:', style: 'tableTh' },
                {
                  text: report?.report?.inspection_datetime ? `${report?.report?.inspection_datetime}` : ' ',
                  style: 'tableTd',
                  colSpan: 3
                },
                '',
                ''
              ],
              [
                { text: 'Weather Condition:', style: 'tableTh' },
                {
                  text: report?.report?.weather_condition ? `${report?.report?.weather_condition}` : ' ',
                  style: 'tableTd'
                },
                { text: 'Last Inspection Date:', style: 'tableTh' },
                {
                  text: report?.report?.inspection_lastdate ? `${report?.report?.inspection_lastdate}` : ' ',
                  style: 'tableTd'
                }
              ],
              [
                { text: 'Elevation Marking/ Reference:', style: 'tableTh' },
                {
                  text: {
                    text: report?.report?.elevation_marking ? `${report?.report?.elevation_marking}` : ' '
                  },
                  style: 'tableTd',
                  colSpan: 3
                }
              ],
              [
                { text: 'Duration of Visual Inspection:', style: 'tableTh' },
                {
                  text: {
                    text: report?.report?.duration_visual ? `${report?.report?.duration_visual}` : ' ',
                    style: 'tableTd'
                  },
                  style: 'tableTd',
                  colSpan: 3
                }
              ],
              [
                { text: 'Duration of close range inspection:', style: 'tableTh' },
                {
                  text: report?.report?.duration_closeup ? `${report?.report?.duration_closeup}` : ' ',
                  style: 'tableTd'
                },
                { text: 'Location of close-range inspection:', style: 'tableTh' },
                {
                  text: report?.report?.location_closeup ? `${report?.report?.location_closeup}` : ' ',
                  style: 'tableTd'
                }
              ],
              [
                { text: 'Tools used for visual Inspection:', style: 'tableTh' },
                {
                  text: {
                    text: report?.report?.tools_used ? `${report?.report?.tools_used}` : ' '
                  },
                  style: 'tableTd',
                  colSpan: 3
                }
              ],
              [
                { text: 'Visual Inspection Assessment:', style: 'tableTh' },
                {
                  text: {
                    text: report?.report?.inspection_assessment ? `${report?.report?.inspection_assessment}` : ' '
                  },
                  style: 'tableTd',
                  colSpan: 3
                },
                '',
                ''
              ],
              [
                { text: 'Façade Type(s):', style: 'tableTh' },
                {
                  text: {
                    text: report?.report?.facade_type ? `${report?.report?.facade_type}` : ' '
                  },
                  style: 'tableTd',
                  colSpan: 3
                },
                '',
                ''
              ],
              [
                { text: 'Cladding Material (if any):', style: 'tableTh' },
                {
                  text: {
                    text: report?.report?.cladding_material ? `${report?.report?.cladding_material}` : ' '
                  },
                  style: 'tableTd',
                  colSpan: 3
                },
                '',
                ''
              ],
              [
                { text: 'Attachments / Appendages Type:', style: 'tableTh' },
                {
                  text: {
                    text: report?.report?.attachment ? `${report?.report?.attachment}` : ' '
                  },
                  style: 'tableTd',
                  colSpan: 3
                },
                '',
                ''
              ],
              [
                {
                  text: 'Any Exterior False Ceiling System? If ‘Yes’, please specify the material used for the ceiling panels.',
                  style: 'tableTh'
                },
                {
                  text: {
                    text: report?.report?.exterior_false_ceiling ? `${report?.report?.exterior_false_ceiling}` : ' '
                  },
                  style: 'tableTd',
                  colSpan: 3
                }
              ],
              [
                { text: 'Declaration of FI on visual inspection works:', style: 'tableTh' },
                {
                  stack: [
                    {
                      text: report?.report?.fi_declare_id
                        ? '(√), I have taken photos of the entire façade under CP’s instruction for his assessment.'
                        : 'I have taken photos of the entire façade under CP’s instruction for his assessment.',
                      style: 'tableTd'
                    },
                    {
                      text: [
                        { text: 'Declared by:', bold: true },
                        ...inspectors.map((inspector) => {
                          if (inspector?.id === report?.report?.fi_declare_id) {
                            return {
                              text: ` ${inspector.username} - ${inspector.regno}\n`,
                              margin: [18, 10, 0, 0],
                              bold: true
                            }
                          } else {
                            return ''
                          }
                        })
                      ],
                      style: 'tableTh'
                    }
                  ],
                  colSpan: 3
                }
              ]
            ]
          }
        },
        {
          text: 'Table 4: Defects Identified During Close Range Inspection',
          alignment: 'center',
          pageBreak: 'before',
          pageSize: 'A4',
          pageOrientation: 'landscape',
          margin: [0, 0, 16, 30],
          fontSize: 18,
          bold: true
        },
        reportCloseRangeDefectListing.length > 0
          ? {
              table: {
                // headerRows: 1,
                widths: [35, 50, 64, 55, 55, 45, 40, 36, 90, 90, 80],
                dontBreakRows: true,
                body: [
                  [
                    { text: 'ID', style: 'tableTh', alignment: 'center' },
                    { text: 'Elevation', style: 'tableTh', alignment: 'center' },
                    { text: 'Location', style: 'tableTh', alignment: 'center' },
                    { text: 'Element', style: 'tableTh', alignment: 'center' },
                    { text: 'Defect', style: 'tableTh', alignment: 'center' },
                    { text: 'Severity', style: 'tableTh', alignment: 'center' },
                    { text: 'Visual Defect ID', style: 'tableTh', alignment: 'center' },
                    { text: 'Photo Record', style: 'tableTh', alignment: 'center' },
                    { text: 'Reason for Defect	', style: 'tableTh', alignment: 'center' },
                    { text: 'Repair Recommended', style: 'tableTh', alignment: 'center' },
                    { text: 'Remarks', style: 'tableTh', alignment: 'center' }
                  ],
                  ...reportCloseRangeDefectListing?.map((crdefectListing, index) => {
                    return [
                      {
                        text: crdefectListing?.orderId ? `${crdefectListing?.orderId}` : ' ',
                        style: 'tableTd',
                        alignment: 'center'
                      },
                      {
                        text: crdefectListing?.elevation_name ? `${crdefectListing?.elevation_name}` : ' ',
                        style: 'tableTd',
                        alignment: 'center'
                      },
                      {
                        text:
                          crdefectListing?.row && crdefectListing?.column
                            ? `${crdefectListing?.row}\n${crdefectListing?.column}`
                            : ' ',
                        style: 'tableTd',
                        alignment: 'center'
                      },
                      {
                        text: crdefectListing?.element ? `${crdefectListing?.element}` : ' ',
                        style: 'tableTd',
                        alignment: 'center'
                      },
                      {
                        text: crdefectListing?.tags ? `${crdefectListing?.tags}` : ' ',
                        style: 'tableTd',
                        alignment: 'center'
                      },
                      {
                        text: crdefectListing?.cls ? `${crdefectListing?.cls}` : ' ',
                        style: 'tableTd',
                        alignment: 'center'
                      },
                      {
                        text: crdefectListing?.visualOrderId ? `${crdefectListing?.visualOrderId}` : ' ',
                        style: 'tableTd',
                        alignment: 'center'
                      },
                      {
                        text: crdefectListing?.photoRecord ? 'Yes' : 'No',
                        style: 'tableTd',
                        alignment: 'center'
                      },
                      {
                        text:
                          crdefectListing?.reasons?.length > 0
                            ? crdefectListing?.reasons?.map((reason, index) => {
                                return `${reason?.label}${index === crdefectListing?.reasons?.length - 1 ? '' : ', '}`
                              })
                            : ' ',
                        style: 'tableTd',
                        alignment: 'center'
                      },
                      {
                        text:
                          crdefectListing?.repairs?.length > 0
                            ? crdefectListing?.repairs?.map((repair, index) => {
                                return `${repair?.label}${index === crdefectListing?.repairs?.length - 1 ? '' : ', '}`
                              })
                            : ' ',
                        style: 'tableTd',
                        alignment: 'center'
                      },
                      {
                        text: crdefectListing?.remarks ? `${crdefectListing?.remarks}` : ' ',
                        style: 'tableTd',
                        alignment: 'center'
                      }
                    ]
                  })
                ]
              }
            }
          : {
              text: 'Close Range Inspection Data is not included in this report',
              style: 'coverPageAddress',
              margin: [0, 0, 0, 10]
            },

        {
          table: {
            widths: ['*', '*'],
            defaultBorder: false,
            dontBreakRows: true,
            body: [
              [
                {
                  stack: [
                    inspectors.map((inspector) => {
                      return {
                        text: inspector?.username ? `${inspector?.username}` : ``,
                        bold: true,
                        margin: [200, 0, 0, 0],
                        noWrap: true
                      }
                    }),
                    {
                      text: [
                        { text: `Signed By: ` },
                        {
                          text: '                                                      ',
                          decoration: 'underline'
                        }
                      ]
                    }
                  ],
                  alignment: 'center'
                },
                {
                  stack: [
                    {
                      text: report?.cp?.username ? `${report?.cp?.username}` : ``,
                      bold: true,
                      margin: [180, 0, 0, 0],
                      noWrap: true
                    },
                    {
                      text: [
                        { text: `Verified By: ` },
                        {
                          text: '                                     ',
                          decoration: 'underline'
                        }
                      ]
                    }
                  ],
                  margin: [0, 15 * (report?.inspectors?.length - 1), 120, 0],
                  alignment: 'center'
                }
              ]
            ]
          },
          layout: 'noBorders',
          margin: [0, 50, 0, 0]
        },
        {
          text: 'Photos of Selected Defects Identified During Close Range Inspection',
          pageBreak: 'before',
          margin: [0, 30, 0, 20],
          fontSize: 14,
          headerRows: 10,
          pageOrientation: 'portrait',
          bold: true
        },

        ...crdefectData?.map((defect) => {
          if (defect?.photoRecord === true) {
            if (defect?.type !== 'Thumbnail') {
              return {
                margin: [0, 0, 0, 20],
                table: {
                  widths: [90, 190, 190],
                  dontBreakRows: false,
                  headerRows: 12,
                  body: [
                    [
                      { text: 'Defect ID #', bold: true, style: 'tableTh' },
                      { text: `${defect.orderId}`, style: 'tableTh' },
                      {
                        stack: [
                          {
                            text: 'Close Range Image',
                            margin: [0, 10, 0, 10],
                            bold: true,
                            style: 'tableTh'
                          },
                          defect?.url
                            ? {
                                image: `${defect?.url}`,
                                fit: [180, 180],
                                alignment: 'center',
                                margin: [0, 10, 0, 10],
                                bold: true,
                                style: 'tableTh'
                              }
                            : {}
                        ],
                        rowSpan: 7
                      }
                    ],
                    [
                      { text: 'Elevation', bold: true, style: 'tableTh' },
                      {
                        text: defect?.elevation_name ? `${defect?.elevation_name}` : ' ',
                        style: 'tableTd'
                      },
                      {}
                    ],
                    [
                      { text: 'Location', bold: true, style: 'tableTh' },
                      {
                        text: defect?.row || defect?.column ? `${defect?.row} - ${defect?.column}` : ' ',
                        style: 'tableTd'
                      },
                      {}
                    ],
                    [
                      { text: 'Element', bold: true, style: 'tableTh' },
                      { text: defect?.element ? `${defect?.element}` : ' ', style: 'tableTd' },
                      {}
                    ],
                    [
                      { text: 'Defect', bold: true, style: 'tableTh' },
                      { text: defect?.tags ? `${defect?.tags}` : ' ', style: 'tableTd' },
                      {}
                    ],
                    [
                      { text: 'Longtitude - Latitude', bold: true, style: 'tableTh' },
                      {
                        text:
                          defect?.metadata && !isEmptyObject(defect.metadata)
                            ? `${formatMetadata(defect.metadata)?.longitude} - ${
                                formatMetadata(defect.metadata)?.latitude
                              }`
                            : ' ',
                        style: 'tableTd'
                      },
                      {}
                    ],
                    [
                      { text: 'Aitutude', bold: true, style: 'tableTh' },
                      {
                        text:
                          defect?.metadata && !isEmptyObject(defect.metadata)
                            ? `${formatMetadata(defect.metadata)?.altitude}`
                            : ' ',
                        style: 'tableTd'
                      },
                      {}
                    ],
                    [
                      { text: 'Severity', bold: true, style: 'tableTh' },
                      { text: defect?.cls ? `${defect?.cls}` : ' ', style: 'tableTd' },
                      {
                        stack: [
                          {
                            text: 'Visual Inspection Image',
                            margin: [0, 10, 0, 10],
                            bold: true,
                            style: 'tableTh'
                          },
                          defect?.fromUrl
                            ? {
                                image: `${defect?.fromUrl}`,
                                fit: [180, 180],
                                alignment: 'center',
                                margin: [0, 10, 0, 10],
                                bold: true,
                                style: 'tableTh'
                              }
                            : {}
                        ],
                        rowSpan: 5
                      }
                    ],
                    [
                      { text: 'Visual Defect ID', bold: true, style: 'tableTh' },
                      { text: defect?.visualOrderId ? `${defect?.visualOrderId}` : ' ', style: 'tableTd' },
                      {}
                    ],
                    [
                      { text: 'Remarks', bold: true, style: 'tableTh' },
                      { text: defect?.remarks ? `${defect?.remarks}` : ' ', style: 'tableTd' },
                      {}
                    ],
                    [
                      { text: 'Reason for Defect', bold: true, style: 'tableTh' },
                      {
                        text:
                          defect?.reasons && defect?.reasons?.length > 0
                            ? defect?.reasons?.map((reason, index) => {
                                return `${reason?.label}${index === defect?.reasons?.length - 1 ? '' : ', '}`
                              })
                            : ' ',
                        style: 'tableTd'
                      },
                      {}
                    ],
                    [
                      { text: 'Repair Recommended', bold: true, style: 'tableTh' },
                      {
                        text:
                          defect?.repairs && defect?.repairs?.length > 0
                            ? defect?.repairs?.map((repair, index) => {
                                return `${repair?.label}${index === defect?.repairs?.length - 1 ? '' : ', '}`
                              })
                            : ' ',
                        style: 'tableTd'
                      },
                      {}
                    ]
                  ]
                }
              }
            } else {
              return {
                margin: [0, 0, 0, 20],
                table: {
                  widths: [90, 190, 190],
                  dontBreakRows: true,
                  headerRows: 12,
                  body: [
                    [
                      { text: 'Defect ID #', bold: true, style: 'tableTh' },
                      { text: `${defect.orderId}`, style: 'tableTh' },
                      {
                        stack: [
                          {
                            text: 'Close Range Image',
                            margin: [0, 10, 0, 10],
                            bold: true,
                            style: 'tableTh'
                          },
                          defect?.url
                            ? {
                                image: `${defect?.url}`,
                                fit: [180, 180],
                                alignment: 'center',
                                margin: [0, 10, 0, 10],
                                bold: true,
                                style: 'tableTh'
                              }
                            : {}
                        ],
                        rowSpan: 12
                      }
                    ],
                    [
                      { text: 'Elevation', bold: true, style: 'tableTh' },
                      {
                        text: defect?.elevation_name ? `${defect?.elevation_name}` : ' ',
                        style: 'tableTd'
                      },
                      {}
                    ],
                    [
                      { text: 'Location', bold: true, style: 'tableTh' },
                      {
                        text: defect?.row || defect?.column ? `${defect?.row} - ${defect?.column}` : ' ',
                        style: 'tableTd'
                      },
                      {}
                    ],
                    [
                      { text: 'Element', bold: true, style: 'tableTh' },
                      { text: defect?.element ? `${defect?.element}` : ' ', style: 'tableTd' },
                      {}
                    ],
                    [
                      { text: 'Defect', bold: true, style: 'tableTh' },
                      { text: defect?.tags ? `${defect?.tags}` : ' ', style: 'tableTd' },
                      {}
                    ],
                    [
                      { text: 'Severity', bold: true, style: 'tableTh' },
                      { text: defect?.cls ? `${defect?.cls}` : ' ', style: 'tableTd' },
                      {}
                    ],
                    [
                      { text: 'Visual Defect ID', bold: true, style: 'tableTh' },
                      { text: defect?.visualOrderId ? `${defect?.visualOrderId}` : ' ', style: 'tableTd' },
                      {}
                    ],
                    [
                      { text: 'Remarks', bold: true, style: 'tableTh' },
                      { text: defect?.remarks ? `${defect?.remarks}` : ' ', style: 'tableTd' },
                      {}
                    ],
                    [
                      { text: 'Reason for Defect', bold: true, style: 'tableTh' },
                      {
                        text:
                          defect?.reasons && defect?.reasons?.length > 0
                            ? defect?.reasons?.map((reason, index) => {
                                return `${reason?.label}${index === defect?.reasons?.length - 1 ? '' : ', '}`
                              })
                            : ' ',
                        style: 'tableTd'
                      },
                      {}
                    ],
                    [
                      { text: 'Repair Recommended', bold: true, style: 'tableTh' },
                      {
                        text:
                          defect?.repairs && defect?.repairs?.length > 0
                            ? defect?.repairs?.map((repair, index) => {
                                return `${repair?.label}${index === defect?.repairs?.length - 1 ? '' : ', '}`
                              })
                            : ' ',
                        style: 'tableTd'
                      },
                      {}
                    ],
                    [
                      { text: 'Longtitude - Latitude', bold: true, style: 'tableTh' },
                      {
                        text:
                          defect?.metadata && !isEmptyObject(defect.metadata)
                            ? `${formatMetadata(defect.metadata)?.longitude} - ${
                                formatMetadata(defect.metadata)?.latitude
                              }`
                            : ' ',
                        style: 'tableTd'
                      },
                      {}
                    ],
                    [
                      { text: 'Aitutude', bold: true, style: 'tableTh' },
                      {
                        text:
                          defect?.metadata && !isEmptyObject(defect.metadata)
                            ? `${formatMetadata(defect.metadata)?.altitude}`
                            : ' ',
                        style: 'tableTd'
                      },
                      {}
                    ]
                  ]
                }
              }
            }
          }
        }),

        // ...crdefectData?.map((defect) => {
        //   if (defect.photoRecord === true) {
        //     return {
        //       margin: [0, 0, 0, 20],
        //       table: {
        //         widths: ['auto', 'auto', '*'],
        //         dontBreakRows: true,
        //         defaultBorder: false,
        //         body: [
        //           [
        //             { text: 'Defect ID #', bold: true, style: 'tableTd' },
        //             { text: `${defect.orderId}`, style: 'tableTd' },
        //             {
        //               stack: [
        //                 {
        //                   text: 'Close Range Image',
        //                   margin: [0, 10, 0, 10],
        //                   bold: true,
        //                   style: 'tableTd'
        //                 },
        //                 defect?.url
        //                   ? {
        //                       image: `${defect?.url}`,
        //                       width: 200,
        //                       alignment: 'center',
        //                       margin: [0, 10, 0, 10],
        //                       bold: true,
        //                       style: 'tableTd'
        //                     }
        //                   : {}
        //               ],
        //               rowSpan: 5
        //             }
        //           ],
        //           [
        //             { text: 'Elevation', bold: true, style: 'tableTd' },
        //             {
        //               text: defect?.elevation_name ? `${defect?.elevation_name}` : ' ',
        //               style: 'tableTd'
        //             },
        //             {}
        //           ],
        //           [
        //             { text: 'Location', bold: true, style: 'tableTd' },
        //             {
        //               text: defect?.row || defect?.column ? `${defect?.row} - ${defect?.column}` : ' ',
        //               style: 'tableTd'
        //             },
        //             {}
        //           ],
        //           [
        //             { text: 'Element', bold: true, style: 'tableTd' },
        //             { text: defect?.element ? `${defect?.element}` : ' ', style: 'tableTd' },
        //             {}
        //           ],
        //           [
        //             { text: 'Defect', bold: true, style: 'tableTd' },
        //             { text: defect?.tags ? `${defect?.tags}` : ' ', style: 'tableTd' },
        //             {}
        //           ],
        //           [
        //             { text: 'Severity', bold: true, style: 'tableTd' },
        //             { text: defect?.cls ? `${defect?.cls}` : ' ', style: 'tableTd' },
        //             defect?.fromUrl
        //               ? {
        //                   image: `${defect?.fromUrl}`,
        //                   rowSpan: 5,
        //                   width: 200,
        //                   margin: [0, 30, 0, 30],

        //                   style: 'tableTd'
        //                 }
        //               : {
        //                   text: ' ',
        //                   rowSpan: 5,
        //                   width: 200,
        //                   margin: [0, 30, 0, 30],

        //                   style: 'tableTd'
        //                 }
        //           ],
        //           [
        //             { text: 'Visual Defect ID', bold: true, style: 'tableTd' },
        //             { text: defect?.visualOrderId ? `${defect?.visualOrderId}` : ' ', style: 'tableTd' },
        //             {}
        //           ],
        //           [
        //             { text: 'Remarks', bold: true, style: 'tableTd' },
        //             { text: defect?.remarks ? `${defect?.remarks}` : ' ', style: 'tableTd' },
        //             {}
        //           ],
        //           [
        //             { text: 'Reason for Defect', bold: true, style: 'tableTd' },
        //             {
        //               text:
        //                 defect?.reasons && defect?.reasons?.length > 0
        //                   ? defect?.reasons?.map((reason, index) => {
        //                       return `${reason?.label}${index === defect?.reasons?.length - 1 ? '' : ', '}`
        //                     })
        //                   : ' ',
        //               style: 'tableTd'
        //             },
        //             {}
        //           ],
        //           [
        //             { text: 'Repair Recommended', bold: true, style: 'tableTd' },
        //             {
        //               text:
        //                 defect?.repairs && defect?.repairs?.length > 0
        //                   ? defect?.repairs?.map((repair, index) => {
        //                       return `${repair?.label}${index === defect?.repairs?.length - 1 ? '' : ', '}`
        //                     })
        //                   : ' ',
        //               style: 'tableTd'
        //             },
        //             {}
        //           ]

        //           // [
        //           //   { text: 'Defect ID', bold: true, style: 'tableTd' },
        //           //   { text: defect?.orderId ? `${defect?.orderId}` : ' ', style: 'tableTd' },
        //           //   { text: 'Element', bold: true, style: 'tableTd' },
        //           //   { text: defect?.element ? `${defect?.element}` : ' ', style: 'tableTd' }
        //           // ],
        //           // [
        //           //   { text: 'Elevation', bold: true, style: 'tableTd' },
        //           //   {
        //           //     text: defect?.elevation_name ? `${defect?.elevation_name}` : ' ',
        //           //     style: 'tableTd'
        //           //   },
        //           //   { text: 'Defect', bold: true, style: 'tableTd' },
        //           //   { text: defect?.tags ? `${defect?.tags}` : ' ', style: 'tableTd' }
        //           // ],
        //           // [
        //           //   { text: 'Location', bold: true, style: 'tableTd' },
        //           //   {
        //           //     text: defect?.row || defect?.column ? `${defect?.row} - ${defect?.column}` : ' ',
        //           //     style: 'tableTd'
        //           //   },
        //           //   { text: 'Severity', bold: true, style: 'tableTd' },
        //           //   { text: defect?.cls ? `${defect?.cls}` : ' ', style: 'tableTd' }
        //           // ],
        //           // [
        //           //   { text: 'Reason for Defect', bold: true, style: 'tableTd' },
        //           //   {
        //           //     text:
        //           //       defect?.reasons && defect?.reasons?.length > 0
        //           //         ? defect?.reasons?.map((reason, index) => {
        //           //             return `${reason?.label}${index === defect?.reasons?.length - 1 ? '' : ', '}`
        //           //           })
        //           //         : ' ',
        //           //     style: 'tableTd'
        //           //   },
        //           //   { text: 'Repair Recommended', bold: true, style: 'tableTd' },
        //           //   {
        //           //     text:
        //           //       defect?.repairs && defect?.repairs?.length > 0
        //           //         ? defect?.repairs?.map((repair, index) => {
        //           //             return `${repair?.label}${index === defect?.repairs?.length - 1 ? '' : ', '}`
        //           //           })
        //           //         : ' ',
        //           //     style: 'tableTd'
        //           //   }
        //           // ],
        //           // [
        //           //   { text: 'Remarks', bold: true, style: 'tableTd' },
        //           //   { text: defect?.remarks ? `${defect?.remarks}` : ' ', style: 'tableTd' },
        //           //   { text: 'Visual Defect ID', bold: true, style: 'tableTd' },
        //           //   { text: defect?.visualOrderId ? `${defect?.visualOrderId}` : ' ', style: 'tableTd' }
        //           // ]
        //           //   [
        //           //     {
        //           //       stack: [
        //           //         {
        //           //           text: 'Close Range Image',
        //           //           margin: [0, 10, 0, 10],
        //           //           bold: true,
        //           //           style: 'tableTd'
        //           //         },
        //           //         defect?.url
        //           //           ? {
        //           //               image: `${defect?.url}`,
        //           //               width: 400,
        //           //               alignment: 'center',
        //           //               margin: [0, 10, 0, 10],
        //           //               bold: true,
        //           //               style: 'tableTd'
        //           //             }
        //           //           : {}
        //           //       ],
        //           //       colSpan: 4,
        //           //       border: defect?.type === 'Thumbnail' ? [true, true, true, false] : [true, true, true, true]
        //           //     }
        //           //   ],
        //           //   defect?.type !== 'Thumbnail'
        //           //     ? [
        //           //         {
        //           //           stack: [
        //           //             {
        //           //               text: 'Visual Inspection Image',
        //           //               margin: [0, 10, 0, 10],
        //           //               bold: true,
        //           //               style: 'tableTd'
        //           //             },
        //           //             defect?.fromUrl
        //           //               ? {
        //           //                   image: `${defect?.fromUrl}`,
        //           //                   width: 200,
        //           //                   alignment: 'center',
        //           //                   margin: [0, 10, 0, 10],
        //           //                   bold: true,
        //           //                   style: 'tableTd'
        //           //                 }
        //           //               : {}
        //           //           ],
        //           //           colSpan: 4
        //           //         }
        //           //       ]
        //           //     : [
        //           //         {
        //           //           text: '',
        //           //           margin: [0, 0, 0, 0],
        //           //           width: 0,
        //           //           height: 0,
        //           //           colSpan: 4,
        //           //           border: [true, false, true, true]
        //           //         }
        //           //       ]
        //         ]
        //       }
        //     }
        //   }
        // }),
        {
          text: 'Appendix D: Elevation Images',
          style: 'h1',
          pageBreak: 'before',
          pageOrientation: 'portrait',
          tocItem: true,
          tocMargin: [0, 16, 0, 0],
          margin: [0, 0, 0, 10],
          tocStyle: 'h1'
        },
        elevationData
          ?.map((elevation, index) => {
            return [
              {
                text: elevation?.name ? `${index + 1}. Elevation ${elevation?.name}` : '',
                margin: [0, 10, 0, 10],
                fontSize: 12,
                fontWeight: 'bold'
              },
              {
                image: `${elevation?.url}`,
                alignment: 'center',
                width: elevation?.width,
                height: elevation?.height
              },
              {
                text: `Thumbnail`,
                margin: [0, 10, 0, 10],
                fontSize: 10,
                alignment: 'center'
              },
              ...(elevation?.composite_drone
                ? [
                    {
                      image: `${elevation?.composite_drone}`,
                      alignment: 'center',
                      width: elevation?.widthDrone,
                      height: elevation?.heightDrone,
                      fit: [500, 500]
                    },
                    {
                      text: `Composite drone image`,
                      margin: [0, 10, 0, 10],
                      fontSize: 10,
                      alignment: 'center'
                    }
                  ]
                : []),

              ...(elevation?.composite_manual
                ? [
                    {
                      image: `${elevation?.composite_manual}`,
                      alignment: 'center',
                      width: elevation?.widthManual,
                      height: elevation?.heightManual,
                      fit: [500, 500]
                    },
                    {
                      text: `Composite manual image`,
                      margin: [0, 10, 0, 10],
                      fontSize: 10,
                      alignment: 'center'
                    }
                  ]
                : [])
            ]
          })
          .map((r) =>
            r.map((d) => {
              return d
            })
          ),
        {
          text: 'Appendix E: UAS Inspection Report',
          style: 'h1',
          pageBreak: 'before',
          tocItem: true,
          tocMargin: [0, 16, 0, 0],
          tocStyle: 'h1'
        },
        {
          table: {
            widths: [235, 235],
            defaultBorder: true,
            dontBreakRows: true,
            body: [
              [{ text: 'A. General Information', style: 'tableTh', colSpan: 2 }, {}],
              [
                { text: 'Flight location', style: 'tableTh' },
                {
                  text: report?.building?.address ? `${report?.building?.address}` : ' ',
                  style: 'tableTd'
                }
              ],

              [
                { text: 'Flight date(s) & time(s)', style: 'tableTh' },
                {
                  text: report?.report?.uas_flight_datetime ? `${report?.report?.uas_flight_datetime}` : ' ',
                  style: 'tableTd'
                }
              ],
              [
                { text: 'Purpose', style: 'tableTh' },
                {
                  text: report?.report?.purpose ? `${report?.report?.purpose}` : ' ',
                  style: 'tableTd'
                }
              ],
              [
                { text: 'CAAS activity permit reference number', style: 'tableTh' },
                {
                  text: report?.report?.caas_permit_no ? `${report?.report?.caas_permit_no}` : ' ',
                  style: 'tableTd'
                }
              ],
              [
                { text: 'Areas of operation', style: 'tableTh' },
                {
                  text: report?.report?.areas ? `${report?.report?.areas}` : ' ',
                  style: 'tableTd'
                }
              ],
              [
                { text: 'Media and file format', style: 'tableTh' },
                {
                  text: report?.report?.media_file ? `${report?.report?.media_file}` : ' ',
                  style: 'tableTd'
                }
              ],
              [
                { text: 'UA(s) model', style: 'tableTh' },
                {
                  text: report?.report?.uas_model ? `${report?.report?.uas_model}` : ' ',
                  style: 'tableTd'
                }
              ],
              [
                { text: 'Tethered UA (if applicable)', style: 'tableTh' },
                {
                  text: report?.report?.tethered ? 'Yes' : 'No',
                  style: 'tableTd'
                }
              ],
              [
                { text: 'Camera/Sensor(s) model and specification', style: 'tableTh' },
                {
                  text: report?.report?.camera ? `${report?.report?.camera}` : ' ',
                  style: 'tableTd'
                }
              ]
            ]
          },
          margin: [0, 10, 0, 10]
        },
        {
          table: {
            widths: [140, 330],
            defaultBorder: true,
            dontBreakRows: true,
            body: [
              [{ text: 'B. Building Information', style: 'tableTh', colSpan: 2 }, {}],
              [
                { text: 'B.1 - Client Information', style: 'tableTh' },
                {
                  text: report?.report?.client_name ? `${report?.report?.client_name}` : ' ',
                  style: 'tableTd'
                }
              ],
              [
                { text: 'B.2 - Address', style: 'tableTh' },
                {
                  text: report?.report?.client_address ? `${report?.report?.client_address}` : ' ',
                  style: 'tableTd'
                }
              ],
              ...imageB3Data.map((imageB3, index) => {
                if (index === 0) {
                  return [
                    { text: 'B.3 - Site layout plan', style: 'tableTh' },
                    {
                      stack: [
                        { image: `${imageB3.url}`, alignment: 'center', width: 300, margin: [0, 10, 0, 0] },
                        {
                          text: imageB3.title ? `${imageB3.title}` : ' ',
                          margin: [0, 10, 0, 0],
                          alignment: 'center'
                        },
                        {
                          text: imageB3.comment ? `${imageB3.comment}` : ' ',
                          margin: [0, 10, 0, 20],
                          alignment: 'center',
                          fontSize: 14
                        }
                      ]
                    }
                  ]
                } else {
                  return [
                    {},
                    {
                      stack: [
                        { image: `${imageB3.url}`, alignment: 'center', width: 300, margin: [0, 10, 0, 0] },
                        {
                          text: imageB3.title ? `${imageB3.title}` : ' ',
                          margin: [0, 10, 0, 0],
                          alignment: 'center'
                        },
                        {
                          text: imageB3.comment ? `${imageB3.comment}` : ' ',
                          margin: [0, 10, 0, 20],
                          alignment: 'center',
                          fontSize: 14
                        }
                      ]
                    }
                  ]
                }
              }),
              ...imageB4Data.map((imageB4, index) => {
                if (index === 0) {
                  return [
                    { text: 'B.4 - Building(s) layout plan and elevation', style: 'tableTh' },
                    {
                      stack: [
                        { image: `${imageB4.url}`, alignment: 'center', width: 300, margin: [0, 10, 0, 0] },
                        {
                          text: imageB4.title ? `${imageB4.title}` : ' ',
                          margin: [0, 10, 0, 0],
                          alignment: 'center'
                        },
                        {
                          text: imageB4.comment ? `${imageB4.comment}` : ' ',
                          margin: [0, 10, 0, 20],
                          alignment: 'center',
                          fontSize: 14
                        }
                      ]
                    }
                  ]
                } else {
                  return [
                    {},
                    {
                      stack: [
                        { image: `${imageB4.url}`, alignment: 'center', width: 300, margin: [0, 10, 0, 0] },
                        {
                          text: imageB4.title ? `${imageB4.title}` : ' ',
                          margin: [0, 10, 0, 0],
                          alignment: 'center'
                        },
                        {
                          text: imageB4.comment ? `${imageB4.comment}` : ' ',
                          margin: [0, 10, 0, 20],
                          alignment: 'center',
                          fontSize: 14
                        }
                      ]
                    }
                  ]
                }
              }),
              ...imageB5Data.map((imageB5, index) => {
                if (index === 0) {
                  return [
                    { text: 'B.5 – Other drawings/plans/ sketches (if deemed applicable)', style: 'tableTh' },
                    {
                      stack: [
                        { image: `${imageB5.url}`, alignment: 'center', width: 300, margin: [0, 10, 0, 0] },
                        {
                          text: imageB5.title ? `${imageB5.title}` : ' ',
                          margin: [0, 10, 0, 0],
                          alignment: 'center'
                        },
                        {
                          text: imageB5.comment ? `${imageB5.comment}` : ' ',
                          margin: [0, 10, 0, 20],
                          alignment: 'center',
                          fontSize: 14
                        }
                      ]
                    }
                  ]
                } else {
                  return [
                    {},
                    {
                      stack: [
                        { image: `${imageB5.url}`, alignment: 'center', width: 300, margin: [0, 10, 0, 0] },
                        {
                          text: imageB5.title ? `${imageB5.title}` : ' ',
                          margin: [0, 10, 0, 0],
                          alignment: 'center'
                        },
                        {
                          text: imageB5.comment ? `${imageB5.comment}` : ' ',
                          margin: [0, 10, 0, 20],
                          alignment: 'center',
                          fontSize: 14
                        }
                      ]
                    }
                  ]
                }
              })
            ]
          },
          margin: [0, 10, 0, 10]
        },
        {
          table: {
            widths: ['*'],
            defaultBorder: true,
            dontBreakRows: true,
            headerRows: 1,
            keepWithHeaderRows: 0,
            body: [
              [{ text: 'C. List of unsafe facade defects that require immediate action', style: 'tableTh' }],
              [{ text: 'Please refer to Table A of Appendix B', style: 'tableTh', alignment: 'center' }]
            ]
          },
          margin: [0, 10, 0, 10]
        },
        {
          text: 'Appendix F: CP Checklist for PFI Report',
          style: 'h1',
          pageBreak: 'before',
          tocItem: true,
          tocMargin: [0, 16, 0, 0],
          tocStyle: 'h1'
        },
        {
          text: 'CHECKLIST FOR PERIODIC FAÇADE INSPECTION (PFI) REPORT OF EXISTING BUILDING AT',
          alignment: 'center',
          margin: [0, 16, 0, 12],
          fontSize: 12,
          bold: true
        },
        {
          text: report?.building?.address ? `${report?.building?.address}` : ' ',
          alignment: 'center',
          margin: [0, 0, 0, 20],
          fontSize: 12,
          bold: true
        },
        {
          text: 'I declare that all the items listed below are addressed in my visual inspection report and a “tick” (√) is placed in the checkbox for each item to indicate the aforementioned.',
          fontSize: 12,
          lineHeight: 1.4
        },
        {
          table: {
            widths: ['*', 30],
            defaultBorder: true,
            dontBreakRows: true,
            body: [
              [{ text: '1. Types of façade systems and details', style: 'tableTh' }, {}],
              [
                {
                  text: 'a. Floor layout plans and details attached in Annex',
                  style: 'tableTd',
                  margin: [16, 5, 3, 5]
                },
                { text: report?.report?.q1a ? '√' : ' ', style: 'tableTd', alignment: 'center' }
              ],
              [
                {
                  text: 'b. Indication of façade types on building elevation plans',
                  style: 'tableTd',
                  margin: [16, 5, 3, 5]
                },
                { text: report?.report?.q1b ? '√' : ' ', style: 'tableTd', alignment: 'center' }
              ],
              [
                {
                  text: 'c. Description of all façade systems (including material and connections)',
                  style: 'tableTd',
                  margin: [16, 5, 3, 5]
                },
                { text: report?.report?.q1c ? '√' : ' ', style: 'tableTd', alignment: 'center' }
              ],
              [{ text: '2. Particulars of inspection personnel', style: 'tableTh' }, {}],
              [
                {
                  text: 'a. For Competent Person (CP): Name of CP, company name, company address, contact number and email address stated on cover page',
                  style: 'tableTd',
                  margin: [16, 5, 3, 5]
                },
                { text: report?.report?.q2a ? '√' : ' ', style: 'tableTd', alignment: 'center' }
              ],
              [
                {
                  text: 'b. For Façade Inspectors (FI): Name of FI, company name, company address, FI registration number listed in Annex',
                  style: 'tableTd',
                  margin: [16, 5, 3, 5]
                },
                { text: report?.report?.q2b ? '√' : ' ', style: 'tableTd', alignment: 'center' }
              ],
              [{ text: '3. Visual inspection of the building façade', style: 'tableTh' }, {}],
              [
                {
                  text: 'a. Inspected 100% of building façade',
                  style: 'tableTd',
                  margin: [16, 5, 3, 5]
                },
                { text: report?.report?.q3a ? '√' : ' ', style: 'tableTd', alignment: 'center' }
              ],
              [
                {
                  text: 'b. Description of inspection methods and its process',
                  style: 'tableTd',
                  margin: [16, 5, 3, 5]
                },
                { text: report?.report?.q3b ? '√' : ' ', style: 'tableTd', alignment: 'center' }
              ],
              [
                {
                  text: 'c. Close-up photos of façade defects classified as “Require repair” or “Unsafe” attached in Annex, with details of its location and defect type',
                  style: 'tableTd',
                  margin: [16, 5, 3, 5]
                },
                { text: report?.report?.q3c ? '√' : ' ', style: 'tableTd', alignment: 'center' }
              ],
              [{ text: '4. Close-range inspection of the building façade', style: 'tableTh' }, {}],
              [
                {
                  text: 'a. Inspected a minimum of 10% per building elevation',
                  style: 'tableTd',
                  margin: [16, 5, 3, 5]
                },
                { text: report?.report?.q4a ? '√' : ' ', style: 'tableTd', alignment: 'center' }
              ],
              [
                {
                  text: 'b. Location of close-range inspection indicated on elevation plans, with calculation on façade area inspected at each building elevation to justify item 4a.',
                  style: 'tableTd',
                  margin: [16, 5, 3, 5]
                },
                { text: report?.report?.q4b ? '√' : ' ', style: 'tableTd', alignment: 'center' }
              ],
              [
                {
                  text: 'c. Description of inspection methods and its process for each façade system',
                  style: 'tableTd',
                  margin: [16, 5, 3, 5]
                },
                { text: report?.report?.q4c ? '√' : ' ', style: 'tableTd', alignment: 'center' }
              ],
              [
                {
                  text: 'd. Close-up photos of façade defects classified as “Require repair” or “Unsafe” attached in Annex, with details of its location and defect type',
                  style: 'tableTd',
                  margin: [16, 5, 3, 5]
                },
                { text: report?.report?.q4d ? '√' : ' ', style: 'tableTd', alignment: 'center' }
              ],
              [
                {
                  text: '5. Overall elevation photo of each façade elevation, and medium long shot (not more than four stories) covering full building facade.',
                  style: 'tableTh'
                },
                { text: report?.report?.q5 ? '√' : ' ', style: 'tableTd', alignment: 'center' }
              ],
              [{ text: '6. Inspected for signs of façade defects and deterioration', style: 'tableTh' }, {}],
              [
                {
                  text: 'a. Debonding/bulging',
                  style: 'tableTd',
                  margin: [16, 5, 3, 5]
                },
                { text: report?.report?.q6a ? '√' : ' ', style: 'tableTd', alignment: 'center' }
              ],
              [
                {
                  text: 'b. Cracks on façade and its supports',
                  style: 'tableTd',
                  margin: [16, 5, 3, 5]
                },
                { text: report?.report?.q6b ? '√' : ' ', style: 'tableTd', alignment: 'center' }
              ],
              [
                {
                  text: 'c. Corrosion',
                  style: 'tableTd',
                  margin: [16, 5, 3, 5]
                },
                { text: report?.report?.q6c ? '√' : ' ', style: 'tableTd', alignment: 'center' }
              ],
              [
                {
                  text: 'd. Loose/misaligned members',
                  style: 'tableTd',
                  margin: [16, 5, 3, 5]
                },
                { text: report?.report?.q6d ? '√' : ' ', style: 'tableTd', alignment: 'center' }
              ],
              [
                {
                  text: 'e. Spalling concrete',
                  style: 'tableTd',
                  margin: [16, 5, 3, 5]
                },
                { text: report?.report?.q6e ? '√' : ' ', style: 'tableTd', alignment: 'center' }
              ],
              [
                {
                  text: 'f. Dislodged/dangling façade elements',
                  style: 'tableTd',
                  margin: [16, 5, 3, 5]
                },
                { text: report?.report?.q6f ? '√' : ' ', style: 'tableTd', alignment: 'center' }
              ],
              [
                {
                  text: 'g. Missing supports/fixings',
                  style: 'tableTd',
                  margin: [16, 5, 3, 5]
                },
                { text: report?.report?.q6g ? '√' : ' ', style: 'tableTd', alignment: 'center' }
              ],
              [
                {
                  stack: [
                    {
                      text: report?.report?.q6h_comment ? `h. Others (Please specify)\n` : `h. Others (Please specify)`,
                      margin: [16, 5, 3, 0],
                      style: 'tableTd'
                    },
                    {
                      text: report?.report?.q6h_comment ? `${report?.report?.q6h_comment}` : ``,
                      margin: [30, 10, 0, 5],
                      style: 'tableTd'
                    }
                  ]
                },
                { text: report?.report?.q6h ? '√' : ' ', style: 'tableTd', alignment: 'center' }
              ],
              [
                {
                  text: '7. Summary of façade defects observed, with condition classification (“Require Repair” or “Unsafe”)',
                  style: 'tableTh'
                },
                { text: report?.report?.q7 ? '√' : ' ', style: 'tableTd', alignment: 'center' }
              ],
              [
                {
                  text: '8. Recommend remedial works for façade systems classified as “Require Repair” and “Unsafe” and façade maintenance measures.',
                  style: 'tableTh'
                },
                { text: report?.report?.q8 ? '√' : ' ', style: 'tableTd', alignment: 'center' }
              ],
              [
                {
                  text: '9. Conclusion of inspection. Summary of assessment on the condition of the façade elements and highlight façade elements requiring remedial works. Assessment on the necessity for a full façade investigation.',
                  style: 'tableTh'
                },
                { text: report?.report?.q9 ? '√' : ' ', style: 'tableTd', alignment: 'center' }
              ],
              [
                {
                  text: '10. Annex C: Inspection Checklist of Façade Record for Elevation in the “Guidelines on PFI” attached in Annex.',
                  style: 'tableTh'
                },
                { text: report?.report?.q10 ? '√' : ' ', style: 'tableTd', alignment: 'center' }
              ],
              [
                {
                  text: '11. If drones are used for inspection, Annex E: UAS Inspection Report in “TR78-1 : 2020: Building façade inspection using unmanned aircraft system (UAS)” attached in Annex.',
                  style: 'tableTh'
                },
                { text: report?.report?.q11 ? '√' : ' ', style: 'tableTd', alignment: 'center' }
              ],
              [
                {
                  text: '12. Annex D: Standard Certification by Competent Person on first and last page of report.',
                  style: 'tableTh'
                },
                { text: report?.report?.q12 ? '√' : ' ', style: 'tableTd', alignment: 'center' }
              ]
            ]
          },
          margin: [0, 16, 0, 0]
        },
        {
          table: {
            widths: ['*', '*'],
            defaultBorder: false,
            dontBreakRows: true,
            body: [
              [
                {
                  text: [
                    `Competent Person: `,
                    {
                      text: report?.cp?.username
                        ? `         ${report?.cp?.username}            `
                        : `                                     `,
                      bold: true,
                      decoration: 'underline'
                    }
                  ],
                  alignment: 'center'
                },
                {
                  text: [
                    `Date: `,
                    { text: `                                          `, bold: true, decoration: 'underline' }
                  ],
                  margin: [50, 0, 0, 0]
                }
              ]
            ]
          },
          layout: 'noBorders',
          margin: [0, 16, 0, 0]
        },
        {
          text: 'Standard Certification by Competent Person',
          style: ['standardHeader', 'defaultSpacingHeight'],
          pageBreak: 'before'
        },
        {
          text: 'for Periodic Façade Inspection of Buildings',
          style: ['standardHeader', 'defaultSpacingHeight']
        },
        {
          text: [
            'In accordance with Section 28(6) of the Building Control Act (the “Act”) and Regulations 15, 16 and 17 of (Periodic Inspection of Buildings and Building Facades) Regulations 2021 (the “Regulations”), I,',
            {
              text: report?.cp?.username && report?.cp?.regno ? `${report?.cp?.username} (${report?.cp?.regno})` : ' ',
              bold: true
            },
            ', the Competent Person appointed by the building owner under Section 28(3) of the Act have conducted an inspection on the condition and integrity of the building façade and hereby submit the report of the results of the inspection. I certify that the inspection was carried out and the report was prepared by me in accordance with the provisions of the Act and the Regulations.'
          ],
          style: ['standardBody', 'defaultSpacingHeight'],
          margin: [0, 20, 0, 0]
        },
        {
          table: {
            widths: ['*', '*'],
            defaultBorder: false,
            dontBreakRows: true,
            body: [
              [
                {
                  text: [
                    `Competent Person: `,
                    {
                      text: report?.cp?.username
                        ? `         ${report?.cp?.username}            `
                        : `                                     `,
                      bold: true,
                      decoration: 'underline'
                    }
                  ],
                  alignment: 'center'
                },
                {
                  text: [
                    `Date: `,
                    { text: `                                          `, bold: true, decoration: 'underline' }
                  ],
                  margin: [50, 0, 0, 0]
                }
              ]
            ]
          },
          layout: 'noBorders',
          margin: [0, 50, 0, 0]
        }
      ],

      styles: {
        defaultSpacingHeight: {
          characterSpacing: 1,
          lineHeight: 1.4
        },
        person: {
          margin: [0, 60, 0, 0],
          alignment: 'center'
        },
        standardHeader: {
          fontSize: 18,
          bold: true,
          alignment: 'center',
          decoration: 'underline'
        },
        standardBody: {
          fontSize: 14,
          alignment: 'justify'
        },
        coverPage: {
          fontSize: 18,
          alignment: 'center',
          margin: [0, 20, 0, 0],
          bold: true
        },
        coverPageAddress: {
          fontSize: 12,
          alignment: 'center'
        },
        h0: {
          fontSize: 16,
          bold: true
        },
        h1: {
          fontSize: 14,
          bold: true
        },
        h2: {
          fontSize: 12,
          bold: true,
          margin: [0, 10, 0, 0]
        },
        fontNomal: {
          fontSize: 12,
          margin: [0, 10, 0, 0]
        },
        asBuiltDrawings: {
          fontSize: 18,
          margin: [0, 20, 0, 20],
          bold: true,
          alignment: 'center'
        },
        tableTh: {
          margin: [1, 1],
          bold: true,
          fontSize: 10
        },
        tableTd: {
          margin: [1, 1],
          fontSize: 10
        },
        tableDefect: {
          margin: [2, 4, 2, 4],
          fontSize: 10,
          wordBreak: 'break-word'
        }
      }
    }
    const pdfDocGenerator = pdfMake.createPdf(docDefinition)
    pdfDocGenerator.download(report?.building?.name)
    // const boxes = document.getElementsByTagName('page')
    // boxes.forEach((box) => {
    //   box.style.marginBottom = '0px'
    // })
    // // let totalPages = 0
    // const pages = document.querySelectorAll(
    //   '#Standard ,#CoverPage, #TableOfContent, #Inspection-Information, #Introduction, #ClassificationH, #Classification, #SummaryPage, #BuiltDrawings, #FacadeRecord, #DefectListings, #PhotoRecords, #ElevationImages, #UASInspectionReport, #Checklist, #Standard'
    // )
    // const porPages = document.querySelectorAll(
    //   '#CoverPage, #TableOfContent, #Inspection-Information, #SummaryPage, #BuiltDrawings, #FacadeRecord'
    // )
    // const landPages = document.querySelectorAll('#Classification')
    // porPages.forEach((page) => {
    //   totalPages += Math.ceil(page.offsetHeight / 1122.519685)
    // })
    // landPages.forEach((page) => {
    //   totalPages += Math.ceil(page.offsetHeight / 793.7007874)
    // })
    // const tds = document.querySelectorAll('td')
    // tds.forEach((td) => {
    //   td.style.paddingBottom = '1.25rem'
    //   td.style.paddingTop = '0'
    // })
    // const ths = document.querySelectorAll('th')
    // ths.forEach((th) => {
    //   th.style.paddingBottom = '1.25rem'
    //   th.style.paddingTop = '0'
    // })
    // for (let pageIndex = 0; pageIndex < pages.length; pageIndex++) {
    //   pages[pageIndex].style.height = 'auto'
    //   pages[pageIndex].style.minHeight = 'unset'
    // }
    // let images = document.getElementById('pdfContent').getElementsByTagName('img')
    // if (images && images.length > 0) {
    //   for (let index = 0; index < images.length; index++) {
    //     const url = await handleImage(images[index])
    //     images[index].src = url
    //   }
    // }
    // let textareas = document.getElementsByTagName('textarea')
    // textareas.forEach((textarea) => {
    //   let p = document.createElement('div')
    //   p.innerHTML = textarea.innerHTML
    //   textarea.parentNode.replaceChild(p, textarea)
    // })
    // let textareas = Array.from(document.getElementsByTagName('textarea'))
    // textareas.forEach((textarea) => {
    //   let div = document.createElement('div')
    //   div.innerHTML = textarea.value
    //   // Sao chép thuộc tính từ textarea gốc sang div mới
    //   for (let i = 0; i < textarea.attributes.length; i++) {
    //     let attr = textarea.attributes[i]
    //     div.setAttribute(attr.name, attr.value)
    //   }
    //   textarea.parentNode.replaceChild(div, textarea)
    // })
    // const totalPages = handleContent()

    // boxes.forEach((box) => {
    //   box.style.paddingTop = '0px'
    //   box.style.paddingBottom = '0px'
    // })

    // let i = 0
    // let pageIndex = 0
    // let currentPage = 1
    // let pdfPagesArray = Array(pages.length)
    // while (pageIndex < pages.length) {
    //   if (i === pageIndex) {
    //     let ori
    //     if (pages[pageIndex].id !== 'Classification' && pages[pageIndex].id !== 'DefectListings') {
    //       ori = 'portrait'
    //     } else {
    //       ori = 'landscape'
    //     }
    //     html2pdf()
    //       .set({
    //         filename: `${pages[pageIndex].id}`,
    //         pagebreak: {
    //           mode: ['css'],
    //           avoid: [
    //             'td',
    //             'tr',
    //             'img',
    //             'input',
    //             'p',
    //             'h1',
    //             'h2',
    //             '.table-break',
    //             '.elevation-images',
    //             '.reportContentBreakE',
    //             '.reportContentBreak',
    //             'uas-images'
    //           ]
    //         },
    //         margin: [25, 0, 25, 0],
    //         jsPDF: { unit: 'mm', format: 'a4', orientation: ori }
    //       })
    //       .from(pages[pageIndex])
    //       .toPdf()
    //       .get('pdf')
    //       // eslint-disable-next-line no-loop-func
    //       .then((pdf) => {
    //         if (pages[pageIndex].id !== 'Standard') {
    //           let totalPagesInPDF = pdf.internal.getNumberOfPages()
    //           for (let i = 1; i <= totalPagesInPDF; i++) {
    //             pdf.setPage(i)
    //             pdf.setFontSize(10)
    //             pdf.setTextColor(150)
    //             pdf.text(`${currentPage}`, pdf.internal.pageSize.getWidth() - 16, pdf.internal.pageSize.getHeight() - 8)
    //             currentPage = currentPage + 1
    //           }
    //         }
    //       })
    //       .output('arraybuffer')
    //       // eslint-disable-next-line no-loop-func
    //       .then((arraybuffer) => {
    //         // let newPdfs = pdfs
    //         // newPdfs.push(arraybuffer)
    //         pdfPagesArray[i] = arraybuffer
    //         if (i === pages.length - 1) {
    //           mergePDF(pdfPagesArray)
    //         }
    //         i += 1
    //       })
    //       .catch((e) => {
    //         console.log(e)
    //       })
    //     pageIndex += 1
    //   }
    // }
    // let progessing = false
    // let ob = undefined
    // while (pageIndex < pages.length) {
    //   let ori
    //   if (pages[pageIndex].id !== 'Classification' && pages[pageIndex].id !== 'DefectListings') {
    //     ori = 'portrait'
    //   } else {
    //     ori = 'landscape'
    //   }
    //   if (!progessing) {
    //     progessing = true
    //     ob = await convertoPDF(pages[pageIndex], currentPage, pages[pageIndex].id, ori)
    //   }
    //   if (ob && ob.arraybuffer) {
    //     pdfPagesArray[i] = ob.arraybuffer
    //     if (i === pages.length - 1) {
    //       mergePDF(pdfPagesArray)
    //     }
    //     i += 1
    //     currentPage = ob.curPage
    //     pageIndex += 1
    //     ob = undefined
    //     progessing = false
    //   }
    // }
    // // Replace 'pdfContent' with the ID of the HTML element containing the data to be converted
    // var e = document.getElementsByTagName('textarea')[0];
    // var d = document.createElement('p');
    // d.innerHTML = e.innerHTML;
    // e.parentNode.replaceChild(d, e);
    // html2pdf()
    //   .set({
    //     filename: "myPDF.pdf",
    //     pagebreak: { mode: ["avoid-all", "css", "legacy"], after: ".A4"},
    //     margin: [6,0,12,0]
    //   })
    //   .from(element)
    //   .outputPdf()
    //   .get('pdf')
    //   .then((pdf) => {
    //     var totalPages = pdf.internal.getNumberOfPages();
    //     console.log(pdf.interal)
    //     for (let i = 1; i <= totalPages; i++) {
    //       pdf.setPage(i);
    //       pdf.setFontSize(10);
    //       pdf.setTextColor(150);
    //       pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth()/2.25), (pdf.internal.pageSize.getHeight()-8));
    //     }
    //     boxes.forEach(box => {
    //       box.style.marginBottom = '40px';
    //     });
    //     tds.forEach(td => {
    //       td.style.paddingBottom = '0.5rem';
    //       td.style.paddingTop = '0.5rem';
    //   });
    //     ths.forEach(th => {
    //       th.style.paddingBottom = '1.25rem';
    //       th.style.paddingTop = '0';
    //     });
    //   }).catch(() => {
    //     boxes.forEach(box => {
    //       box.style.marginBottom = '40px';
    //     });
    //     tds.forEach(td => {
    //       td.style.paddingBottom = '0.5rem';
    //       td.style.paddingTop = '0.5rem';
    //   });
    //     ths.forEach(th => {
    //       th.style.paddingBottom = '1.25rem';
    //       th.style.paddingTop = '0';
    //   });
    //   })
    //   .save();
    // if (page.id === 'CoverPage') {
    //   pdf.addPage();
    //   pdf.setFontSize(16);
    //   pdf.text(contentHeading.textContent, 10, 10);
    //   let height = 25;
    //   pdf.setFontSize(12);
    //   for (const contentHref of Array.from(contentHrefs)) {
    //     // const pageNumber = parseInt(contentHref.querySelector('[id*="Page"]').textContent);
    //     pdf.text(contentHref.textContent, 10, height);
    //     height += 15;
    //   }
    // }
    setLoadingDownload(false)
  }

  // const convertoPDF = async (e, curPage, id, ori) => {
  //   let currentPage = curPage
  //   const data = await html2pdf()
  //     .set({
  //       filename: `${id}`,
  //       pagebreak: {
  //         mode: ['css'],
  //         avoid: [
  //           'td',
  //           'tr',
  //           'img',
  //           'input',
  //           'p',
  //           'h1',
  //           'h2',
  //           '.table-break',
  //           '.elevation-images',
  //           '.reportContentBreakE',
  //           '.reportContentBreak',
  //           'uas-images'
  //         ]
  //       },
  //       margin: [25, 0, 25, 0],
  //       enableLinks: false,
  //       jsPDF: { unit: 'mm', format: 'a4', orientation: ori }
  //     })
  //     .from(e)
  //     .toPdf()
  //     .get('pdf')
  //     // eslint-disable-next-line no-loop-func
  //     .then((pdf) => {
  //       if (id !== 'Standard' && id !== 'CoverPage' && id !== 'TableOfContent') {
  //         let totalPagesInPDF = pdf.internal.getNumberOfPages()
  //         for (let i = 1; i <= totalPagesInPDF; i++) {
  //           pdf.setPage(i)
  //           pdf.setFontSize(10)
  //           pdf.setTextColor(150)
  //           pdf.text(`${currentPage}`, pdf.internal.pageSize.getWidth() / 1.98, pdf.internal.pageSize.getHeight() - 8)
  //           currentPage = currentPage + 1
  //         }
  //       }
  //     })
  //     .output('arraybuffer')
  //     // eslint-disable-next-line no-loop-func
  //     .then((arraybuffer) => {
  //       // let newPdfs = pdfs
  //       // newPdfs.push(arraybuffer)
  //       const ob = { arraybuffer, curPage: currentPage }
  //       return ob
  //     })
  //     .catch((e) => {
  //       console.log(e)
  //     })
  //   return data
  // }

  const editPDF = async () => {
    if (editMode === true) {
      setEditMode(false)
    } else {
      setEditMode(true)
    }
  }

  const confirm = () => {
    setConfirms(true)
  }

  const handleLock = () => {
    setLocks(true)
  }

  const lock = async () => {
    const lock = {
      locked: !report?.report.locked,
      building_id: report?.report?.building_id
    }
    dispatch(updateLockReport(lock))
    setLocks(false)
  }

  const closePopupConfirm = () => {
    setConfirms(false)
  }

  const closePopupLock = () => {
    setLocks(false)
  }

  const saveConfirm = async (e) => {
    const confirm = {
      description: addConfirm,
      building_id: report?.report?.building_id
    }
    const lock = {
      locked: true,
      building_id: report?.report?.building_id
    }
    setConfirmation(confirm)
    // await reportApi.confirmation(confirm)
    dispatch(updateReportConfirmation(confirm))
  }

  const unlock = async () => {
    const lock = {
      locked: false,
      building_id: report?.report?.building_id
    }
    dispatch(updateLockReport(lock))
  }

  const handleAddConfirm = (e) => {
    setAddConfirm(e.target.value)
  }

  const savePDF = async () => {
    setLoading(true)

    let reportData = report
    let drawings = report?.report?.drawing
    let site_buildings = report?.report?.site_buildings
    let site_layouts = report?.report?.site_layout
    let site_others = report?.report?.site_others

    if (drawings && drawings.length > 0) {
      drawings = await uploadImage(drawings)
    }
    if (site_buildings && site_buildings.length > 0) {
      site_buildings = await uploadImage(site_buildings)
    }
    if (site_layouts && site_layouts.length > 0) {
      site_layouts = await uploadImage(site_layouts)
    }
    if (site_others && site_others.length > 0) {
      site_others = await uploadImage(site_others)
    }

    reportData = {
      ...reportData,
      report: {
        ...reportData.report,
        drawing: drawings,
        site_buildings: site_buildings,
        site_layout: site_layouts,
        site_others: site_others
      }
    }

    setReport(reportData)
    if ([...makeRoles([13])].includes(role)) {
      const declarationData = {
        checked: declaration.checked ? declaration.checked : false,
        building_id: reportData.report.building_id
      }
      await reportApi.addDeclare(declarationData)
    }

    dispatch(updateInspectionReport(reportData.report))
  }
  const uploadImage = async (array) => {
    const options = {
      maxSizeMB: 1,
      initialQuality: 0.6,
      maxWidthOrHeight: 1920
    }
    for (const data of array) {
      if (data.url.includes('base64')) {
        const dataThumbnail = {
          filename: data.file.name,
          contentType: data.file.type,
          key: 'report',
          reportId: contentsReport[reportTypes.GET_INSPECTION_REPORT].report.id,
          size: data.file.size
        }
        const { signedUrl } = await uploadElevationImage(dataThumbnail)
        const compressedFile = await imageCompression(data.file, options)
        await fetch(signedUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': data.file.type
          },
          body: compressedFile
        })
        reset()
        data.url = signedUrl.split('?')[0]
        delete data.file
      }
    }

    return array
  }
  // const mergePDF = async (array) => {
  //   let merger = new PDFMerger()
  //   for (const pdf of array) {
  //     await merger.add(pdf)
  //   }
  //   await merger.save(report?.report?.building_id)
  //   dispatch(getInspectionReport(id))
  //   // setReport({ ...contentsReport[reportTypes.GET_INSPECTION_REPORT] })
  //   setLoading(false)
  //   setLoadingDownload(false)
  //   // const mergedPdf = await merger.saveAsBuffer();
  //   // var blob = new Blob([mergedPdf], {type: "application/pdf"});
  //   // var link = window.URL.createObjectURL(blob);
  //   // window.open(link);
  //   // setTimeout(() => {
  //   //   window.location.reload()
  //   // }, 1000)
  // }

  const handleImagePDF = async (src) => {
    try {
      const c = document.getElementById('canvas')
      await handleCreateCanvasFullImagePDF(src)

      let canvasUrl = await c.toDataURL('image/jpeg')
      return canvasUrl
    } catch (e) {
      return ''
    }
  }

  const convertURLImageToBase64 = async (src) => {
    try {
      const response = await fetch(src, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache'
      })

      const blob = await response.arrayBuffer()

      const contentType = response.headers.get('content-type')

      const base64String = `data:${contentType};base64,${Buffer.from(blob).toString('base64')}`

      return base64String
    } catch (err) {}
  }

  const handleCreateCanvasFullImagePDF = async (image) => {
    try {
      var canvas = document.getElementById('canvas')
      const ctx = canvas.getContext('2d')
      var img = new Image()
      img.crossOrigin = 'anonymous'
      img.onload = async function () {
        canvas.width = img.width
        canvas.height = img.height
        var t = Math.floor(canvas.width / 100)
        var w = Math.floor(t / 2)
        await ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
        ctx.lineWidth = w
      }
      img.src = image + '?stopGivingMeHeadaches=true'
      await img.decode()
    } catch (e) {}
  }

  const handleImage = async (element) => {
    try {
      const c = document.getElementById('canvas')
      await handleCreateCanvasFullImage({
        element: element
      })

      let canvasUrl = await c.toDataURL('image/jpeg')
      return canvasUrl
    } catch (e) {
      return ''
    }
  }

  const handleCreateCanvasFullImage = async (image) => {
    try {
      var canvas = document.getElementById('canvas')
      const ctx = canvas.getContext('2d')
      var img = new Image()
      img.crossOrigin = 'anonymous'
      img.onload = async function () {
        canvas.width = img.width
        canvas.height = img.height
        var t = Math.floor(canvas.width / 100)
        var w = Math.floor(t / 2)
        if (image.element.id === 'logo') {
          ctx.fillStyle = 'white'
          ctx.fillRect(0, 0, canvas.width, canvas.height)
        }
        await ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
        ctx.lineWidth = w
      }
      img.src = image.element.src + '?stopGivingMeHeadaches=true'
      await img.decode()
      return image.url
    } catch (e) {}
  }

  const onDeleteDrawing = (index) => {
    let reportData = report
    let drawings = [...report?.report?.drawing]
    drawings?.splice(index, 1)

    reportData = {
      ...reportData,
      report: {
        ...reportData.report,
        drawing: drawings
      }
    }

    setReport(reportData)
  }

  const onDeleteLayout = (index) => {
    let reportData = report
    let layout = [...report?.report?.site_layout]
    layout?.splice(index, 1)

    reportData = {
      ...reportData,
      report: {
        ...reportData.report,
        site_layout: layout
      }
    }

    setReport(reportData)
  }

  const onDeleteBuildings = (index) => {
    let reportData = report
    let buildings = [...report?.report?.site_buildings]
    buildings?.splice(index, 1)

    reportData = {
      ...reportData,
      report: {
        ...reportData.report,
        site_buildings: buildings
      }
    }

    setReport(reportData)
  }

  const onDeleteOthers = (index) => {
    let reportData = report
    let others = [...report?.report?.site_others]
    others?.splice(index, 1)

    reportData = {
      ...reportData,
      report: {
        ...reportData.report,
        site_others: others
      }
    }

    setReport(reportData)
  }

  const handleChecked = async (e) => {
    const declare = {
      checked: e.target.checked,
      building_id: report?.report?.building_id
    }
    setDeclaration(declare)
    // await reportApi.addDeclare(declare)
  }
  const handleContent = () => {
    const pdfPages = document.querySelectorAll(
      '#Inspection-Information, #Introduction, #ClassificationH, #Classification, #ClassificationCloseRange, #SummaryPage, #ConclusionPage, #BuiltDrawings, #RecordVisual, #PhotoRecords, #FacadeRecord, #DefectListings, #CloseRangePhotoRecord, #ElevationImages, #UASInspectionReport, #Checklist'
    )
    // let morePages = 0
    // let landscapePages = 0
    let pages = 2
    for (let index = 0; index < pdfPages.length; index++) {
      const headings = pdfPages[index].querySelectorAll('li')
      let gap = 0
      let currentCount = 1
      for (const heading of headings) {
        if (!heading.id || heading.id.includes('-container-div')) {
          continue
        }
        if (pdfPages[index].id === 'Classification' || pdfPages[index].id === 'DefectListings') {
          const page =
            Math.ceil(
              (heading.offsetHeight + heading.offsetTop - pdfPages[index].offsetTop + 188.97637795) / 793.7007874
            ) + pages

          // if (
          //   heading.offsetTop - pdfPages[index].offsetTop < 793.7007874 * currentCount &&
          //   (heading.offsetHeight + heading.offsetTop - pdfPages[index].offsetTop + 188.97637795) / 793.7007874 >
          //     1 * currentCount
          // ) {
          //   gap += heading.offsetHeight
          // }
          // currentCount = Math.ceil(
          //   (heading.offsetHeight + heading.offsetTop - pdfPages[index].offsetTop + 188.97637795) / 793.7007874
          // )
          handleSetContentPage(heading.id, page)
        } else if (pdfPages[index].id === 'BuiltDrawings') {
          const page =
            Math.ceil(
              (heading.offsetHeight + heading.offsetTop + gap - pdfPages[index].offsetTop + 188.97637795) / 793.7007874
            ) + pages

          handleSetContentPage(heading.id, page)

          const images = document.getElementById('BuiltDrawings').getElementsByClassName('drawing-break')
          for (const image of Array.from(images)) {
            const img = image.getElementsByTagName('img')[0]
            const imgOffset = img.getBoundingClientRect()
            if (
              imgOffset.height + imgOffset.top + gap - pdfPages[index].offsetTop >
              (1122.519685 - 188.97637795) * currentCount
            ) {
              gap +=
                imgOffset.height +
                imgOffset.top +
                gap -
                pdfPages[index].offsetTop -
                (1122.519685 - 188.97637795) * currentCount
              currentCount = Math.ceil(
                (imgOffset.height + imgOffset.top + gap - pdfPages[index].offsetTop + 188.97637795 * currentCount) /
                  1122.519685
              )
            }
            const inputs = image.getElementsByTagName('input')[0]
            for (const input of Array.from(inputs)) {
              const inputOffset = input.getBoundingClientRect()
              if (
                inputOffset.height + inputOffset.top + gap - pdfPages[index].offsetTop + 188.97637795 >
                (1122.519685 - 188.97637795) * currentCount
              ) {
                gap +=
                  inputOffset.height +
                  inputOffset.top +
                  gap -
                  pdfPages[index].offsetTop -
                  (1122.519685 - 188.97637795) * currentCount
                currentCount = Math.ceil(
                  (inputOffset.height +
                    inputOffset.top +
                    gap -
                    pdfPages[index].offsetTop +
                    188.97637795 * currentCount) /
                    1122.519685
                )
              }
            }
          }
        } else if (pdfPages[index].id === 'PhotoRecords') {
          const page =
            Math.ceil(
              (heading.offsetHeight + heading.offsetTop + gap - pdfPages[index].offsetTop + 188.97637795) / 1122.519685
            ) + pages

          handleSetContentPage(heading.id, page)

          const tables = document.getElementById('PhotoRecords').getElementsByClassName('table-break')
          for (const table of Array.from(tables)) {
            const tableOffset = table.getBoundingClientRect()
            if (
              tableOffset.height + tableOffset.top + gap - pdfPages[index].offsetTop >
              (1122.519685 - 188.97637795) * currentCount
            ) {
              gap +=
                tableOffset.height +
                tableOffset.top +
                gap -
                pdfPages[index].offsetTop -
                (1122.519685 - 188.97637795) * currentCount
            }
            currentCount = Math.ceil(
              (table.offsetHeight + table.offsetTop + gap - pdfPages[index].offsetTop + 188.97637795 * currentCount) /
                1122.519685
            )
          }
        } else if (pdfPages[index].id === 'ElevationImages') {
          const page =
            Math.ceil(
              (heading.offsetHeight + heading.offsetTop + gap - pdfPages[index].offsetTop + 188.97637795) / 1122.519685
            ) + pages

          handleSetContentPage(heading.id, page)

          const divs = document.getElementById('ElevationImages').getElementsByClassName('elevation-images')
          for (const div of Array.from(divs)) {
            const divOffset = div.getBoundingClientRect()
            if (
              divOffset.height + divOffset.top + gap - pdfPages[index].offsetTop >
              (1122.519685 - 188.97637795) * currentCount
            ) {
              gap +=
                divOffset.height +
                divOffset.top +
                gap -
                pdfPages[index].offsetTop -
                (1122.519685 - 188.97637795) * currentCount
            }
            currentCount = Math.ceil(
              (div.offsetHeight + div.offsetTop + gap - pdfPages[index].offsetTop + 188.97637795 * currentCount) /
                1122.519685
            )
          }
        } else if (pdfPages[index].id === 'UASInspectionReport') {
          const page =
            Math.ceil(
              (heading.offsetHeight + heading.offsetTop + gap - pdfPages[index].offsetTop + 188.97637795) / 1122.519685
            ) + pages

          handleSetContentPage(heading.id, page)

          const divs = document.getElementById('UASInspectionReport').getElementsByClassName('uas-images')
          for (const div of Array.from(divs)) {
            const divOffset = div.getBoundingClientRect()
            if (
              divOffset.height + divOffset.top + gap - pdfPages[index].offsetTop >
              (1122.519685 - 188.97637795) * currentCount
            ) {
              gap +=
                divOffset.height +
                divOffset.top +
                gap -
                pdfPages[index].offsetTop -
                (1122.519685 - 188.97637795) * currentCount
            }
            currentCount = Math.ceil(
              (div.offsetHeight + div.offsetTop + gap - pdfPages[index].offsetTop + 188.97637795 * currentCount) /
                1122.519685
            )
          }
        } else {
          const page =
            Math.ceil(
              (heading.offsetHeight + heading.offsetTop + gap - pdfPages[index].offsetTop + 188.97637795) / 1122.519685
            ) + pages

          // console.log(
          //   heading.id,
          //   heading.offsetHeight + heading.offsetTop + gap - pdfPages[index].offsetTop + 188.97637795,
          //   gap
          // )

          handleSetContentPage(heading.id, page)

          const h1 = pdfPages[index].getElementsByTagName('h1')[0]
          if (h1) {
            const h1Offset = h1.getBoundingClientRect()
            if (
              h1Offset.height + h1Offset.top + gap - pdfPages[index].offsetTop >
              (1122.519685 - 188.97637795) * currentCount
            ) {
              gap +=
                h1Offset.height +
                h1Offset.top +
                gap -
                pdfPages[index].offsetTop -
                (1122.519685 - 188.97637795) * currentCount
            }
            currentCount = Math.ceil(
              (h1.offsetHeight + h1.offsetTop + gap - pdfPages[index].offsetTop + 188.97637795 * currentCount) /
                1122.519685
            )
          }

          const divContainer = document.getElementById(`${heading.id}-container-div`)
          if (divContainer) {
            const h2 = divContainer.getElementsByTagName('h2')[0]

            if (h2) {
              const h2Offset = h2.getBoundingClientRect()
              if (
                h2Offset.height + h2Offset.top + gap - pdfPages[index].offsetTop >
                (1122.519685 - 188.97637795) * currentCount
              ) {
                gap +=
                  h2Offset.height +
                  h2Offset.top +
                  gap -
                  pdfPages[index].offsetTop -
                  (1122.519685 - 188.97637795) * currentCount
              }
              currentCount = Math.ceil(
                (h2.offsetHeight + h2.offsetTop + gap - pdfPages[index].offsetTop + 188.97637795 * currentCount) /
                  1122.519685
              )
            }

            const ps = divContainer.getElementsByTagName('p')

            for (const p of Array.from(ps)) {
              const pOffset = p.getBoundingClientRect()
              if (
                pOffset.height + pOffset.top + gap - pdfPages[index].offsetTop >
                (1122.519685 - 188.97637795) * currentCount
              ) {
                gap +=
                  pOffset.height +
                  pOffset.top +
                  gap -
                  pdfPages[index].offsetTop -
                  (1122.519685 - 188.97637795) * currentCount
              }
              currentCount = Math.ceil(
                (p.offsetHeight + p.offsetTop + gap - pdfPages[index].offsetTop + 188.97637795 * currentCount) /
                  1122.519685
              )
            }
          }

          // const
          // for (const h1 of Array.from(h1s)) {
          //   const h1Offset = h1.getBoundingClientRect()
          //   if (
          //     h1Offset.height + h1Offset.top + gap - pdfPages[index].offsetTop >
          //     (1122.519685 - 188.97637795) * currentCount
          //   ) {
          //     gap +=
          //       h1Offset.height +
          //       h1Offset.top +
          //       gap -
          //       pdfPages[index].offsetTop -
          //       (1122.519685 - 188.97637795) * currentCount
          //   }
          //   currentCount = Math.ceil(
          //     (h1.offsetHeight + h1.offsetTop + gap - pdfPages[index].offsetTop + 188.97637795 * currentCount) /
          //       1122.519685
          //   )
          // }
        }
      }

      if (pdfPages[index].id === 'Classification' || pdfPages[index].id === 'DefectListings') {
        // const addPages = Math.ceil(pdfPages[index].offsetHeight / 793.7007874)
        // landscapePages += addPages
        // if (pdfPages[index].offsetHeight > 793.7007874) {
        //   const pageMore = Math.floor(pdfPages[index].offsetHeight / 793.7007874)
        //   morePages += pageMore
        // }
        const addPages = Math.ceil((pdfPages[index].offsetHeight + gap + 188.97637795 * currentCount) / 793.7007874)
        pages += addPages
      } else {
        const addPages = Math.ceil((pdfPages[index].offsetHeight + gap + 188.97637795 * currentCount) / 1122.519685)
        // morePages += addPages
        pages += addPages
        if (pdfPages[index].id === 'Introduction') {
        }
      }
    }

    return pages
  }

  const handleSetContentPage = (id, page) => {
    switch (id) {
      case 'INSPECTION-INFORMATION':
        setInspectionInformationPage(page)
        break
      case 'Inspection-Dates':
        setInspectionDatesPage(page)
        break
      case 'Inspection-Location':
        setInspectionLocationPage(page)
        break
      case 'Inspectors':
        setInspectorsPage(page)
        break
      case 'Witnesses':
        setWitnessesPage(page)
        break
      case 'Report-Preparation':
        setReportPreparationPage(page)
        break
      case 'Reference-Documents':
        setReferenceDocumentsPage(page)
        break
      case 'INTRODUCTION':
        setIntroductionPage(page)
        break
      case 'Background-Information':
        setBackgroundInformationPage(page)
        break
      case 'Objectives':
        setObjectivesPage(page)
        break
      case 'Façade-Systems':
        setFacadeSystemsPage(page)
        break
      case 'DEFECT-DESCRIPTION-AND-CLASSIFICATION':
        setDefectPage(page)
        break
      case 'SUMMARY-AND-RECOMMENDATION':
        setSummaryAndRecommendationsPage(page)
        break
      case 'Summary':
        setSummaryPage(page)
        break
      case 'Recommendations':
        setRecommendationsPage(page)
        break
      case 'CONCLUSIONVALUE':
        setConclusionValue(page)
        break
      case 'ConclusionPage':
        setConclusionPage(page)
        break
      case 'appendixA':
        setAppendixA(page)
        break
      case 'appendixB':
        setAppendixB(page)
        break
      case 'appendixC':
        setAppendixC(page)
        break
      case 'appendixD':
        setAppendixD(page)
        break
      case 'appendixE':
        setAppendixE(page)
        break
      case 'appendixF':
        setAppendixF(page)
        break
      default:
        break
    }
  }

  const setValueInpectionInformation = (value, name) => {
    const newdata = {
      ...contentsReport[reportTypes.GET_INSPECTION_REPORT],
      report: {
        ...report.report,
        [name]: value
      }
    }
    setReport(newdata)
  }

  useEffect(() => {
    if (prevUpdateReportFetching && !loadings[reportTypes.UPDATE_INSPECTION_REPORT]) {
      if (!errors[reportTypes.UPDATE_INSPECTION_REPORT]) {
        toast.success('Save Report Successfully', {
          theme: 'dark',
          autoClose: 2000
        })
        setEditMode(false)
      } else {
        toast.error(errors[reportTypes.UPDATE_INSPECTION_REPORT], {
          theme: 'dark',
          autoClose: 2000
        })
      }
    }
    setLoading(false)
  }, [errors, loadings, prevUpdateReportFetching])

  useEffect(() => {
    if (prevGetReportFetching && !loadings[reportTypes.GET_INSPECTION_REPORT]) {
      if (!errors[reportTypes.GET_INSPECTION_REPORT]) {
        setRenderingProcess((prev) => ({ ...prev, error: false, loading: false }))
      } else {
        setRenderingProcess((prev) => ({ ...prev, error: true, loading: false }))
      }
    }
  }, [errors, loadings, prevGetReportFetching])

  const renderSkeleton = () => {
    return (
      <div className=" bg-gray-500 w-full h-auto py-10">
        <div>
          <div className="flex justify-center items-end mr-4 gap-2 flex-col sticky left-5">
            <SkeletonViewer height={48} width={140} borderRadius={6} />
            <SkeletonViewer height={48} width={140} borderRadius={6} />
          </div>
          <div className="flex justify-center items-center flex-col mt-[-100px]">
            <div id="pdfContent" style={{ marginTop: 0 }}>
              <page id="Standard" className="A4">
                {/* <h1 className="w-2/3 m-auto py-2 font-bold text-center underline">
                  Standard Certification by Competent Person for Periodic Façade Inspection of Buildings
                </h1> */}
                <SkeletonViewer height={72} width={600} />
                <div className="py-2 text-justify font-normal">
                  <SkeletonViewer height={270} width={600} />
                </div>

                <div className="flex justify-between gap-8 pt-24 font-normal">
                  <div className="flex justify-center gap-2 my-4">
                    <SkeletonViewer height={32} width={290} />
                  </div>
                  <div className="flex justify-center gap-2 my-4">
                    <SkeletonViewer height={32} width={290} />
                  </div>
                </div>
              </page>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <React.Fragment>
      {(loadingDownLoad || loading) && <LoadingSpinner />}
      <Navigate options={optionNavigate} isBackButton backToLink={`/inspections/${id}`} />
      <PaddingAuto>
        {renderingProcess.loading ? (
          <>{renderSkeleton()}</>
        ) : renderingProcess.error ? (
          <NotAvailable content={errors[reportTypes.GET_INSPECTION_REPORT]} />
        ) : (
          <div className=" bg-gray-500 w-full h-auto py-10">
            <div className="relative">
              <div className="flex justify-center items-end mr-4 gap-2 flex-col sticky top-4 left-5">
                {!editMode ? (
                  <>
                    {[...makeRoles([1, 2, 3, 6, 7, 8, 9, 10, 12, 13])].includes(role) && (
                      <button
                        className="py-2 px-4 shadow-2xl rounded-md leading-8 font-bold text-white bg-blue-500 w-[140px]"
                        onClick={generatePDF}
                      >
                        Download PDF
                      </button>
                    )}
                    <button
                      type="button"
                      onClick={scrollToTop}
                      className={` ${
                        visible ? `block` : `hidden`
                      } fixed bottom-8 right-8  p-3 bg-red-600 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out`}
                    >
                      <img src={TopIcon} className="w-4 h-4" alt="topIcon" />
                    </button>
                    {!report?.report?.locked ? (
                      <>
                        {[...makeRoles([6, 7, 10, 13])].includes(role) ? (
                          <button
                            className="py-2 px-4 shadow-2xl rounded-md leading-8 font-bold text-white bg-yellow-500 w-[140px]"
                            onClick={editPDF}
                          >
                            Edit PDF
                          </button>
                        ) : (
                          <div className="h-12"></div>
                        )}
                        {[...makeRoles([10])].includes(role) && (
                          <button
                            className="py-2 px-4 shadow-2xl rounded-md leading-8 font-bold text-white bg-green-500 w-[140px]"
                            onClick={confirm}
                          >
                            Confirm
                          </button>
                        )}
                      </>
                    ) : (
                      <>
                        {[...makeRoles([10])].includes(role) ? (
                          <button
                            className="py-2 px-4 shadow-2xl rounded-md leading-8 font-bold text-white bg-red-500 w-[140px]"
                            onClick={unlock}
                          >
                            Unconfirm
                          </button>
                        ) : (
                          <div className="h-12"></div>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <button
                      className="py-2 px-4 shadow-2xl rounded-md leading-8 font-bold text-white bg-green-500 w-[140px]"
                      onClick={savePDF}
                    >
                      Save
                    </button>
                    <div className="h-12"></div>
                  </>
                )}
              </div>

              {/* <div className="w-full relative flex justify-end pr-6"> */}

              {/* </div> */}
              <div className="flex justify-center items-center flex-col mt-[-100px]">
                <div id="pdfContent" style={{ marginTop: 0 }}>
                  <page id="Standard" className="A4">
                    <Standard
                      report={report?.report}
                      building={report?.building}
                      inspectors={report?.inspectors}
                      cp={report?.cp}
                      editMode={editMode}
                      setValue={setValueInpectionInformation}
                    />
                  </page>
                  <page id="CoverPage" className="A4">
                    <CoverPage
                      data={report?.building}
                      report={report?.report}
                      company={report?.company}
                      handleBreakLine={handleBreakLine}
                    />
                  </page>
                  <page id="TableOfContent" className="A4">
                    <TableOfContent
                      data={report?.report}
                      InspectionInformationPage={InspectionInformationPage}
                      InspectionDatesPage={InspectionDatesPage}
                      InspectionLocationPage={InspectionLocationPage}
                      InspectorsPage={InspectorsPage}
                      WitnessesPage={WitnessesPage}
                      ReportPreparationPage={ReportPreparationPage}
                      ReferenceDocumentsPage={ReferenceDocumentsPage}
                      IntroductionPage={IntroductionPage}
                      BackgroundInformationPage={BackgroundInformationPage}
                      ObjectivesPage={ObjectivesPage}
                      FacadeSystemsPage={FacadeSystemsPage}
                      DefectPage={DefectPage}
                      SummaryAndRecommendationsPage={SummaryAndRecommendationsPage}
                      SummaryPage={SummaryPage}
                      RecommendationsPage={RecommendationsPage}
                      ConclusionValue={ConclusionValue}
                      ConclusionPage={ConclusionPage}
                      AppendixA={appendixA}
                      AppendixB={appendixB}
                      AppendixC={appendixC}
                      AppendixD={appendixD}
                      AppendixE={appendixE}
                      AppendixF={appendixF}
                      DefectListingsV={defectListingsV}
                    />
                  </page>
                  <page id="Introduction" className="A4">
                    <Introduction
                      report={report?.report}
                      building={report?.building}
                      inspectors={report?.inspectors}
                      editMode={editMode}
                      setValue={setValueInpectionInformation}
                      handleBreakLine={handleBreakLine}
                    />
                  </page>
                  <page id="Inspection-Information" className="A4">
                    <InspectionInformation
                      report={report?.report}
                      building={report?.building}
                      inspectors={report?.inspectors}
                      cp={report?.cp}
                      editMode={editMode}
                      setValue={setValueInpectionInformation}
                      handleBreakLine={handleBreakLine}
                    />
                  </page>

                  <page id="ClassificationH" className="A4">
                    <ClassificationH
                      editMode={editMode}
                      report={report?.report}
                      building={report?.building}
                      inspectors={report?.inspectors}
                      defectSummary={report?.defectSummary}
                      handleBreakLine={handleBreakLine}
                      setValue={setValueInpectionInformation}
                    />
                  </page>
                  <page id="Classification" className="A4-landscape">
                    <Classification
                      editMode={editMode}
                      report={report?.report}
                      building={report?.building}
                      inspectors={report?.inspectors}
                      defectSummary={report?.defectSummary}
                      setValue={setValueInpectionInformation}
                    />
                  </page>
                  <page id="ClassificationCloseRange" className="A4-landscape">
                    <ClassificationCloseRange
                      editMode={editMode}
                      report={report?.report}
                      building={report?.building}
                      inspectors={report?.inspectors}
                      crDefectSummary={report?.crDefectSummary}
                      setValue={setValueInpectionInformation}
                    />
                  </page>
                  <page id="SummaryPage" className="A4">
                    <Summary
                      editMode={editMode}
                      report={report?.report}
                      building={report?.building}
                      inspectors={report?.inspectors}
                      handleBreakLine={handleBreakLine}
                      setValue={setValueInpectionInformation}
                    />
                  </page>
                  <page id="ConclusionPage" className="A4">
                    <Conclusion
                      editMode={editMode}
                      report={report?.report}
                      building={report?.building}
                      inspectors={report?.inspectors}
                      handleBreakLine={handleBreakLine}
                      setValue={setValueInpectionInformation}
                    />
                  </page>
                  <page id="BuiltDrawings" className="A4">
                    <BuiltDrawings
                      editMode={editMode}
                      report={report?.report}
                      building={report?.building}
                      inspectors={report?.inspectors}
                      cp={report?.cp}
                      handleBreakLine={handleBreakLine}
                      setValue={setValueInpectionInformation}
                      onDeleteDrawing={onDeleteDrawing}
                      name="drawing"
                    />
                  </page>
                  <page id="RecordVisual" className="A4-landscape">
                    <RecordVisual
                      editMode={editMode}
                      report={report?.report}
                      building={report?.building}
                      inspectors={report?.inspectors}
                      cp={report?.cp}
                      defectListing={reportDefectListing}
                      setValue={setValueInpectionInformation}
                    />
                  </page>
                  <page id="PhotoRecords" className="A4">
                    <PhotoRecords
                      report={report?.report}
                      building={report?.building}
                      inspectors={report?.inspectors}
                      cp={report?.cp}
                      defectListing={reportDefectListing}
                      setValue={setValueInpectionInformation}
                    />
                  </page>
                  <page id="FacadeRecord" className="A4">
                    <FacadeRecord
                      editMode={editMode}
                      report={report?.report}
                      building={report?.building}
                      inspectors={report?.inspectors}
                      cp={report?.cp}
                      handleBreakLine={handleBreakLine}
                      handleChecked={handleChecked}
                      setValue={setValueInpectionInformation}
                    />
                  </page>
                  <page id="DefectListings" className="A4-landscape">
                    <DefectListings
                      editMode={editMode}
                      report={report?.report}
                      building={report?.building}
                      inspectors={report?.inspectors}
                      cp={report?.cp}
                      crDefectListing={reportCloseRangeDefectListing}
                      setValue={setValueInpectionInformation}
                    />
                  </page>
                  <page id="CloseRangePhotoRecord" className="A4">
                    <CloseRangePhotoRecord
                      editMode={editMode}
                      report={report?.report}
                      building={report?.building}
                      inspectors={report?.inspectors}
                      cp={report?.cp}
                      crDefectListing={reportCloseRangeDefectListing}
                      setValue={setValueInpectionInformation}
                    />
                  </page>
                  <page id="ElevationImages" className="A4">
                    <ElevationImages
                      report={report?.report}
                      building={report?.building}
                      inspectors={report?.inspectors}
                      cp={report?.cp}
                      defectListing={report?.defectListing}
                      elevations={report?.elevations}
                      setValue={setValueInpectionInformation}
                    />
                  </page>
                  <page id="UASInspectionReport" className="A4">
                    <UASInspectionReport
                      editMode={editMode}
                      report={report?.report}
                      building={report?.building}
                      inspectors={report?.inspectors}
                      cp={report?.cp}
                      handleBreakLine={handleBreakLine}
                      setValue={setValueInpectionInformation}
                      name={{
                        site_layout: 'site_layout',
                        site_buildings: 'site_buildings',
                        site_others: 'site_others'
                      }}
                      onDeleteLayout={onDeleteLayout}
                      onDeleteBuildings={onDeleteBuildings}
                      onDeleteOthers={onDeleteOthers}
                    />
                  </page>
                  <page id="Checklist" className="A4">
                    <Checklist
                      editMode={editMode}
                      report={report?.report}
                      building={report?.building}
                      inspectors={report?.inspectors}
                      cp={report?.cp}
                      handleBreakLine={handleBreakLine}
                      setValue={setValueInpectionInformation}
                    />
                  </page>
                  <page id="Standard" className="A4">
                    <Standard
                      report={report?.report}
                      building={report?.building}
                      inspectors={report?.inspectors}
                      cp={report?.cp}
                      setValue={setValueInpectionInformation}
                    />
                  </page>
                  {confirms && (
                    <Confirm
                      closePopupConfirm={closePopupConfirm}
                      saveConfirm={saveConfirm}
                      addConfirm={addConfirm}
                      handleAddConfirm={handleAddConfirm}
                    />
                  )}
                  {locks && <Lock lock={lock} closePopupLock={closePopupLock} status={report?.report?.locked} />}
                </div>
              </div>
            </div>
          </div>
        )}
      </PaddingAuto>
      <canvas id="canvas" style={{ display: 'none' }}></canvas>
    </React.Fragment>
  )
}

export default BuildingSummaryReport
