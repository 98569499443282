import React, { useRef } from 'react'
import Textarea from '../Report/Textarea'

function Introduction({ report, building, inspectors, editMode, setValue, handleBreakLine }) {
  const refInformation7 = useRef()
  const refInformation8 = useRef()
  const refInformation9 = useRef()
  return (
    <>
      <ol className="toc-list" style={{ lineHeight: '30px' }}>
        <li id="INTRODUCTION">
          <div className="font-bold">
            <h1 className="font-bold title">1.0 INTRODUCTION</h1>
          </div>
        </li>
        {/* <ol> */}
        <li id="Background-Information">
          <div>
            <h2 className="font-bold pt-5 title">1.1 Background Information</h2>
          </div>
        </li>
        <li id="Background-Information-container-div">
          <div className="py-2">
            <Textarea
              refTextArea={refInformation7}
              id="refInformation7"
              values={report?.background}
              name="background"
              setValue={setValue}
              editMode={editMode}
            />
            <div id="refInformation7-div-container" className={editMode ? 'hidden' : ''}>
              <div id={`refInformation7-div`}>{handleBreakLine(report?.background)}</div>
            </div>
          </div>
        </li>

        <li id="Objectives">
          <div>
            <h2 className="font-bold title">1.2 Objectives</h2>
          </div>
        </li>
        <li id="Objectives-container-div">
          <div className="py-2">
            <Textarea
              refTextArea={refInformation8}
              id="refInformation8"
              values={report?.objectives}
              name="objectives"
              setValue={setValue}
              editMode={editMode}
            />
            <div id="refInformation8-div-container" className={editMode ? 'hidden' : ''}>
              <div id={`refInformation8-div`}>{handleBreakLine(report?.objectives)}</div>
            </div>
          </div>
        </li>

        <li id="Façade-Systems">
          <div>
            <h2 className="font-bold title">1.3 Façade Systems</h2>
          </div>
        </li>
        <li id="Façade-Systems-container-div">
          <div className="py-2">
            <Textarea
              refTextArea={refInformation9}
              id="refInformation9"
              values={report?.systems}
              name="systems"
              setValue={setValue}
              editMode={editMode}
            />
            <div id="refInformation9-div-container" className={editMode ? 'hidden' : ''}>
              <div id={`refInformation9-div`}>{handleBreakLine(report?.systems)}</div>
            </div>
          </div>
        </li>
      </ol>
    </>
  )
}

export default Introduction
