// libs
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { onConfirm } from 'react-confirm-pro'
import { toast } from 'react-toastify'
import { useNavigate, useLocation, createSearchParams } from 'react-router-dom'
import { format } from 'date-fns'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'

// hocs
import useAuth from '../../../hooks/useAuth'
import usePrevious from '../../../hooks/usePrevious'

// actions
import {
  createCompany,
  getCompanies,
  updateCompany,
  deleteCompany,
  revertDeleteCompany
} from '../../../store/thunks/companyThunk'

// commons
import Table from '../../Common/table2'
import Cropper2 from '../../Common/cropper2/Cropper'
import Modal from '../../Common/Modal'
import StatusButton from '../../Common/status'
import { isEmptyObject, makeRoles } from '../../../utils/common'

// @antd
import {
  SaveOutlined,
  ExclamationCircleOutlined,
  HomeOutlined,
  FieldTimeOutlined,
  UserOutlined,
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
  RedoOutlined /*  */
} from '@ant-design/icons'
import { BsCameraFill } from 'react-icons/bs'
import { TbHomeQuestion } from 'react-icons/tb'
import { MdOutlineRecommend } from 'react-icons/md'

// assets
import NextArrowIconWhite from '../../../assets/images/svg/arrow_next_white.svg'

// variables
import { adminRoles } from '../../../constant/auth'
import { color } from '../../../constant/styled'
import companyTypes from '../../../constant/store/companyTypes'
import SkeletonViewer from '../../Common/skeleton-viewer'
import LoadingSpinner from '../../Common/loading-spinner/LoadingSpinner'
import NotAvailable from '../../Common/NotAvailable'
import PreviewImage from '../../Common/PreviewImage'
import PaddingAuto from '../../Layout/PaddingAuto'
import { useMemo } from 'react'

// navigate variable

const CompanyManagement = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { user } = useAuth()
  const { role } = user?.user || {}
  const [_mounted, setMouted] = useState(false)

  const {
    loadings: loadingsCompany,
    contents: contentsCompany,
    errors: errorsCompany,
    paginations: paginationsCompany
  } = useSelector((state) => state.company)

  const companies = contentsCompany[companyTypes.GET_COMPANIES]
  const paginationsOptions = paginationsCompany[companyTypes.GET_COMPANIES]

  const dispatch = useDispatch()

  const [showModal, setShowModal] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [renderingProcess, setRenderingProcess] = useState({
    loading: true,
    error: false
  })
  const [currentFile, setCurrentFile] = useState(null)
  const [currentCompany, setCurrentCompany] = useState(null)
  const [confirm, setConfirm] = useState(false)
  const [tableOptions, setTableOptions] = useState({})
  const cropperRef = useRef()

  const prevGetCompany = usePrevious(loadingsCompany[companyTypes.GET_COMPANIES])
  const loadingGetCompany = loadingsCompany[companyTypes.GET_COMPANIES]
  const prevCompanyCreateFetching = usePrevious(loadingsCompany[companyTypes.CREATE_COMPANY])
  const prevCompanyUpdateFetching = usePrevious(loadingsCompany[companyTypes.UPDATE_COMPANY])
  const prevCompanyUpdateAvatar = usePrevious(loadingsCompany[companyTypes.UPDATE_COMPANY_AVATAR])
  const prevCompanyDeleteFetching = usePrevious(loadingsCompany[companyTypes.DELETE_COMPANY])

  const initialValues = {
    name: currentCompany?.name || '',
    address: currentCompany?.address || '',
    thumbnail: null,
    telephone: currentCompany?.telephone || '',
    fax: currentCompany?.fax || '',
    uen: currentCompany?.uen || '',
    email: currentCompany?.email || ''
  }

  //navigation list
  const actions = [
    {
      id: 1,
      title: 'Company',
      isShow: adminRoles.includes(role)
    }
  ]

  const CompanyColumns = [
    {
      Header: 'Company Logo',
      accessor: 'thumbnail',
      canSort: false,
      width: 140,
      Cell: (tableProps) => (
        <div
          className="w-16 h-12 rounded-md shadow-avatar"
          style={{
            backgroundImage: `url(${tableProps.row.original.thumbnail})`,
            display: 'block',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPositionX: 'center',
            backgroundPositionY: '10%'
          }}
        ></div>
      ),
      disableSortBy: true
    },
    {
      Header: 'Name',
      accessor: 'name',
      id: 'name',
      width: 110
    },
    {
      Header: 'Inspections',
      accessor: 'total_inspection',
      id: 'total_inspection',
      width: 120,
      Cell: (tableProps) => {
        return (
          <div
            className="inline-flex items-center font-bold py-2 px-3 rounded-lg group-hover:bg-white text-blue-button cursor-pointer bg-view-detail"
            onClick={() => navigate(`/company/${tableProps.row.original.id}`)}
          >
            <HomeOutlined style={{ fontSize: '18px', color: color.blue }} />
            <span className="ml-2">{tableProps.row.original.total_inspection || 0}</span>
          </div>
        )
      }
    },
    {
      Header: 'User',
      accessor: 'total_user',
      id: 'total_user',
      width: 90,
      maxWidth: 90,
      Cell: (tableProps) => {
        return (
          <div
            className="inline-flex items-center font-bold py-2 px-3 rounded-lg group-hover:bg-white text-blue-button cursor-pointer bg-view-detail"
            onClick={() => navigate(`/company/list-user/${tableProps.row.original.id}`)}
          >
            <UserOutlined style={{ fontSize: '18px', color: color.blue }} />
            <span className="ml-2">{tableProps.row.original.total_user || 0}</span>
          </div>
        )
      }
    },
    {
      Header: 'Resons for defect',
      accessor: 'reasons',
      disableSortBy: true,
      width: 130,
      maxWidth: 130,
      Cell: (tableProps) => {
        return (
          <div
            className="inline-flex items-center font-bold py-2 px-3 rounded-lg group-hover:bg-white text-blue-button cursor-pointer bg-view-detail"
            onClick={() => navigate(`/company/list-reason/${tableProps.row.original.id}`)}
          >
            <TbHomeQuestion style={{ fontSize: '20px', color: color.blue }} />
            {/* <span className="ml-2">{tableProps.row.original.reasons || 0}</span> */}
          </div>
        )
      }
    },
    {
      Header: 'Repair Recommended',
      accessor: 'recommendations',
      id: 'recommendations',
      disableSortBy: true,
      width: 130,
      maxWidth: 130,
      Cell: (tableProps) => {
        return (
          <div
            className="inline-flex items-center font-bold py-2 px-3 rounded-lg group-hover:bg-white text-blue-button cursor-pointer bg-view-detail"
            onClick={() => navigate(`/company/list-repair/${tableProps.row.original.id}`)}
          >
            <MdOutlineRecommend style={{ fontSize: '20px', color: color.blue }} />
          </div>
        )
      }
    },
    {
      Header: 'Date Created',
      accessor: 'created_at',
      id: 'created_at',
      minWidth: 160,
      Cell: ({ value }) => {
        const date = format(new Date(value), 'dd/MM/yyyy')
        return (
          <div className="relative">
            <div className="flex items-center">
              <FieldTimeOutlined style={{ fontSize: '18px' }} className="group:hover:text-white" />
              <span className="ml-2">{date}</span>
            </div>
          </div>
        )
      }
    },
    {
      Header: 'Status',
      accessor: 'status',
      id: 'status',
      width: 120,
      Cell: ({ value }) => {
        return (
          <>
            {value === 'Active' ? (
              <StatusButton color={color.green} bgColor={color.greenLight} title="Active" />
            ) : value === 'Inactive' ? (
              <StatusButton color={color.red} bgColor={color.redLight} title="Deleted" />
            ) : (
              ''
            )}
          </>
        )
      }
    },
    {
      Header: 'Actions',
      hideHeader: true,
      disableSortBy: true,
      role: adminRoles,
      width: 120,
      maxWidth: 120,
      Cell: (tableProps) => {
        const { id, status } = tableProps.row?.original
        return (
          <>
            {status !== 'Inactive' ? (
              <div className="flex items-center gap-2">
                <div className="relative">
                  <div
                    className="inline-flex items-center font-bold py-2 px-3 rounded-lg bg-view-detail group-hover:bg-white group-hover:text-blue-button cursor-pointer"
                    onClick={() => handleEditCompany(tableProps.row.original.id)}
                  >
                    <EditOutlined
                      style={{
                        fontSize: '20px',
                        cursor: 'pointer',
                        color: color.green
                      }}
                    />
                  </div>
                </div>
                <div
                  className="inline-flex items-center font-bold py-2 px-3 rounded-lg bg-view-detail group-hover:bg-white group-hover:text-blue-button cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation()
                    onConfirm({
                      title: <h3 className="text-center">Are you sure you want to delete this company?</h3>,
                      description: <br />,
                      onSubmit: async () => {
                        dispatch(deleteCompany(id))
                      },
                      onCancel: () => {},
                      buttons: ({ onSubmit, onCancel }) => (
                        <div className="custom-buttons">
                          <button style={{ background: 'rgba(37, 99, 235)', color: 'white' }} onClick={onSubmit}>
                            YES
                          </button>
                          <button style={{ background: 'rgba(220, 38, 38)', color: 'white' }} onClick={onCancel}>
                            NO
                          </button>
                        </div>
                      )
                    })
                  }}
                >
                  <DeleteOutlined
                    style={{
                      fontSize: '20px',
                      cursor: 'pointer',
                      color: color.red
                    }}
                    alt="delete"
                  />
                </div>
              </div>
            ) : (
              <div className="flex gap-2">
                <div className="relative">
                  <div
                    className="inline-flex items-center font-bold py-2 px-3 rounded-lg bg-view-detail group-hover:bg-white group-hover:text-blue-button cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation()
                      onConfirm({
                        title: <h3 className="text-center">Are you sure you want to restore this deleted company?</h3>,
                        description: <br />,
                        onSubmit: async () => {
                          dispatch(revertDeleteCompany({ id }))
                        },
                        onCancel: () => {},
                        buttons: ({ onSubmit, onCancel }) => (
                          <div className="custom-buttons">
                            <button style={{ background: 'rgba(37, 99, 235)', color: 'white' }} onClick={onSubmit}>
                              YES
                            </button>
                            <button style={{ background: 'rgba(220, 38, 38)', color: 'white' }} onClick={onCancel}>
                              NO
                            </button>
                          </div>
                        )
                      })
                    }}
                  >
                    <RedoOutlined
                      style={{
                        fontSize: '20px',
                        cursor: 'pointer',
                        color: color.blue
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        )
      }
    },

    {
      Header: 'Date Created',
      hideHeader: true,
      disableSortBy: true,
      width: 60,
      maxWidth: 60,
      height: 80,
      Cell: (tableProps) => {
        const { status } = tableProps.row?.original
        return (
          <>
            {status !== 'Inactive' && (
              <div
                className="border-solid border border-primary py-2 px-3 h-8 md:h-10 rounded-lg cursor-pointer flex justify-center items-center bg-blue-button"
                onClick={() => navigate(`/company/${tableProps.row.original.id}`, {})}
              >
                <img src={NextArrowIconWhite} alt="Next" />
              </div>
            )}
          </>
        )
      }
    }
  ]

  const handleSetAvatar = async (file) => {
    setCurrentFile(file)
  }

  const handleOpenCropper = useCallback(() => {
    cropperRef?.current?.openCropModal()
  }, [cropperRef])

  const onSubmitUpdate = (values, onSubmitProps) => {
    setSubmitting(true)
    const { name, address, fax, uen, email, telephone } = values
    const uploadFile = currentFile ? true : currentCompany.thumbnail ? false : true
    try {
      const dataReq = {
        name: name.trim(),
        address: address.trim(),
        file: currentFile || currentCompany.thumbnail,
        id: parseInt(currentCompany.id),
        status: currentCompany.status.trim(),
        upload: uploadFile,
        fax: fax.trim(),
        uen: uen.trim(),
        email: email.trim(),
        telephone: telephone.trim()
      }
      if (fax === '') {
        delete dataReq.fax
      }
      if (email === '') {
        delete dataReq.email
      }
      if (telephone === '') {
        delete dataReq.telephone
      }
      dispatch(updateCompany(dataReq))
    } catch (err) {
      toast.error('Error when updating building')
    }
  }

  const onSubmitAdd = (values, onSubmitProps) => {
    setSubmitting(true)
    const { name, address, fax, uen, email, telephone } = values
    try {
      const dataReq = {
        name: name.trim(),
        address: address.trim(),
        file: currentFile,
        status: 'Active',
        fax: fax.trim(),
        uen: uen.trim(),
        email: email.trim(),
        telephone: telephone.trim(),
        isUpload: currentFile ? true : false
      }
      if (fax === '') {
        delete dataReq.fax
      }
      if (email === '') {
        delete dataReq.email
      }
      if (telephone === '') {
        delete dataReq.telephone
      }
      dispatch(createCompany(dataReq))
    } catch (err) {
      toast.error('Error when create building')
    }
  }

  const validationSchema = Yup.object({
    name: Yup.string().required('Please enter company name'),
    address: Yup.string().required('Please enter company address'),
    telephone: Yup.string()
      // .required('Please enter company telephone')
      .max(12, 'Phone number must be at most 12 characters')
      .test('Invalid phone', 'Invalid phone', (value) => {
        const re = /^[0-9-]*$/
        // /^0?[1-9]\d{0,2}-?\d{2,3}-?\d{1,4}-?\d{1,4}$/
        return !value || re.test(String(value))
      }),
    fax: Yup.string()
      // .required('Please enter company fax')
      .max(12, 'Fax must be at most 12 characters')
      .test('Invalid Fax', 'Invalid Fax', (value) => {
        const re = /^[0-9-]*$/
        return !value || re.test(String(value))
      }),
    email: Yup.string()
      // .required('Please enter correct company email')
      .email('Invalid Email')
      .test('Invalid Email', 'Invalid Email', (value) => {
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return !value || re.test(String(value))
      })
  }).shape({
    thumbnail: currentCompany?.thumbnail
      ? Yup.mixed().nullable()
      : // .test(
        //   'Fichier taille',
        //   'The maximum size for the uploads is 2MB',
        //   (value) => !value || (value && value.size <= 2048 * 1024)
        // )
        Yup.mixed().nullable()
  })

  const handleEditCompany = (id) => {
    setShowModal(true)
    const indexCpn = companies.findIndex((company) => company.id === id)
    setCurrentCompany(companies[indexCpn])
  }

  const handleCloseModal = () => {
    setConfirm(false)
    setShowModal(false)
    setCurrentFile(null)
    setCurrentCompany(null)
  }

  const handleCloseModalConfirm = () => {
    setConfirm(false)
    setCurrentFile(null)
    setCurrentCompany(null)
  }

  useMemo(() => {
    setMouted(true)
  }, [])

  // get data table

  const handleNavigateParamsUrl = (params, replace = false, pathname) => {
    navigate(
      {
        pathname: pathname,
        search: createSearchParams({
          page: params.page,
          size: params.size,
          ...params
        }).toString()
      },
      { replace }
    )
  }

  useEffect(() => {
    if (!location.search) {
      handleNavigateParamsUrl({ page: 1, size: 10 }, true, '/')
    }
  }, [])

  useEffect(() => {
    if (_mounted && location.search) {
      const params = Object.fromEntries(new URLSearchParams(window.location.search))
      const options = {
        ...params,
        ...(params.page ? { page: params.page } : { page: 1 }),
        ...(params.size && params.size >= 10 ? { size: params.size } : { size: 10 })
      }
      setTableOptions(options)
    }
  }, [location.search])

  useEffect(() => {
    const get = async () => {
      if (_mounted && location.search && !isEmptyObject(tableOptions)) {
        if ([...makeRoles([1, 2])].includes(role)) {
          const tableId = document?.getElementById('table-custom')
          tableId?.scrollTo({ top: 0 })
          dispatch(getCompanies(tableOptions))
        }
      }
    }
    get()
  }, [dispatch, user, tableOptions])

  // end get data table

  useEffect(() => {
    return () => {
      setMouted(false)
    }
  }, [])

  useEffect(() => {
    if (prevGetCompany && !loadingsCompany[companyTypes.GET_COMPANIES]) {
      if (!errorsCompany[companyTypes.GET_COMPANIES]) {
        setRenderingProcess((prev) => ({ ...prev, error: false, loading: false }))
      } else {
        setRenderingProcess((prev) => ({ ...prev, error: true, loading: false }))
      }
    }
  }, [errorsCompany, loadingsCompany, prevCompanyCreateFetching])

  useEffect(() => {
    if (prevCompanyCreateFetching && !loadingsCompany[companyTypes.CREATE_COMPANY]) {
      setSubmitting(false)
      if (!errorsCompany[companyTypes.CREATE_COMPANY]) {
        handleNavigateParamsUrl({ page: 1, size: 10 }, false, '/')
        setShowModal(false)
        setCurrentFile(null)
        setCurrentCompany(null)
        toast.success('Company Created', {
          theme: 'dark',
          autoClose: 2000
        })
      } else {
        setSubmitting(false)
        toast.error(errorsCompany[companyTypes.CREATE_COMPANY], {
          theme: 'dark',
          autoClose: 2000
        })
      }
    }
  }, [errorsCompany, loadingsCompany, prevCompanyCreateFetching])

  useEffect(() => {
    if (prevCompanyUpdateFetching && !loadingsCompany[companyTypes.UPDATE_COMPANY]) {
      setSubmitting(false)
      if (!errorsCompany[companyTypes.UPDATE_COMPANY]) {
        setShowModal(false)
        setCurrentFile(null)
        setCurrentCompany(null)
        toast.success('Company Updated', {
          theme: 'dark',
          autoClose: 2000
        })
      } else {
        toast.error(errorsCompany[companyTypes.UPDATE_COMPANY], {
          theme: 'dark',
          autoClose: 2000
        })
      }
    }
  }, [errorsCompany, loadingsCompany, prevCompanyUpdateFetching])

  useEffect(() => {
    if (prevCompanyUpdateAvatar && !loadingsCompany[companyTypes.UPDATE_COMPANY_AVATAR]) {
      setSubmitting(false)
      if (!errorsCompany[companyTypes.UPDATE_COMPANY_AVATAR]) {
        const indexInspection = contentsCompany[companyTypes.GET_COMPANIES].findIndex(
          (company) => company.id === currentCompany.id
        )
        setCurrentCompany(contentsCompany[companyTypes.GET_COMPANIES][indexInspection])
        toast.success('Avatar updated', {
          theme: 'dark',
          autoClose: 2000
        })
      } else {
        toast.error(errorsCompany[companyTypes.UPDATE_COMPANY_AVATAR], {
          theme: 'dark',
          autoClose: 2000
        })
      }
    }
  }, [errorsCompany, loadingsCompany, prevCompanyUpdateAvatar])

  useEffect(() => {
    if (prevCompanyDeleteFetching && !loadingsCompany[companyTypes.DELETE_COMPANY]) {
      if (!errorsCompany[companyTypes.DELETE_COMPANY]) {
        setTableOptions({ page: 1, size: 10, ...tableOptions })
        setShowModal(false)
        setCurrentFile(null)
        setCurrentCompany(null)
        toast.success('Company Deleted', {
          theme: 'dark',
          autoClose: 2000
        })
      } else {
        toast.error(errorsCompany[companyTypes.DELETE_COMPANY], {
          theme: 'dark',
          autoClose: 2000
        })
      }
    }
  }, [errorsCompany, loadingsCompany, prevCompanyDeleteFetching])

  const modalConfirm = () => {
    return (
      <Modal wrapper="w-auto max-w-md mx-auto" onClose={handleCloseModalConfirm}>
        <div className="w-88">
          <div className="flex items-center justify-between p-3 border-b border-solid rounded-t border-slate-200">
            <div className="text-xl text-primary font-semibold ">Notification</div>
            <span className="block w-6 h-6 ">
              <CloseOutlined style={{ fontSize: '22px', cursor: 'pointer' }} onClick={() => setConfirm(false)} />
            </span>
          </div>
          {/*body*/}
          <div className="relative flex flex-auto p-6">
            <div className="w-full max-w-lg">Are you sure to delete the company?</div>
          </div>
          <div className="flex items-center justify-between p-6 border-t border-solid rounded-b border-slate-200">
            <button
              className="px-6 py-3 mb-1 mr-1 text-sm font-bold cursor-pointer text-white uppercase transition-all duration-150 ease-linear bg-red-500 rounded shadow outline-none active:bg-gray-600 hover:shadow-lg focus:outline-none"
              type="button"
              onClick={handleCloseModalConfirm}
            >
              Confirm
            </button>
          </div>
        </div>
      </Modal>
    )
  }

  const renderSkeleton = () => {
    return (
      <div style={{ width: '100%', margin: '0 auto', marginTop: '16px' }}>
        <div className="flex items-center flex-wrap gap-2 md:gap-6">
          {actions &&
            Array.from({ length: actions.length + 1 }, function (v, k) {
              return k
            }).map((i) => (
              <div key={i}>
                <SkeletonViewer borderRadius={16} className="h-8 md:h-10 w-32 md:w-44" />
              </div>
            ))}
          <div className="sm:ml-auto">
            <SkeletonViewer borderRadius={16} className="h-8 md:h-10 w-[90vw] sm:w-40 md:w-56 lg:w-72" />
          </div>
        </div>
        <div>
          <SkeletonViewer className="mt-2" height={68} width="100%" />
          <div className="block overflow-hidden mt-1" style={{ height: '66vh' }}>
            {Array.from({ length: 10 }, function (v, k) {
              return k
            }).map((i) => (
              <div key={i}>
                <SkeletonViewer className="mt-2" borderRadius={12} height={110} width="100%" />
              </div>
            ))}
          </div>
        </div>
        <div className="flex justify-center">
          <SkeletonViewer className="h-8 md:h-10 mt-4" width={300} />
        </div>
      </div>
    )
  }

  return (
    <div>
      <>
        {submitting && <LoadingSpinner />}
        <PaddingAuto>
          {renderingProcess.loading ? (
            <>{renderSkeleton()}</>
          ) : renderingProcess.error ? (
            <NotAvailable content={errorsCompany[companyTypes.GET_COMPANIES]} />
          ) : (
            <Table
              tableParams
              columns={CompanyColumns}
              data={companies}
              actions={actions}
              setShowModal={setShowModal}
              loading={loadingGetCompany}
              tableOptions={tableOptions}
              paginations={paginationsOptions}
              setTableOptions={setTableOptions}
              cellHeight={110}
              pathname={`/`}
            ></Table>
          )}
        </PaddingAuto>
      </>

      {confirm && modalConfirm()}

      {showModal && (
        <Modal wrapper="w-auto max-w-md mx-auto" onClose={handleCloseModal}>
          <div className="flex items-center justify-between p-3 border-b border-solid rounded-t border-slate-200">
            <div className="text-xl text-primary font-semibold">
              {currentCompany ? 'Edit Company' : 'Create Company'}
            </div>
          </div>
          {/*body*/}
          <div className="relative flex flex-auto p-6">
            <div className="w-full max-w-lg">
              <Formik
                initialValues={initialValues}
                onSubmit={currentCompany ? onSubmitUpdate : onSubmitAdd}
                validationSchema={validationSchema}
              >
                {({ values, setFieldValue, isSubmitting, errors }) => {
                  return (
                    <Form className="w-full max-w-lg ">
                      <div className="flex flex-wrap mb-6 -mx-3 max-h-[68vh] overflow-y-auto overflow-x-hidden">
                        <div className="w-full px-3">
                          <div className="mb-6">
                            <div className="relative mt-1 rounded-md group h-[120px] w-[160px] shadow-avatar">
                              {currentFile ? (
                                <PreviewImage file={currentFile} imgClass="w-full h-full rounded-md ml-0" />
                              ) : currentCompany ? (
                                <div
                                  className="w-full h-full rounded-md ml-0"
                                  style={{
                                    backgroundImage: `url(${currentCompany?.thumbnail})`,
                                    display: 'block',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                    backgroundPositionX: 'center',
                                    backgroundPositionY: '10%'
                                  }}
                                ></div>
                              ) : (
                                <img
                                  className="w-full h-full rounded-md ml-0 "
                                  src={process.env.REACT_APP_AWS_BLANK_IMAGE}
                                  alt="avt"
                                />
                              )}
                              <div
                                className="flex justify-center items-center absolute z-20 bottom-1 right-1 w-[32px] h-[32px] bg-gray-300 rounded-full cursor-pointer"
                                onClick={handleOpenCropper}
                              >
                                <BsCameraFill style={{ fontSize: '20px', color: 'black' }} />
                              </div>
                              <Cropper2
                                ref={cropperRef}
                                name="avatar"
                                cropShape="rect"
                                onCompleteCropper={(file) => {
                                  handleSetAvatar(file)
                                  cropperRef?.current?.closeCropModal()
                                }}
                                setLoading={setSubmitting}
                              />
                              <div className="absolute z-10 bottom-0 bg-gray-300 opacity-10 cursor-pointer w-[160px] h-[120px] rounded-md hidden group-hover:block"></div>
                            </div>
                          </div>
                        </div>
                        <div className="w-full px-3 mb-3">
                          <label
                            className="block mb-2 text-xs font-bold tracking-wide text-primary uppercase"
                            htmlFor="grid-first-name"
                          >
                            Company Name
                          </label>
                          <Field
                            type="text"
                            id="name"
                            placeholder="Company name"
                            className="block w-full px-4 py-3 leading-tight text-primary bg-gray-50 border rounded appearance-none focus:outline-none focus:bg-white"
                            name="name"
                          />
                          <ErrorMessage name="name">
                            {(errMsg) => (
                              <div className="text-red-500 mt-1 flex items-center">
                                <ExclamationCircleOutlined />
                                <span className="ml-1">{errMsg}</span>
                              </div>
                            )}
                          </ErrorMessage>
                        </div>
                        <div className="w-full px-3 mb-3">
                          <label
                            className="block mb-2 text-xs font-bold tracking-wide text-primary uppercase"
                            htmlFor="address"
                          >
                            COMPANY ADDRESS
                          </label>
                          <Field
                            type="text"
                            id="address"
                            placeholder="Company address"
                            className="block w-full px-4 py-3 leading-tight text-primary bg-gray-50 border rounded appearance-none focus:outline-none focus:bg-white"
                            name="address"
                          />
                          <ErrorMessage name="address">
                            {(errMsg) => (
                              <div className="text-red-500 mt-1 flex items-center">
                                <ExclamationCircleOutlined />
                                <span className="ml-1">{errMsg}</span>
                              </div>
                            )}
                          </ErrorMessage>
                        </div>

                        <div className="w-full px-3 mb-3">
                          <label
                            className="block mb-2 text-xs font-bold tracking-wide text-primary uppercase"
                            htmlFor="address"
                          >
                            COMPANY TELEPHONE
                          </label>
                          <Field
                            type="text"
                            id="telephone"
                            placeholder="Company telephone"
                            className="block w-full px-4 py-3 leading-tight text-primary bg-gray-50 border rounded appearance-none focus:outline-none focus:bg-white"
                            name="telephone"
                          />
                          <ErrorMessage name="telephone">
                            {(errMsg) => (
                              <div className="text-red-500 mt-1 flex items-center">
                                <ExclamationCircleOutlined />
                                <span className="ml-1">{errMsg}</span>
                              </div>
                            )}
                          </ErrorMessage>
                        </div>

                        <div className="w-full px-3 mb-3">
                          <label
                            className="block mb-2 text-xs font-bold tracking-wide text-primary uppercase"
                            htmlFor="address"
                          >
                            COMPANY FAX
                          </label>
                          <Field
                            type="text"
                            id="fax"
                            placeholder="Company fax"
                            className="block w-full px-4 py-3 leading-tight text-primary bg-gray-50 border rounded appearance-none focus:outline-none focus:bg-white"
                            name="fax"
                          />
                          <ErrorMessage name="fax">
                            {(errMsg) => (
                              <div className="text-red-500 mt-1 flex items-center">
                                <ExclamationCircleOutlined />
                                <span className="ml-1">{errMsg}</span>
                              </div>
                            )}
                          </ErrorMessage>
                        </div>

                        <div className="w-full px-3 mb-3">
                          <label
                            className="block mb-2 text-xs font-bold tracking-wide text-primary uppercase"
                            htmlFor="uen"
                          >
                            UEN
                          </label>
                          <Field
                            type="text"
                            id="uen"
                            placeholder="Company UEN"
                            className="block w-full px-4 py-3 leading-tight text-primary bg-gray-50 border rounded appearance-none focus:outline-none focus:bg-white"
                            name="uen"
                          />
                          <ErrorMessage name="uen">
                            {(errMsg) => (
                              <div className="text-red-500 mt-1 flex items-center">
                                <ExclamationCircleOutlined />
                                <span className="ml-1">{errMsg}</span>
                              </div>
                            )}
                          </ErrorMessage>
                        </div>

                        <div className="w-full px-3 mb-3">
                          <label
                            className="block mb-2 text-xs font-bold tracking-wide text-primary uppercase"
                            htmlFor="address"
                          >
                            COMPANY EMAIL
                          </label>
                          <Field
                            type="text"
                            id="email"
                            placeholder="Company email"
                            className="block w-full px-4 py-3 leading-tight text-primary bg-gray-50 border rounded appearance-none focus:outline-none focus:bg-white"
                            name="email"
                          />
                          <ErrorMessage name="email">
                            {(errMsg) => (
                              <div className="text-red-500 mt-1 flex items-center">
                                <ExclamationCircleOutlined />
                                <span className="ml-1">{errMsg}</span>
                              </div>
                            )}
                          </ErrorMessage>
                        </div>
                      </div>
                      <div className="flex items-center justify-between px-4 pt-4 border-t border-solid rounded-b border-slate-200">
                        <button
                          className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear bg-gray-500 rounded shadow outline-none active:bg-gray-600 hover:shadow-lg focus:outline-none"
                          type="button"
                          onClick={handleCloseModal}
                        >
                          Close
                        </button>

                        <div className="flex gap-4 items-center">
                          <button
                            className="flex items-center px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear bg-red-500 rounded shadow outline-none active:bg-red-600 hover:shadow-lg focus:outline-none"
                            type="submit"
                          >
                            <span className="mr-2">Submit</span> <SaveOutlined />
                          </button>
                        </div>
                      </div>
                    </Form>
                  )
                }}
              </Formik>
            </div>
          </div>
        </Modal>
      )}
    </div>
  )
}

export default CompanyManagement
