// libs
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useLocation, createSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { format } from 'date-fns'

// hocs
import useAuth from '../../../hooks/useAuth'

// actions

import { getBuildingDetails, updateBuildingAvatar } from '../../../store/thunks/buildingThunk'

import { CSVLink } from 'react-csv'
import { isEmptyObject, makeRoles } from '../../../utils/common'

// commons
import Table from '../../Common/table2'
import SmartDrawer from '../../Common/drawer/SmartDrawer'
import SkeletonViewer from '../../Common/skeleton-viewer'
import Cropper2 from '../../Common/cropper2/Cropper'

// @antd
import { EyeOutlined, EditOutlined } from '@ant-design/icons'
import PreIcon from '../../../assets/images/svg/arrow_back.svg'
import { IoCheckmarkDoneCircleSharp, IoCheckmarkDoneCircleOutline } from 'react-icons/io5'
import { BsCameraFill } from 'react-icons/bs'

// assets

// variables

import inspectionTypes from '../../../constant/store/inspectionTypes'
import StatusButton from '../../Common/status'
import { color } from '../../../constant/styled'
import elevationTypes from '../../../constant/store/elevationTypes'
import { getElevationsBuilding } from '../../../store/thunks/elevationThunk'
import NotAvailable from '../../Common/NotAvailable'
import { usePrevious } from 'react-use'
import LoadingSpinner from '../../Common/loading-spinner/LoadingSpinner'
import PopupEditElevation from './PopupEditElevation'
import Cropper from '../../Common/cropper/Cropper'
import ElevationLog from '../ElevationDetails/ElevationLog'
import PaddingAuto from '../../Layout/PaddingAuto'

const BuildingDetail = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const { id } = useParams()
  const { user } = useAuth()
  const { role } = user?.user || {}
  const [_mounted, setMouted] = useState(false)
  const [renderingProcess, setRenderingProcess] = useState({
    loading: true,
    error: false
  })
  const [tableOptions, setTableOptions] = useState({})
  const [fileName, setFileName] = useState('report')
  const [dataCSV, setDataCSV] = useState([])
  const [popupEditElevationDetail, setPopupEditElevationDetail] = useState(false)
  const [editElevationDetailId, setEditElevationDetailId] = useState(null)
  const [logDrawer, setShowLogDrawer] = useState(null)
  const cropperRef = useRef()

  const {
    loadings: loadingsInspection,
    contents: contentsInspection,
    errors: errorsInspection
  } = useSelector((state) => state.building)
  const {
    loadings: loadingsElevation,
    contents: contentsElevation,
    errors: errorsElevation,
    paginations: paginationsElevation
  } = useSelector((state) => state.elevation)

  const [exportLoading, setExportLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  // build detail variables
  const buildingDetails = contentsInspection[inspectionTypes.GET_BUILDINGS_DETAILS]
  const prevGetInspectionDetail = usePrevious(loadingsInspection[inspectionTypes.GET_BUILDINGS_DETAILS])
  const prevUpdateAvatar = usePrevious(loadingsInspection[inspectionTypes.UPDATE_BUIDING_AVATAR])
  // elevation variables
  const prevGetElevationsBuilding = usePrevious(loadingsElevation[elevationTypes.GET_ELEVATIONS_BUILDING])
  const elevationList = contentsElevation[elevationTypes.GET_ELEVATIONS_BUILDING]
  const paginationsOptions = paginationsElevation[elevationTypes.GET_ELEVATIONS_BUILDING]
  const loadingElavationList = loadingsElevation[elevationTypes.GET_ELEVATIONS_BUILDING]

  const actions = [
    {
      id: 1,
      title: 'Elevation',
      isShow:
        [...makeRoles([1, 2, 5, 9, 6, 7, 10, 12, 13])].includes(role) && buildingDetails?.building.status === 'Active'
    }
  ]

  useMemo(() => {
    setMouted(true)
  }, [])

  const editElevation = (id) => {
    setPopupEditElevationDetail(true)
    setEditElevationDetailId(id)
  }

  const closePopupEditElevation = () => {
    setPopupEditElevationDetail(false)
  }

  const handleOpenCropper = useCallback(() => {
    cropperRef?.current?.openCropModal()
  }, [cropperRef])

  const handleSetAvatar = async (file) => {
    setSubmitting(true)
    const updateBuildingReq = {
      ...buildingDetails.building,
      upload: true,
      company: buildingDetails.building.company_id,
      type: buildingDetails.building.type_id,
      file
    }
    dispatch(updateBuildingAvatar(updateBuildingReq))
  }

  const ElevationColumns = [
    {
      Header: 'Elevation Image',
      accessor: 'data_thumbnail',
      id: 'data_thumbnail',
      width: 140,
      Cell: (tableProps) => {
        const { data_thumbnail } = tableProps.row?.original
        return (
          <div
            className="w-16 h-12 rounded-md shadow-avatar"
            style={{
              backgroundImage: `url(${data_thumbnail?.thumbnailUrl})`,
              display: 'block',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPositionX: 'center',
              backgroundPositionY: '10%'
            }}
          ></div>
        )
      },
      disableSortBy: true
    },
    {
      Header: 'Elevation Name',
      accessor: 'name',
      id: 'name',
      width: 110
    },
    {
      Header: 'Drone Images',
      accessor: 'droneImages',
      id: 'drone_images',
      width: 110,
      Cell: (tableProps) => {
        const { checkedDroneImages, droneImages } = tableProps.row?.original
        return (
          <div>
            {checkedDroneImages}/{droneImages}
          </div>
        )
      }
    },
    {
      Header: 'Manual Images',
      accessor: 'manualImages',
      id: 'manual_images',
      width: 110,
      Cell: (tableProps) => {
        const { checkedManualImages, manualImages } = tableProps.row?.original
        return (
          <div>
            {checkedManualImages}/{manualImages}
          </div>
        )
      }
    },
    {
      Header: 'Visual Unsafe',
      accessor: 'unSafe',
      id: 'visual_unsafe',
      width: 110
    },
    {
      Header: 'Visual Required Repair',
      accessor: 'reportedRequireRepair',
      id: 'visual_required_repair',
      width: 110,
      Cell: (tableProps) => {
        const { totalRequireRepair, reportedRequireRepair } = tableProps.row?.original
        return (
          <div>
            {reportedRequireRepair}/{totalRequireRepair}
          </div>
        )
      }
    },
    {
      Header: 'Visual Safe',
      accessor: 'totalSafe',
      id: 'visual_safe',
      width: 110,
      Cell: (tableProps) => {
        const { reportedSafe, totalSafe } = tableProps.row?.original
        return (
          <div>
            {reportedSafe}/{totalSafe}
          </div>
        )
      }
    },
    {
      Header: 'Close Range Unsafe',
      accessor: 'crUnsafe',
      id: 'cr_unsafe',
      width: 110,
      Cell: (tableProps) => {
        const { crUnSafe } = tableProps.row?.original
        return <div>{crUnSafe}</div>
      }
    },
    {
      Header: 'Close Range Required Repair',
      accessor: 'crTotalRequireRepair',
      id: 'cr_required_repair',
      width: 110,
      Cell: (tableProps) => {
        const { crReportedRequireRepair, crTotalRequireRepair } = tableProps.row?.original
        return (
          <div>
            {crReportedRequireRepair}/{crTotalRequireRepair}
          </div>
        )
      }
    },
    {
      Header: 'Close Range Safe',
      accessor: 'crTotalSafe',
      id: 'cr_safe',
      width: 110,
      Cell: (tableProps) => {
        const { crReportedSafe, crTotalSafe } = tableProps.row?.original
        return (
          <div>
            {crReportedSafe}/{crTotalSafe}
          </div>
        )
      }
    },
    {
      Header: 'Status',
      width: 60,
      maxWidth: 60,
      disableSortBy: true,
      Cell: (tableProps) => {
        const { switch_close_range, switch_visual } = tableProps.row?.original
        return (
          <>
            {switch_close_range ? (
              <div className="inline-flex items-center font-bold py-[6px] px-3 rounded-lg bg-view-detail group-hover:bg-white group-hover:text-blue-button cursor-pointer">
                <IoCheckmarkDoneCircleSharp style={{ fontSize: '24px', color: color.green }} alt="eye" />
              </div>
            ) : switch_visual ? (
              <div className="inline-flex items-center font-bold py-[6px] px-3 rounded-lg bg-view-detail group-hover:bg-white group-hover:text-blue-button cursor-pointer">
                <IoCheckmarkDoneCircleOutline style={{ fontSize: '24px', color: color.green }} alt="eye" />
              </div>
            ) : null}
          </>
        )
      }
    },
    {
      Header: 'Edit_Elevation',
      width: 80,
      maxWidth: 80,
      hideHeader: true,
      disableSortBy: true,
      isHidden: ![...makeRoles([1, 2, 6, 7, 10, 12, 13])].includes(role),
      Cell: (tableProps) => {
        const { id } = tableProps.row?.original
        return (
          buildingDetails?.building.status === 'Active' && (
            <div
              className="inline-flex items-center font-bold py-2 px-3 rounded-lg bg-view-detail group-hover:bg-white group-hover:text-blue-button cursor-pointer"
              onClick={() => editElevation(id)}
            >
              <EditOutlined
                style={{
                  fontSize: '20px',
                  cursor: 'pointer',
                  color: color.green
                }}
                alt="edit"
                className="mr-1"
              />
            </div>
          )
        )
      }
    },

    {
      Header: 'Action',
      width: 180,
      maxWidth: 180,
      hideHeader: true,
      disableSortBy: true,
      Cell: (tableProps) => {
        const { id } = tableProps.row?.original
        return (
          <div
            className="inline-flex items-center font-bold py-2 px-3 rounded-lg bg-view-detail group-hover:bg-white group-hover:text-blue-button cursor-pointer"
            onClick={() => navigate(`/elevation/${id}/drone`)}
          >
            <EyeOutlined style={{ fontSize: '20px' }} alt="eye" className="mr-1" />
            Details
          </div>
        )
      }
    }
  ]

  useEffect(() => {
    const get = async () => {
      dispatch(getBuildingDetails(id))
    }
    get()
  }, [dispatch, user, id])

  useMemo(() => {
    setMouted(true)
  }, [])

  // get data table

  const handleNavigateParamsUrl = (params, replace = false, pathname) => {
    navigate(
      {
        pathname: pathname,
        search: createSearchParams({
          page: params.page,
          size: params.size,
          ...params
        }).toString()
      },
      { replace }
    )
  }

  useEffect(() => {
    if (!location.search) {
      handleNavigateParamsUrl({ page: 1, size: 10 }, true, `/inspections/${id}`)
    }
  }, [])

  useEffect(() => {
    if (_mounted && location.search) {
      const params = Object.fromEntries(new URLSearchParams(window.location.search))
      const options = {
        ...params,
        ...(params.page ? { page: params.page } : { page: 1 }),
        ...(params.size && params.size >= 10 ? { size: params.size } : { size: 10 })
      }
      setTableOptions(options)
    }
  }, [location.search])

  useEffect(() => {
    const get = async () => {
      if (_mounted && location.search && !isEmptyObject(tableOptions)) {
        dispatch(getElevationsBuilding({ id, options: tableOptions }))
      }
    }
    get()
  }, [dispatch, user, tableOptions])

  // end get data table

  useEffect(() => {
    return () => {
      setMouted(false)
    }
  }, [])

  useEffect(() => {
    if (prevGetInspectionDetail && !loadingsInspection[inspectionTypes.GET_BUILDINGS_DETAILS]) {
      if (!errorsInspection[inspectionTypes.GET_BUILDINGS_DETAILS]) {
        setRenderingProcess((prev) => ({ ...prev }))
      } else {
        setRenderingProcess((prev) => ({ ...prev, error: errorsInspection[inspectionTypes.GET_BUILDINGS_DETAILS] }))
      }
    }
  }, [loadingsInspection, errorsInspection, prevGetInspectionDetail])

  useEffect(() => {
    if (prevUpdateAvatar && !loadingsInspection[inspectionTypes.UPDATE_BUIDING_AVATAR]) {
      setSubmitting(false)
      if (!errorsInspection[inspectionTypes.UPDATE_BUIDING_AVATAR]) {
        toast.success('Avatar updated', {
          theme: 'dark',
          autoClose: 2000
        })
        cropperRef?.current?.closeCropModal()
      } else {
        toast.error(errorsInspection[inspectionTypes.UPDATE_BUIDING_AVATAR], {
          theme: 'dark',
          autoClose: 2000
        })
      }
    }
  }, [loadingsInspection, errorsInspection, prevUpdateAvatar])

  useEffect(() => {
    if (prevGetElevationsBuilding && !loadingsElevation[elevationTypes.GET_ELEVATIONS_BUILDING]) {
      if (!errorsElevation[elevationTypes.GET_ELEVATIONS_BUILDING]) {
        setRenderingProcess((prev) => ({ ...prev, loading: false, error: false }))
      } else {
        setRenderingProcess((prev) => ({
          ...prev,
          error: errorsElevation[elevationTypes.GET_ELEVATIONS_BUILDING],
          loading: false
        }))
      }
    }
  }, [loadingsElevation, errorsElevation, prevGetElevationsBuilding])

  useEffect(() => {
    if (buildingDetails && buildingDetails.building) {
      const d = new Date()
      const day = d.getMonth() + 1 + '/' + d.getDate() + '/' + d.getFullYear()
      const name = `${buildingDetails.building.name ? buildingDetails.building.name : ''}-${day.toString()}`
      setFileName(name)
    }
  }, [buildingDetails])

  useEffect(() => {
    return () => {
      setMouted(false)
    }
  }, [])

  const renderDrawerShowLog = () => {
    return (
      <SmartDrawer
        visible={logDrawer ? true : false}
        onClose={() => {
          setShowLogDrawer(null)
        }}
        title="Elevation log"
        width={1200}
        zIndex={1000}
        children={logDrawer && <ElevationLog />}
      />
    )
  }

  const renderSkeleton = () => {
    return (
      <>
        <div className="w-full overflow-auto bg-white border border-gray-300 rounded-xl shadow-lg bg-opacity-90 bg-clip-padding flex flex-col mb-6">
          <div className="flex flex-rowborder-b pt-5 pb-8 px-[30px]">
            <div className="flex justify-between items-stretch w-full relative">
              <div className="flex flex-col gap-4 flex-grow-[4] max-w-[40%]">
                <SkeletonViewer height={20} width={200} />
                <SkeletonViewer height={20} width={200} />
                <div className="flex items-center gap-2">
                  <SkeletonViewer width={160} height={120} className="mr-4" />
                </div>
              </div>
              <div className="flex flex-col gap-4 flex-grow-[6] max-w-[60%]">
                <SkeletonViewer height={20} width={300} />
                <SkeletonViewer height={28} width={300} />
                <SkeletonViewer height={20} width={300} />
                <SkeletonViewer height={20} width={300} />
              </div>
              <div className="flex items-end absolute top-0 right-0">
                <SkeletonViewer height={38} width={95} borderRadius={6} />
              </div>
              <div className="flex items-end gap-4 text-white absolute bottom-0 right-0">
                <SkeletonViewer height={64} width={170} borderRadius={6} />
                <div className="flex flex-col gap-4">
                  <SkeletonViewer height={64} width={170} borderRadius={6} />
                  <SkeletonViewer height={64} width={170} borderRadius={6} />
                </div>
                <div className="flex flex-col gap-4">
                  <SkeletonViewer height={64} width={170} borderRadius={6} />
                  <SkeletonViewer height={64} width={170} borderRadius={6} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ width: '100%', margin: '0 auto', marginTop: '16px' }}>
          <div className="flex items-center flex-wrap gap-2 md:gap-6">
            {actions &&
              Array.from({ length: actions.length + 1 }, function (v, k) {
                return k
              }).map((i) => (
                <div key={i}>
                  <SkeletonViewer borderRadius={16} className="h-8 md:h-10 w-32 md:w-44" />
                </div>
              ))}
            <div className="sm:ml-auto">
              <SkeletonViewer borderRadius={16} className="h-8 md:h-10 w-[90vw] sm:w-40 md:w-56 lg:w-72" />
            </div>
          </div>
          <div>
            <div className="block overflow-hidden mt-1" style={{ height: '64vh' }}>
              <SkeletonViewer className="mt-2" height={108} width="100%" />
              {Array.from({ length: 10 }, function (v, k) {
                return k
              }).map((i) => (
                <div key={i}>
                  <SkeletonViewer className="mt-2" borderRadius={12} height={96} width="100%" />
                </div>
              ))}
            </div>
          </div>
          <div className="flex justify-center">
            <SkeletonViewer className="h-8 md:h-10 mt-4" width={300} />
          </div>
        </div>
      </>
    )
  }
  return (
    <React.Fragment>
      {popupEditElevationDetail && (
        <PopupEditElevation
          closePopupEditElevation={closePopupEditElevation}
          elevations={elevationList}
          id={editElevationDetailId}
          buildingId={id}
          setPopupEditElevationDetail={setPopupEditElevationDetail}
          buildingDetails={buildingDetails}
          handleNavigateParamsUrl={handleNavigateParamsUrl}
          tableOptions={tableOptions}
          setTableOptions={setTableOptions}
        />
      )}
      {(exportLoading || submitting) && <LoadingSpinner />}
      <PaddingAuto>
        <div>
          {renderingProcess.loading ? (
            <>{renderSkeleton()}</>
          ) : renderingProcess.error ? (
            <NotAvailable content={renderingProcess.error} />
          ) : (
            <>
              <div className="w-full overflow-auto bg-white border border-gray-300 rounded-xl shadow-lg bg-opacity-90 bg-clip-padding flex flex-col mb-6">
                <div className="flex flex-row  border-b pt-5 pb-8 px-[30px]">
                  <div className="flex justify-between items-stretch w-full  relative">
                    <div className="flex flex-col gap-4 flex-grow-[4] max-w-[40%]">
                      <div className="flex items-center gap-2">
                        <div>Inspection Name:</div>
                        <div>{buildingDetails?.building.name}</div>
                      </div>
                      <div className="flex items-center gap-2">
                        <div>Address:</div>
                        <div>{buildingDetails?.building.address}</div>
                      </div>
                      <div className="relative flex items-center gap-2 group h-[120px] w-[160px] rounded-md  shadow-avatar">
                        <div
                          className="w-full h-full rounded-md ml-0"
                          style={{
                            backgroundImage: `url(${buildingDetails?.building.thumbnail})`,
                            display: 'block',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPositionX: 'center',
                            backgroundPositionY: '10%'
                          }}
                        ></div>
                        {[...makeRoles([1, 2, 3, 6, 7, 8, 10, 12, 13])].includes(role) && (
                          <div
                            className="flex justify-center items-center absolute z-20 bottom-1 right-1 w-[32px] h-[32px] bg-gray-300 rounded-full cursor-pointer"
                            onClick={handleOpenCropper}
                          >
                            <BsCameraFill style={{ fontSize: '20px', color: 'black' }} />
                          </div>
                        )}

                        <Cropper2
                          ref={cropperRef}
                          name="avatar"
                          cropShape="rect"
                          onCompleteCropper={handleSetAvatar}
                          setLoading={setSubmitting}
                        />
                        <div className="absolute z-10 bottom-0 bg-gray-300 opacity-10 w-[160px] h-[120px] rounded-md  hidden group-hover:block"></div>
                      </div>
                    </div>
                    <div className="flex flex-col gap-4 flex-grow-[6] max-w-[60%]">
                      <div className="flex items-center gap-2">
                        <div>Inspection Type:</div>
                        <div>{buildingDetails?.building.type_name}</div>
                      </div>
                      <div className="flex items-center gap-2">
                        <div>Status:</div>
                        <div>
                          {buildingDetails?.building.status === 'Active' ? (
                            <StatusButton color={color.green} bgColor={color.greenLight} title="Active" />
                          ) : buildingDetails?.building.status === 'Completed' ? (
                            <StatusButton color={color.blue} bgColor={color.blueLight} title="Completed" />
                          ) : buildingDetails?.building.status === 'Deleted' ? (
                            <StatusButton color={color.red} bgColor={color.redLight} title="Deleted" />
                          ) : buildingDetails?.building.status === 'Archived' ? (
                            <StatusButton color={color.yellow} bgColor={color.yellowLight} title="Archived" />
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                      <div className="flex items-center gap-2">
                        <div>Date Created:</div>
                        <div>
                          {buildingDetails && format(new Date(buildingDetails?.building.created_at), 'dd/MM/yyyy')}
                        </div>
                      </div>
                      <div className="flex items-center gap-2">
                        <div>Date Modified :</div>
                        <div>
                          {buildingDetails && format(new Date(buildingDetails?.building.updated_at), 'dd/MM/yyyy')}
                        </div>
                      </div>
                    </div>
                    <div className="flex items-end gap-4 text-white absolute top-0 right-0">
                      <div
                        className="px-5 py-2 border-solid  border-primary border rounded-xl flex items-center cursor-pointer"
                        onClick={() => navigate(`/inspections`)}
                      >
                        <img src={PreIcon} width="14" alt="" />
                        <span className="ml-2 text-sm font-bold text-opacity">Back</span>
                      </div>
                    </div>
                    <div className="flex items-end gap-4 text-white absolute bottom-0 right-0">
                      {/* {[...makeRoles([1, 2, 3, 6, 7, 8, 10, 12])].includes(role) && (
                        <div
                          className="flex justify-center items-center rounded-md px-4 cursor-pointer w-[170px] h-16 text-center bg-blue-button"
                          onClick={async (e) => {
                            await handleExportDoc(e)
                            handleExportCSV(e)
                          }}
                        >
                          <span className="ml-2">Download Report</span>
                        </div>
                      )} */}
                      <div
                        className="flex justify-center items-center rounded-md px-4 cursor-pointer w-[170px] h-16 text-center bg-blue-button"
                        onClick={() => navigate(`/inspections/inspection-report/${id}`)}
                      >
                        PFI Report
                      </div>
                      <div className="flex flex-col gap-4">
                        <div
                          className="flex justify-center items-center rounded-md px-4 cursor-pointer w-[170px] h-16 text-center bg-blue-button"
                          onClick={() => navigate(`/inspections/${id}/close-range-defect-listing`)}
                        >
                          Close Range Defects Listing
                        </div>
                        <div
                          className="flex justify-center items-center rounded-md px-4 cursor-pointer w-[170px] h-16 text-center bg-blue-button"
                          onClick={() => navigate(`/inspections/${id}/close-range-defect-summary`)}
                        >
                          Close Range Defects Summary
                        </div>
                      </div>
                      <div className="flex flex-col gap-4">
                        <div
                          className="flex justify-center items-center rounded-md px-4 cursor-pointer w-[170px] h-16 text-center bg-blue-button"
                          onClick={() => navigate(`/inspections/${id}/defect-listing`)}
                        >
                          Visual Defects Listing
                        </div>
                        <div
                          className="flex justify-center items-center rounded-md px-4 cursor-pointer w-[170px] h-16 text-center bg-blue-button"
                          onClick={() => navigate(`/inspections/defect-sumary/${id}`)}
                        >
                          Visual Defects Summary
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Table
                tableParams
                columns={ElevationColumns}
                data={elevationList}
                actions={actions}
                setShowLogDrawer={setShowLogDrawer}
                setShowModal={() => editElevation(null)}
                loading={loadingElavationList}
                tableOptions={tableOptions}
                paginations={paginationsOptions}
                setTableOptions={setTableOptions}
                cellHeight={96}
                pathname={`/inspections/${id}`}
              />

              {/* {renderDrawerShowLog()} */}
            </>
          )}
        </div>
      </PaddingAuto>
      )
      <CSVLink
        data={dataCSV}
        filename={`${fileName}.csv`}
        headers={[
          { label: 'S/No', key: 'defect' },
          { label: 'Report Photo #', key: 'no' },
          { label: 'Elevation', key: 'elevation' },
          { label: 'Row/Storey', key: 'row' },
          { label: 'Column/Gridline', key: 'column' },
          { label: 'Element', key: 'element' },
          { label: 'Defect', key: 'defect1' },
          { label: 'Severity', key: 'severity' },
          { label: 'Remarks', key: 'remarks' },
          { label: 'Possible causes', key: 'possiblecauses' },
          { label: 'Recommended Repair', key: 'recommendedrepair' }
        ]}
        id="exportCSV"
      ></CSVLink>
      <canvas id="canvas" style={{ display: 'none' }}></canvas>
    </React.Fragment>
  )
}

export default BuildingDetail
