import React, { useRef } from 'react'
import Textarea from '../Report/Textarea'

function InspectionInformation({ report, building, cp, inspectors, editMode, setValue, handleBreakLine }) {
  const refInformation1 = useRef()
  const refInformation2 = useRef()
  const refInformation3 = useRef()
  const refInformation4 = useRef()
  const refInformation5 = useRef()
  const refInformation6 = useRef()

  return (
    <>
      <ol className="toc-list" style={{ lineHeight: '30px' }}>
        <li id="INSPECTION-INFORMATION">
          <div className="font-bold">
            <h1 className="font-bold title">2.0 INSPECTION INFORMATION</h1>
          </div>
        </li>
        {/* <ol> */}

        <li id="Inspection-Location">
          <div>
            <h2 className="font-bold pt-5 title">2.1 Inspection Location</h2>
          </div>
        </li>
        <li id="Inspection-Location-container-div">
          <div className="py-2">
            <p>The visual façade inspection was carried out at:</p>
            <div> {handleBreakLine(building?.address)}</div>
          </div>
          <div className="py-2">
            <Textarea
              refTextArea={refInformation2}
              id="refInformation2"
              values={report?.location}
              name="location"
              setValue={setValue}
              editMode={editMode}
            />
            <div id="refInformation2-div-container" className={editMode ? 'hidden' : ''}>
              <div id={`refInformation2-div`}>{handleBreakLine(report?.location)}</div>
            </div>
          </div>
        </li>

        <li id="Inspection-Dates">
          <div>
            <h2 className="font-bold pt-5 title">2.2 Inspection Dates</h2>
          </div>
        </li>
        <li id="Inspection-Dates-container-div">
          <div className="py-2">
            <p>The façade inspection was carried out using UAS on the following dates:</p>
            <div>{handleBreakLine(report?.uas_flight_datetime)}</div>
          </div>
          <div className="py-2">
            <p>The close-up visual façade inspection was carried out on the following dates:</p>
            <div>{handleBreakLine(report?.inspection_datetime)}</div>
          </div>
          <div className="py-2">
            <Textarea
              refTextArea={refInformation1}
              id="refInformation1"
              values={report?.dates}
              name="dates"
              setValue={setValue}
              editMode={editMode}
            />
            <div id="refInformation1-div-container" className={editMode ? 'hidden' : ''}>
              <div id={`refInformation1-div`}>{handleBreakLine(report?.dates)}</div>
            </div>
          </div>
        </li>

        <li id="Inspectors">
          <div>
            <h2 className="font-bold pt-5 title">2.3 Inspectors</h2>
          </div>
        </li>
        <li id="Inspectors-container-div">
          <div className="py-2">
            <div>
              <p>The façade inspection was carried out by the following registered facade inspectors:</p>
              <p>Competent Person:</p>
              {cp?.username || cp?.regno ? (
                <p>
                  - {cp?.username} {cp?.regno ? '(' + cp?.regno + ')' : ""}
                </p>
              ) : (
                <></>
              )}
            </div>
            <p>Facade inspectors:</p>
            {inspectors?.map((inspector, index) => (
              <div key={inspector.id}>
                <p>
                  - {inspector?.username} {inspector?.regno ? '(' + inspector?.regno + ')' : ''}
                </p>
              </div>
            ))}
          </div>
          <p className="py-2">Additional personnel involved are</p>
          <div className="py-2">
            <Textarea
              refTextArea={refInformation3}
              id="refInformation3"
              values={report?.inspectors_comment}
              name="inspectors_comment"
              setValue={setValue}
              editMode={editMode}
            />
            <div id="refInformation3-div-container" className={editMode ? 'hidden' : ''}>
              <div id={`refInformation3-div`}>{handleBreakLine(report?.inspectors_comment)}</div>
            </div>
          </div>
        </li>

        <li id="Witnesses">
          <div>
            <h2 className="font-bold pt-5 title">2.4 Witnesses</h2>
          </div>
        </li>
        <li id="Witnesses-container-div">
          <div className="pt-2">
            <p>The façade inspection was witnessed by:</p>
          </div>
          <div className="pb-2">
            <Textarea
              refTextArea={refInformation4}
              id="refInformation4"
              values={report?.witnesses}
              name="witnesses"
              setValue={setValue}
              editMode={editMode}
            />
            <div id="refInformation4-div-container" className={editMode ? 'hidden' : ''}>
              <div id={`refInformation4-div`}>{handleBreakLine(report?.witnesses)}</div>
            </div>
          </div>
        </li>

        <li id="Report-Preparation">
          <div>
            <h2 className="font-bold pt-5 title">2.5 Report Preparation</h2>
          </div>
        </li>
        <li id="Report-Preparation-container-div">
          <p className="pt-2">The façade inspection report was prepared by:</p>
          <div className="pb-2">
            <Textarea
              refTextArea={refInformation5}
              id="refInformation5"
              values={report?.preparation}
              name="preparation"
              setValue={setValue}
              editMode={editMode}
            />
            <div id="refInformation5-div-container" className={editMode ? 'hidden' : ''}>
              <div id={`refInformation5-div`}>{handleBreakLine(report?.preparation)}</div>
            </div>
          </div>
        </li>

        <li id="Reference-Documents">
          <div>
            <h2 className="font-bold pt-5 title">2.6 Reference Documents</h2>
          </div>
        </li>
        <li id="Reference-Documents-container-div">
          <div className="pb-2">
            <Textarea
              refTextArea={refInformation6}
              id="refInformation6"
              values={report?.reference}
              name="reference"
              setValue={setValue}
              editMode={editMode}
            />
            <div id="refInformation6-div-container" className={editMode ? 'hidden' : ''}>
              <div id={`refInformation6-div`}>{handleBreakLine(report?.reference)}</div>
            </div>
          </div>
        </li>
        {/* </ol> */}
        {/* </li> */}
      </ol>
      {/* </ol> */}
    </>
  )
}

export default InspectionInformation
