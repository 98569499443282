import { createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import building from '../../apis/buildingApis'
import { uploadElevationImage } from '../../apis/fileApis'
import inspectionTypes from '../../constant/store/inspectionTypes'
import imageCompression from 'browser-image-compression'
import { nanoid } from 'nanoid'
// thunk building

export const getBuildingDetails = createAsyncThunk(
  inspectionTypes.GET_BUILDINGS_DETAILS,
  async (data, { rejectWithValue, dispatch, getState }) => {
    try {
      const result = await building.buildingDetails(data)
      if (result.status === 200) {
        return result.data
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getCompanyBuildings = createAsyncThunk(
  inspectionTypes.GET_COMPANY_BUILDINGS,
  async (data, { rejectWithValue }) => {
    try {
      const result = await building.getCompanyBuildings(data.id, data.options)
      if (result.status === 200) {
        return result.data
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getBuildingsActive = createAsyncThunk(
  inspectionTypes.GET_BUILDINGS_ACTIVE,
  async (data, { rejectWithValue }) => {
    try {
      const result = await building.getBuildingsActive(data)
      if (result.status === 200) {
        return result.data
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getRoleBuilding = createAsyncThunk(
  inspectionTypes.GET_ROLE_BUILDING,
  async (data, { rejectWithValue }) => {
    try {
      const result = await building.getRoleBuilding()
      if (result.status === 200) {
        return result.data
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getBuildings = createAsyncThunk(inspectionTypes.GET_BUILDINGS, async (data, { rejectWithValue }) => {
  try {
    const result = await building.getBuildings(data)
    if (result.status === 200) {
      return result.data
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const changeStatusBuilding = createAsyncThunk(
  inspectionTypes.CHANGE_STATUS_BUILDING,
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const result = await building.changeStatusBuilding(data)
      if (result.status === 200) {
        toast.success(result.data, {
          theme: 'dark',
          autoClose: 2000
        })
      }
      const newResult = { message: result.data, id: data.id, status: data.status }
      return newResult
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const revertDeleteBuilding = createAsyncThunk(
  inspectionTypes.REVERT_DELETE_BUILDING,
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const result = await building.revertDeleteBuilding(data.id)
      if (result.status === 200) {
        return { id: data.id }
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const createBuilding = createAsyncThunk(
  inspectionTypes.CREATE_BUIDING,
  async (data, { rejectWithValue, dispatch }) => {
    try {
      let resultUpload = null
      const options = {
        maxSizeMB: 1,
        initialQuality: 0.6,
        maxWidthOrHeight: 1920
      }
      if (data.isUpload) {
        // resultUpload = await uploadFile(data.file);
        const dataThumbnail = {
          filename: nanoid() + data.file.name,
          contentType: data.file.type,
          key: 'inspection'
        }
        const { signedUrl } = await uploadElevationImage(dataThumbnail)
        const compressedFile = await imageCompression(data.file, options)
        resultUpload = signedUrl
        await fetch(signedUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': data.file.type
          },
          body: compressedFile
        })
      }
      const dataReq = {
        name: data.name,
        type_id: data.type_id,
        address: data.address,
        thumbnail: resultUpload ? resultUpload.split('?')[0] : process.env.REACT_APP_AWS_BLANK_IMAGE,
        company_id: data.company
      }
      const result = await building.createBuilding(dataReq)
      if (result.status === 200 || result.status === 201) {
        return result.data
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const updateBuilding = createAsyncThunk(
  inspectionTypes.UPDATE_BUIDING,
  async (data, { rejectWithValue, dispatch }) => {
    try {
      var resultUpload = null
      const options = {
        maxSizeMB: 1,
        initialQuality: 0.6,
        maxWidthOrHeight: 1920
      }
      if (data.upload === true) {
        const dataThumbnail = {
          filename: nanoid() + data.file.name,
          contentType: data.file.type,
          key: 'inspection'
        }
        const { signedUrl } = await uploadElevationImage(dataThumbnail)
        resultUpload = signedUrl
        const compressedFile = await imageCompression(data.file, options)
        await fetch(signedUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': data.file.type
          },
          body: compressedFile
        })
      }
      const dataReq = {
        name: data.name,
        address: data.address,
        thumbnail: resultUpload ? resultUpload.split('?')[0] : data.file,
        company_id: data.company,
        id: data.id,
        type: data.type,
        status: data.status,
        cp_id: data.cp_id
      }
      const result = await building.updateBuilding(dataReq)
      if (result.status === 200 || result.status === 201) {
        return result.data
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
export const updateBuildingAvatar = createAsyncThunk(
  inspectionTypes.UPDATE_BUIDING_AVATAR,
  async (data, { rejectWithValue, dispatch }) => {
    try {
      var resultUpload = null
      const options = {
        maxSizeMB: 1,
        initialQuality: 0.6,
        maxWidthOrHeight: 1920
      }
      if (data.upload === true) {
        const dataThumbnail = {
          filename: nanoid() + data.file.name,
          contentType: data.file.type,
          key: 'inspection'
        }
        const { signedUrl } = await uploadElevationImage(dataThumbnail)
        resultUpload = signedUrl
        const compressedFile = await imageCompression(data.file, options)
        await fetch(signedUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': data.file.type
          },
          body: compressedFile
        })
      }
      const dataReq = {
        name: data.name,
        address: data.address,
        thumbnail: resultUpload ? resultUpload.split('?')[0] : data.file,
        company_id: data.company,
        id: data.id,
        type: data.type,
        status: data.status,
        cp_id: data.cp_id
      }
      const result = await building.updateBuilding(dataReq)
      if (result.status === 200 || result.status === 201) {
        return { building: result.data, clasify: data?.clasify }
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const deleteBuilding = createAsyncThunk(
  inspectionTypes.DELETE_BUIDING,
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const result = await building.deleteBuilding(data)
      if (result.status === 200 || result.status === 201) {
        return {
          id: data,
          building: result.data
        }
      }
      return result
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
