import React from 'react'
import PropTypes from 'prop-types'
function PaddingAuto({ children }) {
  return <div className="max-w-full md:max-w-screen-2xl mx-auto px-4 md:px-6 py-1 md:py-3">{children}</div>
}

PaddingAuto.propTypes = {
  children: PropTypes.element
}

export default PaddingAuto
