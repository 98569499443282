import { createSlice } from '@reduxjs/toolkit'
import {
  getCompanyReason,
  updateCompanyReason,
  createCompanyReason,
  deleteCompanyReason,
  getAllCompanyReason
} from '../thunks/reasonThunk'
import reasonTypes from '../../constant/store/reasonTypes'

const elevationSlice = createSlice({
  name: 'reason',
  initialState: {
    loadings: {},
    errors: {},
    contents: {},
    paginations: {}
  },
  extraReducers: {
    // ACTION TYPE: Get all reason
    [getCompanyReason.pending]: (state) => {
      state.loadings[reasonTypes.GET_COMPANY_REASON] = true
    },
    [getCompanyReason.fulfilled]: (state, { payload }) => {
      state.loadings[reasonTypes.GET_COMPANY_REASON] = false
      state.contents[reasonTypes.GET_COMPANY_REASON] = payload.reasons
      state.paginations[reasonTypes.GET_COMPANY_REASON] = payload.pagination
      state.errors[reasonTypes.GET_COMPANY_REASON] = ''
    },
    [getCompanyReason.rejected]: (state, { payload }) => {
      state.loadings[reasonTypes.GET_COMPANY_REASON] = false
      state.errors[reasonTypes.GET_COMPANY_REASON] = payload.message
    },

    // ACTION TYPE: create reason
    [createCompanyReason.pending]: (state) => {
      state.loadings[reasonTypes.CREATE_COMPANY_REASON] = true
    },
    [createCompanyReason.fulfilled]: (state, { payload }) => {
      state.loadings[reasonTypes.CREATE_COMPANY_REASON] = false
      state.contents[reasonTypes.GET_COMPANY_REASON] = [payload, ...state.contents[reasonTypes.GET_COMPANY_REASON]]
      state.errors[reasonTypes.CREATE_COMPANY_REASON] = ''
    },
    [createCompanyReason.rejected]: (state, { payload }) => {
      state.loadings[reasonTypes.CREATE_COMPANY_REASON] = false
      state.errors[reasonTypes.CREATE_COMPANY_REASON] = payload.message
    },
    // ACTION TYPE: update reason
    [updateCompanyReason.pending]: (state) => {
      state.loadings[reasonTypes.UPDATE_COMPANY_REASON] = true
    },
    [updateCompanyReason.fulfilled]: (state, { payload }) => {
      state.loadings[reasonTypes.UPDATE_COMPANY_REASON] = false
      const index = state.contents[reasonTypes.GET_COMPANY_REASON].findIndex((reason) => reason.id === payload.id)
      state.contents[reasonTypes.GET_COMPANY_REASON].splice(index, 1, payload)
      state.errors[reasonTypes.UPDATE_COMPANY_REASON] = ''
    },
    [updateCompanyReason.rejected]: (state, { payload }) => {
      state.loadings[reasonTypes.UPDATE_COMPANY_REASON] = false
      state.errors[reasonTypes.UPDATE_COMPANY_REASON] = payload.message
    },
    // ACTION TYPE: delete reason
    [deleteCompanyReason.pending]: (state) => {
      state.loadings[reasonTypes.DELETE_COMPANY_REASON] = true
    },
    [deleteCompanyReason.fulfilled]: (state, { payload }) => {
      state.loadings[reasonTypes.DELETE_COMPANY_REASON] = false
      const index = state.contents[reasonTypes.GET_COMPANY_REASON].findIndex((reason) => reason.id === payload.id)
      state.contents[reasonTypes.GET_COMPANY_REASON].splice(index, 1)
      state.errors[reasonTypes.DELETE_COMPANY_REASON] = ''
    },
    [deleteCompanyReason.rejected]: (state, { payload }) => {
      state.loadings[reasonTypes.DELETE_COMPANY_REASON] = false
      state.errors[reasonTypes.DELETE_COMPANY_REASON] = payload.message
    },
    // ACTION TYPE: get all company reasons
    [getAllCompanyReason.pending]: (state) => {
      state.loadings[reasonTypes.GET_ALL_COMPANY_REASON] = true
    },
    [getAllCompanyReason.fulfilled]: (state, { payload }) => {
      state.loadings[reasonTypes.GET_ALL_COMPANY_REASON] = false
      state.contents[reasonTypes.GET_ALL_COMPANY_REASON] = payload.reasons
      state.errors[reasonTypes.GET_ALL_COMPANY_REASON] = ''
    },
    [getAllCompanyReason.rejected]: (state, { payload }) => {
      state.loadings[reasonTypes.GET_ALL_COMPANY_REASON] = false
      state.errors[reasonTypes.GET_ALL_COMPANY_REASON] = payload.message
    }
  }
})
export default elevationSlice.reducer
