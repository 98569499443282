import React from 'react'
import PropTypes from 'prop-types'
import './styled.css'
function CommonButton({ icon, textColor, bgColor, placement, height, iconPosion, onClick, width, disabled }) {
  return (
    <div className="relative">
      <div
        className={`custom-button flex justify-center items-center rounded-xl px-3 select-none text-primary ${
          iconPosion === 'right' ? 'flex-row-reverse' : ''(icon && placement) ? ' gap-2' : ''
        } ${disabled ? ' cursor-default' : 'cursor-pointer'}`}
        style={{ background: bgColor, color: textColor, height, width }}
        onClick={onClick}
      >
        <div>{icon}</div>
        <div>{placement}</div>
      </div>
    </div>
  )
}

CommonButton.propTypes = {
  icon: PropTypes.element,
  height: PropTypes.number.isRequired,
  width: PropTypes.number,
  placement: PropTypes.string,
  iconPosion: PropTypes.oneOf(['left', 'right']),
  textColor: PropTypes.string,
  bgColor: PropTypes.string,
  onClick: PropTypes.func.isRequired
}

export default CommonButton
