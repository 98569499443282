import React from 'react'
import { typeFile, typeFileSort } from '../../../constant/file'
import ImageUploading from 'react-images-uploading'
import { onConfirm } from 'react-confirm-pro'
import CommonButton from '../button/CommonButton'
import { color } from '../../../constant/styled'

const CustomReplaceImage = (props) => {
  const { currentImage, onChangeUpload, type, showWarning, status, visualStatus, ...rest } = props

  const onImageUploadingError = (errors) => {
    if (errors?.acceptType) {
      onConfirm({
        title: <h3 className="text-center">{`The images must be a file of type: ${typeFile.join()}`}</h3>,
        description: <br />,
        style: {
          modal: { zIndex: 2000 }, // Set your desired z-index value
          overlay: { zIndex: 1999 }
        },
        onCancel: () => {},
        buttons: ({ onCancel }) => (
          <div className="custom-buttons">
            <button
              style={{
                background: 'rgba(37, 99, 235)',
                color: 'white'
              }}
              onClick={onCancel}
            >
              Ok
            </button>
          </div>
        )
      })
    }
  }
  return (
    <ImageUploading
      value={currentImage}
      onChange={onChangeUpload}
      maxNumber={1}
      onError={onImageUploadingError}
      acceptType={typeFileSort}
      dataURLKey="data_url"
      {...rest}
    >
      {({ onImageUpdate }) => (
        <>
          <div className="upload-container  relative w-full mb-6 ">
            {props.children}
            {/* <div className="overlay cursor-pointer">
              <FileImageOutlined className="mr-1" /> Replace image
            </div> */}
          </div>
          {status !== 'Completed' && !visualStatus && (
            <CommonButton
              textColor={color.white}
              bgColor={color.green}
              iconPosion="right"
              height={40}
              placement={`Upload ${type}`}
              width={140}
              onClick={() => {
                if (showWarning) {
                  onConfirm({
                    title: (
                      <h3 className="text-center">
                        This defect has annotation <br /> Are you sure you want to replace this image?
                      </h3>
                    ),
                    description: <br />,
                    onSubmit: async () => {
                      onImageUpdate(0)
                    },
                    onCancel: () => {},
                    buttons: ({ onSubmit, onCancel }) => (
                      <div className="custom-buttons">
                        <button
                          style={{
                            background: 'rgba(37, 99, 235)',
                            color: 'white'
                          }}
                          onClick={onSubmit}
                        >
                          YES
                        </button>
                        <button
                          style={{
                            background: 'rgba(220, 38, 38)',
                            color: 'white'
                          }}
                          onClick={onCancel}
                        >
                          NO
                        </button>
                      </div>
                    )
                  })
                } else {
                  onImageUpdate(0)
                }
              }}
            />
          )}
        </>
      )}
    </ImageUploading>
  )
}

export default CustomReplaceImage
