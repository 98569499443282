const userTypes = {
  CREATE_USER: 'CREATE_USER',
  CLEAR_ERRORS: 'CLEAR_ERRORS',
  GET_USER: 'GET_USER',
  UPDATE_USER: 'UPDATE_USER',
  GET_ROLE_ADMIN: 'GET_ROLE_ADMIN',
  GET_ROLE_USER: 'GET_ROLE_USER',
  GET_USERS_COMPANY: 'GET_USERS_COMPANY'
}

export default userTypes
