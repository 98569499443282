import React from 'react'
import Layout from '../Layout/HomeLayout'
import { Outlet } from 'react-router-dom'

const HomeManagement = () => {
  return (
    <div className="min-h-screen bg-white">
      <Layout>
        <Outlet />
      </Layout>
    </div>
  )
}

export default HomeManagement
