import React, { useState } from 'react'
import AuthLayout from '../Layout/AuthLayout'

function Register() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [inputError, setInputError] = useState({
    email: false,
    password: false
  })
  const handleSubmit = (e) => {
    e.preventDefault()
  }
  return (
    <AuthLayout>
      <form onSubmit={handleSubmit} className="px-10 pt-4 pb-4 mb-4">
        <p className="my-5 text-3xl font-bold">Sign up new account</p>
        <div className="mb-4">
          <label className="block mb-2 text-sm font-bold text-primary" htmlFor="username">
            User name
          </label>
          <input
            className="w-full px-3 py-2 leading-tight text-primary border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
            id="username"
            type="text"
            placeholder="User name"
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
          {inputError.email && <p className="text-xs italic text-red-500">Please input a valid username</p>}
        </div>
        <div className="mb-6">
          <label className="block mb-2 text-sm font-bold text-primary" htmlFor="password">
            Password
          </label>
          <input
            className="w-full px-3 py-2 mb-3 leading-tight text-primary border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
            id="password"
            type="password"
            placeholder="**************"
            value={password}
            onChange={(e) => setPassword(e.currentTarget.value)}
          />
          {inputError.email && <p className="text-xs italic text-red-500">Please input a valid password</p>}
        </div>
        <div className="flex items-center justify-between">
          <button
            className="px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Sign In
          </button>
          <a className="inline-block text-sm font-bold text-blue-500 align-baseline hover:text-blue-800" href="/mod">
            Forgot Password?
          </a>
        </div>
      </form>
      <p className="text-xs text-center text-gray-500">&copy;2023 Axpara. All rights reserved.</p>
    </AuthLayout>
  )
}

export default Register
