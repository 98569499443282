import { Routes, Route } from 'react-router-dom'

// hocs
import useAuth from './hooks/useAuth'

// utilities
import { makeRoles } from './utils/common'

/* auth page */
import ForgetPassword from './components/Auth/ForgetPassword'
import Login from './components/Auth/Login'
import ResetPassword from './components/Auth/ResetPassword'
import ProtectedAuth from './components/Auth/ProtectedAuth'
import Unauthorized from './components/Auth/Unauthorized'
import Register from './components/Auth/Register'

/* home page */
import UserManagement from './components/HomeManagement/Management/User'
import LogManagement from './components/HomeManagement/Management/logs'
import CompanyManagement from './components/HomeManagement/Management/Company'
import BuildingManagement from './components/HomeManagement/Management/Building'
import ListBuilding from './components/HomeManagement/ListBuilding'
import ListUser from './components/HomeManagement/ListUser'
import HomeManagement from './components/HomeManagement'
import ElevationDetails from './components/HomeManagement/ElevationDetails'
import DroneUploadImage from './components/HomeManagement/ElevationCreate/DroneUploadImage'
import ThermalUploadImage from './components/HomeManagement/ElevationDetails/ThermalUploadImage'
import ProfileMember from './components/HomeManagement/ProfileMember'
import BuildingDetail from './components/HomeManagement/Management/BuildingDetail'
import BuildingSummaryReport from './components/HomeManagement/Management/BuildingSummaryReport'
import DefectListing from './components/HomeManagement/Management/DefectListing'
import BuildingCloseRangeDefectListing from './components/HomeManagement/Management/BuildingCloseRangeDefectListing'
import BuildingCloseRangeDefectSummary from './components/HomeManagement/Management/BuildingCloseRangeDefectSummary'
import ReasonsForDefect from './components/HomeManagement/Management/settings/ReasonsForDefect'
import RepairRecommend from './components/HomeManagement/Management/settings/RepairRecommend'
import DefectSummary from './components/HomeManagement/Management/DefectSummary'
import InspectionLog from './components/HomeManagement/Management/logs/InspectionLog'
import UserLog from './components/HomeManagement/Management/logs/UserLog'
import CompanyLog from './components/HomeManagement/Management/logs/CompanyLog'
import ElevationLog from './components/HomeManagement/Management/logs/ElevationLog'
import ImagesLog from './components/HomeManagement/Management/logs/ImagesLog'
import TrackLog from './components/HomeManagement/Management/logs/TrackLog'
/* 404 */
import Page404 from './components/StaticPages/Page404'

// style
import 'react-toastify/dist/ReactToastify.css'

// variables
import { ClientProject, claritasAdmin } from './constant/auth'

function App() {
  const { user } = useAuth()
  const { role } = user?.user || {}
  return (
    <Routes>
      {/* public routes */}

      <Route element={<ProtectedAuth routeProtected={false} />}>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forget_password" element={<ForgetPassword />} />
        <Route path="reset_password" element={<ResetPassword />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
      </Route>

      {/* we want to protect these routes */}
      <Route element={<ProtectedAuth allowedRoles={[...makeRoles([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13])]} />}>
        <Route path="/" element={<HomeManagement />}>
          <Route path="/profile" element={<ProfileMember />} />
          <Route
            index
            element={makeRoles([5, 6, 7, 8, 9, 10, 11, 12]).includes(role) ? <ListBuilding /> : <CompanyManagement />}
          />
          <Route element={<ProtectedAuth allowedRoles={[...claritasAdmin]} />}>
            <Route path="company/:id" element={<ListBuilding />} />
            <Route path="company/list-user/:companyId" element={<ListUser />} />
            <Route path="company/list-repair/:companyId" element={<RepairRecommend />} />
            <Route path="company/list-reason/:companyId" element={<ReasonsForDefect />} />
          </Route>
          <Route element={<ProtectedAuth allowedRoles={[...claritasAdmin, ...ClientProject]} />}>
            <Route path="users/" element={<UserManagement />}>
              <Route path="claritas-user" element="" />
              <Route path="normal-user" element="" />
            </Route>
            {/* <Route path="users/:id" element={<UserManagement />} /> */}
          </Route>
          <Route element={<ProtectedAuth allowedRoles={[...claritasAdmin, ...ClientProject]} />}>
            <Route path="logs/" element={<LogManagement />}>
              <Route path="inspection" element={<InspectionLog />} />
              <Route path="user" element={<UserLog />} />
              <Route path="company" element={<CompanyLog />} />
              <Route path="elevation" element={<ElevationLog />} />
              <Route path="image" element={<ImagesLog />} />
              <Route path="track-user" element={<TrackLog />} />
            </Route>
          </Route>
          <Route path="/settings" element={<ProtectedAuth allowedRoles={[...makeRoles([6, 7, 10])]} />}>
            <Route path="reasons-for-defect" element={<ReasonsForDefect />} />
            <Route path="repair-recommended" element={<RepairRecommend />} />
          </Route>
          <Route path="/inspections" element={<BuildingManagement />} />
          <Route path="/inspections/:id" element={<BuildingDetail />} />
          <Route path="/inspections/inspection-report/:id" element={<BuildingSummaryReport />} />
          <Route path="/inspections/defect-sumary/:id" element={<DefectSummary />} />
          <Route path="/inspections/:id/defect-listing" element={<DefectListing />} />
          <Route path="/inspections/:id/close-range-defect-listing" element={<BuildingCloseRangeDefectListing />} />
          <Route path="/inspections/:id/close-range-defect-summary" element={<BuildingCloseRangeDefectSummary />} />
          <Route element={<ProtectedAuth allowedRoles={[...makeRoles([1, 2, 5, 6, 7, 9, 10, 12, 13])]} />}>
            <Route path="elevation/create/drone/:id" element={<DroneUploadImage />} />
            <Route path="elevation/create/thermal/:id" element={<ThermalUploadImage />} />
          </Route>
          <Route path="elevation/:id/" element={<ElevationDetails />}>
            <Route path="drone" />
            <Route path="thermal" />
            <Route path="manual" />
            <Route path="thumbnail" />
          </Route>
        </Route>
      </Route>

      {/* catch all */}
      <Route path="*" element={<Page404 />} />
      {/* </Route> */}
    </Routes>
  )
}

export default App

// libs

// hocs

// actions

// services

// utils

// commons

// cpns

// styles

// @antd

// assets

// variable
