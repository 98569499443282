import React from 'react'

function Standard({ cp }) {
  return (
    <>
      <ol className="toc-list" style={{ lineHeight: '30px' }}>
        <li className="text-lg font-bold">
          <h1 className="w-2/3 m-auto py-2 font-bold text-center underline">
            Standard Certification by Competent Person for Periodic Façade Inspection of Buildings
          </h1>
          <div className="py-2 text-justify font-normal">
            In accordance with Section 28(6) of the Building Control Act (the “Act”) and Regulations 15, 16 and 17 of
            (Periodic Inspection of Buildings and Building Facades) Regulations 2021 (the “Regulations”), I,
            <span className="underline">
              <strong>{cp?.username}</strong>
              {''} (<strong>{cp?.regno}</strong>)
            </span>
            , the Competent Person appointed by the building owner under Section 28(3) of the Act have conducted an
            inspection on the condition and integrity of the building façade and hereby submit the report of the results
            of the inspection. I certify that the inspection was carried out and the report was prepared by me in
            accordance with the provisions of the Act and the Regulations.
          </div>

          <div className="flex justify-between gap-8 pt-24 font-normal reportContentBreak">
            <div>
              <div className="flex justify-center gap-2 my-4">
                <div className="mt-1">Competent Person:</div>
                <div>
                  <div className="text-center">{cp?.username}</div>
                  <div className={cp?.username ? 'mt-[-25px]' : 'mt-1'}>__________________</div>
                </div>
              </div>
            </div>

            <div>
              <div className="flex justify-center gap-2 my-4">
                <div className="mt-1">Date:</div>
                <div className="mt-1">___________________</div>
              </div>
            </div>
          </div>
        </li>
      </ol>
    </>
  )
}

export default Standard
