const logTypes = {
  GET_INSPECTION_LOG: 'GET_INSPECTION_LOG',
  GET_COMPANY_LOG: 'GET_COMPANY_LOG',
  GET_ELEVATION_LOG: 'GET_ELEVATION_LOG',
  GET_ELEVATION_LOG_All: 'GET_ELEVATION_LOG_All',
  GET_IMAGE_LOG: 'GET_IMAGE_LOG',
  GET_USER_LOG: 'GET_USER_LOG',
  GET_IMAGE_LOG_ALL: 'GET_IMAGE_LOG_ALL',
  GET_TRACK_USER_LOG: 'GET_TRACK_USER_LOG',
  GET_DOWNLOAD_COMPANY: 'GET_DOWNLOAD_COMPANY',
  GET_DOWNLOAD_INSPECTION: 'GET_DOWNLOAD_INSPECTION',
  GET_DOWNLOAD_USER: 'GET_DOWNLOAD_USER',
  GET_DOWNLOAD_ELEVATION: 'GET_DOWNLOAD_ELEVATION',
  GET_DOWNLOAD_IMAGE: 'GET_DOWNLOAD_IMAGE',
  GET_DOWNLOAD_TRACK_USER: 'GET_DOWNLOAD_TRACK_USER'
}
export default logTypes
