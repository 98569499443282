export const mapElevationDetailPayload = (payload) => {
  const { elevation, elevationImages, elevationNormalImages, closeRangeImages } = payload

  let droneList = elevation.images
  let manualList = elevation.manual_images
  // new drone
  const newDroneList = droneList.map((image) => {
    let item = elevationImages.find((e) => e?.url === image.url)
    if (item) {
      return {
        ...image,
        ...item,
        order: image.order,
        annoId: item.id
      }
    } else {
      return {
        ...image,
        order: image.order,
        annoId: 0
      }
    }
  })
  //new manual
  const newManualList = manualList.map((image) => {
    let item = elevationNormalImages.find((e) => e?.url === image.url)
    if (item) {
      return {
        ...image,
        ...item,
        annoId: item.id
      }
    } else {
      return {
        ...image,
        annoId: 0
      }
    }
  })
  const newElevation = {
    ...elevation,
    images: newDroneList,
    manual_images: newManualList
  }
  return {
    elevation: newElevation,
    elevationNormalImages,
    elevationImages,
    closeRangeImages
  }
}

export const mapBuildingReport = (payload) => {
  const { defectListing, crDefectListing } = payload
  const mapDefectListing = defectListing.map((dl) => {
    const { type, row, column } = dl
    if (type === 'Thumbnail') {
      return {
        ...dl,
        row: `Storey ${dl.floor}`,
        column: `Gridline ${dl.gridline}`
      }
    } else if (type === 'Drone') {
      return {
        ...dl,
        row: `Drone Row ${row + 1}`,
        column: `Column ${column + 1}`
      }
    } else if (type === 'Manual') {
      return {
        ...dl,
        row: `Manual Row ${row}`,
        column: `Column ${column}`
      }
    }
  })
  const mapCloseRangeDefectListing = crDefectListing.map((dl) => {
    const { type, row, column } = dl
    if (type === 'Thumbnail') {
      return {
        ...dl,
        row: `Storey ${dl.floor}`,
        column: `Gridline ${dl.gridline}`
      }
    } else if (type === 'Drone') {
      return {
        ...dl,
        row: `Drone Row ${row + 1}`,
        column: `Column ${column + 1}`
      }
    } else if (type === 'Manual') {
      return {
        ...dl,
        row: `Manual Row ${row}`,
        column: `Column ${column}`
      }
    }
  })
  return {
    defectListing: mapDefectListing,
    crDefectListing: mapCloseRangeDefectListing
  }
}