import React, { createContext, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'

import { REFRESH_TOKEN_KEY, TOKEN_KEY, USER_ID, REFRESH_TOKEN_ID } from '../apis/configApis'
import userApi from '../apis/userApis'
import authApi from '../apis/authApis'
import { changeAvtName } from '../utils/common'
import { generateHSL } from '../utils/stringUtil'

const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [signInSuccess, setSignInSuccess] = useState(false)
  const [error, setError] = useState()
  const [submitting, setSubmitting] = useState(false)

  const [loadingInitial, setLoadingInitial] = useState(true)
  const location = useLocation()

  useEffect(() => {
    if (error) setError(null)
  }, [location.pathname])

  const getCurrentUser = async () => {
    const data = await userApi.getCurrentUser()
    const newName = changeAvtName(data?.user?.username)
    let color = generateHSL(data?.user?.email)
    const newUser = {
      ...data,
      user: {
        ...data.user,
        color: color,
        newName
      }
    }
    setUser(newUser)
    setSubmitting(false)
    setLoadingInitial(false)
  }

  const signIn = async (email, password) => {
    try {
      setSubmitting(true)
      const data = await userApi.signIn(email, password)
      if (!data) {
        setSubmitting(false)
        return false
      }
      localStorage.setItem(TOKEN_KEY, data.token)
      localStorage.setItem(REFRESH_TOKEN_KEY, data.refresh_token)
      localStorage.setItem(USER_ID, data.user.user_id)
      localStorage.setItem(REFRESH_TOKEN_ID, data.user.refresh_token_id)
      setSignInSuccess(true)
      setSubmitting(false)
    } catch (error) {
      setError(error)
      setSubmitting(false)
      toast.error(error.message, {
        theme: 'dark'
      })
    }
  }

  const logout = async () => {
    const resp = await authApi.logOut(localStorage.getItem(REFRESH_TOKEN_ID))
    if (resp.msg === 'logout') {
      localStorage.clear()
      window.location.reload()
    } else {
      setSubmitting(false)
    }
  }
  useEffect(() => {
    const user_id = localStorage.getItem(USER_ID)
    const token_key = localStorage.getItem(TOKEN_KEY)
    const refresh_token_id = localStorage.getItem(REFRESH_TOKEN_ID)
    const refresh_token = localStorage.getItem(REFRESH_TOKEN_KEY)

    if (!user_id || !refresh_token_id || !refresh_token || !token_key) {
      localStorage.clear()
      setLoadingInitial(false)
    } else {
      setSignInSuccess(true)
    }
  }, [])
  useEffect(() => {
    if (signInSuccess) {
      getCurrentUser()
    }
  }, [signInSuccess])

  const memoedValue = useMemo(
    () => ({
      user,
      submitting,
      loadingInitial,
      error,
      signIn,
      // signUp,
      logout
    }),
    [user, submitting, error]
  )
  return <AuthContext.Provider value={memoedValue}>{!loadingInitial && children}</AuthContext.Provider>
}

export default AuthContext
