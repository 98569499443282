import apiInstance from './configApis'

const getElevationsBuilding = async (id, options) => {
  const url = '?' + new URLSearchParams(options).toString()
  try {
    const data = await apiInstance.get(`building/elevations/${id}${url}`)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const createAnnotationDroneElevation = async ({ elevation_id, order, url, review }) => {
  try {
    const data = await apiInstance.post(`building/add-review-image`, {
      elevation_id,
      order,
      url,
      review
    })
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const createAnnotationUserElevation = async ({ elevation_id, url, review, floor, gridline }) => {
  try {
    const data = await apiInstance.post(`elevation-image-normals`, {
      elevation_id,
      url,
      review,
      floor,
      gridline
    })
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const updateCheckedDroneAnnotationElevation = async (elevation_id, url, check) => {
  try {
    const data = await apiInstance.patch(`building/edit-check-image-in-elevation`, {
      elevation_id,
      url,
      check
    })

    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}
const updateCheckedUserAnnotationElevation = async (elevation_id, url, check) => {
  try {
    const { data } = await apiInstance.patch(`building/edit-check-user-image-in-elevation`, {
      elevation_id,
      url,
      check
    })
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}
const updateStatusCloseRangeDrone = async (elevation_id, url, closeRange) => {
  try {
    const data = await apiInstance.patch(`building/edit-status-close-range-image`, {
      elevation_id,
      url,
      closeRange
    })
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const updateStatusCloseRangeManual = async (elevation_id, url, closeRange) => {
  try {
    const data = await apiInstance.patch(`/building/edit-status-close-range-manual-image`, {
      elevation_id,
      url,
      closeRange
    })
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const updateElevationDetail = async ({ elevation_id, name, url }) => {
  try {
    const data = await apiInstance.patch(`/building/update-elevation`, {
      elevation_id,
      name,
      url
    })
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const createAnnotationCloseRangeElevation = async ({
  elevation_id,
  url,
  fileName,
  originalUrl,
  fromUrl,
  defectId,
  type,
  review,
  metadata,
  floor,
  gridline
}) => {
  try {
    const data = await apiInstance.post(`close-range-images`, {
      elevation_id,
      url,
      fileName,
      originalUrl,
      fromUrl,
      defectId,
      type,
      review,
      metadata,
      floor,
      gridline
    })
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const createCloseRangeInThumbnail = async ({
  elevation_id,
  building_id,
  closeRange,
  url,
  fileName,
  originalUrl,
  defectId,
  review,
  metadata,
  floor,
  gridline
}) => {
  try {
    const data = await apiInstance.patch(`/building/edit-closeRange-in-thumbnail`, {
      elevation_id,
      building_id,
      closeRange,
      url,
      fileName,
      originalUrl,
      defectId,
      review,
      metadata,
      floor,
      gridline
    })
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const deleteCloseRangeElevation = async ({ elevation_id, id }) => {
  try {
    const data = await apiInstance.delete(`/close-range-images/${elevation_id}/${id}`)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}
const setElevationStatus = async (body) => {
  try {
    const data = await apiInstance.patch(`/building/switchElevation`, body)
    return data
  } catch (err) {
    throw new Error(err?.response.data.message)
  }
}

const elevationApi = {
  getElevationsBuilding,
  createAnnotationDroneElevation,
  createAnnotationUserElevation,
  updateCheckedDroneAnnotationElevation,
  updateCheckedUserAnnotationElevation,
  updateElevationDetail,
  createAnnotationCloseRangeElevation,
  createCloseRangeInThumbnail,
  updateStatusCloseRangeDrone,
  updateStatusCloseRangeManual,
  deleteCloseRangeElevation,
  setElevationStatus
}
export default elevationApi
