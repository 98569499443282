import { createSlice } from '@reduxjs/toolkit'
import {
  getInspectionReport,
  updateInspectionReport,
  addInspector,
  addDeclare,
  confirmation,
  lock,
  getDefectListing,
  getInspectionDefectSummary,
  getCloseRangeDefectSummary,
  updateLockReport,
  updateReportConfirmation
} from '../thunks/reportThunk'
import reportTypes from '../../constant/store/reportTypes'
import { mapBuildingReport } from '../../utils/sliceUtils'

const elevationSlice = createSlice({
  name: 'report',
  initialState: {
    loadings: {},
    errors: {},
    contents: {},
    paginations: {}
  },
  extraReducers: {
    // ACTION TYPE: Get inspection report
    [getInspectionReport.pending]: (state) => {
      state.loadings[reportTypes.GET_INSPECTION_REPORT] = true
    },
    [getInspectionReport.fulfilled]: (state, { payload }) => {
      const data = mapBuildingReport(payload)
      const newPayload = {
        ...payload,
        ...data
      }
      state.contents[reportTypes.GET_INSPECTION_REPORT] = newPayload
      state.loadings[reportTypes.GET_INSPECTION_REPORT] = false

      state.errors[reportTypes.GET_INSPECTION_REPORT] = ''
    },
    [getInspectionReport.rejected]: (state, { payload }) => {
      state.loadings[reportTypes.GET_INSPECTION_REPORT] = false
      state.errors[reportTypes.GET_INSPECTION_REPORT] = payload.message
    },

    // ACTION TYPE: Get inspection report defect Summary
    [getInspectionDefectSummary.pending]: (state) => {
      state.loadings[reportTypes.GET_INSPECTION_REPORT_DEFECT_SUMMARY] = true
    },
    [getInspectionDefectSummary.fulfilled]: (state, { payload }) => {
      state.loadings[reportTypes.GET_INSPECTION_REPORT_DEFECT_SUMMARY] = false
      state.contents[reportTypes.GET_INSPECTION_REPORT_DEFECT_SUMMARY] = payload
      state.errors[reportTypes.GET_INSPECTION_REPORT_DEFECT_SUMMARY] = ''
    },
    [getInspectionDefectSummary.rejected]: (state, { payload }) => {
      state.loadings[reportTypes.GET_INSPECTION_REPORT_DEFECT_SUMMARY] = false
      state.errors[reportTypes.GET_INSPECTION_REPORT_DEFECT_SUMMARY] = payload.message
    },

    // ACTION TYPE: Get close range defect Summary
    [getCloseRangeDefectSummary.pending]: (state) => {
      state.loadings[reportTypes.GET_CLOSE_RANGE_DEFECT_SUMMARY] = true
    },
    [getCloseRangeDefectSummary.fulfilled]: (state, { payload }) => {
      state.loadings[reportTypes.GET_CLOSE_RANGE_DEFECT_SUMMARY] = false
      state.contents[reportTypes.GET_CLOSE_RANGE_DEFECT_SUMMARY] = payload
      state.errors[reportTypes.GET_CLOSE_RANGE_DEFECT_SUMMARY] = ''
    },
    [getCloseRangeDefectSummary.rejected]: (state, { payload }) => {
      state.loadings[reportTypes.GET_CLOSE_RANGE_DEFECT_SUMMARY] = false
      state.errors[reportTypes.GET_CLOSE_RANGE_DEFECT_SUMMARY] = payload.message
    },

    // ACTION TYPE: update report
    [updateInspectionReport.pending]: (state) => {
      state.loadings[reportTypes.UPDATE_INSPECTION_REPORT] = true
    },
    [updateInspectionReport.fulfilled]: (state, { payload }) => {
      const data = mapBuildingReport(payload.report)
      const newPayload = {
        ...payload.report,
        ...data
      }
      state.loadings[reportTypes.UPDATE_INSPECTION_REPORT] = false
      state.contents[reportTypes.GET_INSPECTION_REPORT] = newPayload
      state.errors[reportTypes.UPDATE_INSPECTION_REPORT] = ''
    },
    [updateInspectionReport.rejected]: (state, { payload }) => {
      state.loadings[reportTypes.UPDATE_INSPECTION_REPORT] = false
      state.errors[reportTypes.UPDATE_INSPECTION_REPORT] = payload.message
    },

    // ACTION TYPE: add inspector
    [addInspector.pending]: (state) => {
      state.loadings[reportTypes.ADD_INSPECTOR] = true
      state.errors[reportTypes.ADD_INSPECTOR] = ''
    },
    [addInspector.fulfilled]: (state, payload) => {
      state.loadings[reportTypes.ADD_INSPECTOR] = false
      state.errors[reportTypes.ADD_INSPECTOR] = ''
    },
    [addInspector.rejected]: (state, payload) => {
      state.loadings[reportTypes.ADD_INSPECTOR] = false
      state.errors[reportTypes.ADD_INSPECTOR] = payload.payload.message
    },

    [addDeclare.pending]: (state) => {
      state.loadings[reportTypes.ADD_DECLARE] = true
    },
    [addDeclare.fulfilled]: (state, payload) => {
      state.loadings[reportTypes.ADD_DECLARE] = false
      state.contents[reportTypes.ADD_DECLARE] = payload
      state.errors[reportTypes.ADD_DECLARE] = ''
    },
    [addDeclare.rejected]: (state, payload) => {
      state.loadings[reportTypes.ADD_DECLARE] = false
      state.errors[reportTypes.ADD_DECLARE] = payload.message
    },

    [confirmation.pending]: (state) => {
      state.loadings[reportTypes.ADD_CONFIRMATION] = true
    },
    [confirmation.fulfilled]: (state, payload) => {
      state.loadings[reportTypes.ADD_CONFIRMATION] = false
      state.contents[reportTypes.ADD_CONFIRMATION] = payload
      state.errors[reportTypes.ADD_CONFIRMATION] = ''
    },
    [confirmation.rejected]: (state, payload) => {
      state.loadings[reportTypes.ADD_CONFIRMATION] = false
      state.errors[reportTypes.ADD_CONFIRMATION] = payload.message
    },

    [lock.pending]: (state) => {
      state.loadings[reportTypes.ADD_LOCK] = true
    },
    [lock.fulfilled]: (state, payload) => {
      state.loadings[reportTypes.ADD_LOCK] = false
      state.contents[reportTypes.ADD_LOCK] = payload
      state.errors[reportTypes.ADD_LOCK] = ''
    },
    [lock.rejected]: (state, payload) => {
      state.loadings[reportTypes.ADD_LOCK] = false
      state.errors[reportTypes.ADD_LOCK] = payload.message
    },
    // ACTION TYPE: get Defect Listing
    [getDefectListing.pending]: (state) => {
      state.loadings[reportTypes.GET_DEFECT_LISTING] = true
    },
    [getDefectListing.fulfilled]: (state, { payload }) => {
      state.loadings[reportTypes.GET_DEFECT_LISTING] = false
      const { defectListing, crDefectListing } = payload
      const mapDefectListing = defectListing.map((dl) => {
        const { type, row, column } = dl
        if (type === 'Thumbnail') {
          return {
            ...dl,
            row: `Storey ${dl.floor}`,
            column: `Gridline ${dl.gridline}`
          }
        } else if (type === 'Drone') {
          return {
            ...dl,
            row: `Drone Row ${row + 1}`,
            column: `Column ${column + 1}`
          }
        } else if (type === 'Manual') {
          return {
            ...dl,
            row: `Manual Row ${row}`,
            column: `Column ${column}`
          }
        }
      })
      const mapCloseRangeDefectListing = crDefectListing.map((dl) => {
        const { type, row, column } = dl
        if (type === 'Thumbnail') {
          return {
            ...dl,
            row: `Storey ${dl.floor}`,
            column: `Gridline ${dl.gridline}`
          }
        } else if (type === 'Drone') {
          return {
            ...dl,
            row: `Drone Row ${row + 1}`,
            column: `Column ${column + 1}`
          }
        } else if (type === 'Manual') {
          return {
            ...dl,
            row: `Manual Row ${row}`,
            column: `Column ${column}`
          }
        }
      })
      const newPayload = {
        ...payload,
        defectListing: mapDefectListing,
        crDefectListing: mapCloseRangeDefectListing
      }
      state.contents[reportTypes.GET_DEFECT_LISTING] = newPayload
      state.errors[reportTypes.GET_DEFECT_LISTING] = ''
    },
    [getDefectListing.rejected]: (state, { payload }) => {
      state.loadings[reportTypes.GET_DEFECT_LISTING] = false
      state.errors[reportTypes.GET_DEFECT_LISTING] = payload.message
    },
    // updateLockReport
    [updateLockReport.pending]: (state) => {
      state.loadings[reportTypes.UPDATE_LOCK_REPORT] = true
    },
    [updateLockReport.fulfilled]: (state, { payload }) => {
      state.loadings[reportTypes.UPDATE_LOCK_REPORT] = false
      state.contents[reportTypes.GET_INSPECTION_REPORT] = payload.report
      state.errors[reportTypes.UPDATE_LOCK_REPORT] = ''
    },
    [updateLockReport.rejected]: (state, { payload }) => {
      state.loadings[reportTypes.UPDATE_LOCK_REPORT] = false
      state.errors[reportTypes.UPDATE_LOCK_REPORT] = payload.message
    },
    // updateReportConfirmation
    [updateReportConfirmation.pending]: (state) => {
      state.loadings[reportTypes.UPDATE_REPORT_CONFIRMATION] = true
    },
    [updateReportConfirmation.fulfilled]: (state, { payload }) => {
      state.loadings[reportTypes.UPDATE_REPORT_CONFIRMATION] = false
      state.contents[reportTypes.GET_INSPECTION_REPORT] = payload.report
      state.errors[reportTypes.UPDATE_REPORT_CONFIRMATION] = ''
    },
    [updateReportConfirmation.rejected]: (state, { payload }) => {
      state.loadings[reportTypes.UPDATE_REPORT_CONFIRMATION] = false
      state.errors[reportTypes.UPDATE_REPORT_CONFIRMATION] = payload.message
    }
  }
})
export default elevationSlice.reducer
