import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { nanoid } from 'nanoid'
import { useNavigate, useParams } from 'react-router-dom'
import imageCompression from 'browser-image-compression'

import EXIF from 'exif-js'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Progress } from 'antd'
import ImageUploading from 'react-images-uploading'
import { onConfirm } from 'react-confirm-pro'
import LoadingAnimated from '../../../assets/images/loading_new.gif'
import { editElevation } from '../../../store/thunks/elevationThunk'
import { getElevationDetails } from '../../../store/thunks/elevationThunk'
import NoImage from '../../../assets/images/no_upload_image.png'
import SaveIcon from '../../../assets/images/svg/save_icon.svg'
import UploadFileIcon from '../../../assets/images/svg/file_upload_icon.svg'
import EditIcon from '../../../assets/images/svg/edit_icon.svg'
import DeleteIcon from '../../../assets/images/svg/delete_icon_orange.svg'
import CloseIcon from '../../../assets/images/svg/close_icon.svg'
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai'
import { toast } from 'react-toastify'
import { makeid } from '../../../utils/common'

import inspectionTypes from '../../../constant/store/inspectionTypes'
import { uploadElevationImage, aiAutoMasking } from '../../../apis/fileApis'
import usePrevious from '../../../hooks/usePrevious'
import useIdleTimer from '../../../hooks/useIdleTimer'
import Direction_1 from '../../../assets/images/direction1.png'
import Direction_2 from '../../../assets/images/direction2.png'
import Direction_3 from '../../../assets/images/direction3.png'
import Direction_4 from '../../../assets/images/direction4.png'
import elevationTypes from '../../../constant/store/elevationTypes'
import { changeFileName } from '../../../utils/common'
import NotAvailable from '../../Common/NotAvailable'
import SkeletonViewer from '../../Common/skeleton-viewer'
import { typeFile, typeFileSort } from '../../../constant/file'
import { color } from '../../../constant/styled'
import PaddingAuto from '../../Layout/PaddingAuto'
import CustomPrompt from '../../custom/Customprompt'

function DroneUploadImage() {
  const navigate = useNavigate()
  const { id: elevation_id } = useParams()
  const dispatch = useDispatch()
  const { reset } = useIdleTimer()

  const {
    contents: contentsElevation,
    loadings: loadingsElevation,
    errors: errorsElevation
  } = useSelector((state) => state.elevation)
  const [columns, setColumns] = useState(1)
  const [rows, setRows] = useState(1)
  const [images, setImages] = useState([])
  const [direction, setDirection] = useState(0)
  const [position, setPosition] = useState([])
  const [preViewLoading, setPreviewLoading] = useState(false)
  let [count, setCount] = useState(0)
  const [totalNewItem, setTotalNewItem] = useState(0)
  const [isShowProcess, setIsShowProcess] = useState(false)
  const [isPageDirty, setPageDirty] = useState(false)

  const [renderingProcess, setRenderingProcess] = useState({
    loading: true,
    error: false
  })

  const elevationDetails = contentsElevation[elevationTypes.GET_ELEVATION_DETAILS]
  const prevElevationGetFetching = usePrevious(loadingsElevation[elevationTypes.GET_ELEVATION_DETAILS])
  const prevElevationUpdateFetching = usePrevious(loadingsElevation[elevationTypes.UPDATE_ELEVATION])

  useEffect(() => {
    dispatch(getElevationDetails(elevation_id))
  }, [elevation_id, dispatch])

  useEffect(() => {
    if (prevElevationGetFetching && !loadingsElevation[elevationTypes.GET_ELEVATION_DETAILS]) {
      if (!errorsElevation[elevationTypes.GET_ELEVATION_DETAILS]) {
        if (elevationDetails.elevation.images?.length > 0) {
          setRenderingProcess((prev) => ({ ...prev, error: true, message: 'Drone created', loading: false }))
        } else {
          setRenderingProcess((prev) => ({ ...prev, loading: false }))
        }
      } else {
        setRenderingProcess((prev) => ({ ...prev, error: true, loading: false }))
      }
    }
  }, [loadingsElevation, errorsElevation, prevElevationGetFetching])

  useEffect(() => {
    if (prevElevationUpdateFetching && !loadingsElevation[elevationTypes.UPDATE_ELEVATION]) {
      if (!errorsElevation[inspectionTypes.UPDATE_ELEVATION]) {
        toast.success(
          'Drone created',
          {
            theme: 'dark',
            autoClose: 2000
          },
          500
        )
        navigate(`/elevation/${contentsElevation[elevationTypes.GET_ELEVATION_DETAILS]?.elevation?.id}/drone`, {
          replace: true
        })
      } else {
        toast.error(errorsElevation[elevationTypes.UPDATE_ELEVATION], {
          theme: 'dark',
          autoClose: 2000
        })
        setCount(0)
        setIsShowProcess(false)
      }
    }
  }, [loadingsElevation, errorsElevation, prevElevationUpdateFetching, contentsElevation, navigate])

  const onPlus = (e, type) => {
    if (type === 'columns') {
      if (columns >= 50) {
        return false
      }
      if (direction >= 2 && images.length > 0) {
        let newImages = [...images]
        for (let i = 1; i <= rows; i++) {
          newImages.push(undefined)
        }
        setImages(newImages)
      }
      if (direction <= 1 && images.length > 0) {
        let newImages = [...images]
        let isEven = false
        let i = columns
        if (i < columns * rows) {
          newImages.splice(i, 0, undefined)
        }
        while (i < columns * rows) {
          if (isEven) {
            i += columns * 2 + 1
            if (i <= columns * rows) {
              newImages.splice(i, 0, undefined)
            }
          } else {
            i += 1
            if (i <= columns * rows) {
              newImages.splice(i, 0, undefined)
            }
          }
          isEven = !isEven
        }
        setImages(newImages)
      }
      setColumns(columns + 1)
    }
    if (type === 'rows') {
      if (rows >= 50) {
        return false
      }
      if (direction <= 1 && images.length > 0) {
        let newImages = [...images]
        for (let i = 1; i <= columns; i++) {
          newImages.push(undefined)
        }
        setImages(newImages)
      }
      if (direction >= 2 && images.length > 0) {
        let newImages = [...images]
        let isEven = false
        let i = rows
        if (i < columns * rows) {
          newImages.splice(i, 0, undefined)
        }
        while (i < columns * rows) {
          if (isEven) {
            i += rows * 2 + 1
            if (i <= columns * rows) {
              newImages.splice(i, 0, undefined)
            }
          } else {
            i += 1
            if (i <= columns * rows) {
              newImages.splice(i, 0, undefined)
            }
          }
          isEven = !isEven
        }
        setImages(newImages)
      }
      setRows(rows + 1)
    }
  }
  const onMinus = (e, type) => {
    if (type === 'columns') {
      if (columns <= 1) {
        return false
      }
      if (direction <= 1 && images.length > 0) {
        let newImages = [...images]
        let isEven = false
        let i = columns - 1
        let count = 0
        if (i < columns * rows) {
          newImages.splice(i, 1)
          count += 1
        }
        while (i < columns * rows) {
          if (isEven) {
            i += columns * 2 - 1
            if (i - count <= columns * rows) {
              newImages.splice(i - count, 1)
              count += 1
            }
          } else {
            i += 1
            if (i - count <= columns * rows) {
              newImages.splice(i - count, 1)
              count += 1
            }
          }
          isEven = !isEven
        }
        setImages(newImages)
      }
      if (direction >= 2 && images.length > 0) {
        let newImages = [...images]
        for (let i = 1; i < rows; i++) {
          newImages.pop()
        }
        setImages(newImages)
      }
      setColumns(columns - 1)
    }
    if (type === 'rows') {
      if (rows <= 1) {
        return false
      }
      if (direction <= 1 && images.length > 0) {
        let newImages = [...images]
        for (let i = 1; i < rows; i++) {
          newImages.pop()
        }
        setImages(newImages)
      }
      if (direction >= 2 && images.length > 0) {
        let newImages = [...images]
        let isEven = false
        let i = rows - 1
        let count = 0
        if (i < columns * rows) {
          newImages.splice(i, 1)
          count += 1
        }
        while (i < columns * rows) {
          if (isEven) {
            i += rows * 2 - 1
            if (i - count <= columns * rows) {
              newImages.splice(i - count, 1)
              count += 1
            }
          } else {
            i += 1
            if (i - count <= columns * rows) {
              newImages.splice(i - count, 1)
              count += 1
            }
          }
          isEven = !isEven
        }
        setImages(newImages)
      }
      setRows(rows - 1)
    }
  }

  const onMoveToNext = (listImgs, index) => {
    if (!listImgs[index + 1] && index < listImgs.length - 1) {
      let newImages = [...listImgs]
      newImages.splice(index, 0, undefined)
      newImages.splice(index + 2, 1)
      setImages(newImages)
      handlePosition(newImages)
    } else {
      const findIndexOfNextDummyImage = (listImage, nextIndex) => {
        if (nextIndex < listImage.length) {
          if (!listImage[nextIndex]) {
            return nextIndex
          } else {
            return findIndexOfNextDummyImage(listImage, nextIndex + 1)
          }
        } else {
          return -1
        }
      }
      const nextDummy = findIndexOfNextDummyImage(listImgs, index + 2)
      if (nextDummy !== -1) {
        let newImages = [...listImgs]
        const arrTemp = newImages.splice(index, nextDummy - index)
        newImages.splice(index + 1, 0, ...arrTemp)
        setImages(newImages)
        handlePosition(newImages)
      } else {
        toast.error("Can't be moved. No space!", {
          theme: 'dark',
          autoClose: 2000
        })
      }
    }
  }
  const onMoveToPrevious = (listImgs, index) => {
    if (!listImgs[index - 1] && index > 0) {
      let newImages = [...listImgs]
      newImages.splice(index + 1, 0, undefined)
      newImages.splice(index - 1, 1)
      setImages(newImages)
      handlePosition(newImages)
    } else {
      const findIndexOfPreDummyImage = (listImage, preIndex) => {
        if (preIndex > -1) {
          if (!listImage[preIndex]) {
            return preIndex
          } else {
            return findIndexOfPreDummyImage(listImage, preIndex - 1)
          }
        } else {
          return -1
        }
      }
      const preDummy = findIndexOfPreDummyImage(listImgs, index - 2)
      if (preDummy !== -1) {
        let newImages = [...listImgs]
        const arrTemp = newImages.splice(preDummy + 1, index - preDummy)
        newImages.splice(preDummy, 0, ...arrTemp)
        setImages(newImages)
        handlePosition(newImages)
      } else {
        toast.error("Can't be moved. No space!", {
          theme: 'dark',
          autoClose: 2000
        })
      }
    }
  }

  const handleUploadImages = async () => {
    setIsShowProcess(true)
    setPageDirty(false)
    let newImgUrls = []
    let temUrls = []
    let newPosition = [...position]
    if (parseInt(direction) === 3) {
      newPosition = [...position].reverse()
    }
    newPosition.forEach((arrRow, idx) => {
      if (parseInt(direction) === 3) {
        arrRow = arrRow.reverse()
      }
      arrRow.forEach((item) => {
        if (parseInt(direction) === 0 || parseInt(direction) === 2) {
          if (idx % 2 === 0) {
            temUrls.push(item?.img ? item.img.file : 'dummy')
          } else {
            temUrls.unshift(item?.img ? item.img.file : 'dummy')
          }
        } else {
          if (idx % 2 !== 0) {
            temUrls.push(item?.img ? item.img.file : 'dummy')
          } else {
            temUrls.unshift(item?.img ? item.img.file : 'dummy')
          }
        }
      })
      newImgUrls.push(...temUrls)

      temUrls = []
    })

    try {
      setTotalNewItem(images.length)
      let uploadUrls = []
      let order = 1
      const options = {
        maxSizeMB: 1,
        initialQuality: 0.6,
        maxWidthOrHeight: 1920
      }
      // const options = {
      //   maxSizeMB: 1,
      //   initialQuality: 0.6,
      //   maxWidthOrHeight: 1920,
      //   fileType: 'image/png'
      // }
      for (const item of newImgUrls) {
        if (item === 'dummy' || !item) {
          uploadUrls.push({
            order: order,
            url: process.env.REACT_APP_AWS_BLANK_IMAGE,
            urlOriginal: process.env.REACT_APP_AWS_BLANK_IMAGE
          })
        } else {
          // image compress
          const nanoiId = nanoid()
          const newFileName = changeFileName(nanoiId + item.name, 'jpg')
          const fileName = decodeURIComponent(item.name)
          const blob = item.slice(0, item.size, 'image/jpeg')
          const newFile = new File([blob], newFileName, { type: 'image/jpeg' })
          const dataThumbnail = {
            filename: newFile.name,
            contentType: newFile.type,
            key: 'elevation',
            type: 'compressed',
            elevationId: elevationDetails.elevation.id,
            size: item.size
          }
          let { signedUrl } = await uploadElevationImage(dataThumbnail)
          // const compressedFile = await imageCompression(newFile, options)
          const newUrl = signedUrl.split('?')[0]
          let maskingUrl = {}
          const compressedFile = await imageCompression(newFile, options)

          await fetch(signedUrl, {
            method: 'PUT',
            headers: {
              'Content-Type': newFile.type
            },
            body: compressedFile
          })
          maskingUrl = await aiAutoMasking({
            elevation_id: elevationDetails.elevation.id,
            type: 'drone',
            url: newUrl
          })

          //original image
          const newFileNameOriginal = changeFileName('original_' + nanoiId + +item.name, 'jpg')
          const newFileOriginal = new File([blob], newFileNameOriginal, { type: 'image/jpeg' })
          const dataThumbnailOrg = {
            filename: newFileOriginal.name,
            contentType: newFileOriginal.type,
            key: 'elevation',
            type: 'raw',
            elevationId: elevationDetails.elevation.id,
            size: item.size
          }
          let originalSigned = await uploadElevationImage(dataThumbnailOrg)
          await fetch(originalSigned.signedUrl, {
            method: 'PUT',
            headers: {
              'Content-Type': newFileOriginal.type
            },
            body: newFileOriginal
          })
          const urlOriginal = originalSigned.signedUrl.split('?')[0]
          let metadata = await new Promise((resolve) =>
            EXIF.getData(item, function () {
              const exifData = {
                DateTimeOriginal: EXIF.getTag(this, 'DateTimeOriginal'),
                GPSLatitudeRef: EXIF.getTag(this, 'GPSLatitudeRef'),
                GPSLatitude: EXIF.getTag(this, 'GPSLatitude'),
                GPSLongitudeRef: EXIF.getTag(this, 'GPSLongitudeRef'),
                GPSLongitude: EXIF.getTag(this, 'GPSLongitude'),
                GPSAltitude: EXIF.getTag(this, 'GPSAltitude'),
                GPSAltitudeRef: EXIF.getTag(this, 'GPSAltitudeRef')
              }
              resolve(exifData)
            })
          )
          // if (JSON.stringify(metadata) === '{}') {
          //   uploadUrls.push({ order: order, url: maskingUrl.newUrl, urlOriginal, fileName })
          //   // uploadUrls.push({ order: order, url: newUrl, urlOriginal, fileName })
          // } else {
          //   uploadUrls.push({ order: order, url: newUrl, urlOriginal, fileName, metadata })
          // }
          uploadUrls.push({ order: order, url: maskingUrl.newUrl, urlOriginal, fileName })
        }
        // push all images

        order++
        count += 1
        reset()
        setCount(count)
      }
      let data_thumbnail = {
        thumbnailUrl: elevationDetails?.elevation?.data_thumbnail?.thumbnailUrl,
        data: []
      }
      const body2 = {
        columns: parseInt(columns),
        direction: parseInt(direction),
        rows: parseInt(rows),
        elevation_id: parseInt(elevation_id),
        building_id: parseInt(elevationDetails?.elevation.building_id),
        images: uploadUrls,
        data_thumbnail: data_thumbnail,
        manual_images: []
      }
      dispatch(editElevation(body2))
    } catch (error) {
      toast.error('Elevation update error', {
        theme: 'dark'
      })
    }
  }

  const handleOnDragEnd = (result) => {
    if (!result.destination) {
      return
    }
    const image1 = position[result.source.droppableId][result.source.index]
    const image2 = position[result.destination.droppableId][result.destination.index]
    let newPosition = [...position]
    const sourceImage = image1.img
    const destinationImage = image2.img

    image1.img = destinationImage
    image2.img = sourceImage
    newPosition[result.source.droppableId].splice(result.source.index, 1, image1)
    newPosition[result.destination.droppableId].splice(result.destination.index, 1, image2)
    handleImages(newPosition)
    setPosition(newPosition)
  }
  const handleImages = (positionData) => {
    let newPosition = [...positionData]
    if (direction === 3) {
      newPosition = newPosition.reverse()
    }
    let newPositionList = []
    for (let newList of newPosition) {
      let newArray = [...newList]
      newArray.sort((a, b) => (a.order > b.order ? 1 : -1))
      newPositionList.push(newArray)
    }
    let newImages = []
    for (let newPositionArray of newPositionList) {
      const urls = newPositionArray.map((array) => array.img)
      newImages = newImages.concat(urls)
    }
    const checkImages = newImages.every((image) => !image)
    if (checkImages) {
      return []
    }
    setImages(newImages)
    return newImages
  }
  const handlePosition = (imagesData) => {
    const newPosition = []
    const images = [...imagesData]
    if (parseInt(direction) === 0 || parseInt(direction) === 1) {
      for (let iRow = 1; iRow < parseInt(rows) + 1; iRow++) {
        const newRows = []
        for (let iCol = 1; iCol < parseInt(columns) + 1; iCol++) {
          const element = { x: iRow, y: iCol, active: true, img: null }
          newRows.push(element)
        }

        newPosition.push(newRows)
      }

      setPosition(newPosition)
    } else {
      for (let iRow = 1; iRow < parseInt(columns) + 1; iRow++) {
        const newRows = []
        for (let iCol = 1; iCol < parseInt(rows) + 1; iCol++) {
          const element = { x: iRow, y: iCol, active: true, img: null }
          newRows.push(element)
        }
        newPosition.push(newRows)
      }
      setPosition(newPosition)
    }

    if (images.length !== 0) {
      const newPos = [...newPosition]
      let currentIndex = 0
      for (let i = 0; i < newPos.length; i++) {
        if (parseInt(direction) === 0) {
          if (i % 2 === 0) {
            for (let j = 0; j < newPos[i].length; j++) {
              if (newPos[i][j].active) {
                newPos[i][j].img = images[currentIndex]
                newPos[i][j].order = currentIndex
                currentIndex++
              }
            }
          } else {
            for (let u = newPos[i].length - 1; u >= 0; u--) {
              if (newPos[i][u].active) {
                newPos[i][u].img = images[currentIndex]
                newPos[i][u].order = currentIndex
                currentIndex++
              }
            }
          }
        } else if (parseInt(direction) === 1) {
          if (i % 2 === 0) {
            for (let t = newPos[i].length - 1; t >= 0; t--) {
              if (newPos[i][t].active) {
                newPos[i][t].img = images[currentIndex]
                newPos[i][t].order = currentIndex
                currentIndex++
              }
            }
          } else {
            for (let z = 0; z < newPos[i].length; z++) {
              if (newPos[i][z].active) {
                newPos[i][z].img = images[currentIndex]
                newPos[i][z].order = currentIndex
                currentIndex++
              }
            }
          }
        } else if (parseInt(direction) === 2) {
          if (i % 2 === 0) {
            for (let j = 0; j < newPos[i].length; j++) {
              if (newPos[i][j].active) {
                newPos[i][j].img = images[currentIndex]
                newPos[i][j].order = currentIndex
                currentIndex++
              }
            }
          } else {
            for (let u = newPos[i].length - 1; u >= 0; u--) {
              if (newPos[i][u].active) {
                newPos[i][u].img = images[currentIndex]
                newPos[i][u].order = currentIndex
                currentIndex++
              }
            }
          }
        } else if (parseInt(direction) === 3) {
          if (i % 2 === 0) {
            for (let t = newPos[i].length - 1; t >= 0; t--) {
              if (newPos[i][t].active) {
                newPos[i][t].img = images[currentIndex]
                newPos[i][t].order = currentIndex
                currentIndex++
              }
            }
          } else {
            for (let z = 0; z < newPos[i].length; z++) {
              if (newPos[i][z].active) {
                newPos[i][z].img = images[currentIndex]
                newPos[i][z].order = currentIndex
                currentIndex++
              }
            }
          }
        }
      }
      if (parseInt(direction) === 3) {
        newPos.map((pos) => {
          return pos.reverse()
        })
        const posReverse = [...newPos].reverse()
        handleImages(posReverse)
        setPosition(posReverse)
      } else {
        handleImages(newPos)
        setPosition(newPos)
      }
    }
  }

  const handleSetPosition = () => {
    const newPosition = []
    if (parseInt(direction) === 0 || parseInt(direction) === 1) {
      for (let iRow = 1; iRow < parseInt(rows) + 1; iRow++) {
        const newRows = []
        for (let iCol = 1; iCol < parseInt(columns) + 1; iCol++) {
          const element = { x: iRow, y: iCol, active: true, img: null }
          newRows.push(element)
        }

        newPosition.push(newRows)
      }

      setPosition(newPosition)
    } else {
      for (let iRow = 1; iRow < parseInt(columns) + 1; iRow++) {
        const newRows = []
        for (let iCol = 1; iCol < parseInt(rows) + 1; iCol++) {
          const element = { x: iRow, y: iCol, active: true, img: null }
          newRows.push(element)
        }
        newPosition.push(newRows)
      }
      setPosition(newPosition)
    }
  }

  const changeDirectAction = () => {
    let imagesData = [...images]
    let newImageData = []
    for (const imageValue of imagesData) {
      if (imageValue) {
        newImageData.push(imageValue)
      }
    }
    setImages(newImageData)
    handlePosition(newImageData)
  }

  useEffect(() => {
    if (rows > 1 || columns > 1 || parseInt(direction) !== 0 || images.length !== 0) {
      setPageDirty(true)
    } else {
      setPageDirty(false)
    }
  }, [rows, columns, direction, images])

  useEffect(() => {
    handlePosition(images)
  }, [rows, columns])

  useEffect(() => {
    changeDirectAction()
  }, [direction])

  const onChange = (imageList, addUpdateIndex) => {
    if (addUpdateIndex?.length > 0) {
      const newImageList = imageList.map((img) => {
        return {
          ...img,
          id: makeid(30)
        }
      })
      setImages(newImageList)
      handlePosition(newImageList)
      setPreviewLoading(false)
    } else if (!addUpdateIndex && imageList.length === 0) {
      setImages(imageList)
      handleSetPosition()
    } else if (!addUpdateIndex && imageList.length > 0) {
      const imagesId = images.map((img) => {
        if (img) {
          return img.id
        }
      })
      const imagesListId = imageList.map((l) => {
        if (l) {
          return l.id
        }
      })
      const removeItemIndex = imagesId.filter((img) => !imagesListId.includes(img))
      const newPosition = position.map((arrow, i) => {
        return arrow.map((item, j) => {
          if (item?.img?.id === removeItemIndex[0]) {
            return {
              ...item,
              img: undefined
            }
          } else {
            return item
          }
        })
      })
      handleImages(newPosition)
      setPosition(newPosition)
    }
  }

  const renderSkeleton = () => {
    return (
      <div style={{ width: '100%', margin: '0 auto' }}>
        <div className="w-full overflow-auto bg-white border border-gray-300 rounded-xl shadow-lg bg-opacity-90 bg-clip-padding flex flex-col mb-6">
          <div className="flex flex-row justify-between border-b pt-5 pb-8 px-[30px]">
            <div className="flex justify-between w-full">
              <div className="flex flex-col gap-2">
                <SkeletonViewer width={240} height={28} />
                <div className="flex flex-row items-center gap-4 my-2">
                  <SkeletonViewer width={34} height={26} />
                  <SkeletonViewer width={130} height={53} borderRadius={12} />
                  <SkeletonViewer width={34} height={26} />
                  <SkeletonViewer width={130} height={53} borderRadius={12} />
                </div>
                <div className="my-2">
                  <SkeletonViewer width={170} height={28} />
                  <div className="flex items-center gap-6 mt-2">
                    <SkeletonViewer borderRadius={6} width={145} height={50} />
                    <SkeletonViewer borderRadius={6} width={145} height={50} />
                    <SkeletonViewer borderRadius={6} width={145} height={50} />
                    <SkeletonViewer borderRadius={6} width={145} height={50} />
                  </div>
                </div>
              </div>
              <SkeletonViewer width={160} height={120} borderRadius={8} className="mr-4" />
            </div>
            <div className="border-l">
              <div className="w-36">
                <SkeletonViewer width={128} borderRadius={12} className="ml-4" height={48} />
              </div>
            </div>
          </div>
        </div>

        <SkeletonViewer borderRadius={12} className="mb-4" height={48} />
        <div className="relative flex flex-col min-w-0  break-words bg-navigate w-full mb-6 shadow-lg rounded">
          <div className="px-4 py-4 flex-auto">
            <div className="tab-content tab-space">
              <div className="flex flex-col overflow-hidden w-full">
                <div className="flex gap-1">
                  {Array.from({ length: 7 }, function (v, k) {
                    return k
                  }).map((i) => (
                    <div key={i} className="flex flex-col gap-1">
                      {Array.from({ length: 3 }, function (f, g) {
                        return g
                      }).map((idx) => (
                        <div key={`${idx} + id`}>
                          <SkeletonViewer width={240} height={192} borderRadius={4} />
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  const onImageUploadingError = (errors) => {
    if (errors?.acceptType) {
      onConfirm({
        title: <h3 className="text-center">{`The images must be a file of type: ${typeFile.join()}`}</h3>,
        description: <br />,

        onCancel: () => {},
        buttons: ({ onCancel }) => (
          <div className="custom-buttons">
            <button
              style={{
                background: 'rgba(37, 99, 235)',
                color: 'white'
              }}
              onClick={onCancel}
            >
              Ok
            </button>
          </div>
        )
      })
    }
  }

  return (
    <>
      <CustomPrompt when={isPageDirty} title="Warning" message="Unsaved Changes. Are you sure you wish to exit?" />
      <div className="relative">
        <PaddingAuto>
          {renderingProcess.loading ? (
            renderSkeleton()
          ) : renderingProcess.error ? (
            <NotAvailable content={renderingProcess.message} />
          ) : (
            <ImageUploading
              multiple
              value={images}
              onChange={onChange}
              onError={onImageUploadingError}
              acceptType={typeFileSort}
              maxNumber={columns * rows}
              dataURLKey="data_url"
            >
              {({
                imageList,
                isDragging,
                dragProps,
                errors,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove
              }) => {
                return (
                  <>
                    <div className="w-full overflow-auto bg-white border border-gray-300 rounded-xl shadow-lg bg-opacity-90 bg-clip-padding flex flex-col mb-6">
                      <div className="flex flex-row justify-between border-b pt-5 pb-8 px-[30px]">
                        <div className="flex justify-between w-full">
                          <div className="flex flex-col gap-2">
                            <p className="text-lg">
                              Elevation:
                              <span className="text-center rounded-full w-11 h-11">
                                {elevationDetails?.elevation.name}
                              </span>
                            </p>
                            <div className="flex flex-row items-center mt-2 mb-2">
                              <span className="mx-4 text-lg">Row</span>
                              <div className="flex items-center rounded-xl p-4 border border-solid border-primary leading-3">
                                <div className="">
                                  <button
                                    className="btn btn-outline-primary"
                                    type="button"
                                    onClick={(e) => onMinus(e, 'rows')}
                                  >
                                    <AiOutlineMinusCircle
                                      size={18}
                                      style={{ color: `${rows === 1 ? color.grayLight : color.gray}` }}
                                    />
                                  </button>
                                </div>
                                <input type="text" className="focus:outline-none w-8 mx-4 text-center" value={rows} />
                                <div className="">
                                  <button
                                    className="btn btn-outline-primary"
                                    type="button"
                                    disabled={(rows + 1) * columns > 400}
                                    onClick={(e) => onPlus(e, 'rows')}
                                  >
                                    <AiOutlinePlusCircle
                                      size={18}
                                      style={{
                                        color: `${
                                          (rows + 1) * columns > 400 || rows >= 50 ? color.grayLight : color.gray
                                        }`
                                      }}
                                    />
                                  </button>
                                </div>
                              </div>
                              <span className="mx-4 text-lg">Col</span>
                              <div className="flex items-center rounded-xl p-4 border border-solid border-primary leading-3">
                                <div className="">
                                  <button
                                    className="btn btn-outline-primary"
                                    type="button"
                                    onClick={(e) => onMinus(e, 'columns')}
                                  >
                                    <AiOutlineMinusCircle
                                      size={18}
                                      style={{ color: `${columns === 1 ? color.grayLight : color.gray}` }}
                                    />
                                  </button>
                                </div>
                                <input
                                  type="text"
                                  className="focus:outline-none w-8 mx-4 text-center"
                                  value={columns}
                                  // onChange={(e) => handleChangValue(e, "column")}
                                />
                                <div className="">
                                  <button
                                    className="btn btn-outline-primary"
                                    type="button"
                                    disabled={(columns + 1) * rows > 400}
                                    onClick={(e) => onPlus(e, 'columns')}
                                  >
                                    <AiOutlinePlusCircle
                                      size={18}
                                      style={{
                                        color: `${
                                          (columns + 1) * rows > 400 || columns >= 50 ? color.grayLight : color.gray
                                        }`
                                      }}
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="my-2">
                              <span className="text-lg mr-4">Choose the direction:</span>
                              <div className="flex items-center gap-6 mt-2 ">
                                <div
                                  className={`flex items-center gap-1 p-2 cursor-pointer box-border border border-solid ${
                                    parseInt(direction) === 0 ? ' border-blue-500 rounded-md' : 'border-transparent'
                                  }`}
                                  onClick={(e) => setDirection(0)}
                                >
                                  <img
                                    alt="Direction"
                                    src={Direction_1}
                                    className="w-12 transition duration-500 transform rounded h-8 cursor-pointer"
                                  />
                                  <div className="text-xs text-primary font-bold">Top left right</div>
                                </div>
                                <div
                                  className={`flex items-center gap-1 p-2 cursor-pointer box-border border border-solid  ${
                                    parseInt(direction) === 1
                                      ? ' border-solid border-blue-500 rounded-md'
                                      : 'border-transparent'
                                  }`}
                                  onClick={(e) => setDirection(1)}
                                >
                                  <img
                                    alt="Direction"
                                    src={Direction_2}
                                    className="w-12 transition duration-500 transform rounded h-8 cursor-pointer"
                                  />
                                  <div className="text-xs text-primary font-bold">Top right left</div>
                                </div>
                                <div
                                  className={`flex items-center gap-1 p-2 cursor-pointer box-border border border-solid  ${
                                    parseInt(direction) === 2
                                      ? ' border-solid border-blue-500 rounded-md'
                                      : 'border-transparent'
                                  }`}
                                  onClick={(e) => setDirection(2)}
                                >
                                  <img
                                    alt="Direction"
                                    src={Direction_3}
                                    className="w-12 transition duration-500 transform rounded h-8 cursor-pointer"
                                  />
                                  <div className="text-xs text-primary font-bold">Top left down</div>
                                </div>
                                <div
                                  className={`flex items-center gap-1 p-2 cursor-pointer box-border border border-solid ${
                                    parseInt(direction) === 3
                                      ? ' border-solid border-blue-500 rounded-md'
                                      : 'border-transparent'
                                  }`}
                                  onClick={(e) => setDirection(3)}
                                >
                                  <img
                                    alt="Direction"
                                    src={Direction_4}
                                    className="w-12 transition duration-500 transform rounded h-8 cursor-pointer"
                                  />
                                  <div className="text-xs text-primary font-bold">Top right down</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="relative flex items-center gap-2 group h-[120px] w-[160px] rounded-md shadow-avatar mr-4">
                            <img
                              className="w-full h-full"
                              src={elevationDetails?.elevation?.data_thumbnail?.thumbnailUrl}
                              alt="avt"
                            />
                          </div>
                        </div>
                        {columns !== 0 && rows !== 0 && (
                          <div className="border-l border-solid border-primary w-36">
                            {isShowProcess ? (
                              <div className="h-14 flex flex-col justify-center items-center mt-6">
                                <div className="font-bold">
                                  Uploaded {count}/{totalNewItem}
                                </div>
                                <Progress
                                  type="circle"
                                  percent={Math.ceil(((count * 100) / totalNewItem) * 10) / 10}
                                  status="exception"
                                  height={56}
                                  width={56}
                                />
                              </div>
                            ) : (
                              <>
                                {images && images.length !== 0 && (
                                  <button
                                    className="w-32 ml-4 bg-blue-button py-4 flex items-center justify-around rounded-xl  leading-3 cursor-pointer mb-3 px-3"
                                    onClick={() => {
                                      handleUploadImages()
                                    }}
                                  >
                                    <img src={SaveIcon} alt="save" className="flex mx-2" />
                                    <span className="text-white font-bold mr-2">Save</span>
                                  </button>
                                )}

                                {(!images || images.length === 0) && (
                                  <button
                                    className="w-32 ml-4 border border-primary boroder-solid py-4 flex items-center justify-around rounded-xl  leading-3 cursor-pointer mb-3"
                                    onClick={() => {
                                      onImageUpload()
                                      setPreviewLoading(true)
                                    }}
                                    {...dragProps}
                                  >
                                    <img src={UploadFileIcon} alt="save" />
                                    <span className="text-primary mr-2 font-bold">Upload photo</span>
                                  </button>
                                )}
                                {images && images.length !== 0 && (
                                  <button
                                    className="w-32 float-right border border-primary boroder-solid py-4 flex items-center justify-around rounded-xl  leading-3 cursor-pointer mb-3 px-3"
                                    onClick={onImageRemoveAll}
                                  >
                                    <img src={CloseIcon} alt="save" />
                                    <span className="text-opacity mr-2">Remove all</span>
                                  </button>
                                )}
                                {preViewLoading && (
                                  <img src={LoadingAnimated} className="w-12 h-12 ml-4" alt="Loading" />
                                )}
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    {columns !== 0 && rows !== 0 && !errors?.maxNumber && (
                      <div
                        className="flex p-4 mb-4 text-sm text-yellow-700 bg-yellow-100 rounded-lg dark:bg-yellow-200 dark:text-yellow-800 mt-4"
                        role="alert"
                      >
                        <svg
                          className="inline flex-shrink-0 mr-3 w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <div>
                          <span className="font-medium">Warning!</span> If you want to change the direction, columns or
                          rows after you add images, you should remove all images and add again. <br />
                          <span>The maximum amount of drone images for each elevation is 400.</span>
                        </div>
                      </div>
                    )}

                    {errors && errors.maxNumber && (
                      <div
                        className="relative p-4 my-4 text-red-700 bg-red-100 border border-red-400 rounded"
                        role="alert"
                      >
                        <strong className="mr-2 font-bold">Error!</strong>
                        <span className="block sm:inline">Number of selected images exceed max number</span>
                      </div>
                    )}
                    {columns !== 0 && rows !== 0 && (
                      <div className="relative flex flex-col min-w-0  break-words bg-navigate w-full mb-6 shadow-lg rounded">
                        <div className="px-4 py-4 flex-auto">
                          <div className="tab-content tab-space">
                            <div className="flex flex-col overflow-auto bg-navigate w-full max-h-[90vh] custom-scroll-bar">
                              <div
                                className={`flex gap-1 ${
                                  parseInt(direction) === 0 || parseInt(direction) === 1 ? 'flex-col' : 'flex-row'
                                }`}
                              >
                                <DragDropContext onDragEnd={handleOnDragEnd}>
                                  {position.map((arrRow, idx) => (
                                    <React.Fragment key={idx}>
                                      <Droppable
                                        droppableId={idx.toString()}
                                        key={idx}
                                        direction={direction < 2 ? 'horizontal' : 'vertical'}
                                        isDropDisabled={false}
                                        isCombineEnabled={true}
                                        ignoreContainerClipping={true}
                                      >
                                        {(provided) => {
                                          return (
                                            <div key={idx} className="flex flex-row items-center justify-start">
                                              <div
                                                className={`flex justify-start  gap-1 ${
                                                  parseInt(direction) === 0 || parseInt(direction) === 1
                                                    ? 'flex-row pr-5'
                                                    : parseInt(direction) === 2
                                                    ? 'flex-col'
                                                    : isShowProcess
                                                    ? 'flex-col-reverse'
                                                    : 'flex-col'
                                                }`}
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                              >
                                                {arrRow.map((col, idx) => {
                                                  return (
                                                    <Draggable
                                                      key={col.order?.toString()}
                                                      draggableId={col.order?.toString()}
                                                      index={idx}
                                                    >
                                                      {(provided, snapshot) => (
                                                        <>
                                                          <div
                                                            className={`relative flex bg-transparent flex-row items-center justify-center h-48  cursor-pointer w-60 ${
                                                              snapshot.isDragging ? '' : 'drag'
                                                            }`}
                                                            // style={getStyle(provided.draggableProps.style, snapshot)}
                                                            key={idx}
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                          >
                                                            {col.img && col.img?.data_url ? (
                                                              <>
                                                                <img
                                                                  src={col.img.data_url}
                                                                  alt="New Images"
                                                                  className="rounded-lg  w-60 h-48"
                                                                />
                                                                <button
                                                                  onClick={() => {
                                                                    const index = images.findIndex(
                                                                      (img) => img && img.id === col.img.id
                                                                    )

                                                                    return onImageUpdate(index)
                                                                  }}
                                                                  className="absolute rounded-lg flex items-center justify-center w-8 h-8 bg-white opacity-50 top-2 left-2 cursor-pointer hover:opacity-100"
                                                                >
                                                                  <img src={EditIcon} alt="edit" />
                                                                </button>
                                                                <button
                                                                  onClick={() => {
                                                                    onConfirm({
                                                                      title: (
                                                                        <h3 className="text-center">
                                                                          Are you sure you want to delete this image?
                                                                        </h3>
                                                                      ),
                                                                      description: <br />,
                                                                      onSubmit: async () => {
                                                                        const index = images.findIndex(
                                                                          (img) => img && img.id === col.img.id
                                                                        )
                                                                        return onImageRemove(index)
                                                                      },
                                                                      onCancel: () => {},
                                                                      buttons: ({ onSubmit, onCancel }) => (
                                                                        <div className="custom-buttons">
                                                                          <button
                                                                            style={{
                                                                              background: 'rgba(37, 99, 235)',
                                                                              color: 'white'
                                                                            }}
                                                                            onClick={onSubmit}
                                                                          >
                                                                            YES
                                                                          </button>
                                                                          <button
                                                                            style={{
                                                                              background: 'rgba(220, 38, 38)',
                                                                              color: 'white'
                                                                            }}
                                                                            onClick={onCancel}
                                                                          >
                                                                            NO
                                                                          </button>
                                                                        </div>
                                                                      )
                                                                    })
                                                                  }}
                                                                  className="absolute rounded-lg flex items-center justify-center w-8 h-8 bg-white opacity-50  top-12 left-2 cursor-pointer hover:opacity-100"
                                                                >
                                                                  <img src={DeleteIcon} alt="delete" />
                                                                </button>
                                                                <button
                                                                  onClick={() => {
                                                                    const index = images.findIndex(
                                                                      (img) => img && img.id === col.img.id
                                                                    )
                                                                    onMoveToNext(images, index)
                                                                  }}
                                                                  className="absolute rounded-lg flex items-center justify-center w-8 h-8 font-bold text-primary text-xs bg-white opacity-50  top-[88px] left-2 cursor-pointer hover:opacity-100"
                                                                >
                                                                  Next
                                                                </button>
                                                                <button
                                                                  onClick={() => {
                                                                    const index = images.findIndex(
                                                                      (img) => img && img.id === col.img.id
                                                                    )
                                                                    onMoveToPrevious(images, index)
                                                                  }}
                                                                  className="absolute rounded-lg flex items-center justify-center w-8 h-8 font-bold text-primary text-xs bg-white opacity-50  top-[128px] left-2 cursor-pointer hover:opacity-100"
                                                                >
                                                                  Pre
                                                                </button>
                                                              </>
                                                            ) : (
                                                              <img
                                                                src={NoImage}
                                                                alt="Placeholder"
                                                                className="w-60 h-full rounded"
                                                              />
                                                            )}
                                                          </div>
                                                          {snapshot.isDragging ? (
                                                            <div
                                                              className={`relative flex bg-transparent flex-row items-center justify-center h-48  cursor-pointer w-60`}
                                                            >
                                                              <img
                                                                src={NoImage}
                                                                alt="Placeholder"
                                                                className="w-60 h-full rounded"
                                                              />
                                                            </div>
                                                          ) : (
                                                            <></>
                                                          )}
                                                        </>
                                                      )}
                                                    </Draggable>
                                                  )
                                                })}
                                                <span
                                                  style={{
                                                    display: 'none'
                                                  }}
                                                >
                                                  {provided.placeholder}
                                                </span>
                                              </div>
                                            </div>
                                          )
                                        }}
                                      </Droppable>
                                    </React.Fragment>
                                  ))}
                                </DragDropContext>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )
              }}
            </ImageUploading>
          )}
        </PaddingAuto>
      </div>
    </>
  )
}

export default DroneUploadImage
